import { doGraphqlRequest } from 'ducks/requests/operations'
import { BOOTSTRAP_APP_DATA } from './types'
import { bootstrapQuery } from './queries'
import { bootstrapSchema } from './schema'

// Bootstap function for new GQL API, so we can gradually port types over.
export function doFetchAppBootstrapData() {
  return dispatch => {
    dispatch(
      doGraphqlRequest(
        BOOTSTRAP_APP_DATA,
        bootstrapQuery(),
        {},
        {
          normalizationSchema: bootstrapSchema,
          app: true,
        }
      )
    )
  }
}
