export const getAllQuery = `
  query Exports($filter: ExportFilter, $orderBy: ExportOrder) {
    exports(filter: $filter, orderBy: $orderBy) {
      nodes {
        id
        name
        state
        createdAt
        updatedAt
        queuedAt
        startedAt
        exportedAt
        completedAt
        failedAt
        cancelledAt
        dateRangeStart
        dateRangeEnd
        requestedBy {
          id
        }
      }
    }
  }
`
