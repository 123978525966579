import { compact } from 'util/objects'
import { doApiReadRequest } from 'ducks/requests/operations'
import { delay } from 'util/functions'
import { LOAD_ALL_BATCH_JOBS_QUERY_ID } from './queryIds'
import { selectIsLoading, selectShouldLoadBatchJobs } from './selectors'
import { FETCH_BATCH_JOBS } from './actionTypes'
import { batchJobsApiV1ResponseSchema } from './schema'

const DEFAULT_POLLING_INTERVAL = 5000 // 5 seconds
let pollingHandle = null
export const doFetchBatchJobs = fetchConfig => async (dispatch, getState) => {
  const queryId = LOAD_ALL_BATCH_JOBS_QUERY_ID
  const cursor = 'all'
  const {
    batchTypes,
    states,
    debounce,
    shouldPoll,
    pollingInterval = DEFAULT_POLLING_INTERVAL,
  } = fetchConfig
  const params = compact({
    batch_type: batchTypes,
    state: states,
  })

  if (debounce && selectIsLoading(getState())) {
    return Promise.resolve()
  }

  const response = await dispatch(
    doApiReadRequest(FETCH_BATCH_JOBS, 'v1/batch_jobs', params, {
      normalizationSchema: batchJobsApiV1ResponseSchema,
      searches: {
        queryId,
        cursor,
        extractPagination: 'apiv1',
      },
      moduleOptions: {
        entities: {
          additionalActions: [
            {
              entityType: 'batchJob',
              stores: ['current'],
              phases: ['SUCCESS'],
              type: 'clear',
            },
          ],
        },
      },
    })
  )

  if (shouldPoll && pollingInterval && selectShouldLoadBatchJobs(getState())) {
    if (pollingHandle) clearTimeout(pollingHandle)
    pollingHandle = delay(() => {
      dispatch(doFetchBatchJobs(fetchConfig))
    }, pollingInterval)
  }

  return response
}
