import { oauthTokenSelector } from 'selectors/app'
import config from 'config'
import { selectContactByEmail } from '../selectors'
import doFetchDealProperties from './doFetchDealProperties'

import * as api from '../api'
import {
  FETCH_CONTACT_DEALS_REQUEST,
  FETCH_CONTACT_DEALS_SUCCESS,
  FETCH_CONTACT_DEALS_FAILURE,
} from '../types'

export default function doFetchContactDeals(email) {
  return (dispatch, getState) => {
    dispatch(doFetchDealProperties()).then(() => {
      const meta = {
        requestKey: `integrations/hubspot/fetchContactDeals:${email}`,
      }
      const state = getState()

      const customer = selectContactByEmail(state, email)
      if (!customer) return null

      dispatch({
        type: FETCH_CONTACT_DEALS_REQUEST,
        data: {
          email,
        },
        meta,
      })
      const token = oauthTokenSelector(state)
      return api
        .getContactDeals(token, customer.data.vid)
        .then(payload => {
          const { response, code } = payload
          dispatch({
            type: FETCH_CONTACT_DEALS_SUCCESS,
            data: {
              email,
              code,
              deals: response,
            },
            meta,
          })
          return payload
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          if (config.isDevelopment) console.error(error)
          return dispatch({
            type: FETCH_CONTACT_DEALS_FAILURE,
            data: {
              email,
              error,
            },
            meta,
          })
        })
    })
  }
}
