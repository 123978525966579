import { selectRequestByType } from 'ducks/requests/selectors'
import { emptyObj } from 'util/objects'

import createCachedSelector from 're-reselect'

import {
  FETCH_CUSTOMER_RATING_SETTINGS,
  UPSERT_CUSTOMER_RATING_SETTINGS,
} from '../types'
import { selectCustomerRatingSettingsById } from './selectCustomerRatingSettingsById'

export const selectCustomerRatingSettingById = createCachedSelector(
  selectCustomerRatingSettingsById,
  (state, byId) => byId,
  (byId, settingId) => byId[settingId] || emptyObj
)((state, settingId) => `${settingId || 'unknown'}`)

export const selectIsLoadingCustomerRatingSettings = state =>
  selectRequestByType(state, FETCH_CUSTOMER_RATING_SETTINGS).loading

export const selectIsLoadingCustomerRatingSetting = state =>
  selectRequestByType(state, UPSERT_CUSTOMER_RATING_SETTINGS).loading
