import storage from 'util/storage'

import { CLICK_ID_KEY } from './types'

export function getClickIdFromStorage() {
  return storage.get(CLICK_ID_KEY)
}

export function setClickIdInStorage(clickId) {
  storage.set(CLICK_ID_KEY, clickId)
}
