import { contactCustomFieldValuesSubQueryClientPaging } from '.'

export const fetchConversationContactCustomFieldsClientPagingQuery = `
  query ConversationContactByNumber($contactCustomFieldIds: [ID!], $number: Int!) {
    conversation(number: $number) {
      id
      number
      contact {
        id
        ${contactCustomFieldValuesSubQueryClientPaging}
      }
    }
  }
`
