import moment from 'moment'
import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { selectAccount } from 'selectors/app'
import { selectCurrentUserIsAdmin } from 'ducks/currentUser/selectors/base'
import { selectIsBillingPage } from 'subapps/settings/selectors'
import { selectFlag } from 'ducks/flags/selectors'
import { createBasicSelector } from 'util/redux'
import { selectRequestByType } from 'ducks/requests/selectors'
import { HIDE_IMPROVEMENT_IDEA } from 'ducks/flags/flagNames'
import { diff, toDate } from 'util/date'
import storage from 'util/storage'
import { selectCurrentEntitiesDenormalizedByType } from 'ducks/entities/selectors'

import { selectBase } from './selectBase'
import { FETCH_BILLING_DATA_V1, UPDATE_SUBSCRIPTION } from '../types'
import { ACTIVE_PLAN_STATES } from '../constants'

export const selectHasLegacyKb = createSelector(
  selectBase,
  billing => billing.has_legacy_kb
)

export const selectIsBillingDataLoading = createBasicSelector(
  selectBase,
  state => selectRequestByType(state, FETCH_BILLING_DATA_V1),
  (base, request) => !!base.isLoading || request.loading
)

export const selectIsCanceling = createSelector(
  selectBase,
  base => !!base.isCanceling
)

export const selectIsKbActive = createSelector(
  selectBase,
  base => base.kb && ACTIVE_PLAN_STATES.includes(base.kb.state)
)

export const selectIsStartingTrial = createSelector(
  selectBase,
  base => base.isStartingTrial === true
)

export const selectIsUpdatingCard = createSelector(
  selectBase,
  base => base.isUpdatingCard
)

export const selectInvoiceBase = createSelector(
  selectBase,
  base => base.invoices || emptyObj
)

export const selectIsInvoicesLoaded = createSelector(
  selectInvoiceBase,
  base => !!base.isLoaded
)

export const selectInvoices = createSelector(
  selectInvoiceBase,
  base => base.data || emptyArr
)

export const selectInvoiceExcludingDrafts = createSelector(
  selectInvoices,
  invoices => invoices.filter(invoice => invoice.status !== 'draft')
)

export const selectBillingInformationBase = createSelector(
  selectBase,
  base => base.information || emptyObj
)

export const selectIsBillingInformationLoaded = createSelector(
  selectBillingInformationBase,
  base => !!base.isLoaded
)

export const selectBillingInformation = createSelector(
  selectBillingInformationBase,
  base => base.data || emptyObj
)

export const selectHasBillingInformationError = createSelector(
  selectBillingInformationBase,
  base => !!base.hasError
)

export const selectCreditCards = createBasicSelector(state =>
  selectCurrentEntitiesDenormalizedByType(state, 'creditCard')
)

export const selectPrimaryCreditCard = createSelector(
  selectCreditCards,
  // Kevin Rademan (2019-03-29)
  // Note we dont currently have multiple cards in the backend, so there
  // will always only be 1 card (if you've addedf a card) and that card
  // will be your primary. Im keeping the interface an array incase down
  // the line we want to support having primary and backup credit cards
  // (common for most business with business critical software)
  cards => (cards.length > 0 ? cards[0] : null)
)

export const selectIsCreditCardExpiringSoon = createSelector(
  selectPrimaryCreditCard,
  card => {
    if (!card) return false
    const { expiryMonth, expiryYear } = card
    const ccDate = moment(`${expiryYear}-${expiryMonth}-01`, 'YYYY-MM-DD').add(
      1,
      'months'
    )
    const currentDate = moment()
    const expiresIn = ccDate.diff(currentDate, 'days')
    if (expiresIn >= 0 && expiresIn <= 42) return true
    // When expires in is < 0, then we return false becaues its already expired
    return false
  }
)

export const selectShowCreditCardExpireSoon = createSelector(
  selectIsBillingPage,
  selectIsCreditCardExpiringSoon,
  selectCurrentUserIsAdmin,
  selectFlag,
  selectPrimaryCreditCard,
  (
    isBillingPage,
    isCreditCardExpiringSoon,
    currentUserIsAdmin,
    flags,
    creditCard
  ) => {
    if (!creditCard) return false
    const { expiryMonth, expiryYear } = creditCard
    const flagName = `has_seen_credit_card_expire_soon_${expiryYear}_${expiryMonth}`
    return (
      currentUserIsAdmin &&
      !isBillingPage &&
      isCreditCardExpiringSoon &&
      flags(flagName) !== true
    )
  }
)

export const selectIsCreditCardExpired = createSelector(
  selectPrimaryCreditCard,
  () => new Date().getFullYear(),
  () => new Date().getMonth() + 1,
  (card, currentYear, currentMonth) => {
    if (!card) return false
    if (card.expiryYear < currentYear) return true
    if (card.expiryYear > currentYear) return false
    if (card.expiryMonth < currentMonth) return true
    return false
  }
)

export const selectHasLastBillingFailed = createSelector(
  selectBase,
  base => !!base.hasLastBillingFailed
)

export const selectShowLastBillingFailed = createSelector(
  selectHasLastBillingFailed,
  selectCurrentUserIsAdmin,
  (hasLastBillingFailed, currentUserIsAdmin) =>
    currentUserIsAdmin && hasLastBillingFailed
)

export const selectShowCreditCardExpired = createSelector(
  selectIsBillingPage,
  selectIsCreditCardExpired,
  selectCurrentUserIsAdmin,
  (isBillingPage, isCreditCardExpired, currentUserIsAdmin) =>
    currentUserIsAdmin && !isBillingPage && isCreditCardExpired
)

export const selectCcLast4 = createSelector(
  selectPrimaryCreditCard,
  card => card.last4
)

export const selectCcExp = createSelector(
  selectPrimaryCreditCard,
  card => `${card.expiryMonth}/${card.expiryYear}`
)

export const selectBillingPendingSubscription = createBasicSelector(
  selectBase,
  base => base.pendingSubscription || emptyObj
)

export const selectBillingStartTrialDurationDays = createBasicSelector(
  selectBase,
  base => base.startTrialDurationDays
)

export const selectIsPaidAccount = createSelector(
  selectBase,
  base => !!base.isPaid
)

export const selectUpdateSubscriptionRequest = state =>
  selectRequestByType(state, UPDATE_SUBSCRIPTION)

export const selectBillingExternalAccountId = createBasicSelector(
  selectBase,
  base => base.externalAccountId
)

export const selectBillingExternalSubscriptionId = createBasicSelector(
  selectBase,
  base => base.externalSubscriptionId
)

export const selectShowImprovementIdeaBar = createSelector(
  selectFlag,
  selectAccount,
  selectIsPaidAccount,
  (flag, account, isPaidAccount) => {
    if (
      isPaidAccount &&
      diff('weeks', account.activated_at, new Date()) > 2 &&
      !flag(HIDE_IMPROVEMENT_IDEA)
    ) {
      const expirationDate = storage.get('has_seen_improvement_idea')
      return expirationDate ? toDate(expirationDate) < new Date() : true
    }
    return false
  }
)
