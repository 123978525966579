import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import { groupBy } from 'util/arrays'

export function selectBase(state) {
  return state.crm.customFieldCategories
}
export function selectCustomFieldCategoriesById(state) {
  return selectBase(state).byId
}

export const selectCustomFieldCategories = createSelector(
  selectCustomFieldCategoriesById,
  categories => {
    return Object.values(categories).filter(
      ({ key }) => key !== 'contact_handles'
    )
  }
)

export const selectCustomFieldCategoriesByKey = createSelector(
  selectCustomFieldCategories,
  categories => groupBy(categories, ({ key }) => key)
)

export const selectCustomFieldCategoryForKey = createCachedSelector(
  selectCustomFieldCategoriesByKey,
  (state, { key }) => key,
  (customFieldCategories, key) => {
    const forKey = customFieldCategories[key]
    if (forKey) return forKey[0]
    return undefined
  }
)((state, { key }) => key)

export const selectCustomFieldCategoryKeys = createSelector(
  selectCustomFieldCategories,
  categories => categories.map(({ key }) => key)
)

export const selectCustomFieldCategoriesForType = createCachedSelector(
  selectCustomFieldCategories,
  (state, { type }) => type,
  (categories, type) => {
    return categories
      .filter(({ type: categoryType }) => categoryType === type)
      .sort(({ createdAt: a }, { createdAt: b }) => {
        if (a < b) return 1
        if (a > b) return -1
        return 0
      })
  }
)((state, { type }) => type)

export const selectOtherCategoriesAsOptions = createCachedSelector(
  selectCustomFieldCategories,
  (state, { categoryId }) => categoryId,
  (categories, categoryId) => {
    return categories
      .filter(({ id }) => id !== categoryId)
      .sort(({ name: a }, { name: b }) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1
        if (a.toLowerCase() > b.toLowerCase()) return 1
        return 0
      })
      .map(({ id, name }) => ({
        text: name,
        value: id,
      }))
  }
)((state, { categoryId }) => categoryId)
