import { capture } from 'ducks/tracking/actions'
import {
  FETCH_CANNED_REPLY_CATEGORIES_STARTED,
  TOGGLE_CANNED_REPLY_DROPDOWN,
  INSERT_CANNED_REPLY_REQUEST,
} from '../types'

export const initialState = {
  isVisible: false,
  source: null,
  cursorBookmark: null,
  actionPerformed: false,
}

const actionPerformed = draftState => {
  draftState.cannedReplyDropdown.actionPerformed = true
  return draftState
}

export const reducers = {
  [TOGGLE_CANNED_REPLY_DROPDOWN]: (draftState, action) => {
    const { isVisible, source, cursorBookmark } = action.payload
    const cannedReplyDropdown = draftState.cannedReplyDropdown
    if (isVisible && source === 'forward-slash') {
      capture('canned_reply modal forwardslash')
    }
    if (
      !isVisible &&
      cannedReplyDropdown.source === 'forward-slash' &&
      !cannedReplyDropdown.actionPerformed
    ) {
      capture('canned_reply modal forwardslash_failure')
    }
    Object.assign(cannedReplyDropdown, {
      isVisible,
      source,
      cursorBookmark,
      actionPerformed: false,
    })
    return draftState
  },
  [FETCH_CANNED_REPLY_CATEGORIES_STARTED]: actionPerformed,
  [INSERT_CANNED_REPLY_REQUEST]: actionPerformed,
}
