import { createSelector } from 'reselect'
import { selectCurrentUserPrefersAutoAdvanceOnOpen } from './selectCurrentUserPrefersAutoAdvanceOnOpen'
import { selectCurrentUserPrefersAutoAdvanceOnSnoozed } from './selectCurrentUserPrefersAutoAdvanceOnSnoozed'
import { selectCurrentUserPrefersAutoAdvanceOnClosed } from './selectCurrentUserPrefersAutoAdvanceOnClosed'
import { selectCurrentUserPrefersAutoAdvanceOnSpam } from './selectCurrentUserPrefersAutoAdvanceOnSpam'
import { selectCurrentUserPrefersAutoAdvanceOnTrash } from './selectCurrentUserPrefersAutoAdvanceOnTrash'
import { selectCurrentUserPrefersAutoAdvanceOnAssign } from './selectCurrentUserPrefersAutoAdvanceOnAssign'
import { selectCurrentUserPrefersAutoAdvanceOnFollow } from './selectCurrentUserPrefersAutoAdvanceOnFollow'

export const selectAutoAdvancePreferences = createSelector(
  selectCurrentUserPrefersAutoAdvanceOnOpen,
  selectCurrentUserPrefersAutoAdvanceOnSnoozed,
  selectCurrentUserPrefersAutoAdvanceOnClosed,
  selectCurrentUserPrefersAutoAdvanceOnSpam,
  selectCurrentUserPrefersAutoAdvanceOnTrash,
  selectCurrentUserPrefersAutoAdvanceOnAssign,
  selectCurrentUserPrefersAutoAdvanceOnFollow,
  (
    autoAdvanceOnOpen,
    autoAdvanceOnSnoozed,
    autoAdvanceOnClosed,
    autoAdvanceOnSpam,
    autoAdvanceOnTrash,
    autoAdvanceOnAssign,
    autoAdvanceOnFollow
  ) => ({
    autoAdvanceOnOpen,
    autoAdvanceOnSnoozed,
    autoAdvanceOnClosed,
    autoAdvanceOnSpam,
    autoAdvanceOnTrash,
    autoAdvanceOnAssign,
    autoAdvanceOnFollow,
  })
)
