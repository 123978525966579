import React from 'react'
import { useSelector } from 'react-redux'
import { HIDE_CANNED_REPLY_PREVIEW_CALLOUT } from 'ducks/flags/flagNames'
import { selectFlag } from 'ducks/flags/selectors'
import { selectFeatureCalloutExpired } from 'selectors/app/selectFeatureCalloutExpired'
import PreviewCallout from './PreviewCallout'

const WithPreviewCallout = ({ children }) => {
  const shouldHide = useSelector(state =>
    selectFlag(state)(HIDE_CANNED_REPLY_PREVIEW_CALLOUT)
  )
  const featureCalloutExpired = useSelector(state =>
    selectFeatureCalloutExpired(state, '2023-03-14T00:00:00')
  )
  if (featureCalloutExpired || shouldHide !== false) return children
  return <PreviewCallout>{children}</PreviewCallout>
}

export default WithPreviewCallout
