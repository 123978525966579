import { oauthTokenSelector } from 'selectors/app'
import { selectFormElementValue } from 'selectors/userIntegrations'

import * as trello from '../api'
import {
  FETCH_CARD_FOR_BOARD_REQUEST,
  FETCH_CARD_FOR_BOARD_SUCCESS,
  FETCH_CARD_FOR_BOARD_FAILURE,
} from '../types'

export default function doFetchTrelloCardsForCurrentBoardForCurrentBoard() {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_CARD_FOR_BOARD_REQUEST })
    const state = getState()
    const board = selectFormElementValue(state)('board')
    return trello
      .listCardsForBoard(oauthTokenSelector(state), board)
      .then(cards =>
        dispatch({
          type: FETCH_CARD_FOR_BOARD_SUCCESS,
          payload: { cards },
        })
      )
      .catch(error =>
        dispatch({
          error: true,
          payload: error,
          type: FETCH_CARD_FOR_BOARD_FAILURE,
        })
      )
  }
}
