export const FETCH_CUSTOM_FIELD_CATEGORIES =
  'crm/customFieldCategories/FETCH_CUSTOM_FIELD_CATEGORIES'
export const FETCH_CUSTOM_FIELD_CATEGORIES_STARTED =
  'crm/customFieldCategories/FETCH_CUSTOM_FIELD_CATEGORIES_STARTED'
export const FETCH_CUSTOM_FIELD_CATEGORIES_SUCCESS =
  'crm/customFieldCategories/FETCH_CUSTOM_FIELD_CATEGORIES_SUCCESS'
export const FETCH_CUSTOM_FIELD_CATEGORIES_FAILED =
  'crm/customFieldCategories/FETCH_CUSTOM_FIELD_CATEGORIES_FAILED'

export const FETCH_ALL_CUSTOM_FIELD_CATEGORIES_STARTED =
  'crm/customFieldCategories/FETCH_ALL_CUSTOM_FIELD_CATEGORIES_STARTED'
export const FETCH_ALL_CUSTOM_FIELD_CATEGORIES_SUCCESS =
  'crm/customFieldCategories/FETCH_ALL_CUSTOM_FIELD_CATEGORIES_SUCCESS'
export const FETCH_ALL_CUSTOM_FIELD_CATEGORIES_FAILED =
  'crm/customFieldCategories/FETCH_ALL_CUSTOM_FIELD_CATEGORIES_FAILED'

export const CREATE_CUSTOM_FIELD_CATEGORY =
  'crm/customFieldCategories/CREATE_CUSTOM_FIELD_CATEGORY'
export const CREATE_CUSTOM_FIELD_CATEGORY_STARTED =
  'crm/customFieldCategories/CREATE_CUSTOM_FIELD_CATEGORY_STARTED'
export const CREATE_CUSTOM_FIELD_CATEGORY_SUCCESS =
  'crm/customFieldCategories/CREATE_CUSTOM_FIELD_CATEGORY_SUCCESS'
export const CREATE_CUSTOM_FIELD_CATEGORY_FAILED =
  'crm/customFieldCategories/CREATE_CUSTOM_FIELD_CATEGORY_FAILED'

export const UPDATE_CUSTOM_FIELD_CATEGORY =
  'crm/customFieldCategories/UPDATE_CUSTOM_FIELD_CATEGORY'
export const UPDATE_CUSTOM_FIELD_CATEGORY_STARTED =
  'crm/customFieldCategories/UPDATE_CUSTOM_FIELD_CATEGORY_STARTED'
export const UPDATE_CUSTOM_FIELD_CATEGORY_SUCCESS =
  'crm/customFieldCategories/UPDATE_CUSTOM_FIELD_CATEGORY_SUCCESS'
export const UPDATE_CUSTOM_FIELD_CATEGORY_FAILED =
  'crm/customFieldCategories/UPDATE_CUSTOM_FIELD_CATEGORY_FAILED'

export const DELETE_CUSTOM_FIELD_CATEGORY =
  'crm/customFieldCategories/DELETE_CUSTOM_FIELD_CATEGORY'
export const DELETE_CUSTOM_FIELD_CATEGORY_STARTED =
  'crm/customFieldCategories/DELETE_CUSTOM_FIELD_CATEGORY_STARTED'
export const DELETE_CUSTOM_FIELD_CATEGORY_SUCCESS =
  'crm/customFieldCategories/DELETE_CUSTOM_FIELD_CATEGORY_SUCCESS'
export const DELETE_CUSTOM_FIELD_CATEGORY_FAILED =
  'crm/customFieldCategories/DELETE_CUSTOM_FIELD_CATEGORY_FAILED'

export const CONTACT = 'CONTACT'
export const COMPANY = 'COMPANY'
