import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRequestByType } from 'ducks/requests/selectors'
import {
  doFetchSlackApp,
  doFetchSlackChannels,
  doFetchSlackProfiles,
} from './operations'
import {
  selectApp,
  selectAppRequestState,
  selectChannels,
  selectChannelsBySlackId,
  selectChannelsRequestState,
  selectProfiles,
  selectProfilesBySlackId,
  selectProfilesRequestState,
} from './selectors'
import { FETCH_SLACK_PROFILES } from './types'

export const useSlackApp = (options = {}) => {
  const refetch = !options.preferCached
  const dispatch = useDispatch()

  const appRequestState = useSelector(selectAppRequestState)
  const channelsRequestState = useSelector(selectChannelsRequestState)
  const profilesRequestState = useSelector(selectProfilesRequestState)

  const slackApp = useSelector(selectApp)
  const channels = useSelector(selectChannels)
  const channelsBySlackId = useSelector(selectChannelsBySlackId)
  const profiles = useSelector(selectProfiles)
  const profilesBySlackId = useSelector(selectProfilesBySlackId)

  useEffect(
    () => {
      if (appRequestState.untried || refetch) {
        dispatch(doFetchSlackApp())
      }
      if (channelsRequestState.untried || refetch) {
        dispatch(doFetchSlackChannels())
      }
      if (profilesRequestState.untried || refetch) {
        dispatch(doFetchSlackProfiles())
      }
    },
    [
      dispatch,
      appRequestState.untried,
      channelsRequestState.untried,
      profilesRequestState.untried,
      refetch,
    ]
  )

  return {
    slackApp,
    channels,
    channelsBySlackId,
    profiles,
    profilesBySlackId,
    isLoaded:
      appRequestState.loaded ||
      channelsRequestState.loaded ||
      profilesRequestState.loaded,
    isLoading:
      appRequestState.loading ||
      channelsRequestState.loading ||
      profilesRequestState.loading,
    hasError:
      appRequestState.error ||
      channelsRequestState.error ||
      profilesRequestState.error,
    isMissing:
      (appRequestState.loaded && !slackApp) ||
      (channelsRequestState.loaded && !channels) ||
      (profilesRequestState.loaded && !profiles),
  }
}

export const useSlackProfiles = () => {
  const dispatch = useDispatch()
  const [profiles, setProfiles] = useState(null)
  const requestState = useSelector(state =>
    selectRequestByType(state, FETCH_SLACK_PROFILES)
  )

  useEffect(
    () => {
      dispatch(doFetchSlackProfiles()).then(results => setProfiles(results))
    },
    [dispatch]
  )

  return {
    profiles,
    isLoading: requestState.loading,
    hasError: requestState.error,
    isMissing: requestState.loaded && !profiles,
  }
}
