const NOTIFICATION_PREFERENCES_FIELDS = `
  key
  value
  scopeId
  event
`

export const PREFERENCES_FIELDS = `
  id
  ${NOTIFICATION_PREFERENCES_FIELDS}
`

export const defaultNotificationPreferencesQuery = `
  query DefaultNotificationPreferencesQuery {
    me {
      defaultNotificationPreferences {
        ${NOTIFICATION_PREFERENCES_FIELDS}
      }
    }
  }
`

export const currentUserNotificationPreferencesQuery = `
  query CurrentUserNotificationPreferencesQuery($after: String) {
    me {
      notificationPreferences(after: $after) {
        nodes {
          id
          ${NOTIFICATION_PREFERENCES_FIELDS}
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
      }
    }
  }
`

export const getTwoFactorAuthenticationCodeQuery = `
  query TwoFactorAuthCode {
    me {
      twoFactorAuthCode {
        uri
        code
      }
    }
  }
`
