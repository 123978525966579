import { oauthTokenSelector } from 'selectors/app'

import { emptyArr } from 'util/arrays'

import integrationApi from '../../api'
import {
  FETCH_ISSUE_SEARCH_ERROR,
  FETCH_ISSUE_SEARCH_REQUEST,
  FETCH_ISSUE_SEARCH_SUCCESS,
} from '../types'

const KEY_REGEX = /^.+-\d+$/
export default function makeDoFetchJiraIssueSearch(prefix, getPath) {
  return function doFetchJiraIssueSearch(
    cloudId,
    query,
    projectId = null,
    subtask = null,
    type = null
  ) {
    return (dispatch, getState) => {
      const state = getState()
      const token = oauthTokenSelector(state)
      if (!query || query === '') {
        return dispatch({
          type: `${prefix}/${FETCH_ISSUE_SEARCH_SUCCESS}`,
          payload: { issues: emptyArr },
        })
      }
      dispatch({ type: `${prefix}/${FETCH_ISSUE_SEARCH_REQUEST}` })
      const keyQuery = query.match(KEY_REGEX) ? ` or key = "${query}"` : ''
      const projectQuery = projectId ? ` and project = "${projectId}"` : ''
      const subtaskQuery =
        subtask !== null
          ? ` and parent IS ${subtask === false ? 'NOT' : ''} EMPTY`
          : ''
      const typeQuery = type !== null ? `and type != "${type}"` : ''
      const sanitizedQuery = query.replace(/[()[\]'?.,+;|*/%^$#@]/g, '').trim()
      return integrationApi
        .post(
          token,
          getPath(cloudId, query),
          {},
          JSON.stringify({
            jql: `(text ~ "${sanitizedQuery}*"${keyQuery})${projectQuery}${subtaskQuery}${typeQuery}`,
            fields: [
              'key',
              'summary',
              'project',
              'components',
              'status',
              'assignee',
              'created',
              'duedate',
              'labels',
              'priority',
              'resolution',
              'issuetype',
              'updated',
              'site',
            ],
          })
        )
        .then(({ success, json } = {}) => {
          const { issues } = json || {}
          if (success) {
            dispatch({
              type: `${prefix}/${FETCH_ISSUE_SEARCH_SUCCESS}`,
              payload: { issues },
            })
          } else {
            dispatch({
              error: true,
              type: `${prefix}/${FETCH_ISSUE_SEARCH_ERROR}`,
              payload: json,
            })
          }
        })
        .catch(error => {
          dispatch({
            error: true,
            type: `${prefix}/${FETCH_ISSUE_SEARCH_ERROR}`,
            payload: error,
          })
        })
    }
  }
}
