import React from 'react'
import { css, keyframes } from '@emotion/react'
import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'
// import Loader from './loader.inline.svg'

// const loaderSvg = css`
//   height: 120px;
// `

const fadeIn = keyframes`
  0% { opacity: 0; }
  90% { opacity: 0; }
  100% { opacity: 1; }
`

const loadingContainer = css`
  text-align: center;
`

const loadingFadeIn = css`
  animation: 3s ease 0s normal forwards 1 ${fadeIn};
`

const loaderText = css`
  font-size: 16px;
  font-weight: 500;
`

export default function Loading({
  text = 'Just a moment...',
  disableFadeIn = false,
  className,
}) {
  return (
    <div
      id="subapp-loading"
      css={[loadingContainer, !disableFadeIn && loadingFadeIn]}
      className={className}
    >
      <div>
        {/* Will use Loader again once the new logo loader is done */}
        {/* <Loader css={loaderSvg} /> */}
        <Spinner size="medium" />
        {text && <div css={loaderText}>{text}</div>}
      </div>
    </div>
  )
}
