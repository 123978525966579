/* eslint-disable no-param-reassign */
import { emptyArr, withPush, without } from 'util/arrays'
import { emptyObj } from 'util/objects'
import { createActionTypeReducer } from 'util/reducers'

import {
  ROOMS_FETCH_STARTED,
  ROOMS_FETCH_SET_LOADING_IDS,
  ROOMS_FETCH_SUCCESS,
  ROOMS_FETCH_FAILED,
  ROOMS_BYID_FETCH_STARTED,
  ROOMS_BYID_FETCH_SUCCESS,
  ROOMS_BYID_FETCH_FAILED,
  ROOMS_BYIDS_FETCH_STARTED,
  ROOMS_BYIDS_FETCH_SUCCESS,
  ROOMS_BYIDS_FETCH_FAILED,
  ROOMS_UPDATED,
  ROOM_OPTIMISTIC_UPDATED,
  ROOM_OPTIMISTIC_COMPLETE,
  ROOM_OPTIMISTIC_FAILED,
  ROOM_DELETE_STARTED,
  ROOM_DELETE_FAILED,
} from '../actionTypes/rooms'

import { buildRoom, ensureUserIsMember, getRoomId } from '../utils/rooms'

const initialState = {
  byId: emptyObj,
  ids: emptyArr,
  loadingIds: emptyArr,
  loadingMatrixIds: emptyArr,
  updatingMatrixIds: emptyArr,
  errors: emptyObj,
}

export default createActionTypeReducer(
  {
    [ROOMS_FETCH_STARTED]: draftState => {
      withPush(draftState.loadingIds, '*')
      if (draftState.errors['*']) {
        draftState.errors['*'] = null
      }
      return draftState
    },

    [ROOMS_FETCH_SET_LOADING_IDS]: (draftState, { payload: { roomIds } }) => {
      roomIds.forEach(roomId => withPush(draftState.loadingMatrixIds, roomId))
    },

    [ROOMS_FETCH_SUCCESS]: (draftState, action) => {
      const {
        payload: { rooms },
      } = action
      rooms.forEach(room => {
        const roomId = getRoomId(room)
        if (ensureUserIsMember(room.roomId)) {
          const basicRoom = buildRoom(room, draftState.byId[roomId])
          // The matrix room id is a fully qualified room id including the homer
          // server host. This doesnt look great on the url, so we cut it down
          // to the ID only
          if (basicRoom) {
            draftState.byId[roomId] = basicRoom
            withPush(draftState.ids, roomId)
          }
        }
        without(draftState.loadingIds, roomId)
      })
      // Not ideal as a rooms_byid could also have an id in this array
      draftState.loadingMatrixIds = []
      without(draftState.loadingIds, '*')
      return draftState
    },

    [ROOMS_FETCH_FAILED]: (draftState, action) => {
      const {
        payload: { error },
      } = action
      draftState.loadingMatrixIds = []
      without(draftState.loadingIds, '*')
      draftState.errors['*'] = error
      return draftState
    },

    [ROOMS_BYID_FETCH_STARTED]: (draftState, action) => {
      const {
        payload: { id: roomId },
      } = action
      withPush(draftState.loadingIds, roomId)
      if (draftState.errors[roomId]) {
        draftState.errors[roomId] = null
      }
      return draftState
    },
    [ROOMS_BYID_FETCH_SUCCESS]: (draftState, action) => {
      const {
        payload: { room },
        meta: {
          requestParameters: { id: roomId },
        },
      } = action
      if (room) {
        if (ensureUserIsMember(room.roomId)) {
          const basicRoom = buildRoom(room, draftState.byId[roomId])
          // The matrix room id is a fully qualified room id including the homer
          // server host. This doesnt look great on the url, so we cut it down
          // to the ID only
          if (basicRoom) {
            draftState.byId[roomId] = basicRoom
            withPush(draftState.ids, roomId)
          }
        }
      }
      without(draftState.loadingIds, roomId)
      return draftState
    },
    [ROOMS_BYIDS_FETCH_STARTED]: (draftState, action) => {
      const {
        payload: { ids: roomIds },
      } = action
      roomIds.forEach(roomId => {
        withPush(draftState.loadingIds, roomId)
        if (draftState.errors[roomId]) {
          draftState.errors[roomId] = null
        }
      })
      return draftState
    },
    [ROOMS_BYIDS_FETCH_SUCCESS]: (draftState, action) => {
      const {
        payload: { rooms = [] },
        meta: {
          requestParameters: { ids: roomIds },
        },
      } = action

      rooms.forEach(room => {
        if (room) {
          const roomId = getRoomId(room)
          if (ensureUserIsMember(room.roomId)) {
            const basicRoom = buildRoom(room, draftState.byId[roomId])
            // The matrix room id is a fully qualified room id including the homer
            // server host. This doesnt look great on the url, so we cut it down
            // to the ID only
            if (basicRoom) {
              draftState.byId[roomId] = basicRoom
              withPush(draftState.ids, roomId)
            }
          }
          without(draftState.loadingIds, roomId)
        }
      })
      roomIds.forEach(roomId => without(draftState.loadingIds, roomId))
      draftState.loadingMatrixIds = []
      return draftState
    },
    [ROOMS_BYIDS_FETCH_FAILED]: (draftState, action) => {
      const {
        payload: { error },
        meta: {
          requestParameters: { ids: roomIds },
        },
      } = action
      draftState.loadingMatrixIds = []
      roomIds.forEach(roomId => without(draftState.loadingIds, roomId))
      draftState.errors['*'] = error
      return draftState
    },
    [ROOMS_UPDATED]: (draftState, action) => {
      const {
        payload: { roomUpdates, loadingMatrixIds },
      } = action
      if (!roomUpdates) return draftState
      const rooms = roomUpdates.map(roomUpdate => roomUpdate.room)
      rooms.forEach(room => {
        // If we're loading this room as part of a ROOMS_FETCH, then just ignore it for now
        // It'll get constructed and updated in state in the ROOMS_FETCH_SUCCESS method
        if (loadingMatrixIds.includes(room.roomId)) return
        const roomId = getRoomId(room)
        if (roomId && ensureUserIsMember(room.roomId)) {
          const basicRoom = buildRoom(room, draftState.byId[roomId])
          if (basicRoom) {
            draftState.byId[roomId] = basicRoom
          }
        }
      })
      return draftState
    },

    [ROOM_OPTIMISTIC_UPDATED]: (draftState, action) => {
      const {
        payload: { roomId, room },
      } = action
      if (draftState.byId[roomId]) {
        const { matrixRoomId } = room
        draftState.byId[roomId] = room
        withPush(draftState.updatingMatrixIds, matrixRoomId)
      }
      return draftState
    },

    [ROOM_OPTIMISTIC_COMPLETE]: (draftState, action) => {
      const {
        payload: {
          room: { matrixRoomId },
        },
      } = action
      without(draftState.updatingMatrixIds, matrixRoomId)
      return draftState
    },

    [ROOM_OPTIMISTIC_FAILED]: (draftState, action) => {
      const {
        payload: {
          room: { matrixRoomId },
        },
      } = action
      without(draftState.updatingMatrixIds, matrixRoomId)
      return draftState
    },

    [ROOMS_BYID_FETCH_FAILED]: (draftState, action) => {
      const {
        payload: { error },
        meta: {
          requestParameters: { roomId },
        },
      } = action
      without(draftState.loadingIds, roomId)
      draftState.errors[roomId] = error
      return draftState
    },

    [ROOM_DELETE_STARTED]: (draftState, action) => {
      const {
        payload: { conversationId },
      } = action
      without(draftState.loadingIds, conversationId)
      without(draftState.ids, conversationId)
      delete draftState.byId[conversationId]
      return draftState
    },

    [ROOM_DELETE_FAILED]: (draftState, action) => {
      const {
        payload: { error },
        meta: {
          requestParameters: { conversationId },
        },
      } = action
      without(draftState.loadingIds, conversationId)
      draftState.errors[conversationId] = error
      return draftState
    },
  },
  initialState
)
