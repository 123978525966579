import createCachedSelector from 're-reselect'

import selectTrelloCards from './selectTrelloCards'

const NO_LIST = 'NO_LIST'

export default createCachedSelector(
  selectTrelloCards,
  (_, listId) => listId,
  (cards, listId) => {
    if (!listId) return []
    return cards
      .filter(card => card.idList === listId)
      .sort(({ pos: posA }, { pos: posB }) => posA - posB)
      .map(({ id, name }) => ({
        text: name,
        value: id,
      }))
  }
)((_, listId) => listId || NO_LIST)
