import { createSelector } from 'reselect'
import { selectCurrentUserPrefersOpenFolderOnNoteAdd } from './selectCurrentUserPrefersOpenFolderOnNoteAdd'
import { selectPrefersClassicView } from './selectPrefersClassicView'

export const selectPrefersOpenFolderOnNoteAdd = createSelector(
  selectPrefersClassicView,
  selectCurrentUserPrefersOpenFolderOnNoteAdd,
  (classicView, openFolder) => {
    return classicView && openFolder
  }
)
