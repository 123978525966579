/* eslint-disable no-multi-assign */
import * as types from 'constants/action_types'
import * as pages from 'constants/pages'
import { FETCH_CURRENT_USER_SUCCESS } from 'ducks/currentUser/types'
import * as drafts2 from 'ducks/drafts2/constants'
import { EDITOR_PINNED } from 'ducks/flags/flagNames'

import storage from 'util/storage'

import {
  CHANGE_EDITOR_FOCUS,
  SET_EDITOR_MODE,
  UPDATE_EDITOR_VISIBILITY,
  UPDATE_EDITOR_PINNED,
} from './types'

const defaultState = {
  isFocused: false,
  isVisible: false,
  isPinned: false,
  mode: 'reply',
}

const reducers = {}

reducers[CHANGE_EDITOR_FOCUS] = (state, { payload: { isFocused } }) => {
  return {
    ...state,
    isFocused,
  }
}

reducers[SET_EDITOR_MODE] = (state, { payload: mode }) => {
  return {
    ...state,
    mode,
  }
}

reducers[UPDATE_EDITOR_VISIBILITY] = (state, action) => {
  return {
    ...state,
    isVisible: !!action.payload.isVisible,
  }
}

reducers[UPDATE_EDITOR_PINNED] = (state, action) => {
  const isPinned = !!action.payload.isPinned
  return {
    ...state,
    isPinned,
  }
}

reducers[types.UPDATE_APP_DATA] = reducers[FETCH_CURRENT_USER_SUCCESS] = (
  state,
  { data: { currentUser } }
) => {
  const isPinned = currentUser.flags?.includes(EDITOR_PINNED)
  return {
    ...state,
    // We had used storage for this prefrence before, need to respect it too
    isPinned: storage.get('editorPinned') ? true : isPinned,
  }
}

reducers[types.UNDO_SEND_REQUEST] = reducers[
  types.UNDO_SEND_SUCCESS
] = state => {
  return {
    ...state,
    isVisible: true,
  }
}

reducers[pages.TICKET_PAGE] = (state, action) => {
  if (action.meta?.fromDrawerAction) {
    return {
      ...state,
      mode: 'reply',
    }
  }

  return {
    ...state,
    mode: 'reply',
    isVisible: !!action?.payload?.replying,
  }
}

reducers[pages.TICKET_COMMENT_PAGE] = state => {
  if (state.isVisible) return state

  return {
    ...state,
    isVisible: true,
    mode: 'note',
  }
}

reducers[pages.TICKET_REPLY_PAGE] = reducers[
  pages.TICKET_REPLY_CHANGESET_PAGE
] = reducers[pages.TICKET_REPLY_DIRECT_PAGE] = reducers[
  pages.TICKET_REPLY_DIRECT_CHANGESET_PAGE
] = reducers[pages.TICKET_FORWARD_CHANGESET_PAGE] = reducers[
  pages.TICKET_FORWARD_PAGE
] = reducers[pages.TICKET_FORWARD_PAGE] = state => {
  return {
    ...state,
    isVisible: true,
  }
}

reducers[drafts2.DELETE_DRAFT] = state => {
  return {
    ...state,
    isVisible: false,
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
