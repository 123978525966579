import { combineReducers } from 'core/redux'

import assignableUsers from './assignableUsers'
import components from './components'
import issues from './issues'
import labels from './labels'
import priorities from './priorities'
import projects from './projects'
import searchedIssues from './searchedIssues'
import sites from './sites'
import types from './types'
import createIssueTypeFields from './createIssueTypeFields'

export default combineReducers({
  assignableUsers,
  components,
  issues,
  labels,
  priorities,
  projects,
  searchedIssues,
  sites,
  types,
  createIssueTypeFields,
})
