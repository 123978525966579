import React from 'react'
import cn from 'classnames'

import styles from './styles.css'

export default function CannedReply({
  category,
  name,
  snippet,
  isActive,
  onClick,
}) {
  return (
    <div
      className={cn(styles.reply, {
        [styles['reply-active']]: isActive,
      })}
      onClick={onClick}
    >
      <div className={styles.reply_category}>{category}</div>

      <div className={styles.reply_name}>{name}</div>
      {isActive && (
        <div className={cn('Icon Icon-keyboard-return', styles.returnIcon)} />
      )}
      <div className={styles.reply_snippet}>{snippet}</div>
    </div>
  )
}
