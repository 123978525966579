import createCachedSelector from 're-reselect'

import selectTrelloMembers from './selectTrelloMembers'

const NO_BOARD = 'NO_BOARD'

export default createCachedSelector(
  selectTrelloMembers,
  (_, boardId) => boardId,
  (members, boardId) => {
    if (!boardId) return []
    return members
      .filter(member => member.idBoards.includes(boardId))
      .sort(({ fullName }) => fullName)
      .map(({ id, fullName }) => ({
        text: fullName,
        value: id,
      }))
  }
)((_, boardId) => boardId || NO_BOARD)
