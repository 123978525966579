import createCachedSelector from 're-reselect'
import { selectPreferences } from './selectPreferences'

export const selectBooleanPreferenceByName = createCachedSelector(
  selectPreferences,
  (_state, name) => name,
  (_state, _name, defaultValue) => defaultValue,
  (preferences, name, defaultValue) => {
    const preference = preferences[name]
    return preference !== undefined ? !!preference : defaultValue
  }
)(
  (_state, name, defaultValue) =>
    `${name || 'unknown'}-${defaultValue || 'unknown'}`
)
