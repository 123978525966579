import graphql from 'api/graphql'
import { oauthTokenSelector } from 'selectors/app'
import { BootstrapLoader } from 'util/bootstrap'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import { selectCurrentUser } from 'ducks/currentUser/selectors/base'
import {
  selectCurrentFolderById,
  selectFolders,
} from 'ducks/folders/selectors/folders'
import { selectCurrentChannels } from 'ducks/channels/selectors'
import { selectGroupsFilteredByCurrentUserGroupsAccess } from 'selectors/app/groups_filtered'
import { collectionQuery as collectionQueryV1 } from 'api/folders'
import { foldersByIdSelector } from 'selectors/folders/foldersByIdSelector'
import { reverseHashInt, hash } from 'util/scatterSwap'
import { doGraphqlRequest, doApiWriteRequest } from 'ducks/requests/operations'
import { buildId, getRawId } from 'util/globalId'
import { selectMailboxesIncludingInaccessible } from 'selectors/mailboxes/selectMailboxesIncludingInaccessible'
import {
  FETCH_FOLDERS_REQUEST,
  FETCH_FOLDERS_SUCCESS,
  FETCH_FOLDERS_FAIL,
  SET_CURRENT_FOLDER,
  FETCH_FOLDERS,
  FETCH_FOLDERS_V2,
  FETCH_FOLDERS_BY_IDS_V2,
  DELETE_FOLDER,
  UPDATE_FOLDER,
  CREATE_FOLDER,
  FETCH_FOLDER_CHANNELS,
} from '../actionTypes/folders'
import {
  dataTableQuery,
  getByIdsQuery,
  getChannelsQuery,
} from '../queries/folders/queries.v2'
import {
  folderGraphQlResponseSchema,
  folderGraphQlV2ResponseSchema,
} from '../schema/folders'

const decodeGlobalId = gid => {
  if (!gid) return null
  return reverseHashInt(gid.split('_').pop())
}

export function doSetCurrentFolder({ folderId }) {
  return {
    type: SET_CURRENT_FOLDER,
    payload: {
      folderId,
    },
  }
}

function transformAttachCurrentUser(data, { getState }) {
  const currentUser = selectCurrentUser(getState())
  return {
    ...data,
    currentUser,
  }
}

function calculateFolderAccess(data, { getState }) {
  const state = getState()
  const currentUser = selectCurrentUser(state)
  const totalChannels = selectMailboxesIncludingInaccessible(state).length
  const groupsWithCurrentUserAccess = selectGroupsFilteredByCurrentUserGroupsAccess(
    state
  )
  const {
    agents: { nodes: agents },
    folders: { nodes: folders },
  } = data
  const totalAgents = agents.length

  folders.forEach(folder => {
    const {
      channels: { totalCount: totalFolderChannelCount },
      teams: { nodes: folderTeams },
      agents: { nodes: folderAgents },
    } = folder

    const linkType =
      folderTeams.length > 0 && folderAgents.length === 0 ? 'team' : 'agent'

    const linkedAllAgents = totalAgents === folderAgents.length
    let viewAccess
    if (linkType === 'team') {
      viewAccess = 'selected-teams'
    } else {
      viewAccess = linkedAllAgents ? 'all-agents' : 'selected-agents'
    }

    const currentUserGlobalId = buildId('Agent', currentUser.id)
    if (folderAgents.length === 1) {
      const id = folderAgents[0].id
      if (id === currentUserGlobalId) viewAccess = 'me'
    }

    let hasAccess
    if (linkType === 'agent') {
      hasAccess = folderAgents.some(fa => fa.id === currentUserGlobalId)
    } else {
      hasAccess = folderTeams.some(ft =>
        groupsWithCurrentUserAccess.some(g => g.id === getRawId(ft.id))
      )
    }

    Object.assign(folder, {
      channelVisibility:
        totalChannels === totalFolderChannelCount ? 'all' : 'selected',
      viewAccess,
      hasAccess,
    })
  })

  Object.assign(data, {
    currentUser,
  })

  return data
}

const LOAD_ALL_FOLDERS_QUERYID = 'entityType:folder pageSize:10000'
export const doFetchFolders = ({ skipLoaded }) => (dispatch, getState) => {
  const queryId = LOAD_ALL_FOLDERS_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]

  const hasLoaded = loaded && hasCurrentPage

  // Old way of loading folders
  // if (skipLoaded && folders && !isEmpty(folders)) {
  //   return Promise.resolve(folders)
  // }

  if (hasLoaded && skipLoaded) {
    const folders = foldersByIdSelector(state)
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve(folders)
  }

  return dispatch(
    doGraphqlRequest(
      FETCH_FOLDERS,
      collectionQueryV1(),
      {},
      {
        normalizationSchema: folderGraphQlResponseSchema,
        searches: {
          queryId,
          cursor,
          extractPagination: 'apiv1',
        },
        includeLegacyPayload: true,
        transformResponse: transformAttachCurrentUser,
      }
    )
  )
}

export const doFetchFoldersForDataTable = () => dispatch => {
  const queryId = LOAD_ALL_FOLDERS_QUERYID
  const cursor = 'all'

  return dispatch(
    doGraphqlRequest(
      FETCH_FOLDERS_V2,
      dataTableQuery(),
      {
        size: 1000,
      },
      {
        app: true,
        normalizationSchema: folderGraphQlV2ResponseSchema,
        searches: {
          queryId,
          cursor,
          extractPagination: 'gqlv2',
        },
        transformResponse: calculateFolderAccess,
      }
    )
  )
}

export const doFetchFoldersInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_FOLDERS_QUERYID,
  entityType: 'folder',
  doLoadAllFn: doFetchFoldersForDataTable,
})

export function createFetchFoldersRequestAction() {
  return { type: FETCH_FOLDERS_REQUEST }
}

export function createFetchFoldersSuccessAction(res, { getState }) {
  const state = getState()
  const currentUser = selectCurrentUser(state)
  const data = res.json.data
  return { type: FETCH_FOLDERS_SUCCESS, data: { ...data, currentUser } }
}

export function createFetchFoldersFailAction(error) {
  return { type: FETCH_FOLDERS_FAIL, data: { err: error } }
}

export function createFetchFoldersLoader() {
  return new BootstrapLoader(
    'folders',
    createFetchFoldersRequestAction,
    createFetchFoldersSuccessAction,
    createFetchFoldersFailAction,
    getState => {
      return graphql(oauthTokenSelector(getState()), collectionQueryV1())
    }
  )
}
// eslint-disable-next-line no-unused-vars
export const doDeleteSingleFolder = (id, options = {}) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      DELETE_FOLDER,
      `api/settings/folders/${id}`,
      {},
      {
        method: 'DELETE',
        optimist: {},
        normalizationSchema: folderGraphQlResponseSchema,
        moduleOptions: {
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                // Because we're passing through a global id here, we need to manually delete
                // the entity from the store
                entityType: 'folder',
                entityId: id,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['SUCCESS'],
              },
            ],
          },
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: `${app.t('Folder')} deletion complete`,
            },
            failed: {
              content: `${app.t('Folder')} deletion failed`,
              onClickAction: () => {
                dispatch(doDeleteSingleFolder(id, options))
              },
            },
          },
        },
      }
    )
  )
}

/* eslint-disable camelcase */
function transformPayloadFromV0toV2(data) {
  const {
    id,
    row_order,
    name,
    match_type,
    active,
    user_ids,
    mailbox_ids,
    group_ids,
    locked,
    filter_conditions_attributes,
  } = data

  const payload = {
    id: buildId('Folder', hash(id)),
    name,
    matchType: match_type.toUpperCase(),
    state: active ? 'ACTIVE' : 'INACTIVE',
    position: row_order,
    agents: {
      nodes: user_ids.map(uid => {
        return {
          id: buildId('Agent', hash(uid)),
        }
      }),
    },
    teams: {
      nodes: group_ids.map(grid => {
        return {
          id: buildId('Team', hash(grid)),
        }
      }),
    },
    channels: {
      nodes: mailbox_ids.map(mid => {
        return {
          id: buildId('Channel', hash(mid)),
        }
      }),
    },
    conditions: {
      nodes: filter_conditions_attributes.map(fca => {
        const { id: fcaId, operator, param, value } = fca || {}

        return {
          id: fcaId ? buildId('Condition', hash(fcaId)) : null,
          operator: operator ? operator.toUpperCase() : null,
          param: param ? param.toUpperCase() : null,
          value,
        }
      }),
    },
    locked,
    displayCountWhenInactive: !data.hide_inactive_count,
    hideIfZeroConversations: data.hide_if_zero_conversations,
    type: 'TICKET',
  }

  // make it compatible with folderGraphQlResponseSchema
  return { folders: [payload] }
}
/* eslint-enable camelcase */

const statusMap = {
  unread: 1000,
  opened: 2000,
  closed: 5000,
  spam: 6000,
}

const priorityMap = {
  low: 1000,
  medium: 2000,
  high: 3000,
  urgent: 4000,
}

/* eslint-disable camelcase */
function fixupValue(type, value) {
  if (type === 'SNOOZE_STATE') {
    return value > 0 ? decodeGlobalId(value) : value
  } else if (type === 'ASSIGNED_AGENT') {
    return value > 0 ? decodeGlobalId(value) : value
  } else if (type === 'ASSIGNED_GROUP') {
    return value > 0 ? decodeGlobalId(value) : value
  } else if (type === 'DRAFTS') {
    return value > 0 ? decodeGlobalId(value) : value
  } else if (type === 'STATUS') {
    return statusMap[value]
  } else if (type === 'PRIORITY') {
    return priorityMap[value]
  } else if (type === 'STARRED') {
    return value === 'true' ? 4000 : 1000
  }
  return value
}

function transformPayloadFromV2toV0(
  folder,
  currentUser,
  currentFolder,
  channels,
  { updatePosition } = {}
) {
  let mailbox_ids = []
  let user_ids = []
  let group_ids = []
  const viewAccess = folder.viewAccess
  const channelVisibility = folder.channelVisibility

  if (viewAccess === 'selected-agents') {
    if (folder.agentIds) {
      user_ids = folder.agentIds.map(id => decodeGlobalId(id))
    } else if (folder.agents) {
      user_ids = Object.values(folder.agents).map(a => decodeGlobalId(a.id))
    }
  } else if (viewAccess === 'selected-teams') {
    if (folder.groupIds) {
      group_ids = folder.groupIds.map(id => decodeGlobalId(id))
    } else if (folder.teams) {
      group_ids = Object.values(folder.teams).map(t => decodeGlobalId(t.id))
    }
  } else if (viewAccess === 'me') {
    user_ids = [decodeGlobalId(currentUser.id)]
  }
  if (channelVisibility === 'selected') {
    if (folder.channelIds) {
      mailbox_ids = folder.channelIds.map(id => decodeGlobalId(id))
    } else if (folder.channels) {
      mailbox_ids = Object.values(folder.channels).map(({ id }) =>
        decodeGlobalId(id)
      )
    }
    // If they've selected all channels, then rather implemented the All inboxes
    // permissions rather than the specific inboxes permissions with all mailboxes
    // selected
    if (mailbox_ids.length === channels.length) {
      mailbox_ids = []
    }
  }
  const conditions = []
  const currentConditionsIds = {}
  if (currentFolder) {
    // eslint-disable-next-line no-underscore-dangle
    currentFolder.conditions.forEach(c => (currentConditionsIds[c.id] = true))
  }
  folder.conditions.forEach(cond => {
    // eslint-disable-next-line no-underscore-dangle
    delete currentConditionsIds[cond.id]
    let param = cond.param.toLowerCase()
    if (param === 'tags') param = 'labels'
    conditions.push({
      // eslint-disable-next-line no-underscore-dangle
      id: decodeGlobalId(cond.id),
      param,
      operator: cond.operator.toLowerCase(),
      value: fixupValue(cond.param, cond.value),
    })
  })
  // API V0 expects deletes to be sent a an array of objects with
  // id and _delete=1
  Object.keys(currentConditionsIds).forEach(id => {
    conditions.push({
      // eslint-disable-next-line no-underscore-dangle
      _destroy: 'true',
      id: decodeGlobalId(id),
    })
  })

  const data = {
    name: folder.name,
    hide_inactive_count: !folder.displayCountWhenInactive,
    hide_if_zero_conversations: folder.hideIfZeroConversations,
    active: folder.state === 'ACTIVE',
    match_type: folder.matchType === 'ALL' ? 'all' : 'any',
    mailbox_ids,
    user_ids,
    group_ids,
    filter_conditions_attributes: conditions,
  }
  if (updatePosition) {
    data.row_order_position = folder.position
  }
  return data
}
/* eslint-enable camelcase */

// eslint-disable-next-line no-unused-vars
export const doCreateSingleFolder = (folder, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const currentUser = selectCurrentUser(state)
  const allChannels = selectCurrentChannels(state)

  const data = transformPayloadFromV2toV0(
    folder,
    currentUser,
    null,
    allChannels
  )

  const entityType = 'folder'

  const payload = {
    folder: data,
  }

  return dispatch(
    doApiWriteRequest(CREATE_FOLDER, `api/settings/folders`, payload, {
      method: 'POST',
      normalizationSchema: folderGraphQlResponseSchema,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: [entityType],
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: `${app.t('Folder')} created`,
          },
          failed: {
            content: `${app.t('Folder')} create failed`,
            onClickAction: () => doCreateSingleFolder(folder, options),
          },
        },
      },
      transformResponse: transformPayloadFromV0toV2,
    })
  )
}

function calculateFolderPosition(folder, currentOrderedFolders) {
  if (currentOrderedFolders[folder.position + 1]) {
    return currentOrderedFolders[folder.position + 1].position - 10
  } else if (currentOrderedFolders[folder.position - 1]) {
    return currentOrderedFolders[folder.position - 1].position + 10
  }
  return folder.position
}

// eslint-disable-next-line no-unused-vars
export const doUpdateSingleFolder = (folder, options = {}) => (
  dispatch,
  getState
) => {
  const { updatePosition = false, queryId, orderedIds = null } = options
  const id = folder.id

  const state = getState()

  const orderedFolders = selectFolders(state)
  const currentUser = selectCurrentUser(state)
  const currentFolder = selectCurrentFolderById(state, id)
  const allChannels = selectCurrentChannels(state)

  const data = transformPayloadFromV2toV0(
    folder,
    currentUser,
    currentFolder,
    allChannels,
    options
  )

  const payload = {
    folder: data,
  }
  const entityId = folder.id
  const entityType = 'folder'
  const optimisticFolder = {
    ...folder,
  }
  if (updatePosition) {
    optimisticFolder.position = calculateFolderPosition(folder, orderedFolders)
  }

  const optimist = {
    entities: {
      [entityType]: {
        [entityId]: optimisticFolder,
      },
    },
  }

  return dispatch(
    doApiWriteRequest(
      UPDATE_FOLDER,
      `api/settings/folders/${folder.id}`,
      payload,
      {
        method: 'PUT',
        optimist,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [entityType],
              phases: ['SUCCESS'],
            },
            {
              type: 'UPDATE_CURSOR',
              queryId,
              entityIds: orderedIds,
              phases: ['STARTED'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: !updatePosition,
              content: `${app.t('Folder')} updated`,
            },
            failed: {
              content: `${app.t('Folder')} update failed`,
              onClickAction: () => doUpdateSingleFolder(folder, options),
            },
          },
          entities: {
            additionalActions: [
              {
                // For updates
                // Clear pending entity after successful entity update
                entityType,
                entityId: id,
                stores: ['pending'],
                operation: 'remove',
                phases: ['SUCCESS'],
              },
            ],
          },
        },
        normalizationSchema: folderGraphQlResponseSchema,
        transformResponse: transformPayloadFromV0toV2,
      }
    )
  )
}

const fetchFolderChannels = async props => {
  const { data, variables, cursor, dispatch } = props
  const targetFolder = data.folders?.nodes[0]
  if (!targetFolder) return data
  const { nodes, pageInfo } = targetFolder.channels
  const { hasNextPage, endCursor } = pageInfo
  if (hasNextPage) {
    const moreData = await dispatch(
      doGraphqlRequest(
        FETCH_FOLDER_CHANNELS,
        getChannelsQuery(),
        {
          ...variables,
          channelsCursor: endCursor,
        },
        {
          app: true,
          searches: {
            cursor,
            extractPagination: 'gqlv2',
          },
        }
      )
    )
    targetFolder.channels.pageInfo = moreData.folders.nodes[0].channels.pageInfo
    if (moreData.folders.nodes[0].channels.nodes.length) {
      nodes.push(...moreData.folders.nodes[0].channels.nodes)
      await fetchFolderChannels(props)
    }
  }
  return data
}

export const doFetchFolderByIdsV2 = (ids, options = {}) => dispatch => {
  // targetStore: optional, defaults to changeEntity()'s default store otherwise
  const { targetStore, isUpdate } = options

  const missingEntityActions = ids.map(id => {
    return {
      entityType: 'cannedReply',
      entityId: id,
    }
  })

  const variables = {
    filter: { ids, state: 'ALL', scope: 'ALL' },
  }

  const cursor = 'all'
  return dispatch(
    doGraphqlRequest(FETCH_FOLDERS_BY_IDS_V2, getByIdsQuery(), variables, {
      app: true,
      normalizationSchema: folderGraphQlV2ResponseSchema,
      searches: {
        cursor,
        extractPagination: 'gqlv2',
      },
      transformResponse: async (data, ...rest) => {
        let result = data
        // Fetch all channels for folder edit
        if (ids.length === 1 && isUpdate) {
          result = await fetchFolderChannels({
            data,
            variables,
            cursor,
            dispatch,
          })
        }

        return calculateFolderAccess(result, ...rest)
      },
      moduleOptions: {
        entities: {
          targetStore,
          missingEntityActions,
        },
      },
    })
  )
}

export const doDeleteFolders = (ids, options = {}) => dispatch => {
  // Promise.all is not technically required in the current implementation because
  // we'll only step into this block if the ids array has a single id, but I do
  // want to leave the door open for us to say that deleting less than X rows uses
  // single deletes instead of batch deletes
  return Promise.all(ids.map(id => dispatch(doDeleteSingleFolder(id, options))))
}

export const createFetchFolders = {
  createFetchFoldersRequestAction,
  createFetchFoldersSuccessAction,
  createFetchFoldersFailAction,
  createFetchFoldersLoader,
}
