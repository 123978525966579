import { selectRequestByType } from 'ducks/requests/selectors'
import { FETCH_ACCOUNT_PREFERENCE, FETCH_ACCOUNT_PREFERENCES } from '../types'

export const selectIsLoadingAccountPreferences = state =>
  selectRequestByType(state, FETCH_ACCOUNT_PREFERENCES).loading

export const selectHasLoadedAccountPreferences = state =>
  selectRequestByType(state, FETCH_ACCOUNT_PREFERENCES).loaded

export const selectHasErrorAccountPreferences = state =>
  selectRequestByType(state, FETCH_ACCOUNT_PREFERENCES).error

export const selectIsLoadingAccountPreference = state =>
  selectRequestByType(state, FETCH_ACCOUNT_PREFERENCE).loading

export const selectHasLoadedAccountPreference = state =>
  selectRequestByType(state, FETCH_ACCOUNT_PREFERENCE).loaded

export const selectHasErrorAccountPreference = state =>
  selectRequestByType(state, FETCH_ACCOUNT_PREFERENCE).error
