/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import {
  queryIdToQuery,
  queryStringToQueryId,
  constructFolderItemQueryId,
} from 'ducks/searches/utils/query'
import { buildId } from 'util/globalId'
import { withToggle } from 'util/arrays'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'
import {
  SET_COLLECTIONS_BYID,
  TOGGLE_COLLECTION,
  OPEN_ONE_COLLECTION,
  BUILD_FROM_WIDGETS,
} from '../actionTypes/collections'
import {
  mapWidgetChannelTypeToPageType,
  mapPageTypeToWidgetChannelType,
} from '../utils'

// Example of how the collections look in state
/*
{
  fc0: {
    id: 'fc0',
    type: 'pinned',
    name: 'Pinned',
    position: 1,
    queryId: 'inbox:4517239960',
    color: '#e5bb3e',
    hideName: false,
    icon: null,
    linkTo: null,
  }
}
*/

const collectionsByIdInitialState = {}

function buildCollectionQueryId(widget, folder, orderBy) {
  return constructFolderItemQueryId({ widget, folder, orderBy })
}

export const collectionsById = createActionTypeReducer(
  {
    [SET_COLLECTIONS_BYID]: (_, { payload: { collections = [] } }) => {
      return collections.reduce((collectionById, collection) => {
        collectionById[collection.id] = collection
        return collectionById
      }, {})
    },
    [BUILD_FROM_WIDGETS]: (
      draftState,
      {
        payload: {
          widgets = [],
          folders = [],
          prefersAllMailboxesSectionVisible = false,
          prefersUnifiedInbox = false,
          channelType: pageChannelType,
          orderBy,
        },
      }
    ) => {
      const validIds = []
      const hasAccessToAtleastOneWidget = widgets.some(w => w.hasAccess)

      const allowedWidgets = prefersUnifiedInbox
        ? []
        : [...widgets.filter(w => w.hasAccess)]
      const allowedWidgetsByPageType = allowedWidgets.filter(
        w => w.channelType === pageChannelType
      )

      if (
        hasAccessToAtleastOneWidget &&
        (prefersUnifiedInbox ||
          (prefersAllMailboxesSectionVisible &&
            allowedWidgetsByPageType.length > 1))
      ) {
        allowedWidgets.unshift({
          id: '',
          name: pageChannelType === 'widget' ? 'All widgets' : 'All social',
          hasAccess: true,
          channelType: pageChannelType,
        })
      }

      allowedWidgets.forEach((widget, index) => {
        const {
          id: widgetId,
          name,
          channelColor,
          kind: widgetKind,
          channelType,
        } = widget
        const currentPosition = index + 1
        const id = buildId('Widget', widgetId || pageChannelType)
        validIds.push(id)
        const collectionQueryId = buildCollectionQueryId(
          {
            id: widgetId,
            channelType,
          },
          folders[0],
          orderBy
        )
        const queryId = widgetId ? `widget:${widgetId}` : ''
        if (!draftState[id]) {
          draftState[id] = {}
        }
        Object.assign(draftState[id], {
          id,
          widgetId,
          widgetKind,
          type: channelType,
          queryId,
          name,
          position: currentPosition,
          color: channelColor || 'var(--color-monochrome-dark)',
          hideName: false,
          icon: false,
          linkTo: {
            type: mapWidgetChannelTypeToPageType(channelType),
            payload: {},
            meta: {
              query: queryIdToQuery(collectionQueryId),
            },
          },
        })
      })
      Object.keys(draftState).forEach(id => {
        if (!validIds.includes(id)) {
          delete draftState[id]
        }
      })
      return draftState
    },
  },
  collectionsByIdInitialState
)

const openCollectionIdsInitialState = []

const currentOpenCollectionIdromQueryReducer = (draftState, action) => {
  // https://github.com/faceyspacey/redux-first-router/blob/master/docs/query-strings.md#where-query-lives-on-your-actions
  const {
    type,
    meta: { location: { current: { query = {} } = {} } = {} } = {},
  } =
    action || {}

  const {
    channelId = `wid_${mapPageTypeToWidgetChannelType(type)}`,
    folderId,
  } =
    queryIdToQuery(queryStringToQueryId(query)) || {}

  if (channelId || folderId) {
    return [channelId]
  }
  return draftState
}

export const openCollectionIds = createActionTypeReducer(
  {
    [CHAT_PAGE]: currentOpenCollectionIdromQueryReducer,
    [SOCIAL_PAGE]: currentOpenCollectionIdromQueryReducer,
    [TOGGLE_COLLECTION]: (draftState, { payload: { collectionId } }) => {
      withToggle(draftState, collectionId)
    },
    [OPEN_ONE_COLLECTION]: (_, { payload: { collectionId } }) => {
      return [collectionId]
    },
  },
  openCollectionIdsInitialState
)
