import { PAGE_CONTACTS } from '../types'

import { selectPageAfterCursor, selectPageBeforeCursor } from '../selectors'

export function doNextPage() {
  return (dispatch, getState) => {
    const afterCursor = selectPageAfterCursor(getState())
    dispatch(doPage(null, afterCursor))
  }
}

export function doPreviousPage() {
  return (dispatch, getState) => {
    const before = selectPageBeforeCursor(getState())
    dispatch(doPage(before))
  }
}

export function doPage(before, after) {
  return {
    type: PAGE_CONTACTS,
    payload: { before, after },
  }
}
