import { v4 as uuidV4 } from 'uuid'
import { CREATE_DRAFT } from 'ducks/drafts2/constants'
import {
  buildDraftDefaults,
  buildNewConversationDraftDefaults,
  buildSharedDraftActionMetadata,
} from 'ducks/drafts2/util'
import { selectRawTicket } from 'selectors/tickets/byId/selectRawTicket'
import { selectChangesetsForTicketId } from 'selectors/currentChangesets'
import { doSendDraftToServer } from './doSendDraftToServer'

export function doCreateDraft(ticketId, draftType, draftFields) {
  return (dispatch, getState) => {
    const state = getState()
    let draftDefaults
    if (ticketId === 'new') {
      draftDefaults = buildNewConversationDraftDefaults(draftType)
    } else {
      const ticket = selectRawTicket(state, ticketId)
      // (Cindy) When creating a new reply draft, the recipient is removed due to a bug (https://trello.com/c/H3CqnhQh)
      // This happens because the draftDefaults.to property is empty, even though the recipients field has a value.
      // The recipients field is populated by calling the selectDraftPropOrDefault selector,
      // which invokes the buildDraftDefaults function if the draftDefaults property is null.
      // The buildDraftDefaults function uses the buildRecipientsFromAction function to return the “to” property,
      // which might be buggy and return an empty value when the ticket is fetched.
      // However, I cannot reproduce this bug.
      // Therefore, we need to apply the same logic here as in the selectDraftPropOrDefault function.
      const ticketChangesets = selectChangesetsForTicketId(state, ticketId)
      const rebuiltDraftDefaults = buildDraftDefaults(
        state,
        ticket,
        ticketChangesets
      )

      draftDefaults =
        draftType === 'note'
          ? ticket.noteDefaults || rebuiltDraftDefaults
          : ticket.draftDefaults || rebuiltDraftDefaults
    }

    const fields = {
      ...draftDefaults,
      ...draftFields,
      draftType,
    }
    const isOnlyUpdatingRecipients =
      'to' in draftFields && Object.keys(draftFields).length === 1

    if (draftDefaults) {
      fields.defaultsSet = true
      fields.defaultsSetOnCreate = true
    }
    // catch a case when defaults don't set proper "to"
    // except when the "to" field is emptied by the user
    if (
      !isOnlyUpdatingRecipients &&
      fields.draftType === 'reply' &&
      (!fields.to || fields.to.length === 0)
    ) {
      delete fields.defaultsSet
      delete fields.defaultsSetOnCreate
      fields.defaultsAbortedOnCreate = true
    }
    const draftId = uuidV4()

    dispatch({
      type: CREATE_DRAFT,
      payload: { draftId, ticketId, draftType, fields },
      meta: {
        ...buildSharedDraftActionMetadata(state),
      },
    })
    dispatch(doSendDraftToServer(draftId, ticketId))
  }
}
