import { selectCurrentEntitiesDenormalizedByType } from 'ducks/entities/selectors'
import { createSelector } from 'reselect'
import { sortByKey } from 'util/arrays/sorting'

export const selectPricings = createSelector(
  state => selectCurrentEntitiesDenormalizedByType(state, 'pricing'),
  pricings => {
    return sortByKey(sortByKey(pricings, 'version'), 'monthly')
  }
)
