import { doGraphqlRequest } from 'ducks/requests/operations'
import { wait } from 'util/promises'
import { selectIsLoadedForQuery } from 'ducks/integrations/shopify/selectors'
import graphql from '../api'
import * as types from '../types'

const MAX_RETRY_COUNT = 5

const searchProductsQuery = `
  query ShopifySearchProducts(
    $query: String!
  ) {
    products(first: 5, query: $query) {
      edges {
        node {
          id
          totalVariants
          title
          featuredImage {
            url
          }
          hasOnlyDefaultVariant
          variants(first: 25) {
            edges {
              node {
                id
                displayName
                image {
                  url
                }
                inventoryQuantity
                price
                sku
              }
            }
          }
        }
      }
    }
  }
`

const doSearchProducts = (integrationId, query, options) => async (
  dispatch,
  getState
) => {
  const { skipLoaded = true } = options || {}
  let callCount = 0
  const state = getState()
  const isQueryLoaded = selectIsLoadedForQuery(state, integrationId, { query })

  if (skipLoaded && isQueryLoaded) {
    return Promise.resolve({})
  }

  while (callCount < MAX_RETRY_COUNT) {
    callCount += 1

    try {
      // eslint-disable-next-line no-await-in-loop
      const response = await dispatch(
        doGraphqlRequest(
          types.SEARCH_PRODUCTS_REQUEST,
          searchProductsQuery,
          {
            query,
          },
          {
            graphqlFunc: graphql(integrationId),
            meta: {
              integrationId,
            },
            throwOnError: true,
          }
        )
      )

      return response
    } catch (e) {
      // eslint-disable-next-line no-await-in-loop
      await wait(1000)
    }
  }

  return null
}

export default doSearchProducts
