import { createSelector } from 'reselect'
import { selectCurrentUserPrefersOpenFolderOnTicketReply } from './selectCurrentUserPrefersOpenFolderOnTicketReply'
import { selectPrefersClassicView } from './selectPrefersClassicView'

export const selectPrefersOpenFolderOnTicketReply = createSelector(
  selectPrefersClassicView,
  selectCurrentUserPrefersOpenFolderOnTicketReply,
  (classicView, openFolder) => {
    return classicView && openFolder
  }
)
