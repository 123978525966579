import { doAppGraphqlRequest } from 'ducks/requests/operations'

import { SEARCH_COMPANIES } from '../types'

export function doSearch(term) {
  return {
    type: SEARCH_COMPANIES,
    payload: { term },
  }
}

const searchCompaniesQuery = `
  query searchCompanies($filter: CompanyFilter) {
    companies(filter: $filter) {
      nodes {
        id
        domain
        logoUrl
        name
      }
    }
  }
`

export function doSearchCompanies(queryString) {
  return dispatch => {
    return dispatch(
      doAppGraphqlRequest(
        SEARCH_COMPANIES,
        searchCompaniesQuery,
        { filter: { search: queryString } },
        {
          meta: {
            requestKey: queryString,
          },
        }
      )
    )
  }
}
