import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const footer = theme => css`
  display: flex;
  justify-content: space-between;
  padding: ${rem(14)} ${rem(16)};
  border-top: 1px solid ${theme.color.monochrome.medium};
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background-color: ${theme.color.monochrome.white};

  button {
    color: ${theme.color.brand['900']};
    cursor: pointer;
    font-size: ${theme.fontSize.base};
    font-weight: ${theme.fontWeight.medium};
    display: flex;
    align-items: center;

    svg {
      width: ${rem(11)};
      height: ${rem(11)};
      margin-right: ${rem(3)};
    }

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      svg {
        width: ${rem(19)};
        height: ${rem(19)};
      }
    }
  }
`

export const styles = {
  footer,
}
