import { combineReducers } from 'core/redux'

import byMatrixId from './byMatrixId'
import isLoading from './isLoading'
import settings from './settings'

export default combineReducers({
  byMatrixId,
  isLoading,
  settings,
})
