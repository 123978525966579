import React, { PureComponent } from 'react'
import { propFunc } from 'util/functions'
import CannedReply from '../CannedReply'
import SearchInput from '../SearchInput'

export default class SearchResults extends PureComponent {
  onEnter = () => {
    const { replies, onSelectReply } = this.props
    onSelectReply(replies[0])
  }

  onClick = reply => {
    const { onSelectReply } = this.props
    onSelectReply(reply)
  }

  render() {
    const { replies } = this.props
    return (
      <div>
        <SearchInput onEnter={this.onEnter} />
        {replies.map((reply, index) => (
          <CannedReply
            key={`template-${reply.id}`}
            isActive={index === 0}
            category={reply.category ? reply.category.name : 'Uncategorized'}
            name={reply.name}
            snippet={reply.snippet}
            onClick={propFunc(this.onClick, reply)}
          />
        ))}
      </div>
    )
  }
}
