export const changeContactMutation = `
mutation changeContactMutation($conversationId: ID!, $contactId: ID!) {
  conversationChangeContact(input: { conversationId: $conversationId, contactId: $contactId }) {
    conversation {
      id
      number
      contact {
        id
      }
    }
  }
}
`
