import { doGraphqlRequest } from 'ducks/requests/operations'
import { isEmpty } from 'util/objects'
import doCreateShopifyEvent from './doCreateShopifyEvent'
import graphql from '../api'
import * as types from '../types'
import { orderPartial } from './partials'

const mutation = `
  mutation refundCreate($input: RefundInput!) {
    refundCreate(input: $input) {
      order {
        ${orderPartial}
      }
      refund {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`

const doOrderRefund = (integrationId, orderId, payload) => async dispatch => {
  const {
    conversationId,
    currencyCode,
    note,
    notify,
    lineItems,
    shippingAmount,
    refundAmount,
    suggestedTransactions,
  } = payload

  const suggestedRefundTransaction = (suggestedTransactions || []).find(
    x => x?.kind === 'SUGGESTED_REFUND'
  )

  if (!suggestedRefundTransaction) {
    return Promise.reject('SUGGESTED_REFUND Transaction not found')
  }

  const variables = {
    input: {
      currency: currencyCode,
      note,
      notify,
      orderId,
      refundLineItems: lineItems
        .map(item => {
          if (!item?.suggestedRefund || isEmpty(item?.suggestedRefund)) {
            return null
          }

          const {
            id,
            suggestedRefund: { location, quantity, restockType },
          } = item

          return {
            lineItemId: id,
            locationId: location?.id || null,
            quantity,
            restockType,
          }
        })
        .filter(Boolean),
      shipping: {
        amount: shippingAmount,
        fullRefund: false,
      },
      transactions: [
        {
          amount: refundAmount,
          gateway: suggestedRefundTransaction.gateway,
          // https://shopify.dev/api/admin-graphql/2022-07/enums/OrderTransactionKind
          kind: 'REFUND',
          orderId,
          parentId: suggestedRefundTransaction?.parentTransaction?.id || null,
        },
      ],
    },
  }

  const result = await dispatch(
    doGraphqlRequest(types.ORDER_REFUND_REQUEST, mutation, variables, {
      graphqlFunc: graphql(integrationId),
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: 'Refund created',
          },
          failed: {
            enabled: true,
            content: 'Refund creation failed',
          },
        },
      },
    })
  )

  if (orderId) {
    dispatch(
      doCreateShopifyEvent(
        integrationId,
        orderId,
        conversationId,
        'ORDER_REFUNDED'
      )
    )
  }

  return result
}

export default doOrderRefund
