import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import { selectCurrentContact } from 'ducks/crm/contacts/selectors'
import { selectCurrentCompany } from 'ducks/crm/companies/selectors'
import selectIntegrationSettingsForId from 'ducks/integrations/selectors/selectIntegrationSettingsForId'
import { emptyObj, getValueByPath } from 'util/objects'
import { any } from 'util/arrays'

export const selectIntegrationHubspotData = state =>
  state.integrations.thirdPartyData.hubspot

export const selectIntegrationHubspotContactsData = state =>
  state.integrations.thirdPartyData.hubspot.contacts

export const selectContactsByEmail = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.byEmail || emptyObj
)

export const selectContactProperties = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.propertiesByName
)

export const selectDealProperties = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.dealPropertiesByName
)

export const selectCompanyProperties = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.companyPropertiesByName
)

export const selectDealStages = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.dealStages
)

export const selectNewContactInitialValues = createSelector(
  selectCurrentContact,
  selectCurrentCompany,
  (contact, company) => {
    return {
      email: contact?.email,
      firstname: contact?.firstName,
      lastname: contact?.lastName,
      // eslint-disable-next-line camelcase
      phone: contact?.customFieldValues?.contact_phone_number?.value?.content,
      // eslint-disable-next-line camelcase
      website: contact?.customFieldValues?.contact_website?.value?.text,
      company: company?.name,
    }
  }
)

export const selectCreateContactError = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.createContactError || emptyObj
)

export const selectContactByEmail = (state, email) => {
  return selectContactsByEmail(state)[email]
}

export const selectContactStateByEmail = (state, email) => {
  const found = selectContactByEmail(state, email)
  if (found && found.state === 'failed' && found.error.code === 404)
    return 'missing'
  return found ? found.state : 'unknown'
}

export const selectContactListsByEmail = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.listsByEmail || emptyObj
)

export const selectContactListsForEmail = (state, email) => {
  return selectContactListsByEmail(state)[email]
}

export const selectContactDealsByEmail = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.dealsByEmail || emptyObj
)

export const selectContactDealsForEmail = (state, email) => {
  return selectContactDealsByEmail(state)[email]
}

export const selectContactCompaniesByEmail = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.companiesByEmail || emptyObj
)

export const selectContactCompaniesForEmail = (state, email) => {
  return selectContactCompaniesByEmail(state)[email]
}

export const selectOwnersById = createSelector(
  selectIntegrationHubspotContactsData,
  data => data.ownersById || emptyObj
)

export const selectContactOwnerByEmail = (state, email) => {
  const contact = selectContactByEmail(state, email)
  if (!contact) return null
  const ownerId = getValueByPath('properties.hubspot_owner_id.value', contact)
  if (!ownerId) return null
  const ownersById = selectOwnersById(state)
  return ownersById[ownerId] || emptyObj
}

const selectSettingsKeys = createCachedSelector(
  selectIntegrationSettingsForId,
  settings => Object.keys(settings).filter(key => settings[key])
)((_state, id) => id || 'unknown')

export const selectShouldShowDealSection = createCachedSelector(
  selectSettingsKeys,
  keys => {
    return any(key => key.match('showSidebarField.Deal.'), keys)
  }
)((_state, id) => id || 'unknown')

export const selectShouldShowListSection = createCachedSelector(
  selectSettingsKeys,
  keys => any(key => key.match('showSidebarField.ListMembership'), keys)
)((_state, id) => id || 'unknown')

export const selectShouldShowCompanySection = createCachedSelector(
  selectSettingsKeys,
  keys => any(key => key.match('showSidebarField.Company.'), keys)
)((_state, id) => id || 'unknown')
