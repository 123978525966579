/* eslint-disable no-param-reassign */
import { emptyObj } from 'util/objects'
import { createActionTypeReducer } from 'util/reducers'
import { CHAT_BOT_STATE_UPDATE } from 'ducks/chat/actionTypes/botState'

const initialState = {
  bySkillId: emptyObj,
}

export default createActionTypeReducer(
  {
    [CHAT_BOT_STATE_UPDATE]: (draftState, { payload }) => {
      Object.keys(payload).forEach(skillId => {
        draftState.bySkillId[skillId] = payload[skillId]
      })
      return draftState
    },
  },
  initialState
)
