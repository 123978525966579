import { oauthTokenSelector } from 'selectors/app'
import config from 'config'
import { selectCustomer } from 'ducks/customers/selectors'
import { selectContactStateByEmail } from 'ducks/integrations/hubspot/selectors'
import doFetchContactProperties from './doFetchContactProperties'
import doFetchContactLists from './doFetchContactLists'
import doFetchContactDeals from './doFetchContactDeals'
import doFetchContactCompany from './doFetchContactCompany'
import doFetchOwners from './doFetchOwners'

import * as api from '../api'
import {
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILURE,
} from '../types'

export default function doFetchContact(email) {
  return (dispatch, getState) => {
    const meta = {
      requestKey: `integrations/hubspot/fetchContact:${email}`,
    }
    dispatch({
      type: FETCH_CONTACT_REQUEST,
      data: {
        email,
      },
      meta,
    })
    dispatch(doFetchContactProperties()).then(() => {
      const state = getState()
      const customer = selectCustomer(state, email)
      if (!customer) return null

      const customerState = selectContactStateByEmail(state, email)
      if (customerState === 'fetched') return customer
      if (customerState === 'missing') return customer

      const token = oauthTokenSelector(state)
      return api
        .getContactByEmail(token, email)
        .then(payload => {
          const { response, code } = payload
          dispatch({
            type: FETCH_CONTACT_SUCCESS,
            data: {
              email,
              code,
              ...response,
            },
            meta,
          })
          Promise.all([
            dispatch(doFetchContactLists(email)),
            dispatch(doFetchContactDeals(email)),
            dispatch(doFetchContactCompany(email)),
          ]).then(() => {
            dispatch(doFetchOwners(email))
          })
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          if (config.isDevelopment) console.error(error)
          return dispatch({
            type: FETCH_CONTACT_FAILURE,
            data: {
              email,
              error,
            },
            meta,
          })
        })
    })
  }
}
