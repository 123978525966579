import { doLinkResource } from 'actions/userIntegrations'
import { oauthTokenSelector } from 'selectors/app'

import { selectJiraSitesById } from 'ducks/integrations/jiraShared'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import integrationApi from '../../api'
import doFetchJiraCloudIssues from './doFetchJiraCloudIssues'
import {
  CREATE_ISSUE_REQUEST,
  CREATE_ISSUE_SUCCESS,
  CREATE_ISSUE_ERROR,
} from '../types'

import doPostTicketLinkOnJiraCloudIssue from './doPostTicketLinkOnJiraCloudIssue'
import formatADF from '../lib/formatADF'

export default function doCreateAndLinkJiraCloudIssueToTicket(
  siteId,
  projectId,
  {
    assignee,
    component = [],
    description,
    label,
    parent,
    priority,
    summary,
    type,
  }
) {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_ISSUE_REQUEST })
    const state = getState()
    const sites = selectJiraSitesById(state, { source: 'jiraCloud' })
    const token = oauthTokenSelector(state)
    const { name: siteName, url: siteUrl } = sites[siteId]
    const fields = {
      project: { id: projectId },
      summary,
      issuetype: { id: type },
    }
    if (description) fields.description = formatADF(description)
    if (assignee) fields.assignee = { id: assignee }
    if (label) fields.labels = label
    if (priority) fields.priority = { id: priority }
    if (parent) fields.parent = { id: parent }
    if (component && component.length > 0) {
      fields.components = component.map(id => ({ id }))
    }
    integrationApi
      .post(
        token,
        `proxy/atlassian/ex/jira/${siteId}/rest/api/3/issue`,
        {},
        JSON.stringify({
          fields,
        })
      )
      .then(({ json: issue }) => {
        if (!issue.id) {
          throw new Error('Failed to create Jira Issue')
        }
        dispatch({
          meta: {},
          payload: { issue },
          type: CREATE_ISSUE_SUCCESS,
        })
        const url = `${siteUrl}/browse/${issue.key}`
        const ticketId = selectCurrentTicketId(state)
        dispatch(
          doLinkResource({
            ticketId,
            id: `${siteName}:${issue.id}`,
            provider: 'jira_cloud',
            title: `[${issue.key}] ${summary}`,
            url,
          })
        )
        dispatch(doFetchJiraCloudIssues([`${siteName}:${issue.id}`]))
        dispatch(doPostTicketLinkOnJiraCloudIssue(siteId, issue.id))
      })
      .catch(error => {
        dispatch({
          error: true,
          meta: {},
          payload: error,
          type: CREATE_ISSUE_ERROR,
        })
      })
  }
}
