// HACK: status page URL is not available in any of the stable GraphQL versions as of yet
// but is available on the unstable version so will be released soon
// it is, however, available via REST API so we use that for now

import config from 'config'
import { doIntegrationsReadRequest } from 'ducks/requests/operations'
import { shopifyGidToId } from 'ducks/integrations/shopify/utils'

import { ORDER_STATUS_URL_REQUEST } from '../types'

const doFetchOrderStatusPageUrl = (integrationId, orderId) => dispatch => {
  const orderShortId = shopifyGidToId(orderId)

  const url = `proxy/shopify/admin/api/${
    config.shopify.restApiVersion
  }/orders/${orderShortId}.json?gid=${integrationId}&fields=order_status_url`

  return dispatch(doIntegrationsReadRequest(ORDER_STATUS_URL_REQUEST, url))
}

export default doFetchOrderStatusPageUrl
