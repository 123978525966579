/* eslint-disable no-multi-assign */ // ok in reducers
import { createCombinedReducer } from 'util/reducers'

import byId from './byId'
import hasErrored from './hasErrored'
import isLoading from './isLoading'
import isLoaded from './isLoaded'

export default createCombinedReducer({
  byId,
  hasErrored,
  isLoaded,
  isLoading,
})
