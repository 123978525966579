import { connect } from 'react-redux'

import { selectCannedReplyTemplatesWithCategory } from 'ducks/cannedReplies/selectors'

import SearchResults from './view'

const select = state => ({
  replies: selectCannedReplyTemplatesWithCategory(state),
})

export default connect(select)(SearchResults)
