import { doAppGraphqlRequest } from 'ducks/requests/operations'

import { customFieldNormalizationSchema } from '../schema'
import { RESTORE_CUSTOM_FIELD, TRASH_CUSTOM_FIELD } from '../types'

const trashCustomField = `
  mutation CustomFieldDelete($customFieldId: ID!, $mode: DeleteMode) {
    customFieldDelete(input: { customFieldId: $customFieldId, deleteMode: $mode }) {
      errors {
        message
        path
      }
      deletedCustomFieldId
    }
  }
`
export function doTrashCustomField(customFieldId, mode = 'SOFT') {
  return doAppGraphqlRequest(
    TRASH_CUSTOM_FIELD,
    trashCustomField,
    { customFieldId, mode },
    {
      normalizationSchema: customFieldNormalizationSchema,
    }
  )
}

export function doPermanentlyDeleteCustomField(customFieldId) {
  return doTrashCustomField(customFieldId, 'HARD')
}

const restoreCustomField = `
  mutation CustomFieldRestore($customFieldId: ID!) {
    customFieldRestore(input: { customFieldId: $customFieldId }) {
      errors {
        message
        path
      }
      customField {
        id
      }
    }
  }
`
export function doRestoreCustomField(customFieldId) {
  return doAppGraphqlRequest(
    RESTORE_CUSTOM_FIELD,
    restoreCustomField,
    { customFieldId },
    {
      normalizationSchema: {
        customFieldNormalizationSchema,
      },
    }
  )
}
