import { createSelector } from 'reselect'

import selectGitHubCurrentTicketLinkedResources from './selectGitHubCurrentTicketLinkedResources'

export default createSelector(
  selectGitHubCurrentTicketLinkedResources,
  linkedResources => {
    if (!linkedResources) return linkedResources
    return linkedResources.map(({ externalId }) => externalId)
  }
)
