export const ROOMS_FETCH = 'CHAT/ROOMS/FETCH'
export const ROOMS_FETCH_SET_LOADING_IDS = 'CHAT/ROOMS/FETCH_SET_LOADING_IDS'
export const ROOMS_FETCH_STARTED = 'CHAT/ROOMS/FETCH_STARTED'
export const ROOMS_FETCH_SUCCESS = 'CHAT/ROOMS/FETCH_SUCCESS'
export const ROOMS_FETCH_FAILED = 'CHAT/ROOMS/FETCH_FAILED'

export const ROOMS_CREATE = 'CHAT/ROOMS/CREATE'
export const ROOMS_CREATE_STARTED = 'CHAT/ROOMS/CREATE_STARTED'
export const ROOMS_CREATE_SUCCESS = 'CHAT/ROOMS/CREATE_SUCCESS'
export const ROOMS_CREATE_FAILED = 'CHAT/ROOMS/CREATE_FAILED'

export const ROOMS_BYID_FETCH = 'CHAT/ROOMS/BYID/FETCH'
export const ROOMS_BYID_FETCH_STARTED = 'CHAT/ROOMS/BYID/FETCH_STARTED'
export const ROOMS_BYID_FETCH_SUCCESS = 'CHAT/ROOMS/BYID/FETCH_SUCCESS'
export const ROOMS_BYID_FETCH_FAILED = 'CHAT/ROOMS/BYID/FETCH_FAILED'

export const ROOMS_BYID_ACCESS_FETCH = 'CHAT/ROOMS/BYID/ACCESS/FETCH'
export const ROOMS_BYID_ACCESS_FETCH_STARTED =
  'CHAT/ROOMS/BYID/ACCESS/FETCH_STARTED'
export const ROOMS_BYID_ACCESS_FETCH_SUCCESS =
  'CHAT/ROOMS/BYID/ACCESS/FETCH_SUCCESS'
export const ROOMS_BYID_ACCESS_FETCH_FAILED =
  'CHAT/ROOMS/BYID/ACCESS/FETCH_FAILED'

export const ROOMS_BYIDS_FETCH = 'CHAT/ROOMS/BYIDS/FETCH'
export const ROOMS_BYIDS_FETCH_STARTED = 'CHAT/ROOMS/BYIDS/FETCH_STARTED'
export const ROOMS_BYIDS_FETCH_SUCCESS = 'CHAT/ROOMS/BYIDS/FETCH_SUCCESS'
export const ROOMS_BYIDS_FETCH_FAILED = 'CHAT/ROOMS/BYIDS/FETCH_FAILED'

export const ROOMS_REALTIME_SYNC_UNREAD = 'CHAT/ROOMS/REALTIME/SYNC_UNREAD'

export const ROOMS_UPDATED = 'CHAT/ROOMS/UPDATED'
export const ROOM_OPTIMISTIC_UPDATED = 'CHAT/ROOM/OPTIMISTIC_UPDATED'
export const ROOM_OPTIMISTIC_COMPLETE = 'CHAT/ROOM/ROOM_OPTIMISTIC_COMPLETE'
export const ROOM_OPTIMISTIC_FAILED = 'CHAT/ROOM/ROOM_OPTIMISTIC_FAILED'

export const ROOM_DELETE = 'CHAT/ROOM/DELETE'
export const ROOM_DELETE_STARTED = 'CHAT/ROOM/DELETE_STARTED'
export const ROOM_DELETE_SUCCESS = 'CHAT/ROOM/DELETE_SUCCESS'
export const ROOM_DELETE_FAILED = 'CHAT/ROOM/DELETE_FAILED'
