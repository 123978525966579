import deepEqual from 'fast-deep-equal'

import { selectCustomFieldForKey } from 'ducks/crm/customFields/selectors'
import { doUpdateCustomFieldFileValues } from 'ducks/crm/customFields/operations/files'
import { doAppGraphqlRequest } from 'ducks/requests/operations'

export function doCreateCustomFieldValuesSubjectFromForm(
  type,
  query,
  formData,
  normalizationSchema
) {
  return (dispatch, getState) => {
    const state = getState()
    const doneKeys = []

    const newValues = Object.keys(formData)
      .reduce((result, key) => {
        const { id, isArray } = selectCustomFieldForKey(state, { key }) || {}
        const newValue = formData[key]
        if (isArray) {
          return result.concat(
            newValue
              .map((individualValue, index) => {
                return {
                  key,
                  id,
                  isArray: true,
                  isFileField: false,
                  mode: index === 0 ? 'REPLACE' : 'APPEND',
                  value: individualValue,
                }
              })
              .filter(({ value: { content } }) => !!content)
          )
        }
        const firstForKey = !doneKeys.includes(key)
        doneKeys.push(key)
        result.push({
          key,
          id,
          firstForKey,
          value: newValue,
        })
        return result
      }, [])
      .filter(x => x !== null)
    return dispatch(
      doCreateCustomFieldValuesSubject(
        type,
        query,
        newValues,
        normalizationSchema
      )
    )
  }
}

export function doCreateCustomFieldValuesSubject(
  type,
  query,
  fields,
  normalizationSchema
) {
  return async dispatch => {
    const fileFields = fields.filter(
      ({ isFileField, remove }) => isFileField && !remove
    )
    const nonFileFields = fields.filter(
      ({ isFileField, remove }) => !isFileField || remove
    )
    const isSubjectCompany = type.match(/^crm\/companies/)
    const subjectKey = isSubjectCompany ? 'companies' : 'contacts'
    const attachments = await (fileFields.length > 0
      ? dispatch(
          doUpdateCustomFieldFileValues(fileFields, {
            // optimist,
            meta: {
              mergeEntities: true,
            },
          })
        )
      : Promise.resolve([]))
    const customFields = [
      ...nonFileFields.map(({ id, mode, value }) => {
        const newValue =
          value && deepEqual(Object.keys(value), ['content'])
            ? value.content
            : value
        return {
          customFieldIdOrKey: id,
          ...(newValue === '' || !newValue
            ? { remove: true }
            : { value: newValue, mode }),
        }
      }),
      ...attachments.map(({ id, value }) => {
        return {
          customFieldIdOrKey: id,
          value: {
            content_type: value.attachment_content_type,
            file_name: value.attachment_file_name,
            file_size: value.attachment_file_size,
            attachment_id: value.id,
          },
        }
      }),
    ]
    if (customFields.length === 0) return null
    const variables = {
      input: {
        customFields,
      },
      customFieldIds: fields.map(({ id }) => id),
    }
    return dispatch(
      doAppGraphqlRequest(type, query, variables, {
        // optimist,
        meta: {
          mergeEntities: true,
          attachments,
        },
        normalizationSchema,
        transformEntities: entitities => {
          const subjects = entitities[subjectKey] || {}
          Object.keys(subjects).forEach(id => {
            // eslint-disable-next-line no-param-reassign
            subjects[id].customFieldValues =
              subjects[id].customFieldValues || {}
          })
          return entitities
        },
      })
    )
  }
}
