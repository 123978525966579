import {
  FETCH_TYPES_ERROR,
  FETCH_TYPES_REQUEST,
  FETCH_TYPES_SUCCESS,
} from '../types'

const initialState = { byId: {}, errored: false, loading: false }

export default function reduceTypes(
  state = initialState,
  { type, payload: types = [] }
) {
  if (type === FETCH_TYPES_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  if (type === FETCH_TYPES_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }
  if (type === FETCH_TYPES_SUCCESS) {
    return {
      ...state,
      byId: {
        ...types.reduce((result, issueType) => {
          // eslint-disable-next-line no-param-reassign
          result[issueType.id] = issueType
          return result
        }, {}),
      },
      errored: false,
      loading: false,
    }
  }
  return state
}
