import { schema } from 'normalizr'

export const companyNormalizationSchema = new schema.Entity('companies')

export const companiesNormalizationSchema = {
  companies: {
    nodes: [companyNormalizationSchema],
  },
}

export const createCompanySchema = {
  companyCreate: {
    company: companyNormalizationSchema,
  },
}
