import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import { buildId, getRawId } from 'util/globalId'
import { selectAgentsById } from 'selectors/agents/base'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors/base'
import { COLLISION_STATES } from './constants'

function selectBase(state) {
  return state.collisions || emptyObj
}

const cacheKeyForConversationIdAndExcludeCurrent = (
  _state,
  conversationId,
  excludeCurrentUser = true,
  lastEventOnly = false
) => `${conversationId}-${excludeCurrentUser}-${lastEventOnly}`

const emptyCollision = COLLISION_STATES.reduce((obj, stateName) => {
  // eslint-disable-next-line no-param-reassign
  obj[stateName] = []
  return obj
}, {})

const selectCollisionParams = (_state, conversationId, excludeCurrentUser) => ({
  conversationId: buildId('Conversation', conversationId),
  // We explicently check for !== false here so that undefined also returns true
  excludeCurrentUser: excludeCurrentUser !== false,
})

export const selectCollisionsByConversationId = createCachedSelector(
  selectBase,
  selectCurrentUserGlobalId,
  selectCollisionParams,
  (base, currentUserId, { conversationId, excludeCurrentUser }) => {
    const conversationCollisions = base.byConversationId[conversationId]
    if (!conversationCollisions) return emptyCollision
    if (!excludeCurrentUser) return conversationCollisions
    return Object.keys(conversationCollisions).reduce((obj, stateName) => {
      // eslint-disable-next-line no-param-reassign
      obj[stateName] = conversationCollisions[stateName].filter(
        agentId => agentId !== currentUserId
      )
      return obj
    }, {})
  }
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectCollisionsByConversationIdWithAgents = createCachedSelector(
  selectCollisionsByConversationId,
  selectAgentsById,
  (collisions, agentById) => {
    return Object.keys(collisions).reduce((obj, stateName) => {
      // eslint-disable-next-line no-param-reassign
      obj[stateName] = collisions[stateName]
        .map(agentId => agentById[getRawId(agentId)])
        .filter(agent => !!agent)

      return obj
    }, {})
  }
)(cacheKeyForConversationIdAndExcludeCurrent)

const collisionOperations = {
  viewing: 'is viewing',
  replying: 'is replying',
  noting: 'is leaving a note',
}

export const selectConversationAgentCollisions = createCachedSelector(
  selectCollisionsByConversationIdWithAgents,
  collisions => {
    const viewingAgents = collisions.viewing
    const replyingAgents = collisions.replying
    const notingAgents = collisions.noting

    const isAnotherAgentViewing = viewingAgents.length > 0
    const isAnotherAgentReplying = replyingAgents.length > 0
    const isAnotherAgentAddingNote = notingAgents.length > 0

    const hasCollision =
      isAnotherAgentViewing ||
      isAnotherAgentReplying ||
      isAnotherAgentAddingNote

    let collidingAgent = null
    let collisionType = null
    if (isAnotherAgentViewing) collisionType = 'viewing'
    if (isAnotherAgentReplying) collisionType = 'replying'
    if (isAnotherAgentAddingNote) collisionType = 'noting'

    if (hasCollision) {
      collidingAgent =
        replyingAgents[0] || notingAgents[0] || viewingAgents[0] || null
    }

    return {
      hasCollision,
      collidingAgent,
      collisionType,
      collisionOperation: collisionOperations[collisionType],
    }
  }
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectIsAgentColliding = createCachedSelector(
  selectCollisionsByConversationId,
  collisions => {
    return (
      collisions.viewing.length > 0 ||
      collisions.replying.length > 0 ||
      collisions.noting.length > 0 ||
      collisions.typing.length > 0
    )
  }
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectAgentIdsForViewingCollisionByConversationId = createCachedSelector(
  selectCollisionsByConversationId,
  collisions => collisions.viewing
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectAgentIdsForReplyingCollisionByConversationId = createCachedSelector(
  selectCollisionsByConversationId,
  collisions => collisions.replying
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectAgentIdsForNotingCollisionByConversationId = createCachedSelector(
  selectCollisionsByConversationId,
  collisions => collisions.noting
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectAgentIdsForTypingCollisionByConversationId = createCachedSelector(
  selectCollisionsByConversationId,
  collisions => collisions.typing
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectAgentsForViewingCollisionByConversationId = createCachedSelector(
  selectCollisionsByConversationIdWithAgents,
  ({ viewing, replying, noting, typing }) =>
    viewing.filter(
      agent =>
        !replying.includes(agent) &&
        !noting.includes(agent) &&
        !typing.includes(agent)
    )
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectAgentsForReplyingCollisionByConversationId = createCachedSelector(
  selectCollisionsByConversationIdWithAgents,
  ({ replying, typing }) => replying.filter(agent => !typing.includes(agent))
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectAgentsForNotingCollisionByConversationId = createCachedSelector(
  selectCollisionsByConversationIdWithAgents,
  ({ noting, typing }) => noting.filter(agent => !typing.includes(agent))
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectAgentsForTypingReplyCollisionByConversationId = createCachedSelector(
  selectCollisionsByConversationIdWithAgents,
  ({ replying, typing }) => typing.filter(agent => replying.includes(agent))
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectAgentsForTypingNoteCollisionByConversationId = createCachedSelector(
  selectCollisionsByConversationIdWithAgents,
  ({ noting, typing }) => typing.filter(agent => noting.includes(agent))
)(cacheKeyForConversationIdAndExcludeCurrent)
