import { createEntityReducer } from 'util/reducers'
import { FETCH_COMPANY_SUCCESS } from '../types'

export default createEntityReducer('companies', {
  transformEntity: company => {
    const newCompany = { ...company }
    if (newCompany.customFieldValues && newCompany.customFieldValues.nodes) {
      newCompany.customFieldValues = newCompany.customFieldValues.nodes.reduce(
        (result, fieldValue) => {
          const {
            key: valueKey, // This is actually the custom field key but sometimes it is on the value
            customField: { isArray, key = valueKey } = {},
            ...rest
          } = fieldValue
          const existingValue = result[key]
          if (isArray) {
            rest.value = [...(existingValue?.value || []), rest.value]
          }
          // eslint-disable-next-line no-param-reassign
          result[key] = { key, ...rest }
          return result
        },
        {}
      )
    }
    return newCompany
  },
  reduce: (draftState, { type, entities }) => {
    if (type === FETCH_COMPANY_SUCCESS) {
      Object.keys(entities?.companies || {}).forEach(contactId => {
        if (
          draftState[contactId] &&
          draftState[contactId].isLoaded &&
          entities.companies[contactId].isMissing
        ) {
          // HACK (jscheel): We should not overwrite a contact if it was not
          // previously missing, even if we think it actually is missing now.
          // This prevents bad responses from the API from blowing away good
          // data that we already have in the store. It's possible that the
          // contact could have actually been removed from the backend, but we
          // would rather fail on the off chance that someone tries to edit it
          // from the sidebar than failing with a big honking "missing contact"
          // because of wonky edge cases where the request failed.
          draftState[contactId].isMissing = false
        }
      })
    }
  },
})
