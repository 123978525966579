import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setKind } from 'redux-first-router'
import {
  selectCurrentPage,
  selectQueryParams,
  selectCurrentPayload,
} from 'selectors/location'

const buildTabPageAction = ({ tabId, kind }) => (dispatch, getState) => {
  const state = getState()
  const pageType = selectCurrentPage(state)
  const pagePayload = selectCurrentPayload(state)
  const currentPageQuery = selectQueryParams(state)

  const pageAction = {
    type: pageType,
    payload: {
      ...pagePayload,
      tab: tabId,
    },
    meta: {
      query: currentPageQuery,
    },
  }

  return dispatch(kind ? setKind(pageAction, kind) : pageAction)
}

export const useTabs = ({ tabs: inputTabs, defaultTabId }) => {
  const dispatch = useDispatch()
  const pagePayload = useSelector(selectCurrentPayload)
  const { tab: urlTabId = defaultTabId } = pagePayload

  const tabs = useMemo(() => inputTabs || [], [inputTabs])
  const activeTab = useMemo(
    () => {
      const urlTab = tabs.find(t => t.key === urlTabId)
      if (urlTab) return urlTab
      const defaultTab = tabs.find(t => t.key === defaultTabId)
      if (defaultTab) return defaultTab
      if (tabs.length > 0) return tabs[0]
      return null
    },
    [urlTabId, defaultTabId, tabs]
  )

  const tabId = useMemo(
    () => {
      return activeTab?.key || urlTabId
    },
    [urlTabId, activeTab]
  )

  const onTabChange = useCallback(
    inputTabId => {
      if (inputTabId === tabId) return
      const payload = {}
      if (inputTabId) {
        payload.tab = inputTabId
      }
      dispatch(buildTabPageAction({ tabId: inputTabId }))
    },
    [dispatch, tabId]
  )

  return {
    onTabChange,
    tabs,
    activeTab,
    ActiveTabComponent: activeTab?.component,
    activeTabId: tabId,
    pagePayload,
  }
}

export const useLink = (inputAction, { preserveQuery = false } = {}) => {
  const currentPageQuery = useSelector(selectQueryParams)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const action = useMemo(() => inputAction, [])

  const pageAction = useMemo(
    () => {
      if (preserveQuery) {
        return {
          ...action,
          meta: { query: currentPageQuery },
        }
      }
      return action
    },
    [action, currentPageQuery, preserveQuery]
  )

  return pageAction
}
