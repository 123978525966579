import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import { DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST } from '../types'
import { draftOrderPartial } from './partials'

const draftOrderCreateFromOrderMutation = `
  mutation draftOrderCreateFromOrder($orderId: ID!) {
    draftOrderCreateFromOrder(orderId: $orderId) {
      draftOrder {
        ${draftOrderPartial}
      }
      userErrors {
        field
        message
      }
    }
  }
`

// AKA duplicate order
const doDraftOrderCreateFromOrder = (
  integrationId,
  customerId,
  orderId
) => dispatch => {
  return dispatch(
    doGraphqlRequest(
      DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST,
      draftOrderCreateFromOrderMutation,
      {
        orderId,
      },
      {
        graphqlFunc: graphql(integrationId),
        meta: {
          customerId,
        },
      }
    )
  )
}

export default doDraftOrderCreateFromOrder
