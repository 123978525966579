import { createCustomer } from 'ducks/customers/api'
import { oauthTokenSelector } from 'selectors/app'

import {
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
} from '../types'

import { doChangeUser } from './doChangeUser'

export const doCreateNewUser = user => (dispatch, getState) => {
  dispatch({ type: ADD_NEW_USER_REQUEST, payload: { user } })
  const token = oauthTokenSelector(getState())
  return createCustomer(token, user)
    .then(newUser => {
      dispatch({ type: ADD_NEW_USER_SUCCESS, payload: { user: newUser } })
      dispatch(doChangeUser(newUser))
    })
    .catch(error => {
      dispatch({ type: ADD_NEW_USER_FAILURE, payload: { error } })
    })
}
