import { createSelector } from 'reselect'

import { emptyArr } from 'util/arrays'

import { selectCurrentSearchTerm } from '../../selectors'
import { selectLatestLoadedSearchTerm } from './base'

export const selectPageIsFiltered = createSelector(
  selectCurrentSearchTerm,
  term => {
    return !term || term === ''
  }
)

export const selectLatestLoadedPageIsFiltered = createSelector(
  selectLatestLoadedSearchTerm,
  term => {
    return term && term !== ''
  }
)

export const selectLatestLoadedSearchTerms = createSelector(
  selectLatestLoadedSearchTerm,
  term => (term && term.split(' ')) || emptyArr
)
