import { doGraphqlRequest } from 'ducks/requests/operations'
import * as types from '../types'

const upsertJiraServerMutation = `
  mutation IntegrationJiraServerUpsertMutation(
    $url: String!,
    $username: String!,
    $password: String
  ) {
    integrationJiraServerUpsert(input: {
      url: $url,
      username: $username,
      password: $password,
    }) {
      integration {
        id
        provider
        uid
        legacyId
        settings {
          settings
        }
      }
    }
  }
`

export const doUpsertJiraServerCredentials = attrs => dispatch => {
  const variables = {
    url: attrs.url,
    username: attrs.username,
    password: attrs.access_token,
  }

  dispatch(
    doGraphqlRequest(
      types.UPSERT_JIRA_SERVER_CREDENTIALS,
      upsertJiraServerMutation,
      variables,
      { app: true }
    )
  )
}
