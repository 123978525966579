import grooveAPI from 'api/groove'
import {
  oauthTokenSelector,
  selectAccountPrefersScopedContactsSearch,
} from 'selectors/app'
import { selectCurrentTicketMailboxId } from 'selectors/tickets'
import { selectCurrentCustomerId } from 'selectors/tickets/customer/selectCurrentCustomerId'
import {
  SEARCH_USER_FAILURE,
  SEARCH_USER_REQUEST,
  SEARCH_USER_SUCCESS,
} from '../types'

export const doSearchUsers = term => (dispatch, getState) => {
  dispatch({
    type: SEARCH_USER_REQUEST,
    payload: { term },
  })

  const state = getState()
  const token = oauthTokenSelector(state)
  const currentTicketUserId = selectCurrentCustomerId(getState())
  const defaultRoles = ['admin', 'owner', 'agent']
  const role = defaultRoles.concat('enduser')
  const scopeByMailbox = selectAccountPrefersScopedContactsSearch(state)

  let mailboxIds = null
  if (scopeByMailbox) {
    const ticketMailboxId = selectCurrentTicketMailboxId(state)
    mailboxIds = [ticketMailboxId]
  }

  return grooveAPI
    .get(token, 'v1/contacts.json', {
      term,
      role,
      without: { id: `${currentTicketUserId}` },
      full_contact: true,
      mailbox_ids: mailboxIds,
    })
    .then(res => {
      dispatch({
        type: SEARCH_USER_SUCCESS,
        payload: { users: res.json.contacts },
      })
    })
    .catch(error => {
      dispatch({ type: SEARCH_USER_FAILURE, payload: { error } })
    })
}
