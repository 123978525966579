import React, { useCallback } from 'react'
import cn from 'classnames'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { DRAWER_TYPE_CANNED_REPLIES_UPDATE } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'

import { styles } from './styles'

const SearchEmpty = ({ className, onVisibleChange }) => {
  const { openDrawer: openCreateDrawer } = useDrawer({
    type: DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  })

  const handleOpenCreateDrawer = useCallback(
    () => {
      openCreateDrawer('new')
      onVisibleChange(false)
    },
    [openCreateDrawer, onVisibleChange]
  )

  return (
    <div css={styles.container} className={cn('grui text-center', className)}>
      <div css={[text.styles.fontMedium, styles.copy]}>
        No {app.t('canned_replies')} found!
      </div>
      <Button
        size="small"
        type="info"
        css={[text.styles.fontMedium, styles.btn]}
        onClick={handleOpenCreateDrawer}
      >
        Create an {app.t('canned_replies')}
      </Button>
    </div>
  )
}

export default SearchEmpty
