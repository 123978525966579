import { createSelector } from 'reselect'
import { selectBillingUsage } from './selectBillingUsage'
import { selectCurrentBillingCycle } from './selectCurrentBillingCycle'
import { selectIsBillingDataLoaded } from './selectIsBillingDataLoaded'
import { PRICING_MODEL_FLAT } from '../constants'
import { selectPlans } from './selectPlans'

export const selectCurrentTotalPerCycle = createSelector(
  selectIsBillingDataLoaded,
  selectPlans,
  selectCurrentBillingCycle,
  selectBillingUsage,
  (loaded, plans, billingCycle, usage) => {
    if (!loaded) return undefined

    return plans.reduce((total, plan) => {
      const pricing = plan.pricing
      const quantity =
        pricing.pricingModel === PRICING_MODEL_FLAT
          ? 1
          : usage[pricing.usageFrom]
      const cyclePrice = pricing[`${billingCycle}`]

      return (total + quantity * cyclePrice) / 100
    }, 0)
  }
)
