export const FETCH_SLACK_APP = 'integrations/slack/FETCH_APP'
export const FETCH_SLACK_APP_STARTED = 'integrations/slack/FETCH_APP_STARTED'
export const FETCH_SLACK_APP_SUCCESS = 'integrations/slack/FETCH_APP_SUCCESS'
export const FETCH_SLACK_APP_FAILED = 'integrations/slack/FETCH_APP_FAILED'

export const FETCH_SLACK_CHANNELS = 'integrations/slack/FETCH_CHANNELS'
export const FETCH_SLACK_CHANNELS_STARTED =
  'integrations/slack/FETCH_CHANNELS_STARTED'
export const FETCH_SLACK_CHANNELS_SUCCESS =
  'integrations/slack/FETCH_CHANNELS_SUCCESS'
export const FETCH_SLACK_CHANNELS_FAILED =
  'integrations/slack/FETCH_CHANNELS_FAILED'

export const FETCH_SLACK_PROFILES = 'integrations/slack/FETCH_PROFILES'
export const FETCH_SLACK_PROFILES_STARTED =
  'integrations/slack/FETCH_PROFILES_STARTED'
export const FETCH_SLACK_PROFILES_SUCCESS =
  'integrations/slack/FETCH_PROFILES_SUCCESS'
export const FETCH_SLACK_PROFILES_FAILED =
  'integrations/slack/FETCH_PROFILES_FAILED'

export const UPDATE_SLACK_APP = 'integrations/slack/UPDATE_APP'
export const UPDATE_SLACK_APP_STARTED = 'integrations/slack/UPDATE_APP_STARTED'
export const UPDATE_SLACK_APP_SUCCESS = 'integrations/slack/UPDATE_APP_SUCCESS'
export const UPDATE_SLACK_APP_FAILED = 'integrations/slack/UPDATE_APP_FAILED'
