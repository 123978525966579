/* eslint-disable no-multi-assign */ // ok in reducers
import { createCombinedReducer } from 'util/reducers'

import byId from './byId'
import byRequestKey from './byRequestKey'
import cursor from './cursor'
import latestLoadedRequestKey from './latestLoadedRequestKey'
import latestLoadedSearchTerm from './latestLoadedSearchTerm'
import latestRequestKey from './latestRequestKey'
import orderBy from './orderBy'
import totalCount from './totalCount'

export default createCombinedReducer({
  byId,
  byRequestKey,
  cursor,
  latestLoadedRequestKey,
  latestLoadedSearchTerm,
  latestRequestKey,
  orderBy,
  totalCount,
})
