import { doApiReadRequest } from 'ducks/requests/operations'
import {
  doSidebarCardRequestStart,
  doSidebarCardRequestSuccess,
  doSidebarCardRequestFailure,
} from 'actions/sidebar_cards'
import {
  selectSidebarCardData,
  selectIsSidebarCardExpired,
} from 'selectors/sidebar_cards'
import * as types from './types'

export function doFetchCustomProfile(
  enduserId,
  cardId = `custom-profile-${enduserId}`,
  channelId = null,
  { hardRefresh = false } = {}
) {
  return (dispatch, getState) => {
    const state = getState()

    const hasData = selectSidebarCardData(state)(cardId)
    const isExpired = selectIsSidebarCardExpired(state)(cardId)

    if (hasData && !isExpired && !hardRefresh) return

    dispatch(doSidebarCardRequestStart(cardId))
    dispatch(
      doApiReadRequest(
        types.FETCH_CUSTOM_PROFILE,
        'v1/custom_profile',
        {
          enduser_id: enduserId,
          hard_refresh: hardRefresh,
          channel_id: channelId,
        },
        {
          skipActions: !hardRefresh,
          meta: { enduserId },
        }
      )
    )
      .then(data => {
        dispatch(doSidebarCardRequestSuccess(cardId, { data }))
      })
      .catch(error => {
        dispatch(doSidebarCardRequestFailure(cardId, { error }))
      })
  }
}
