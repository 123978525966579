import { createActionTypeReducer } from 'util/reducers'
import {
  RESYNC_CONTACT_STARTED,
  RESYNC_CONTACT_FAILED,
  RESYNC_CONTACT_SUCCESS,
} from '../types'

const defaultState = {
  byIdAndIntegrationId: {},
}

export default createActionTypeReducer(
  {
    [RESYNC_CONTACT_STARTED]: (draftState, action) => {
      const {
        request: { parameters: { contactId, integrationId } = {} } = {},
      } = action

      const key = `${contactId}-${integrationId}`

      if (!draftState.byIdAndIntegrationId[key]) {
        draftState.byIdAndIntegrationId[key] = {}
      }

      draftState.byIdAndIntegrationId[key].isLoading = true
      draftState.byIdAndIntegrationId[key].loaded = false
      draftState.byIdAndIntegrationId[key].error = false

      return draftState
    },
    [RESYNC_CONTACT_FAILED]: (draftState, action) => {
      const {
        request: { parameters: { contactId, integrationId } = {} } = {},
      } = action

      const key = `${contactId}-${integrationId}`

      if (!draftState.byIdAndIntegrationId[key]) {
        draftState.byIdAndIntegrationId[key] = {}
      }

      draftState.byIdAndIntegrationId[key].isLoading = false
      draftState.byIdAndIntegrationId[key].loaded = false
      draftState.byIdAndIntegrationId[key].error = true

      return draftState
    },
    [RESYNC_CONTACT_SUCCESS]: (draftState, action) => {
      const {
        request: { parameters: { contactId, integrationId } = {} } = {},
      } = action

      const key = `${contactId}-${integrationId}`

      if (!draftState.byIdAndIntegrationId[key]) {
        draftState.byIdAndIntegrationId[key] = {}
      }

      draftState.byIdAndIntegrationId[key].isLoading = false
      draftState.byIdAndIntegrationId[key].loaded = true
      draftState.byIdAndIntegrationId[key].error = false

      return draftState
    },
  },
  defaultState
)
