import { oauthTokenSelector } from 'selectors/app'
import config from 'config'
import { selectCurrentContactId } from 'ducks/crm/contacts/selectors'
import { doLinkResource } from 'actions/userIntegrations'

import * as api from '../api'
import {
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
} from '../types'
import doFetchContact from './doFetchContact'

export default function doCreateContact(formFields) {
  return (dispatch, getState) => {
    const state = getState()
    const contactId = selectCurrentContactId(state)
    dispatch({
      type: CREATE_CONTACT_REQUEST,
      data: {
        contactId,
      },
    })

    const token = oauthTokenSelector(state)
    const props = Object.keys(formFields).map(property => {
      const value = formFields[property]
      return {
        property,
        value,
      }
    })

    return api
      .createContact(token, JSON.stringify({ properties: props }))
      .then(payload => {
        const { response } = payload
        dispatch({
          type: CREATE_CONTACT_SUCCESS,
          data: {
            contactId,
            ...response,
          },
        })
        return payload
      })
      .then(payload => {
        const {
          response,
          response: { properties },
        } = payload
        const url = response['profile-url']
        const id = response['portal-id']
        const title = [
          properties.firstname.value,
          properties.lastname.value,
        ].join(' ')
        dispatch(doLinkResource({ title, url, id, provider: 'hubspot' }))
        dispatch(doFetchContact(formFields.email))
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        if (config.isDevelopment) console.error(error)
        dispatch({
          type: CREATE_CONTACT_FAILURE,
          data: {
            contactId,
            error,
          },
        })
        throw error
      })
  }
}
