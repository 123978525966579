import { pick } from 'util/objects'

/*
 * Filters global state to be shared across all the apps.
 *
 * Global state is bad ಠ_ಠ

 * Here we only expose the state that is absolutely required across our apps.
 * So, any change to this list must go thru approval from the "frontend group".
 *
 * IMPORTANT: To be able to use existing `selectors/*`,
 *            shared state keys must use the same name!
 */
function filterGlobalState(state) {
  return {
    // FIXME: When inbox becomes a subapp and app state is truly shared,
    // expose entire app, instead of cherry-picking.
    app: pick(['token'], state.app),
    ...pick(['billing', 'location', 'page'], state),
  }
}

/*
 * Combines reducers, filters out invalid ones and passes in shared state.
 *
 * Based on https://github.com/Velenir/combine-reducers-global-state/blob/master/src/index.js
 *
 * This function actually extends combineReducers, so that you can define either
 * a reducer function _or_ an object of the shape:
 *
 *   {
 *     reducer: reducerFn,
 *     includes: ['global', 'state', 'keys', 'to' , 'include', 'in', 'sharedState']
 *   }
 *
 * Using this shape will add include additional slices of the state in the
 * sharedState argument. Note that this will try to use the latest reduced state,
 * so that you can work from derived data if necessary. Please use this with
 * caution, as we do not want to develop a reliance on cross-state data.
 */
export function combineReducers(reducers) {
  const finalReducers = {}
  const includesLookup = {}

  Object.keys(reducers).forEach(key => {
    if (typeof reducers[key] === 'object') {
      finalReducers[key] = reducers[key].reducer
      includesLookup[key] = reducers[key].includes
    } else if (typeof reducers[key] === 'function') {
      finalReducers[key] = reducers[key]
    }
  })

  return function combination(
    state = {},
    action,
    sharedState = filterGlobalState(state)
  ) {
    let hasChanged = false
    const nextState = {}

    Object.keys(finalReducers).forEach(key => {
      const reducer = finalReducers[key]
      const previousStateForKey = state[key]
      const nextStateForKey = reducer(previousStateForKey, action, {
        ...sharedState,
        ...pick(includesLookup[key] || [], { ...state, ...nextState }),
      })

      nextState[key] = nextStateForKey
      hasChanged = hasChanged || nextStateForKey !== previousStateForKey
    })

    return hasChanged ? nextState : state
  }
}
