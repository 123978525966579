import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectCurrentMatrixUserId } from 'ducks/chat/selectors/users'
import { selectAccountChatRoomId } from 'selectors/app/selectAccountPreferences'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { getMatrixClient } from '../utils/client'
import { getMatrixEnvironment } from '../utils/client/getMatrixEnvironment'
import {
  BULK_USER_PRESENCE_CHANGED,
  PRESENCE_UPDATE,
  PRESENCE_UPDATE_SETTINGS,
  USER_PRESENCE_CHANGED,
  PRESENCE_UPDATE_CACHE,
} from '../actionTypes/presence'
import {
  selectPresence,
  selectCurrentContactMatrixUserPresence,
  selectByMatrixId,
} from '../selectors/presence'

export function doUpdatePresence(inputPresence) {
  return (dispatch, getState) => {
    const state = getState()
    const isAdminOrOwnerOrAgent = selectCurrentUserIsAdminOrOwnerOrAgent(state)
    const presence = isAdminOrOwnerOrAgent ? inputPresence : 'offline'
    const currentState = selectPresence(getState())
    if (currentState !== presence) {
      dispatch({
        type: PRESENCE_UPDATE,
        payload: {
          presence,
        },
      })
    }
  }
}

export function doUpdatePresenceForMatrixUsers(
  matrixUserIds,
  internalPresence
) {
  return (dispatch, getState) => {
    const state = getState()
    const presenceStates = selectByMatrixId(state)
    const accountChatRoomId = selectAccountChatRoomId(state)
    const autoPresence = internalPresence === 'autoPresence'
    const presenceState = autoPresence ? 'online' : internalPresence
    const finalUpdatableUserIds = matrixUserIds.filter(id => {
      return presenceStates[id] !== presenceState
    })

    if (finalUpdatableUserIds.length === 0) return
    const client = getMatrixClient()
    client.sendEvent(accountChatRoomId, 'g.presence.update', {
      user_ids: finalUpdatableUserIds,
      state: presenceState,
      auto_presence: autoPresence,
    })
    dispatch({
      type: BULK_USER_PRESENCE_CHANGED,
      payload: finalUpdatableUserIds.map(id => {
        return { userId: id, presence: presenceState }
      }),
    })
  }
}

export function doUpdatePresenceSettings(settings) {
  return {
    type: PRESENCE_UPDATE_SETTINGS,
    payload: { settings },
  }
}

export function doGetUserPresence() {
  return async (dispatch, getState) => {
    const state = getState()
    if (selectCurrentContactMatrixUserPresence(state) !== 'unknown') return
    const client = await getMatrixEnvironment()
    const userId = selectCurrentMatrixUserId(state)
    if (!userId) return
    const { presence } = client.getUser(userId)
    dispatch({
      type: USER_PRESENCE_CHANGED,
      payload: presence,
      meta: { userId },
    })
  }
}

const updatePresenceCacheMutation = `
mutation UpdatePresence($presenceStatus: PresenceStatusType!) {
  updatePresence(input: { presenceStatus: $presenceStatus }) {
    presenceStatus
    lastActiveAt
    updatedAt
    accessibleWidgetIds
  }
}
`

export function doUpdatePresenceCache(presence) {
  return dispatch => {
    dispatch(
      doGraphqlRequest(
        PRESENCE_UPDATE_CACHE,
        updatePresenceCacheMutation,
        {
          presenceStatus: presence.toUpperCase(),
        },
        {
          app: true,
        }
      )
    )
  }
}
