import createCachedSelector from 're-reselect'

import selectTrelloLabels from './selectTrelloLabels'

const NO_BOARD = 'NO_BOARD'

export default createCachedSelector(
  selectTrelloLabels,
  (_, boardId) => boardId,
  (labels, boardId) => {
    if (!boardId) return []
    return labels
      .filter(label => label.idBoard === boardId)
      .map(({ color, id, name }) => ({
        color,
        text: name || color,
        value: id,
      }))
  }
)((_, boardId) => boardId || NO_BOARD)
