import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import { INBOX, KB, ADDON } from './productTypes'

export const PLAN_STATE_PAID = 'PAID'
export const PLAN_STATE_TRIAL = 'TRIAL'
export const ACTIVE_PLAN_STATES = [PLAN_STATE_PAID, PLAN_STATE_TRIAL]

export const LEGACY_INBOX_KB_COUNT = {
  'inbox-v17-free': 0,
  'inbox-v17-standard': 1,
}

export const PRICING_MODEL_USAGE = 'USAGE'
export const PRICING_MODEL_FLAT = 'FLAT'

export const BILLING_CYCLE_MONTHLY = 'monthly'
export const BILLING_CYCLE_ANNUAL = 'annual'
export const BILLING_CYCLE_ALL = 'all'

export const USAGE_FROM_TO_PRODUCT_NAME = {
  users: [app.t('Agent')],
  kbs: ['Knowledge base'],
  channels: [app.t('Mailbox'), 'es'],
  mailboxes: [app.t('Mailbox'), 'es'],
  viewer_users: ['Lite User', 's'],
}

export const USAGE_FROM_TO_PER_ITEM = {
  users: app.t('agent'),
  kbs: 'knowledge base',
  channels: app.t('mailbox'),
  mailboxes: app.t('mailbox'),
  viewer_users: 'lite User',
}

export const PRODUCT_TO_PRODUCT_NAME = {
  [KB]: 'Knowledge base',
  [INBOX]: app.t('Mailbox'),
  [ADDON]: 'Addon',
}

export const TO_SETTINGS_BILLING_PAGE = {
  type: SETTINGS_BILLING_PAGE,
  payload: {
    tab: 'plans',
  },
}

export const TO_SETTINGS_BILLING_DRAFT_CREDITS_PAGE = {
  type: SETTINGS_BILLING_PAGE,
  payload: {
    tab: 'ai-draft-credits',
  },
}
