import { oauthTokenSelector } from 'selectors/app'

import gitHubGraphQl from '../gitHubGraphQl'
import {
  FETCH_ISSUES_FOR_REPOSITORY_ERROR,
  FETCH_ISSUES_FOR_REPOSITORY_REQUEST,
  FETCH_ISSUES_FOR_REPOSITORY_SUCCESS,
} from '../types'

function doFetchGitHubIssuesForRepositoryPage(
  token,
  repositoryId,
  cursor = null,
  nodesSoFar = []
) {
  const query = `query($cursor: String, $repositoryId: ID!) {
    node(id: $repositoryId) {
      ... on Repository {
        issues(first: 100, after: $cursor, orderBy: { direction: DESC, field: CREATED_AT }) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            __typename
            id
            title
            number
            url
          }
        }
      }
    }
  }`
  const variables = {
    cursor,
    repositoryId,
  }
  return gitHubGraphQl(token, query, variables).then(({ data: { node } }) => {
    const {
      issues: { pageInfo: { endCursor, hasNextPage } = {}, nodes } = {},
    } =
      node || {}
    const newNodesSoFar = [...nodesSoFar, ...nodes]
    if (hasNextPage) {
      return doFetchGitHubIssuesForRepositoryPage(
        token,
        repositoryId,
        endCursor,
        newNodesSoFar
      )
    }
    return newNodesSoFar
  })
}

export default function doFetchGitHubIssuesForRepository(repositoryId) {
  return (dispatch, getState) => {
    const token = oauthTokenSelector(getState())

    dispatch({
      type: FETCH_ISSUES_FOR_REPOSITORY_REQUEST,
      meta: { id: repositoryId },
    })
    doFetchGitHubIssuesForRepositoryPage(token, repositoryId)
      .then(nodes => {
        dispatch({
          meta: { id: repositoryId },
          payload: {
            nodes: nodes.map(node => ({
              ...node,
              repository: { id: repositoryId },
            })),
          },
          type: FETCH_ISSUES_FOR_REPOSITORY_SUCCESS,
        })
      })
      .catch(error => {
        dispatch({
          error: true,
          meta: { id: repositoryId },
          payload: error,
          type: FETCH_ISSUES_FOR_REPOSITORY_ERROR,
        })
      })
  }
}
