import { oauthTokenSelector } from 'selectors/app'

import { selectJiraSites } from 'ducks/integrations/jiraShared'
import integrationApi from '../../api'
import doFetchJiraCloudSites from '../operations/doFetchJiraCloudSites'

import {
  FETCH_PROJECTS_ERROR,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
} from '../types'

export default function doFetchJiraCloudProjects() {
  return (dispatch, getState) => {
    const token = oauthTokenSelector(getState())
    dispatch({ type: FETCH_PROJECTS_REQUEST })
    dispatch(doFetchJiraCloudSites())
      .then(() => {
        const state = getState()
        const sites = selectJiraSites(state, { source: 'jiraCloud' })
        return Promise.all(
          sites.map(({ id: cloudId }) => {
            return integrationApi
              .get(
                token,
                `proxy/atlassian/ex/jira/${cloudId}/rest/api/3/project`,
                {
                  expand: 'issueTypes',
                }
              )
              .then(({ json }) => {
                return json.map(project => ({
                  ...project,
                  site: { id: cloudId },
                }))
              })
          })
        ).then(result => result.flat())
      })
      .then(projects => {
        dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: { projects } })
      })
      .catch(error => {
        dispatch({ error: true, type: FETCH_PROJECTS_ERROR, payload: error })
      })
  }
}
