export const FETCH_CONTACT_REQUEST =
  'integrations/hubspot/FETCH_CONTACT_REQUEST'
export const FETCH_CONTACT_SUCCESS =
  'integrations/hubspot/FETCH_CONTACT_SUCCESS'
export const FETCH_CONTACT_FAILURE =
  'integrations/hubspot/FETCH_CONTACT_FAILURE'

export const FETCH_CONTACT_PROPERTIES_REQUEST =
  'integrations/hubspot/FETCH_CONTACT_PROPERTIES_REQUEST'
export const FETCH_CONTACT_PROPERTIES_SUCCESS =
  'integrations/hubspot/FETCH_CONTACT_PROPERTIES_SUCCESS'
export const FETCH_CONTACT_PROPERTIES_FAILURE =
  'integrations/hubspot/FETCH_CONTACT_PROPERTIES_FAILURE'

export const FETCH_CONTACT_LISTS_REQUEST =
  'integrations/hubspot/FETCH_CONTACT_LISTS_REQUEST'
export const FETCH_CONTACT_LISTS_SUCCESS =
  'integrations/hubspot/FETCH_CONTACT_LISTS_SUCCESS'
export const FETCH_CONTACT_LISTS_FAILURE =
  'integrations/hubspot/FETCH_CONTACT_LISTS_FAILURE'

export const FETCH_CONTACT_DEALS_REQUEST =
  'integrations/hubspot/FETCH_CONTACT_DEALS_REQUEST'
export const FETCH_CONTACT_DEALS_SUCCESS =
  'integrations/hubspot/FETCH_CONTACT_DEALS_SUCCESS'
export const FETCH_CONTACT_DEALS_FAILURE =
  'integrations/hubspot/FETCH_CONTACT_DEALS_FAILURE'

export const FETCH_CONTACT_COMPANY_REQUEST =
  'integrations/hubspot/FETCH_CONTACT_COMPANY_REQUEST'
export const FETCH_CONTACT_COMPANY_SUCCESS =
  'integrations/hubspot/FETCH_CONTACT_COMPANY_SUCCESS'
export const FETCH_CONTACT_COMPANY_FAILURE =
  'integrations/hubspot/FETCH_CONTACT_COMPANY_FAILURE'

export const FETCH_COMPANY_PROPERTIES_REQUEST =
  'integrations/hubspot/FETCH_COMPANY_PROPERTIES_REQUEST'
export const FETCH_COMPANY_PROPERTIES_SUCCESS =
  'integrations/hubspot/FETCH_COMPANY_PROPERTIES_SUCCESS'
export const FETCH_COMPANY_PROPERTIES_FAILURE =
  'integrations/hubspot/FETCH_COMPANY_PROPERTIES_FAILURE'

export const FETCH_DEAL_PROPERTIES_REQUEST =
  'integrations/hubspot/FETCH_DEAL_PROPERTIES_REQUEST'
export const FETCH_DEAL_PROPERTIES_SUCCESS =
  'integrations/hubspot/FETCH_DEAL_PROPERTIES_SUCCESS'
export const FETCH_DEAL_PROPERTIES_FAILURE =
  'integrations/hubspot/FETCH_DEAL_PROPERTIES_FAILURE'

export const FETCH_OWNER_REQUEST = 'integrations/hubspot/FETCH_OWNER_REQUEST'
export const FETCH_OWNER_SUCCESS = 'integrations/hubspot/FETCH_OWNER_SUCCESS'
export const FETCH_OWNER_FAILURE = 'integrations/hubspot/FETCH_OWNER_FAILURE'

export const CREATE_CONTACT_REQUEST =
  'integrations/hubspot/CREATE_CONTACT_REQUEST'
export const CREATE_CONTACT_SUCCESS =
  'integrations/hubspot/CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILURE =
  'integrations/hubspot/CREATE_CONTACT_FAILURE'
