import createCachedSelector from 're-reselect'

import selectTrelloBoardsById from './selectTrelloBoardsById'

const NO_BOARD = 'NO_BOARD'

export default createCachedSelector(
  selectTrelloBoardsById,
  (_, boardId) => boardId,
  (boards, boardId) => {
    const board = boards && boards[boardId]
    return board && board.loading
  }
)((_, boardId) => boardId || NO_BOARD)
