import config from 'config'
import universal from 'react-universal-component'
import { ReactReduxContext } from 'react-redux'
import { createStoreReducer } from 'store'
import { addRoutes, pathToAction } from 'redux-first-router'
import { registerReducer } from 'reducers/page'
import { subappRegistry } from 'subapps'
import SubAppLoading from './Loading'

const SubAppError = props => {
  if (config.isDevelopment) {
    // eslint-disable-next-line no-console
    console.error('SubAppError', { props })
    throw props.error
  }
  return (
    <div id="subapp-loading">
      <div>{props.error}</div>
    </div>
  )
}

const SubAppLoader = universal(props => subappRegistry[props.subapp].loader, {
  onLoad: (module, info, props) => {
    const { subapp, contextStore } = props
    const { dispatch, replaceReducer, getState } = contextStore
    // Register any page reducers declared in the subapp
    if (module.registerPageReducer) {
      module.registerPageReducer(registerReducer)
    }

    // Register any application reducers defined by the subapp
    if (module.reducers) {
      replaceReducer(createStoreReducer({ [subapp]: module.reducers }))
    }

    // Register any dynamic routes delcared in the subapp
    if (module.routes) {
      const aThunk = addRoutes(module.routes)
      dispatch(aThunk)
      // Important we have to get the updated routesMap after we've
      // dispatched the action to add our new dynamic routes to the state
      const { routesMap, pathname, type, query } = getState().location

      // After we've loaded the module and all the additional routes we
      // need to check if perhaps a more specific route has been loaded
      // in. If thats the case, we should dispatch a new action
      // to redirect to the updated route
      const newAction = pathToAction(pathname, routesMap)
      if (newAction.type !== type) {
        // We need to trick redux-first-router into thinking this action
        // is a load operation to ensure that the type and payload in
        // state.location is updated correctly
        newAction.meta = {
          location: {
            kind: 'load',
          },
          // Ensure any query string parameters are preserved
          query,
        }
        dispatch(newAction)
      } else if (module.routes[type] && module.routes[type].thunk) {
        // Entry points cannot have thunk functions because those functions would
        // have to be included from the subapp breaking code splitting. The way
        // around this problem is to add the thunk to the redefined entrypoint
        // in the subapp/${subapp}/routes.js file. The only problem then is that
        // the check above prevent duplicate calls to the same route. Because the
        // thunk was lazyloaded, we need to call it for the first time here. After
        // that redux-first-router will ensure it gets called
        const thunkFunc = module.routes[type].thunk
        thunkFunc(dispatch, getState)
      }
    }
  },

  render: ({ contextStore, ...props }, Mod, isLoading, error) => {
    let child = <SubAppLoading {...props} />
    if (isLoading) child = <SubAppLoading {...props} />
    else if (error) child = <SubAppError {...props} error={error} />
    else if (Mod)
      child = (
        <Mod componentName={props.componentName} props={props.subprops || {}} />
      )

    return (
      <div id="app-container" className="fullHeight">
        <div id="subapp-container" className="fullHeight">
          {child}
        </div>
      </div>
    )
  },
})

// https://github.com/faceyspacey/react-universal-component/issues/172

const ContextFix = props => {
  return (
    <ReactReduxContext.Consumer>
      {contextProps => {
        return <SubAppLoader {...props} contextStore={contextProps.store} />
      }}
    </ReactReduxContext.Consumer>
  )
}

export function preloadSubapp(subapp) {
  universal(subappRegistry[subapp].loader).preload()
}

export default ContextFix
