import storage from 'util/storage'
import { IN_APP_CARD_CHANGE_OPEN_STATE } from './types'

const STORAGE_KEY = 'inappcards-open'

const reducers = {}
const stored = storage.get(STORAGE_KEY)
const initialState = stored || {
  'recent-tickets': true,
  'custom-profile': true,
}

reducers[IN_APP_CARD_CHANGE_OPEN_STATE] = (state = initialState, action) => {
  const { key, isOpen } = action.payload
  const newState = {
    ...state,
    [key]: isOpen,
  }
  storage.set(STORAGE_KEY, newState)
  return newState
}

export default (state = initialState, action) => {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
