import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_SHOP_INFO_REQUEST_STARTED,
  FETCH_SHOP_INFO_REQUEST_FAILED,
  FETCH_SHOP_INFO_REQUEST_SUCCESS,
} from '../types'

const initialState = {
  defaultCurrencyCode: 'USD',
  byIntegrationId: {},
}

export default createActionTypeReducer(
  {
    [FETCH_SHOP_INFO_REQUEST_STARTED]: (draftState, action) => {
      const {
        meta: { integrationId },
      } = action

      if (!integrationId) return draftState

      if (!draftState.byIntegrationId[integrationId])
        draftState.byIntegrationId[integrationId] = {}

      draftState.byIntegrationId[integrationId].isLoading = true
      draftState.byIntegrationId[integrationId].loaded = false

      return draftState
    },
    [FETCH_SHOP_INFO_REQUEST_FAILED]: (draftState, action) => {
      const {
        meta: { integrationId },
      } = action

      if (!integrationId) return draftState

      if (!draftState.byIntegrationId[integrationId])
        draftState.byIntegrationId[integrationId] = {}

      draftState.byIntegrationId[integrationId].isLoading = false
      draftState.byIntegrationId[integrationId].loaded = false

      return draftState
    },
    [FETCH_SHOP_INFO_REQUEST_SUCCESS]: (draftState, action) => {
      const {
        payload: { shop },
        meta: { integrationId },
      } = action

      if (!integrationId) return draftState

      if (!draftState.byIntegrationId[integrationId])
        draftState.byIntegrationId[integrationId] = {}

      draftState.byIntegrationId[integrationId].isLoading = false
      draftState.byIntegrationId[integrationId].loaded = true
      draftState.byIntegrationId[integrationId].currencyCode = shop.currencyCode
      draftState.byIntegrationId[integrationId].contactEmail = shop.contactEmail

      return draftState
    },
  },
  initialState
)
