import moment from 'moment'
import { createSelector } from 'reselect'
import { selectCurrentAccount } from 'ducks/accounts/selectors/selectCurrentAccount'

export const selectDaysLeftBeforeExpiring = createSelector(
  selectCurrentAccount,
  account =>
    account.expiresAt
      ? moment(account.expiresAt).diff(moment(), 'minutes') / 1440
      : null
)
