import { doSendChangeset } from 'actions/tickets/changeset/doSendChangeset'
import { reactionMessagePayload } from 'optimistic/message'
import { selectCurrentFolderId } from 'selectors/app'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectComputedReactions } from 'selectors/currentChangesets'
import { findAddedReactionAction } from 'ducks/comments/util'
import { v4 as uuidV4 } from 'uuid'

export const doReact = input => (dispatch, getState) => {
  const { ticketId, commentId, reaction } = input

  const state = getState()
  const changesetId = uuidV4()
  const agent = selectCurrentUser(state)
  const computedReactions = selectComputedReactions(state)

  const existingAddedReaction = findAddedReactionAction(
    computedReactions,
    commentId,
    agent.id,
    reaction
  )

  const optimisticData = reactionMessagePayload(
    state,
    ticketId,
    commentId,
    reaction,
    !existingAddedReaction,
    selectCurrentFolderId(state),
    selectLatestTicketSearchQueryObject(state),
    changesetId
  )

  const base = { commentId }

  if (existingAddedReaction) {
    base.reactionToRemove = reaction
  } else {
    base.reactionToAdd = reaction
  }

  return dispatch(
    doSendChangeset(ticketId, base, {
      optimisticData,
      disableNavigateToFolder: true,
    })
  )
}
