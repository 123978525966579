/* eslint-disable no-param-reassign */
import { createEntityReducer } from 'util/reducers'

import {
  CREATE_CONTACT_STARTED,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILED,
  CHANGE_CONVERSATION_CONTACT_FAILED,
  FETCH_CONVERSATION_CONTACT_SUCCESS,
} from '../types'

export default createEntityReducer('contacts', {
  transformEntity: contact => {
    const newContact = { ...contact }
    if (newContact.customFieldValues && newContact.customFieldValues.nodes) {
      newContact.customFieldValues = newContact.customFieldValues.nodes.reduce(
        (result, fieldValue) => {
          const {
            key: valueKey, // This is actually the custom field key but sometimes it is on the value
            customField: { isArray, key = valueKey } = {},
            mode,
            ...rest
          } = fieldValue
          const existingValue = result[key]
          if (isArray) {
            rest.value = [...(existingValue?.value || []), rest.value]
          }
          result[key] = { key, ...rest }
          return result
        },
        {}
      )
    }
    if (newContact.companies) {
      newContact.companies = newContact.companies.nodes
    }
    return newContact
  },
  reduce: (draftState, { type, entities, meta: { contactIdOrTemp } = {} }) => {
    if (type === FETCH_CONVERSATION_CONTACT_SUCCESS) {
      Object.keys(entities?.contacts || {}).forEach(contactId => {
        if (
          draftState[contactId] &&
          draftState[contactId].isLoaded &&
          entities.contacts[contactId].isMissing
        ) {
          // HACK (jscheel): We should not overwrite a contact if it was not
          // previously missing, even if we think it actually is missing now.
          // This prevents bad responses from the API from blowing away good
          // data that we already have in the store. It's possible that the
          // contact could have actually been removed from the backend, but we
          // would rather fail on the off chance that someone tries to edit it
          // from the sidebar than failing with a big honking "missing contact"
          // because of wonky edge cases where the request failed.
          draftState[contactId].isMissing = false
        }
      })
    }

    if (!contactIdOrTemp) return

    if (type === CREATE_CONTACT_STARTED) {
      draftState[contactIdOrTemp].isCreating = true
    } else if (type === CREATE_CONTACT_SUCCESS) {
      draftState[contactIdOrTemp].isCreating = false
    } else if (
      [CREATE_CONTACT_FAILED, CHANGE_CONVERSATION_CONTACT_FAILED].includes(type)
    ) {
      draftState[contactIdOrTemp].isCreating = false
    }
  },
})
