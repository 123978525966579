import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { FETCH_PAGES, BULK_UPDATE_PAGES } from './types'
import { pagesQuery, updatePageMutation } from './api'
import { fetchPagesSchema, bulkUpdatePagesSchema } from './schema'
import { selectCurrentFacebookPages } from './selectors'

const LOAD_ALL_FACEBOOKPAGES_QUERYID = 'entityType:facebookPage pageSize:10000'

export const doFetchFacebookPages = ({
  skipLoaded,
  version: inputVersion,
  bridgeType: inputBridgeType,
} = {}) => (dispatch, getState) => {
  const version = inputVersion || 2
  const bridgeType = inputBridgeType || '*'
  const queryId = LOAD_ALL_FACEBOOKPAGES_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale
  const entities = selectCurrentFacebookPages(state)

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({
      facebookPages: entities,
    })
  }

  return dispatch(
    doGraphqlRequest(
      FETCH_PAGES,
      pagesQuery(),
      {
        version,
        bridgeType,
      },
      {
        normalizationSchema: fetchPagesSchema,
        searches: {
          queryId,
          cursor,
          extractPagination: ({ facebookPages: nodes }) => {
            return {
              type: 'facebookPage',
              nodes,
              startCursor: 1,
              endCursor: 1,
              hasNextPage: false,
              hasPreviousPage: false,
              totalCount: nodes.length,
              totalPageCount: 1,
            }
          },
        },
        moduleOptions: {
          entities: {
            additionalActions: [
              {
                entityType: 'facebookPage',
                stores: ['current', 'pending'],
                phases: ['SUCCESS'],
                type: 'clear',
              },
            ],
          },
        },
      }
    )
  )
}

export const doBulkUpdateFacebookPages = (
  facebookPages,
  options = {}
) => dispatch => {
  const { version: inputVersion } = options
  const version = inputVersion || 2
  const subscriptions = facebookPages.map(({ id, subscribed, widgetId }) => ({
    id,
    subscribed,
    widgetId,
    destroyMailboxOnUnsubscribe: false,
  }))

  return dispatch(
    doGraphqlRequest(
      BULK_UPDATE_PAGES,
      updatePageMutation(),
      {
        version,
        subscriptions,
      },
      {
        normalizationSchema: bulkUpdatePagesSchema,
        ...options,
      }
    )
  )
}
