import { schema } from 'normalizr'

import { customFieldCategoryNormalizationSchema } from '../customFieldCategories/schema'

export const customFieldNormalizationSchema = new schema.Entity(
  'customFields',
  { category: customFieldCategoryNormalizationSchema }
)

export const customFieldsNormalizationSchema = {
  customFields: {
    nodes: [customFieldNormalizationSchema],
  },
}
