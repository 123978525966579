import { createSelector } from 'reselect'

import { objectToId } from 'util/objects'

import { selectCurrentSearchTerm } from '../../selectors'
import { selectPageCursor } from './base'
import { selectPageOrderBy } from './sorting'

export const selectPageRequestVariables = createSelector(
  selectCurrentSearchTerm,
  selectPageCursor,
  selectPageOrderBy,
  (term, cursor, orderBy) => {
    return {
      ...cursor,
      first: 10,
      filter: {
        search: term,
      },
      orderBy,
    }
  }
)

export const selectPageRequestKey = createSelector(
  selectPageRequestVariables,
  objectToId
)
