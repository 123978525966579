import { createSelector } from 'reselect'
import { selectCurrentContact } from 'ducks/crm/contacts/selectors'
import { selectCurrentCompany } from 'ducks/crm/companies/selectors'
import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'

export const selectIntegrationData = state =>
  state.integrations.thirdPartyData.salesforce

export const selectData = state =>
  state.integrations.thirdPartyData.salesforce.data

export const selectIntegrationContactsData = state =>
  state.integrations.thirdPartyData.salesforce.contacts

export const selectContactProperties = createSelector(
  selectIntegrationContactsData,
  data => data.contactPropertiesByName
)

export const selectObjectsByEmail = createSelector(
  selectData,
  data => data.byEmail || emptyObj
)

export const selectObjectByEmail = (state, email) => {
  return selectObjectsByEmail(state)[email]
}

export const selectRelatedObjectsByObjectId = createSelector(
  selectData,
  data => data.relatedById || emptyObj
)

export const selectRelatedObjectsForObject = (state, email) => {
  try {
    const related = selectRelatedObjectsByObjectId(state)
    return related[email]
  } catch (e) {
    return null
  }
}

export const selectSpecificRelatedObjectsForObject = (state, email, type) => {
  try {
    const related = selectRelatedObjectsForObject(state, email)
    return related && related.byType ? related.byType[type] : null
  } catch (e) {
    return null
  }
}

export const selectObjectStateByEmail = (state, email) => {
  const found = selectObjectByEmail(state, email)
  if (found && found.state !== 'failure' && found.count === 0) return 'missing'
  return found ? found.state : 'unknown'
}

// Leads
export const selectIntegrationLeadsData = state =>
  state.integrations.thirdPartyData.salesforce.leads

export const selectLeadProperties = createSelector(
  selectIntegrationLeadsData,
  data => data.leadsPropertiesByName
)

export const selectProperties = createSelector(
  selectData,
  data => data.properties || emptyObj
)

export const selectPropertiesByType = (state, type) => {
  return selectProperties(state)[type]
}

export const selectLeadsById = createSelector(
  selectIntegrationLeadsData,
  data => data.byId || emptyObj
)

export const selectLeadByEmail = (state, email) => {
  return selectLeadsById(state)[email]
}

export const selectLeadStateByEmail = (state, email) => {
  const found = selectLeadByEmail(state, email)
  if (found && found.state === 'failed' && found.error.code === 404)
    return 'missing'
  return found ? found.state : 'unknown'
}

// New Contact
export const selectNewContactInitialValues = createSelector(
  selectCurrentContact,
  selectCurrentCompany,
  (contact, company) => {
    return {
      Email: contact?.email,
      FirstName: contact?.firstName,
      LastName: contact?.lastName,
      // eslint-disable-next-line camelcase
      Phone: contact?.customFieldValues?.contact_phone_number?.value?.content,
      // eslint-disable-next-line camelcase
      Website: contact?.customFieldValues?.contact_website?.value?.text,
      Company: company?.name,
    }
  }
)

export const selectCreateContactErrors = createSelector(
  selectData,
  data => data.createContactErrors || emptyArr
)
