import { PAGE_COMPANIES } from '../types'

const defaultState = null

export default function reduceCursor(
  state = defaultState,
  { type, payload = {} }
) {
  if (type === PAGE_COMPANIES) return payload
  return state
}
