import { createSelector } from 'reselect'
import { selectRequestsById } from 'selectors/requests'
import {
  selectCurrentEntities,
  selectCurrentEntityById,
} from 'ducks/entities/selectors'

import { emptyArr } from 'util/arrays'

const selectBase = state => state.integrations.emailMarketing

export const selectEmailMarketingIntegrations = createSelector(
  selectBase,
  base => {
    return Object.values(base.integrations.byId) || emptyArr
  }
)

export const selectIsEmailMarketingIntegrationLoading = createSelector(
  selectRequestsById,
  (state, { contactId, integrationId }) =>
    `FetchEmailMarketingIntegrationState:${integrationId}:${contactId}`,
  (requestsById, id) => {
    return requestsById[id]?.loading
  }
)

export const selectIsEmailMarketingIntegrationFailed = createSelector(
  selectRequestsById,
  (state, { contactId, integrationId }) =>
    `FetchEmailMarketingIntegrationState:${integrationId}:${contactId}`,
  (requestsById, id) => {
    return requestsById[id]?.failed
  }
)

export const selectEmailMarketingSubscriptions = createSelector(
  selectBase,
  (state, { contactId, integrationId }) => `${integrationId}:${contactId}`,
  (base, id) => {
    return base.contactState.byId[id] || emptyArr
  }
)

export const selectEmailMarketingAvailableEmailLists = createSelector(
  selectBase,
  (state, { integrationId }) => integrationId,
  (base, integrationId) => {
    return base.integrations.byId[integrationId]?.lists
  }
)

export const selectEmailMarketingAvailableLists = createSelector(
  selectEmailMarketingAvailableEmailLists,
  selectEmailMarketingSubscriptions,
  (emailLists, subscriptions) => {
    return emailLists.filter(list => {
      return !subscriptions.map(({ id }) => id).includes(list.id)
    })
  }
)

export const selectCurrentEmailMarketingIntegrations = state => {
  return selectCurrentEntities(state, 'emailMarketingIntegration')
}

export const selectEmailMarketingIntegrationByProviderId = (
  state,
  providerId
) => {
  return selectCurrentEntityById(
    state,
    'emailMarketingIntegration',
    providerId,
    null,
    true
  )
}
