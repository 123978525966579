import createCachedSelector from 're-reselect'

import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'

import { selectBase } from './selectBase'

const ticketIdAndTypeCacheKey = (state, ticketId, { type }) =>
  `${ticketId || 'unknown'}-${type || 'unknown'}`

const selectSuggestions = createBasicSelector(
  selectBase,
  ai => ai.suggestions || emptyObj
)

const selectSuggestionsByTicketId = createBasicSelector(
  selectSuggestions,
  suggestions => suggestions.byTicketId || emptyObj
)

export const selectSuggestionsForTicketId = createCachedSelector(
  selectSuggestionsByTicketId,
  (state, ticketId) => ticketId,
  (byTicketId, ticketId) => byTicketId[ticketId] || emptyObj
)((state, ticketId) => ticketId || 'unknown')

export const selectSuggestionsIsLoading = createCachedSelector(
  selectSuggestionsForTicketId,
  byTicketId => byTicketId.isLoading
)((state, ticketId) => ticketId || 'unknown')

export const selectSuggestionsIsLoaded = createCachedSelector(
  selectSuggestionsForTicketId,
  byTicketId => byTicketId.isLoaded
)((state, ticketId) => ticketId || 'unknown')

export const selectSuggestionForType = createCachedSelector(
  selectSuggestionsForTicketId,
  (state, ticketId, { type }) => type,
  (byTicketId, type) => byTicketId[type] || emptyObj
)(ticketIdAndTypeCacheKey)
