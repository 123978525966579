export const BASE_TYPE_REGEX = /^integrations\/github\//

export const GITHUB_ISSUE_CHANGED = 'integrations/github/GITHUB_ISSUE_CHANGED'

export const FETCH_INSTALLS_ERROR = 'integrations/github/FETCH_INSTALLS_ERROR'
export const FETCH_INSTALLS_REQUEST =
  'integrations/github/FETCH_INSTALLS_REQUEST'
export const FETCH_INSTALLS_SUCCESS =
  'integrations/github/FETCH_INSTALLS_SUCCESS'

export const CREATE_ISSUE_ERROR = 'integrations/github/CREATE_ISSUE_ERROR'
export const CREATE_ISSUE_REQUEST = 'integrations/github/CREATE_ISSUE_REQUEST'
export const CREATE_ISSUE_SUCCESS = 'integrations/github/CREATE_ISSUE_SUCCESS'

export const FETCH_DETAILS_FOR_REPOSITORY_ERROR =
  'integrations/github/FETCH_DETAILS_FOR_REPOSITORY_ERROR'
export const FETCH_DETAILS_FOR_REPOSITORY_REQUEST =
  'integrations/github/FETCH_DETAILS_FOR_REPOSITORY_REQUEST'
export const FETCH_DETAILS_FOR_REPOSITORY_SUCCESS =
  'integrations/github/FETCH_DETAILS_FOR_REPOSITORY_SUCCESS'

export const FETCH_ISSUES_ERROR = 'integrations/github/FETCH_ISSUES_ERROR'
export const FETCH_ISSUES_REQUEST = 'integrations/github/FETCH_ISSUES_REQUEST'
export const FETCH_ISSUES_SUCCESS = 'integrations/github/FETCH_ISSUES_SUCCESS'

export const FETCH_ISSUES_FOR_REPOSITORY_ERROR =
  'integrations/github/FETCH_ISSUES_FOR_REPOSITORY_ERROR'
export const FETCH_ISSUES_FOR_REPOSITORY_REQUEST =
  'integrations/github/FETCH_ISSUES_FOR_REPOSITORY_REQUEST'
export const FETCH_ISSUES_FOR_REPOSITORY_SUCCESS =
  'integrations/github/FETCH_ISSUES_FOR_REPOSITORY_SUCCESS'

export const FETCH_REPOSITORIES_ERROR =
  'integrations/github/FETCH_REPOSITORIES_ERROR'
export const FETCH_REPOSITORIES_REQUEST =
  'integrations/github/FETCH_REPOSITORIES_REQUEST'
export const FETCH_REPOSITORIES_SUCCESS =
  'integrations/github/FETCH_REPOSITORIES_SUCCESS'
