import { emptyObj } from 'util/objects'

export function selectIsMatrixClientReady(state) {
  const { chat: { ready: { client } = {} } = emptyObj } = state
  return !!client
}

export function selectIsMatrixUiReady(state) {
  const { chat: { ready: { ui } = {} } = emptyObj } = state
  return !!ui
}

export function selectIsMatrixClientAndUiReady(state) {
  return selectIsMatrixClientReady(state) && selectIsMatrixUiReady(state)
}
