import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'
import {
  FETCH_PRIORITIES_ERROR,
  FETCH_PRIORITIES_REQUEST,
  FETCH_PRIORITIES_SUCCESS,
} from '../types'

export default function doFetchJiraCloudPriorities() {
  return (dispatch, getState) => {
    const token = oauthTokenSelector(getState())
    dispatch({
      type: FETCH_PRIORITIES_REQUEST,
    })
    integrationApi
      .get(token, `proxy/jira-server/rest/api/2/priority`)
      .then(({ json: payload }) => {
        dispatch({
          type: FETCH_PRIORITIES_SUCCESS,
          payload,
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_PRIORITIES_ERROR,
          error: true,
          payload: error,
        })
      })
  }
}
