import { BEGIN, REVERT } from 'redux-optimist'
import { v4 as uuidV4 } from 'uuid'

import graphql, { mapErrorsToFormFields } from 'api/graphql'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentCustomerId } from 'selectors/tickets/customer/selectCurrentCustomerId'
import { pick } from 'util/objects'

import { byIdQuery, byEmailAddressQuery } from './api'
import {
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  REMOVE_CUSTOMER,
  FETCH_CUSTOMERS,
} from './actionTypes'

function updateCustomerRequest(optimistId, customerId, params) {
  return {
    type: UPDATE_CUSTOMER_REQUEST,
    data: {
      customerId,
      params,
    },
    optimist: { type: BEGIN, id: optimistId },
  }
}

function updateCustomerSuccess(optimistId, customerId, params) {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    data: {
      customerId,
      params,
    },
    optimist: { type: REVERT, id: optimistId },
  }
}
function updateCustomerError(optimistId, customerId, error) {
  return {
    type: UPDATE_CUSTOMER_FAIL,
    error: true,
    data: { customerId, message: error.message },
    optimist: { type: REVERT, id: optimistId },
  }
}

export const updateCustomerById = (customerId, customer) => {
  return (dispatch, getState) => {
    const { name } = customer
    const hasName = name || name === ''
    const names = hasName && name.split(' ')
    const mappedParams = pick(
      [
        'email',
        'company_name',
        'about',
        'location',
        'name',
        'first_name',
        'last_name',
        'phone_number',
        'title',
        'website_url',
      ],
      customer,
      false
    )
    if (hasName) {
      mappedParams.first_name = names && names[0]
      mappedParams.last_name = names && names.splice(1).join(' ')
    }

    const state = getState()
    const token = oauthTokenSelector(state)
    const query = `
      mutation UpdateCustomer(
        $id: String!,
        $email: String,
        $company_name: String,
        $about: String,
        $location: String,
        $first_name: String,
        $last_name: String,
        $phone_number: String,
        $title: String,
        $website_url: String,
      ) {
        updateCustomer(
          id: $id,
          email: $email,
          company_name: $company_name,
          about: $about,
          location: $location,
          first_name: $first_name,
          last_name: $last_name,
          phone_number: $phone_number,
          title: $title,
          website_url: $website_url,
        ) {
          id
          email
          company_name
          about
          location
          first_name
          last_name
          name
          phone_number
          title
          website_url
        }
      }
    `
    const variables = {
      ...mappedParams,
      id: customerId,
    }

    const optimistId = uuidV4()
    dispatch(updateCustomerRequest(optimistId, customerId, mappedParams))

    return graphql(token, query, variables)
      .then(response => {
        const newParams = response.json.data.updateCustomer
        return dispatch(
          updateCustomerSuccess(optimistId, customerId, newParams)
        )
      })
      .catch(error => {
        dispatch(updateCustomerError(optimistId, customerId, error))
        throw error
      })
      .catch(mapErrorsToFormFields)
  }
}

export const updateCustomer = customer => {
  return (dispatch, getState) => {
    const customerId = selectCurrentCustomerId(getState())
    return dispatch(updateCustomerById(customerId, customer))
  }
}

function transformDoFetchCustomersResponse(data) {
  if (data.customers && data.customers.records) {
    return { customers: data.customers.records }
  }
  throw new Error('Invalid response received for doFetchCustomers')
}

export function doFetchCustomersById(ids = []) {
  return doGraphqlRequest(
    FETCH_CUSTOMERS,
    byIdQuery(),
    {
      ids,
    },
    {
      transformResponse: transformDoFetchCustomersResponse,
    }
  )
}

export function doFetchCustomersByEmails({ emailAddresses }) {
  return doGraphqlRequest(
    FETCH_CUSTOMERS,
    byEmailAddressQuery(),
    {
      // TODO: Refactor fucking graphql to accept an array of strings.
      emailAddresses: emailAddresses.join(','),
    },
    {
      transformResponse: transformDoFetchCustomersResponse,
    }
  )
}

export function doUpdateCustomer(customer) {
  return {
    type: UPDATE_CUSTOMER,
    data: customer,
  }
}

export function doRemoveCustomer(id) {
  return {
    type: REMOVE_CUSTOMER,
    data: id,
  }
}
