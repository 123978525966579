import {
  selectCurrentPage,
  selectQueryParams,
  selectCurrentPayload,
} from 'selectors/location'
import { omit } from 'util/objects'
import { selectDrawerStack } from './selectors'
import drawerTypes from './types'
import { buildDrawerQueryParam } from './util'

export const doOpenDrawer = (
  drawerId,
  drawerType,
  drawerResourceId,
  options = {}
) => {
  const { clearStack = false } = options
  if (!drawerTypes.includes(drawerType))
    throw new Error(`Unable to find the drawer type ${drawerType}.`)

  return (dispatch, getState) => {
    const state = getState()
    const pageQuery = selectQueryParams(state)
    const pageType = selectCurrentPage(state)
    const pagePayload = selectCurrentPayload(state)

    const drawerKeys = Object.keys(pageQuery).filter(k =>
      k.startsWith('drawer')
    )

    const openDrawerQuery = omit(
      [...drawerKeys, ...(options.removeQueryByKeys || [])],
      pageQuery
    )

    const updatedQuery = {
      ...openDrawerQuery,
      ...{
        [`drawer-${drawerId}`]: drawerType,
      },
      ...(options.query || {}),
    }
    if (drawerResourceId) {
      updatedQuery[`drawerResourceId-${drawerId}`] = drawerResourceId
    }

    return dispatch({
      type: pageType,
      payload: pagePayload,
      meta: {
        query: updatedQuery,
        fromDrawerAction: true,
      },
      drawers: {
        clearStack,
      },
    })
  }
}

export const doCloseDrawer = (options = {}) => {
  return (dispatch, getState) => {
    const { ignoreStack = false } = options
    const state = getState()
    const pageQuery = selectQueryParams(state)
    const pageType = selectCurrentPage(state)
    const pagePayload = selectCurrentPayload(state)
    const previousDrawerOnStack = selectDrawerStack(state)[0] || {}
    const previousDrawer = ignoreStack ? {} : previousDrawerOnStack

    const drawerKeys = Object.keys(pageQuery).filter(k =>
      k.startsWith('drawer')
    )

    const closedDrawerQuery = omit(
      [...drawerKeys, ...(options.removeQueryByKeys || [])],
      pageQuery
    )

    return dispatch({
      type: pageType,
      payload: pagePayload,
      meta: {
        query: {
          ...closedDrawerQuery,
          ...previousDrawer,
          ...(options.query || {}),
        },
        fromDrawerAction: true,
      },
      drawers: {
        clearStack: ignoreStack,
      },
    })
  }
}

export const doAddDrawerParam = (
  drawerId,
  paramKey,
  paramValue,
  options = {}
) => {
  return (dispatch, getState) => {
    const state = getState()
    const pageQuery = selectQueryParams(state)
    const pageType = selectCurrentPage(state)
    const pagePayload = selectCurrentPayload(state)

    const drawerQuery = omit(options.removeQueryByKeys || [], pageQuery)

    return dispatch({
      type: pageType,
      payload: pagePayload,
      meta: {
        query: {
          ...drawerQuery,
          ...(options.query || {}),
          ...buildDrawerQueryParam(drawerId, paramKey, paramValue),
        },
        fromDrawerAction: true,
      },
    })
  }
}
