import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'

import selectJiraSites from './selectJiraSites'

export default createCachedSelector(
  selectJiraSites,
  (state, { siteName }) => siteName,
  (sites, siteName) => {
    const { id } = sites.find(({ name }) => name === siteName) || emptyObj
    return id
  }
)((state, { siteName }) => siteName || 'NULL')
