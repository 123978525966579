import { createBasicSelector } from 'util/redux'
import {
  selectPrefersKbBillingVersion,
  selectPrefersInboxBillingVersion,
  selectPrefersAddonBillingVersion,
} from 'selectors/app/selectAccountPreferences'
import { ADDON, INBOX, KB } from '../productTypes'

export const selectPreferredBillingVersionByProduct = createBasicSelector(
  selectPrefersKbBillingVersion,
  selectPrefersInboxBillingVersion,
  selectPrefersAddonBillingVersion,
  (
    accountKbBillingVersion,
    accountInboxBillingVersion,
    accountAddonBillingVersion
  ) => {
    return {
      [INBOX]: accountInboxBillingVersion,
      [KB]: accountKbBillingVersion,
      [ADDON]: accountAddonBillingVersion,
    }
  }
)
