import { createSelector } from 'reselect'

import selectCurrentTicketLinkedIssues from './selectCurrentTicketLinkedIssues'

const NODE_ID_REGEX = /[^\d]/

export default createSelector(selectCurrentTicketLinkedIssues, issues => {
  if (!issues) return issues
  return issues.map(({ externalId, url }) => {
    return externalId.match(NODE_ID_REGEX) ? externalId : url
  })
})
