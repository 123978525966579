import { contactCustomFieldValuesSubQuery } from './contactCustomFieldValuesSubQuery'

export const fetchConversationContactCustomFieldsQuery = `
  query ConversationContactByNumber($after: String, $number: Int!) {
    conversation(number: $number) {
      id
      number
      contact {
        id
        companies {
          nodes {
            id
          }
        }
        ${contactCustomFieldValuesSubQuery}
      }
    }
  }
`
