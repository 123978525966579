import { createSelector } from 'reselect'
import { selectFetchAppBootstrapRequest } from 'ducks/bootstrap/selectors/selectFetchAppBootstrapRequest'
import { selectBase } from './selectBase'
import { selectFetchBillingDataV2Request } from './selectFetchBillingDataV2Request'

export const selectIsBillingDataLoaded = createSelector(
  selectBase,
  selectFetchAppBootstrapRequest,
  selectFetchBillingDataV2Request,
  (base, bootstrapRequest, v2BillingRequest) =>
    !!base.isLoaded && (!!bootstrapRequest.loaded || !!v2BillingRequest.loaded)
)
