const typesConfig = {
  assignee_group_id: 'Assign to',
  assignee_id: 'Assign to',
  snooze_until: 'Snooze for',
  status: 'Change to',
}

export const getActionTitle = ({ type, label }) => {
  return typesConfig[type] ? `${typesConfig[type]} ${label}` : label
}
