import { oauthTokenSelector } from 'selectors/app'

import { selectJiraProjectKeyForProjectId } from 'ducks/integrations/jiraShared'
import integrationApi from '../../api'
import {
  FETCH_ASSIGNABLE_USERS_ERROR,
  FETCH_ASSIGNABLE_USERS_REQUEST,
  FETCH_ASSIGNABLE_USERS_SUCCESS,
} from '../types'

export default function doFetchJiraServerAssignableUsers(projectId) {
  return (dispatch, getState) => {
    const meta = { projectId }
    const state = getState()
    const token = oauthTokenSelector(state)
    const projectKeys = selectJiraProjectKeyForProjectId(state, {
      source: 'jiraServer',
      projectId,
    })
    dispatch({
      type: FETCH_ASSIGNABLE_USERS_REQUEST,
      meta,
    })
    integrationApi
      .get(
        token,
        `proxy/jira-server/rest/api/2/user/assignable/multiProjectSearch`,
        {
          projectKeys,
          maxResults: 500,
        }
      )
      .then(({ json: payload }) => {
        dispatch({
          type: FETCH_ASSIGNABLE_USERS_SUCCESS,
          meta,
          payload,
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_ASSIGNABLE_USERS_ERROR,
          error: true,
          meta,
          payload: error,
        })
      })
  }
}
