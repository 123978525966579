import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectAgents, selectRawAgents } from 'selectors/agents/base'
import { selectContactByRoomId } from './contacts'
import { getMatrixClient } from '../utils/client'
import {
  getChatAdminUserId,
  isAgentUser,
  isEndUser,
  getAvatarUrl,
} from '../utils/users'
import { CHAT_ADMIN_NAME } from '../utils/constants'
import { selectRoomFromUrl } from './rooms'

export function selectRoomContactForRoomFromUrl(state) {
  const room = selectRoomFromUrl(state)
  return selectContactByRoomId(state)[room.matrixRoomId] || emptyObj
}

export function selectCurrentMatrixUserId(state) {
  const room = selectRoomFromUrl(state)
  return room.firstEnduserId
}

export function selectMemberUserIdFromProps(_, props) {
  const {
    member: { userId: memberUserId },
  } = props
  return memberUserId
}

export function selectMemberNameFromProps(state, props) {
  const {
    member: {
      userId,
      name,
      rawDisplayName,
      user,
      roomId,
      events: { member: memberEvent },
    },
  } = props
  const { displayName } = user || {}

  const { displayname: eventDisplayName } =
    (memberEvent && memberEvent.getContent()) || {}

  let finalName = name || rawDisplayName || displayName || eventDisplayName
  if (finalName === userId) {
    const {
      displayName: loadedDisplayName,
      rawDisplayName: loadedRawDisplayName,
    } =
      getMatrixClient().getUser(userId) || {}
    finalName = loadedRawDisplayName || loadedDisplayName
  }
  if (finalName === userId) {
    finalName = null
  }

  // Sometimes the member information isnt present on the event
  // at which case it defaults the the matrix user id. First
  // we check if the name is actually a matrix id
  if (isAgentUser(userId)) {
    return finalName || app.t('Agent')
  } else if (isEndUser(userId)) {
    const { name: contactName } =
      selectContactByRoomId(state)[roomId] || emptyObj
    return contactName || finalName || 'Customer'
  }
  return CHAT_ADMIN_NAME
}

export function selectMemberAvatarUrlFromProps(state, props) {
  const {
    member: {
      userId,
      roomId,
      user,
      events: { member: memberEvent },
    },
  } = props
  const { avatarUrl } = user || {}
  const { avatar_url: eventAvatarUrl } =
    (memberEvent && memberEvent.getContent()) || {}

  if (isEndUser(userId)) {
    const { avatarUrl: contactAvatarUrl } =
      selectContactByRoomId(state)[roomId] || emptyObj
    return contactAvatarUrl
  }
  return getAvatarUrl(avatarUrl || eventAvatarUrl)
}

export const selectChatAdminAgent = () => {
  const chatAdminId = getChatAdminUserId()
  return {
    agentId: '0000000000',
    archived: false,
    avatarUrl: null,
    avatar_url: 'https://groovehq.docker/avatars/original/missing.png',
    chatId: chatAdminId,
    created_at: '2020-01-10T00:00:00Z',
    email: 'bot@groovehq.com',
    first_name: CHAT_ADMIN_NAME,
    fullName: CHAT_ADMIN_NAME,
    id: '0000000000',
    initial: 'B',
    initials: 'B',
    isAssignedToSelf: false,
    label: CHAT_ADMIN_NAME,
    labelFull: CHAT_ADMIN_NAME,
    last_name: '',
    name: CHAT_ADMIN_NAME,
    role: 'admin',
    shortName: 'BotFinbar',
    type: 'Agent',
    username: CHAT_ADMIN_NAME.toLowerCase(),
  }
}

export const selectAgentsByIdOrChatId = createSelector(
  selectRawAgents,
  selectChatAdminAgent,
  (agents, chatAdminAgent) => {
    return [...agents, chatAdminAgent].reduce((byId, agent) => {
      // eslint-disable-next-line no-param-reassign
      byId[agent.agentId] = agent
      // eslint-disable-next-line no-param-reassign
      byId[agent.chatId] = agent
      return byId
    }, {})
  }
)

export function makeSelectMemberAvatarUrl() {
  return createSelector(
    selectAgents,
    selectMemberUserIdFromProps,
    (agents, userId) => {
      const { avatar_url: avatarUrl = null } =
        agents.find(a => a.chatId === userId) || {}
      // When the avatar is missing, we want to return null
      // instead of the missing avatar image to ensure that
      // the matrix component still generates the  initial
      // avatar
      if (avatarUrl && avatarUrl.includes('missing.png')) {
        return null
      }
      return avatarUrl
    }
  )
}
