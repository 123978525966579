import {
  BIGCOMMERCE_DOMAIN,
  SHOPIFY_DOMAIN,
} from 'ducks/integrations/shared/constants'

export const MISSING_VALUE = 'No value'

export const platformsData = {
  domains: {
    shopify: SHOPIFY_DOMAIN,
    bigcommerce: BIGCOMMERCE_DOMAIN,
  },
  installationDomainParamName: {
    shopify: 'myshopify_domain',
    bigcommerce: 'mybigcommerce_domain',
  },
  suffix: {
    shopify: 'sp',
    bigcommerce: 'bc',
  },
}

export const defaultCustomFieldsOrder = keyPrefix => {
  return [
    {
      key: `${keyPrefix}_first_charge_processed_at`,
      index: 0,
      visible: true,
    },
    {
      key: `${keyPrefix}_number_active_subscriptions`,
      index: 1,
      visible: true,
    },
    {
      key: `${keyPrefix}_number_subscriptions`,
      index: 2,
      visible: true,
    },
    {
      key: `${keyPrefix}_id`,
      index: 3,
      visible: false,
    },
    {
      key: `${keyPrefix}_external_customer_id`,
      index: 4,
      visible: false,
    },
    {
      key: `${keyPrefix}_tax_exempt`,
      index: 5,
      visible: false,
    },
    {
      key: `${keyPrefix}_has_card_error_in_dunning`,
      index: 6,
      visible: false,
    },
    {
      key: `${keyPrefix}_has_valid_payment_method`,
      index: 7,
      visible: false,
    },
  ]
}
