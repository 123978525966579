import { oauthTokenSelector } from 'selectors/app'
import config from 'config'
import { selectCustomer } from 'ducks/customers/selectors'

import * as api from '../api'

import {
  FETCH_RELATED_OBJECTS_REQUEST,
  FETCH_RELATED_OBJECTS_SUCCESS,
  FETCH_RELATED_OBJECTS_FAILURE,
} from '../types'

export default function doFetchRelatedObjects(object) {
  return (dispatch, getState) => {
    const objectId = object.id
    const customerId = object.customerId
    dispatch({
      type: FETCH_RELATED_OBJECTS_REQUEST,
      data: {
        customerId,
      },
    })

    const state = getState()
    const customer = selectCustomer(state, customerId)
    if (!customer) return null
    const belongsTo = {}
    const hasMany = []
    const collection = {
      belongsTo,
      hasMany,
      typeMap: {
        Owner: 'User',
      },
    }
    const { properties } = object
    const type = object.type
    if (properties.OwnerId) {
      belongsTo.Owner = belongsTo.Owner || []
      belongsTo.Owner.push(properties.OwnerId.value)
    }
    if (properties.AccountId) {
      belongsTo.Account = belongsTo.Account || []
      belongsTo.Account.push(properties.AccountId.value)
    }
    if (type === 'Contact') {
      hasMany.push('Tasks')
      hasMany.push('Opportunities')
    }
    const token = oauthTokenSelector(state)
    return api
      .getRelatedObjects(token, object, collection)
      .then(payload => {
        dispatch({
          type: FETCH_RELATED_OBJECTS_SUCCESS,
          data: {
            customerId,
            objectId,
            objects: payload,
          },
        })
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        if (config.isDevelopment) console.error(error)
        return dispatch({
          type: FETCH_RELATED_OBJECTS_FAILURE,
          data: {
            customerId,
            error,
          },
        })
      })
  }
}
