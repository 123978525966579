import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_AI_JOB_CONVERSATION_RESULT_INITIALIZE,
  FETCH_AI_SOURCES_FAILED,
  FETCH_AI_SOURCES_STARTED,
  FETCH_AI_SOURCES_SUCCESS,
} from 'ducks/ai/types'
import { getSourceTitle, getSourceTo } from '../utils'

const initialState = {
  loading: false,
  loaded: false,
  sources: [],
}

const handleGetSources = (draftState, { payload }) => {
  draftState.sources =
    payload.nodes?.filter(Boolean).map(node => {
      return {
        id: node.id,
        to: getSourceTo(node),
        title: getSourceTitle(node),
        isArticle: !!node.url,
      }
    }) || []
  draftState.loading = false
  draftState.loaded = true
  return draftState
}

export default createActionTypeReducer(
  {
    [FETCH_AI_JOB_CONVERSATION_RESULT_INITIALIZE]: () => {
      return initialState
    },
    [FETCH_AI_SOURCES_STARTED]: draftState => {
      draftState.sources = []
      draftState.loading = true
      draftState.loaded = false
      return draftState
    },
    [FETCH_AI_SOURCES_SUCCESS]: handleGetSources,
    // See comment in doFetchSourcesRequest for why we need to get sources from failed actions
    [FETCH_AI_SOURCES_FAILED]: (draftState, { payload }) =>
      handleGetSources(draftState, { payload: payload.data || {} }),
  },
  initialState
)
