import { selectContactById } from 'ducks/crm/contacts/selectors/base'
import { doFetchCustomerByEmail } from 'ducks/integrations/shopify/operations/doFetchCustomerId'
import { selectCustomerIdByIntegrationIdAndEmail } from 'ducks/integrations/shopify/selectors'

const doLookupCustomerGidAndFetch = (
  integrationId,
  contactId,
  action,
  actionOptions
) => async (dispatch, getState) => {
  const state = getState()
  const { email } = selectContactById(state, contactId) || {}
  const {
    id: customerGid,
    loaded,
    found,
  } = selectCustomerIdByIntegrationIdAndEmail(state, integrationId, email)

  if (!loaded) {
    await dispatch(doFetchCustomerByEmail(integrationId, email))
    return dispatch(
      doLookupCustomerGidAndFetch(
        integrationId,
        contactId,
        action,
        actionOptions
      )
    )
  }

  if (!found) {
    return {}
  }
  return dispatch(action(integrationId, customerGid, actionOptions))
}

export default doLookupCustomerGidAndFetch
