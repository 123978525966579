import { oauthTokenSelector } from 'selectors/app'
import { selectContactProperties } from 'ducks/integrations/hubspot/selectors'
import config from 'config'

import * as api from '../api'
import {
  FETCH_CONTACT_PROPERTIES_REQUEST,
  FETCH_CONTACT_PROPERTIES_SUCCESS,
  FETCH_CONTACT_PROPERTIES_FAILURE,
} from '../types'

export default function doFetchContactProperties() {
  return (dispatch, getState) => {
    const state = getState()

    const properties = selectContactProperties(state)
    if (properties) return Promise.resolve(properties)
    dispatch({
      type: FETCH_CONTACT_PROPERTIES_REQUEST,
    })
    const token = oauthTokenSelector(state)
    return api
      .getContactProperties(token)
      .then(payload => {
        const array = Object.values(payload.response)
        dispatch({
          type: FETCH_CONTACT_PROPERTIES_SUCCESS,
          data: {
            properties: array,
          },
        })
        return payload
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        if (config.isDevelopment) console.error(error)
        return dispatch({
          type: FETCH_CONTACT_PROPERTIES_FAILURE,
          data: {
            error,
          },
        })
      })
  }
}
