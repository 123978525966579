import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectOrdersForSubscriptionId } from 'ducks/integrations/recharge/selectors/orders'

import doFetchOrders from 'ducks/integrations/recharge/operations/doFetchOrders'

export const useRechargeOrders = (integrationId, subscriptionId) => {
  const dispatch = useDispatch()

  const ordersData = useSelector(state =>
    selectOrdersForSubscriptionId(state, subscriptionId)
  )

  useEffect(
    () => {
      if (ordersData?.orders === undefined && subscriptionId) {
        dispatch(doFetchOrders({ integrationId, subscriptionId }))
      }
    },
    [dispatch, integrationId, subscriptionId, ordersData]
  )

  if (ordersData) {
    return ordersData
  }

  return {}
}
