import integrationApi from '../api'

const ENDPOINT_PREFIX = 'proxy/hubspot'

function parseProxyResponse({ code, json }) {
  const object = { response: json }
  object.code = code
  if (code === 200 || code === 201) {
    return Promise.resolve(object)
  }
  if (code >= 400) return Promise.reject(object)
  return Promise.reject({ code })
}

function get(token, path, params) {
  return integrationApi
    .get(token, ENDPOINT_PREFIX + path, params)
    .then(parseProxyResponse)
}

// eslint-disable-next-line no-unused-vars
function put(token, path, params) {
  return integrationApi
    .put(token, ENDPOINT_PREFIX + path, params)
    .then(parseProxyResponse)
}

// eslint-disable-next-line no-unused-vars
function post(token, path, data) {
  return integrationApi
    .post(token, ENDPOINT_PREFIX + path, null, data)
    .then(parseProxyResponse)
}

// eslint-disable-next-line no-unused-vars
function deleteRequest(token, path, data) {
  return integrationApi
    .delete(token, ENDPOINT_PREFIX + path, data)
    .then(parseProxyResponse)
}

export function getContactByEmail(token, email) {
  return get(
    token,
    `/contacts/v1/contact/email/${email}/profile?propertyMode=value_only&formSubmissionMode=none&showListMemberships=true`
  )
}

export function getContactProperties(token) {
  return get(token, `/properties/v1/contacts/properties`)
}

export function getDealProperties(token) {
  return get(token, `/properties/v1/deals/properties`)
}

export function getCompanyProperties(token) {
  return get(token, `/properties/v1/companies/properties`)
}

export function getDealStages(token) {
  return get(token, `/crm-pipelines/v1/pipelines/deals`)
}

export function getContactLists(token, listIds) {
  const params = listIds.map(id => `listId=${id}`).join('&')
  const url = `/contacts/v1/lists/batch?${params}`
  return get(token, url)
}

export function getCompany(token, companyId) {
  const url = `/companies/v2/companies/${companyId}`
  return get(token, url)
}

export function getOwner(token, ownerId) {
  const url = `/owners/v2/owners/${ownerId}`
  return get(token, url)
}

export function createContact(token, data) {
  return post(token, `/contacts/v1/contact`, data)
}

export function getContactDeals(token, contactVid) {
  return new Promise((resolve, reject) => {
    // https://developers.hubspot.com/docs/methods/crm-associations/crm-associations-overview
    const definitionId = 4
    const url = `/crm-associations/v1/associations/${contactVid}/HUBSPOT_DEFINED/${definitionId}`
    get(token, url)
      .then(data => {
        const ids = data.response.results
        const promises = []
        ids.forEach(id => {
          const suburl = `/deals/v1/deal/${id}?propertyMode=value_only`
          promises.push(get(token, suburl))
        })
        Promise.all(promises)
          .then(subdata => {
            const deals = subdata.map(item => ({
              code: item.code,
              ...item.response,
            }))
            resolve({ code: 200, response: deals })
          })
          .catch(reject)
      })
      .catch(reject)
  })
}
