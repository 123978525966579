import { createSelector } from 'reselect'
import merge from 'deepmerge'
import { removeNullValues } from 'util/objects'
import { selectBillingUsage } from '../selectBillingUsage'
import { selectLocalUsage } from './selectLocalUsage'

export const selectCombinedRemoteLocalUsage = createSelector(
  selectBillingUsage,
  selectLocalUsage,
  (remoteUsage, localUsage) => {
    return merge(remoteUsage, removeNullValues(localUsage))
  }
)
