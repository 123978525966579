/* eslint-disable no-multi-assign */ /* this is ok in reducers */
import deepEqual from 'fast-deep-equal'

import * as types from 'constants/action_types'

import { isEmpty } from 'util/arrays'
import { isMessage } from 'util/changesets'
import {
  mergeCustomer,
  twitterHandle,
  firstInitial,
} from 'ducks/customers/utils'
import { deepCopy } from 'util/objects'
import { avatarURL } from 'util/actors'
import { FETCH_MERGEABLE_TICKETS_SUCCESS } from 'ducks/merging/types'

import {
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  FETCH_CUSTOMERS_SUCCESS,
  REMOVE_CUSTOMER,
} from './actionTypes'

const defaultState = { byId: {} }
const reducers = {}

export function isCustomer(actor) {
  if (!actor) return false
  return actor.type === 'Customer' || actor.type === 'Collaborator'
}

function enhanceCustomer(customer, state) {
  const currentUserEmail = state.currentUserEmail
  const isCurrentUser = customer.email && customer.email === currentUserEmail
  return {
    ...customer,
    avatarUrl: avatarURL(customer) || null,
    labelFull: customer.name || null,
    labelFullOrYou: isCurrentUser ? 'You' : customer.name || null,
    label: customer.first_name || null,
    labelOrYou: isCurrentUser ? 'You' : customer.first_name || null,
    twitterHandle: twitterHandle(customer) || null,
    initials: firstInitial(customer) || null,
  }
}

reducers[types.FETCH_TICKET_SUCCESS] = (state, action) => {
  const byId = { ...deepCopy(state.byId) }

  action.data.ticket.actions.records.forEach(record => {
    if (record.actor && isCustomer(record.actor)) {
      const currentCustomer = byId[record.actor.id]
      byId[record.actor.id] = enhanceCustomer(
        mergeCustomer(currentCustomer, record.actor),
        state
      )
    }
    if (record.change_type === 'Message') {
      const change = record.change
      const recipients = [].concat(change.to, change.cc, change.bcc)
      recipients.filter(isCustomer).forEach(customer => {
        const currentCustomer = byId[customer.id]
        byId[customer.id] = enhanceCustomer(
          mergeCustomer(currentCustomer, customer),
          state
        )
      })
    }
  })

  const customer = action.data.ticket.customer
  if (customer) {
    const currentCustomer = byId[customer.id]
    byId[customer.id] = enhanceCustomer(
      mergeCustomer(currentCustomer, customer),
      state
    )
  }

  return Object.assign({}, state, { byId })
}

reducers[types.FETCH_FOLDER_WITH_TICKETS_SUCCESS] = reducers[
  types.CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_SUCCESS
] = (state, action) => {
  const byId = Object.assign({}, state.byId)
  const data = action.data
  const folder = data.folder
  const tickets = folder.tickets
  const ticketRecords = tickets ? tickets.records : []

  ticketRecords.forEach(ticket => {
    byId[ticket.customer.id] = Object.assign({}, ticket.customer)
  })

  return Object.assign({}, state, { byId })
}

reducers[types.UPDATE_TICKETS] = reducers[types.SEARCH_SUCCESS] = reducers[
  FETCH_MERGEABLE_TICKETS_SUCCESS
] = (state, action) => {
  const byId = Object.assign({}, state.byId)
  const tickets = action.data?.tickets || action.payload?.tickets
  let changed = false

  tickets.forEach(ticket => {
    const currentCustomer = byId[ticket.customer.id]
    const newCustomer = enhanceCustomer(
      mergeCustomer(currentCustomer, ticket.customer),
      state
    )
    if (!deepEqual(currentCustomer, newCustomer)) {
      byId[ticket.customer.id] = newCustomer
      if (!changed) changed = true
    }
    const summaryAuthor = ticket.search_summary && ticket.search_summary.author
    if (summaryAuthor && isCustomer(summaryAuthor)) {
      byId[summaryAuthor.id] = enhanceCustomer(
        Object.assign({}, summaryAuthor),
        state
      )
    }
  })

  if (changed) {
    return {
      ...state,
      byId,
    }
  }
  return state
}

reducers[types.SEARCH_USER_SUCCESS] = (state, action) => {
  const byId = Object.assign({}, state.byId)
  const users = action.data.users
  users.forEach(user => {
    const currentCustomer = byId[user.id]
    const newCustomer = enhanceCustomer(user, state)
    if (!deepEqual(currentCustomer, newCustomer)) {
      byId[user.id] = newCustomer
    }
  })
  return Object.assign({}, state, { byId })
}

reducers[types.UPDATE_APP_DATA] = (state, action) => {
  const { currentUser } = action.data
  return {
    ...state,
    currentUserId: currentUser.id,
    currentUserEmail: currentUser.email,
  }
}

function applyCustomers(state, customers) {
  // perf - dont mutate if you dont need to!
  if (isEmpty(customers)) return state

  const byId = state.byId || {}
  const newById = { ...byId }
  let changed = false

  customers
    .filter(e => !!e)
    .filter(e => e.id)
    .forEach(customer => {
      const currentCustomer = byId[customer.id]
      const newCustomer = enhanceCustomer(
        {
          ...byId[customer.id],
          ...customer,
        },
        state
      )
      if (!deepEqual(currentCustomer, newCustomer)) {
        changed = true
        newById[customer.id] = newCustomer
      }
    })

  if (!changed) return state

  return {
    ...state,
    byId: newById,
  }
}

reducers['inappcards/changeUser/CHANGE_USER_REQUEST'] = (state, action) => {
  return applyCustomers(state, [action.payload.user])
}

reducers['inappcards/changeUser/ADD_NEW_USER_SUCCESS'] = (state, action) => {
  return applyCustomers(state, [action.payload.user])
}

reducers[FETCH_CUSTOMERS_SUCCESS] = (state, action) => {
  return applyCustomers(state, action.payload.customers)
}

reducers[types.CREATE_CHANGESET_REQUEST] = (state, { data }) => {
  const { to } = data.draft
  if (!to || !to[0]) return state
  return applyCustomers(state, [to[0]])
}

reducers[types.CREATE_CHANGESET_SUCCESS] = (state, { data }) => {
  if (!data || !data.ticketId || !data.ticketData) return state

  const { customer } = data.ticketData
  const { actions } = data
  const customerActors = actions
    .filter(isMessage)
    .map(action => action.actor)
    .filter(isCustomer)

  return applyCustomers(state, [...customerActors, customer])
}

reducers[types.NEW_CONVERSATION_LOGGED] = (state, { data }) => {
  const { customer } = data.ticket
  if (!customer) return state
  return applyCustomers(state, [customer])
}

reducers[REMOVE_CUSTOMER] = (state, action) => {
  const id = action.data
  const newById = {
    ...state.byId,
  }
  delete newById[id]
  return {
    ...state,
    byId: newById,
  }
}

reducers[UPDATE_CUSTOMER_REQUEST] = reducers[UPDATE_CUSTOMER_SUCCESS] = (
  state,
  action
) => {
  const {
    data: { customerId, params },
  } = action
  const byId = deepCopy(state.byId) || {}
  if (params) {
    byId[customerId] = enhanceCustomer(
      {
        ...byId[customerId],
        ...params,
      },
      state
    )
  }
  return { ...state, byId }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
