import config from 'config'

import debug from 'util/debug'
import storage from 'util/storage'
import uuid from 'util/uuid'
import { throttle } from 'util/functions'
import {
  getAudioNotificationsState,
  getAudioNotificationsStateTs,
  setAudioNotificationsState,
  setMatrixAudioNotificationState,
} from 'util/audio'
import { isWebPushSupported } from 'util/browser'
import { isGranted } from 'util/webPush/permissions'
import { getSubscription } from 'util/webPush/subscriptions'

import { selectAccountPreferenceChatEnabled } from 'selectors/app/selectAccountPreferences'

// Matrix includes
import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg'
import * as Lifecycle from 'matrix-react-sdk/lib/Lifecycle'
import Presence from 'matrix-react-sdk/lib/Presence'
import SettingsStore from 'matrix-react-sdk/lib/settings/SettingsStore'

import { doSubscribe } from 'actions/webPush/doSubscribe'
import { doFetchWidgetById } from 'ducks/widgets/operations'
import { doUpdatePresence, doUpdatePresenceSettings } from './actions/presence'
import GroovePresence from './Presence'
import './IndexedDb.worker'
import { BULK_USER_PRESENCE_CHANGED } from './actionTypes/presence'
import { MATRIX_CLIENT_READY } from './actionTypes/client'

window.mxSettingsStore = SettingsStore

const {
  chatServerUrl: MATRIX_CHAT_SERVER_URL,
  chatIdentityServerUrl: MATRIX_IDENTITY_SERVER_URL,
} = config
let presenceEvents = []

class MatrixEnvironment {
  constructor(storeInstance) {
    this.store = storeInstance
    this.loadingPromise = null
    MatrixClientPeg.setIndexedDbWorkerScript('/chat-sw.js')
    MatrixClientPeg.setCreateOptions({
      storeName: `groove-chat`,
      // For some reason the matrix-js-sdk prefixes the normal index db store
      // with matrix-js-sdk but not the crypto store. Adding the prefix here
      // to keep it consistent
      cryptoStoreName: `matrix-js-sdk:groove-chat-crypto`,
    })
    Presence.setPresenceProvider(GroovePresence)
    this.loadMatrix()
  }

  isChatEnabled() {
    const { getState } = this.store
    return selectAccountPreferenceChatEnabled(getState())
  }

  async loadMatrix() {
    if (!this.isChatEnabled()) return null
    if (this.loadingPromise) return this.loadingPromise
    const { dispatch } = this.store

    this.loadingPromise = new Promise(async resolve => {
      await this.setupLoadedSession()
      await this.loadPresenceFromServer()
      await this.subscribe()
      await this.waitForFirstSync()
      await this.subscribePostFirstSync()
      const client = MatrixClientPeg.get()
      this.ensurePusherRegistration()
      this.syncAudioNotificationsState(
        client.getAccountData('g.notifications.audio'),
        true
      )
      // Setup the default matrix configuration settings
      this.presence = Presence.get()
      this.presence.initClient(client, this.onAccountPresence)
      Object.freeze(this)
      resolve(this)
      dispatch({ type: MATRIX_CLIENT_READY })
    })

    return this.loadingPromise
  }

  async setupLoadedSession() {
    let loadedSession = await Lifecycle.loadSession({
      fragmentQueryParams: {},
      enableGuest: false,
      guestHsUrl: MATRIX_CHAT_SERVER_URL,
      guestIsUrl: MATRIX_IDENTITY_SERVER_URL,
      defaultDeviceDisplayName: 'None',
    })
    if (!loadedSession) {
      const { chatId: userId, chatAccessToken: accessToken } =
        storage.get('currentUser') || {}
      if (userId && accessToken) {
        const credentials = {
          homeserverUrl: MATRIX_CHAT_SERVER_URL,
          identityServerUrl: MATRIX_IDENTITY_SERVER_URL,
          userId,
          accessToken,
          deviceId: uuid(),
        }
        MatrixClientPeg.replaceUsingCreds(credentials)
        await Lifecycle.doSetLoggedIn(credentials, false)
        loadedSession = true
      }
    }
    if (!loadedSession) {
      throw new Error(
        'CRITICAL: Unable to setup a loaded session. Loading for token and registration failed'
      )
    }
    return true
  }

  onAccountPresence = event => {
    const {
      unavailableTimeMs: autoAway,
      offlineTimeMs: autoOffline,
      state,
      autoPresence,
    } = event.getContent()
    const { dispatch } = this.store
    dispatch(doUpdatePresenceSettings({ autoAway, autoOffline, autoPresence }))
    dispatch(doUpdatePresence(state))
  }

  onAccountData = event => {
    if (event.getType() === 'g.notifications.audio') {
      this.syncAudioNotificationsState(event)
    }
  }

  async subscribe() {
    const client = MatrixClientPeg.get()
    client.on('event', event => {
      if (event.getType() === 'm.presence') {
        this.schedulePresenceUpdate(event)
      }
    })
  }

  async subscribePostFirstSync() {
    const client = MatrixClientPeg.get()
    client.on('accountData', this.onAccountData)
    client.on('event', event => {
      const eventType = event.getType()
      if (
        eventType === 'g.integration.bridge.disconnected' ||
        eventType === 'g.integration.facebook.disconnected' // legacy
      ) {
        const { dispatch } = this.store
        const { widget_id: widgetId } = event.getContent()
        // Refetches widget, which will update it's disconnected state.
        dispatch(doFetchWidgetById(widgetId))
      }
    })
  }

  async loadPresenceFromServer() {
    const serverPresence = await MatrixClientPeg.get().getAccountDataFromServer(
      'g.presence'
    )
    const { state = 'online' } = serverPresence || {}
    MatrixClientPeg.updateSyncPresence(state)
  }

  schedulePresenceUpdate(event) {
    presenceEvents.push(event)

    // This function is debounced to only execute after 500ms. This
    // allows gives the buffer time for us to acumolate presence events
    // before syncing them to state
    this.updatePresenceInState()
  }

  updatePresenceInState = throttle(
    () => {
      this.store.dispatch({
        type: BULK_USER_PRESENCE_CHANGED,
        payload: presenceEvents.reduce((memo, evt) => {
          const userId = evt.getSender()
          const presence = evt.getContent().presence
          if (userId && presence) {
            // eslint-disable-next-line no-param-reassign
            memo.push({ userId, presence })
          }
          return memo
        }, []),
      })
      presenceEvents = []
    },
    500,
    { leading: false }
  )

  waitForFirstSync() {
    if (!this.firstSyncPromise) {
      this.firstSyncPromise = new Promise(resolve => {
        const client = MatrixClientPeg.get()
        client.on('sync', function onSync(state) {
          debug('MatrixClient sync state => %s', state)
          if (state === 'PREPARED') {
            resolve()
            client.off('sync', onSync)
          }
        })
      })
    }
    return this.firstSyncPromise
  }

  getSettingsStore() {
    return SettingsStore
  }

  async get() {
    await this.loadMatrix()
    return MatrixClientPeg.get()
  }

  // TODO (jscheel): Consider checkng this intermittently, not just on load
  async ensurePusherRegistration() {
    if (!isWebPushSupported() || !isGranted()) return
    const client = MatrixClientPeg.get()
    const { dispatch } = this.store
    const { pushers } = await client.getPushers()
    const currentPushSubscription = await getSubscription()
    let shouldResubscribe = false

    // NOTE (jscheel): If we somehow do not have a push subscription in the
    // browser, we need to try and set it back up.
    if (!currentPushSubscription || !currentPushSubscription.endpoint) {
      shouldResubscribe = true
    } else {
      // NOTE (jscheel): This is slightly problematic because we can't ensure that
      // the pusher is set up unless chat is loaded, but it's the best we can do
      // right now. We have to ensure that the pusher exists here becuase synapse
      // will delete the pusher if it fails. This can happen when the service worker
      // is uninstalled or deleted, because the browser will deregister the push
      // endpoint. If synapse pushes a notification through sygnal, and sygnal fails
      // to push the notification to the browser's non-existent push endpoint,
      // synapse will consider this a failure and delete the pusher.
      shouldResubscribe = !pushers.find(pusher => {
        const { app_id: appId, kind, data: { endpoint } = {} } = pusher
        return (
          appId === 'com.groovehq.app.web' &&
          kind === 'htttp' &&
          endpoint === currentPushSubscription.endpoint
        )
      })
    }

    if (shouldResubscribe) {
      dispatch(doSubscribe())
    }
  }

  syncAudioNotificationsState(mxEvent, initialSync = false) {
    if (!mxEvent) return

    const matrixEnabled = !!mxEvent.getContent()?.enabled
    const matrixTs = mxEvent.getContent()?.ts
    if (initialSync) {
      const lsEnabled = getAudioNotificationsState()
      const lsTs = getAudioNotificationsStateTs()
      if (matrixEnabled !== lsEnabled) {
        if (matrixTs > lsTs) {
          setAudioNotificationsState(matrixEnabled, matrixTs)
        } else {
          setMatrixAudioNotificationState(lsEnabled, lsTs)
        }
      }
      return
    }

    setAudioNotificationsState(matrixEnabled, matrixTs)
  }

  async logout() {
    Lifecycle.stopMatrixClient()
    await Lifecycle.clearStorage()
  }
}

export default new MatrixEnvironment(app.store)
