import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'

import {
  FETCH_PROJECT_ERROR,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
} from '../types'

export default function doFetchJiraServerProject(id) {
  return (dispatch, getState) => {
    if (!id) return null
    const token = oauthTokenSelector(getState())
    const meta = { id }
    dispatch({ meta, type: FETCH_PROJECT_REQUEST })
    return integrationApi
      .get(token, `proxy/jira-server/rest/api/2/project/${id}`)
      .then(({ json: project }) => {
        dispatch({
          meta,
          payload: { project },
          type: FETCH_PROJECT_SUCCESS,
        })
      })
      .catch(error => {
        dispatch({
          error: true,
          meta,
          payload: error,
          type: FETCH_PROJECT_ERROR,
        })
      })
  }
}
