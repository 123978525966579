import { createSelector } from 'reselect'

function selectBase(state) {
  return state.inAppCards.changeUser
}

export const selectIsSearching = createSelector(selectBase, changeUser => {
  return changeUser.isSearching
})
export const selectIsCreating = createSelector(selectBase, changeUser => {
  return changeUser.isCreating
})
export const selectSearchTerm = createSelector(selectBase, changeUser => {
  return changeUser.search.term
})
export const selectMatchingUsers = createSelector(selectBase, changeUser => {
  return changeUser.search.matchingUsers
})
export const selectIsSearchingStateLoading = createSelector(
  selectBase,
  changeUser => {
    return changeUser.search && changeUser.search.isLoading
  }
)
export const selectIsSearchingStateError = createSelector(
  selectBase,
  changeUser => {
    return changeUser.search && changeUser.search.isError
  }
)
export const selectIsCreatingStateLoading = createSelector(
  selectBase,
  changeUser => {
    return changeUser.create && changeUser.create.isLoading
  }
)
export const selectIsCreatingStateError = createSelector(
  selectBase,
  changeUser => {
    return changeUser.create && changeUser.create.isError
  }
)

export const selectIsChangeUserStateError = createSelector(
  selectBase,
  changeUser => {
    return changeUser.isError
  }
)

export const selectIsChangeUserStateLoading = createSelector(
  selectBase,
  changeUser => {
    return changeUser.isLoading
  }
)
