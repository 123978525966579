import { createSelector } from 'reselect'

import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { selectRoomFromUrl } from 'ducks/chat/selectors/rooms'
import { camelizeObjectKeys } from 'util/objects'
import { sortByKey } from 'util/arrays'

export default createSelector(
  selectCurrentTicket,
  selectRoomFromUrl,
  (ticket, room) => {
    const linkedResources = (ticket || room)?.linkedExternalResources?.records
    if (linkedResources) {
      return sortByKey(linkedResources.map(camelizeObjectKeys), 'linkedAt')
    }
    return null
  }
)
