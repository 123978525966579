import { createSelector } from 'reselect'

import { objectToId } from 'util/objects'
import { selectRequestByType } from 'ducks/requests/selectors'

import { selectCurrentSearchTerm } from '../../selectors'
import { selectPageCursor } from './base'
import { selectPageOrderBy } from './sorting'
import { fetchContactForCustomFieldRequestKey } from '../utils'

export const selectPageRequestVariables = createSelector(
  selectCurrentSearchTerm,
  selectPageCursor,
  selectPageOrderBy,
  (state, { query } = {}) => query,
  (term, cursor, orderBy, query) => {
    return {
      ...cursor,
      first: 20,
      filter: {
        search: query || term,
      },
      orderBy,
    }
  }
)

export const selectPageRequestKey = createSelector(
  selectPageRequestVariables,
  objectToId
)

export const selectFetchContactForPrimaryEmailRequestState = (state, email) => {
  return selectRequestByType(
    state,
    fetchContactForCustomFieldRequestKey('contact_email', email)
  )
}
