import {
  FETCH_EVENTS_STARTED,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILED,
} from './types'
import { enrichEvent } from './util'

const reducers = {}

reducers[FETCH_EVENTS_STARTED] = state => {
  return {
    ...state,
    ...{
      isLoading: true,
      hasError: false,
    },
  }
}

reducers[FETCH_EVENTS_SUCCESS] = (state, action) => {
  const { payload: { events } = {} } = action

  const enrichedEvents = events.map((evt, i) =>
    enrichEvent(evt, events[i - 1], events[i + 1])
  )

  return {
    ...state,
    ...{
      isLoading: false,
      isLoaded: true,
      data: enrichedEvents,
    },
  }
}

reducers[FETCH_EVENTS_FAILED] = (state, action) => {
  const error = action.payload.error

  return {
    ...state,
    ...{
      isLoading: false,
      hasError: true,
      error,
    },
  }
}

export default function reducer(state = {}, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
