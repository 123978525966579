import createCachedSelector from 're-reselect'
import { billingUsageKeyFeature } from '../../featureTypes'
import { byFeatureCacheKey } from './byFeatureCacheKey'
import { selectLocalUsage } from './selectLocalUsage'

export const selectLocalUsageForFeature = createCachedSelector(
  selectLocalUsage,
  (_state, feature) => feature,
  (usage, feature) => {
    const key = billingUsageKeyFeature(feature)
    return usage[key]
  }
)(byFeatureCacheKey)
