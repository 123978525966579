/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'

import {
  SEARCH_PRODUCTS_REQUEST_STARTED,
  SEARCH_PRODUCTS_REQUEST_FAILED,
  SEARCH_PRODUCTS_REQUEST_SUCCESS,
} from '../types'

const initialState = {
  byIntegrationId: {},
}

const byIntegrationIdInitialState = {
  isLoading: false,
  byId: {},
  byQuery: {},
}

export default createActionTypeReducer(
  {
    [SEARCH_PRODUCTS_REQUEST_STARTED]: (draftState, action) => {
      const {
        meta: { integrationId },
      } = action

      if (!integrationId) return draftState

      if (!draftState.byIntegrationId[integrationId])
        draftState.byIntegrationId[integrationId] = {
          ...byIntegrationIdInitialState,
        }

      draftState.byIntegrationId[integrationId].isLoading = true

      return draftState
    },
    [SEARCH_PRODUCTS_REQUEST_FAILED]: (draftState, action) => {
      const {
        meta: { integrationId },
      } = action

      if (!integrationId) return draftState

      if (!draftState.byIntegrationId[integrationId])
        draftState.byIntegrationId[integrationId] = {
          ...byIntegrationIdInitialState,
        }

      draftState.byIntegrationId[integrationId].isLoading = false

      return draftState
    },
    [SEARCH_PRODUCTS_REQUEST_SUCCESS]: (draftState, action) => {
      const {
        payload,
        meta: {
          requestParameters: { query },
          integrationId,
        },
      } = action

      if (!integrationId) return draftState

      if (!draftState.byIntegrationId[integrationId])
        draftState.byIntegrationId[integrationId] = {
          ...byIntegrationIdInitialState,
        }

      const { products: { edges } = [] } = payload
      const nodes = edges.map(edge => edge.node)
      const newByQuery = Object.assign(
        {},
        draftState.byIntegrationId[integrationId].byQuery
      )
      newByQuery[query] = nodes.map(node => node.id)
      const newById = Object.assign(
        {},
        draftState.byIntegrationId[integrationId].byId
      )
      nodes.forEach(node => {
        const variants = node.variants.edges.map(edge => edge.node)
        delete node.variants
        node.variants = variants
        newById[node.id] = node
      })

      draftState.byIntegrationId[integrationId].isLoading = false
      draftState.byIntegrationId[integrationId].byQuery = newByQuery
      draftState.byIntegrationId[integrationId].byId = newById

      return draftState
    },
  },
  initialState
)
