import { combineReducers } from 'redux'
import { collectionsById, openCollectionIds } from './collections'
import { groupsById, openGroupIds } from './groups'
import { byId } from './folders'
import { itemsById } from './items'

export default combineReducers({
  collectionsById,
  openCollectionIds,
  groupsById,
  openGroupIds,
  itemsById,
  byId,
})
