/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_BATCH_JOBS_SUCCESS,
  FETCH_BATCH_JOBS_FAILED,
  START_BATCH_JOBS_POLLING,
} from './actionTypes'

export const defaultState = {
  isPolling: false,
}

export default createActionTypeReducer(
  {
    [FETCH_BATCH_JOBS_SUCCESS]: (draftState, action) => {
      const {
        payload: { jobs },
      } = action
      draftState.isPolling = jobs.length > 0
      return draftState
    },
    [FETCH_BATCH_JOBS_FAILED]: draftState => {
      draftState.isPolling = false
      return draftState
    },
    [START_BATCH_JOBS_POLLING]: draftState => {
      draftState.isPolling = true
      return draftState
    },
  },
  defaultState
)
