export const FETCH_ALL_EXCLUDED_DOMAINS_SUCCESS =
  'crm/excludedDomains/FETCH_ALL_EXCLUDED_DOMAINS_SUCCESS'
export const FETCH_ALL_EXCLUDED_DOMAINS_STARTED =
  'crm/excludedDomains/FETCH_ALL_EXCLUDED_DOMAINS_STARTED'
export const FETCH_EXCLUDED_DOMAINS =
  'crm/excludedDomains/FETCH_EXCLUDED_DOMAINS'
export const FETCH_EXCLUDED_DOMAINS_STARTED =
  'crm/excludedDomains/FETCH_EXCLUDED_DOMAINS_STARTED'
export const FETCH_EXCLUDED_DOMAINS_SUCCESS =
  'crm/excludedDomains/FETCH_EXCLUDED_DOMAINS_SUCCESS'
export const FETCH_EXCLUDED_DOMAINS_FAILED =
  'crm/excludedDomains/FETCH_EXCLUDED_DOMAINS_FAILED'
export const FETCH_CUSTOM_FIELDS_FAILED =
  'crm/excludedDomains/FETCH_CUSTOM_FIELDS_FAILED'
export const FETCH_ALL_EXCLUDED_DOMAINS_FAILED =
  'crm/excludedDomains/FETCH_ALL_EXCLUDED_DOMAINS_FAILED'

export const CREATE_EXCLUDED_DOMAIN =
  'crm/excludedDomains/CREATE_EXCLUDED_DOMAIN'
export const CREATE_EXCLUDED_DOMAIN_STARTED =
  'crm/excludedDomains/CREATE_EXCLUDED_DOMAIN_STARTED'
export const CREATE_EXCLUDED_DOMAIN_SUCCESS =
  'crm/excludedDomains/CREATE_EXCLUDED_DOMAIN_SUCCESS'
export const CREATE_EXCLUDED_DOMAIN_FAILED =
  'crm/excludedDomains/CREATE_EXCLUDED_DOMAIN_FAILED'

export const UPDATE_EXCLUDED_DOMAIN =
  'crm/excludedDomains/UPDATE_EXCLUDED_DOMAIN'
export const UPDATE_EXCLUDED_DOMAIN_STARTED =
  'crm/excludedDomains/UPDATE_EXCLUDED_DOMAIN_STARTED'
export const UPDATE_EXCLUDED_DOMAIN_SUCCESS =
  'crm/excludedDomains/UPDATE_EXCLUDED_DOMAIN_SUCCESS'
export const UPDATE_EXCLUDED_DOMAIN_FAILED =
  'crm/excludedDomains/UPDATE_EXCLUDED_DOMAIN_FAILED'

export const DELETE_EXCLUDED_DOMAIN =
  'crm/excludedDomains/DELETE_EXCLUDED_DOMAIN'
export const DELETE_EXCLUDED_DOMAIN_STARTED =
  'crm/excludedDomains/DELETE_EXCLUDED_DOMAIN_STARTED'
export const DELETE_EXCLUDED_DOMAIN_SUCCESS =
  'crm/excludedDomains/DELETE_EXCLUDED_DOMAIN_SUCCESS'
export const DELETE_EXCLUDED_DOMAIN_FAILED =
  'crm/excludedDomains/DELETE_EXCLUDED_DOMAIN_FAILED'
