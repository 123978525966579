import { oauthTokenSelector } from 'selectors/app'
import config from 'config'
import { selectCustomer } from 'ducks/customers/selectors'
import { selectObjectStateByEmail } from 'ducks/integrations/salesforce/selectors'
import doFetchBatchProperties from './doFetchBatchProperties'

import * as api from '../api'

import {
  FETCH_OBJECT_REQUEST,
  FETCH_OBJECTS_SUCCESS,
  FETCH_OBJECT_FAILURE,
  FETCH_OBJECTS_FAILURE,
} from '../types'

const RETRY_TIMEOUT = 20 * 1000 // 10 seconds

export default function doFetchMatchingObjects(email, shouldRetry = true) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_OBJECT_REQUEST,
      data: {
        email,
      },
    })
    let retry

    if (shouldRetry) {
      retry = setTimeout(
        () => dispatch(doFetchMatchingObjects(email, false)),
        RETRY_TIMEOUT
      )
    }

    dispatch(
      doFetchBatchProperties([
        'Contact',
        'Lead',
        'Account',
        'Opportunity',
        'Task',
      ])
    )
      .then(() => {
        const state = getState()
        const customer = selectCustomer(state, email)
        if (!customer) {
          // eslint-disable-next-line no-console
          console.error({ email, message: 'selectCustomer returned null' })
          return null
        }

        const customerState = selectObjectStateByEmail(state, email)
        if (customerState === 'fetched') return customer
        if (customerState === 'missing') return customer

        const token = oauthTokenSelector(state)
        return api
          .getMatchingObjects(token, email)
          .then(payload => {
            if (retry) clearTimeout(retry)

            const { errorPayloads, successPayloads } = payload

            let type = FETCH_OBJECTS_SUCCESS
            let objects = successPayloads.map(data => data.response)

            if (errorPayloads.length) {
              type = FETCH_OBJECTS_FAILURE
              objects = errorPayloads.map(data => data.response)
            }

            dispatch({
              type,
              data: {
                email,
                objects,
              },
            })
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            if (config.isDevelopment) console.error(error)
            return dispatch({
              type: FETCH_OBJECT_FAILURE,
              data: {
                email,
                error,
              },
            })
          })
      })
      .catch(error => {
        return dispatch({
          type: FETCH_OBJECT_FAILURE,
          data: {
            email,
            error,
          },
        })
      })
  }
}
