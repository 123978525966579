import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { sortByKey } from 'util/arrays/sorting'
import {
  selectCurrentEntitiesById,
  selectPendingEntityById,
  selectCurrentEntityById,
} from 'ducks/entities/selectors'
import { selectRequestByType } from 'ducks/requests/selectors'
import selectBase from './selectBase'
import { FETCH_FOLDERS_BY_IDS_V2 } from '../actionTypes/folders'

export const selectFoldersById = createSelector(selectBase, base => base.byId)

export const selectFolders = createSelector(selectFoldersById, foldersById =>
  sortByKey(Object.values(foldersById), 'position')
)

export const selectChatFolders = createSelector(selectFolders, folders =>
  folders.filter(folder => folder.type === 'Chat::Filter')
)

export function selectFolderIdFromProps(_, props) {
  return props.folderId || null
}

export function makeSelectFolderById() {
  return createSelector(
    selectFoldersById,
    selectFolderIdFromProps,
    (foldersById, folderId) => {
      return foldersById[folderId] || emptyObj
    }
  )
}

export const selectCurrentFoldersById = state =>
  selectCurrentEntitiesById(state, 'folder') || null

export const selectCurrentFolderById = (state, id) =>
  selectCurrentEntityById(state, 'folder', id) || null

export const selectPendingFolderById = (state, id) =>
  selectPendingEntityById(state, 'folder', id) || null

export const selectIsLoadingFolderById = state =>
  selectRequestByType(state, FETCH_FOLDERS_BY_IDS_V2).loading

export const selectHasLoadedFolderById = state =>
  selectRequestByType(state, FETCH_FOLDERS_BY_IDS_V2).loaded

export const selectHasErrorFolderById = state =>
  selectRequestByType(state, FETCH_FOLDERS_BY_IDS_V2).error
