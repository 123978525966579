import integrationApi from '../api'

const ENDPOINT_PREFIX = 'proxy/recharge'

function parseProxyResponse({ code, json }) {
  const object = { response: json }
  object.code = code
  if (code === 200 || code === 201) {
    return Promise.resolve(object)
  }
  if (code >= 400) return Promise.reject(object)
  return Promise.reject({ code })
}

function post(token, path, data) {
  return integrationApi
    .post(token, ENDPOINT_PREFIX + path, null, data)
    .then(parseProxyResponse)
}

function get(token, path, params) {
  return integrationApi
    .get(token, ENDPOINT_PREFIX + path, params)
    .then(parseProxyResponse)
}

export function getCustomerByEmail(token, integrationId, email) {
  const url = `/customers?gid=${integrationId}&email=${email}`
  return get(token, url)
}

export function getCustomer(token, integrationId, id) {
  const url = `/customers/${id}?gid=${integrationId}`
  return get(token, url)
}

export function getSubscriptions(token, integrationId, customerId) {
  const url = `/subscriptions?gid=${integrationId}&customer_id=${customerId}`
  return get(token, url)
}

export function cancelSubscription(token, integrationId, subscriptionId, data) {
  const url = `/subscriptions/${subscriptionId}/cancel?gid=${integrationId}`
  return post(token, url, data)
}

export function reactivateSubscription(token, integrationId, subscriptionId) {
  const url = `/subscriptions/${subscriptionId}/activate?gid=${integrationId}`
  return post(token, url)
}

export function getOrders(token, integrationId, subscriptionId) {
  const url = `/orders?gid=${integrationId}&purchase_item_id=${subscriptionId}`
  return get(token, url)
}

export function getCharges(token, integrationId, subscriptionId) {
  const url = `/charges?gid=${integrationId}&purchase_item_id=${subscriptionId}`
  return get(token, url)
}

export function getCharge(token, integrationId, chargeId) {
  const url = `/charges/${chargeId}?gid=${integrationId}`
  return get(token, url)
}

export function skipCharge(token, integrationId, chargeId, data) {
  const url = `/charges/${chargeId}/skip?gid=${integrationId}`
  return post(token, url, data)
}
