import { createBasicSelector } from 'util/redux'
import { flatten, uniq } from 'util/arrays'
import { selectPlans } from './selectPlans'
import { selectPricings } from './selectPricings'

export const selectCurrentAddonPricings = createBasicSelector(
  selectPlans,
  selectPricings,
  (plans, pricings) => {
    const addonPricingIds = uniq(
      flatten(plans.map(p => p.pricing.addonPricingIds))
    )
    return addonPricingIds.map(pricingId =>
      pricings.find(p => p.id === pricingId)
    )
  }
)
