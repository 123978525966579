import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'
import { selectCompaniesById } from '../../companies/selectors/base'

export function selectBase(state) {
  return state.crm.contacts
}

export function selectContactIdsByIdentifyingValue(state) {
  return selectBase(state).byIdentifyingValue
}

const selectContactIdsByEmail = createBasicSelector(
  selectContactIdsByIdentifyingValue,
  contactIdsByIdentifyingValue =>
    contactIdsByIdentifyingValue?.contact_email || emptyObj
)

export function selectContactsById(state) {
  return selectBase(state).byId
}

export const selectContactForEmail = createCachedSelector(
  selectContactIdsByEmail,
  selectContactsById,
  (_state, email) => email,
  (contactIdsByEmail, contactsById, email) => {
    const contactId = contactIdsByEmail[email]

    if (!contactId) return null

    return contactsById[contactId]
  }
)((_state, email) => email || 'unknown')

export const selectContactById = createCachedSelector(
  selectContactsById,
  (_state, contactId) => contactId,
  (contactsById, contactId) => contactsById[contactId]
)((_state, contactId) => contactId || 'unknown')

export function selectLatestLoadedRequestKey(state) {
  return selectBase(state).latestLoadedRequestKey
}

export function selectLatestLoadedSearchTerm(state) {
  return selectBase(state).latestLoadedSearchTerm
}

export function selectLatestRequestKey(state) {
  return selectBase(state).latestRequestKey
}

export function selectRequestsByKey(state) {
  return selectBase(state).byRequestKey
}

export function selectPageCursor(state) {
  return selectBase(state).cursor
}

export function selectTotalContactsCount(state) {
  return selectBase(state).totalCount
}

export function selectSubjectForSubjectId(state, { subjectId }) {
  return (
    selectContactsById(state)[subjectId] ||
    selectCompaniesById(state)[subjectId]
  )
}

export function selectContactIdsByCustomerId(state) {
  return selectBase(state).contactIdsByCustomerId
}

const selectResyncContact = createBasicSelector(
  selectBase,
  base => base.resyncContact || emptyObj
)

const selectResyncContactByIdAndIntegrationId = createBasicSelector(
  selectResyncContact,
  resyncContact => resyncContact.byIdAndIntegrationId || emptyObj
)

export const selectResyncContactForIdAndIntegrationId = createCachedSelector(
  selectResyncContactByIdAndIntegrationId,
  (_state, integrationId) => integrationId,
  (_state, _integrationId, contactId) => contactId,
  (byIdAndIntegrationId, integrationId, contactId) => {
    return byIdAndIntegrationId[`${contactId}-${integrationId}`] || emptyObj
  }
)(
  (state, integrationId, contactId) =>
    `${integrationId || 'unknown'}-${contactId || 'unknown'}`
)
