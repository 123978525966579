import config from 'config'
import { doPostIntegrationInstallForProvider } from 'ducks/integrations/operations/settings'
import OAuthWindow from 'util/oauth_window'
import { toQueryString } from 'util/params'
import { reverseHashInt } from 'util/scatterSwap'
import { fetchOAuthAuthenticationUrl } from 'subapps/onboarding/api/channels'
import { oauthTokenSelector } from 'selectors/app/base'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'

const defaultReturnTo = `${window.location.origin}/oauth.html`
export const doFetchOAuthAuthenticationUrl = (
  providerId,
  { returnTo = defaultReturnTo, query = {} } = {}
) => async (_, getState) => {
  const state = getState()
  const token = oauthTokenSelector(state)

  const res = await fetchOAuthAuthenticationUrl(token, providerId, {
    ...query,
    returnTo,
  })
  return res.url
}

// In future, this will more likely be a generic method for oauth integrations which will be driven by ducks/integrations/selectors/settings/selectIntegrationData
// Don't want to jump the gun just yet as I'm not sure of the behaviour of other oauth providers
export const doAddShopifyIntegration = (
  providerId,
  shopDomain,
  oAuthWindowOptions = { width: 600, height: 800 }
) => (dispatch, getState) => {
  const state = getState()
  const currentUser = selectCurrentUser(state)
  const returnTo = `${window.location.origin}/oauth.html`
  const params = toQueryString({
    shop: shopDomain,
    return_to: returnTo,
    user_id: reverseHashInt(currentUser.id),
  })
  const url = `${config.api_url}/auth/shopify?${params}`

  const oAuthFlow = new OAuthWindow({
    title: 'Add Shopify Store',
    url,
    ...oAuthWindowOptions,
  })

  return oAuthFlow
    .start()
    .then(() => {
      return dispatch(doPostIntegrationInstallForProvider(providerId))
    })
    .catch(err => {
      oAuthFlow.cancel()
      throw err
    })
}

export const doAddRechargeIntegration = (
  providerId,
  shopDomain,
  oAuthWindowOptions = { width: 600, height: 800 }
) => dispatch => {
  const clientId = config.rechargeClientId
  const url = `https://admin.rechargeapps.com/partners/app/${clientId}/install?myshopify_domain=${shopDomain}`

  const oAuthFlow = new OAuthWindow({
    title: 'Add Recharge Store',
    url,
    ...oAuthWindowOptions,
  })

  return oAuthFlow
    .start()
    .then(() => {
      return dispatch(doPostIntegrationInstallForProvider(providerId))
    })
    .catch(err => {
      oAuthFlow.cancel()
      throw err
    })
}

export const doOauthIntegrationFlow = ({
  providerId,
  oauthService,
  title = 'Connect Integration',
  oAuthWindowOptions = { width: 600, height: 800 },
  fetchOauthOptions = {},
}) => async dispatch => {
  const url = await dispatch(
    doFetchOAuthAuthenticationUrl(oauthService, fetchOauthOptions)
  )

  const oAuthFlow = new OAuthWindow({
    title,
    url,
    ...oAuthWindowOptions,
  })

  return oAuthFlow
    .start()
    .then(() => {
      return dispatch(doPostIntegrationInstallForProvider(providerId))
    })
    .catch(err => {
      oAuthFlow.cancel()
      throw err
    })
}
