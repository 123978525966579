import createCachedSelector from 're-reselect'
import { selectPricingsById } from './selectPricingsById'
import { ADDON } from '../productTypes'
import { selectBillingUsage } from './selectBillingUsage'

export const selectAvailableFeaturesByPricingIds = createCachedSelector(
  selectPricingsById,
  selectBillingUsage,
  (_state, pricingIds) => pricingIds,
  (pricingsById, usage, pricingIds) => {
    // Kevin R (2023-12-08)
    // Make sure that if you modify the logic in this function that you
    // also adjust the ruby equivilant code in the base billing provider
    // app/models/billing/provider.rb
    const accountFeatures = {}

    const sortedPricingObjects = pricingIds
      .map(pricingId => pricingsById[pricingId])
      .sort((a, b) => {
        // The following sort ensures that addons are always processed last.
        // This is important as inbox, kb and any future pricing types will
        // set base feature values, and addons will accumulate on top of the base
        if (a.type === ADDON && b.type !== ADDON) {
          return 1 // 'a' should come after 'b'
        } else if (a.type !== ADDON && b.type === ADDON) {
          return -1 // 'a' should come before 'b'
        }
        return 0 // 'a' and 'b' are equal in order
      })

    sortedPricingObjects.forEach(pricing => {
      if (pricing.type === ADDON) {
        pricing.features.forEach(feature => {
          // We have 3 data types in feature system. string (unlimited), number, bool
          // When a feature has a value of unlimited or true, it means they have
          // unrestricted access to that feature. No reason to make any additional changes
          if (['unlimited', true].includes(accountFeatures[feature.key])) {
            return
          }

          // If the pricing feature allows unlimited access, then just set it
          // and move along
          if (['unlimited', true].includes(feature.value)) {
            accountFeatures[feature.key] = feature.value
          }
          // If we hit this block a configuration error has been made. Addon should never
          // be used to remove someone's access to a feature.
          else if ([false, 0].includes(feature.value)) {
            // Do nothing
          }
          // This means we only need to cater for numbers
          else {
            const basePricings = sortedPricingObjects.filter(
              sortedPricing =>
                sortedPricing.id !== pricing.id && sortedPricing.type !== ADDON
            )
            let availableBaseQuantity = 0
            basePricings.forEach(basePricing => {
              if (availableBaseQuantity === 'unlimited') return

              const pricingQuantity = basePricing.features.find(
                f => f.key === feature.key
              )?.value
              if (pricingQuantity === 'unlimited') {
                availableBaseQuantity = pricingQuantity
              } else if (pricingQuantity !== undefined) {
                availableBaseQuantity += pricingQuantity
              }
            })
            let addonUsage =
              availableBaseQuantity === 'unlimited'
                ? 0
                : usage[feature.key] - availableBaseQuantity
            if (addonUsage < 0) {
              addonUsage = 0
            }
            const addonQuantity = Math.floor(addonUsage / feature.value)

            // If the feature hasn't been defined yet, then define it as 0
            accountFeatures[feature.key] = accountFeatures[feature.key] || 0

            // We do += here, because if you have multiple addons affecting the same feature it needs
            // to accumulate the total for all of them
            accountFeatures[feature.key] += feature.value * addonQuantity
          }
        })
      } else {
        Object.assign(
          accountFeatures,
          pricing.features.reduce((featureObj, item) => {
            // eslint-disable-next-line no-param-reassign
            featureObj[item.key] = item.value
            return featureObj
          }, {})
        )
      }
    })

    return accountFeatures
  }
)((_state, pricingIds) => (pricingIds || []).join('-'))
