import { doAppGraphqlRequest } from 'ducks/requests/operations'

import { selectAllExcludedDomainsIsLoading } from '../selectors'
import { excludedDomainsNormalizationSchema } from '../schema'
import {
  FETCH_ALL_EXCLUDED_DOMAINS_SUCCESS,
  FETCH_ALL_EXCLUDED_DOMAINS_STARTED,
  FETCH_EXCLUDED_DOMAINS,
} from '../types'

const fetchCustomFieldQuery = `
  query ExcludedDomains($after: String) {
    excludedDomains(first: 20, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        domain
      }
    }
  }
`
export function doFetchAllExcludedDomains() {
  return async (dispatch, getState) => {
    const state = getState()
    const isLoading = selectAllExcludedDomainsIsLoading(state)
    if (isLoading) return null
    let hasNextPage
    let after

    dispatch({ type: FETCH_ALL_EXCLUDED_DOMAINS_STARTED })
    do {
      const variables = {
        after,
      }
      // eslint-disable-next-line no-await-in-loop
      const result = await dispatch(
        doAppGraphqlRequest(
          FETCH_EXCLUDED_DOMAINS,
          fetchCustomFieldQuery,
          variables,
          {
            normalizationSchema: excludedDomainsNormalizationSchema,
          }
        )
      )
      const pageInfo = result.excludedDomains.pageInfo
      hasNextPage = pageInfo.hasNextPage
      after = pageInfo.endCursor
    } while (hasNextPage)
    dispatch({ type: FETCH_ALL_EXCLUDED_DOMAINS_SUCCESS })
    return null
  }
}
