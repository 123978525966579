import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import { ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST } from '../types'

const query = `
  query OrderTransactions($orderId: ID!) {
    order(id: $orderId) {
      id
      transactions {
        id
        accountNumber
        formattedGateway
        paymentIcon {
          altText
          url
        }
        amountSet {
          shopMoney {
            amount
            currencyCode
          }
        }
      }
      suggestedRefund(suggestFullRefund: true, refundShipping: true) {
        maximumRefundableSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        suggestedTransactions {
          accountNumber
          formattedGateway
          parentTransaction {
            id
            paymentIcon {
              altText
              url
            }
          }
        }
      }
    }
  }
`

const doCalculateCancelOrderRefund = (integrationId, orderId) => dispatch => {
  const variables = { orderId }
  return dispatch(
    doGraphqlRequest(
      ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST,
      query,
      variables,
      {
        graphqlFunc: graphql(integrationId),
      }
    )
  )
}

export default doCalculateCancelOrderRefund
