import { normalize } from 'normalizr'
import { changeEntity, mergeEntityChanges } from 'ducks/entities/actionUtils'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import { filterEntities as defaultFilterEntities } from 'ducks/searches/utils/memory'
import {
  doFetchAccountPreference,
  doFetchAccountPreferences,
} from 'ducks/accountPreferences/operations'

import { removeIntegrationsByProviderMutation } from 'ducks/integrations/operations/removeIntegrationsByProviderMutation'
import { updateIntegrationSettingsMutation } from 'ducks/integrations/operations/updateIntegrationSettingsMutation'
import {
  selectPendingIntegrationById,
  selectPendingIntegrationInstallStateValueProviderIdsByProviderId,
} from 'ducks/integrations/selectors/settings'
import { doUpdateAccount } from 'actions/account'
import selectIntegrationData from 'ducks/integrations/selectors/settings/selectIntegrationData'
import { selectBillingUsage } from 'ducks/billing/selectors/selectBillingUsage'
import { integrationsWithUserIntegrationsGraphQlResponseSchema } from '../schema/integrations'
import {
  FETCH_INTEGRATIONS_DATA_TABLE,
  FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS,
  LOAD_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS,
  CLEAR_INTEGRATION_DRAFT,
  UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS,
  UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS,
  UPDATE_INTEGRATION_SETTINGS_DRAFT,
} from '../types'
import {
  ACCOUNT_PREFERENCES,
  INTEGRATION_PROVIDER,
} from '../utils/credentialsType'
import { isIntegrationInstalled, isIntegrationVisible } from '../utils/entity'

const LOAD_ALL_INTEGRATIONS_QUERY_ID = 'entityType:integration pageSize:10000'

const fetchIntegrationsNodeQuery = `
  nodes {
    __typename
    ... on Integration {
      id
      legacyId
      provider
      settings {
        settings
      }
    }

    ... on UserIntegration {
      agent {
        id
      }
    }

    ... on ChannelIntegration {
      channel {
        id
      }
    }

    ... on ShopifyV2Integration {
      storeName
      storeDomain
    }

    ... on ShopifyIntegration {
      storeName
      storeDomain
    }

    ... on SalesforceIntegration {
      salesforceDomain
    }

    ... on JiraServerIntegration {
      username
      url
    }

    ... on RechargeIntegration {
      storeName
      storeDomain
      fullStoreDomain
    }
  }
`

const fetchIntegrationsQuery = `
  query IntegrationsQuery {
    integrations {
      ${fetchIntegrationsNodeQuery}
    }
  }
`

const fetchIntegrationsByProviderQuery = `
  query IntegrationsByProviderQuery($filter: IntegrationsFilter) {
    integrations(filter: $filter) {
      ${fetchIntegrationsNodeQuery}
    }
  }
`

const transformResponseAttachInstallStateValues = (
  integrationsProviderApiResponse,
  store,
  options = {}
) => {
  const { getState } = store
  const state = getState()

  const { integrations: { nodes: installations = [] } = {} } =
    integrationsProviderApiResponse || {}

  const {
    transformResponseProviderFilter,
    transformResponseAdditionalData: {
      accountPreferencesApiResponse: {
        account: { preferences = {} } = {},
      } = {},
      billingUsage = {},
    } = {},
  } = options

  const integrationData = selectIntegrationData(state)

  const filteredIntegrations = transformResponseProviderFilter
    ? integrationData.filter(i => i.id === transformResponseProviderFilter)
    : integrationData

  const integrationsWithInstallations = filteredIntegrations.map(
    integration => {
      const {
        id,
        installStateConfig: { accountPreferenceKey, billingUsageKey } = {},
      } = integration

      // get integration provider response match by provider if available
      const providerValues = installations.filter(ui => ui.provider === id)

      // get integration account preference match if available
      const accountPreferenceValue = preferences[accountPreferenceKey]

      // get integration account preference match if available
      const billingUsageValue = billingUsage[billingUsageKey]

      // TODO: get account value (e.g facebook, twitter is saved on account not preferences)

      return {
        ...integration,
        installStateValue: {
          provider: providerValues,
          accountPreference: accountPreferenceValue,
          billingUsage: billingUsageValue,
          account: null,
        },
      }
    }
  )

  const integrationsWithVisibilityFilter = integrationsWithInstallations.filter(
    isIntegrationVisible
  )

  return {
    integrations: {
      nodes: integrationsWithVisibilityFilter,
    },
  }
}

export const doFetchIntegrationsByProviderForSettingsDrawer = (
  provider,
  options
) => async (dispatch, getState) => {
  // TODO: this might need a cleanup rework
  const { targetStore } = options
  const state = getState()

  const integrationData = selectIntegrationData(state)

  const { installStateConfig: { type, accountPreferenceKey } = {} } =
    integrationData.find(i => i.id === provider) || {}

  const transformResponseProviderFilter = provider

  if (type === INTEGRATION_PROVIDER) {
    return dispatch(
      doGraphqlRequest(
        FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS,
        fetchIntegrationsByProviderQuery,
        {
          filter: {
            provider,
          },
        },
        {
          app: true,
          normalizationSchema: integrationsWithUserIntegrationsGraphQlResponseSchema,
          moduleOptions: {
            entities: {
              targetStore,
            },
          },
          transformResponse: transformResponseAttachInstallStateValues,
          transformResponseProviderFilter,
        }
      )
    )
  }

  let accountPreferencesApiResponse

  if (type === ACCOUNT_PREFERENCES) {
    accountPreferencesApiResponse = await dispatch(
      doFetchAccountPreference(accountPreferenceKey, options)
    )
  }

  // manually load an integration entity from integrations.data to entity store
  // TODO: add account endpoint query for twitter/facebook integrations
  const {
    entities: { integration },
  } = normalize(
    transformResponseAttachInstallStateValues(
      null,
      { dispatch, getState },
      {
        transformResponseProviderFilter,
        transformResponseAdditionalData: {
          accountPreferencesApiResponse,
        },
      }
    ),
    integrationsWithUserIntegrationsGraphQlResponseSchema
  )

  if (!integration[provider]) return Promise.resolve({})

  return dispatch({
    type: LOAD_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS,
    ...changeEntity(
      'integration',
      provider,
      integration[provider],
      'update',
      targetStore
    ),
  })
}

export const doFetchIntegrationsForDataTable = ({ skipLoaded } = {}) => async (
  dispatch,
  getState
) => {
  const queryId = LOAD_ALL_INTEGRATIONS_QUERY_ID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)

  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  const accountPreferencesApiResponse = await dispatch(
    doFetchAccountPreferences()
  )

  const billingUsage = selectBillingUsage(state)

  // some app installation status settings are set in account preferences, some in integration endpoint, some in account endpoint
  return dispatch(
    doGraphqlRequest(
      FETCH_INTEGRATIONS_DATA_TABLE,
      fetchIntegrationsQuery,
      {},
      {
        app: true,
        normalizationSchema: integrationsWithUserIntegrationsGraphQlResponseSchema,
        transformResponse: transformResponseAttachInstallStateValues,
        transformResponseAdditionalData: {
          accountPreferencesApiResponse,
          billingUsage,
        },
        searches: {
          queryId,
          cursor,
          extractPagination: ({ integrations: { nodes = [] } = {} } = {}) => {
            // mock and attach pagination result so data table is happy
            return {
              nodes,
              type: 'integration',
              startCursor: 1,
              endCursor: 1,
              hasNextPage: false,
              hasPreviousPage: false,
              totalCount: nodes.length,
              totalPageCount: 1,
            }
          },
        },
      }
    )
  )
}

export const doFetchIntegrationsInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_INTEGRATIONS_QUERY_ID,
  entityType: 'integration',
  doLoadAllFn: doFetchIntegrationsForDataTable,
  STARTED_ACTION_TYPE: 'FETCH_INTEGRATIONS_IN_MEMORY_STARTED',
  SUCCESS_ACTION_TYPE: 'FETCH_INTEGRATIONS_IN_MEMORY_SUCCESS',
  filterEntities: (filterBy, entities) => {
    const filterByCopy = { ...filterBy }
    let filtered = entities
    const scope = filterByCopy.scope
    if (scope) {
      filtered = filtered.filter(entity => {
        if (scope === 'installed') {
          return !!isIntegrationInstalled(entity)
        } else if (scope === 'available') {
          return !isIntegrationInstalled(entity)
        }
        return false
      })
    }
    delete filterByCopy.scope
    return defaultFilterEntities(filterByCopy, filtered)
  },
})
// eslint-disable-next-line no-unused-vars
export const doClearIntegrationDraft = (id, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  // delete associated integrationInstallStateProviderValue entities as well
  const providerInstallationIds = selectPendingIntegrationInstallStateValueProviderIdsByProviderId(
    state,
    id
  )

  const installationsToDelete = providerInstallationIds.map(pId =>
    changeEntity(
      'integrationInstallStateProviderValue',
      pId,
      {},
      'remove',
      'pending'
    )
  )

  return dispatch({
    type: CLEAR_INTEGRATION_DRAFT,
    ...mergeEntityChanges([
      changeEntity('integration', id, {}, 'remove', 'pending'),
      ...installationsToDelete,
    ]),
  })
}

export const doPostIntegrationInstallForProvider = (
  id,
  // eslint-disable-next-line no-unused-vars
  options = {}
) => dispatch => {
  // clear entity from pending store
  dispatch(doClearIntegrationDraft(id))
  // update the entity store for provider (which magically updates the integrations overview page)
  dispatch(
    doFetchIntegrationsByProviderForSettingsDrawer(id, {
      targetStore: 'current',
    })
  )
}

export const doUninstallIntegrationByProvider = (provider, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const variables = { provider }

  const {
    name,
    installStateValue: { provider: installedProviderIds } = {},
    uninstallActionType,
    uninstallActionConfig = {},
  } =
    selectPendingIntegrationById(state, provider) || {}

  const removeIntegrationAdditionalActions = {
    entityType: 'integration',
    entityId: provider,
    stores: ['pending'],
    operation: 'remove',
    phases: ['STARTED'],
  }

  const toasts = {
    enabled: true,
    started: {
      enabled: true,
      content: `${name} uninstalled successfully`,
    },
    success: {
      enabled: false,
    },
    failed: {
      content: `${name} uninstall failed`,
      onClickAction: () => {
        dispatch(doUninstallIntegrationByProvider(provider, options))
      },
    },
  }

  const additionalSearchActions = [
    {
      type: 'INVALIDATE_ENTITIES',
      entityTypes: ['integration', 'integrationInstallStateProviderValue'],
      phases: ['SUCCESS'],
    },
  ]

  if (uninstallActionType === INTEGRATION_PROVIDER) {
    const additionalActionsProviderIds = installedProviderIds.map(pid => {
      return {
        entityType: 'integrationInstallStateProviderValue',
        entityId: pid,
        stores: ['pending', 'current'],
        operation: 'remove',
        phases: ['STARTED'],
      }
    })

    return dispatch(
      doGraphqlRequest(
        UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS,
        removeIntegrationsByProviderMutation,
        variables,
        {
          app: true,
          optimist: {},
          searches: {
            additionalActions: additionalSearchActions,
          },
          moduleOptions: {
            toasts,
            entities: {
              targetOperation: 'remove',
              additionalActions: [
                removeIntegrationAdditionalActions,
                ...additionalActionsProviderIds,
              ],
            },
          },
        }
      )
    )
  } else if (uninstallActionType === ACCOUNT_PREFERENCES) {
    const fields = {
      [uninstallActionConfig.accountPreferenceKey]:
        uninstallActionConfig.accountPreferenceValue,
    }

    return dispatch(
      doUpdateAccount(fields, {
        optimist: {},
        searches: {
          additionalActions: additionalSearchActions,
        },
        moduleOptions: {
          toasts,
          entities: {
            targetOperation: 'remove',
            additionalActions: [removeIntegrationAdditionalActions],
          },
        },
      })
    )
  }

  // eslint-disable-next-line no-console
  console.log(`TODO: doUninstallIntegrationByProvider: ${uninstallActionType}`)

  return Promise.reject({})
}

export const doUpdateStoreSettings = (id, entity, options = {}) => dispatch => {
  const {
    settings: { settings },
    storeName,
    provider,
  } = entity

  const variables = {
    integrationId: id,
    settings,
  }

  dispatch({
    type: UPDATE_INTEGRATION_SETTINGS_DRAFT,
    ...changeEntity(
      'integrationInstallStateProviderValue',
      id,
      entity,
      'replace',
      'pending'
    ),
  })

  return dispatch(
    doGraphqlRequest(
      UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS,
      updateIntegrationSettingsMutation,
      variables,
      {
        app: true,
        optimist: {},
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [
                'integration',
                'integrationInstallStateProviderValue',
              ],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: `${storeName} store updated successfully`,
            },
            success: {
              enabled: false,
            },
            failed: {
              content: `${storeName} store changes failed`,
              onClickAction: () => {
                dispatch(doUpdateStoreSettings(id, entity, options))
              },
            },
          },
        },
      }
    )
  ).then(() => {
    dispatch(
      doFetchIntegrationsByProviderForSettingsDrawer(provider, {
        targetStore: 'current',
      })
    )
  })
}
