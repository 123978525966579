/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { emptyObj } from 'util/objects'

import {
  USER_PRESENCE_CHANGED,
  BULK_USER_PRESENCE_CHANGED,
  PRESENCE_UPDATE,
} from '../../actionTypes/presence'
import { getCurrentMatrixUserId } from '../../utils/users'
import { mapPresence } from '../../utils/presence'

export default createActionTypeReducer(
  {
    [USER_PRESENCE_CHANGED]: (
      draftState,
      { meta: { userId } = {}, payload: presence }
    ) => {
      if (userId) {
        draftState[userId] = mapPresence(presence)
      }
    },
    [BULK_USER_PRESENCE_CHANGED]: (draftState, { payload = [] }) => {
      payload.forEach(({ userId, presence }) => {
        draftState[userId] = mapPresence(presence)
      })
    },
    [PRESENCE_UPDATE]: (draftState, { payload: { presence } }) => {
      const currentUserId = getCurrentMatrixUserId()
      draftState[currentUserId] = mapPresence(presence)
    },
  },
  emptyObj
)
