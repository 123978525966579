import { useSelector } from 'react-redux'
import { selectCustomerForCustomerId } from 'ducks/integrations/recharge/selectors'

export const useCustomer = (integrationId, customerId) => {
  const customer = useSelector(state =>
    selectCustomerForCustomerId(state, integrationId, { customerId })
  )

  return customer
}
