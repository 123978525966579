export const UPDATE_CUSTOMER_REQUEST = 'customers/UPDATE_CUSTOMER_REQUEST'
export const UPDATE_CUSTOMER_SUCCESS = 'customers/UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAIL = 'customers/UPDATE_CUSTOMER_FAIL'

export const UPDATE_CUSTOMER = 'customers/UPDATE_CUSTOMER'
export const REMOVE_CUSTOMER = 'customers/REMOVE_CUSTOMER'

export const FETCH_CUSTOMERS = 'customers/FETCH_CUSTOMERS'
export const FETCH_CUSTOMERS_STARTED = 'customers/FETCH_CUSTOMERS_STARTED'
export const FETCH_CUSTOMERS_SUCCESS = 'customers/FETCH_CUSTOMERS_SUCCESS'
export const FETCH_CUSTOMERS_FAIL = 'customers/FETCH_CUSTOMERS_FAIL'
