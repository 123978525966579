import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const preview = ({ theme, top }) => css`
  border-radius: ${rem(6)};
  color: ${theme.color.monochrome.white};
  font-size: ${theme.fontSize.large};
  width: ${rem(337)};
  background-color: ${theme.color.monochrome.dark};
  position: relative;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    top: ${top + 16}px;
    left: 0;
    width: ${rem(23)};
    height: ${rem(23)};
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${theme.color.monochrome.dark};
  }

  h3 {
    font-weight: ${theme.fontWeight.semibold};
    margin: 0 0 ${rem(6)};
    font-size: ${theme.fontSize.large};
  }

  figure.image {
    margin: 0;
    img {
      max-width: 100%;
    }
  }
`

const perviewDetail = css`
  overflow: auto;
  padding: ${rem(16)};
  display: flex;
  flex-direction: column;
  height: 100%;
`

const heading = theme => css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${rem(6)};
  margin-right: ${rem(-6)};
  line-height: ${theme.lineHeight.leadingTight};

  h3 {
    margin-bottom: 0;
  }
`

const bodyContent = theme => css`
  flex-grow: 1;
  overflow: auto;
  margin: 0 ${rem(-16)} ${rem(16)} ${rem(8)};
  padding-right: ${rem(28)};
  position: relative;
  overflow-wrap: break-word;

  &:after {
    content: ' ';
    display: block;
    width: 100%;
    height: ${rem(45)};
    background-image: linear-gradient(
      to bottom,
      transparent,
      ${theme.color.monochrome.dark}
    );
    position: sticky;
    bottom: 0;
  }
`

const tags = css`
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: ${rem(-4)} ${rem(-4)} ${rem(12)} ${rem(4)};
`

const tag = theme => css`
  background-color: ${theme.color.brand['800']};
  padding: ${rem(4)} ${rem(9)};
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.medium};
  border-radius: ${rem(4)};
  margin: ${rem(4)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const smallTag = css`
  max-width: ${rem(80)};
`

const btnWrapper = theme => css`
  &&& button {
    border-color: transparent;
    border-radius: ${rem(6)};
    color: ${theme.color.info[900]};
  }
`

const emptySubject = css`
  opacity: 0.8;
`

const btn = theme => css`
  color: ${theme.color.brand['50']};
  display: flex;
  align-items: center;
  font-weight: ${theme.fontWeight.semibold};
  font-size: ${theme.fontSize.large};
  margin-bottom: ${rem(6)};

  svg {
    width: ${rem(12)};
    height: ${rem(12)};
    margin-right: ${rem(3)};
  }

  &:hover {
    color: ${theme.color.brand['50']};
    text-decoration: underline;
  }
`

const actions = css`
  overflow: hidden;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: ${rem(42)};
`

export const styles = {
  preview,
  perviewDetail,
  heading,
  bodyContent,
  tags,
  tag,
  smallTag,
  btnWrapper,
  emptySubject,
  btn,
  actions,
}
