import { combineReducers } from 'core/redux'

import customers from './customers'
import subscriptions from './subscriptions'
import charges from './charges'
import upcomingInvoices from './upcomingInvoices'

export default combineReducers({
  customers,
  subscriptions,
  charges,
  upcomingInvoices,
})
