import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_CHARGES_REQUEST_STARTED,
  FETCH_CHARGES_REQUEST_SUCCESS,
  FETCH_CHARGES_REQUEST_FAILED,
  SKIP_NEXT_ORDER_REQUEST_STARTED,
  SKIP_NEXT_ORDER_REQUEST_SUCCESS,
  SKIP_NEXT_ORDER_REQUEST_FAILED,
  FETCH_CHARGE_REQUEST_STARTED,
  FETCH_CHARGE_REQUEST_SUCCESS,
  FETCH_CHARGE_REQUEST_FAILED,
} from 'ducks/integrations/recharge/types'

const initialState = {
  bySubscriptionId: {},
}

export const initialSingleState = {
  charges: [],
  isLoading: false,
  isUpdating: false,
  isError: false,
  error: null,
}

const findChargeByRequestParameters = (state, requestParameters) => {
  const { chargeId, subscriptionId } = requestParameters

  return state.bySubscriptionId[subscriptionId].charges.find(
    c => c.id === parseInt(chargeId, 10)
  )
}

export default createActionTypeReducer(
  {
    [FETCH_CHARGES_REQUEST_STARTED]: (draftState, action) => {
      const { subscriptionId } = action.payload

      if (!draftState.bySubscriptionId[subscriptionId]) {
        draftState.bySubscriptionId[subscriptionId] = Object.assign(
          {},
          initialSingleState
        )
      }

      draftState.bySubscriptionId[subscriptionId].isLoading = true

      return draftState
    },
    [FETCH_CHARGES_REQUEST_SUCCESS]: (draftState, action) => {
      const { subscriptionId } = action.meta.requestParameters
      const { charges } = action.payload

      Object.assign(draftState.bySubscriptionId[subscriptionId], {
        isLoading: false,
        isError: false,
        error: null,
        charges,
      })

      return draftState
    },
    [FETCH_CHARGES_REQUEST_FAILED]: (draftState, action) => {
      const { subscriptionId } = action.meta.requestParameters
      const { error } = action.payload

      Object.assign(draftState.bySubscriptionId[subscriptionId], {
        isLoading: false,
        isError: true,
        error,
      })

      return draftState
    },
    [SKIP_NEXT_ORDER_REQUEST_STARTED]: (draftState, action) => {
      const foundCharge = findChargeByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundCharge, {
        isUpdating: true,
      })

      return draftState
    },
    [SKIP_NEXT_ORDER_REQUEST_SUCCESS]: (draftState, action) => {
      const { charge } = action.payload

      const foundCharge = findChargeByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundCharge, {
        ...charge,
        isUpdating: false,
      })

      return draftState
    },
    [SKIP_NEXT_ORDER_REQUEST_FAILED]: (draftState, action) => {
      const foundCharge = findChargeByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundCharge, {
        isUpdating: false,
      })

      return draftState
    },
    [FETCH_CHARGE_REQUEST_STARTED]: (draftState, action) => {
      const { subscriptionId, chargeId } = action.payload

      if (!draftState.bySubscriptionId[subscriptionId]) {
        draftState.bySubscriptionId[subscriptionId] = Object.assign(
          {},
          initialSingleState
        )
      }

      const foundCharge = findChargeByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      if (!foundCharge) {
        const newCharge = { id: chargeId, isLoading: true }
        draftState.bySubscriptionId[subscriptionId].charges.push(newCharge)
      }

      return draftState
    },
    [FETCH_CHARGE_REQUEST_SUCCESS]: (draftState, action) => {
      const { charge } = action.payload
      const foundCharge = findChargeByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundCharge, {
        ...charge,
        isLoading: false,
      })

      return draftState
    },
    [FETCH_CHARGE_REQUEST_FAILED]: (draftState, action) => {
      const { error } = action.payload
      const foundCharge = findChargeByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundCharge, {
        isLoading: false,
        isError: true,
        error,
      })

      return draftState
    },
  },
  initialState
)
