/* eslint-disable no-multi-assign */
import { v4 as uuidV4 } from 'uuid'
import {
  FETCH_CANNED_REPLIES_STARTED,
  FETCH_CANNED_REPLIES_SUCCESS,
  FETCH_CANNED_REPLIES_FAILED,
  CLEAR_TEMPLATES,
  FETCH_CANNED_REPLY_CATEGORIES_STARTED,
} from '../types'

const reducers = {}
export const defaultState = {
  isLoading: false,
  isLoaded: false,
  hasError: false,
  loadingCategoryIds: [],
  loadedCategoryIds: [],
  categoryBatchId: uuidV4(),
  data: [],
  totalCount: null,
  mailboxId: null,
}

reducers[FETCH_CANNED_REPLY_CATEGORIES_STARTED] = (state, action) => {
  const { meta: { requestId = null } = {} } = action

  return {
    ...state,
    categoryBatchId: requestId,
    isLoading: false,
    isLoaded: false,
    hasError: false,
    loadingCategoryIds: [],
    loadedCategoryIds: [],
    data: [],
  }
}

reducers[FETCH_CANNED_REPLIES_STARTED] = (state, action) => {
  const {
    meta: { requestParameters: { categoryIds = null } = {} } = {},
    payload: { mailboxIds },
  } = action

  let mailboxId = null
  if (mailboxIds) {
    mailboxId = mailboxIds.length > 1 ? null : mailboxIds[0]
  }

  // Show which categoryIds are currently loading
  let loadingCategoryIds = state.loadingCategoryIds
  if (categoryIds) {
    loadingCategoryIds = [...state.loadingCategoryIds]
    categoryIds.forEach(categoryId => {
      if (categoryId && !state.loadingCategoryIds.includes(categoryId)) {
        loadingCategoryIds.push(categoryId)
      }
    })
  }

  return {
    ...state,
    isLoading: true,
    mailboxId,
    loadingCategoryIds,
  }
}

const isFiltered = parameters => {
  // We only want to update the cannedReplies totalCount in state if we're loading canned replies
  // for a completely unflitered state.
  const FILTER_KEYS = ['category', 'keywords', 'categoryIds']
  const keys = Object.keys(parameters)
  return FILTER_KEYS.some(k => keys.includes(k))
}

reducers[FETCH_CANNED_REPLIES_SUCCESS] = (state, action) => {
  const {
    payload: {
      commentTemplates,
      meta: { pagination: { total_count: totalCount } = {} } = {},
    },
    meta: {
      categoryBatchId = null,
      requestParameters: { categoryIds = null } = {},
    } = {},
    request: { parameters = {} } = {},
  } = action

  const countFromTemplateData = (commentTemplates || []).length
  // TEMP: gql is not returning meta pagination for getAllQuery
  // would use Nullish coalescing operator (??) but supported only from 2020 onwards
  const countFromState = [null, undefined].includes(state.totalCount)
    ? countFromTemplateData
    : state.totalCount
  const countFromPayload = [null, undefined].includes(totalCount)
    ? countFromTemplateData
    : totalCount

  const totalTemplatesCount =
    totalCount === undefined || isFiltered(parameters)
      ? countFromState
      : countFromPayload

  // If the person performed a new search after requesting the templates
  // for a category but before the response came in, it means that the results
  // from this category load is no longer valid. We should then disregards this
  // response, except totalCount.
  if (categoryBatchId !== state.categoryBatchId) {
    return {
      ...state,
      totalCount: totalTemplatesCount,
    }
  }

  // Merge comment templates
  const templates = state.data.filter(
    t => !commentTemplates.some(nt => nt.id === t.id)
  )
  commentTemplates.forEach(t => templates.push(t))

  // Show which categoryIds have been loaded
  let loadingCategoryIds = state.loadingCategoryIds
  let loadedCategoryIds = state.loadedCategoryIds
  if (categoryIds) {
    loadingCategoryIds = loadingCategoryIds.filter(
      cid => !categoryIds.includes(cid)
    )

    loadedCategoryIds = [...state.loadedCategoryIds]
    categoryIds.forEach(categoryId => {
      if (categoryId && !state.loadedCategoryIds.includes(categoryId)) {
        loadedCategoryIds.push(categoryId)
      }
    })
  }
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    hasError: false,
    loadingCategoryIds,
    loadedCategoryIds,
    data: templates,
    totalCount: totalTemplatesCount,
  }
}

reducers[FETCH_CANNED_REPLIES_FAILED] = (state, action) => {
  return {
    ...state,
    isLoading: false,
    loadingCategoryIds: [],
    hasError: true,
    error: action.payload.error,
  }
}

reducers[CLEAR_TEMPLATES] = state => {
  return {
    ...state,
    loadedCategoryIds: [],
    data: [],
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
