export const COPY_CUSTOMER_EMAIL = 'inappcards/customer/COPY_CUSTOMER_EMAIL'
export const COPY_CUSTOMER_WEBSITE = 'inappcards/customer/COPY_CUSTOMER_WEBSITE'

export const SET_EDITING = 'inappcards/customer/SET_EDITING'
export const SET_CHANGING = 'inappcards/customer/SET_CHANGING'

export const CHANGING_CHANGE_TO_CREATING =
  'inappcards/customer/CHANGING_CHANGE_TO_CREATING'
export const CHANGING_CHANGE_TO_SEARCHING =
  'inappcards/customer/CHANGING_CHANGE_TO_SEARCHING'

export const CHANGING_SEARCH_CUSTOMER_REQUEST =
  'inappcards/customer/CHANGING_SEARCH_CUSTOMER_REQUEST'
export const CHANGING_SEARCH_CUSTOMER_SUCCESS =
  'inappcards/customer/CHANGING_SEARCH_CUSTOMER_SUCCESS'
export const CHANGING_SEARCH_CUSTOMER_FAILURE =
  'inappcards/customer/CHANGING_SEARCH_CUSTOMER_FAILURE'

export const CHANGING_ADD_NEW_CUSTOMER_REQUEST =
  'inappcards/customer/CHANGING_ADD_NEW_CUSTOMER_REQUEST'
export const CHANGING_ADD_NEW_CUSTOMER_SUCCESS =
  'inappcards/customer/CHANGING_ADD_NEW_CUSTOMER_SUCCESS'
export const CHANGING_ADD_NEW_CUSTOMER_FAILURE =
  'inappcards/customer/CHANGING_SEARCH_CUSTOMER_FAILURE'

export const CHANGING_SELECT_CUSTOMER_REQUEST =
  'inappcards/customer/CHANGING_SELECT_CUSTOMER_REQUEST'
export const CHANGING_SELECT_CUSTOMER_SUCCESS =
  'inappcards/customer/CHANGING_SELECT_CUSTOMER_SUCCESS'
export const CHANGING_SELECT_CUSTOMER_FAILURE =
  'inappcards/customer/CHANGING_SELECT_CUSTOMER_FAILURE'
