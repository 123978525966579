import React from 'react'

import IsolateScroll from 'components/IsolateScroll'

import CannedRepliesByCategory from './CannedRepliesByCategory'
import SearchResults from './SearchResults'
import styles from './styles.css'

const AllRepliesState = ({ isSearching, onSelectReply }) => {
  return (
    <IsolateScroll className={styles.scrollableList}>
      {isSearching ? (
        <SearchResults onSelectReply={onSelectReply} />
      ) : (
        <CannedRepliesByCategory onSelectReply={onSelectReply} />
      )}
    </IsolateScroll>
  )
}

export default AllRepliesState
