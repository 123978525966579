import { selectFlag } from 'ducks/flags/selectors'
import { doClearFlag, doSetFlag } from 'ducks/flags/operations'
import { EDITOR_PINNED } from 'ducks/flags/flagNames'
import storage from 'util/storage'

import {
  CHANGE_EDITOR_FOCUS,
  SET_EDITOR_MODE,
  UPDATE_EDITOR_VISIBILITY,
  UPDATE_EDITOR_PINNED,
} from './types'

export const doChangeEditorFocus = isFocused => ({
  type: CHANGE_EDITOR_FOCUS,
  payload: { isFocused },
})

export const doSetEditorMode = mode => ({
  type: SET_EDITOR_MODE,
  payload: mode,
})

export function doUpdateEditorVisibility(isVisible) {
  return {
    type: UPDATE_EDITOR_VISIBILITY,
    payload: {
      isVisible,
    },
  }
}

export const doUpdateEditorPinned = isPinned => (dispatch, getState) => {
  const state = getState()
  if (isPinned) {
    dispatch(doSetFlag(EDITOR_PINNED))
  } else {
    // We had used storage for this prefrence before, need to clear it too
    storage.remove('editorPinned')
    if (selectFlag(state)(EDITOR_PINNED)) {
      dispatch(doClearFlag(EDITOR_PINNED))
    }
  }
  dispatch({
    type: UPDATE_EDITOR_PINNED,
    payload: {
      isPinned,
    },
  })
}
