/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { emptyObj } from 'util/objects'

import { PRESENCE_UPDATE_SETTINGS } from '../../actionTypes/presence'

export default createActionTypeReducer(
  {
    [PRESENCE_UPDATE_SETTINGS]: (draftState, { payload: { settings } }) => {
      Object.assign(draftState, settings)
      return draftState
    },
  },
  emptyObj
)
