import { v4 as uuidV4 } from 'uuid'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectIntegrationCredentialsForId } from 'ducks/integrations/selectors'
import doLinkResourceToRoom from 'ducks/integrations/operations/doLinkResourceToRoom'
import {
  selectOrderForId,
  selectDraftOrderForId,
} from 'ducks/integrations/shopify/selectors'
import {
  shopifyGidToId,
  shopifyStoreAdminUrl,
} from 'ducks/integrations/shopify/utils'
import { doFetchTicketActions } from 'actions/tickets/actions'
import { isGid, buildId, getRawId } from 'util/globalId'
import { isDraftOrderId } from 'components/integrations/ShopifyV2WidgetCard/order.util'
import { selectIsInChat } from 'selectors/location'
import * as types from '../types'

const mutation = `
  mutation EventShopifyCreate(
    $changeType: ShopifyChangeType!,
    $conversationId: ID!,
    $externalId: String!,
    $meta: JSON,
    $shopDomain: String!
  ) {
    eventsShopifyCreate(input: {
      changeType: $changeType,
      conversationId: $conversationId,
      externalId: $externalId,
      meta: $meta,
      shopDomain: $shopDomain
    }) {
      event {
        id
      }
      errors {
        message
        path
      }
    }
  }

`

const changeTypes = [
  'ORDER_CANCELED',
  'ORDER_CREATED',
  'ORDER_DUPLICATED',
  'ORDER_REFUNDED',
  'DRAFT_ORDER_INVOICE_SENT',
]

const shopifyPathForChangeType = changeType => {
  switch (changeType) {
    case 'DRAFT_ORDER_INVOICE_SENT':
      return 'admin/draft_orders'
    default:
      return 'admin/orders'
  }
}

const doCreateChatShopifyEvent = (
  conversationId,
  changeType,
  storeDomain,
  externalId,
  orderName
) => dispatch => {
  const path = shopifyPathForChangeType(changeType)
  const url = `${shopifyStoreAdminUrl(storeDomain)}/${path}/${externalId}`
  const roomId = isGid(conversationId)
    ? getRawId(conversationId)
    : conversationId

  return dispatch(
    doLinkResourceToRoom({
      id: uuidV4(),
      title: orderName,
      url,
      roomId,
      provider: 'shopify_v2',
      changeType,
    })
  )
}

const doCreateShopifyEvent = (
  integrationId,
  orderId,
  conversationId,
  changeType
) => (dispatch, getState) => {
  const state = getState()

  const integration = selectIntegrationCredentialsForId(state, {
    id: integrationId,
  })
  const { storeDomain } = integration

  if (!changeTypes.find(type => type === changeType)) {
    throw new Error(`Unknown type ${changeType}`)
  }

  const isDraftOrder = isDraftOrderId(orderId)

  const orderSelector = isDraftOrder ? selectDraftOrderForId : selectOrderForId
  const { name: orderName } = orderSelector(state, orderId)

  const conversationGid = isGid(conversationId)
    ? conversationId
    : buildId('Conversation', conversationId)

  const externalId = shopifyGidToId(orderId) // convert global ids to regular ids

  const isInChat = selectIsInChat(state)
  if (isInChat) {
    return dispatch(
      doCreateChatShopifyEvent(
        conversationGid,
        changeType,
        storeDomain,
        externalId,
        orderName
      )
    )
  }

  const variables = {
    changeType,
    conversationId: conversationGid,
    externalId,
    shopDomain: storeDomain,
  }

  if (orderName) {
    variables.meta = {
      orderName,
    }
  }

  return dispatch(
    doGraphqlRequest(types.SHOPIFY_EVENT_CREATE_REQUEST, mutation, variables, {
      app: true,
    })
  ).then(() => dispatch(doFetchTicketActions(conversationId)))
}

export default doCreateShopifyEvent
