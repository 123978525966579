import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import {
  FEATURE_INBOX_MAX_CHANNELS,
  FEATURE_INBOX_MAX_MAILBOXES,
  FEATURE_INBOX_MAX_REPORTING_DAYS,
  billingUsageKeyFeature,
} from 'ducks/billing/featureTypes'

import { selectUsageForFeature } from '../usage/selectUsageForFeature'
import { selectLocalUsage } from '../usage/selectLocalUsage'
import { selectBillingUsage } from '../selectBillingUsage'
import { selectLimitForFeature } from './selectLimitForFeature'
import { byFeatureCacheKey } from './byFeatureCacheKey'
import { selectCurrentAddonPricings } from '../selectCurrentSupportedAddonPricings'

export const selectCanUseFeature = createCachedSelector(
  selectLimitForFeature,
  selectBillingUsage,
  selectLocalUsage,
  selectCurrentAddonPricings,
  (_state, feature) => feature,
  (featureLimit, billingUsage, localUsage, currentAddonPricings, feature) => {
    const hasAddonSupport = currentAddonPricings.some(pricing => {
      const checkFeature =
        feature === FEATURE_INBOX_MAX_MAILBOXES
          ? FEATURE_INBOX_MAX_CHANNELS
          : feature

      return pricing.usageFrom === checkFeature
    })

    if (hasAddonSupport) {
      return true
    }

    if ([undefined, null].includes(featureLimit)) {
      return false
    }
    if (featureLimit === 'unlimited') return true
    if (typeof featureLimit === 'boolean') return featureLimit // no need to check usage

    const billingUsageKey = billingUsageKeyFeature(feature)
    if (typeof featureLimit === 'number' && billingUsageKey) {
      // check current usage
      const usageValue =
        localUsage[billingUsageKey] || billingUsage[billingUsageKey]

      return Number(usageValue) < featureLimit
    }

    return true
  }
)(byFeatureCacheKey)

export const selectIsFeatureEnabled = createCachedSelector(
  selectLimitForFeature,
  featureLimit => {
    if ([undefined, null].includes(featureLimit)) {
      return false
    }

    if (featureLimit === 'unlimited') return true
    if (typeof featureLimit === 'boolean') return featureLimit // no need to check usage
    return featureLimit > 0
  }
)(byFeatureCacheKey)

export const selectFeatureUsage = createCachedSelector(
  selectCanUseFeature,
  selectLimitForFeature,
  selectUsageForFeature,
  selectIsFeatureEnabled,
  (canUseFeature, featureLimit, currentUsage, isFeatureEnabled) => ({
    canUseFeature,
    featureLimit,
    currentUsage,
    isFeatureEnabled,
  })
)(byFeatureCacheKey)

export const selectMaxReportingDays = createSelector(
  state => selectLimitForFeature(state, FEATURE_INBOX_MAX_REPORTING_DAYS),
  inboxMaxReportingDays => {
    return inboxMaxReportingDays
  }
)
