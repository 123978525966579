import { combineReducers } from 'core/redux'

import contacts from './contacts'
import companies from './companies'
import customFieldCategories from './customFieldCategories'
import customFields from './customFields'
import excludedDomains from './excludedDomains'

export default combineReducers({
  contacts,
  companies,
  customFieldCategories,
  customFields,
  excludedDomains,
})
