import React from 'react'

import Expander from 'components/Expander'
import { propFunc } from 'util/functions'

import CannedReply from '../CannedReply'
import styles from './styles.css'

const Category = ({ name, replies, onSelectReply, onOpen }) => (
  <Expander
    className={styles.category}
    label={name}
    labelClass={styles.category_name}
    onOpen={onOpen}
  >
    <div className={styles.category_replies}>
      {replies.map(reply => (
        <CannedReply
          key={`template-${reply.id}`}
          name={reply.name}
          snippet={reply.snippet}
          onClick={propFunc(onSelectReply, reply)}
        />
      ))}
    </div>
  </Expander>
)

export default Category
