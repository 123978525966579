import { basicFields as integrationFields } from 'api/integrations'
import { EXTENDED_BILLING_FIELDS_V2 } from 'ducks/billing/api'

export const bootstrapQuery = () => `
  query AppBootstrapQuery {
    account {
      id
      subdomain
      state
      expiresAt
    }
    billing {
      ${EXTENDED_BILLING_FIELDS_V2}
    }
    integrations {
      ${integrationFields}
    }
  }
`
