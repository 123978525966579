import { createActionTypeReducer } from 'util/reducers'
import {
  SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_STARTED,
  SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_FAILED,
  SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_SUCCESS,
} from '../types'

const initialState = {
  byIntegrationId: {},
}

export default createActionTypeReducer(
  {
    [SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_STARTED]: (
      draftState,
      action
    ) => {
      const {
        meta: { integrationId },
      } = action

      if (!integrationId) return draftState

      if (!draftState.byIntegrationId[integrationId])
        draftState.byIntegrationId[integrationId] = {}

      draftState.byIntegrationId[integrationId].isLoading = true
      draftState.byIntegrationId[integrationId].loaded = false
      draftState.byIntegrationId[integrationId].error = false

      return draftState
    },
    [SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_FAILED]: (
      draftState,
      action
    ) => {
      const {
        meta: { integrationId },
      } = action

      if (!integrationId) return draftState

      if (!draftState.byIntegrationId[integrationId])
        draftState.byIntegrationId[integrationId] = {}

      draftState.byIntegrationId[integrationId].isLoading = false
      draftState.byIntegrationId[integrationId].loaded = false
      draftState.byIntegrationId[integrationId].error = true

      return draftState
    },
    [SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_SUCCESS]: (
      draftState,
      action
    ) => {
      const {
        payload: { paymentTermsTemplates },
        meta: { integrationId },
      } = action

      if (!integrationId) return draftState

      if (!draftState.byIntegrationId[integrationId])
        draftState.byIntegrationId[integrationId] = {}

      draftState.byIntegrationId[integrationId].isLoading = false
      draftState.byIntegrationId[integrationId].loaded = true
      draftState.byIntegrationId[integrationId].error = false

      draftState.byIntegrationId[
        integrationId
      ].templates = paymentTermsTemplates

      return draftState
    },
  },
  initialState
)
