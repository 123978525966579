import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { css } from '@emotion/react'
import FeatureCallout from '@groovehq/internal-design-system/lib/components/FeatureCallout/FeatureCallout'
import { styles } from '@groovehq/internal-design-system/lib/components/FeatureCallout/FeatureCallout.styles'
import {
  button,
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import useFrillNotification from 'util/hooks/useFrillNotification'
import { HIDE_CANNED_REPLY_PREVIEW_CALLOUT } from 'ducks/flags/flagNames'
import { doSetFlag } from 'ducks/flags/operations'

const minWidth = css`
  min-width: 230px;
`

const PreviewCallout = ({ children }) => {
  const dispatch = useDispatch()
  const { onNotify, loaded } = useFrillNotification()
  const [show, setShow] = useState(true)
  const handleClose = useCallback(
    e => {
      e.stopPropagation()
      dispatch(doSetFlag(HIDE_CANNED_REPLY_PREVIEW_CALLOUT))
      setShow(false)
    },
    [dispatch]
  )
  const handleLearnMore = useCallback(
    e => {
      e.stopPropagation()
      onNotify('instant-reply-previews-and-recently-used-category')
      handleClose(e)
    },
    [onNotify, handleClose]
  )
  const overlay = (
    <div>
      <h5
        className="grui my-4"
        css={[heading.styles.h5, text.styles.textWhite]}
      >
        {`${app.t('Canned_Reply')} Previews`}
      </h5>
      {`Now you can preview your ${app.t(
        'Canned_Reply'
      )} by hovering on it before inserting it!`}
      <div className="grui mt-10" css={styles.footer}>
        <button
          css={button.styles.preflight}
          type="button"
          className="grui"
          onClick={handleLearnMore}
        >
          Learn more
        </button>
        <button
          css={button.styles.preflight}
          type="button"
          onClick={handleClose}
          className="grui"
        >
          Dismiss
        </button>
      </div>
    </div>
  )

  return (
    <FeatureCallout
      overlay={overlay}
      open={show && loaded}
      position="right"
      css={minWidth}
    >
      {children}
    </FeatureCallout>
  )
}

export default PreviewCallout
