import graphql, { getData } from 'api/graphql'

export const CREDIT_CARD_FIELDS = `
id
brand
expiryMonth
expiryYear
last4
`

export const basicFields = `
  provider
  createdAt
  state
  cc_last4
  has_legacy_kb
  billing_cycle
  cancels_at
  hasLastBillingFailed
  isPaid
  pendingSubscription {
    approvalUrl
    inboxPlan
    kbPlan
    billingCycle
    state
  }
  futurePhase {
    startDate
    items {
      plan
      quantity
    }
  }
  externalAccountId
  externalSubscriptionId
`

export const UPCOMING_INVOICE_FIELDS = `
  id
  billing
  nextPaymentAttempt
  nextBill
  currency
  billingCycle
  startingBalance
  total
  amountDue
  totalDiscountAmount
  lines {
    amount
    proration
  }
`

export const EXTENDED_BILLING_FIELDS_V1 = `
  state
  cc_last4
  has_legacy_kb
  billing_cycle
  cancels_at
  account {
    upcomingInvoice {
      ${UPCOMING_INVOICE_FIELDS}
    }
    creditBalance
  }
  pendingSubscription {
    approvalUrl
    inboxPlan
    kbPlan
    billingCycle
    state
  }
  hasLastBillingFailed
  externalAccountId
  externalSubscriptionId
  startTrialDurationDays
  futurePhase {
    startDate
    items {
      plan
      quantity
    }
  }
`

export const EXTENDED_BILLING_FIELDS_V2 = `
  availableFeatures {
    key
    name
    value
  }
  usages {
    key
    value
  }
  plans {
    id
    createdAt
    expiresAt
    externalId
    quantity
    state
    updatedAt
    pricing {
      id
      annual
      externalAnnualPriceId
      externalMonthlyPriceId
      minimumQuantity
      monthly
      name
      pricingModel
      trialAvailable
      type
      usageFrom
      version
      addonPricingIds
      features {
        key
        name
        value
      }
    }
  }
  pricings {
    id
    annual
    externalAnnualPriceId
    externalMonthlyPriceId
    minimumQuantity
    monthly
    name
    pricingModel
    trialAvailable
    active
    type
    usageFrom
    version
    addonPricingIds
    features {
      key
      name
      value
    }
  }
  creditCards {
    ${CREDIT_CARD_FIELDS}
  }
  discount {
    id,
    type
    startAt
    endAt
    coupon {
      id
      duration
      durationInMonths
      maxRedemptions
      name
      percentOff
      amountOff
      timesRedeemed
      valid
      createdAt
    }
  }
`

export const BILLING_SETUP_INTENT_FIELDS = `
  id
  clientSecret
  usage
  status
  createdAt
`

export const BILLING_DATA_QUERY_V1 = `
  query BillingDataQueryV1 {
    billing {
      ${EXTENDED_BILLING_FIELDS_V1}
    }
  }
`

export const BILLING_DATA_QUERY_V2 = `
  query BillingDataQueryV2 {
    billing {
      ${EXTENDED_BILLING_FIELDS_V2}
    }
  }
`

export const BILLING_SETUP_INTENTS_QUERY = `
  query BillingSetupIntents {
    billing {
      cardSetupIntents {
        ${BILLING_SETUP_INTENT_FIELDS}
      }
    }
  }
`

export const BILLING_CREATE_SETUP_INTENT_MUTATION = `
  mutation BillingCardSetupIntentCreate {
    cardSetupIntentCreate(input: {}) {
      setupIntent {
        ${BILLING_SETUP_INTENT_FIELDS}
      }
      errors {
        message
        path
      }
    }
  }
`

export const BILLING_CARD_SETUP_INTENT_SUCCEED_MUTATION = `
  mutation BillingCardSetupIntentSucceed($id: ID!) {
    cardSetupIntentSucceed(input: { setupIntentId: $id }) {
      setupIntent {
        ${BILLING_SETUP_INTENT_FIELDS}
      }
      errors {
        message
        path
      }
    }
  }
`

export const INVOICES_DATA_QUERY = `
query InvoicesDataQuery {
  invoices {
    id
    paid
    periodStart
    periodEnd
    number
    hostedInvoiceUrl
    invoicePdf
    total
    date
    currency
    status
    lines {
      period {
        start
        end
      }
      plan {
        id
      }
      description
    }
  }
}
`

export const BILLING_INFORMATION_FIELDS = `
name
phone
city
country
line1
line2
postalCode
state
vatId
`

const BILLING_INFORMATION_DATA_QUERY = `
query BillingInformationDataQuery {
  billingInformation {
    ${BILLING_INFORMATION_FIELDS}
  }
}
`

export const fetchBillingInformation = token => {
  return graphql(token, BILLING_INFORMATION_DATA_QUERY).then(getData)
}

export const billingQuery = () => `
  query BillingQuery {
    billing {
      ${basicFields}
    }
    creditCards {
      ${CREDIT_CARD_FIELDS}
    }
  }
`
