import {
  FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_REQUEST,
  FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_SUCCESS,
  FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_ERROR,
} from '../types'

const initialState = { byProjectId: {}, errored: false, loading: false }

export default function reduceCreateIssueTypeFields(
  state = initialState,
  { meta: { cloudId } = {}, type, payload: { projects } = {} }
) {
  if (type === FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  if (type === FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }
  if (type === FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_SUCCESS) {
    return {
      ...state,
      byProjectId: {
        ...state.byProjectId,
        ...projects.reduce((result, project) => {
          const { id: projectId, issuetypes } = project
          // too much data being returned, pick only the data we need and use issueType ID as the key for easy access
          // e.g. byProjectId['cloudId:projectId'][issueTypeId].fields['assignee'] to get meta for assignee field
          const fieldsByIssueType = issuetypes.reduce(
            (accumulator, { id, name, fields }) => {
              // eslint-disable-next-line no-param-reassign
              accumulator[id] = { id, name, fields }
              return accumulator
            },
            {}
          )

          // eslint-disable-next-line no-param-reassign
          result[`${cloudId}:${projectId}`] = fieldsByIssueType
          return result
        }, {}),
      },
      errored: false,
      loading: false,
    }
  }
  return state
}
