import { combineReducers } from 'core/redux'

import installs from './installs'
import loading from './loading'
import nodes from './nodes'

export default combineReducers({
  installs,
  loading,
  nodes,
})
