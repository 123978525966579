import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'

import selectJiraSites from './selectJiraSites'

export default createSelector(selectJiraSites, sites => {
  if (!sites) return emptyArr
  return sites.map(({ avatarUrl, id, name }) => {
    return {
      avatarUrl,
      text: name,
      value: id,
    }
  })
})
