import createCachedSelector from 're-reselect'

import { emptyArr } from 'util/arrays'

import selectIntegrationSettingsForProvider from './selectIntegrationSettingsForProvider'

const SHOW_FIELD_REGEX = /^showSidebarField\.(.+)$/

const emptyObj = { hidden: emptyArr, visible: emptyArr }

export default createCachedSelector(
  selectIntegrationSettingsForProvider,
  settings => {
    const keys = Object.keys(settings)
    return (
      (keys.length > 0 &&
        keys.reduce(
          (result, key) => {
            const matches = key.match(SHOW_FIELD_REGEX)
            if (matches) {
              if (settings[key]) {
                result.visible.push(key)
              } else {
                result.hidden.push(key)
              }
            }
            return result
          },
          { hidden: [], visible: [] }
        )) ||
      emptyObj
    )
  }
)((state, { provider }) => provider)
