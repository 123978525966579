import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: ${rem(10)};
`

const imgWrapper = css`
  height: 42%;
  margin: 0 auto ${rem(10)};
  flex-shrink: 1;

  img {
    height: 100%;
  }
`

const heading = theme => css`
  font-size: ${rem(26)};
  color: ${theme.color.info['900']};
`

const content = theme => css`
  font-size: ${theme.fontSize.x2large};
  color: ${theme.color.monochrome.black};
  margin: ${rem(10)} 0 ${rem(24)};
`

const btn = css`
  padding-left: ${rem(24)};
  padding-right: ${rem(24)};
  flex-shrink: 0;
`

export const styles = {
  container,
  imgWrapper,
  heading,
  content,
  btn,
}
