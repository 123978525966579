import createCachedSelector from 're-reselect'
import selectIntegrationCredentialsByProvider from './selectIntegrationCredentialsByProvider'

export default createCachedSelector(
  selectIntegrationCredentialsByProvider,
  (state, { provider }) => provider,
  (integrationCredentials, provider) => {
    const forProvider =
      integrationCredentials[provider] ||
      integrationCredentials[provider.toUpperCase()]
    if (forProvider) return forProvider[0]
    return undefined
  }
)((state, { provider }) => provider)
