export const EMAIL_CHANNEL_TYPES = {
  google: 'GOOGLE',
  forwarding: 'FORWARDING',
  imap: 'IMAP',
  office365: 'OFFICE365',
  outlook: 'OFFICE365',
}

export const channelTypes = {
  livechat: 'widget',
  social: ['social', 'facebook', 'instagram'],
  forwarding: 'forwarding',
  google: 'google',
  facebook: 'facebook',
  instagram: 'instagram',
  twitter: 'twitter',
  chat: ['social', 'widget', 'facebook', 'instagram'],
  inboxes: [...Object.keys(EMAIL_CHANNEL_TYPES), 'twitter'],
  inboxOAuthes: Object.keys(EMAIL_CHANNEL_TYPES).filter(
    type => type !== 'forwarding'
  ),
}

export const keyFromChannelType = channelType => {
  if (!Array.isArray(channelType)) return channelType

  return channelType.join('-')
}

export const isChatChannelType = channelType => {
  return channelTypes.chat.includes(channelType)
}

export const isBridgeChannelType = channelType => {
  return channelTypes.social.includes(channelType)
}

export const isEmailChannelType = channelType => {
  return channelTypes.inboxes.includes(channelType)
}

export const isEmailOAuthChannelType = channelType => {
  return channelTypes.inboxOAuthes.includes(channelType)
}

export const isIMAPChannelType = channelType =>
  ['imap', EMAIL_CHANNEL_TYPES.imap].includes(channelType)

export const isEmailEngineChannelType = channelType => {
  return ['imap', 'office365', 'outlook', 'google'].includes(channelType)
}
