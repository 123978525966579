import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.stripe || emptyObj

export const selectCharges = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.charges
)

export const selectChargesByCustomerId = createBasicSelector(
  selectCharges,
  charges => charges.byCustomerId || emptyObj
)

export const selectChargesForCustomerId = createCachedSelector(
  selectChargesByCustomerId,
  (state, customerId) => customerId,
  (byCustomerId, customerId) => byCustomerId[customerId] || emptyObj
)((state, customerId) => customerId || 'unknown')

export const selectChargeForId = createCachedSelector(
  (state, { customerId }) => selectChargesForCustomerId(state, customerId),
  (state, { chargeId }) => chargeId,
  (chargesData, chargeId) =>
    chargesData.charges.find(charge => charge.id === chargeId) || emptyObj
)((state, { customerId }) => customerId || 'unknown')
