import { doAppGraphqlRequest } from 'ducks/requests/operations'

import { customFieldValueNodeQuery } from 'ducks/crm/customFields/operations/fetching'

import {
  FETCH_COMPANIES,
  FETCH_COMPANY_PAGE,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_STARTED,
  FETCH_COMPANY_FAILED,
} from '../types'
import {
  selectPageIsLoading,
  selectPageRequestKey,
  selectPageRequestVariables,
} from '../selectors'

import {
  companiesNormalizationSchema,
  companyNormalizationSchema,
} from '../schema'

const query = `
  query Companies(
    $after: String,
    $filter: CompanyFilter,
    $first: Int,
  ) {
    companies(after: $after, filter: $filter, first: $first) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        contacts {
          totalCount
        }
        conversationCount
        createdAt
        domain
        logoUrl
        name
      }
    }
  }
`

export function doFetchCompanies() {
  return (dispatch, getState) => {
    const state = getState()
    const loading = selectPageIsLoading(state)
    const requestKey = selectPageRequestKey(state)
    const variables = selectPageRequestVariables(state)

    if (loading) return null

    return dispatch(
      doAppGraphqlRequest(FETCH_COMPANIES, query, variables, {
        normalizationSchema: companiesNormalizationSchema,
        meta: {
          requestKey,
        },
      })
    )
  }
}

const companyCustomFieldValuesSubQuery = `
  customFieldValues(first: 20, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    ${customFieldValueNodeQuery}
  }
`

const fetchCompanyCustomFieldsFirstPageQuery = `query CompanyCustomFieldsFirstPageQuery($after: String, $id: ID!) {
  node(id: $id) {
    id
    ... on Company {
      ${companyCustomFieldValuesSubQuery}
      domain
      name
      logoUrl
    }
  }
}`
const fetchCompanyCustomFieldsQuery = ``

export function doFetchCompany(companyId) {
  return async dispatch => {
    let hasNextPage
    let after
    let firstLoop = true
    let fetchedCompanyId

    dispatch({
      type: FETCH_COMPANY_STARTED,
      entities: {
        companies: {
          [companyId]: { id: companyId, isLoading: true },
        },
      },
      meta: { mergeEntities: true },
    })
    do {
      const variables = {
        after,
        id: companyId,
      }
      // eslint-disable-next-line no-await-in-loop
      const result = await dispatch(
        doAppGraphqlRequest(
          FETCH_COMPANY_PAGE,
          firstLoop
            ? fetchCompanyCustomFieldsFirstPageQuery
            : fetchCompanyCustomFieldsQuery,
          variables,
          {
            meta: { mergeEntities: true },
            normalizationSchema: { node: companyNormalizationSchema },
          }
        )
      )
      const { company } = result
      if (!company) {
        dispatch({
          type: FETCH_COMPANY_SUCCESS,
          entities: {
            companies: {
              [companyId]: {
                id: companyId,
                isPartiallyLoaded: true,
                isLoaded: true,
                isLoading: false,
                isMissing: true,
              },
            },
          },
          meta: { mergeEntities: true },
        })

        return
      }
      const { customFieldValues } = company || {}
      const { pageInfo } = customFieldValues || {}
      fetchedCompanyId = company.id
      hasNextPage = pageInfo?.hasNextPage
      after = pageInfo?.endCursor
      firstLoop = false
    } while (hasNextPage)

    try {
      const company = {
        id: companyId,
        isPartiallyLoaded: true,
        isLoaded: true,
        isMissing: false,
        isLoading: false,
      }
      dispatch({
        type: FETCH_COMPANY_SUCCESS,
        entities: {
          companies: companyId
            ? {
                [companyId]: company,
                [fetchedCompanyId]: {
                  ...company,
                  id: fetchedCompanyId,
                },
              }
            : {},
        },
        meta: { mergeEntities: true },
      })
    } catch (error) {
      dispatch({
        type: FETCH_COMPANY_FAILED,
        error: true,
        payload: error,
      })
    }
  }
}
