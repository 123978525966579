import { createSelector } from 'reselect'

import selectIntegrationCredentialsById from './selectIntegrationCredentialsById'

export default createSelector(selectIntegrationCredentialsById, byId => {
  return Object.keys(byId).reduce((list, id) => {
    const item = byId[id]
    if (item.kind === 'account') return [...list, item]
    return list
  }, [])
})
