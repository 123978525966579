import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import {
  selectCurrentMatrixUserId,
  selectMemberUserIdFromProps,
} from 'ducks/chat/selectors/users'
import { selectWidgetAccessLists } from 'ducks/accessList/selectors'
import { selectWidgetsById } from 'ducks/widgets/selectors/widgets'
import { emptyObj } from 'util/objects'
import { selectCurrentUserId } from 'ducks/currentUser/selectors'
import { selectCurrentPaidActiveAgents } from 'ducks/agents/selectors'
import selectAgentsById from 'selectors/agents/selectAgentsById'
import { getCurrentMatrixUserId } from '../utils/users'
import { AGENT_USER_REGEX } from '../utils/constants'

export function selectBase(state) {
  const { chat: { presence = emptyObj } = emptyObj } = state
  return presence
}

export const selectPresenceSettings = createSelector(
  selectBase,
  base => base.settings || emptyObj
)

export const selectAutoPresenceSetting = createSelector(
  selectPresenceSettings,
  settings => settings.autoPresence
)

export const selectAutoAwaySetting = createSelector(
  selectPresenceSettings,
  settings => settings.autoAway
)

export const selectAutoOfflineSetting = createSelector(
  selectPresenceSettings,
  settings => settings.autoOffline
)

export function selectByMatrixId(state) {
  return selectBase(state).byMatrixId
}

export const selectPresence = createSelector(
  selectByMatrixId,
  getCurrentMatrixUserId,
  (byMatrixId, currentMatrixUserId) =>
    byMatrixId[currentMatrixUserId] || emptyObj
)

export const selectPresenceByAgentId = createCachedSelector(
  selectByMatrixId,
  (_, agentId) => agentId,
  (byMatrixId, agentId) => {
    // only return a default presence if there is an agentId passed
    if (!agentId) return null

    const matchingMatrixId = Object.keys(byMatrixId || {}).find(matrixId => {
      const { groups: { agent_id: matrixAgentId } = {} } =
        matrixId.match(AGENT_USER_REGEX) || {}

      return matrixAgentId === agentId
    })

    return byMatrixId[matchingMatrixId] || 'offline'
  }
)((_, agentId) => agentId || 'unknown')

// NOTE (jscheel): This selector scopes the participating chat agents based on
// the enabled status of this widget, the enabled status of chat on the widget,
// the access lists of the widget, and the current user's access.
export const selectScopedChatParticipatingAgents = createSelector(
  selectAgentsById,
  selectCurrentPaidActiveAgents,
  selectWidgetAccessLists,
  selectWidgetsById,
  selectCurrentUserId,
  (
    agentsById,
    paidAgents,
    widgetAccessListsById,
    widgetsById,
    currentUserId
  ) => {
    const agentsWithChat = new Set()
    const accessLists = Object.values(widgetAccessListsById)
    for (let idx = 0; idx < accessLists.length; idx += 1) {
      const list = accessLists[idx]
      const widgetId = list.channelId.replace(/wid_/, '')
      const widget = widgetsById[widgetId]
      // NOTE (jscheel): In this case, all agents should be displayed, because
      // of some archaic logic we have that says an empty agents array means
      // all agents should be included.
      const allAgents = list.agentIds.length === 0
      const includeWidget =
        widget &&
        widget.publishedSettings.enabled &&
        widget.publishedSettings['chat.enabled'] &&
        (allAgents || list.agentIds.includes(currentUserId))
      if (includeWidget) {
        if (allAgents) {
          paidAgents.forEach(agentsWithChat.add, agentsWithChat)
          // NOTE (jscheel): Reminder that this breaks entirely out of the for
          // loop so that we don't bother computing any more access, because
          // everyone has access at this point.
          break
        }
        list.agentIds.forEach(id => {
          const agent = agentsById[id]
          if (agent) {
            agentsWithChat.add(agent)
          }
        })
      }
    }

    return Array.from(agentsWithChat).filter(a => a !== undefined)
  }
)

export const selectAgentsOnlineCount = createSelector(
  selectByMatrixId,
  selectScopedChatParticipatingAgents,
  (byId, participatingAgents) => {
    return participatingAgents.filter(agent => {
      return byId[agent.chatId] === 'online'
    }).length
  }
)

export const selectCurrentContactMatrixUserPresence = createSelector(
  selectByMatrixId,
  selectCurrentMatrixUserId,
  (matrixId, matrixUserId) => {
    return matrixId[matrixUserId] || 'unknown'
  }
)

export function selectIsMxClientLoading(state) {
  return selectPresence(state).isLoading
}

export function selectMxEventFromProps(_, props) {
  return props.mxEvent
}

export function makeSelectPresenceByMemberId() {
  return createSelector(
    selectByMatrixId,
    selectMemberUserIdFromProps,
    (byMatrixId, userId) => {
      return byMatrixId[userId] || 'offline'
    }
  )
}
