import createCachedSelector from 're-reselect'

import selectGitHubIssues from './selectGitHubIssues'

export default createCachedSelector(
  selectGitHubIssues,
  (state, { repositoryId }) => repositoryId,
  (repositories, repositoryId) => {
    return repositories
      .filter(({ repository: { id } }) => id === repositoryId)
      .map(({ id, title }) => ({ value: id, text: title }))
  }
)((state, { repositoryId }) => repositoryId)
