import { oauthTokenSelector } from 'selectors/app'
import { FETCH_CHARGES_REQUEST } from 'ducks/integrations/recharge/types'
import { doSdkRequest } from 'ducks/requests/operations'

import * as api from '../api'

const doFetchCharges = ({ integrationId, subscriptionId }) => {
  return doSdkRequest(
    FETCH_CHARGES_REQUEST,
    async ({ getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)

      const { response } = await api.getCharges(
        token,
        integrationId,
        subscriptionId
      )
      return response
    },
    { integrationId, subscriptionId }
  )
}

export default doFetchCharges
