import { createSelector } from 'reselect'

import selectCurrentIntegration from './selectCurrentIntegration'

export default createSelector(selectCurrentIntegration, currentIntegration => {
  if (currentIntegration && currentIntegration.settings)
    return currentIntegration.settings.settings

  return { foo: 'bar' }
})
