import { oauthTokenSelector } from 'selectors/app'

import debug from 'util/debug'
import * as api from '../api'

const doFetchCustomerByEmail = (integrationId, email) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const token = oauthTokenSelector(state)

  try {
    const { response, code } = await api.getCustomerByEmail(
      token,
      integrationId,
      email
    )
    return { response, code }
  } catch (error) {
    debug(error)
    return error
  }
}

export default doFetchCustomerByEmail
