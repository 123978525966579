const BASIC_FIELDS = `
  id
  locale
  name
  mailboxIds
  account {
    id
  }
  translations {
    value
    locale
    id
  }
`

export const getAllQuery = `
  query CustomerRatingSettings {
    customerRatingSettings {
      nodes {
        ${BASIC_FIELDS}
      }
    }
  }
`

export const upsertQuery = `
  mutation CustomerRatingSettingUpsert(
    $locale: String,
    $name: String,
    $settingId: String,
    $translations: JSON,
    $mailboxIds: [String],
  ) {
    customerRatingSettingUpsert(input: {
      locale: $locale
      name: $name
      settingId: $settingId
      mailboxIds: $mailboxIds
      translations: $translations
    }) {
      customerRatingSetting {
        ${BASIC_FIELDS}
      }
    }
  }
`

export const deleteQuery = `
  mutation CustomerRatingSettingDelete(
    $settingId: String
  ) {
    customerRatingSettingDelete(input: {
      settingId: $settingId
    }) {
      deletedCustomerRatingSettingId
      errors {
        message
        path
      }
    }
  }
`
