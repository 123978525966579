import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const integrationIdAndCustomerEmailCacheKey = (
  state,
  integrationId,
  { email }
) => `${integrationId || 'unknown'}-${email || 'unknown'}`

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.stripe || emptyObj

export const selectCustomers = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.customers
)

const selectCustomersByIntegrationId = createBasicSelector(
  selectCustomers,
  customers => customers.byIntegrationId || emptyObj
)

export const selectCustomersForIntegrationId = createCachedSelector(
  selectCustomersByIntegrationId,
  (state, integrationId) => integrationId,
  (customersByIntegrationId, integrationId) =>
    customersByIntegrationId[integrationId] || emptyObj
)((state, integrationId) => integrationId || 'unknown')

export const selectCustomersByCustomerEmail = createCachedSelector(
  selectCustomersForIntegrationId,
  customersForIntegrationId =>
    customersForIntegrationId.byCustomerEmail || emptyObj
)((state, integrationId) => integrationId || 'unknown')

export const selectCustomerForCustomerEmail = createCachedSelector(
  selectCustomersByCustomerEmail,
  (state, integrationId, { email }) => email,
  (byCustomerEmail, email) => byCustomerEmail[email] || emptyObj
)(integrationIdAndCustomerEmailCacheKey)
