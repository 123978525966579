export const INSTALLATION_ACTION_TYPE_OAUTH_LINK =
  'INSTALLATION_ACTION_TYPE_OAUTH_LINK'
export const INSTALLATION_ACTION_TYPE_EMAIL_MARKETING_OAUTH_LINK =
  'INSTALLATION_ACTION_TYPE_EMAIL_MARKETING_OAUTH_LINK'
export const INSTALLATION_ACTION_TYPE_EXTERNAL_LINK =
  'INSTALLATION_ACTION_TYPE_EXTERNAL_LINK'
export const INSTALLATION_ACTION_TYPE_DRAWER_ACTION =
  'INSTALLATION_ACTION_TYPE_DRAWER_ACTION'
export const INSTALLATION_ACTION_TYPE_ACTIVATE =
  'INSTALLATION_ACTION_TYPE_ACTIVATE'

// DEPRECATED: These are types added in order to use the old links and modals
// on the new integrations screen
export const INSTALLATION_ACTION_TYPE_LINK = 'INSTALLATION_ACTION_TYPE_LINK'
export const INSTALLATION_ACTION_TYPE_AUTH_BUTTON =
  'INSTALLATION_ACTION_TYPE_AUTH_BUTTON'
