import { selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider } from 'ducks/integrations/selectors'

export default function selectGitHubHiddenFields(state) {
  const {
    hidden,
  } = selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider(state, {
    provider: 'github',
  })
  return hidden
}
