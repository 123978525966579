import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectChargesForSubscriptionId,
  selectChargeForId,
} from 'ducks/integrations/recharge/selectors/charges'

import doFetchCharges from 'ducks/integrations/recharge/operations/doFetchCharges'
import doFetchCharge from 'ducks/integrations/recharge/operations/doFetchCharge'

export const useRechargeCharges = (integrationId, subscriptionId) => {
  const dispatch = useDispatch()

  const chargesData = useSelector(state =>
    selectChargesForSubscriptionId(state, subscriptionId)
  )

  useEffect(
    () => {
      if (chargesData?.charges === undefined && subscriptionId) {
        dispatch(doFetchCharges({ integrationId, subscriptionId }))
      }
    },
    [dispatch, integrationId, subscriptionId, chargesData]
  )

  if (chargesData) {
    return chargesData
  }

  return {}
}

export const useRechargeCharge = (integrationId, subscriptionId, chargeId) => {
  const dispatch = useDispatch()

  const charge = useSelector(state =>
    selectChargeForId(state, { subscriptionId, chargeId })
  )

  useEffect(
    () => {
      if (charge === undefined && subscriptionId && chargeId) {
        dispatch(doFetchCharge({ integrationId, subscriptionId, chargeId }))
      }
    },
    [dispatch, integrationId, subscriptionId, chargeId, charge]
  )

  if (charge) {
    return charge
  }

  return {}
}
