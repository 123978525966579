import { BOOTSTRAP_APP_DATA_SUCCESS } from 'ducks/bootstrap/types'

import { FETCH_INTEGRATIONS_SUCCESS } from '../../types'

const defaultState = false // Initial state assumes something is either loading this or going to load it very shortly
export default function loadingReducer(state = defaultState, { type }) {
  if ([FETCH_INTEGRATIONS_SUCCESS, BOOTSTRAP_APP_DATA_SUCCESS].includes(type)) {
    return true
  }
  return state
}
