import { createSelector } from 'reselect'

import selectCurrentTicketLinkedCards from './selectCurrentTicketLinkedCards'

export default createSelector(selectCurrentTicketLinkedCards, cards => {
  if (!cards) return cards
  return cards.reduce((result, card) => {
    // eslint-disable-next-line no-param-reassign
    result[card.id] = card
    return result
  }, {})
})
