import Bugsnag from '@bugsnag/js'
import emailParser from 'email-addresses'
import { isEmpty } from 'util/objects'
import { twitterHandle } from 'ducks/customers/utils'
import { extractTwitterHandles } from 'util/strings'
import { isEmpty as isEmptyArray, uniqByProp } from 'util/arrays'
import { getAccountTwitterUsername } from 'util/ticket'
import { selectCurrentMailbox } from 'selectors/mailboxes/selectCurrentMailbox'
import { selectMailboxes } from 'selectors/mailboxes/selectMailboxes'
import { selectIsLoggingNewConversation } from 'selectors/page'
import { queryParams } from 'util/params'
import { selectGroupsById } from 'selectors/app/groups'
import { selectCurrentUserId } from 'ducks/currentUser/selectors'
import { selectReassignTicketOnReply } from 'selectors/tickets/assignment'
import { selectFolderIdsMatchingDraftForCurrentUser } from 'selectors/folders'
import { FETCH_DRAFTS } from './constants'

const isAgentResponse = change => !!change.agent_response

const isActor = actor => {
  // very loose check - just checks if its not nil/empty
  return actor && !isEmpty(actor)
}

const getTo = (action = {}) => {
  if (isAgentResponse(action.change)) {
    return action.change &&
      Array.isArray(action.change.to) &&
      action.change.to.length > 0
      ? action.change.to[0]
      : action.change.to
  }
  return action.actor
}

export const isSaveable = draft => {
  const wasSavedBefore = !!draft.serverVersion
  if (wasSavedBefore) return true
  const bodyIsEmpty = !draft.body || draft.body === ''
  const hasAttachments =
    draft.attachmentsNormalized && draft.attachmentsNormalized.length > 0
  if (bodyIsEmpty && !hasAttachments) return false
  return true
}

function isSameActor(firstAction = {}, lastAction = {}) {
  const first = getTo(firstAction)
  const last = getTo(lastAction)
  return first && last && first.id && last.id && first.id === last.id
}

/* eslint-disable no-param-reassign */
const buildRecipientsFromAction = (draftDefaults, action, toOverride) => {
  const { change } = action
  const resolvedTo = getTo(action)
  if (toOverride && isActor(toOverride)) {
    draftDefaults.to = [toOverride]
  } else if (isActor(resolvedTo)) {
    draftDefaults.to = [resolvedTo]
  }
  if (change.cc) draftDefaults.cc = change.cc
  if (change.bcc) draftDefaults.bcc = change.bcc
}

const getUniqueRecipients = (original, merged) => {
  const getArray = value => (isEmptyArray(value) ? [] : value)
  return uniqByProp([...getArray(original), ...getArray(merged)], 'id')
}

const updateCCAndBCC = (change, shouldMerge, originalState) => {
  if (!shouldMerge || !originalState) return change
  return {
    ...change,
    cc: getUniqueRecipients(originalState.cc, change.cc),
    bcc: getUniqueRecipients(originalState.bcc, change.bcc),
  }
}

/* eslint-enable no-param-reassign */
export const buildReplyDraftDefaults = (state, ticket, collection) => {
  const { currentUserId, reassignTicketOnReply, defaultReplyState } = state
  const { lastReply, firstReply, hasUserChanged, changesets } = collection || {}
  const reduxState = global.app.store.getState()
  const groupsById = selectGroupsById(reduxState)

  const draftDefaults = {
    replyType: 'reply-all',
    mailboxId: ticket.mailboxId,
    assigneeId: ticket.assignee && ticket.assignee.id,
    assigneeGroupId: groupsById[ticket.assigned_group_id]?.id,
  }
  if (reassignTicketOnReply) {
    draftDefaults.assigneeId = currentUserId
  }
  if (defaultReplyState) {
    draftDefaults.state = defaultReplyState
  }

  // Recipient resolution
  if (lastReply) {
    const lastReplyWithUpdatedChange = {
      ...lastReply,
      change: updateCCAndBCC(
        lastReply.change,
        changesets?.find(c => c.id === lastReply.changeset)?.isFromMerge,
        ticket.draftDefaults
      ),
    }
    // if the user has changed and first and last reple are from different actors
    if (!hasUserChanged) {
      buildRecipientsFromAction(draftDefaults, lastReplyWithUpdatedChange)
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!isSameActor(firstReply, lastReplyWithUpdatedChange)) {
        buildRecipientsFromAction(draftDefaults, lastReplyWithUpdatedChange)
      } else {
        buildRecipientsFromAction(
          draftDefaults,
          lastReplyWithUpdatedChange,
          ticket.customer
        )
      }
    }
  } else {
    draftDefaults.to = [ticket.customer]
  }

  // defaults with no To are incorrect
  if (!draftDefaults.to || draftDefaults.to.length === 0) {
    try {
      const sameActor = isSameActor(firstReply, lastReply)
      Bugsnag.notify(
        new Error('Detected Draft Defaults with empty To'),
        event => {
          event.addMetadata('metaData', {
            meta: {
              to: draftDefaults.to,
              ticketId: ticket.id,
              lastReplyId: lastReply && lastReply.id,
              firstReplyId: firstReply && firstReply.id,
              hasUserChanged,
              sameActor,
            },
          })
        }
      )
    } catch (e) {
      // pass
    }
    return null
  }

  return draftDefaults
}

export const buildNoteDefaults = (state, ticket) => {
  const { currentUserId, reassignTicketOnNote } = state
  const draftDefaults = {
    isNote: true,
    assigneeId: ticket.assignee && ticket.assignee.id,
    assigneeGroupId: ticket.assigned_group_id,
  }
  if (reassignTicketOnNote) {
    draftDefaults.assigneeId = currentUserId
  }
  draftDefaults.state = ticket.state
  return draftDefaults
}

export const buildTwitterDraftDefaults = (state, ticket, collection) => {
  const { lastCustomerReply, firstMessage } = collection || {}
  const customerMessage = lastCustomerReply || firstMessage

  const draftDefaults = buildReplyDraftDefaults(state, ticket, collection)
  if (!draftDefaults) return null
  const customer = ticket.customer

  draftDefaults.isTwitter = true

  const to = draftDefaults.to && draftDefaults.to[0]

  const twitterRecipients = []
  const twitterHandles = []

  if (to && to.twitter) {
    twitterRecipients.push(to)
    twitterHandles.push(`@${to.twitter.username}`)
  } else if (customer && customer.twitter) {
    twitterRecipients.push({
      twitter: { username: customer.twitter.username },
    })
    twitterHandles.push(`@${customer.twitter.username}`)
  }

  delete draftDefaults.to
  delete draftDefaults.cc
  delete draftDefaults.bcc

  if (customerMessage) {
    const { body } = customerMessage.change

    const actorHandle = twitterHandle(customerMessage.actor)
    const accountHandle = getAccountTwitterUsername(ticket)
    const otherHandles = extractTwitterHandles(body).filter(
      item => item !== accountHandle
    )
    const handles = [actorHandle, ...otherHandles].filter(h => !!h)
    handles.forEach(handle => {
      if (handle) {
        const username = handle.substr(1)
        if (
          twitterRecipients.length === 0 ||
          (twitterRecipients.length > 0 &&
            twitterRecipients[0].twitter &&
            twitterRecipients[0].twitter.username !== username)
        ) {
          twitterHandles.push(`@${username}`)
          twitterRecipients.push({
            twitter: {
              username,
            },
          })
        }
      }
    })
  }

  return {
    ...draftDefaults,
    twitterPrefix: twitterHandles.length > 0 && `${twitterHandles.join(' ')} `,
    twitterRecipients,
  }
}

export const buildFacebookDraftDefaults = (state, ticket, collection) => {
  const draftDefaults = buildReplyDraftDefaults(state, ticket, collection)
  if (!draftDefaults) return null

  draftDefaults.isFacebook = true

  const to = draftDefaults.to && draftDefaults.to[0]

  delete draftDefaults.cc
  delete draftDefaults.bcc

  return {
    ...draftDefaults,
    facebookRecipient: to,
  }
}

export const buildNewConversationDraftDefaults = draftType => {
  const state = app.store.getState()
  if (draftType === 'note') {
    return buildNoteDefaults(state.tickets, { state: 'opened' })
  }
  // HACK (jscheel): This is kinda a violation, but there's too much going on
  // here right now. Need to clean this up better later.
  const isNote = selectIsLoggingNewConversation(state)
  const params = queryParams()
  const title = params.subject || ''
  const body = params.body || ''
  const mailbox = selectCurrentMailbox(state) || selectMailboxes(state)[0]
  const to = []
  const cc = []
  const bcc = []
  const currentUserId = selectCurrentUserId(state)
  const reassignTicketOnReply = selectReassignTicketOnReply(state)

  if (params.to) {
    const parsed = emailParser.parseAddressList({
      input: params.to,
      rejectTLD: true,
    })
    if (parsed && parsed[0]) {
      const item = parsed[0]
      to.push({ email: item.address, name: item.name })
    }
  }
  if (params.cc) {
    const parsed = emailParser.parseAddressList({
      input: params.cc,
      rejectTLD: true,
    })
    parsed.forEach(item => {
      cc.push({ email: item.address, name: item.name })
    })
  }
  if (params.bcc) {
    const parsed = emailParser.parseAddressList({
      input: params.bcc,
      rejectTLD: true,
    })
    parsed.forEach(item => {
      bcc.push({ email: item.address, name: item.name })
    })
  }

  return {
    replyType: 'reply-all',
    mailboxId: mailbox?.id,
    assigneeId: reassignTicketOnReply ? currentUserId : null,
    assigneeGroupId: null,
    to,
    cc,
    bcc,
    isNote,
    body,
    title,
  }
}

export const buildDraftDefaults = (state, ticket, collection) => {
  if (ticket.id === 'new') {
    return buildNewConversationDraftDefaults()
  }
  if (ticket.isTwitterTicket) {
    return buildTwitterDraftDefaults(state, ticket, collection)
  }
  if (ticket.isFacebookTicket) {
    return buildFacebookDraftDefaults(state, ticket, collection)
  }
  return buildReplyDraftDefaults(state, ticket, collection)
}

export const updateToWithRecipientName = (to, value = '') => {
  const recipient = to?.[0]
  if (!recipient) return to

  const parts = value.split(' ')
  const newTo = [
    {
      ...recipient,
      name: value,
      ...(parts[0] && { first_name: parts[0] }),
      ...(parts[1] && { last_name: parts[1] }),
    },
    ...((to && to.slice(1)) || []),
  ]

  return newTo
}

export const buildSharedDraftActionMetadata = state => {
  const draftFolderIds = selectFolderIdsMatchingDraftForCurrentUser(state)
  const currentUserId = selectCurrentUserId(state)

  return {
    draftFolderIds,
    currentUserId,
  }
}

export const fetchDraftsActionRequestKey = (ticketId, draftType) => {
  return `${FETCH_DRAFTS}/${draftType}/${ticketId}`
}

// Check src/ducks/drafts2/__tests__/formatEmailAddressesStringForEmailParser.test.js
export const formatEmailAddressesStringForEmailParser = emailAddressesString =>
  emailAddressesString?.trim().replace(/,?\s+/g, ',')
