import createCachedSelector from 're-reselect'

export function selectBase(state) {
  return state.crm.companies
}

export function selectCompaniesById(state) {
  return selectBase(state).byId
}

export const selectCompanytById = createCachedSelector(
  selectCompaniesById,
  (_state, companyId) => companyId,
  (companiesById, companyId) => companiesById[companyId]
)((_state, companyId) => companyId || 'unknown')

export function selectCompanyForId(state, { id }) {
  return selectCompaniesById(state)[id]
}

export function selectLatestLoadedRequestKey(state) {
  return selectBase(state).latestLoadedRequestKey
}

export function selectLatestLoadedSearchTerm(state) {
  return selectBase(state).latestLoadedSearchTerm
}

export function selectLatestRequestKey(state) {
  return selectBase(state).latestRequestKey
}

export function selectRequestsByKey(state) {
  return selectBase(state).byRequestKey
}

export function selectPageCursor(state) {
  return selectBase(state).cursor
}

export function selectTotalCompaniesCount(state) {
  return selectBase(state).totalCount
}
