import { selectCurrentEntities } from 'ducks/entities/selectors'
import { createSelector } from 'reselect'
import {
  agentNotificationPreferences,
  defaultAgentNotificationPreferences,
} from 'ducks/entities/schema'
import { emptyArr } from 'util/arrays'
import { PREFERENCE_SCOPES } from '../constants'

// selectCurrentEntitiesById(state, agentNotificationPreferences.key) || emptyObj

export const selectCurrentAgentNotificationPreferences = state =>
  selectCurrentEntities(state, agentNotificationPreferences.key) || emptyArr

export const selectCurrentAgentNotificationPreferencesForSettingsPage = createSelector(
  selectCurrentAgentNotificationPreferences,
  preferences => {
    return preferences.reduce((accumulator, currentValue) => {
      const key = currentValue.scopeId
      if (!accumulator[key]) {
        // eslint-disable-next-line no-param-reassign
        accumulator[key] = []
      }

      accumulator[key].push(currentValue)

      return accumulator
    }, {})
  }
)

export const selectCurrentAgentNotificationPreferencesScope = createSelector(
  selectCurrentAgentNotificationPreferences,
  preferences => {
    if (!preferences?.length) {
      return PREFERENCE_SCOPES.UNSCOPED
    }

    return preferences.some(entity => entity.scopeId === 'unscoped')
      ? PREFERENCE_SCOPES.UNSCOPED
      : PREFERENCE_SCOPES.SCOPED
  }
)
export const selectDefaultNotificationPreferenceSettings = state =>
  selectCurrentEntities(state, defaultAgentNotificationPreferences.key) ||
  emptyArr
