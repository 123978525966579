import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_CHARGES_REQUEST_STARTED,
  FETCH_CHARGES_REQUEST_SUCCESS,
  FETCH_CHARGES_REQUEST_FAILED,
} from 'ducks/integrations/stripe/types'

const initialState = {
  byCustomerId: {},
}

export const initialSingleState = {
  charges: [],
  isInitialized: false,
  isLoading: false,
  isFetchingMore: false,
  hasMore: false,
}

export default createActionTypeReducer(
  {
    [FETCH_CHARGES_REQUEST_STARTED]: (draftState, action) => {
      const { customerId, lastItemId } = action.payload

      if (!draftState.byCustomerId[customerId]) {
        draftState.byCustomerId[customerId] = Object.assign(
          {},
          initialSingleState
        )
      }

      draftState.byCustomerId[customerId].isInitialized = true
      draftState.byCustomerId[customerId].isLoading = lastItemId === undefined
      draftState.byCustomerId[customerId].isFetchingMore =
        lastItemId !== undefined

      return draftState
    },
    [FETCH_CHARGES_REQUEST_SUCCESS]: (draftState, action) => {
      const { customerId } = action.meta.requestParameters
      const { data, has_more: hasMore } = action.payload

      Object.assign(draftState.byCustomerId[customerId], {
        isLoading: false,
        isFetchingMore: false,
        charges: [...draftState.byCustomerId[customerId].charges, ...data],
        hasMore,
      })

      return draftState
    },
    [FETCH_CHARGES_REQUEST_FAILED]: (draftState, action) => {
      const { customerId } = action.meta.requestParameters

      Object.assign(draftState.byCustomerId[customerId], {
        isLoading: false,
        isFetchingMore: false,
      })

      return draftState
    },
  },
  initialState
)
