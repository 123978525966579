import { doLinkResource } from 'actions/userIntegrations'
import { oauthTokenSelector } from 'selectors/app'

import { selectIntegrationCredentialsForProvider } from 'ducks/integrations/selectors'

import integrationApi from '../../api'
import doFetchJiraServerIssues from './doFetchJiraServerIssues'
import {
  CREATE_ISSUE_REQUEST,
  CREATE_ISSUE_SUCCESS,
  CREATE_ISSUE_ERROR,
} from '../types'

import doPostTicketLinkOnJiraServerIssue from './doPostTicketLinkOnJiraServerIssue'

export default function doCreateAndLinkJiraServerIssueToTicket(
  siteId,
  projectId,
  {
    assignee,
    component = [],
    description,
    label,
    parent,
    priority,
    summary,
    type,
  }
) {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_ISSUE_REQUEST })
    const state = getState()
    const token = oauthTokenSelector(state)
    const { url: siteUrl } = selectIntegrationCredentialsForProvider(state, {
      provider: 'jira_server',
    })
    const fields = {
      description,
      project: { id: projectId },
      summary,
      issuetype: { id: type },
    }
    if (assignee) fields.assignee = { name: assignee }
    if (label) fields.labels = label
    if (priority) fields.priority = { id: priority }
    if (component && component.length > 0) {
      fields.components = component.map(id => ({ id }))
    }
    if (parent) fields.parent = { id: parent }

    integrationApi
      .post(
        token,
        `proxy/jira-server/rest/api/2/issue`,
        {},
        JSON.stringify({
          fields,
        })
      )
      .then(({ json: issue }) => {
        if (!issue.id) {
          throw new Error('Failed to create Jira Issue')
        }
        dispatch({
          meta: {},
          payload: { issue },
          type: CREATE_ISSUE_SUCCESS,
        })
        const url = `${siteUrl}/browse/${issue.key}`
        dispatch(
          doLinkResource({
            id: `${issue.id}`,
            provider: 'jira_server',
            title: `[${issue.key}] ${summary}`,
            url,
          })
        )
        dispatch(doFetchJiraServerIssues([issue.id]))
        dispatch(doPostTicketLinkOnJiraServerIssue(issue.id))
      })
      .catch(error => {
        dispatch({
          error: true,
          meta: {},
          payload: error,
          type: CREATE_ISSUE_ERROR,
        })
      })
  }
}
