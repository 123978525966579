export const FETCH_ACCOUNT_PREFERENCES =
  'account/preferences/FETCH_ACCOUNT_PREFERENCES'
export const FETCH_ACCOUNT_PREFERENCES_STARTED =
  'account/preferences/FETCH_ACCOUNT_PREFERENCES_STARTED'
export const FETCH_ACCOUNT_PREFERENCES_SUCCESS =
  'account/preferences/FETCH_ACCOUNT_PREFERENCES_SUCCESS'
export const FETCH_ACCOUNT_PREFERENCES_FAILED =
  'account/preferences/FETCH_ACCOUNT_PREFERENCES_FAILED'
export const FETCH_ACCOUNT_PREFERENCE =
  'account/preferences/FETCH_ACCOUNT_PREFERENCE'
export const FETCH_ACCOUNT_PREFERENCE_STARTED =
  'account/preferences/FETCH_ACCOUNT_PREFERENCE_STARTED'
export const FETCH_ACCOUNT_PREFERENCE_SUCCESS =
  'account/preferences/FETCH_ACCOUNT_PREFERENCE_SUCCESS'
export const FETCH_ACCOUNT_PREFERENCE_FAILED =
  'account/preferences/FETCH_ACCOUNT_PREFERENCE_FAILED'

export const UPDATE_ACCOUNT_PREFERENCES =
  'account/preferences/UPDATE_ACCOUNT_PREFERENCES'
export const UPDATE_ACCOUNT_PREFERENCES_STARTED =
  'account/preferences/UPDATE_ACCOUNT_PREFERENCES_STARTED'
export const UPDATE_ACCOUNT_PREFERENCES_SUCCESS =
  'account/preferences/UPDATE_ACCOUNT_PREFERENCES_SUCCESS'
export const UPDATE_ACCOUNT_PREFERENCES_FAILED =
  'account/preferences/UPDATE_ACCOUNT_PREFERENCES_FAILED'

export const UPDATE_ACCOUNT_PREFERENCES_USAGE_ONBOARDING_STARTED =
  'account/preferences/UPDATE_ACCOUNT_PREFERENCES_USAGE_ONBOARDING_STARTED'
