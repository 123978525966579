export const BASIC_TEMPLATE_FIELDS = `
  id
  name
  categoryId
  snippet
  subject
  automaticActions {
    value
    type
  }
`

export const BY_ID_TEMPLATE_FIELDS = `
  ${BASIC_TEMPLATE_FIELDS}
  body
`

export const getAllQuery = () => `
query CommentTemplateQuery(
  $categoryIds: [String]
  $mailboxIds: [String]
  $keyword: String
  $source: String
) {
  commentTemplates(categoryIds: $categoryIds, keyword: $keyword, source: $source, mailboxIds: $mailboxIds) {
    ${BASIC_TEMPLATE_FIELDS}
  }
}
`

export const getByIdQuery = `
query CommentTemplateByIdQuery(
  $id: String
) {
  commentTemplate(id: $id) {
    ${BY_ID_TEMPLATE_FIELDS}
  }
}
`
