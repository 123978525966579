export const attachmentsQuery = `
  query Attachments($conversationId: ID, $conversationType: String) {
    attachments(filter: {
      conversationId: $conversationId,
      conversationType: $conversationType
    }) {
      nodes {
        id
        ticketId
        commentId
        changesetId
        createdAt
        fileType
        fileName
        fileSize
        url
        downloadUrl
        type
        scanReport {
          status
          matches
        }
        creator {
          name
        }
      }
    }
  }
`
