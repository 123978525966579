import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import { DRAFT_ORDER_DELETE_REQUEST } from '../types'

const mutation = `
  mutation draftOrderDelete($input: DraftOrderDeleteInput!) {
    draftOrderDelete(input: $input) {
      deletedId
      userErrors {
        field
        message
      }
    }
  }
`

const doDraftOrderDelete = (integrationId, id) => dispatch => {
  const variables = { input: { id } }

  return dispatch(
    doGraphqlRequest(DRAFT_ORDER_DELETE_REQUEST, mutation, variables, {
      graphqlFunc: graphql(integrationId),
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: 'Draft order removed',
          },
          failed: {
            enabled: true,
            content: 'Draft order removal failed',
          },
        },
      },
    })
  )
}

export default doDraftOrderDelete
