import { SORT_CONTACTS } from '../types'

const defaultState = {
  field: 'EMAIL',
  direction: 'ASC',
}

export default function reduceOrderBy(state = defaultState, { type, payload }) {
  if (type === SORT_CONTACTS) return payload
  return state
}
