import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const selectAttachments = state => state?.integrations?.attachments || emptyObj

export const selectAttachmentsByTicketId = createBasicSelector(
  selectAttachments,
  attachments => attachments.byTicketId || emptyObj
)

export const selectAttachmentsForTicketId = createCachedSelector(
  selectAttachmentsByTicketId,
  (state, ticketId) => ticketId,
  (byTicketId, ticketId) => byTicketId[ticketId] || emptyObj
)((state, ticketId) => ticketId || 'unknown')

export const selectAttachmentsForType = createCachedSelector(
  (state, { ticketId }) => selectAttachmentsForTicketId(state, ticketId),
  (state, { type }) => type,
  (attachments, type) => attachments[type] || emptyObj
)(
  (state, { ticketId, type }) => `${ticketId || 'unknown'}-${type || 'unknown'}`
)

export const selectIsLoadingForType = createCachedSelector(
  (state, { ticketId, type }) =>
    selectAttachmentsForType(state, { ticketId, type }),
  attachments => attachments.isLoading
)(
  (state, { ticketId, type }) => `${ticketId || 'unknown'}-${type || 'unknown'}`
)
