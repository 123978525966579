import { createSelector } from 'reselect'

import {
  selectCompaniesById,
  selectLatestLoadedRequestKey,
  selectRequestsByKey,
} from './base'
import { selectPageRequestKey } from './fetching'

export const selectPage = createSelector(
  selectPageRequestKey,
  selectRequestsByKey,
  (key, requests) => {
    return requests[key]
  }
)

export const selectLatestLoadedPage = createSelector(
  selectLatestLoadedRequestKey,
  selectRequestsByKey,
  (key, requests) => {
    return requests[key]
  }
)

export function selectLatestLoadedPageCompaniesIds(state) {
  const page = selectLatestLoadedPage(state)
  return (page && page.ids) || []
}

export const selectLatestLoadedPageCompanies = createSelector(
  selectLatestLoadedPageCompaniesIds,
  selectCompaniesById,
  (ids, companies) => {
    return ids.map(id => companies[id])
  }
)

export function selectLatestLoadedPageHasCompanies(state) {
  const ids = selectLatestLoadedPageCompaniesIds(state)
  return ids.length > 0
}
