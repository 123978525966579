import { BOOTSTRAP_APP_DATA_SUCCESS } from 'ducks/bootstrap/types'

import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_INTEGRATIONS_SUCCESS,
  REMOVE_INTEGRATION_STARTED,
  REMOVE_INTEGRATION_SUCCESS,
  UPDATE_INTEGRATION_CREDENTIALS_RESPONSE,
  REMOVE_INTEGRATION_BY_PROVIDER_SUCCESS,
} from '../../types'

const defaultState = {}

const reduceFetchIntegrationSuccess = (
  draftState,
  { payload: { integrations: { nodes = [] } = {} } = {} }
) => {
  // Clear our the existing state
  Object.keys(draftState).forEach(key => {
    delete draftState[key]
  })

  nodes.forEach(integration => {
    draftState[integration.id] = {
      ...integration,
      meta: {},
    }
  })
  return draftState
}

export default createActionTypeReducer(
  {
    [REMOVE_INTEGRATION_STARTED]: (
      draftState,
      { meta: { integrationId } = {} }
    ) => {
      draftState[integrationId].meta.removing = true
      return draftState
    },
    [UPDATE_INTEGRATION_CREDENTIALS_RESPONSE]: (
      draftState,
      { meta: { integrationId } = {} }
    ) => {
      draftState[integrationId].meta = {}
      return draftState
    },
    [REMOVE_INTEGRATION_BY_PROVIDER_SUCCESS]: (
      draftState,
      { payload = {} }
    ) => {
      payload.forEach(key => delete draftState[key])
      return draftState
    },
    [REMOVE_INTEGRATION_SUCCESS]: (
      draftState,
      { meta: { integrationId } = {} }
    ) => {
      delete draftState[integrationId]
      return draftState
    },
    [FETCH_INTEGRATIONS_SUCCESS]: reduceFetchIntegrationSuccess,
    [BOOTSTRAP_APP_DATA_SUCCESS]: reduceFetchIntegrationSuccess,
  },
  defaultState
)
