import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidV4 } from 'uuid'
import Search from '@groovehq/internal-design-system/lib/components/Search/Search'
import { divider } from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import {
  doFetchCannedReplies,
  doFetchCannedReplyCategories,
  doFetchCannedReplyCategoriesAndTemplatesBySearch,
} from 'ducks/cannedReplies/operations'
import {
  doFetchStarredCannedReplies,
  doCreateStarredCannedReply,
  doDeleteStarredCannedReply,
} from 'ducks/starredCannedReplies/operations'
import {
  selectLastRequestedSearchTerm,
  selectIsLoadingCannedReplyCategories,
  selectHasLoadedTemplates,
  selectMailboxIdForTemplate,
} from 'ducks/cannedReplies/selectors'
import { selectStarredCannedReplies } from 'ducks/starredCannedReplies/selectors'
import { selectMailboxIdFromTicketOrCurrent } from 'selectors/mailboxes'
import { debounce } from 'util/functions'
import usePrevious from 'util/hooks/usePrevious'
import { ENTER, TAB } from 'constants/keys'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { Loader } from 'shared/ui'
import { selectMailboxIds } from 'selectors/mailboxes/selectMailboxIds'
import useMenuAim from 'util/hooks/useMenuAim'

import { styles } from './styles'
import SearchEmpty from '../SearchEmpty'
import Empty from '../Empty'
import DropdownFooter from '../DropdownFooter'
import RepliesInCategory from './RepliesInCategory'

const KEYBOARD_NAVIGATOR_KEYS = { select: [ENTER, TAB] }
const recentlyUsedId = uuidV4()
const starredId = uuidV4()

const DropdownMenu = ({
  items,
  onSelect,
  recentlyUsedTemplates,
  hasLoadedReplyCategories,
  visible,
  onVisibleChange,
  setOpenPreview,
  onGetPreview,
  search,
  setSearch,
  setCurrentPreviewTemplateId,
  currentPreviewTemplateId,
  arrowPositionRef,
}) => {
  const searchRef = useRef()
  const containerRef = useRef()
  const menuRef = useRef()
  const searchTerm = useSelector(selectLastRequestedSearchTerm)
  const previousSearch = usePrevious(search)
  const dispatch = useDispatch()
  const [activeItemId, setActiveItemId] = useState()
  const isLoadingCategories = useSelector(selectIsLoadingCannedReplyCategories)
  const hasLoadedTemplates = useSelector(selectHasLoadedTemplates)
  const starredCannedReplies = useSelector(selectStarredCannedReplies)

  const noCannedReplies =
    !items.length && hasLoadedReplyCategories && searchTerm === ''

  const mailboxId = useSelector(selectMailboxIdFromTicketOrCurrent)
  const allMailboxesIds = useSelector(selectMailboxIds)
  const previousMailboxId = useSelector(selectMailboxIdForTemplate)

  const mailboxIds = useMemo(
    () => {
      return mailboxId ? [mailboxId] : allMailboxesIds
    },
    [mailboxId, allMailboxesIds]
  )

  const debouncedSearch = useMemo(
    () =>
      debounce(query => {
        dispatch(
          doFetchCannedReplyCategoriesAndTemplatesBySearch(query, mailboxIds)
        )
      }, 500),
    [dispatch, mailboxIds]
  )
  const debouncedGetPreview = useMemo(() => debounce(onGetPreview, 200), [
    onGetPreview,
  ])

  const list = useMemo(
    () => {
      if (hasLoadedReplyCategories && !hasLoadedTemplates) return []
      if (!items.length) return []

      const itemsCopy = [...items]

      if (recentlyUsedTemplates.length) {
        itemsCopy.unshift({
          id: recentlyUsedId,
          name: 'Recently used',
          total: recentlyUsedTemplates.length,
          type: 'category',
          templates: recentlyUsedTemplates,
        })
      }

      if (starredCannedReplies.length) {
        itemsCopy.unshift({
          id: starredId,
          name: 'Starred replies',
          total: starredCannedReplies.length,
          type: 'category',
          templates: starredCannedReplies,
        })
      }

      return itemsCopy
    },
    [
      items,
      recentlyUsedTemplates,
      hasLoadedReplyCategories,
      hasLoadedTemplates,
      starredCannedReplies,
    ]
  )

  const listWithoutEmptyTemplates = useMemo(
    () => {
      return list.filter(item => {
        if (item.type === 'category') {
          return item.templates.filter(template => template.id > 0).length
        }
        return true
      })
    },
    [list]
  )

  const noSearchResults =
    !!searchTerm.trim() &&
    !listWithoutEmptyTemplates.length &&
    hasLoadedReplyCategories

  const handleHeaderClick = useCallback(
    item => {
      if (item.type === 'category' && item.name !== 'Recently used') {
        if (!item.loaded && !item.loading) {
          dispatch(doFetchCannedReplies([item.id], searchTerm, mailboxIds))
        }
      }
      searchRef.current.focus()
    },
    [dispatch, searchTerm, mailboxIds]
  )

  const handleShowPreview = useCallback(
    (template, target) => {
      // eslint-disable-next-line no-param-reassign
      arrowPositionRef.current =
        target?.getBoundingClientRect().top -
        containerRef.current?.getBoundingClientRect().top
      setOpenPreview(true)
      setCurrentPreviewTemplateId(template?.id)
      if (currentPreviewTemplateId !== template?.id) {
        debouncedGetPreview(template)
      }
    },
    [
      debouncedGetPreview,
      setOpenPreview,
      currentPreviewTemplateId,
      setCurrentPreviewTemplateId,
      arrowPositionRef,
    ]
  )

  const handleChangeSearch = useCallback(
    e => {
      const { value } = e.target
      setOpenPreview(false)
      setSearch(value)
      debouncedSearch(value)
    },
    [debouncedSearch, setSearch, setOpenPreview]
  )

  const starredCannedRepliesIds = useMemo(
    () => starredCannedReplies.map(cannedReply => cannedReply.id),
    [starredCannedReplies]
  )

  const handleStarClick = useCallback(
    cannedReplyId => {
      if (starredCannedRepliesIds.indexOf(cannedReplyId) > -1) {
        dispatch(doDeleteStarredCannedReply({ cannedReplyId }))
      } else {
        dispatch(doCreateStarredCannedReply({ cannedReplyId }))
      }
    },
    [dispatch, starredCannedRepliesIds]
  )

  useEffect(
    () => {
      const hasData = !searchTerm?.trim() && items.length

      if (!visible) return
      if (search.trim()) return

      if (
        (previousSearch?.trim() && !search.trim()) ||
        (!hasData || !hasLoadedTemplates) ||
        previousMailboxId !== mailboxId
      ) {
        dispatch(doFetchCannedReplyCategories())
        dispatch(doFetchCannedReplies([], '', mailboxIds))
        dispatch(doFetchStarredCannedReplies())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visible, dispatch, search]
  )

  useEffect(
    () => {
      if (starredCannedReplies.length) {
        setActiveItemId(starredId)
        return
      }

      if (recentlyUsedTemplates.length) {
        setActiveItemId(recentlyUsedId)
      }
    },
    [starredCannedReplies.length, recentlyUsedTemplates.length]
  )

  useEffect(
    () => {
      if (visible && searchRef.current) {
        searchRef.current.focus()
      }
    },
    [visible]
  )

  const hasSearch = !!searchTerm.trim()

  const handleActivateCategory = useCallback(
    id => {
      setActiveItemId(id)
      setOpenPreview(false)
    },
    [setOpenPreview]
  )

  const handleActivateTemplate = useCallback(
    (templateWithCatId, template, _, target) => {
      setActiveItemId(templateWithCatId)
      handleShowPreview(template, target)
    },
    [handleShowPreview]
  )

  const handleActivateByMouse = useCallback(
    (data, e) => {
      setActiveItemId(data.id)
      if (data.item) {
        handleActivateTemplate(data.id, data.item, e, e.target)
      } else {
        handleActivateCategory(data.id)
      }
    },
    [handleActivateTemplate, handleActivateCategory]
  )

  const { handleMouseEnterItem, handleMouseLeaveMenu } = useMenuAim({
    menuElement: menuRef.current,
    activeItemId,
    activeItemIdChange: handleActivateByMouse,
  })

  if (noCannedReplies) {
    return <Empty css={styles.container} onVisibleChange={onVisibleChange} />
  }

  return (
    <div css={styles.container} ref={containerRef}>
      <div css={styles.searchContainer}>
        <Search
          placeholder="Search replies…"
          onChange={handleChangeSearch}
          value={search}
          ref={searchRef}
          css={styles.search}
        />
        {divider}
      </div>
      {isLoadingCategories && (
        <div className="grui p-12">
          <Loader />
        </div>
      )}
      {noSearchResults &&
        !isLoadingCategories && (
          <SearchEmpty onVisibleChange={onVisibleChange} />
        )}
      {!noSearchResults &&
        !isLoadingCategories && (
          <KeyboardNavigator
            activeKey={searchTerm}
            keys={KEYBOARD_NAVIGATOR_KEYS}
            focusElement={searchRef.current}
            css={styles.templatesWrapper}
            // We want to control it to allow more forgiving mouse leave
            activateOnHover={false}
          >
            <div
              className="grui pb-10"
              ref={menuRef}
              onMouseLeave={handleMouseLeaveMenu}
            >
              {listWithoutEmptyTemplates.map(item => (
                <RepliesInCategory
                  key={item.id}
                  item={item}
                  handleHeaderClick={handleHeaderClick}
                  onSelect={onSelect}
                  active={
                    recentlyUsedId === item.id ||
                    starredId === item.id ||
                    hasSearch
                  }
                  handleMouseEnterItem={handleMouseEnterItem}
                  activeItemId={activeItemId}
                  handleActivateCategory={handleActivateCategory}
                  handleActivateTemplate={handleActivateTemplate}
                  handleStarClick={handleStarClick}
                  starredCannedRepliesIds={starredCannedRepliesIds}
                />
              ))}
            </div>
          </KeyboardNavigator>
        )}
      {<DropdownFooter onVisibleChange={onVisibleChange} />}
    </div>
  )
}

export default React.memo(DropdownMenu)
