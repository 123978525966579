export const EMAIL_MARKETING_FETCH_INTEGRATIONS =
  'integrations/emailMarketing/EMAIL_MARKETING_FETCH_INTEGRATIONS'
export const EMAIL_MARKETING_FETCH_INTEGRATIONS_STARTED =
  'integrations/emailMarketing/EMAIL_MARKETING_FETCH_INTEGRATIONS_STARTED'
export const EMAIL_MARKETING_FETCH_INTEGRATIONS_SUCCESS =
  'integrations/emailMarketing/EMAIL_MARKETING_FETCH_INTEGRATIONS_SUCCESS'
export const EMAIL_MARKETING_FETCH_INTEGRATIONS_FAILED =
  'integrations/emailMarketing/EMAIL_MARKETING_FETCH_INTEGRATIONS_FAILED'

export const EMAIL_MARKETING_GET_INTEGRATION_STATE =
  'integrations/emailMarketing/EMAIL_MARKETING_GET_INTEGRATION_STATE'
export const EMAIL_MARKETING_GET_INTEGRATION_STATE_STARTED =
  'integrations/emailMarketing/EMAIL_MARKETING_GET_INTEGRATION_STATE_STARTED'
export const EMAIL_MARKETING_GET_INTEGRATION_STATE_SUCCESS =
  'integrations/emailMarketing/EMAIL_MARKETING_GET_INTEGRATION_STATE_SUCCESS'
export const EMAIL_MARKETING_GET_INTEGRATION_STATE_FAILED =
  'integrations/emailMarketing/EMAIL_MARKETING_GET_INTEGRATION_STATE_FAILED'

export const EMAIL_MARKETING_SUBSCRIBE =
  'integrations/emailMarketing/EMAIL_MARKETING_SUBSCRIBE'
export const EMAIL_MARKETING_SUBSCRIBE_STARTED =
  'integrations/emailMarketing/EMAIL_MARKETING_SUBSCRIBE_STARTED'
export const EMAIL_MARKETING_UNSUBSCRIBE =
  'integrations/emailMarketing/EMAIL_MARKETING_UNSUBSCRIBE'
export const EMAIL_MARKETING_UNSUBSCRIBE_STARTED =
  'integrations/emailMarketing/EMAIL_MARKETING_UNSUBSCRIBE_STARTED'

export const EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS =
  'integrations/emailMarketing/EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS'
export const EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS_STARTED =
  'integrations/emailMarketing/EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS_STARTED'
export const EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS_SUCCESS =
  'integrations/emailMarketing/EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS_SUCCESS'
export const EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS_FAILED =
  'integrations/emailMarketing/EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS_FAILED'

export const EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS =
  'integrations/emailMarketing/EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS'
export const EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS_STARTED =
  'integrations/emailMarketing/EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS_STARTED'
export const EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS_SUCCESS =
  'integrations/emailMarketing/EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS_SUCCESS'
export const EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS_FAILED =
  'integrations/emailMarketing/EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS_FAILED'

export const EMAIL_MARKETING_UNINSTALL_INTEGRATION =
  'integrations/emailMarketing/EMAIL_MARKETING_UNINSTALL_INTEGRATION'
export const EMAIL_MARKETING_UNINSTALL_INTEGRATION_STARTED =
  'integrations/emailMarketing/EMAIL_MARKETING_UNINSTALL_INTEGRATION_STARTED'
export const EMAIL_MARKETING_UNINSTALL_INTEGRATION_SUCCESS =
  'integrations/emailMarketing/EMAIL_MARKETING_UNINSTALL_INTEGRATION_SUCCESS'
export const EMAIL_MARKETING_UNINSTALL_INTEGRATION_FAILED =
  'integrations/emailMarketing/EMAIL_MARKETING_UNINSTALL_INTEGRATION_FAILED'

export const EMAIL_MARKETING_INSTALL_INTEGRATION =
  'integrations/emailMarketing/EMAIL_MARKETING_INSTALL_INTEGRATION'
export const EMAIL_MARKETING_INSTALL_INTEGRATION_STARTED =
  'integrations/emailMarketing/EMAIL_MARKETING_INSTALL_INTEGRATION_STARTED'
export const EMAIL_MARKETING_INSTALL_INTEGRATION_SUCCESS =
  'integrations/emailMarketing/EMAIL_MARKETING_INSTALL_INTEGRATION_SUCCESS'
export const EMAIL_MARKETING_INSTALL_INTEGRATION_FAILED =
  'integrations/emailMarketing/EMAIL_MARKETING_INSTALL_INTEGRATION_FAILED'

export const EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION =
  'integrations/emailMarketing/EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION'
export const EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION_STARTED =
  'integrations/emailMarketing/EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION_STARTED'
export const EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION_SUCCESS =
  'integrations/emailMarketing/EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION_SUCCESS'
export const EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION_FAILED =
  'integrations/emailMarketing/EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION_FAILED'
