import { doAttachementRequest } from 'ducks/requests/operations'
import { readAsDataURL } from 'util/file'

import { UPLOAD_CUSTOM_FIELD_FILE } from '../types'

export function doUpdateCustomFieldFileValues(fields, options) {
  return dispatch => {
    return Promise.all(
      fields.map(({ id, key, value: file, valueId }) => {
        return dispatch(
          doAttachementRequest(
            UPLOAD_CUSTOM_FIELD_FILE,
            `/crm/${id}/attachments`,
            [file],
            options
          )
        )
          .then(response => response.json())
          .then(result => {
            const value = result.attachments[0]
            return readAsDataURL(file).then(dataUrl => {
              return {
                id,
                key,
                dataUrl,
                value,
                valueId,
              }
            })
          })
      })
    )
  }
}
