import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { createBasicSelector } from 'util/redux'
import { emptyArr, sortByKeyTypeDesc as byPropDesc } from 'util/arrays'
import { emptyObj } from 'util/objects'
import {
  selectAgentsForNotingCollisionByConversationId,
  selectAgentsForReplyingCollisionByConversationId,
  selectAgentsForTypingReplyCollisionByConversationId,
  selectAgentsForTypingNoteCollisionByConversationId,
} from 'ducks/collisions/selectors'
import selectCurrentConversationId from 'ducks/searches/selectors/selectCurrentConversationId'
import { selectContactByRoomId } from './contacts'
import { isEndUser } from '../utils/users'

export function selectRoomsBase(state) {
  const { chat: { rooms = emptyObj } = emptyObj } = state
  return rooms
}

export const selectRoomsIds = createBasicSelector(
  selectRoomsBase,
  base => base.ids || emptyArr
)

export const selectRoomsById = createBasicSelector(
  selectRoomsBase,
  base => base.byId || emptyObj
)

export const selectRoomsByIdWithContact = createSelector(
  selectRoomsById,
  selectContactByRoomId,
  (byId, contactByRoomId) => {
    return Object.keys(byId).reduce((byIdWithContact, key) => {
      const { lastMessageSender, lastMessageSenderId } = byId[key]
      const contact = contactByRoomId[byId[key].matrixRoomId]
      // eslint-disable-next-line no-param-reassign
      byIdWithContact[key] = {
        ...byId[key],
        contact: contact || emptyObj,
        lastMessageSender:
          isEndUser(lastMessageSenderId) && contact
            ? contact.name
            : lastMessageSender,
      }
      return byIdWithContact
    }, {})
  }
)

export const selectConversationIdFromParams = (_state, conversattionId) =>
  conversattionId

export const selectRoomById = createCachedSelector(
  selectRoomsById,
  selectConversationIdFromParams,
  (roomsById, conversationId) => roomsById[conversationId] || emptyObj
)(selectConversationIdFromParams)

export const selectRooms = createSelector(
  selectRoomsById,
  selectRoomsIds,
  (byId, ids) =>
    ids
      .map(id => byId[id])
      .filter(room => !!room)
      .sort(byPropDesc('lastActivityAt'))
)

export const selectRoomsByMatrixId = createSelector(selectRooms, rooms =>
  rooms.reduce((byMatrixId, room) => {
    const { matrixRoomId } = room
    // eslint-disable-next-line no-param-reassign
    byMatrixId[matrixRoomId] = room
    return byMatrixId
  }, {})
)

export const selectOpenRoomIdsOrderedByLastActivityAtDesc = createSelector(
  selectRooms,
  rooms =>
    rooms.filter(room => room.state === 'open').map(room => room.id) || emptyArr
)

export const selectLoadingRoomIds = createBasicSelector(
  selectRoomsBase,
  base => base.loadingIds
)

export const selectLoadingMatrixRoomIds = createBasicSelector(
  selectRoomsBase,
  base => base.loadingMatrixIds
)

export const selectUpdatingMatrixRoomIds = createBasicSelector(
  selectRoomsBase,
  base => base.updatingMatrixIds
)

export const selectRoomErrors = createBasicSelector(
  selectRoomsBase,
  base => base.errors
)

export const selectRoomIdFromUrl = selectCurrentConversationId

export const selectRoomFromUrl = createBasicSelector(
  selectRoomIdFromUrl,
  selectRoomsById,
  (roomId, rooms) => rooms[roomId] || emptyObj
)

export const selectRoomFromUrlExpiryEvent = createBasicSelector(
  selectRoomFromUrl,
  room => room.expiryEvent
)

export const selectRoomFromUrlBridgeType = createBasicSelector(
  selectRoomFromUrl,
  room => room.bridgeType
)

export const selectRoomFromUrlExpiresAt = createBasicSelector(
  selectRoomFromUrlExpiryEvent,
  // eslint-disable-next-line camelcase
  expiryEvent => expiryEvent?.expires_at
)

export const selectRoomFromUrlIsExpired = createBasicSelector(
  selectRoomFromUrlExpiryEvent,
  expiryEvent => {
    if (!expiryEvent) return false
    return (
      // eslint-disable-next-line camelcase
      expiryEvent.is_expired === true ||
      // eslint-disable-next-line camelcase
      (expiryEvent.expires_at && new Date().getTime() > expiryEvent.expires_at)
    )
  }
)

export const selectRoomFromUrlLoaded = createSelector(
  selectRoomIdFromUrl,
  selectRoomsIds,
  (roomId, roomIds) => !!roomIds.includes(roomId)
)

export const selectRoomFromUrlLoading = createSelector(
  selectRoomIdFromUrl,
  selectLoadingRoomIds,
  (roomId, roomIds) => !!roomIds.includes(roomId)
)

export const selectRoomFromUrlError = createBasicSelector(
  selectRoomIdFromUrl,
  selectRoomErrors,
  (roomId, roomErrors) => roomErrors[roomId]
)

export function selectRoomIdFromProps(_, props) {
  return props.roomId || null
}

const cacheKeyForConversationIdAndExcludeCurrent = (
  _state,
  conversationId,
  excludeCurrentUser = true,
  lastEventOnly = false
) => `${conversationId}-${excludeCurrentUser}-${lastEventOnly}`

export const selectChatIdsForAgentsNotingByConversationId = createCachedSelector(
  selectAgentsForNotingCollisionByConversationId,
  noteAgents => {
    if (noteAgents.length === 0) return emptyArr
    return noteAgents.map(agent => agent.chatId)
  }
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectChatIdsForAgentsReplyingByConversationId = createCachedSelector(
  selectAgentsForReplyingCollisionByConversationId,
  replyingAgents => {
    if (replyingAgents.length === 0) return emptyArr
    return replyingAgents.map(agent => agent.chatId)
  }
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectChatIdsForAgentsTypingReplyByConversationId = createCachedSelector(
  selectAgentsForTypingReplyCollisionByConversationId,
  typingAgents => {
    if (typingAgents.length === 0) return emptyArr
    return typingAgents.map(agent => agent.chatId)
  }
)(cacheKeyForConversationIdAndExcludeCurrent)

export const selectChatIdsForAgentsTypingNoteByConversationId = createCachedSelector(
  selectAgentsForTypingNoteCollisionByConversationId,
  typingAgents => {
    if (typingAgents.length === 0) return emptyArr
    return typingAgents.map(agent => agent.chatId)
  }
)(cacheKeyForConversationIdAndExcludeCurrent)

export function makeSelectRoomById() {
  return createSelector(
    selectRoomsById,
    selectRoomIdFromProps,
    (roomsById, roomId) => {
      return roomsById[roomId] || emptyObj
    }
  )
}

export function makeSelectIsLoadingRoomById() {
  return createSelector(
    selectLoadingRoomIds,
    selectRoomIdFromProps,
    (loadingRoomIds, roomId) => {
      return (loadingRoomIds || []).includes(roomId)
    }
  )
}

export function makeSelectIsLoadedRoomById() {
  return createSelector(
    selectRoomsIds,
    selectRoomIdFromProps,
    (loadedRoomIds, roomId) => {
      return (loadedRoomIds || []).includes(roomId)
    }
  )
}
