import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectRequestByType } from 'ducks/requests/selectors'

import { doFetchCustomProfile } from './operations'
import { selectCustomProfileContentByContactId } from './selectors'

export function useCustomProfile(contactId, mailboxId) {
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state =>
    selectRequestByType(state, 'integrations/customprofile/fetchCustomProfile')
  )
  const isLoading = loading
  const isFailed = !!error
  const doLoad = useCallback(
    ({ hard = false } = {}) => {
      if (!isLoading && contactId)
        dispatch(doFetchCustomProfile(contactId, mailboxId, hard))
    },
    [dispatch, isLoading, contactId, mailboxId]
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(doLoad, [contactId, mailboxId])
  const content = useSelector(state =>
    selectCustomProfileContentByContactId(state, contactId)
  )

  return {
    content,
    doLoad,
    isFailed: isFailed && !isLoading,
    isLoading,
  }
}
