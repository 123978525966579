import { createActionTypeReducer } from 'util/reducers'
import {
  AI_STREAMING_UPDATE,
  FETCH_AI_JOB_CONVERSATION_RESULT_INITIALIZE,
} from 'ducks/ai/types'

// NOTE (jscheel): We only store one at a time
const initialState = {
  draftReplyText: '',
  draftReplyConversationId: null,
  draftReplySources: [],
}

export default createActionTypeReducer(
  {
    [FETCH_AI_JOB_CONVERSATION_RESULT_INITIALIZE]: (
      draftState,
      { payload }
    ) => {
      const { conversationId } = payload
      draftState.draftReplyText = ''
      draftState.draftReplyConversationId = conversationId
      draftState.draftReplySources = []

      return draftState
    },
    [AI_STREAMING_UPDATE]: (draftState, { payload }) => {
      const { conversationId, action, content, sources } = payload
      if (action === 'text-generation') {
        draftState.draftReplyText += content
        draftState.draftReplyText = draftState.draftReplyText.replaceAll(
          /\n/g,
          '<br />'
        )
        draftState.draftReplyConversationId = conversationId
        draftState.draftReplySources = sources
      }

      return draftState
    },
  },
  initialState
)
