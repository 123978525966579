export const FETCH_CUSTOMER_REQUEST =
  'integrations/shopify/FETCH_CUSTOMER_REQUEST'
export const FETCH_CUSTOMER_REQUEST_STARTED =
  'integrations/shopify/FETCH_CUSTOMER_REQUEST_STARTED'
export const FETCH_CUSTOMER_REQUEST_SUCCESS =
  'integrations/shopify/FETCH_CUSTOMER_REQUEST_SUCCESS'
export const FETCH_CUSTOMER_REQUEST_FAILED =
  'integrations/shopify/FETCH_CUSTOMER_REQUEST_FAILED'

export const FETCH_ORDERS_REQUEST = 'integrations/shopify/FETCH_ORDERS_REQUEST'
export const FETCH_ORDERS_REQUEST_STARTED =
  'integrations/shopify/FETCH_ORDERS_REQUEST_STARTED'
export const FETCH_ORDERS_REQUEST_SUCCESS =
  'integrations/shopify/FETCH_ORDERS_REQUEST_SUCCESS'
export const FETCH_ORDERS_REQUEST_FAILED =
  'integrations/shopify/FETCH_ORDERS_REQUEST_SUCCESS'

export const FETCH_ORDER_BY_ID_REQUEST =
  'integrations/shopify/FETCH_ORDER_BY_ID_REQUEST'
export const FETCH_ORDER_BY_ID_REQUEST_STARTED =
  'integrations/shopify/FETCH_ORDER_BY_ID_REQUEST_STARTED'
export const FETCH_ORDER_BY_ID_REQUEST_SUCCESS =
  'integrations/shopify/FETCH_ORDER_BY_ID_REQUEST_SUCCESS'
export const FETCH_ORDER_BY_ID_REQUEST_FAILED =
  'integrations/shopify/FETCH_ORDER_BY_ID_REQUEST_SUCCESS'

export const FETCH_CUSTOMER_EVENTS_REQUEST =
  'integrations/shopify/FETCH_CUSTOMER_EVENTS_REQUEST'
export const FETCH_CUSTOMER_EVENTS_REQUEST_STARTED =
  'integrations/shopify/FETCH_CUSTOMER_EVENTS_REQUEST_STARTED'
export const FETCH_CUSTOMER_EVENTS_REQUEST_SUCCESS =
  'integrations/shopify/FETCH_CUSTOMER_EVENTS_REQUEST_SUCCESS'
export const FETCH_CUSTOMER_EVENTS_REQUEST_FAILED =
  'integrations/shopify/FETCH_CUSTOMER_EVENTS_REQUEST_FAILED'

export const SEARCH_PRODUCTS_REQUEST =
  'integrations/shopify/SEARCH_PRODUCTS_REQUEST'
export const SEARCH_PRODUCTS_REQUEST_STARTED =
  'integrations/shopify/SEARCH_PRODUCTS_REQUEST_STARTED'
export const SEARCH_PRODUCTS_REQUEST_SUCCESS =
  'integrations/shopify/SEARCH_PRODUCTS_REQUEST_SUCCESS'
export const SEARCH_PRODUCTS_REQUEST_FAILED =
  'integrations/shopify/SEARCH_PRODUCTS_REQUEST_FAILED'

export const DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST =
  'integrations/shopify/DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST'
export const DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_STARTED =
  'integrations/shopify/DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_STARTED'
export const DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_SUCCESS =
  'integrations/shopify/DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_SUCCESS'
export const DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_FAILED =
  'integrations/shopify/DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_FAILED'

export const DRAFT_ORDER_CREATE_REQUEST =
  'integrations/shopify/DRAFT_ORDER_CREATE_REQUEST'
export const DRAFT_ORDER_CREATE_REQUEST_STARTED =
  'integrations/shopify/DRAFT_ORDER_CREATE_REQUEST_STARTED'
export const DRAFT_ORDER_CREATE_REQUEST_SUCCESS =
  'integrations/shopify/DRAFT_ORDER_CREATE_REQUEST_SUCCESS'
export const DRAFT_ORDER_CREATE_REQUEST_FAILED =
  'integrations/shopify/DRAFT_ORDER_CREATE_REQUEST_FAILED'

export const DRAFT_ORDER_CALCULATE_REQUEST =
  'integrations/shopify/DRAFT_ORDER_CALCULATE_REQUEST'
export const DRAFT_ORDER_CALCULATE_REQUEST_STARTED =
  'integrations/shopify/DRAFT_ORDER_CALCULATE_REQUEST_STARTED'
export const DRAFT_ORDER_CALCULATE_REQUEST_SUCCESS =
  'integrations/shopify/DRAFT_ORDER_CALCULATE_REQUEST_SUCCESS'
export const DRAFT_ORDER_CALCULATE_REQUEST_FAILED =
  'integrations/shopify/DRAFT_ORDER_CALCULATE_REQUEST_FAILED'

export const DRAFT_ORDER_UPDATE_REQUEST =
  'integrations/shopify/DRAFT_ORDER_UPDATE_REQUEST'
export const DRAFT_ORDER_UPDATE_REQUEST_STARTED =
  'integrations/shopify/DRAFT_ORDER_UPDATE_REQUEST_STARTED'
export const DRAFT_ORDER_UPDATE_REQUEST_SUCCESS =
  'integrations/shopify/DRAFT_ORDER_UPDATE_REQUEST_SUCCESS'
export const DRAFT_ORDER_UPDATE_REQUEST_FAILED =
  'integrations/shopify/DRAFT_ORDER_UPDATE_REQUEST_FAILED'

export const DRAFT_ORDER_CLEAR_PRODUCT_LINE_ITEMS_LOCALLY =
  'integrations/shopify/DRAFT_ORDER_CLEAR_PRODUCT_LINE_ITEMS_LOCALLY'

export const FETCH_DRAFT_ORDER_BY_ID_REQUEST =
  'integrations/shopify/FETCH_DRAFT_ORDER_BY_ID_REQUEST'
export const FETCH_DRAFT_ORDER_BY_ID_REQUEST_STARTED =
  'integrations/shopify/FETCH_DRAFT_ORDER_BY_ID_REQUEST_STARTED'
export const FETCH_DRAFT_ORDER_BY_ID_REQUEST_SUCCESS =
  'integrations/shopify/FETCH_DRAFT_ORDER_BY_ID_REQUEST_SUCCESS'
export const FETCH_DRAFT_ORDER_BY_ID_REQUEST_FAILED =
  'integrations/shopify/FETCH_DRAFT_ORDER_BY_ID_REQUEST_SUCCESS'

export const FETCH_DRAFT_ORDERS_REQUEST =
  'integrations/shopify/FETCH_DRAFT_ORDERS_REQUEST'
export const FETCH_DRAFT_ORDERS_REQUEST_STARTED =
  'integrations/shopify/FETCH_DRAFT_ORDERS_REQUEST_STARTED'
export const FETCH_DRAFT_ORDERS_REQUEST_SUCCESS =
  'integrations/shopify/FETCH_DRAFT_ORDERS_REQUEST_SUCCESS'
export const FETCH_DRAFT_ORDERS_REQUEST_FAILED =
  'integrations/shopify/FETCH_DRAFT_ORDERS_REQUEST_SUCCESS'

export const DRAFT_ORDER_COMPLETE_REQUEST =
  'integrations/shopify/DRAFT_ORDER_COMPLETE_REQUEST'
export const DRAFT_ORDER_COMPLETE_REQUEST_STARTED =
  'integrations/shopify/DRAFT_ORDER_COMPLETE_REQUEST_STARTED'
export const DRAFT_ORDER_COMPLETE_REQUEST_SUCCESS =
  'integrations/shopify/DRAFT_ORDER_COMPLETE_REQUEST_SUCCESS'
export const DRAFT_ORDER_COMPLETE_REQUEST_FAILED =
  'integrations/shopify/DRAFT_ORDER_COMPLETE_REQUEST_FAILED'

export const DRAFT_ORDER_DELETE_REQUEST =
  'integrations/shopify/DRAFT_ORDER_DELETE_REQUEST'
export const DRAFT_ORDER_DELETE_REQUEST_STARTED =
  'integrations/shopify/DRAFT_ORDER_DELETE_REQUEST_STARTED'
export const DRAFT_ORDER_DELETE_REQUEST_SUCCESS =
  'integrations/shopify/DRAFT_ORDER_DELETE_REQUEST_SUCCESS'
export const DRAFT_ORDER_DELETE_REQUEST_FAILED =
  'integrations/shopify/DRAFT_ORDER_DELETE_REQUEST_FAILED'

export const ORDER_CANCEL_REQUEST = 'integrations/shopify/ORDER_CANCEL_REQUEST'
export const ORDER_CANCEL_REQUEST_STARTED =
  'integrations/shopify/ORDER_CANCEL_REQUEST_STARTED'
export const ORDER_CANCEL_REQUEST_SUCCESS =
  'integrations/shopify/ORDER_CANCEL_REQUEST_SUCCESS'
export const ORDER_CANCEL_REQUEST_FAILED =
  'integrations/shopify/ORDER_CANCEL_REQUEST_FAILED'

export const ORDER_STATUS_URL_REQUEST =
  'integrations/shopify/ORDER_STATUS_URL_REQUEST'
export const ORDER_STATUS_URL_REQUEST_STARTED =
  'integrations/shopify/ORDER_STATUS_URL_REQUEST_STARTED'
export const ORDER_STATUS_URL_REQUEST_SUCCESS =
  'integrations/shopify/ORDER_STATUS_URL_REQUEST_SUCCESS'
export const ORDER_STATUS_URL_REQUEST_FAILED =
  'integrations/shopify/ORDER_STATUS_URL_REQUEST_FAILED'

export const INVOICE_PREVIEW_REQUEST =
  'integrations/shopify/INVOICE_PREVIEW_REQUEST'
export const INVOICE_PREVIEW_REQUEST_STARTED =
  'integrations/shopify/INVOICE_PREVIEW_REQUEST_STARTED'
export const INVOICE_PREVIEW_REQUEST_SUCCESS =
  'integrations/shopify/INVOICE_PREVIEW_REQUEST_SUCCESS'
export const INVOICE_PREVIEW_REQUEST_FAILED =
  'integrations/shopify/INVOICE_PREVIEW_REQUEST_FAILED'

export const INVOICE_SEND_REQUEST = 'integrations/shopify/INVOICE_SEND_REQUEST'
export const INVOICE_SEND_REQUEST_STARTED =
  'integrations/shopify/INVOICE_SEND_REQUEST_STARTED'
export const INVOICE_SEND_REQUEST_SUCCESS =
  'integrations/shopify/INVOICE_SEND_REQUEST_SUCCESS'
export const INVOICE_SEND_REQUEST_FAILED =
  'integrations/shopify/INVOICE_SEND_REQUEST_FAILED'

export const ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST =
  'integrations/shopify/ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST'
export const ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST_STARTED =
  'integrations/shopify/ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST_STARTED'
export const ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST_SUCCESS =
  'integrations/shopify/ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST_SUCCESS'
export const ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST_FAILED =
  'integrations/shopify/ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST_FAILED'

export const ORDER_REFUND_REQUEST = 'integrations/shopify/ORDER_REFUND_REQUEST'
export const ORDER_REFUND_REQUEST_STARTED =
  'integrations/shopify/ORDER_REFUND_REQUEST_STARTED'
export const ORDER_REFUND_REQUEST_SUCCESS =
  'integrations/shopify/ORDER_REFUND_REQUEST_SUCCESS'
export const ORDER_REFUND_REQUEST_FAILED =
  'integrations/shopify/ORDER_REFUND_REQUEST_FAILED'

export const FETCH_SHOP_INFO_REQUEST =
  'integrations/shopify/FETCH_SHOP_INFO_REQUEST'
export const FETCH_SHOP_INFO_REQUEST_STARTED =
  'integrations/shopify/FETCH_SHOP_INFO_REQUEST_STARTED'
export const FETCH_SHOP_INFO_REQUEST_SUCCESS =
  'integrations/shopify/FETCH_SHOP_INFO_REQUEST_SUCCESS'
export const FETCH_SHOP_INFO_REQUEST_FAILED =
  'integrations/shopify/FETCH_SHOP_INFO_REQUEST_FAILED'

export const SHOPIFY_EVENT_CREATE_REQUEST =
  'integrations/shopify/SHOPIFY_EVENT_CREATE_REQUEST'
export const SHOPIFY_EVENT_CREATE_REQUEST_STARTED =
  'integrations/shopify/SHOPIFY_EVENT_CREATE_REQUEST_STARTED'
export const SHOPIFY_EVENT_CREATE_REQUEST_SUCCESS =
  'integrations/shopify/SHOPIFY_EVENT_CREATE_REQUEST_SUCCESS'
export const SHOPIFY_EVENT_CREATE_REQUEST_FAILED =
  'integrations/shopify/SHOPIFY_EVENT_CREATE_REQUEST_FAILED'

export const SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST =
  'integrations/shopify/SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST'
export const SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_STARTED =
  'integrations/shopify/SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_STARTED'
export const SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_SUCCESS =
  'integrations/shopify/SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_SUCCESS'
export const SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_FAILED =
  'integrations/shopify/SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST_FAILED'

export const FETCH_CUSTOMER_ID_REQUEST =
  'integrations/shopify/FETCH_CUSTOMER_ID_REQUEST'
export const FETCH_CUSTOMER_ID_REQUEST_STARTED =
  'integrations/shopify/FETCH_CUSTOMER_ID_REQUEST_STARTED'
export const FETCH_CUSTOMER_ID_REQUEST_SUCCESS =
  'integrations/shopify/FETCH_CUSTOMER_ID_REQUEST_SUCCESS'
export const FETCH_CUSTOMER_ID_REQUEST_FAILED =
  'integrations/shopify/FETCH_CUSTOMER_ID_REQUEST_FAILED'

export const ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST =
  'integrations/shopify/ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST'
export const ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST_STARTED =
  'integrations/shopify/ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST_STARTED'
export const ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST_SUCCESS =
  'integrations/shopify/ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST_SUCCESS'
export const ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST_FAILED =
  'integrations/shopify/ORDER_CANCEL_SUGGESTED_REFUND_CALCULATE_REQUEST_FAILED'
