import { doLinkResource } from 'actions/userIntegrations'

import {
  selectJiraIssuesById,
  selectJiraSites,
} from 'ducks/integrations/jiraShared'

export default function doUnlinkJiraCloudIssue(id) {
  return (dispatch, getState) => {
    const state = getState()
    const issues = selectJiraIssuesById(state, { source: 'jiraCloud' })
    const sites = selectJiraSites(state, { source: 'jiraCloud' })
    const [siteName, issueId] = id.split(':')
    const issue = issues[issueId]
    if (!issue) return Promise.reject('Cannot find Jira issue')
    const {
      fields: { summary },
      key,
    } = issue
    const { url: siteUrl } = sites.find(({ name }) => name === siteName)
    const url = `${siteUrl}/browse/${key}`

    return dispatch(
      doLinkResource({
        id,
        provider: 'jira_cloud',
        removed: true,
        title: `[${key}] ${summary}`,
        url,
      })
    )
  }
}
