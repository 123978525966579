import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { createBasicSelector } from 'util/redux'

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.recharge || emptyObj

export const selectCharges = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.charges
)

export const selectChargesBySubscriptionId = createBasicSelector(
  selectCharges,
  subscriptions => subscriptions.bySubscriptionId || emptyObj
)

export const selectChargesIsLoading = createCachedSelector(
  selectChargesBySubscriptionId,
  charges => charges?.isLoading
)((state, subscriptionId) => subscriptionId || 'unknown')

export const selectChargesForSubscriptionId = createCachedSelector(
  selectChargesBySubscriptionId,
  (state, subscriptionId) => subscriptionId,
  (bySubscriptionId, subscriptionId) =>
    bySubscriptionId[subscriptionId] || emptyObj
)((state, subscriptionId) => subscriptionId || 'unknown')

export const selectChargesForStatus = createCachedSelector(
  (state, { subscriptionId }) =>
    selectChargesForSubscriptionId(state, subscriptionId),
  (state, { status }) => status,
  (bySubscriptionId, status) => {
    return bySubscriptionId[status] || emptyArr
  }
)((state, { subscriptionId }) => subscriptionId || 'unknown')

export const selectChargeForId = createCachedSelector(
  (state, { subscriptionId }) =>
    selectChargesForSubscriptionId(state, subscriptionId),
  (state, { chargeId }) => chargeId,
  (bySubscriptionId, chargeId) => {
    const charges = bySubscriptionId.charges || emptyArr
    return charges.find(charge => charge.id === parseInt(chargeId, 10))
  }
)((state, { subscriptionId }) => subscriptionId || 'unknown')
