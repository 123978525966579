import { oauthTokenSelector } from 'selectors/app'
import { selectPropertiesByType } from 'ducks/integrations/salesforce/selectors'
import config from 'config'

import * as api from '../api'
import {
  FETCH_BATCH_PROPERTIES_REQUEST,
  FETCH_BATCH_PROPERTIES_SUCCESS,
  FETCH_BATCH_PROPERTIES_FAILURE,
} from '../types'

export default function doFetchBatchProperties(types) {
  return (dispatch, getState) => {
    const state = getState()

    const keys = types.filter(type => !selectPropertiesByType(state, type))
    if (keys.length === 0) return Promise.resolve()

    dispatch({
      type: FETCH_BATCH_PROPERTIES_REQUEST,
      data: {
        types,
      },
    })
    const token = oauthTokenSelector(state)
    return api
      .getBatchProperties(token, keys)
      .then(payload => {
        dispatch({
          type: FETCH_BATCH_PROPERTIES_SUCCESS,
          data: {
            schemas: payload.response,
          },
        })
        return payload
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        if (config.isDevelopment) console.error(error)
        return dispatch({
          type: FETCH_BATCH_PROPERTIES_FAILURE,
          data: {
            error,
          },
        })
      })
  }
}
