import { oauthTokenSelector } from 'selectors/app'
import { groupBy } from 'util/arrays'

import selectJiraSiteIdForSiteName from 'ducks/integrations/jiraShared/selectors/selectJiraSiteIdForSiteName'

import integrationApi from '../../api'
import {
  FETCH_ISSUES_ERROR,
  FETCH_ISSUES_REQUEST,
  FETCH_ISSUES_SUCCESS,
} from '../types'

export default function makeDoFetchJiraIssues(prefix, getPath) {
  return function doFetchJiraIssues(issueIds) {
    return (dispatch, getState) => {
      if (!issueIds) return null
      const state = getState()
      const token = oauthTokenSelector(state)
      const structuredIssueIds = issueIds
        .map(id => {
          const [siteName, issueId] = id.split(':')
          if (prefix === 'integrations/jira_server')
            return { issueId: siteName, siteId: 'jira_server' }
          const siteId = selectJiraSiteIdForSiteName(state, {
            siteName,
            source:
              prefix === 'integrations/jira_server'
                ? 'jiraServer'
                : 'jiraCloud',
          })
          if (!siteId) return null
          return { siteId, siteName, issueId }
        })
        .filter(x => !!x)
      const idsGroupedBySiteId = groupBy(
        structuredIssueIds,
        ({ siteId }) => siteId
      )

      Object.keys(idsGroupedBySiteId).forEach(siteId => {
        if (!siteId) return null
        const issueIdsForCloud = idsGroupedBySiteId[siteId]
          .map(({ issueId }) => issueId)
          .filter(issueId => !!issueId)
        const meta = {
          ids: issueIdsForCloud,
          siteId,
          siteName:
            prefix === 'integrations/jira_cloud' &&
            idsGroupedBySiteId[siteId][0].siteName,
        }
        dispatch({
          type: `${prefix}/${FETCH_ISSUES_REQUEST}`,
          meta,
        })

        return integrationApi
          .post(
            token,
            getPath(siteId),
            {},
            JSON.stringify({
              jql: `id IN (${issueIdsForCloud.join(',')})`,
              fields: [
                'key',
                'summary',
                'project',
                'components',
                'status',
                'assignee',
                'created',
                'duedate',
                'labels',
                'priority',
                'resolution',
                'issuetype',
                'updated',
                'site',
              ],
            })
          )
          .then(({ success, json } = {}) => {
            const { issues } = json || {}

            if (success) {
              dispatch({
                meta,
                payload: { issues },
                type: `${prefix}/${FETCH_ISSUES_SUCCESS}`,
              })
            } else {
              dispatch({
                error: true,
                meta,
                payload: json,
                type: `${prefix}/${FETCH_ISSUES_ERROR}`,
              })
            }
          })
          .catch(error => {
            dispatch({
              error: true,
              meta,
              payload: error,
              type: `${prefix}/${FETCH_ISSUES_ERROR}`,
            })
          })
      })
      return null
    }
  }
}
