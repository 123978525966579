import { combineReducers } from 'redux'
import rooms from './rooms'
import presence from './presence'
import contacts from './contacts'
import ready from './ready'
import notifications from './notifications'
import botState from './botState'

export default combineReducers({
  rooms,
  presence,
  contacts,
  ready,
  notifications,
  botState,
})
