import { isNotFunction } from 'util/functions'

export function createEntityMiddleware() {
  return ({ getState }) => next => action => {
    if (action.entities) {
      // eslint-disable-next-line no-param-reassign
      action.entities.state = getState().entities
    }
    if (action.type === 'BATCH_ACTIONS') {
      action.actions.filter(isNotFunction).forEach(subAction => {
        if (subAction.entities) {
          // eslint-disable-next-line no-param-reassign
          subAction.entities.state = getState().entities
        }
      })
    }
    return next(action)
  }
}
