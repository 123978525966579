import { uniq } from 'util/arrays'

import doFetchJiraServerIssues from './doFetchJiraServerIssues'

export default function doFetchJiraServerResourceFromActions(actions) {
  const issueIds = actions.map(
    ({
      change: {
        action: {
          issue: { id },
        },
        external_id: externalId,
      } = {},
    }) => {
      const siteName = externalId.match(/^[^:]+/)[0]
      return `${siteName}:${id}`
    }
  )
  const uniqIssueIds = uniq(issueIds)

  return doFetchJiraServerIssues(uniqIssueIds)
}
