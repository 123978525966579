import { createSelector } from 'reselect'
import selectCurrentIntegrationId from './selectCurrentIntegrationId'
import selectIntegrationsById from './selectIntegrationsById'
import selectCurrentProvider from './selectCurrentProvider'
import selectIntegrationCredentialsByProvider from './selectIntegrationCredentialsByProvider'

export default createSelector(
  selectIntegrationsById,
  selectCurrentIntegrationId,
  selectIntegrationCredentialsByProvider,
  selectCurrentProvider,
  (byId, id, byProvider, provider) => {
    if (id) return byId[id]

    if (provider) {
      const forProvider = byProvider[provider.toUpperCase()]
      if (forProvider) return forProvider[0]
    }

    return undefined
  }
)
