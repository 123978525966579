export const UPDATE_DRAFT = 'drafts/UPDATE'
export const CREATE_DRAFT = 'drafts/CREATE'
export const DELETE_DRAFT = 'drafts/DELETE'
export const STORED_DRAFT = 'drafts/STORED'
export const FAILED_DRAFT = 'drafts/FAILED'
export const FAILED_SEND = 'drafts/FAILED_SEND'

export const DRAFT_RECIPIENT_SYNC_STARTED = 'drafts/RECIPIENT_SYNC_STARTED'
export const DRAFT_RECIPIENT_SYNC_FINISHED = 'drafts/RECIPIENT_SYNC_FINISHED'

export const FETCH_DRAFTS = 'drafts/FETCH_DRAFTS'
export const FETCH_DRAFTS_STARTED = 'drafts/FETCH_DRAFTS_STARTED'
export const FETCH_DRAFTS_SUCCESS = 'drafts/FETCH_DRAFTS_SUCCESS'
export const FETCH_DRAFTS_FAILED = 'drafts/FETCH_DRAFTS_FAILED'

// new compose drafts are saved with ticket id: 0
export const DRAFT_NEW_CONVERSATION_CONVERSATION_ID = 0

export const REQUEST_DRAFT_TYPES = {
  note: 'NOTE',
  reply: 'REPLY',
}
