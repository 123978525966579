import { pick } from 'util/objects'
import { isString } from 'util/strings'

export const extractDrawerPropsById = (drawerId, queryParams) => {
  const drawerParamKeys = Object.keys(queryParams).filter(k => {
    const [keyDrawer, keyDrawerId] = (k || '').split('-')
    return keyDrawer.startsWith('drawer') && keyDrawerId === drawerId
  })

  return pick(drawerParamKeys, queryParams, true)
}

export const getCurrentDrawerId = queryParams => {
  const drawerTypeKeys = Object.keys(queryParams)
    .map(k => {
      const [keyDrawer, keyDrawerId] = (k || '').split('-')
      return [keyDrawer, keyDrawerId]
    })
    .filter(([keyDrawer]) => keyDrawer === 'drawer')

  if (drawerTypeKeys.length > 0) {
    return drawerTypeKeys[0][1]
  }
  return null
}

export const stripDrawerIdsFromProps = rawDrawerProps => {
  return Object.keys(rawDrawerProps).reduce((cleanProps, rawKey) => {
    const [drawerKey, drawerId] = rawKey.split('-')
    if (!cleanProps.drawerId) {
      // eslint-disable-next-line no-param-reassign
      cleanProps.drawerId = drawerId
    }
    // eslint-disable-next-line no-param-reassign
    cleanProps[drawerKey] = rawDrawerProps[rawKey]
    return cleanProps
  }, {})
}

export const buildDrawerQueryParam = (drawerId, paramKey, paramValue) => {
  return {
    [`${paramKey}-${drawerId}`]: paramValue,
  }
}

export const isResourceId = resourceId => {
  return resourceId && (isString(resourceId) || Number.isInteger(resourceId))
}
