import { HIDE_MODAL } from 'constants/action_types'
import {
  FETCH_CANNED_REPLY_CATEGORIES_STARTED,
  FETCH_CANNED_REPLY_CATEGORIES_SUCCESS,
  FETCH_CANNED_REPLY_CATEGORIES_FAILED,
  TOGGLE_CANNED_REPLY_COLLAPSED_STATE,
  DELETE_CANNED_REPLIES_SUCCESS,
} from '../types'

const reducers = {}
export const defaultState = {
  isLoading: false,
  isLoaded: false,
  hasError: false,
  expandedCategoryIds: [],
  data: [],
}

reducers[HIDE_MODAL] = state => {
  return {
    ...state,
    ...defaultState,
  }
}

reducers[FETCH_CANNED_REPLY_CATEGORIES_STARTED] = (state, action) => {
  const { meta: { requestId = null } = {} } = action
  return {
    ...state,
    isLoading: true,
    categoryBatchId: requestId,
  }
}

reducers[FETCH_CANNED_REPLY_CATEGORIES_SUCCESS] = (state, action) => {
  const {
    meta: { requestId = null } = {},
    payload: { commentTemplateCategories },
  } = action

  if (requestId !== state.categoryBatchId) {
    return state
  }

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    hasError: false,
    expandedCategoryIds: [],
    // commentTemplateCategories is undefined if fetch all categories in Canned reply edit
    data: commentTemplateCategories || state.data,
  }
}

reducers[FETCH_CANNED_REPLY_CATEGORIES_FAILED] = (state, action) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
    error: action.payload.error,
  }
}

reducers[TOGGLE_CANNED_REPLY_COLLAPSED_STATE] = (state, action) => {
  const { categoryIds } = action.payload
  const expandedCategoryIds = [...state.expandedCategoryIds]

  if (categoryIds) {
    categoryIds.forEach(categoryId => {
      if (expandedCategoryIds.includes(categoryId)) {
        expandedCategoryIds.splice(expandedCategoryIds.indexOf(categoryId), 1)
      } else {
        expandedCategoryIds.push(categoryId)
      }
    })
  }

  return {
    ...state,
    expandedCategoryIds,
  }
}

reducers[DELETE_CANNED_REPLIES_SUCCESS] = state => {
  return {
    ...state,
    ...defaultState,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
