import { createSelector } from 'reselect'

import { selectAccountPreferenceCrmSidebar } from 'selectors/app/selectAccountPreferences'

export { default as selectCurrentSearchTerm } from './selectCurrentSearchTerm'
export { default as selectCurrentSearchTerms } from './selectCurrentSearchTerms'

export const selectNewSideBarEnabled = createSelector(
  selectAccountPreferenceCrmSidebar,
  preference => {
    return preference
  }
)
