import { CHANGE_CONVERSATION_CONTACT_SUCCESS } from '../../types'

const doClearConversationContact = ticketId => dispatch => {
  return dispatch({
    type: CHANGE_CONVERSATION_CONTACT_SUCCESS,
    payload: {
      conversationChangeContact: {
        conversation: {
          number: ticketId,
          contact: { id: undefined },
        },
      },
    },
  })
}

export default doClearConversationContact
