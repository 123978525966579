import { createSelector } from 'reselect'
import { areArraysEqual } from 'util/arrays'
import { selectFuturePricingIds } from './selectFuturePricingIds'
import { selectPlans } from './selectPlans'

export const selectHasPendingDowngrade = createSelector(
  selectFuturePricingIds,
  selectPlans,
  (futurePricingIds, plans) => {
    if (futurePricingIds.length === 0) return false

    const currentPricingIds = plans.map(plan => plan.pricing.id)

    // Kevin R (2023-12-14)
    // we're making an assumption here that if there is futurePricingIds and they
    // are different then it will be a downgrade. This is because in our current system
    // upgrades are always immediate. This could change in the future
    return !areArraysEqual(currentPricingIds, futurePricingIds)
  }
)
