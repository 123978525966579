import { SORT_COMPANIES } from '../types'

const defaultState = {
  field: 'CREATED_AT',
  direction: 'ASC',
}

export default function reduceOrderBy(state = defaultState, { type, payload }) {
  if (type === SORT_COMPANIES) return payload
  return state
}
