export const SAVE_CHANNEL_DRAFT = 'channels/SAVE_CHANNEL_DRAFT'
export const CLEAR_CHANNEL_DRAFT = 'channels/CLEAR_CHANNEL_DRAFT'
export const SYNC_CLEAR_CHANNEL_DRAFT = 'channels/SYNC_CLEAR_CHANNEL_DRAFT'
export const SYNC_CHANNEL_DRAFT = 'channels/SYNC_CHANNEL_DRAFT'

// similar to FETCH_MAILBOXES but only updates channels entity store
export const FETCH_CHANNELS = 'channels/FETCH_CHANNELS'
export const FETCH_CHANNELS_STARTED = 'channels/FETCH_CHANNELS_STARTED'
export const FETCH_CHANNELS_SUCCESS = 'channels/FETCH_CHANNELS_SUCCESS'
export const FETCH_CHANNELS_FAILED = 'channels/FETCH_CHANNELS_FAILED'
