import createCachedSelector from 're-reselect'

import { any } from 'util/arrays'

import selectGitHubLoading from './selectGitHubLoading'

export default createCachedSelector(
  selectGitHubLoading,
  (state, { loadingId }) => loadingId,
  (loading, loadingId) => {
    if (!loadingId) any(Object.values(loading))
    return loading[loadingId]
  }
)((state, { loadingId }) => loadingId)
