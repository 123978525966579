export const BASIC_TEMPLATE_FIELDS = `
id
name
category {
  id
  name
}
creator {
  id
}
lastUsedAt

snippet
subject
`

export const CATEGORY_TEMPLATE_FIELDS = `
id
name
`

export const getAllQuery = () => `
query CannedRepliesQuery(
  $filter: CannedReplyFilter,
  $cursor: String,
  $size: Int,
  $orderBy: CannedReplyOrder
) {
  cannedReplies(filter: $filter, after: $cursor, first: $size, orderBy: $orderBy) {
    totalCount
    totalPageCount
    nodes {
      ${BASIC_TEMPLATE_FIELDS}
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
`

export const getAllCategoriesQuery = () => `
query CannedReplyCategoriesQuery(
  $cursor: String,
  $size: Int,
  $orderBy: CategoryOrder
) {
  cannedReplyCategories(after: $cursor, first: $size, orderBy: $orderBy) {
    nodes {
      ${CATEGORY_TEMPLATE_FIELDS}
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
`
