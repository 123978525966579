import config from 'config'
import { graphQLAPI } from 'api/graphql/api'

export const getData = res => res.json.data
export const getResult = key => data => data[key]

const graphql = integrationId =>
  graphQLAPI(
    `${config.integrationsApiUrl}/proxy/shopify/admin/api/${
      config.shopify.graphQLApiVersion
    }/graphql.json?gid=${integrationId}`
  )

export default graphql
