import React, { useCallback } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import { DRAWER_TYPE_CANNED_REPLIES_UPDATE } from 'ducks/drawers/types'
import emailTemplates from 'assets/email_templates.png'
import { useDrawer } from 'ducks/drawers/hooks'

import { styles } from './styles'

const Empty = ({ className, onVisibleChange }) => {
  const { openDrawer: openCreateDrawer } = useDrawer({
    type: DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  })

  const handleOpenCreateDrawer = useCallback(
    () => {
      openCreateDrawer('new')
      onVisibleChange(false)
    },
    [openCreateDrawer, onVisibleChange]
  )

  return (
    <div css={styles.container} className={className}>
      <div css={styles.imgWrapper}>
        <img src={emailTemplates} alt="empty templates" />
      </div>
      <h3 className="grui" css={[heading.styles.h4, styles.heading]}>
        Reply 45% faster with {app.t('Canned_Replies')}!
      </h3>
      <p className="grui" css={styles.content}>
        Create a searchable library of answers to frequently asked questions,
        then insert them into replies in two clicks.
      </p>
      <Button
        size="xBig"
        type="info"
        css={styles.btn}
        onClick={handleOpenCreateDrawer}
      >
        Create {app.t('canned_reply_with_article')}
      </Button>
    </div>
  )
}

export default Empty
