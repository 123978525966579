import { strftime } from 'util/date'
import { platformsData } from './contants'

export const getValueForCustomFieldFromCustomerObject = (
  keyPrefix,
  customer,
  key
) => {
  if (key === `${keyPrefix}_external_customer_id`) {
    return customer.external_customer_id.ecommerce
  }

  const baseKeys = {
    [`${keyPrefix}_id`]: 'id',
    [`${keyPrefix}_first_name`]: 'first_name',
    [`${keyPrefix}_last_name`]: 'last_name',
    [`${keyPrefix}_first_charge_processed_at`]: 'first_charge_processed_at',
    [`${keyPrefix}_number_active_subscriptions`]: 'subscriptions_active_count',
    [`${keyPrefix}_number_subscriptions`]: 'subscriptions_total_count',
    [`${keyPrefix}_created_at`]: 'created_at',
    [`${keyPrefix}_updated_at`]: 'updated_at',
    [`${keyPrefix}_has_card_error_in_dunning`]: 'has_payment_method_in_dunning',
    [`${keyPrefix}_has_valid_payment_method`]: 'has_valid_payment_method',
    [`${keyPrefix}_tax_exempt`]: 'tax_exempt',
  }

  return customer[baseKeys[key]]
}

export const getCustomFieldKeys = keyPrefix => [
  ['id', `${keyPrefix}_id`],
  ['externalCustomerId', `${keyPrefix}_external_customer_id`],
  ['firstName', `${keyPrefix}_first_name`],
  ['lastName', `${keyPrefix}_last_name`],
  ['firstChargeProcessedAt', `${keyPrefix}_first_charge_processed_at`],
  ['numberActiveSubscriptions', `${keyPrefix}_number_active_subscriptions`],
  ['numberSubscriptions', `${keyPrefix}_number_subscriptions`],
  ['createdAt', `${keyPrefix}_created_at`],
  ['updatedAt', `${keyPrefix}_updated_at`],
  ['hasCardErrorInDunning', `${keyPrefix}_has_card_error_in_dunning`],
  ['hasValidPaymentMethod', `${keyPrefix}_has_valid_payment_method`],
  ['taxExempt', `${keyPrefix}_tax_exempt`],
]

export const formatDateForCurrentYear = (date, currentYearFormat, format) => {
  if (!date) {
    return null
  }

  const parsed = new Date(date)

  if (parsed.getFullYear() === new Date().getFullYear()) {
    return strftime(currentYearFormat, parsed)
  }

  return strftime(format, parsed)
}

export const formatAmountWithCurrency = (amount, currency) => {
  if (amount === undefined) {
    return null
  }

  if (currency === 'USD') {
    return `$${amount}`
  }

  return amount
}

export const getRechargeMerchantUrl = (fullStoreDomain, path) => {
  const platform = fullStoreDomain.includes('myshopify')
    ? 'shopify'
    : 'bigcommerce'
  const storeDomain = fullStoreDomain.replace(
    platformsData.domains[platform],
    ''
  )
  const suffix = platformsData.suffix[platform]

  return `https://${storeDomain}-${suffix}.admin.rechargeapps.com/${path}`
}
