import createCachedSelector from 're-reselect'

import selectCurrentTicketLinkedIntegrationsResources from 'ducks/integrations/selectors/selectCurrentTicketLinkedIntegrationsResources'
import { uniqBy } from 'util/arrays'

import selectJiraSitesById from './selectJiraSitesById'
import selectJiraIssuesById from './selectJiraIssuesById'

export default createCachedSelector(
  selectCurrentTicketLinkedIntegrationsResources,
  selectJiraIssuesById,
  selectJiraSitesById,
  (state, { provider }) => provider,
  (linkedResources, issues, sites, propsProvider) => {
    if (!linkedResources) return linkedResources
    return uniqBy(
      [...linkedResources].reverse(),
      ({ externalId }) => externalId
    )
      .reverse()
      .filter(x => !!x)
      .filter(
        ({ provider, removed }) =>
          provider === propsProvider && removed !== true
      )
      .map(linkedResource => {
        const [cloudId, issueId] = linkedResource.externalId.split(':')
        const issue =
          issues[propsProvider === 'jira_server' ? cloudId : issueId]
        return {
          ...linkedResource,
          cloudId,
          site: sites[cloudId],
          ...issue,
        }
      })
  }
)((state, { provider }) => provider)
