import {
  SET_EDITING,
  SET_CHANGING,
  CHANGING_CHANGE_TO_SEARCHING,
  CHANGING_CHANGE_TO_CREATING,
  CHANGING_SEARCH_CUSTOMER_REQUEST,
  CHANGING_SEARCH_CUSTOMER_FAILURE,
  CHANGING_SEARCH_CUSTOMER_SUCCESS,
  CHANGING_ADD_NEW_CUSTOMER_REQUEST,
  CHANGING_SELECT_CUSTOMER_REQUEST,
  CHANGING_SELECT_CUSTOMER_SUCCESS,
  CHANGING_SELECT_CUSTOMER_FAILURE,
} from './types'

const defaultState = {
  isEditing: false,
  isChanging: false,
  changing: {
    search: {
      isLoading: false,
      isError: false,
      term: '',
      matchingCustomers: [],
    },
    create: {
      isLoading: false,
      isError: false,
    },
    isSearching: true,
    isCreating: false,
  },
}

export default function customer(state = defaultState, { type, payload }) {
  if (type === SET_EDITING) {
    const { isEditing } = payload
    return {
      ...state,
      isEditing,
    }
  }

  if (type === SET_CHANGING) {
    const { isChanging } = payload
    return {
      ...state,
      changing: defaultState.changing,
      isChanging,
    }
  }

  if (type === CHANGING_CHANGE_TO_SEARCHING) {
    return {
      ...state,
      changing: {
        ...state.changing,
        isSearching: true,
        isCreating: false,
      },
    }
  }

  if (type === CHANGING_CHANGE_TO_CREATING) {
    return {
      ...state,
      changing: {
        ...state.changing,
        isSearching: false,
        isCreating: true,
        create: defaultState.changing.create,
      },
    }
  }
  if (type === CHANGING_SELECT_CUSTOMER_REQUEST) {
    return {
      ...state,
      changing: {
        ...state.changing,
        search: { ...state.changing.search, isLoading: true, isError: false },
      },
    }
  }
  if (type === CHANGING_SELECT_CUSTOMER_SUCCESS) {
    return {
      ...state,
      changing: {
        ...state.changing,
        search: { ...state.changing.search, isLoading: false },
      },
    }
  }
  if (type === CHANGING_SELECT_CUSTOMER_FAILURE) {
    return {
      ...state,
      changing: {
        ...state.changing,
        search: { ...state.changing.search, isLoading: false, isError: true },
      },
    }
  }
  if (type === CHANGING_SEARCH_CUSTOMER_REQUEST) {
    const { term } = payload
    return {
      ...state,
      changing: {
        ...state.changing,
        search: {
          ...state.changing.search,
          term,
          isLoading: true,
          isError: false,
        },
      },
    }
  }
  if (type === CHANGING_SEARCH_CUSTOMER_SUCCESS) {
    const { results: matchingCustomers } = payload
    return {
      ...state,
      changing: {
        ...state.changing,
        search: {
          ...state.changing.search,
          matchingCustomers,
          isLoading: false,
        },
      },
    }
  }
  if (type === CHANGING_SEARCH_CUSTOMER_FAILURE) {
    return {
      ...state,
      changing: {
        ...state.changing,
        search: {
          ...state.changing.search,
          matchingCustomers: [],
          isLoading: false,
          isError: true,
        },
      },
    }
  }
  if (type === CHANGING_ADD_NEW_CUSTOMER_REQUEST) {
    return {
      ...state,
      changing: {
        ...state.changing,
        create: { ...state.changing.create, isLoading: true, isError: false },
      },
    }
  }

  return state
}
