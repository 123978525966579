import config from 'config'
import { createSelector } from 'reselect'
import { selectIsInInbox } from 'selectors/location'
import { selectFlag } from 'ducks/flags/selectors'
import { selectDaysLeftBeforeExpiring } from './selectDaysLeftBeforeExpiring'
import { selectIsTrial } from './selectIsTrial'

export const selectShowTrialMessage = createSelector(
  selectIsTrial,
  selectDaysLeftBeforeExpiring,
  selectIsInInbox,
  selectFlag,
  (isTrial, trialDaysLeft, isInInbox, flags) =>
    isInInbox &&
    isTrial &&
    trialDaysLeft > 0 &&
    trialDaysLeft <= config.trialShowNoticeBannerDaysLeft &&
    flags('app-notice-trialinfo') !== true
)
