import { createSelector } from 'reselect'

import selectGitHubCurrentTicketLinkedResources from './selectGitHubCurrentTicketLinkedResources'
import selectGitHubNodesById from './selectGitHubNodesById'

export default createSelector(
  selectGitHubCurrentTicketLinkedResources,
  selectGitHubNodesById,
  (linkedResources, nodes) => {
    if (!linkedResources) return linkedResources
    return linkedResources.map(linkedResource => {
      const { externalId } = linkedResource
      const issue =
        nodes[externalId] ||
        Object.values(nodes).find(
          ({ databaseId }) => String(databaseId) === externalId
        )
      return {
        ...linkedResource,
        ...issue,
      }
    })
  }
)
