import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { createBasicSelector } from 'util/redux'

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.recharge || emptyObj

export const selectSubscriptions = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.subscriptions
)

export const selectSubscriptionsByCustomerId = createBasicSelector(
  selectSubscriptions,
  subscriptions => subscriptions.byCustomerId || emptyObj
)

export const selectSubscriptionsForCustomerId = createCachedSelector(
  selectSubscriptionsByCustomerId,
  (state, customerId) => customerId,
  (byCustomerId, customerId) => byCustomerId[customerId] || emptyObj
)((state, customerId) => customerId || 'unknown')

export const selectSubscriptionsIsLoading = createCachedSelector(
  selectSubscriptionsForCustomerId,
  subscriptions => subscriptions?.isLoading
)((state, customerId) => customerId || 'unknown')

export const selectSubscriptionsIsError = createCachedSelector(
  selectSubscriptionsForCustomerId,
  subscriptions => subscriptions?.isError
)((state, customerId) => customerId || 'unknown')

export const selectSubscriptionForId = createCachedSelector(
  (state, { customerId }) =>
    selectSubscriptionsForCustomerId(state, customerId),
  (state, { customerId }) => customerId,
  (state, { subscriptionId }) => subscriptionId,
  (byCustomerId, customerId, subscriptionId) => {
    const subscriptions = byCustomerId.subscriptions || emptyArr
    return subscriptions.find(
      subscription => subscription.id === parseInt(subscriptionId, 10)
    )
  }
)((state, { customerId }) => customerId || 'unknown')
