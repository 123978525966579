import { doLinkResource } from 'actions/userIntegrations'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentConversationUrl } from 'selectors/tickets'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import * as trello from '../api'
import { selectTrelloCardsById } from '../selectors'

export default function doLinkTrelloCardToTicket(cardId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const ticketUrl = selectCurrentConversationUrl(state)
    const ticketId = selectCurrentTicketId(state)
    const cards = selectTrelloCardsById(state)
    const card = cards[cardId]
    if (!card) return Promise.reject('Cannot find Trello card')

    const { name: title, url, id } = card

    return Promise.all([
      dispatch(doLinkResource({ title, url, id, provider: 'trello' })),
      trello.attachTicketToCard(
        token,
        id,
        ticketUrl,
        `Groove conversation #${ticketId}`
      ),
    ])
  }
}
