export const AGENT_DATA_TABLE_QUERY_ID =
  'entityType:agent includeAll:true pageSize:10000'
export const AGENT_ACTIVE_OR_ARCHIVED_DATA_TABLE_QUERY_ID =
  'entityType:agent includeArchived:true pageSize:10000'

export const PAID_AGENT_ROLES = ['owner', 'admin', 'agent']

export const ROLE_TO_NAME = {
  owner: 'Owner',
  admin: 'Admin',
  agent: 'Agent',
  viewer: 'Lite',
}
