import { combineReducers } from 'core/redux'

import loaded from './loaded'
import loading from './loading'
import byId from './byId'

export default combineReducers({
  loaded,
  loading,
  byId,
})
