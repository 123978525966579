import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors'

import { agentPreferencesNormalizationSchema } from './schema'
import { FETCH_AGENT_PREFERENCES, UPDATE_AGENT_PREFERENCES } from './types'

export function doFetchCurrentUserAgentPreferences(namespace = null) {
  return (dispatch, getState) => {
    const agentId = selectCurrentUserGlobalId(getState())
    return dispatch(doFetchAgentPreferences(agentId, namespace))
  }
}

function generateIdFromNamespaceAndKey(data) {
  return {
    ...data,
    agentPreferences: {
      ...data.agentPreferences,
      nodes: data.agentPreferences.nodes.map(node => {
        return {
          id: `${node.namespace}:${node.key}`,
          ...node,
        }
      }),
    },
  }
}

export function doFetchAgentPreferences(agentId, namespace = null) {
  const query = `
      query FetchAgentPreferences($agentId: ID, $namespace: String) {
        agentPreferences(filter: { agentId: $agentId, namespace: $namespace }) {
          nodes {
            namespace
            key
            value
          }
        }
      }
    `
  const variables = {
    agentId,
    namespace,
  }
  return doAppGraphqlRequest(FETCH_AGENT_PREFERENCES, query, variables, {
    normalizationSchema: agentPreferencesNormalizationSchema,
    transformResponse: generateIdFromNamespaceAndKey,
    debug: true,
    meta: {
      requestKey: `FetchAgentPreferences(${agentId},${namespace})`,
    },
  })
}

export function doUpdateAgentPreference(namespace, key, value) {
  const query = `mutation UpdateAgentPreference($input: AgentPreferenceUpsertInput!) {
    agentPreferenceUpsert(input: $input) {
      agentPreference {
        key
        namespace
        value
      }
    }
  }`
  const variables = {
    input: {
      key,
      namespace,
      value,
    },
  }
  return doAppGraphqlRequest(UPDATE_AGENT_PREFERENCES, query, variables, {
    optimist: {
      entities: {
        agentPreferences: [
          {
            id: `${namespace}:${key}`,
            key,
            namespace,
            value,
          },
        ],
      },
    },
  })
}
