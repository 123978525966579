import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { diff } from 'util/date'
import { selectFeatureCalloutExpired } from 'selectors/app/selectFeatureCalloutExpired'
import {
  selectAccount,
  selectIsAccountBootstrapped,
} from 'ducks/accounts/selectors/selectAccount'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import {
  HIDE_CANNED_REPLY_INBOX_SCOPE_CALLOUT,
  HIDE_RULE_ADVANDED_CALLOUT,
  HIDE_AI_TEXT_MODIFICATIONS_CALLOUT,
  HIDE_FEATURES_ANNOUNCEMENT,
  HIDE_NOTE_EDIT_CALLOUT,
  HIDE_CUSTOM_NOTIFICATIONS_CALLOUT,
  HIDE_NEW_COMPOSER_CALLOUT,
} from './flagNames'

function selectBase(state) {
  return state.flags || emptyObj
}

export const selectIsFetchingFlags = createSelector(
  selectBase,
  flags => flags.fetching
)

export const selectByName = createSelector(selectBase, flags => flags.byName)

// NOTE (jscheel): There are three possible states of a flag:
// 1. true      - The flag exists on the agent
// 2. false     - The flag was not found on the agent
// 3. undefined - Flag is still loading, you should _not_ depend on it yet
export const selectFlag = createSelector(
  selectIsFetchingFlags,
  selectByName,
  (fetching, byName) => {
    if (fetching) {
      return () => undefined
    }
    return name => !!byName[name]
  }
)

const selectIsAccountReadyForCallouts = createSelector(
  selectIsAccountBootstrapped,
  selectAccount,
  (isAccountBootstrapped, account) =>
    isAccountBootstrapped && diff('days', account.activated_at, new Date()) >= 3
)

export const selectShouldHideNewForCannedReply = state =>
  selectFeatureCalloutExpired(state, '2023-04-12T00:00:00') ||
  selectFlag(state)(HIDE_CANNED_REPLY_INBOX_SCOPE_CALLOUT) !== false

export const selectShouldHideNewAdvandedRulesCallout = state =>
  !selectIsAccountReadyForCallouts(state) ||
  selectFeatureCalloutExpired(state, '2023-08-17T00:00:00') ||
  selectFlag(state)(HIDE_RULE_ADVANDED_CALLOUT) !== false

export const selectShouldHideNewRule = createSelector(
  selectShouldHideNewAdvandedRulesCallout,
  selectCurrentUserIsAdminOrOwner,
  (shouldHideNewAdvandedRulesCallout, isManager) =>
    shouldHideNewAdvandedRulesCallout || !isManager
)

export const selectShouldHideNewAIMessageCallout = state =>
  !selectIsAccountReadyForCallouts(state) ||
  selectFeatureCalloutExpired(state, '2023-09-14T00:00:00') ||
  selectFlag(state)(HIDE_AI_TEXT_MODIFICATIONS_CALLOUT) !== false

export const selectShouldHideNewSettings = createSelector(
  selectShouldHideNewRule,
  shouldHideNewRule => shouldHideNewRule
)

export const selectShouldHideFeaturesAnnoucement = state =>
  !selectIsAccountReadyForCallouts(state) ||
  selectFeatureCalloutExpired(state, '2023-08-17T00:00:00') ||
  selectFlag(state)(HIDE_FEATURES_ANNOUNCEMENT) !== false

export const selectShouldHideNoteEditCallout = state =>
  !selectIsAccountReadyForCallouts(state) ||
  selectFeatureCalloutExpired(state, '2023-09-4T00:00:00') ||
  selectFlag(state)(HIDE_NOTE_EDIT_CALLOUT) !== false

export const selectShouldHideNewCustomNotificationsCallout = state =>
  !selectIsAccountReadyForCallouts(state) ||
  // FIXME (Cindy): update launch date
  selectFeatureCalloutExpired(state, '2023-09-1T00:00:00') ||
  selectFlag(state)(HIDE_CUSTOM_NOTIFICATIONS_CALLOUT) !== false

export const selectHideShouldNewComposerCallout = state =>
  !selectIsAccountReadyForCallouts(state) ||
  selectFeatureCalloutExpired(state, '2023-10-12T00:00:00') ||
  selectFlag(state)(HIDE_NEW_COMPOSER_CALLOUT) !== false
