import React, { useCallback } from 'react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { Settings } from '@groovehq/internal-design-system/lib/assets/icons'
import { pencil as Pencil } from 'assets/icons/groove/v2'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_CANNED_REPLIES_OVERVIEW,
  DRAWER_TYPE_CANNED_REPLIES_UPDATE,
} from 'ducks/drawers/types'

import { styles } from './styles'

const DropdownFooter = ({ onVisibleChange }) => {
  const { openDrawer: openCreateDrawer } = useDrawer({
    type: DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  })

  const { openDrawer: openOverviewDrawer } = useDrawer({
    type: DRAWER_TYPE_CANNED_REPLIES_OVERVIEW,
  })

  const handleOpenCreateDrawer = useCallback(
    () => {
      onVisibleChange(false)
      openCreateDrawer('new')
    },
    [openCreateDrawer, onVisibleChange]
  )

  const handleOpenDrawer = useCallback(
    () => {
      onVisibleChange(false)
      openOverviewDrawer()
    },
    [openOverviewDrawer, onVisibleChange]
  )

  return (
    <div css={styles.footer}>
      <button
        type="button"
        css={button.styles.link}
        onClick={handleOpenCreateDrawer}
      >
        <Pencil />
        <span className="grui mr-4">Create new</span>
      </button>
      <button type="button" css={button.styles.link} onClick={handleOpenDrawer}>
        <Settings />
        <span className="grui mr-4">Manage</span>
      </button>
    </div>
  )
}

export default DropdownFooter
