export const FETCH_ISSUE_SEARCH_ERROR =
  'integrations/jira_server/FETCH_ISSUE_SEARCH_ERROR'
export const FETCH_ISSUE_SEARCH_REQUEST =
  'integrations/jira_server/FETCH_ISSUE_SEARCH_REQUEST'
export const FETCH_ISSUE_SEARCH_SUCCESS =
  'integrations/jira_server/FETCH_ISSUE_SEARCH_SUCCESS'

export const FETCH_PROJECT_ERROR =
  'integrations/jira_server/FETCH_PROJECT_ERROR'
export const FETCH_PROJECT_REQUEST =
  'integrations/jira_server/FETCH_PROJECT_REQUEST'
export const FETCH_PROJECT_SUCCESS =
  'integrations/jira_server/FETCH_PROJECT_SUCCESS'

export const FETCH_PROJECTS_ERROR =
  'integrations/jira_server/FETCH_PROJECTS_ERROR'
export const FETCH_PROJECTS_REQUEST =
  'integrations/jira_server/FETCH_PROJECTS_REQUEST'
export const FETCH_PROJECTS_SUCCESS =
  'integrations/jira_server/FETCH_PROJECTS_SUCCESS'

export const FETCH_TYPES_ERROR = 'integrations/jira_server/FETCH_TYPES_ERROR'
export const FETCH_TYPES_REQUEST =
  'integrations/jira_server/FETCH_TYPES_REQUEST'
export const FETCH_TYPES_SUCCESS =
  'integrations/jira_server/FETCH_TYPES_SUCCESS'

export const FETCH_ASSIGNABLE_USERS_ERROR =
  'integrations/jira_server/FETCH_ASSIGNABLE_USERS_ERROR'
export const FETCH_ASSIGNABLE_USERS_REQUEST =
  'integrations/jira_server/FETCH_ASSIGNABLE_USERS_REQUEST'
export const FETCH_ASSIGNABLE_USERS_SUCCESS =
  'integrations/jira_server/FETCH_ASSIGNABLE_USERS_SUCCESS'

export const FETCH_LABELS_ERROR = 'integrations/jira_server/FETCH_LABELS_ERROR'
export const FETCH_LABELS_REQUEST =
  'integrations/jira_server/FETCH_LABELS_REQUEST'
export const FETCH_LABELS_SUCCESS =
  'integrations/jira_server/FETCH_LABELS_SUCCESS'

export const FETCH_COMPONENTS_ERROR =
  'integrations/jira_server/FETCH_COMPONENTS_ERROR'
export const FETCH_COMPONENTS_REQUEST =
  'integrations/jira_server/FETCH_COMPONENTS_REQUEST'
export const FETCH_COMPONENTS_SUCCESS =
  'integrations/jira_server/FETCH_COMPONENTS_SUCCESS'

export const FETCH_PRIORITIES_ERROR =
  'integrations/jira_server/FETCH_PRIORITIES_ERROR'
export const FETCH_PRIORITIES_REQUEST =
  'integrations/jira_server/FETCH_PRIORITIES_REQUEST'
export const FETCH_PRIORITIES_SUCCESS =
  'integrations/jira_server/FETCH_PRIORITIES_SUCCESS'

export const CREATE_ISSUE_REQUEST =
  'integrations/jira_server/CREATE_ISSUE_REQUEST'
export const CREATE_ISSUE_SUCCESS =
  'integrations/jira_server/CREATE_ISSUE_SUCCESS'
export const CREATE_ISSUE_ERROR = 'integrations/jira_server/CREATE_ISSUE_ERROR'
