import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'util/objects'

import { doFetchAttachments } from 'ducks/integrations/attachments/operations'
import { selectAttachmentsForType } from 'ducks/integrations/attachments/selectors'

export const useAttachments = (ticketId, type) => {
  const dispatch = useDispatch()
  const attachmentsData = useSelector(state =>
    selectAttachmentsForType(state, {
      ticketId,
      type,
    })
  )

  useEffect(
    () => {
      if (
        !attachmentsData.isLoaded &&
        !attachmentsData.isLoading &&
        ticketId &&
        ticketId !== 'new'
      ) {
        dispatch(doFetchAttachments(ticketId, type))
      }
    },
    [dispatch, attachmentsData, ticketId, type]
  )

  if (!isEmpty(attachmentsData)) {
    return attachmentsData
  }

  return {
    attachments: [],
    isLoading: false,
    isLoaded: false,
  }
}
