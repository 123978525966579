import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { doCloseDrawer, doAddDrawerParam } from '../actions'
import { drawerConfig } from './drawerConfig'

// Functions that are used for both the useRenderDrawers useDrawer

export function useDrawerCommons({
  closeIgnoresStack,
  previousDrawerId,
  drawerId,
}) {
  const dispatch = useDispatch()

  const closeDrawer = useCallback(
    options => {
      const closeOptions = {
        ignoreStack: closeIgnoresStack,
        ...(options || {}),
      }
      const { previousDrawerAdditionalProps, ignoreStack } = closeOptions
      if (previousDrawerId && !drawerConfig[previousDrawerId]) {
        drawerConfig[previousDrawerId] = { additionalProps: {} }
      }
      if (previousDrawerId && previousDrawerAdditionalProps) {
        Object.assign(
          drawerConfig[previousDrawerId].additionalProps,
          previousDrawerAdditionalProps
        )
      }
      dispatch(doCloseDrawer(closeOptions))
      if (ignoreStack) {
        Object.keys(drawerConfig).forEach(key => delete drawerConfig[key])
      } else {
        delete drawerConfig[drawerId]
      }
    },
    [dispatch, closeIgnoresStack, drawerId, previousDrawerId]
  )

  const updateDrawerParameter = useCallback(
    (paramKey, paramValue, options) => {
      dispatch(doAddDrawerParam(drawerId, paramKey, paramValue, options))
    },
    [dispatch, drawerId]
  )

  return {
    closeDrawer,
    updateDrawerParameter,
  }
}
