import { createSelector } from 'reselect'
import { selectPendingFuturePhaseDetailed } from './selectPendingFuturePhaseDetailed'

export const selectPendingDowngrades = createSelector(
  selectPendingFuturePhaseDetailed,
  futurePhase => {
    return Object.keys(futurePhase).reduce((downgrades, phaseKey) => {
      const phaseValue = futurePhase[phaseKey]

      // eslint-disable-next-line no-param-reassign
      downgrades[phaseKey] = phaseValue

      if (phaseValue?.isPlanChange === false) {
        // eslint-disable-next-line no-param-reassign
        downgrades[phaseKey] = null
      }

      return downgrades
    }, {})
  }
)
