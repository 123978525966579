import { doFetchAccountPreference } from 'ducks/accountPreferences/operations'
import { selectRequestByType } from 'ducks/requests/selectors'
import { doApiReadRequest, doApiWriteRequest } from 'ducks/requests/operations'
import { selectIsSlackInstalled } from 'selectors/app'
import { selectChannelsBySlackId } from './selectors'
import {
  FETCH_SLACK_APP,
  FETCH_SLACK_CHANNELS,
  FETCH_SLACK_PROFILES,
  UPDATE_SLACK_APP,
} from './types'

export const doFetchSlackApp = () => (dispatch, getState) => {
  return dispatch(
    doApiReadRequest(
      FETCH_SLACK_APP,
      'slack/app',
      {},
      {
        onBeforeSuccessAction: data => {
          const state = getState()
          // Fetch usage_onboarding.slack_installed again if just installed Slack
          if (data?.id && !selectIsSlackInstalled(state)) {
            dispatch(doFetchAccountPreference('usage_onboarding'))
          }
        },
      }
    )
  )
}

export const doFetchSlackChannels = (forceRefetch = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const requestState = selectRequestByType(state, FETCH_SLACK_CHANNELS)
  const channelsBySlackId = selectChannelsBySlackId(state)
  const idle =
    !requestState ||
    (!requestState.loaded && !requestState.loading && !requestState.error)

  if (
    idle ||
    (forceRefetch && !requestState?.loading && !requestState?.error)
  ) {
    return dispatch(doApiReadRequest(FETCH_SLACK_CHANNELS, 'slack/channels'))
  }

  return Promise.resolve(channelsBySlackId)
}

export const doFetchSlackProfiles = () => dispatch => {
  return dispatch(doApiReadRequest(FETCH_SLACK_PROFILES, 'slack/profiles'))
}

export const doUpdateSlackAppV0 = updatePayload => dispatch => {
  return dispatch(
    doApiWriteRequest(
      UPDATE_SLACK_APP,
      'slack/app',
      { slack_app: updatePayload },
      {
        method: 'PUT',
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: `Slack changes saved`,
            },
            failed: {
              enabled: true,
              content: `Slack changes failed`,
            },
          },
        },
      }
    )
  )
}
