import { createSelector } from 'reselect'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors/base'
import { selectIsBootstrapTypedInStates } from 'selectors/bootstrap/makeSelectIsBootstrapTypesLoaded'
import { diff } from 'util/date'
import { selectIsTrial } from './selectIsTrial'
import { selectBillingProvider } from './selectBillingProvider'
import { selectIsSubscriptionActive } from './selectIsSubscriptionActive'
import { selectCurrentBillingCycle } from './selectCurrentBillingCycle'
import { BILLING_CYCLE_ANNUAL } from '../constants'

export const selectShowAnnualOffer = createSelector(
  selectIsSubscriptionActive,
  selectCurrentUserIsAdminOrOwner,
  selectCurrentBillingCycle,
  selectBillingProvider,
  selectIsTrial,
  state => selectIsBootstrapTypedInStates(state, ['billing'], ['LOADED']),
  (
    isSubscriptionActive,
    isManager,
    billingCycle,
    billingProvider,
    isTrial,
    isBillingBootstrapped
  ) => {
    const daysDiff = diff('days', '2023-05-30T00:00:00', new Date())
    return (
      isBillingBootstrapped &&
      billingProvider === 'stripe' &&
      isSubscriptionActive &&
      !isTrial &&
      isManager &&
      billingCycle !== BILLING_CYCLE_ANNUAL &&
      daysDiff <= 14
    )
  }
)
