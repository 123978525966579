import config from 'config'
import { sortByKeyTypeDesc as byProp } from 'util/arrays'
import { timeInHuman } from 'util/date'
import { runOnNextTick, sleep } from 'util/functions'
import {
  SNOOZED_INDEFINITELY,
  SNOOZED_INDEFINITELY_TIMESTAMP,
} from 'util/snooze'
import { replaceParamInUrl } from 'util/params'
import { camelizeObjectKeys } from 'util/objects'

import { selectRoomsById } from '../selectors/rooms'

import {
  isAgentUser,
  isEndUser,
  isUser as isUserId,
  isBotUser,
  getAgentIdFromMatrixUserId,
} from './users'
import { AGENT_USER_REGEX, CHAT_ADMIN_NAME } from './constants'
import { getMatrixClient } from './client'
import { isAgentNoteEvent } from './events'

const { chatServerUrl: MATRIX_CHAT_SERVER_URL } = config

const chatServerHostName = new URL(MATRIX_CHAT_SERVER_URL).hostname
const roomAliasRegex = /^#(?<accountId>.*?)-(?<roomId>.*?):(?<homeServer>.*)$/

export const ROOM_STATES = {
  open: 2000,
  snoozed: 4000,
  closed: 5000,
  spam: 6000,
  trash: 7000,
}

export function stateToInt(state) {
  const stateStr = state.toString().toLowerCase()
  if (Object.keys(ROOM_STATES).includes(stateStr)) {
    return ROOM_STATES[stateStr]
  }
  if (
    Object.values(ROOM_STATES).some(
      stateInt => stateInt.toString() === stateStr
    )
  ) {
    return stateStr * 1
  }
  throw new Error(
    `The state [${state}] could not be mapped to a known state value`
  )
}

export function stateToString(state) {
  const stateStr = state.toString().toLowerCase()
  if (Object.keys(ROOM_STATES).includes(stateStr)) {
    return stateStr
  }
  if (
    Object.values(ROOM_STATES).some(
      stateInt => stateInt.toString() === stateStr
    )
  ) {
    return Object.keys(ROOM_STATES).find(
      key => ROOM_STATES[key].toString() === stateStr
    )
  }
  throw new Error(
    `The state [${state}] could not be mapped to a known state value`
  )
}

export function isCloseable(state) {
  if (stateToInt(state) < 5000) {
    return true
  }
  return false
}

export function isDeleted(state) {
  return stateToInt(state) === 7000
}

export function isSnoozed(state) {
  return stateToInt(state) === 4000
}

export function isSpam(state) {
  return stateToInt(state) === 6000
}

export function isClosed(state) {
  return stateToInt(state) === 5000
}

export function isTrash(state) {
  return stateToInt(state) === 7000
}

export function getSnoozedUntil(room) {
  const { snoozed_until: snoozedUntil } = getStateEventContent(room) || {}
  return snoozedUntil || null
}

export function getRoomId(room) {
  return extractRoomIdFromAlias(room.getCanonicalAlias())
}

export function extractRoomIdFromAlias(alias) {
  const match = roomAliasRegex.exec(alias)
  if (!match) return null
  return parseInt(match.groups.roomId, 10)
}

export function buildRoomAliasFromId(roomId, accountId) {
  return `#${accountId}-${roomId}:${chatServerHostName}`
}

export function getRoomIdentity(room) {
  return room.currentState.getStateEvents('g.identity', '')
}

export function getRoomVerifiedIdentityEmail(room) {
  const identityEvent = getRoomIdentity(room)
  if (!identityEvent) return null
  const { value, email } = identityEvent.getContent()
  if (value === 'verified') return email
  return null
}

export function getRoomCreatorId(room) {
  const roomCreateEvent = room.currentState.getStateEvents('m.room.create', '')
  if (roomCreateEvent) return roomCreateEvent.getSender()
  return null
}

export function getAssignedEventContent(room) {
  const assignedAgentEvent = room.currentState.getStateEvents('g.assign', '')
  if (assignedAgentEvent) return assignedAgentEvent.getContent()
  return null
}

export function getAssignedAgentId(room) {
  const { type, agent_id: agentId } = getAssignedEventContent(room) || {}
  if (type === 'agent') return agentId
  return null
}

export function getAssignedTeamId(room) {
  const { type, team_id: teamId } = getAssignedEventContent(room) || {}
  if (type === 'team') return teamId
  return null
}

export function getAssignedPermanentAgentId(room) {
  const { agent_id: agentId, permanent_agent_id: permanentAgentId } =
    getAssignedEventContent(room) || {}
  return permanentAgentId || agentId
}

export function getAssignedType(room) {
  const { type } = getAssignedEventContent(room) || {}
  return type || null
}

export function isAssigned(assignedType) {
  return assignedType && assignedType !== 'none'
}

function getLinkedExternalResources(room) {
  return {
    records: Array.from(room.currentState.events)
      .map(([key, value]) => {
        if (key.match(/^g\.integrations/)) {
          return value
        }
        return null
      })
      .filter(x => x !== null)
      .map(value => Array.from(value.values()))
      .flat()
      .map(({ event: { content } = {} }) => camelizeObjectKeys(content))
      .filter(({ removed = false } = {}) => removed !== true),
  }
}

export function getIsRead(matrixRoom) {
  return !getIsUnread(matrixRoom)
}

export function getIsUnread(matrixRoom) {
  const { id } = getStateEventContent(matrixRoom) || {}
  const unreadAt = getUnreadAt(matrixRoom)
  const readAt = getReadAt(matrixRoom)
  const assignedType = getAssignedType(matrixRoom)
  const assignedAgentId = getAssignedAgentId(matrixRoom)

  return isUnread({
    stateId: id,
    unreadAt,
    readAt,
    assignedType,
    assignedAgentId,
  })
}

export function getUnreadAt(matrixRoom) {
  const client = getMatrixClient()
  const userId = client.getUserId()
  const currentAgentId = getAgentIdFromMatrixUserId(userId)

  const roomReadEvent = matrixRoom.currentState.getStateEvents(
    'g.state.read',
    ''
  )
  const agentReadEvent = matrixRoom.currentState.getStateEvents(
    'g.state.read',
    currentAgentId
  )

  const stateEvent = matrixRoom.currentState.getStateEvents('g.state', '')
  const assignEvent = matrixRoom.currentState.getStateEvents('g.assign', '')

  const lastEnduserEvent = getLastEnduserMessage(matrixRoom)
  const { unread_at: roomUnreadAt, is_read: roomLegacyIsRead } = roomReadEvent
    ? roomReadEvent.getContent()
    : {}

  const { is_read: isRead } = agentReadEvent ? agentReadEvent.getContent() : {}

  const unreadTss = [
    stateEvent && stateEvent.getTs(),
    assignEvent && assignEvent.getTs(),
    !roomLegacyIsRead &&
      (roomUnreadAt || (roomReadEvent && roomReadEvent.getTs())),
    lastEnduserEvent && lastEnduserEvent.getTs(),
    !isRead && agentReadEvent && agentReadEvent.getTs(),
  ]

  return Math.max(...unreadTss.filter(ts => !!ts))
}

export function getReadAt(matrixRoom) {
  const client = getMatrixClient()

  const userId = client.getUserId()
  const currentAgentId = getAgentIdFromMatrixUserId(userId)

  const roomReadEvent = matrixRoom.currentState.getStateEvents(
    'g.state.read',
    ''
  )
  const agentReadEvent = matrixRoom.currentState.getStateEvents(
    'g.state.read',
    currentAgentId
  )

  const {
    last_read_event_id: lastReadEventId,
    is_read: roomLegacyIsRead,
  } = roomReadEvent ? roomReadEvent.getContent() : {}

  const { read_at: agentReadAt = 0, is_read: isRead } = agentReadEvent
    ? agentReadEvent.getContent()
    : {}

  const readTss = [
    agentReadAt,
    isRead && agentReadEvent.getTs(),
    (lastReadEventId || roomLegacyIsRead) && roomReadEvent.getTs(),
  ]
  const readAt = Math.max(...readTss.filter(ts => !!ts))

  return readAt
}

export function isUnread({
  stateId,
  unreadAt,
  readAt,
  assignedType,
  assignedAgentId,
}) {
  const client = getMatrixClient()
  const userId = client.getUserId()
  const currentAgentId = getAgentIdFromMatrixUserId(userId)

  // Match rules here to room.rb:read? function
  // Only open conversations needs to be attended to
  if (stateId !== 2000) return false

  // If the agent saw this conversation after the last action of note, then dont
  // display it as unread
  if (readAt > unreadAt) return false

  // If the converastion is open, and the agent hasnt checked it, but the conversation is assigned
  // to a different agent, then they dont need to attend to it.
  if (assignedType === 'agent' && assignedAgentId !== currentAgentId)
    return false

  return true
}

export function getIsChannelId(room) {
  const channelEvent = room.currentState.getStateEvents('g.channel', '')
  if (channelEvent) return channelEvent.getContent().id.replace('ch_', '')
  return null
}

export function getIsStarred(room) {
  const isStarredEvent = room.currentState.getStateEvents('g.state.starred', '')
  if (isStarredEvent) return isStarredEvent.getContent().is_starred
  return false
}

export function getBridgeType(room) {
  let res = null
  // eslint-disable-next-line no-restricted-syntax
  for (const eventType of room.currentState.events.keys()) {
    if (eventType.indexOf('g.bridge.') === 0) {
      res = eventType
      break
    }
  }
  return res
}

export function getBridgeEvent(room) {
  const eventType = getBridgeType(room)
  return room.currentState.getStateEvents(eventType, '')?.getContent() || null
}

export function getExpiryEvent(room) {
  const expiryEvent = room.currentState.getStateEvents('g.state.expiry', '')
  return expiryEvent ? expiryEvent.getContent() : null
}

export function getStateEventContent(room) {
  const stateEvent = room.currentState.getStateEvents('g.state', '')
  if (stateEvent) return stateEvent.getContent()
  return null
}

export function getStateChangedAt(room) {
  const stateEvent = room.currentState.getStateEvents('g.state', '')
  if (stateEvent) return stateEvent.getTs()
  return null
}

export function getAssignedAt(room) {
  const assignEvent = room.currentState.getStateEvents('g.assign', '')
  if (assignEvent) return assignEvent.getTs()
  return null
}

export function getUpdatedAt(room) {
  return room.getLastActiveTimestamp()
}

export function getStateChangedByAgentId(room) {
  const stateEvent = room.currentState.getStateEvents('g.state', '')
  if (stateEvent) {
    const sender = stateEvent.getSender()
    if (isAgentUser(sender)) {
      return sender.match(AGENT_USER_REGEX).groups.agent_id
    }
  }
  return null
}

export function getState(room) {
  const { id } = getStateEventContent(room) || {}
  return id || null
}

export function getLastMessage(room) {
  const messageEvents = room.timeline.filter(
    e => e.event.type === 'm.room.message'
  )
  if (messageEvents.length > 0) {
    return messageEvents[messageEvents.length - 1]
  }
  return null
}

export function getLastEnduserMessage(room) {
  const messageEvents = room.timeline.filter(
    e => e.event.type === 'm.room.message' && isEndUser(e.getSender())
  )
  if (messageEvents.length > 0) {
    return messageEvents[messageEvents.length - 1]
  }
  return null
}

export function getMessageCount(room) {
  return room.timeline.filter(
    e => e.event.type === 'm.room.message' && !isBotUser(e.getSender())
  ).length
}

export function getLastMessageReceivedOrRoomCreatedDate(room) {
  const { timeline, currentState } = room

  // First we try and get the last message received date
  const messageEvents = timeline.filter(e => e.event.type === 'm.room.message')
  if (messageEvents.length > 0) {
    return messageEvents[messageEvents.length - 1].getDate().getTime()
  }

  // If no messages has been sent yet, we look at the room create date
  const roomCreateEvents = currentState.getStateEvents('m.room.create')
  if (roomCreateEvents.length > 0) {
    return roomCreateEvents[0].getDate().getTime()
  }

  // for some reason direct chat message rooms dont have a create date. The only
  // event that has a timestamp is when a member joins the room, so we'll use that
  let lastEventReceived = null
  currentState.events.forEach(typedEvents => {
    typedEvents.forEach(matrixEvent => {
      const eventDate = matrixEvent.getDate()
      if (eventDate && eventDate > lastEventReceived) {
        lastEventReceived = eventDate
      }
    })
  })
  if (lastEventReceived) return lastEventReceived.getTime()
  // When all else fails, just use the room modified time. Technically this case should
  // never happen, but I dont know enough of the matrix internals to rule it out
  return room.currentState.getLastModifiedTime().getTime()
}

export function getMemberJoinEvents(room) {
  const joinEvents = []
  room.currentState.getStateEvents('m.room.member').forEach(matrixEvent => {
    if (matrixEvent.event.content.membership === 'join') {
      joinEvents.push(matrixEvent)
    }
  })
  return joinEvents
}

export function getAgents(room) {
  const agents = []
  room.currentState
    .getMembers()
    .filter(m => !!m.user)
    .forEach(member => {
      const { user, membership } = member
      // membership indicates the current "state" of this user
      if (membership === 'join' && user && isAgentUser(user.userId)) {
        agents.push({
          id: user.userId,
          name: user.displayName,
          presence: user.presence,
          lastActiveAgo: user.lastActiveAgo,
          lastPresenceTs: user.lastPresenceTs,
        })
      }
    })
  return agents
}

export function formatLastMessageEvent(lastMessageEvent) {
  if (!lastMessageEvent) return ''
  const { body: lastMessage = '' } = lastMessageEvent.getContent()
  return lastMessage
}

export function getMessageDisplayName(messageEvent) {
  const { sender: { rawDisplayName = '' } = {} } = messageEvent || {}
  return rawDisplayName
}

export function getMessageUserId(messageEvent) {
  const { sender: { userId } = {} } = messageEvent || {}
  return userId
}

export function isAccountRoom(room) {
  return (
    room.tags &&
    room.tags.GROOVE &&
    room.tags.GROOVE &&
    room.tags.GROOVE.isAccountRoom
  )
}

export function isCurrentUserInvited(room) {
  const client = getMatrixClient()
  const currentUserId = client.getUserId()
  const { currentState } = room
  return currentState.getStateEvents('m.room.member').some(matrixEvent => {
    const {
      event: {
        state_key: stateKey,
        content: { membership },
      },
    } = matrixEvent
    return (
      stateKey === currentUserId &&
      (membership === 'invite' || membership === 'join')
    )
  })
}

export function isCurrentUserMember(room) {
  return room.getMyMembership() === 'join'
}

const joiningRooms = {}
export function ensureUserIsMember(roomId) {
  const client = getMatrixClient()
  const room = client.getRoom(roomId)
  if (room && isCurrentUserMember(room)) return true
  if (!joiningRooms[roomId]) {
    joiningRooms[roomId] = runOnNextTick(() => {
      getRoomById(roomId)
    })
  }
  return false
}

export async function inviteAndJoinRoom(roomId) {
  const client = getMatrixClient()
  const userId = client.getUserId()
  const agentId = getAgentIdFromMatrixUserId(userId)
  const roomIdLocalPart = roomId.substr(1, roomId.indexOf(':') - 1)
  const roomIdServerPart = roomId.substr(roomId.indexOf(':') + 1)
  const autoJoinAlias = `#join-${roomIdLocalPart}-${agentId}:${roomIdServerPart}`
  return joinRoom(autoJoinAlias)
}

export async function joinRoom(idOrAlias) {
  const client = getMatrixClient()
  let room = await client.joinRoom(idOrAlias)
  const roomId = room.roomId
  let attempt = 0
  while (!isCurrentUserMember(room) && attempt < 100) {
    attempt += 1
    // eslint-disable-next-line no-await-in-loop
    await sleep(50)
    // eslint-disable-next-line no-await-in-loop
    const updatedRoom = await client.getRoom(roomId)
    room = updatedRoom || room
  }
  return room
}

export function getParticipatingAgentIds(room) {
  const assignedEvents = room.currentState.getStateEvents('g.participants')
  let agentIds = []
  const assignedAgentId = getAssignedAgentId(room)
  if (assignedEvents.length > 0) {
    agentIds = assignedEvents[0].getContent().agent_ids || []
  }
  if (assignedAgentId && agentIds.indexOf(assignedAgentId) === -1) {
    agentIds.unshift(assignedAgentId)
  }
  return agentIds
}

export function getTitle(room) {
  const title =
    room.currentState.getStateEvents('m.room.name', '')?.getContent()?.name ||
    ''
  const match = roomAliasRegex.exec(title)
  // This check looks wrong, but the default value of the title is the room
  // canonical alias. If its an alias, we want to return the room id and when its
  // not an alias we want to return the value
  if (!match) return title
  return `#${match.groups.roomId}`
}

// The room stored in the state has 2 types of data. Data
// that comes from the data storage layer and data that we
// compute based on the data storage layer. This function
// will read from the data storage fields and rebuild all
// computed fields
export function recomputeFields(roomData) {
  const {
    state,
    assignedType,
    assignedPermanentAgentId,
    assignedAgentId,
    unreadAt,
    readAt,
  } = roomData
  return {
    ...roomData,
    isRead: !isUnread({
      stateId: stateToInt(state),
      unreadAt,
      readAt,
      assignedType,
      assignedAgentId,
    }),
    isCloseable: isCloseable(state),
    isClosed: isClosed(state),
    isTrash: isTrash(state),
    isDeleted: isDeleted(state),
    isSnoozed: isSnoozed(state),
    isSpam: isSpam(state),
    isAssigned: isAssigned(assignedType),
    assignedPermanentAgentId: assignedPermanentAgentId || assignedAgentId,
  }
}

export function mergeFields(room, previousRoom) {
  return {
    ...previousRoom,
    ...room,
  }
}

function getRoomData(matrixRoom) {
  const { roomId: matrixRoomId } = matrixRoom
  const roomId = getRoomId(matrixRoom)
  const lastEnduserMessageEvent = getLastEnduserMessage(matrixRoom)
  const lastEnduserMessageId =
    lastEnduserMessageEvent && lastEnduserMessageEvent.getId()
  const lastMessageEvent = getLastMessage(matrixRoom)
  const lastMessage = formatLastMessageEvent(lastMessageEvent)
  const lastMessageIsNote = isAgentNoteEvent(lastMessageEvent)
  let lastMessageSender = getMessageDisplayName(lastMessageEvent)
  const lastMessageSenderId = getMessageUserId(lastMessageEvent)
  // Matrix userIds are long and ugly. If the chat user doesnt have a name specified,
  // then rather just show Customer or Agent
  if (isUserId(lastMessageSender)) {
    lastMessageSender = isEndUser(lastMessageSender)
      ? 'Customer'
      : app.t('Agent')
  } else if (isBotUser(lastMessageSenderId)) {
    lastMessageSender = CHAT_ADMIN_NAME
  }

  const firstEnduserId = getFirstEndUserId(matrixRoom)
  const lastActivityAt = getLastMessageReceivedOrRoomCreatedDate(matrixRoom)
  const agentIds = getParticipatingAgentIds(matrixRoom)
  const title = getTitle(matrixRoom)
  const messageCount = getMessageCount(matrixRoom)
  const lastActivityAtInHuman = timeInHuman(lastActivityAt)
  const assignedAgentId = getAssignedAgentId(matrixRoom)
  const assignedTeamId = getAssignedTeamId(matrixRoom)
  const assignedPermanentAgentId = getAssignedPermanentAgentId(matrixRoom)
  const assignedType = getAssignedType(matrixRoom)
  const state = stateToString(getState(matrixRoom))
  const isStarred = getIsStarred(matrixRoom)
  const snoozedUntil = getSnoozedUntil(matrixRoom)
  const channelId = getIsChannelId(matrixRoom)
  const stateChangedByAgentId = getStateChangedByAgentId(matrixRoom)
  const stateChangedAt = getStateChangedAt(matrixRoom)
  const updatedAt = getUpdatedAt(matrixRoom)
  const assignedAt = getAssignedAt(matrixRoom)
  const unreadAt = getUnreadAt(matrixRoom)
  const readAt = getReadAt(matrixRoom)
  const expiryEvent = getExpiryEvent(matrixRoom)
  const bridgeType = getBridgeType(matrixRoom)
  const bridgeEvent = getBridgeEvent(matrixRoom)

  // Placeholder fields for when we implementing the remainder of the folder rules
  // for live chat
  const draftAgentIds = []
  const mentionAgentIds = []
  const isRated = false
  const lastRating = null
  const tagIds = []
  // Rules seems to use "labels", but the rest of the system
  // is using tagIds. Tbh, label id should be a slug of the label
  // name which would dramatically reduce complexity
  const labels = []
  const channelType = 'Widget'
  const interactionCount = 0

  // Currently rooms dont have priorities so the value is derrived from the isStarrred
  // status
  const priority = isStarred ? 4000 : 2000

  const linkedExternalResources = getLinkedExternalResources(matrixRoom)

  if (!roomId) return null
  return {
    id: roomId,
    matrixRoomId,
    agentIds,
    assignedAgentId,
    assignedPermanentAgentId,
    assignedTeamId,
    assignedType,
    assignedAt,
    channelId,
    isStarred,
    lastActivityAt,
    lastActivityAtInHuman,
    lastMessageEventId: lastMessageEvent?.getId(),
    lastMessage,
    lastMessageIsNote,
    lastEnduserMessageId,
    linkedExternalResources,
    firstEnduserId,
    lastMessageSenderId,
    lastMessageSender,
    messageCount,
    snoozedUntil,
    state,
    title,
    draftAgentIds,
    mentionAgentIds,
    isRated,
    lastRating,
    tagIds,
    labels, // Remove this shit asap!
    priority,
    channelType,
    stateChangedByAgentId,
    stateChangedAt,
    interactionCount,
    updatedAt,
    unreadAt,
    readAt,
    expiryEvent,
    bridgeType,
    bridgeEvent,
  }
}

export function buildRoom(matrixRoom, currentRoom) {
  return mergeFields(recomputeFields(getRoomData(matrixRoom)), currentRoom)
}

function convertSnoozedUntilToTimestamp(snoozedUntil) {
  if (snoozedUntil) {
    if (snoozedUntil.getTime) {
      return snoozedUntil.getTime()
    } else if (snoozedUntil === SNOOZED_INDEFINITELY) {
      return SNOOZED_INDEFINITELY_TIMESTAMP
    }
  }
  return null
}

// This method calculates the derived values on a room when a field changes
// For example when we change the state, we also need to update the stateChangedByAgentId
// field. The backed also does this, but some of these derived fields are required for our
// optimistic updates to correctly calculate folder diffs. This basically gives us the
// ability to emulate what the backend will change on this room when an update occures
export function transformRoom(room, currentUser) {
  const transformedRoom = room
  if (transformedRoom.state) {
    transformedRoom.snoozedUntil = convertSnoozedUntilToTimestamp(
      transformedRoom.snoozedUntil
    )
    transformedRoom.stateChangedByAgentId = currentUser.id
  }
  if (
    transformedRoom.isRead === false ||
    transformedRoom.state ||
    (transformedRoom.assignedAgentId &&
      transformedRoom.assignedAgentId !== currentUser.id) ||
    (!transformedRoom.assignedAgentId && transformedRoom.assignedType)
  ) {
    transformedRoom.unreadAt = new Date().getTime()
  }
  if (transformedRoom.isRead === true) {
    transformedRoom.readAt = new Date().getTime()
  }
  return transformedRoom
}

export function buildEvents(updatedRoom, state) {
  const room = selectRoomsById(state)[updatedRoom.id] || {}
  const events = []
  if (updatedRoom.state) {
    events.push({
      type: 'g.state',
      stateKey: '',
      content: {
        id: stateToInt(updatedRoom.state),
        snoozed_until: updatedRoom.snoozedUntil,
        last_updated: new Date().getTime(),
      },
    })
  }
  const hasAssignmentUpdate = [
    'assignedAgentId',
    'assignedTeamId',
    'assignedType',
  ].some(key => updatedRoom[key] !== undefined)
  if (hasAssignmentUpdate) {
    events.push({
      type: 'g.assign',
      stateKey: '',
      content: {
        agent_id: updatedRoom.assignedAgentId,
        team_id: updatedRoom.assignedTeamId,
        type: updatedRoom.assignedType,
        permanent_agent_id:
          room.assignedPermanentAgentId || updatedRoom.assignedAgentId,
        last_updated: new Date().getTime(),
      },
    })
    if (
      updatedRoom.assignedAgentId &&
      !room.agentIds.includes(updatedRoom.assignedAgentId)
    ) {
      events.push({
        type: 'g.participants',
        stateKey: '',
        content: {
          agent_ids: [...room.agentIds, updatedRoom.assignedAgentId],
          last_updated: new Date().getTime(),
        },
      })
    }
  }

  if (updatedRoom.isRead !== undefined) {
    const client = getMatrixClient()
    const agentId = getAgentIdFromMatrixUserId(client.getUserId())
    events.push({
      type: 'g.state.read',
      stateKey: agentId,
      content: {
        is_read: updatedRoom.isRead,
        updated_at: new Date().getTime(),
        suppress_audio_notification: !!updatedRoom.ignoreNextClose,
      },
    })
  }

  if (updatedRoom.linkedExternalResources !== undefined) {
    updatedRoom.linkedExternalResources.records.forEach(linkedResource => {
      const { external_id: externalId, provider, removed } = linkedResource
      const existingResource = room.linkedExternalResources?.records.find(
        ({
          id: existingId,
          provider: existingProvider,
          removed: existingRemoved,
        }) => {
          return (
            externalId === existingId &&
            provider === existingProvider &&
            removed === existingRemoved
          )
        }
      )
      if (!existingResource) {
        events.push({
          type: `g.integrations.${provider}`,
          stateKey: externalId,
          content: linkedResource,
        })
      }
    })
  }
  return events
}

export function getFirstEndUserId(room) {
  const joinEvents = getMemberJoinEvents(room)
  const agentJoinEvents = []
  joinEvents.forEach(matrixEvent => {
    if (isEndUser(matrixEvent.event.state_key)) {
      agentJoinEvents.push(matrixEvent)
    }
  })
  if (agentJoinEvents.length > 0) {
    const firstJoinedAgentEvent = agentJoinEvents.sort(
      byProp('event.origin_server_ts')
    )[0]
    return firstJoinedAgentEvent.event.state_key
  }
  return null
}

export async function getRoomById(roomId) {
  const client = getMatrixClient()
  const room = await client.getRoom(roomId)

  if (!room) {
    return inviteAndJoinRoom(roomId)
  } else if (!isCurrentUserInvited(room)) {
    return inviteAndJoinRoom(roomId)
  } else if (!isCurrentUserMember(room)) {
    return joinRoom(roomId)
  }
  return room
}

export async function getRoomIdForAlias(fullRoomAlias) {
  const client = getMatrixClient()
  const { room_id: roomId } = await client.resolveRoomAlias(fullRoomAlias)
  return roomId
}

export async function loadRoomUntilLastMessage(roomId) {
  const client = getMatrixClient()
  let room = await getRoomById(roomId)
  if (!room) return null
  if (room.timeline.some(e => e.getType() === 'm.room.message')) return room

  while (room.oldState.paginationToken !== null) {
    // If we have the last message, then exit
    if (room.timeline.some(e => e.getType() === 'm.room.message')) break
    // eslint-disable-next-line no-await-in-loop
    room = await client.scrollback(room, 50)
  }
  return room
}

export function getRoomLinkUrl(room, event) {
  const { location: { href = '' } = {} } = window || {}
  let url = `${config.appUrl}/chats/?conversationId=${getRoomId(room)}`
  if (href.includes('/chats')) {
    url = href
  }

  if (event) url = replaceParamInUrl('eventId', event.getId(), new URL(url))
  return url
}

export const getFileSizeLimit = room => {
  const bridgeType = getBridgeType(room)

  if (bridgeType) {
    return config.chatFileSizeLimits[bridgeType]
  }

  // default to matrix
  return config.chatFileSizeLimits.matrix
}

export function sendTranscript(matrixRoomId) {
  const client = getMatrixClient()
  client.sendEvent(matrixRoomId, 'g.createtranscript', {})
}

export function getStickerContentById(room, stickerId) {
  const stickerContent = room.currentState.getStateEvents(
    'g.sticker',
    stickerId
  )
  if (stickerContent) return stickerContent.getContent()
  return null
}

export const isEventRead = (room, eventId) => {
  return room.hasUserReadEvent(getMatrixClient().getUserId(), eventId)
}

export const isReadReceiptEventForCurrentUser = event => {
  if (!event) return false
  if (event.getType() !== 'm.receipt') return false

  const myUserId = getMatrixClient().getUserId()
  const content = event.getContent()
  const isReceiptForSelf =
    Object.keys(content).filter(eid => {
      return Object.keys(content[eid]['m.read']).includes(myUserId)
    }).length > 0

  return isReceiptForSelf
}
