export const UPDATE_USER_PREFERENCES_REQUEST =
  'currentUser/UPDATE_USER_PREFERENCES_REQUEST'
export const UPDATE_USER_PREFERENCES_SUCCESS =
  'currentUser/UPDATE_USER_PREFERENCES_SUCCESS'
export const UPDATE_USER_PREFERENCES_FAIL =
  'currentUser/UPDATE_USER_PREFERENCES_FAIL'

export const CURRENT_USER_CHANGED = 'currentUser/CURRENT_USER_CHANGED'

export const UPDATE_CURRENT_USER = 'currentUser/UPDATE_CURRENT_USER'
export const UPDATE_CURRENT_USER_SUCCESS =
  'currentUser/UPDATE_CURRENT_USER_SUCCESS'
export const UPDATE_CURRENT_USER_FAILED =
  'currentUser/UPDATE_CURRENT_USER_FAILED'

export const RESEND_CONFIRMATION_TOKEN = 'currentUser/RESEND_CONFIRMATION_TOKEN'

export const FETCH_CURRENT_USER = 'currentUser/FETCH_CURRENT_USER'
export const FETCH_CURRENT_USER_STARTED =
  'currentUser/FETCH_CURRENT_USER_STARTED'
export const FETCH_CURRENT_USER_SUCCESS =
  'currentUser/FETCH_CURRENT_USER_SUCCESS'
export const FETCH_CURRENT_USER_FAILED = 'currentUser/FETCH_CURRENT_USER_FAILED'

export const UPLOAD_CURRENT_USER_AVATAR =
  'currentUser/UPLOAD_CURRENT_USER_AVATAR'
export const UPLOAD_CURRENT_USER_AVATAR_SUCCESS =
  'currentUser/UPLOAD_CURRENT_USER_AVATAR_SUCCESS'
export const UPLOAD_CURRENT_USER_AVATAR_FAILED =
  'currentUser/UPLOAD_CURRENT_USER_AVATAR_FAILED'

export const FETCH_DEFAULT_NOTIFICATION_PREFERENCES =
  'currentUser/FETCH_DEFAULT_NOTIFICATION_PREFERENCES'
export const FETCH_DEFAULT_NOTIFICATION_PREFERENCES_SUCCESS =
  'currentUser/FETCH_DEFAULT_NOTIFICATION_PREFERENCES_SUCCESS'
export const FETCH_DEFAULT_NOTIFICATION_PREFERENCES_FAILED =
  'currentUser/FETCH_DEFAULT_NOTIFICATION_PREFERENCES_FAILED'

export const FETCH_NOTIFICATION_PREFERENCES_PAGE =
  'currentUser/FETCH_NOTIFICATION_PREFERENCES_PAGE'
export const FETCH_NOTIFICATION_PREFERENCES_PAGE_STARTED =
  'currentUser/FETCH_NOTIFICATION_PREFERENCES_PAGE_STARTED'
export const FETCH_NOTIFICATION_PREFERENCES_PAGE_SUCCESS =
  'currentUser/FETCH_NOTIFICATION_PREFERENCES_PAGE_SUCCESS'
export const FETCH_NOTIFICATION_PREFERENCES_PAGE_FAILED =
  'currentUser/FETCH_NOTIFICATION_PREFERENCES_PAGE_FAILED'

export const FETCH_NOTIFICATION_PREFERENCES =
  'currentUser/FETCH_NOTIFICATION_PREFERENCES'
export const FETCH_NOTIFICATION_PREFERENCES_STARTED =
  'currentUser/FETCH_NOTIFICATION_PREFERENCES_STARTED'
export const FETCH_NOTIFICATION_PREFERENCES_SUCCESS =
  'currentUser/FETCH_NOTIFICATION_PREFERENCES_SUCCESS'
export const FETCH_NOTIFICATION_PREFERENCES_FAILED =
  'currentUser/FETCH_NOTIFICATION_PREFERENCES_FAILED'

export const UPDATE_NOTIFICATION_PREFERENCES =
  'currentUser/UPDATE_NOTIFICATION_PREFERENCES'
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS =
  'currentUser/UPDATE_NOTIFICATION_PREFERENCES_SUCCESS'
export const UPDATE_NOTIFICATION_PREFERENCES_FAILED =
  'currentUser/UPDATE_NOTIFICATION_PREFERENCES_FAILED'

export const FETCH_PREFERENCES = 'currentUser/FETCH_PREFERENCES'
export const FETCH_PREFERENCES_SUCCESS = 'currentUser/FETCH_PREFERENCES_SUCCESS'
export const FETCH_PREFERENCES_FAILED = 'currentUser/FETCH_PREFERENCES_FAILED'

export const UPDATE_PREFERENCES = 'currentUser/UPDATE_PREFERENCES'
export const UPDATE_PREFERENCES_SUCCESS =
  'currentUser/UPDATE_PREFERENCES_SUCCESS'
export const UPDATE_PREFERENCES_FAILED = 'currentUser/UPDATE_PREFERENCES_FAILED'

export const FETCH_TWO_FACTOR_AUTHENTICATION_CODE =
  'currentUser/FETCH_TWO_FACTOR_AUTHENTICATION_CODE'
export const FETCH_TWO_FACTOR_AUTHENTICATION_CODE_STARTED =
  'currentUser/FETCH_TWO_FACTOR_AUTHENTICATION_CODE_STARTED'
export const FETCH_TWO_FACTOR_AUTHENTICATION_CODE_SUCCESS =
  'currentUser/FETCH_TWO_FACTOR_AUTHENTICATION_CODE_SUCCESS'
export const FETCH_TWO_FACTOR_AUTHENTICATION_CODE_FAILED =
  'currentUser/FETCH_TWO_FACTOR_AUTHENTICATION_CODE_FAILED'
