const basicPageFields = `
  id
  name
  subscribed
  mailbox_id
  about
  picture
  uid
  widget_ids
  instagram_account_id
  connected_bridge_types
  available_bridge_types
`

export const pagesQuery = () => `
query FacebookPagesQuery($version: Int!, $bridgeType: String) {
  facebookPages(version: $version, bridgeType: $bridgeType) {
    ${basicPageFields}
  }
}
`

export const updatePageMutation = () => `
mutation UpdateFacebookPageSubscriptions($version: Int!, $subscriptions: [FacebookPageSubscriptionInput!]!) {
  updateFacebookPageSubscriptions(version: $version, subscriptions: $subscriptions) {
    ${basicPageFields}
  }
}
`
