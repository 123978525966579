import { createSelector } from 'reselect'
import { buildId } from 'util/globalId'
import { selectAccount } from './selectAccount'

export const selectAccountId = createSelector(
  selectAccount,
  account => account.id
)

export const selectAccountGlobalId = createSelector(selectAccount, account =>
  buildId('Account', account.id)
)
