import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.stripe || emptyObj

export const selectSubscriptions = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.subscriptions
)

export const selectSubscriptionsByCustomerId = createBasicSelector(
  selectSubscriptions,
  subscriptions => subscriptions.byCustomerId || emptyObj
)

export const selectSubscriptionsForCustomerId = createCachedSelector(
  selectSubscriptionsByCustomerId,
  (state, customerId) => customerId,
  (byCustomerId, customerId) => byCustomerId[customerId] || emptyObj
)((state, customerId) => customerId || 'unknown')

export const selectSubscriptionForId = createCachedSelector(
  (state, { customerId }) =>
    selectSubscriptionsForCustomerId(state, customerId),
  (state, { subscriptionId }) => subscriptionId,
  (subscriptionsData, subscriptionId) =>
    subscriptionsData.subscriptions.find(
      subscription => subscription.id === subscriptionId
    ) || emptyObj
)((state, { customerId }) => customerId || 'unknown')
