export const responseQuery = `
    query EphemeralJobResult($id: ID!) {
      ephemeralJobResult(id: $id) {
        state
        value
      }
    }
  `

export const asyncRpcQuery = `
  mutation AsyncRpc(
    $service: String!,
    $method: String!,
    $params: JSON,
  ) {
    asyncRpc(input: {
      service: $service,
      method: $method,
      params: $params
    }) {
      jobId
    }
  }
`

export const getConversationSuggestionsQuery = `
  query AiConversationSuggestions($conversationId: ID) {
    aiConversationSuggestions(filter: {
      conversationId: $conversationId
    }) {
      nodes {
        id
        conversationId
        value
        type
      }
    }
  }
`

export const upsertConversationSuggestionQuery = `
  mutation AiConversationSuggestionUpsert(
    $suggestionType: String,
    $suggestionId: ID,
    $conversationId: ID,
  ) {
    aiConversationSuggestionUpsert(input: {
      suggestionType: $suggestionType
      suggestionId: $suggestionId
      conversationId: $conversationId
    }) {
      errors {
        message
        path
      }
    }
  }
`

export const getSoucesDataQuery = `
  query FetchAiReplySoucesByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      id
      ... on Article {
        title
        url
      }
      ... on Conversation {
        subject
      }
    }
  }
`
