import { emptyObj } from 'util/objects'
import { FETCH_CONVERSATION_CONTACT_SUCCESS } from '../types'

export default function contactIdsByCustomerId(
  state = emptyObj,
  { type, meta: { contactId, customerId } = {} }
) {
  if (type === FETCH_CONVERSATION_CONTACT_SUCCESS) {
    return { ...state, [customerId]: contactId }
  }
  return state
}
