import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import { selectCurrentPlansByType } from './selectCurrentPlansByType'

export const selectCurrentPlanByType = createCachedSelector(
  selectCurrentPlansByType,
  plans => {
    return plans[0] || emptyObj
  }
)((_state, type) => type || 'unknown')
