import { createBasicSelector } from 'util/redux'
import { selectCurrentUserIsAdmin } from 'ducks/currentUser/selectors'
import { selectIsNewAccountSinceDate } from 'ducks/accounts/selectors/selectIsNewAccountSinceDate'
import { selectAppliedDiscount } from './selectAppliedDiscount'
import { selectIsTrial } from './selectIsTrial'
import { selectIsBillingSetupPaymentDrawerOpen } from './selectIsBillingSetupPaymentDrawerOpen'

export const selectShouldShowDiscountLockout = createBasicSelector(
  selectIsTrial,
  selectAppliedDiscount,
  selectCurrentUserIsAdmin,
  // There are a few existing discounts in the system and we want
  // to prevent those from potentially showing the lock out drawer
  state => selectIsNewAccountSinceDate(state, '2024-01-30T00:00:00'),
  selectIsBillingSetupPaymentDrawerOpen,
  (
    isTrial,
    discount,
    isAdmin,
    isNewAccount,
    isBillingSetupPaymentDrawerOpen
  ) => {
    return (
      isNewAccount &&
      isAdmin &&
      isTrial &&
      !isBillingSetupPaymentDrawerOpen &&
      discount?.coupon
    )
  }
)
