import { selectCustomFieldsForCategoryId } from 'ducks/crm/customFields/selectors'
import { selectCustomFieldCategoryForKey } from 'ducks/crm/customFieldCategories/selectors'
import { getValueForCustomFieldFromCustomerObject } from 'ducks/integrations/recharge/utils'
import { doUpdateCustomFieldValues } from 'ducks/crm/contacts/operations/updating'
import { UPDATE_CUSTOMER_REQUEST } from 'ducks/integrations/recharge/types'
import { doSdkRequest } from 'ducks/requests/operations'

import debug from 'util/debug'
import doFetchCustomerByEmail from 'ducks/integrations/recharge/operations/doFetchCustomerByEmail'
import doFetchCustomer from 'ducks/integrations/recharge/operations/doFetchCustomer'
import doFetchSubscriptions from 'ducks/integrations/recharge/operations/doFetchSubscriptions'

export default function doUpdateRechargeCustomFieldValues({
  integrationId,
  contactId,
  customerId,
  email,
  keyPrefix,
}) {
  return doSdkRequest(
    UPDATE_CUSTOMER_REQUEST,
    async ({ dispatch, getState }) => {
      const state = getState()
      let customer = null

      const customFieldCategory = selectCustomFieldCategoryForKey(state, {
        key: keyPrefix,
      })
      const customFields = selectCustomFieldsForCategoryId(state, {
        categoryId: customFieldCategory?.id,
      })

      if (customerId) {
        const { response, code } = await dispatch(
          doFetchCustomer(integrationId, customerId)
        )

        if (code !== 200) {
          debug(response, code)
          return {}
        }

        customer = response.customer
      } else {
        const { response, code } = await dispatch(
          doFetchCustomerByEmail(integrationId, email)
        )

        if (code !== 200) {
          debug(response, code)
          return {}
        }

        const { customers } = response
        if (!customers.length) {
          return {}
        }

        customer = customers[0]
      }

      const customFieldValues = customFields.map(customField => {
        return {
          id: customField.id,
          value: getValueForCustomFieldFromCustomerObject(
            keyPrefix,
            customer,
            customField.key
          ),
        }
      })

      const data = await dispatch(
        doUpdateCustomFieldValues(contactId, customFieldValues)
      )

      if (customerId) {
        await dispatch(doFetchSubscriptions({ integrationId, customerId }))
      }

      return data
    },
    {
      integrationId,
      contactId,
      customerId,
      email,
      keyPrefix,
    }
  )
}
