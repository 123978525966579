import { createSelector } from 'reselect'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors/base'
import { areArraysEqual } from 'util/arrays'
import { isLimitsExceeded } from '../../util'
import { selectCombinedRemoteLocalUsage } from './selectCombinedRemoteLocalUsage'
import { selectFuturePricingIds } from '../selectFuturePricingIds'
import { selectFutureAvailableFeatures } from '../selectFutureAvailableFeatures'
import { selectCurrentPricingIds } from '../selectCurrentPricingIds'

export const selectShowBillingPlanUsageLimited = createSelector(
  selectCurrentUserIsAdminOrOwner,
  selectCombinedRemoteLocalUsage,
  selectFuturePricingIds,
  selectFutureAvailableFeatures,
  selectCurrentPricingIds,
  (
    isManager,
    billingUsage,
    futurePricingIds,
    futureAvailableFeatures,
    currentPricingIds
  ) => {
    return (
      futurePricingIds.length > 0 &&
      !areArraysEqual(currentPricingIds, futurePricingIds) &&
      isManager &&
      isLimitsExceeded(futureAvailableFeatures, billingUsage)
    )
  }
)
