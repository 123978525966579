import { createBasicSelector } from 'util/redux'
import { selectFuturePhase } from './selectFuturePhase'
import { selectBillingEstimate } from './selectBillingEstimate'

export const selectNextBillDate = createBasicSelector(
  selectBillingEstimate,
  selectFuturePhase,
  (estimate, futurePhase) => {
    const { nextBillDate } = estimate || {}
    const { startDate } = futurePhase || {}

    return nextBillDate || startDate || new Date().getTime() / 1000
  }
)
