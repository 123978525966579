import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import { FETCH_ORDERS_REQUEST } from '../types'
import { selectOrdersForCustomerId } from '../selectors'
import { orderPartial } from './partials'

const fetchOrdersQuery = `
  query ShopifyFetchOrders(
    $customerId: ID!,
    $limit: Int!
  ) {
    customer(id: $customerId) {
      id
      orders(first: $limit, reverse: true) {
        edges {
          node {
            ${orderPartial}
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`

const doFetchOrders = (
  integrationId,
  customerGid,
  { limit, skipLoaded = true, isMoreFetched } = {}
) => (dispatch, getState) => {
  const state = getState()
  const orders = selectOrdersForCustomerId(state, customerGid)

  if (skipLoaded && orders) {
    return Promise.resolve({})
  }

  const variables = { customerId: customerGid, limit }
  return dispatch(
    doGraphqlRequest(FETCH_ORDERS_REQUEST, fetchOrdersQuery, variables, {
      graphqlFunc: graphql(integrationId),
      meta: {
        isMoreFetched,
      },
    })
  )
}

export default doFetchOrders
