import { customFieldValueNodeQuery } from '../../../customFields/operations/fetching'

export const contactCustomFieldValuesSubQuery = `
  customFieldValues(first: 20, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    ${customFieldValueNodeQuery}
  }
`
