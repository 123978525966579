export const RESEND_INVITATION = 'agents/RESEND_INVITATION'
export const REVOKE_INVITATION = 'agents/REVOKE_INVITATION'
export const CREATE_INVITATION = 'agents/CREATE_INVITATION'
export const CREATE_INVITATION_BULK = 'agents/CREATE_INVITATION/BULK'
export const UPDATE_USER = 'agents/UPDATE_USER'
export const RESET_PASSWORD = 'agents/RESET_PASSWORD'
export const SAVE_AGENT_DRAFTS = 'agents/SAVE_AGENT_DRAFTS'
export const CLEAR_AGENTS_DRAFTS = 'agents/CLEAR_AGENTS_DRAFTS'

export const RESET_LOGIN_ATTEMPTS = 'agents/RESET_LOGIN_ATTEMPTS'
export const RESET_LOGIN_ATTEMPTS_STARTED =
  'agents/RESET_LOGIN_ATTEMPTS_STARTED'
export const RESET_LOGIN_ATTEMPTS_SUCCESS =
  'agents/RESET_LOGIN_ATTEMPTS_SUCCESS'
export const RESET_LOGIN_ATTEMPTS_FAILED = 'agents/RESET_LOGIN_ATTEMPTS_FAILED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_STARTED = 'CHANGE_PASSWORD_STARTED'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

export const AGENT_SEND_ADMIN_EMAIL_NOTIFICATION =
  'ai/AGENT_SEND_ADMIN_EMAIL_NOTIFICATION'
export const AGENT_SEND_ADMIN_EMAIL_NOTIFICATION_STARTED =
  'ai/AGENT_SEND_ADMIN_EMAIL_NOTIFICATION_STARTED'
export const AGENT_SEND_ADMIN_EMAIL_NOTIFICATION_SUCCESS =
  'ai/AGENT_SEND_ADMIN_EMAIL_NOTIFICATION_SUCCESS'
export const AGENT_SEND_ADMIN_EMAIL_NOTIFICATION_FAILED =
  'ai/AGENT_SEND_ADMIN_EMAIL_NOTIFICATION_FAILED'
