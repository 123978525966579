import { toObject, uniq } from 'util/arrays'

import { FETCH_BOARD_DETAILS_SUCCESS, FETCH_CARD_SUCCESS } from '../types'

const initialState = {}

export default function reduceMembers(
  state = initialState,
  { meta: { boardId } = {}, payload: { members = [] } = {}, type }
) {
  if (type === FETCH_BOARD_DETAILS_SUCCESS || type === FETCH_CARD_SUCCESS)
    return {
      ...state,
      ...toObject(
        members.map(member => ({
          ...member,
          idBoards: uniq([
            ...((state[member.id] && state[member.id].idBoards) || []),
            boardId,
          ]),
        }))
      ),
    }
  return state
}
