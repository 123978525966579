import Bugsnag from '@bugsnag/js'
import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'
import { ALL_REPOSITORIES } from '../constants'
import gitHubGraphQl from '../gitHubGraphQl'
import {
  FETCH_INSTALLS_ERROR,
  FETCH_INSTALLS_REQUEST,
  FETCH_INSTALLS_SUCCESS,
} from '../types'

/* TAYO: https://trello.com/c/EWVgrK49
  for some reason github graphql API started erroring out when we query organizations out of the blue
  switching to REST API until they sort it out
  ideally we should switch back to graphql after it's resolved so it's one less API call
  just undo the PR for this commit

  organizations(first:100) {
    nodes {
      __typename
      avatarUrl
      databaseId
      id
      login
      name
      viewerCanAdminister
      viewerIsAMember
    }
  }
*/
const QUERY_USER = `
  query {
    viewer {
      __typename
      avatarUrl
      databaseId
      id
      isViewer
      login
      name
    }
  }
`

export default function doFetchGitHubInstalls() {
  return (dispatch, getState) => {
    const token = oauthTokenSelector(getState())
    dispatch({
      type: FETCH_INSTALLS_REQUEST,
      meta: { loading: { [ALL_REPOSITORIES]: true } },
    })
    Promise.all([
      integrationApi
        .get(
          token,
          'proxy/github/user/installations',
          {},
          { Accept: 'application/vnd.github.machine-man-preview+json' }
        )
        .then(({ json: { installations } }) => {
          return Promise.all(
            installations.map(installation => {
              const { id } = installation
              return integrationApi
                .get(
                  token,
                  // 100 is the max per page, we should use API search instead to get repositories in future: https://trello.com/c/bSTBQcnn
                  `proxy/github/user/installations/${id}/repositories?per_page=100`,
                  {},
                  {
                    Accept: 'application/vnd.github.machine-man-preview+json',
                  }
                )
                .then(({ json: { repositories } }) => {
                  return {
                    ...installation,
                    repositories,
                  }
                })
            })
          )
        }),
      gitHubGraphQl(token, QUERY_USER),
      // https://docs.github.com/en/rest/reference/orgs#list-organization-memberships-for-the-authenticated-user
      // using `user/memberships/orgs` and not `user/orgs` because we need to know the role and state for the user
      integrationApi.get(
        token,
        'proxy/github/user/memberships/orgs',
        {},
        { Accept: 'application/vnd.github.machine-man-preview+json' }
      ),
    ])
      .then(
        ([
          installs,
          {
            data: { viewer },
          },
          { json: organisationMembership },
        ]) => {
          const installsWithOutRepositories = installs.map(install => ({
            ...install,
            repositories: install.repositories.map(
              ({ node_id: nodeId }) => nodeId
            ),
          }))

          const allRepositories = installs.reduce(
            (result, { repositories }) => {
              result.push(
                ...repositories.map(repository => ({
                  ...repository,
                  __typename: 'Repository',
                  owner: {
                    ...repository.owner,
                    id: repository.owner.node_id,
                  },
                  id: repository.node_id,
                }))
              )
              return result
            },
            []
          )

          // modify response to be the same as if it was from graphql organization query
          // so it does not break the rest of the code
          const graphQLizedOrganizations = organisationMembership.map(
            ({
              state,
              role,
              organization: {
                avatar_url: avatarUrl,
                id,
                node_id: nodeId,
                login,
              } = {},
            }) => {
              // would have camelized the raw response and just returned it but
              // there are discrepancies between graphql and rest api responses
              // e.g. REST.id = GQL.databaseId, REST.nodeId = GQL.id
              // again, duct taping this so it can be reverted back to github gql without breaking the rest of the code
              return {
                __typename: 'Organization',
                avatarUrl,
                databaseId: id,
                id: nodeId,
                login,
                name: login,
                // for these two, tried to match the field definition in graphql doc to figure out the right REST api field
                // https://docs.github.com/en/graphql/reference/objects#organization
                viewerCanAdminister: role === 'admin',
                viewerIsAMember: state === 'active',
              }
            }
          )

          dispatch({
            type: FETCH_INSTALLS_SUCCESS,
            meta: { loading: { [ALL_REPOSITORIES]: false } },
            payload: {
              installs: installsWithOutRepositories,
              nodes: [viewer, ...graphQLizedOrganizations, ...allRepositories],
            },
          })
        }
      )
      .catch(error => {
        dispatch({
          error: true,
          payload: error,
          meta: { loading: { [ALL_REPOSITORIES]: false } },
          type: FETCH_INSTALLS_ERROR,
        })

        Bugsnag.notify(error)
      })
  }
}
