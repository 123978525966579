import { createBasicSelector } from 'util/redux'
import { selectBooleanPreferenceByName } from './selectBooleanPreferenceByName'

export const selectPrefersAgentsCanMoveToInaccessibleMailboxes = createBasicSelector(
  state =>
    selectBooleanPreferenceByName(
      state,
      'prefers_agents_can_move_to_inaccessible_mailboxes'
    )
)
