import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectRequestByType } from 'ducks/requests/selectors'
import { emptyObj, pick } from 'util/objects'
import {
  selectCurrentEntitiesById,
  selectPendingEntitiesById,
} from '../selectors'

export default function useFetchEntitiesByIds(
  ids,
  entityType,
  fetchActionCreator,
  fetchActionType,
  targetStore = 'current'
) {
  const dispatch = useDispatch()

  useEffect(
    () => {
      if (ids && ids.length > 0) {
        dispatch(fetchActionCreator({ ids }))
      }
    },
    [dispatch, fetchActionCreator, ids]
  )

  const entities = useSelector(state => {
    const byId =
      targetStore === 'current'
        ? selectCurrentEntitiesById(state, entityType)
        : selectPendingEntitiesById(state, entityType)
    return pick(ids, byId || emptyObj)
  }, shallowEqual)

  const requestState = useSelector(state =>
    selectRequestByType(state, fetchActionType)
  )

  return {
    entities,
    requestState,
  }
}
