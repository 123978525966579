export const START_TRIAL = 'billing/START_TRIAL'
export const START_TRIAL_STARTED = 'billing/START_TRIAL_STARTED'
export const START_TRIAL_SUCCESS = 'billing/START_TRIAL_SUCCESS'
export const START_TRIAL_FAILED = 'billing/START_TRIAL_FAILED'

export const UPDATE_SUBSCRIPTION_STARTED = 'billing/UPDATE_SUBSCRIPTION_STARTED'
export const UPDATE_SUBSCRIPTION_SUCCESS = 'billing/UPDATE_SUBSCRIPTION_SUCCESS'
export const UPDATE_SUBSCRIPTION_FAILED = 'billing/UPDATE_SUBSCRIPTION_FAILED'
export const UPDATE_SUBSCRIPTION = 'billing/UPDATE_SUBSCRIPTION'

export const UPDATE_SUBSCRIPTION_REQUEST_SUCCESS =
  'billing/UPDATE_SUBSCRIPTION_REQUEST_SUCCESS'
export const UPDATE_SUBSCRIPTION_REQUEST_FAILED =
  'billing/UPDATE_SUBSCRIPTION_REQUEST_FAILED'
export const UPDATE_SUBSCRIPTION_REQUEST = 'billing/UPDATE_SUBSCRIPTION_REQUEST'
export const CANCEL_SUBSCRIPTION = 'billing/CANCEL_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION_STARTED = 'billing/CANCEL_SUBSCRIPTION_STARTED'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'billing/CANCEL_SUBSCRIPTION_SUCCESS'

export const FETCH_PLANS_REQUEST = 'billing/FETCH_PLANS_REQUEST'
export const FETCH_PLANS_SUCCESS = 'billing/FETCH_PLANS_SUCCESS'
export const FETCH_PLANS_FAIL = 'billing/FETCH_PLANS_FAIL'

export const FETCH_BILLING_REQUEST = 'billing/FETCH_BILLING_REQUEST'
export const FETCH_BILLING_SUCCESS = 'billing/FETCH_BILLING_SUCCESS'
export const FETCH_BILLING_FAIL = 'billing/FETCH_BILLING_FAIL'

export const FETCH_BILLING_DATA_V1 = 'billing/FETCH_BILLING_DATA_V1'
export const FETCH_BILLING_DATA_V1_REQUEST =
  'billing/FETCH_BILLING_DATA_V1_REQUEST'
export const FETCH_BILLING_DATA_V1_SUCCESS =
  'billing/FETCH_BILLING_DATA_V1_SUCCESS'
export const FETCH_BILLING_DATA_V1_FAILED =
  'billing/FETCH_BILLING_DATA_V1_FAILED'

export const FETCH_BILLING_DATA_V2 = 'billing/FETCH_BILLING_DATA_V2'
export const FETCH_BILLING_DATA_V2_REQUEST =
  'billing/FETCH_BILLING_DATA_V2_REQUEST'
export const FETCH_BILLING_DATA_V2_SUCCESS =
  'billing/FETCH_BILLING_DATA_V2_SUCCESS'
export const FETCH_BILLING_DATA_V2_FAILED =
  'billing/FETCH_BILLING_DATA_V2_FAILED'

export const FETCH_BILLING_SETUP_INTENTS = 'billing/FETCH_BILLING_SETUP_INTENTS'
export const FETCH_BILLING_SETUP_INTENTS_REQUEST =
  'billing/FETCH_BILLING_SETUP_INTENTS_REQUEST'
export const FETCH_BILLING_SETUP_INTENTS_SUCCESS =
  'billing/FETCH_BILLING_SETUP_INTENTS_SUCCESS'
export const FETCH_BILLING_SETUP_INTENTS_FAILED =
  'billing/FETCH_BILLING_SETUP_INTENTS_FAILED'

export const CREATE_BILLING_SETUP_INTENT = 'billing/CREATE_BILLING_SETUP_INTENT'
export const CREATE_BILLING_SETUP_INTENT_REQUEST =
  'billing/CREATE_BILLING_SETUP_INTENT_REQUEST'
export const CREATE_BILLING_SETUP_INTENT_SUCCESS =
  'billing/CREATE_BILLING_SETUP_INTENT_SUCCESS'
export const CREATE_BILLING_SETUP_INTENT_FAILED =
  'billing/CREATE_BILLING_SETUP_INTENT_FAILED'

export const SAVE_BILLING_SETUP_INTENT_CONFIRMED =
  'billing/SAVE_BILLING_SETUP_INTENT_CONFIRMED'
export const SAVE_BILLING_SETUP_INTENT_CONFIRMED_REQUEST =
  'billing/SAVE_BILLING_SETUP_INTENT_CONFIRMED_REQUEST'
export const SAVE_BILLING_SETUP_INTENT_CONFIRMED_SUCCESS =
  'billing/SAVE_BILLING_SETUP_INTENT_CONFIRMED_SUCCESS'
export const SAVE_BILLING_SETUP_INTENT_CONFIRMED_FAILED =
  'billing/SAVE_BILLING_SETUP_INTENT_CONFIRMED_FAILED'

export const APP_BILLING_EXPIRED = 'billing/APP_BILLING_EXPIRED'

export const FETCH_BILLING_INVOICES = 'billing/FETCH_BILLING_INVOICES'
export const FETCH_BILLING_INVOICES_STARTED =
  'billing/FETCH_BILLING_INVOICES_STARTED'
export const FETCH_BILLING_INVOICES_SUCCESS =
  'billing/FETCH_BILLING_INVOICES_SUCCESS'
export const FETCH_BILLING_INVOICES_FAILED =
  'billing/FETCH_BILLING_INVOICES_FAILED'

export const FETCH_BILLING_INFORMATION_REQUEST =
  'billing/FETCH_BILLING_INFORMATION_REQUEST'
export const FETCH_BILLING_INFORMATION_SUCCESS =
  'billing/FETCH_BILLING_INFORMATION_SUCCESS'
export const FETCH_BILLING_INFORMATION_FAILED =
  'billing/FETCH_BILLING_INFORMATION_FAILED'

export const UPDATE_BILLING_INFORMATION = 'billing/UPDATE_BILLING_INFORMATION'
export const UPDATE_BILLING_INFORMATION_REQUEST =
  'billing/UPDATE_BILLING_INFORMATION_REQUEST'
export const UPDATE_BILLING_INFORMATION_SUCCESS =
  'billing/UPDATE_BILLING_INFORMATION_SUCCESS'
export const UPDATE_BILLING_INFORMATION_FAILED =
  'billing/UPDATE_BILLING_INFORMATION_FAILED'

export const GET_INVOICE_PDF_LINKS = 'billing/GET_INVOICE_PDF_LINKS'
export const GET_INVOICE_PDF_LINKS_STARTED =
  'billing/GET_INVOICE_PDF_LINKS_STARTED'
export const GET_INVOICE_PDF_LINKS_SUCCESS =
  'billing/GET_INVOICE_PDF_LINKS_SUCCESS'
export const GET_INVOICE_PDF_LINKS_FAILED =
  'billing/GET_INVOICE_PDF_LINKS_FAILED'

export const GET_INVOICE_PREVIEW = 'billing/GET_INVOICE_PREVIEW'
export const GET_INVOICE_PREVIEW_STARTED = 'billing/GET_INVOICE_PREVIEW_STARTED'
export const GET_INVOICE_PREVIEW_SUCCESS = 'billing/GET_INVOICE_PREVIEW_SUCCESS'
export const GET_INVOICE_PREVIEW_FAILED = 'billing/GET_INVOICE_PREVIEW_FAILED'

export const SHOPIFY_BILLING_CONFIRMED = 'billing/SHOPIFY_BILLING_CONFIRMED'

export const CANCEL_DOWNGRADE = 'billing/CANCEL_DOWNGRADE'
export const CANCEL_DOWNGRADE_STARTED = 'billing/CANCEL_DOWNGRADE_STARTED'
export const CANCEL_DOWNGRADE_SUCCESS = 'billing/CANCEL_DOWNGRADE_SUCCESS'
export const CANCEL_DOWNGRADE_FAILED = 'billing/CANCEL_DOWNGRADE_FAILED'

export const CANCEL_DOWNGRADE_REQUEST = 'billing/CANCEL_DOWNGRADE_REQUEST'
export const CANCEL_DOWNGRADE_REQUEST_STARTED =
  'billing/CANCEL_DOWNGRADE_REQUEST_STARTED'
export const CANCEL_DOWNGRADE_REQUEST_SUCCESS =
  'billing/CANCEL_DOWNGRADE_REQUEST_SUCCESS'
export const CANCEL_DOWNGRADE_REQUEST_FAILED =
  'billing/CANCEL_DOWNGRADE_REQUEST_FAILED'
