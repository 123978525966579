export const FETCH_DATA_EXPORTS = 'dataExports/FETCH_DATA_EXPORTS'
export const FETCH_DATA_EXPORTS_REQUEST =
  'dataExports/FETCH_DATA_EXPORTS_STARTED'
export const FETCH_DATA_EXPORTS_SUCCESS =
  'dataExports/FETCH_DATA_EXPORTS_SUCCESS'
export const FETCH_DATA_EXPORTS_FAIL = 'dataExports/FETCH_DATA_EXPORTS_FAILED'

export const CREATE_DATA_EXPORT = 'dataExports/CREATE_DATA_EXPORT'
export const CREATE_DATA_EXPORT_REQUEST =
  'dataExports/CREATE_DATA_EXPORT_STARTED'
export const CREATE_DATA_EXPORT_SUCCESS =
  'dataExports/CREATE_DATA_EXPORT_SUCCESS'
export const CREATE_DATA_EXPORT_FAIL = 'dataExports/CREATE_DATA_EXPORT_FAILED'

export const CANCEL_DATA_EXPORT = 'dataExports/CANCEL_DATA_EXPORT'
export const CANCEL_DATA_EXPORT_REQUEST =
  'dataExports/CANCEL_DATA_EXPORT_STARTED'
export const CANCEL_DATA_EXPORT_SUCCESS =
  'dataExports/CANCEL_DATA_EXPORT_SUCCESS'
export const CANCEL_DATA_EXPORT_FAIL = 'dataExports/CANCEL_DATA_EXPORT_FAILED'

export const DOWNLOAD_DATA_EXPORT = 'dataExports/DOWNLOAD_DATA_EXPORT'
export const DOWNLOAD_DATA_EXPORT_REQUEST =
  'dataExports/DOWNLOAD_DATA_EXPORT_STARTED'
export const DOWNLOAD_DATA_EXPORT_SUCCESS =
  'dataExports/DOWNLOAD_DATA_EXPORT_SUCCESS'
export const DOWNLOAD_DATA_EXPORT_FAIL =
  'dataExports/DOWNLOAD_DATA_EXPORT_FAILED'
