import integrationApi from '../api'

const ENDPOINT_PREFIX = 'proxy/salesforce'
const VERSION = 'v47.0'
const PREFIX = `/services/data/${VERSION}`

function parseProxyResponse({ code, json }) {
  const object = { response: json }
  object.code = code
  if (code === 200 || code === 201) {
    return Promise.resolve(object)
  }
  if (code >= 400) return Promise.reject(object)
  return Promise.reject({ code, response: json })
}

function get(token, path, params) {
  return integrationApi
    .get(token, ENDPOINT_PREFIX + path, params)
    .then(parseProxyResponse)
}

// eslint-disable-next-line no-unused-vars
function put(token, path, params) {
  return integrationApi
    .put(token, ENDPOINT_PREFIX + path, params)
    .then(parseProxyResponse)
}

// eslint-disable-next-line no-unused-vars
function post(token, path, data) {
  return integrationApi
    .post(token, ENDPOINT_PREFIX + path, null, data)
    .then(parseProxyResponse)
}

// eslint-disable-next-line no-unused-vars
function deleteRequest(token, path, data) {
  return integrationApi
    .delete(token, ENDPOINT_PREFIX + path, data)
    .then(parseProxyResponse)
}

export function getRequest(token, path) {
  return get(token, `${PREFIX}/${path}`)
}

export function postRequest(token, path, data) {
  return post(token, `${PREFIX}/${path}`, data)
}

//
export function batchRequest(token, payload) {
  return post(token, `/batch`, JSON.stringify(payload))
}

export function getBatchProperties(token, types) {
  const payload = {}
  types.forEach(type => {
    payload[type] = `${PREFIX}/sobjects/${type}/describe`
  })
  return batchRequest(token, payload)
}

function flatten(array) {
  return array.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  )
}

export function getMatchingObjects(token, email) {
  const promises = []
  const types = ['Contact', 'Lead']
  types.forEach(type => {
    const request = getObjectsByEmail(token, type, email)
    promises.push(request)
  })
  return Promise.allSettled(promises).then(response => {
    const list = flatten(
      response.map(item => {
        return item.value || item.reason
      })
    )

    return {
      errorPayloads: list.filter(item => [400, 401].includes(item.code)),
      successPayloads: list.filter(item => item.code === 200),
    }
  })
}

export function getRelatedObjects(token, object, collection) {
  const { hasMany, belongsTo, typeMap = {} } = collection
  const objectId = object.id
  const objectType = object.type

  const promises = []
  Object.keys(belongsTo).forEach(type => {
    const mappedType = typeMap[type] || type
    const ids = belongsTo[type]
    ids.forEach(id => {
      const request = getRequest(token, `sobjects/${mappedType}/${id}`)
        .then(data => {
          return { ...data, group: type }
        })
        .catch(error => {
          return { ...error, group: type }
        })

      promises.push(request)
    })
  })
  hasMany.forEach(type => {
    const request = getRequest(
      token,
      `sobjects/${objectType}/${objectId}/${type}`
    )
      .then(data => {
        return { ...data, group: type }
      })
      .catch(error => {
        return { ...error, group: type }
      })
    promises.push(request)
  })
  return Promise.all(promises).then(response => {
    return response.filter(item => item.code === 200)
  })
}

export function getObjectsByEmail(token, type, email) {
  return getRequest(token, `sobjects/${type}/email/${email}`).catch(err => {
    if (err.code === 300) {
      const urls = err.response
      const promises = []
      urls.forEach(url => {
        promises.push(get(token, url))
      })
      return Promise.all(promises)
    }
    throw err
  })
}

export function getContactProperties(token) {
  return getRequest(token, `sobjects/Contact/describe`)
}

export function getLeadByEmail(token, email) {
  const url = `${PREFIX}/sobjects/Lead/email/${email}`
  return get(token, url)
}

export function getLeadProperties(token) {
  return getRequest(token, `sobjects/Lead/describe`)
}

export function getProperties(token, type) {
  return getRequest(token, `sobjects/${type}/describe`)
}

export function createLead(token, data) {
  return postRequest(token, `sobjects/Lead`, data)
}
