import { selectWidgetsWithChat } from 'ducks/widgets/selectors'
import {
  selectCurrentQueryId,
  selectCurrentQueryOrderByOrDefault,
} from 'ducks/searches/selectors'
import { selectRoomIdFromUrl } from 'ducks/chat/selectors/rooms'
import {
  queryIdToQuery,
  defaultFolderItemQueryId,
} from 'ducks/searches/utils/query'
import { queryParams } from 'util/params'
import { selectCurrentUser } from 'ducks/currentUser/selectors/base'
import { selectPrefersAllMailboxesSectionVisible } from 'ducks/currentUser/selectors/preferences/selectPrefersAllMailboxesSectionVisible'
import { selectPrefersUnifiedInbox } from 'ducks/currentUser/selectors/preferences/selectPrefersUnifiedInbox'
import { selectCurrentAgents } from 'ducks/agents/selectors'
import {
  selectIsInChat,
  selectIsInInbox,
  selectIsInSettings,
  selectQueryParams,
} from 'selectors/location'
import {
  selectAccessibleCurrentEmailChannels,
  selectChannelTypeFromUrl,
} from 'ducks/channels/selectors'
import { getRawId, buildId } from 'util/globalId'
import {
  BUILD_FROM_WIDGETS,
  TOGGLE_COLLECTION,
  OPEN_ONE_COLLECTION,
  BUILD_INBOX_MENU_FROM_MAILBOXES,
} from '../actionTypes/collections'
import { selectChatFolders, selectFolders } from '../selectors/folders'
import { mapWidgetChannelTypeToPageType } from '../utils'

export function doToggleCollection({ collectionId, event }) {
  return dispatch => {
    if (queryParams().channelId === collectionId) {
      event.preventDefault()
    }
    return dispatch({
      type: TOGGLE_COLLECTION,
      payload: {
        collectionId,
      },
    })
  }
}

export function doOpenOneCollection({ collectionId, event }) {
  return dispatch => {
    if (queryParams().channelId === collectionId) {
      event.preventDefault()
    }
    return dispatch({
      type: OPEN_ONE_COLLECTION,
      payload: {
        collectionId,
      },
    })
  }
}

export function doBuildMenuFromWidgets({ autoRedirect = true } = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const widgets = selectWidgetsWithChat(state)
    const folders = selectChatFolders(state)
    const currentQueryId = selectCurrentQueryId(state)
    const prefersAllMailboxesSectionVisible = selectPrefersAllMailboxesSectionVisible(
      state
    )
    const prefersUnifiedInbox = selectPrefersUnifiedInbox(state)
    const currentChannelType = selectChannelTypeFromUrl(state)
    const orderBy = selectCurrentQueryOrderByOrDefault(state)

    dispatch({
      type: BUILD_FROM_WIDGETS,
      payload: {
        widgets,
        folders,
        prefersAllMailboxesSectionVisible,
        prefersUnifiedInbox,
        channelType: currentChannelType,
        orderBy,
      },
    })
    // Kevin R: I would have preferred dispatching BUILD_FROM_WIDGETS
    // and the doRedirectToCollectionAndFolderById in a batch action
    // but for some reason the redux first router doesnt play well with
    // out batching system
    if (!currentQueryId && autoRedirect) {
      dispatch(doRedirectToCollectionAndFolderById())
    }
  }
}

export function doRedirectToCollectionAndFolderById(
  collectionId = null,
  folderId = null
) {
  return (dispatch, getState) => {
    const state = getState()
    const currentPageQuery = selectQueryParams(state)
    const isInChat = selectIsInChat(state)
    const currentChannelType = selectChannelTypeFromUrl(state)
    const widgets = selectWidgetsWithChat(state).filter(
      w =>
        (collectionId === null || getRawId(collectionId) === w.id) &&
        w.channelType === currentChannelType
    )
    const folders = selectChatFolders(state).filter(
      f => folderId === null || folderId === f.id
    )
    const prefersAllMailboxesSectionVisible = selectPrefersAllMailboxesSectionVisible(
      state
    )
    const prefersUnifiedInbox = selectPrefersUnifiedInbox(state)
    const orderBy = selectCurrentQueryOrderByOrDefault(state)

    if (widgets.length === 0 || folders.length === 0) return
    const query = queryIdToQuery(
      defaultFolderItemQueryId({
        widgets,
        folders,
        prefersAllMailboxesSectionVisible,
        prefersUnifiedInbox,
        pageChannelType: currentChannelType,
        orderBy,
      })
    )
    const conversationId = selectRoomIdFromUrl(state)
    if (conversationId) {
      query.conversationId = conversationId
    }
    const { channelType } =
      widgets.find(w => buildId('Widget', w.id) === query.channelId) || {}
    const currentPageType = mapWidgetChannelTypeToPageType(
      channelType || currentChannelType
    )
    dispatch({
      type: currentPageType,
      payload: {},
      meta: {
        query: {
          ...(isInChat ? currentPageQuery : {}),
          ...query,
        },
      },
    })
  }
}

export function doBuildInboxMenuFromMailboxes() {
  return (dispatch, getState) => {
    const state = getState()
    const isInInbox = selectIsInInbox(state)
    const isInSettings = selectIsInSettings(state)
    const mailboxes = selectAccessibleCurrentEmailChannels(state)
    const folders = selectFolders(state)
    const agents = selectCurrentAgents(state)
    const currentUser = selectCurrentUser(state)

    // Kevin R: 2022-01-31
    // TODO: We need to replace this with a better check that ensures
    // that all the data we need to rebuild the menu has been bootstrapped
    // for now, if your inside inbox, then the data is available. Quick
    // and easy check
    if (!(isInInbox || isInSettings)) return null

    return dispatch({
      type: BUILD_INBOX_MENU_FROM_MAILBOXES,
      payload: {
        folders,
        mailboxes,
        agents,
        currentUser,
      },
    })
  }
}
