import { doAppGraphqlRequest } from 'ducks/requests/operations'

import { FETCH_RECENT_CONVERSATIONS } from './types'

const fetchRecentConversationsQuery = `
  query FetchRecentConversations($contactId: ID!, $orderBy: ConversationOrder) {
    rooms(first: 10, filter: { contactId: $contactId }) {
      nodes {
        agentId
        id
        isRead
        name
        number
        snoozedUntilTimestamp
        state
        updatedTimestamp
        channelId
      }
    }
    conversations(first: 10, filter: { contactId: $contactId }, orderBy: $orderBy) {
      nodes {
        id
        number
        assigned {
          agent {
            id
            username
          }
        }
        subject
        state
        updatedAt
      }
    }
  }
`

export function doFetchRecentConversationsAndRooms(
  contactId,
  sortByCollaboratorCommentAtEnabled
) {
  const requestKey = `fetchRecentConversations-${contactId}`

  const variables = {
    contactId,
  }

  if (sortByCollaboratorCommentAtEnabled) {
    // otherwise use default sort field
    variables.orderBy = {
      field: 'LATEST_COLLABORATOR_COMMENT_AT',
      direction: 'DESC',
    }
  }

  return doAppGraphqlRequest(
    FETCH_RECENT_CONVERSATIONS,
    fetchRecentConversationsQuery,
    variables,
    {
      meta: { contactId, requestKey },
    }
  )
}
