import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'
import {
  FETCH_ASSIGNABLE_USERS_ERROR,
  FETCH_ASSIGNABLE_USERS_REQUEST,
  FETCH_ASSIGNABLE_USERS_SUCCESS,
} from '../types'

export default function doFetchJiraCloudAssignableUsers(cloudId, projectId) {
  return (dispatch, getState) => {
    const meta = { cloudId, projectId }
    const token = oauthTokenSelector(getState())
    dispatch({
      type: FETCH_ASSIGNABLE_USERS_REQUEST,
      meta,
    })
    integrationApi
      .get(
        token,
        `proxy/atlassian/ex/jira/${cloudId}/rest/api/3/user/assignable/search`,
        { project: projectId, maxResults: 500 }
      )
      .then(({ json: payload }) => {
        dispatch({
          type: FETCH_ASSIGNABLE_USERS_SUCCESS,
          meta,
          payload,
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_ASSIGNABLE_USERS_ERROR,
          error: true,
          meta,
          payload: error,
        })
      })
  }
}
