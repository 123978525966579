import { makeEntityAvailable } from '.'

export function makeEntitiesAvailable({ contacts, companies }) {
  return {
    companies:
      companies && Object.values(companies).reduce(makeEntityAvailable, {}),
    contacts:
      contacts && Object.values(contacts).reduce(makeEntityAvailable, {}),
  }
}
