/* eslint-disable no-param-reassign */
import { emptyObj } from 'util/objects'
import { createActionTypeReducer } from 'util/reducers'
import { isBlank } from 'util/strings'

import { SEARCH_CONVERSATIONS_SUCCESS } from 'ducks/searches/actionTypes'

const initialState = {
  byRoomId: emptyObj,
}

export default createActionTypeReducer(
  {
    [SEARCH_CONVERSATIONS_SUCCESS]: (
      draftState,
      {
        payload: {
          rooms: { nodes },
        },
      }
    ) => {
      nodes.forEach(({ internalId, contact }) => {
        const { id, name } = contact || {}
        if (id) {
          if (!draftState.byRoomId[internalId]) {
            draftState.byRoomId[internalId] = {}
          }
          draftState.byRoomId[internalId].id = id
          draftState.byRoomId[internalId].name = isBlank(name)
            ? 'Customer'
            : name
          draftState.byRoomId[internalId].matrixRoomId = internalId
        }
      })
      return draftState
    },
  },
  initialState
)
