import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import {
  selectContactsById,
  selectLatestLoadedRequestKey,
  selectRequestsByKey,
} from './base'
import { selectPageRequestKey } from './fetching'

export const selectPage = createSelector(
  selectPageRequestKey,
  selectRequestsByKey,
  (key, requests) => {
    return requests[key]
  }
)

export const selectLatestLoadedPage = createSelector(
  selectLatestLoadedRequestKey,
  selectRequestsByKey,
  (key, requests) => {
    return requests[key]
  }
)

export function selectLatestLoadedPageContactsIds(state) {
  const page = selectLatestLoadedPage(state)
  return (page && page.ids) || []
}

function mapIdsToContacts(ids, contacts) {
  return ids?.map(id => contacts[id])
}
export const selectLatesetLoadedPageContacts = createSelector(
  selectLatestLoadedPageContactsIds,
  selectContactsById,
  mapIdsToContacts
)

function selectContactIdsForQuery(state, { query }) {
  return selectRequestsByKey(state)[query]?.ids
}
export const selectContactsForQuery = createCachedSelector(
  selectContactIdsForQuery,
  selectContactsById,
  mapIdsToContacts
)((state, { query }) => query)

export function selectLatestLoadedPageHasContacts(state) {
  const ids = selectLatestLoadedPageContactsIds(state)
  return ids.length > 0
}
