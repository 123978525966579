import { contactCustomFieldValuesSubQuery } from './contactCustomFieldValuesSubQuery'

export const fetchContactCustomFieldsByCustomFieldQuery = `
  query ContactByCustomField($after: String, $filter: ContactFilter) {
    contacts(filter: $filter, first: 1) {
      nodes {
        id
        ${contactCustomFieldValuesSubQuery}
      }
    }
  }
`
