import {
  FETCH_PROJECT_ERROR,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECTS_ERROR,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
} from '../types'

const initialState = { byId: {}, errored: false, loading: false }

export default function reduceInstalls(
  state = initialState,
  { meta: { id: projectId } = {}, payload: { project, projects } = {}, type }
) {
  if (type === FETCH_PROJECTS_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  if (type === FETCH_PROJECTS_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }
  if (type === FETCH_PROJECTS_SUCCESS) {
    return {
      ...state,
      byId: {
        ...state.byId,
        ...projects.reduce((result, individualProject) => {
          // eslint-disable-next-line no-param-reassign
          result[individualProject.id] = individualProject
          return result
        }, {}),
      },
      errored: false,
      loading: false,
    }
  }
  if (type === FETCH_PROJECT_REQUEST) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [projectId]: {
          ...state.byId[projectId],
          loading: true,
        },
      },
    }
  }
  if (type === FETCH_PROJECT_SUCCESS) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [projectId]: {
          ...state.byId[projectId],
          ...project,
          errored: false,
          loading: false,
        },
      },
    }
  }
  if (type === FETCH_PROJECT_ERROR) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [projectId]: {
          ...state.byId[projectId],
          errored: true,
          loading: false,
        },
      },
    }
  }
  return state
}
