import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'
import gitHubGraphQl from '../gitHubGraphQl'
import {
  FETCH_ISSUES_ERROR,
  FETCH_ISSUES_REQUEST,
  FETCH_ISSUES_SUCCESS,
} from '../types'

const URL_REGEX = /^https?:\/\//
export default function doFetchGitHubIssues(ids) {
  return (dispatch, getState) => {
    if (!ids || ids.length === 0) return
    const token = oauthTokenSelector(getState())
    Promise.all(
      ids.map(id => {
        if (!id.match || !id.match(URL_REGEX)) return Promise.resolve(id)
        return integrationApi
          .get(token, id.replace('https://github.com/', '/proxy/github/repos/'))
          .then(x => {
            return x.json.node_id
          })
      })
    ).then(issueNodeIds => {
      dispatch({
        type: FETCH_ISSUES_REQUEST,
        meta: { ids: issueNodeIds },
      })
      const query = `query($issueNodeIds: [ID!]!) {
        nodes(ids: $issueNodeIds) {
          __typename
          id
          ... on Issue {
            assignees(first: 100) {
              nodes {
                login
                url
              }
            }
            createdAt
            databaseId
            labels(first: 100) {
              nodes {
                name
                color
                description
                url
              }
            }
            locked
            activeLockReason
            milestone {
              description
              title
              url
            }
            number
            repository {
              name
              nameWithOwner,
              url
            }
            state
            title
            updatedAt  
            url
          }
        }
      }`
      const variables = {
        issueNodeIds,
      }
      return gitHubGraphQl(token, query, variables)
        .then(({ data: { nodes } }) => {
          dispatch({
            meta: { ids: issueNodeIds },
            payload: { nodes },
            type: FETCH_ISSUES_SUCCESS,
          })
        })
        .catch(error => {
          dispatch({
            error: true,
            meta: { ids: issueNodeIds },
            payload: error,
            type: FETCH_ISSUES_ERROR,
          })
        })
    })
  }
}
