import { connect } from 'react-redux'

import { selectCannedReplyCategoriesWithTemplates } from 'ducks/cannedReplies/selectors'
import { doFetchCannedReplies } from 'ducks/cannedReplies/operations'

import CannedRepliesByCategory from './view'

const select = state => ({
  categories: selectCannedReplyCategoriesWithTemplates(state),
})

const perform = {
  fetchCannedReplies: doFetchCannedReplies,
}

export default connect(select, perform)(CannedRepliesByCategory)
