import { timeInHuman, toDate } from 'util/date'
import { flatten, uniqBy } from 'util/arrays'
import {
  FETCH_OBJECT_REQUEST,
  FETCH_OBJECTS_SUCCESS,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_RELATED_OBJECTS_SUCCESS,
  FETCH_BATCH_PROPERTIES_SUCCESS,
  CREATE_CONTACT_FAILURE,
  FETCH_OBJECTS_FAILURE,
} from '../types'

const initialState = {
  fetched: [],
  byEmail: {},
  relatedById: {},
}

const reducers = {}

const renderValue = (fieldValue, type) => {
  if (type === 'datetime') {
    const value = fieldValue
    if (value === 0) return null
    if (!value) return null
    const parsed = toDate(fieldValue)
    const formatted = timeInHuman(parsed, { showToday: true })
    return formatted
  }
  return fieldValue
}

const extractLabel = (data, type) => {
  if (type === 'Task') return data.Subject
  return data && data.Name
}

const extractProperties = (data, type, properties) => {
  const result = {}
  const propertiesByName = properties[type]
  const keys = Object.keys(data)
  keys.forEach(key => {
    if (key.match(/^attributes/)) return
    const property = propertiesByName && propertiesByName[key]
    const label = property ? property.label : key
    const propertyType = property ? property.type : 'unknown'
    const value = renderValue(data[key], propertyType)
    if (value) result[key] = { value, type: propertyType, key, label }
  })
  return result
}

const extractObject = (state, data) => {
  const { object, email } = data
  const { properties } = state
  const type = object.attributes.type
  return {
    state: 'fetched',
    email,
    id: object.Id,
    type,
    label: extractLabel(object, type),
    properties: extractProperties(object, type, properties),
  }
}

reducers[FETCH_PROPERTIES_SUCCESS] = (state, action) => {
  const { schema, type } = action.data
  const fields = {}
  const properties = { ...state.properties }

  if (schema.fields) {
    schema.fields.forEach(property => {
      fields[property.name] = property
    })
    properties[type] = fields
  }

  return {
    ...state,
    properties,
  }
}

reducers[FETCH_BATCH_PROPERTIES_SUCCESS] = (state, action) => {
  const { schemas } = action.data
  const properties = { ...state.properties }

  const types = Object.keys(schemas)
  types.forEach(type => {
    const fields = {}
    const schema = schemas[type].body
    if (schema.fields) {
      schema.fields.forEach(property => {
        fields[property.name] = property
      })
      properties[type] = fields
    }
  })

  return {
    ...state,
    properties,
  }
}

reducers[FETCH_OBJECT_REQUEST] = (state, action) => {
  const { email } = action.data
  const byEmail = { ...state.byEmail }
  byEmail[email] = {
    state: 'fetching',
  }
  return {
    ...state,
    byEmail,
  }
}

reducers[FETCH_OBJECTS_FAILURE] = (state, action) => {
  const { email, objects } = action.data

  const uniqObjs = uniqBy(
    flatten(objects),
    ({ errorCode, message }) => `${errorCode}|${message}`
  )

  const errorResponses = uniqObjs.map(object => ({
    state: 'failure',
    code: object?.errorCode,
    message: object?.message,
  }))

  const byEmail = { ...state.byEmail }
  const fetched = [...state.fetched]
  fetched.push(email)

  byEmail[email] = {
    state: 'failure',
    count: 0,
    objects: [],
    error: errorResponses,
  }
  return {
    ...state,
    byEmail,
    fetched,
  }
}

reducers[FETCH_OBJECTS_SUCCESS] = (state, action) => {
  const { objects } = action.data
  const { email } = action.data
  const found = objects.map(object => {
    return extractObject(state, { object, email })
  })
  const byEmail = { ...state.byEmail }
  const fetched = [...state.fetched]
  fetched.push(email)
  byEmail[email] = {
    state: 'fetched',
    count: found.length,
    objects: found,
  }
  return {
    ...state,
    byEmail,
    fetched,
  }
}

const replaceRelated = (byEmail, relatedById) => {
  try {
    let newById
    Object.keys(byEmail).forEach(key => {
      const wrapper = byEmail[key]
      let changeCount = 0
      const objects = [...wrapper.objects]
      wrapper.objects.forEach((object, index) => {
        const related = relatedById[object.id]
        if (!related) return
        const { properties } = object
        const OwnerId = properties.OwnerId
        if (OwnerId && OwnerId.type === 'reference') {
          const found = related.byType.Owner
          if (found) {
            const newObject = {
              ...object,
              properties: {
                ...properties,
                OwnerId: {
                  value: found.label,
                  type: 'string',
                  key: 'OwnerId',
                  label: 'Owner',
                },
              },
            }
            objects[index] = newObject
            changeCount += 1
          }
        }
      })
      if (changeCount > 0) {
        newById = newById || { ...byEmail }
        newById[key] = { ...wrapper, objects }
      }
    })
    return newById || byEmail
  } catch (e) {
    return byEmail
  }
}

reducers[FETCH_RELATED_OBJECTS_SUCCESS] = (state, action) => {
  const { email, objectId, objects } = action.data
  const relatedById = { ...state.relatedById }
  const byEmail = state.byEmail
  const byType = {}
  objects.forEach(data => {
    const response = data.response
    byType[data.group] = response.records
      ? response.records.map(object => extractObject(state, { email, object }))
      : extractObject(state, { email, object: response })
  })
  relatedById[objectId] = {
    state: 'fetched',
    byType,
  }
  return {
    ...state,
    relatedById,
    byEmail: replaceRelated(byEmail, relatedById),
  }
}

reducers[CREATE_CONTACT_FAILURE] = (state, action) => {
  const { error } = action.data
  const messages = []
  error.response.forEach(err => messages.push(err.message))
  return {
    ...state,
    createContactErrors: messages,
  }
}

export default function reducer(state = initialState, action) {
  const found = reducers[action.type]
  if (found) return found(state, action)
  return state
}
