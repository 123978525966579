import { doApiReadRequest, doApiWriteRequest } from 'ducks/requests/operations'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import { convertScatterIdToRaw } from 'util/scatterSwap'

import {
  FETCH_THIRDPARTY_ACCESSTOKENS,
  FETCH_PRIVATE_ACCESS_TOKEN,
  DELETE_THIRDPARTY_ACCESSTOKEN,
} from './actionTypes'

import { thirdPartyAccessTokensApiV0ResponseSchema } from './schema'

export const doFetchPrivateAccessToken = (options = {}) => dispatch => {
  return dispatch(
    doApiReadRequest(
      FETCH_PRIVATE_ACCESS_TOKEN,
      `api/settings/private_access_token.json`,
      {},
      options
    )
  )
}

const LOAD_ALL_THIRDPARTY_ACCESSTOKENS_QUERYID =
  'entityType:accessToken pageSize:10000'

export const doFetchThirdPartyAccessTokensV0 = ({ skipLoaded } = {}) => (
  dispatch,
  getState
) => {
  const queryId = LOAD_ALL_THIRDPARTY_ACCESSTOKENS_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  return dispatch(
    doApiReadRequest(
      FETCH_THIRDPARTY_ACCESSTOKENS,
      'api/settings/third_party_access_tokens.json',
      {},
      {
        normalizationSchema: thirdPartyAccessTokensApiV0ResponseSchema,
        app: true,
        searches: {
          queryId,
          cursor,
          extractPagination: (
            _,
            {
              action: {
                transformedEntities: { accessToken: accessTokenById = {} } = {},
              },
            }
          ) => {
            const nodes = Object.values(accessTokenById)
            return {
              type: 'accessToken',
              nodes,
              startCursor: 1,
              endCursor: 1,
              hasNextPage: false,
              hasPreviousPage: false,
              totalCount: nodes.length,
              totalPageCount: 1,
            }
          },
        },
      }
    )
  )
}

export const doFetchThirdPartyAccessTokensInMemory = createDoFetchInMemoryByQueryId(
  {
    fromQueryId: LOAD_ALL_THIRDPARTY_ACCESSTOKENS_QUERYID,
    entityType: 'accessToken',
    doLoadAllFn: doFetchThirdPartyAccessTokensV0,
  }
)

export const doDeleteThirdPartyAccessToken = (
  inputId,
  options = {}
) => dispatch => {
  const { convertScatterToRaw } = options
  const id = convertScatterToRaw ? convertScatterIdToRaw(inputId) : inputId
  return dispatch(
    doApiWriteRequest(
      DELETE_THIRDPARTY_ACCESSTOKEN,
      `api/settings/third_party_access_tokens/${id}`,
      {},
      {
        method: 'DELETE',
        optimist: {},
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['accessToken'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: 'API token revoked',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'API token deletion failed. Please try again',
              onClickAction: () => {
                dispatch(doDeleteThirdPartyAccessToken(inputId, options))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                // Because we're passing through a global id here, we need to manually delete
                // the entity from the store
                entityType: 'accessToken',
                entityId: inputId,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['STARTED'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doDeleteThirdPartyAccessTokens = (
  ids,
  options = {}
) => dispatch => {
  // Promise.all is not technically required in the current implementation because
  // we'll only step into this block if the ids array has a single id, but I do
  // want to leave the door open for us to say that deleting less than X rows uses
  // single deletes instead of batch deletes
  return Promise.all(
    ids.map(id => dispatch(doDeleteThirdPartyAccessToken(id, options)))
  )
}
