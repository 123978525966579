export const AI_REQUEST = 'ai/AI_REQUEST'
export const AI_REQUEST_STARTED = 'ai/AI_REQUEST_STARTED'
export const AI_REQUEST_SUCCESS = 'ai/AI_REQUEST_SUCCESS'
export const AI_REQUEST_FAILED = 'ai/AI_REQUEST_FAILED'

export const FETCH_AI_JOB_RESULT = 'ai/FETCH_AI_JOB_RESULT'
export const FETCH_AI_JOB_RESULT_STARTED = 'ai/FETCH_AI_JOB_RESULT_STARTED'
export const FETCH_AI_JOB_RESULT_SUCCESS = 'ai/FETCH_AI_JOB_RESULT_SUCCESS'
export const FETCH_AI_JOB_RESULT_FAILED = 'ai/FETCH_AI_JOB_RESULT_FAILED'

export const FETCH_AI_JOB_INSTANT_REPLY_RESULT =
  'ai/FETCH_AI_JOB_INSTANT_REPLY_RESULT'
export const FETCH_AI_JOB_INSTANT_REPLY_RESULT_STARTED =
  'ai/FETCH_AI_JOB_INSTANT_REPLY_RESULT_STARTED'
export const FETCH_AI_JOB_INSTANT_REPLY_RESULT_FAILED =
  'ai/FETCH_AI_JOB_INSTANT_REPLY_RESULT_FAILED'
export const FETCH_AI_JOB_INSTANT_REPLY_RESULT_END =
  'ai/FETCH_AI_JOB_INSTANT_REPLY_RESULT_END'
export const FETCH_AI_JOB_INSTANT_REPLY_RESULT_CANCEL =
  'ai/FETCH_AI_JOB_INSTANT_REPLY_RESULT_CANCEL'
export const FETCH_AI_JOB_INSTANT_REPLY_RESULT_HIDE =
  'ai/FETCH_AI_JOB_INSTANT_REPLY_RESULT_HIDE'
export const FETCH_AI_JOB_INSTANT_REPLY_RESULT_CLEAR =
  'ai/FETCH_AI_JOB_INSTANT_REPLY_RESULT_CLEAR'

export const FETCH_AI_JOB_CONVERSATION_RESULT =
  'ai/FETCH_AI_JOB_CONVERSATION_RESULT'
export const FETCH_AI_JOB_CONVERSATION_RESULT_STARTED =
  'ai/FETCH_AI_JOB_CONVERSATION_RESULT_STARTED'
export const FETCH_AI_JOB_CONVERSATION_RESULT_FAILED =
  'ai/FETCH_AI_JOB_CONVERSATION_RESULT_FAILED'
export const FETCH_AI_JOB_CONVERSATION_RESULT_END =
  'ai/FETCH_AI_JOB_CONVERSATION_RESULT_END'
export const FETCH_AI_JOB_CONVERSATION_RESULT_CLEAR =
  'ai/FETCH_AI_JOB_CONVERSATION_RESULT_CLEAR'
export const FETCH_AI_JOB_CONVERSATION_RESULT_INITIALIZE =
  'ai/FETCH_AI_JOB_CONVERSATION_RESULT_INITIALIZE'

export const FETCH_AI_JOB_COMPOSER_RESULT = 'ai/FETCH_AI_JOB_COMPOSER_RESULT'
export const FETCH_AI_JOB_COMPOSER_RESULT_STARTED =
  'ai/FETCH_AI_JOB_COMPOSER_RESULT_STARTED'
export const FETCH_AI_JOB_COMPOSER_RESULT_FAILED =
  'ai/FETCH_AI_JOB_COMPOSER_RESULT_FAILED'
export const FETCH_AI_JOB_COMPOSER_RESULT_END =
  'ai/FETCH_AI_JOB_COMPOSER_RESULT_END'
export const FETCH_AI_JOB_COMPOSER_RESULT_CLEAR =
  'ai/FETCH_AI_JOB_COMPOSER_RESULT_CLEAR'
export const FETCH_AI_JOB_COMPOSER_RESULT_INITIALIZE =
  'ai/FETCH_AI_JOB_COMPOSER_RESULT_INITIALIZE'

export const FETCH_AI_SUGGESTIONS = 'ai/FETCH_AI_SUGGESTIONS'
export const FETCH_AI_SUGGESTIONS_STARTED = 'ai/FETCH_AI_SUGGESTIONS_STARTED'
export const FETCH_AI_SUGGESTIONS_SUCCESS = 'ai/FETCH_AI_SUGGESTIONS_SUCCESS'
export const FETCH_AI_SUGGESTIONS_FAILED = 'ai/FETCH_AI_SUGGESTIONS_FAILED'

export const UPSERT_AI_SUGGESTION = 'ai/UPSERT_AI_SUGGESTION'
export const UPSERT_AI_SUGGESTION_STARTED = 'ai/UPSERT_AI_SUGGESTION_STARTED'
export const UPSERT_AI_SUGGESTION_SUCCESS = 'ai/UPSERT_AI_SUGGESTION_SUCCESS'
export const UPSERT_AI_SUGGESTION_FAILED = 'ai/UPSERT_AI_SUGGESTION_FAILED'
export const UPDATE_AI_SUGGESTION_AUTO_GENERATED =
  'ai/UPDATE_AI_SUGGESTION_AUTO_GENERATED'

export const UPDATE_REALTIME_AI_SUGGESTION = 'ai/UPDATE_REALTIME_AI_SUGGESTION'

export const INSTANT_REPLY_TYPE = 'instantReply'
export const CONVERSATION_TYPE = 'conversation'
export const COMPOSER_TYPE = 'composer'

export const TEXT_REFINER_SERVICE = 'groove-text-refiner'

export const INSTANT_REPLY_CAST_ACTION = 'cast_instant_reply'
export const SUMMARY_ACTION = 'summary'
export const SPELLING_ACTION = 'spelling'
export const SHORTEN_ACTION = 'shorten'
export const LENGTHEN_ACTION = 'lengthen'
export const FORMAL_ACTION = 'formal'
export const CASUAL_ACTION = 'casual'
export const CLARIFY_ACTION = 'clarify'
export const CREATE_REPLY_ACTION = 'create_reply'

export const ACTION_RPC_METHOD_MAP = {
  [INSTANT_REPLY_CAST_ACTION]: 'instant_reply.cast_from_text',
  [SUMMARY_ACTION]: 'summary.cast_from_messages',
  [SPELLING_ACTION]: 'rewrite.correct_grammar',
  [SHORTEN_ACTION]: 'rewrite.modify_length',
  [LENGTHEN_ACTION]: 'rewrite.modify_length',
  [FORMAL_ACTION]: 'rewrite.modify_tone',
  [CASUAL_ACTION]: 'rewrite.modify_tone',
  [CLARIFY_ACTION]: 'rewrite.clarify',
  [CREATE_REPLY_ACTION]: 'draft.create_draft',
}

export const REQUEST_TYPES = {
  [INSTANT_REPLY_CAST_ACTION]: INSTANT_REPLY_TYPE,
  [SUMMARY_ACTION]: CONVERSATION_TYPE,
  [SPELLING_ACTION]: COMPOSER_TYPE,
  [SHORTEN_ACTION]: COMPOSER_TYPE,
  [LENGTHEN_ACTION]: COMPOSER_TYPE,
  [FORMAL_ACTION]: COMPOSER_TYPE,
  [CASUAL_ACTION]: COMPOSER_TYPE,
  [CLARIFY_ACTION]: COMPOSER_TYPE,
  [CREATE_REPLY_ACTION]: CONVERSATION_TYPE,
}

export const AI_STREAMING_UPDATE = 'ai/STREAMING_UPDATE'

export const ADMIN_EMAIL_NOTIFICATION_TYPES = {
  aiDraft: 'ADD_MORE_CREDITS_REQUEST_FOR_AI_DRAFTS',
  aiOverview: 'UPGRADE_ACCOUNT_REQUEST_FOR_AI_OVERVIEW',
}

// The first one is the default model
export const AI_MODELS = ['gpt-4', 'command-r']

export const FETCH_AI_SOURCES = 'ai/FETCH_AI_SOURCES'
export const FETCH_AI_SOURCES_STARTED = 'ai/FETCH_AI_SOURCES_STARTED'
export const FETCH_AI_SOURCES_SUCCESS = 'ai/FETCH_AI_SOURCES_SUCCESS'
export const FETCH_AI_SOURCES_FAILED = 'ai/FETCH_AI_SOURCES_FAILED'
