import { doLinkResource } from 'actions/userIntegrations'

import { selectJiraIssuesById } from 'ducks/integrations/jiraShared'
import { selectIntegrationCredentialsForProvider } from 'ducks/integrations/selectors'
import doPostTicketLinkOnJiraServerIssue from './doPostTicketLinkOnJiraServerIssue'

export default function doLinkJiraServerIssueToTicket(issueId) {
  return (dispatch, getState) => {
    const state = getState()
    const issues = selectJiraIssuesById(state, { source: 'jiraServer' })
    const { url: siteUrl } = selectIntegrationCredentialsForProvider(state, {
      provider: 'jira_server',
    })
    const issue = issues[issueId]

    if (!issue) return Promise.reject('Cannot find Jira Server issue')

    const {
      key,
      fields: { summary },
    } = issue
    const url = `${siteUrl.replace(/\/+$/, '')}/browse/${key}`
    return Promise.all([
      dispatch(
        doLinkResource({
          title: `[${key}] ${summary}`,
          url,
          id: `${issueId}`,
          provider: 'jira_server',
        })
      ),
      dispatch(doPostTicketLinkOnJiraServerIssue(issueId)),
    ])
  }
}
