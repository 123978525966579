import createCachedSelector from 're-reselect'

import selectGitHubNodesById from './selectGitHubNodesById'

export default createCachedSelector(
  selectGitHubNodesById,
  (state, { repositoryId }) => repositoryId,
  (nodes, repositoryId) => {
    const repository = nodes[repositoryId]
    return (
      repository &&
      repository.milestones && [
        { text: '- No Milestone -', value: null },
        ...repository.milestones.map(({ id, title }) => ({
          value: id,
          text: title,
        })),
      ]
    )
  }
)((state, { repositoryId }) => repositoryId || 'NULL')
