import createCachedSelector from 're-reselect'
import {
  FEATURE_INBOX_MAX_CHANNELS,
  FEATURE_INBOX_MAX_MAILBOXES,
} from 'ducks/billing/featureTypes'
import { byFeatureCacheKey } from './byFeatureCacheKey'
import { selectAvailableFeatures } from '../selectAvailableFeatures'

export const selectLimitForFeature = createCachedSelector(
  selectAvailableFeatures,
  (_state, feature) => feature,
  (features, feature) => {
    // special case:
    // if feature is mailboxes, use channels BUT if limit for channels is unlimited, use value from mailboxes
    if (feature === FEATURE_INBOX_MAX_MAILBOXES) {
      const channelsFeatureLimit = features[FEATURE_INBOX_MAX_CHANNELS]
      if (channelsFeatureLimit === 'unlimited') return channelsFeatureLimit
    }
    return features[feature]
  }
)(byFeatureCacheKey)
