import { selectCurrentTicketCustomer } from 'selectors/tickets'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectCurrentFolderId } from 'selectors/app'
import { selectLatestTicketSearchQueryObjectString } from 'selectors/search'

import customerChangePayload from 'optimistic/customer'
import { customerChangesetMutation } from 'api/tickets'

import { doSendChangeset } from 'actions/tickets/changeset/doSendChangeset'

import {
  CHANGE_USER_REQUEST,
  CHANGE_USER_SUCCESS,
  CHANGE_USER_FAILURE,
} from '../types'

export const doChangeUser = user => (dispatch, getState) => {
  const state = getState()
  const previousUser = selectCurrentTicketCustomer(state)

  dispatch({ type: CHANGE_USER_REQUEST, payload: { user } })
  const ticketId = selectCurrentTicketId(state)

  const optimisticData = customerChangePayload(
    state,
    ticketId,
    user,
    previousUser,
    selectCurrentFolderId(state),
    selectLatestTicketSearchQueryObjectString(state)
  )

  return dispatch(
    doSendChangeset(
      ticketId,
      { customer: user.id },
      {
        optimisticData,
        mutation: customerChangesetMutation,
      }
    )
  )
    .then(data => {
      dispatch({
        type: CHANGE_USER_SUCCESS,
        data: { ticketId },
        item: data,
      })
    })
    .catch(err =>
      dispatch({
        type: CHANGE_USER_FAILURE,
        data: { ticketId },
        item: err,
      })
    )
}
