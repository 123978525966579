import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'

export default createSelector(
  (state, { source }) => {
    return Object.values(
      state.integrations.thirdPartyData[source].components.byId
    )
  },
  components => {
    if (!components) return emptyArr
    return components.map(({ name, id }) => {
      return {
        text: name,
        value: id,
      }
    })
  }
)
