import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectSubscriptionsForCustomerId } from 'ducks/integrations/stripe/selectors/subscriptions'

import doFetchSubscriptions from 'ducks/integrations/stripe/operations/doFetchSubscriptions'

export const useSubscriptions = (integrationId, customerId) => {
  const dispatch = useDispatch()

  const subscriptionsData = useSelector(state =>
    selectSubscriptionsForCustomerId(state, customerId)
  )

  useEffect(
    () => {
      if (customerId && !subscriptionsData.isInitialized) {
        dispatch(doFetchSubscriptions({ integrationId, customerId }))
      }
    },
    [dispatch, integrationId, customerId, subscriptionsData]
  )

  if (subscriptionsData) {
    return subscriptionsData
  }

  return {}
}

export const useTitle = subscription => {
  if (!subscription) {
    return null
  }

  const items = subscription.items.data

  if (!items.length) {
    return null
  }

  if (items.length > 1) {
    const firstItemNickname = items[0].price.product.name
    return `${firstItemNickname} and ${items.length - 1} more`
  }

  return items[0].price.product.name
}

export const useBillingCycle = subscription => {
  if (!subscription) {
    return null
  }

  const items = subscription.items.data

  if (!items.length) {
    return null
  }

  const itemRecurring = items[0].price.recurring

  switch (itemRecurring.interval) {
    case 'year':
      return 'Billing yearly'
    case 'month':
      return 'Billing monthly'
    case 'week':
      return 'Billing weekly'
    case 'day':
      return 'Billing daily'
    default:
      return ''
  }
}

export const useStatus = subscription => {
  const status = useMemo(
    () => {
      if (subscription.status === 'active') {
        return 'success'
      }

      return 'neutral'
    },
    [subscription]
  )

  return status
}
