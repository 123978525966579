import {
  FETCH_PROJECTS_ERROR,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
} from '../types'

const initialState = { byId: {}, errored: false, loading: false }

export default function reduceInstalls(
  state = initialState,
  { type, payload: { projects } = {} }
) {
  if (type === FETCH_PROJECTS_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  if (type === FETCH_PROJECTS_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }
  if (type === FETCH_PROJECTS_SUCCESS) {
    return {
      ...state,
      byId: {
        ...state.byId,
        ...projects.reduce((result, project) => {
          // eslint-disable-next-line no-param-reassign
          result[`${project.site.id}:${project.id}`] = project
          return result
        }, {}),
      },
      errored: false,
      loading: false,
    }
  }
  return state
}
