import { createSelector } from 'reselect'

import selectTrelloBoards from './selectTrelloBoards'

export default createSelector(
  selectTrelloBoards,
  boards =>
    boards &&
    boards
      .sort(({ name: nameA }, { name: nameB }) => {
        if (nameA > nameB) return 1
        if (nameA < nameB) return -1
        return 0
      })
      .map(({ id, name }) => ({ text: name, value: id }))
)
