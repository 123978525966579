import { useCallback, useEffect, useState } from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { Icon } from 'shared/ui'

const starIcon = 'star'
const starOutlineIcon = 'star-outline'

const StarIcon = ({ cannedReply, handleClick, isStarred }) => {
  const [iconName, setIconName] = useState()

  useEffect(
    () => {
      setIconName(isStarred ? starIcon : starOutlineIcon)
    },
    [isStarred]
  )

  const onClick = useCallback(
    event => {
      event.stopPropagation()
      handleClick(cannedReply.id)
    },
    [cannedReply, handleClick]
  )

  const onMouseOver = useCallback(
    () => {
      if (isStarred) {
        setIconName(starOutlineIcon)
      }
    },
    [isStarred]
  )

  const onMouseLeave = useCallback(
    () => {
      if (isStarred) {
        setIconName(starIcon)
      }
    },
    [isStarred]
  )

  return (
    <Tooltip
      title={
        isStarred
          ? 'Remove from Starred replies'
          : 'Easily star important or frequently used Instant Replies for quick access'
      }
      position="top"
      strategy="absolute"
    >
      <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <Icon
          name={iconName}
          className="star-icon"
          size="small"
          onClick={onClick}
          color={isStarred ? 'color.primary.warning' : 'initial'}
          hoverColor="color.primary.warning"
        />
      </div>
    </Tooltip>
  )
}

export default StarIcon
