import { createBasicSelector } from 'util/redux'
import { selectBase } from './selectBase'

// fyi createdAt is in epoch seconds not milliseconds
const selectAccountCreatedAtEpochMs = createBasicSelector(selectBase, base => {
  if (!base.createdAt) return null

  return base.createdAt * 1000
})

export default selectAccountCreatedAtEpochMs
