import integrationApi from '../api'

const ENDPOINT_PREFIX = 'proxy/stripe'

function parseProxyResponse({ code, json }) {
  const object = { response: json }
  object.code = code
  if (code === 200 || code === 201) {
    return Promise.resolve(object)
  }
  if (code >= 400) return Promise.reject(object)
  return Promise.reject({ code })
}

function get(token, path, params) {
  return integrationApi
    .get(token, ENDPOINT_PREFIX + path, params)
    .then(parseProxyResponse)
}

export function getCustomerByEmail(token, integrationId, email) {
  const url = `/v1/customers/search?query=email:"${email}"&gid=${integrationId}`
  return get(token, url)
}

export function getSubscriptions(
  token,
  integrationId,
  customerId,
  lastItemId = null
) {
  const params = new URLSearchParams({
    customer: customerId,
    status: 'all',
    limit: 3,
    gid: integrationId,
  })

  if (lastItemId) {
    params.append('starting_after', lastItemId)
  }

  const url = `/v1/subscriptions?${decodeURIComponent(params.toString())}`
  return get(token, url)
}

export function getSubscription(token, integrationId, subscriptionId) {
  const params = new URLSearchParams({
    'expand[]': 'items.data.price.product',
    gid: integrationId,
  })

  const url = `/v1/subscriptions/${subscriptionId}?${decodeURIComponent(
    params.toString()
  )}`
  return get(token, url)
}

export function getCharges(
  token,
  integrationId,
  customerId,
  lastItemId = null
) {
  const params = new URLSearchParams({
    customer: customerId,
    limit: 3,
    'expand[]': 'data.balance_transaction',
    gid: integrationId,
  })

  if (lastItemId) {
    params.append('starting_after', lastItemId)
  }

  const url = `/v1/charges?${decodeURIComponent(params.toString())}`
  return get(token, url)
}

export function getInvoice(
  token,
  integrationId,
  customerId,
  subscriptionId,
  type
) {
  const params = new URLSearchParams({
    customer: customerId,
    subscription: subscriptionId,
    gid: integrationId,
  })

  const url = `/v1/invoices/${type}?${decodeURIComponent(params.toString())}`
  return get(token, url)
}
