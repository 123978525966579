/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'

import {
  USER_PRESENCE_CHANGED,
  BULK_USER_PRESENCE_CHANGED,
} from '../../actionTypes/presence'

export default createActionTypeReducer(
  {
    [USER_PRESENCE_CHANGED]: () => {
      return false
    },
    [BULK_USER_PRESENCE_CHANGED]: () => {
      return false
    },
  },
  true
)
