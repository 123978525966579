import { selectCurrentEntities } from 'ducks/entities/selectors'
import { createSelector } from 'reselect'

export const selectBillingUsage = createSelector(
  state => selectCurrentEntities(state, 'usage'),
  usages =>
    usages.reduce((usage, item) => {
      // eslint-disable-next-line no-param-reassign
      usage[item.key] = item.value
      return usage
    }, {})
)
