/* eslint-disable no-param-reassign */
import { getAudioNotificationsState } from 'util/audio'
import { createActionTypeReducer } from 'util/reducers'
import { NOTIFICATIONS_STATE_UPDATE } from '../actionTypes/notifications'

const initialState = {
  enabled: getAudioNotificationsState(),
}

export default createActionTypeReducer(
  {
    [NOTIFICATIONS_STATE_UPDATE]: (draftState, action) => {
      draftState.enabled = action.payload.enabled
    },
  },
  initialState
)
