import { createSelector } from 'reselect'

import {
  selectIntegration,
  selectGithubFormCurrentOptions,
} from 'selectors/userIntegrations'

import { selectTrelloFormCurrentOptions } from '../trello/selectors/selectTrelloFormCurrentOptions'

export default createSelector(
  selectIntegration,
  selectGithubFormCurrentOptions,
  selectTrelloFormCurrentOptions,
  (current, github, trello) => id => {
    if (current === 'github') return github[id]
    const byId = trello[id]
    return byId && Object.values(byId)
  }
)
