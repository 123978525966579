import { doGraphqlRequest } from 'ducks/requests/operations'
import { SETTINGS_INTEGRATIONS_PROVIDER_PAGE } from 'subapps/settings/types'
import selectIntegrationDataForProviderId from 'ducks/integrations/selectors/settings/selectIntegrationDataForProviderId'
import { doOpenDrawer } from 'ducks/drawers/actions'
import appGraphql from 'api//app_graphql'
import { oauthTokenSelector } from 'selectors/app'
import { BootstrapLoader } from 'util/bootstrap'
import * as types from '../types'
import { updateIntegrationSettingsMutation } from './updateIntegrationSettingsMutation'
import { removeIntegrationsByProviderMutation } from './removeIntegrationsByProviderMutation'

export const doRemoveIntegrationsByProvider = provider => dispatch => {
  const variables = { provider }
  dispatch(
    doGraphqlRequest(
      types.REMOVE_INTEGRATION_BY_PROVIDER,
      removeIntegrationsByProviderMutation,
      variables,
      {
        app: true,
        meta: { provider },
        transformResponse: data =>
          data.integrationDeleteByProvider.deletedIntegrationIds,
      }
    )
  )
}
const fetchIntegrationsMutation = `
  query IntegrationsQuery {
    integrations {
      nodes {
        __typename
        ... on Integration {
          id
          legacyId
          provider
          settings {
            settings
          }
        }

        ... on UserIntegration {
          agent {
            id
          }
        }

        ... on ChannelIntegration {
          channel {
            id
          }
        }

        ... on ShopifyV2Integration {
          storeName
          storeDomain
        }

        ... on SalesforceIntegration {
          salesforceDomain
        }

        ... on JiraServerIntegration {
          username
          url
        }

        ... on RechargeIntegration {
          storeName
          storeDomain
          fullStoreDomain
        }
      }
    }
  }
`

function createFetchIntegrationsRequestAction() {
  return { type: types.FETCH_INTEGRATIONS_STARTED }
}

function createFetchIntegrationsSuccessAction(response) {
  const {
    json: { data },
  } = response
  return { type: types.FETCH_INTEGRATIONS_SUCCESS, payload: { ...data } }
}

function createFetchIntegrationsFailAction(error) {
  return { type: types.FETCH_INTEGRATIONS_FAILED, payload: { error } }
}

// bootstrap loader version of doFetchIntegrations() below
export function createFetchIntegrationsLoader() {
  return new BootstrapLoader(
    'integrations',
    createFetchIntegrationsRequestAction,
    createFetchIntegrationsSuccessAction,
    createFetchIntegrationsFailAction,
    getState => {
      return appGraphql(
        oauthTokenSelector(getState()),
        fetchIntegrationsMutation
      )
    }
  )
}

export const doFetchIntegrations = () => dispatch => {
  return dispatch(
    doGraphqlRequest(
      types.FETCH_INTEGRATIONS,
      fetchIntegrationsMutation,
      {},
      { app: true }
    )
  )
}

export const doOpenProviderPage = provider => () => dispatch => {
  dispatch({ type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE, payload: { provider } })
}

export const doUpdateIntegrationSettings = (
  integrationId,
  values
) => dispatch => {
  const variables = {
    integrationId,
    settings: values,
  }

  dispatch(
    doGraphqlRequest(
      types.UPDATE_INTEGRATION_SETTINGS,
      updateIntegrationSettingsMutation,
      variables,
      { app: true }
    )
  )
}

export const doOpenEditIntegrationDrawer = (providerId, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    configureActionConfig: { drawerType: configureDrawerType } = {},
  } = selectIntegrationDataForProviderId(state, providerId)

  return dispatch(
    doOpenDrawer(providerId, configureDrawerType, providerId, options)
  )
}
