import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectUpdateSubscriptionRequest,
  selectPrimaryCreditCard,
  selectIsUpdatingCard,
  selectIsCanceling,
  selectIsBillingDataLoading,
  selectBillingPendingSubscription,
  selectBillingExternalAccountId,
  selectBillingExternalSubscriptionId,
  selectBillingStartTrialDurationDays,
} from 'ducks/billing/selectors'
import {
  doUpdateSubscription,
  doFetchBillingData,
  doExternalApprovePendingSubscription,
  doStartTrial,
  doSetupIntentSuccess,
  doFetchSetupIntents,
} from 'ducks/billing/operations'
import { selectFeatureUsage } from 'ducks/billing/selectors/features'
import { areArraysEqual, uniq, uniqBy } from 'util/arrays'
import { isFunction } from 'util/functions'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_BILLING_EXTERNAL_APPROVE } from 'ducks/drawers/types'
import {
  checkBooleanLimit,
  checkBooleanRemoved,
  checkNumberLimit,
  checkNumberRemoved,
} from 'util/usageLimit'
import { doFetchSettingsBootstrapData } from 'actions/app'
import { selectHasPendingDowngrade } from './selectors/selectHasPendingDowngrade'
import { selectIsBillingEstimateAvailable } from './selectors/selectIsBillingEstimateAvailable'
import { selectBillingEstimateForPlanChange } from './selectors/selectBillingEstimateForPlanChange'
import { selectCurrentInbox } from './selectors/selectCurrentInbox'
import { selectCurrentKb } from './selectors/selectCurrentKb'
import { selectBillingProviderSettings } from './selectors/selectBillingProviderSettings'
import { selectBillingProvider } from './selectors/selectBillingProvider'
import { selectIsSubscriptionActive } from './selectors/selectIsSubscriptionActive'
import { selectHasPlans } from './selectors/selectHasPlans'
import { selectPricings } from './selectors/selectPricings'
import { selectCurrentBillingCycle } from './selectors/selectCurrentBillingCycle'
import { selectIsBillingDataLoaded } from './selectors/selectIsBillingDataLoaded'
import { selectIsTrial } from './selectors/selectIsTrial'
import { selectDaysLeftBeforeExpiring } from './selectors/selectDaysLeftBeforeExpiring'
import { selectIsFreemium } from './selectors/selectIsFreemium'
import {
  buildLineItemsFromPricings,
  calculateSubscriptionFlatTotal,
  calculateSubscriptionUsageTotal,
  isLimitsExceeded,
  buildPricingInfo,
  isBillingUpgrade,
  quantityFor,
  sortPricings,
} from './util'
import {
  BOOLEAN_FEATURES,
  FEATURE_INBOX_MAX_CHANNELS,
  FEATURE_INBOX_MAX_MAILBOXES,
  FEATURE_KB_MAX_KBS,
  NUMBER_FEATURES,
} from './featureTypes'
import { INBOX, ALL, ADDON, KB } from './productTypes'
import { selectAvailableFeatures } from './selectors/selectAvailableFeatures'
import { selectAvailableFeaturesByPricingIds } from './selectors/selectAvailableFeaturesByPricingIds'
import { selectPlans } from './selectors/selectPlans'
import { selectPreferredBillingVersionByProduct } from './selectors/selectPreferredBillingVersionByProduct'
import { selectPricingsById } from './selectors/selectPricingsById'
import { BILLING_CYCLE_ANNUAL, BILLING_CYCLE_MONTHLY } from './constants'
import { selectAppliedDiscount } from './selectors/selectAppliedDiscount'
import { selectUsage } from './selectors/usage/selectUsage'

export const useBilling = () => {
  const dispatch = useDispatch()
  const provider = useSelector(selectBillingProvider)
  const billingCycle = useSelector(selectCurrentBillingCycle)
  const currentInbox = useSelector(selectCurrentInbox)
  const currentKb = useSelector(selectCurrentKb)
  const plans = useSelector(selectPlans)
  const pricings = useSelector(selectPricings)
  const pricingsById = useSelector(selectPricingsById)

  const isSubscriptionActive = useSelector(selectIsSubscriptionActive)
  const { loading: isSaving } = useSelector(selectUpdateSubscriptionRequest)
  const isTrial = useSelector(selectIsTrial)
  const isFreemium = useSelector(selectIsFreemium)
  const primaryCard = useSelector(selectPrimaryCreditCard)
  const isBillingEstimateAvailable = useSelector(
    selectIsBillingEstimateAvailable
  )
  const availableFeatures = useSelector(selectAvailableFeatures)
  const usage = useSelector(selectUsage)
  const isUpdatingCard = useSelector(selectIsUpdatingCard)
  const isCanceling = useSelector(selectIsCanceling)
  const hasPlans = useSelector(selectHasPlans)
  const isLoaded = useSelector(selectIsBillingDataLoaded)
  const isLoading = useSelector(selectIsBillingDataLoading)
  const pendingSubscription = useSelector(selectBillingPendingSubscription)
  const providerSettings = useSelector(selectBillingProviderSettings)
  const isBillingLinked = !!useSelector(selectBillingExternalAccountId)
  const externalAccountId = useSelector(selectBillingExternalAccountId)
  const externalSubscriptionId = useSelector(
    selectBillingExternalSubscriptionId
  )
  const startTrialDurationDays = useSelector(
    selectBillingStartTrialDurationDays
  )

  const trialDaysLeft = useSelector(state =>
    selectDaysLeftBeforeExpiring(state, INBOX)
  )
  const isActiveTrial = isTrial && (trialDaysLeft || 0) > 0

  const hasPendingDowngrade = useSelector(selectHasPendingDowngrade)
  const appliedDiscount = useSelector(selectAppliedDiscount)
  const accountBillingVersionByType = useSelector(
    selectPreferredBillingVersionByProduct
  )

  const updateSubscription = useCallback(
    (data, options) => {
      return dispatch(doUpdateSubscription(data, options))
    },
    [dispatch]
  )

  const startTrial = useCallback(
    () => {
      return dispatch(doStartTrial(INBOX))
    },
    [dispatch]
  )

  const reloadBilling = useCallback(
    () => {
      return dispatch(doFetchBillingData())
    },
    [dispatch]
  )

  const externalApprovePendingSubscription = useCallback(
    options => dispatch(doExternalApprovePendingSubscription(options)),
    [dispatch]
  )

  const buildLineItemsFromPricingsForBillingCycle = useCallback(
    forBillingCycle => {
      return buildLineItemsFromPricings({
        pricings: plans.map(plan => plan.pricing),
        billingCycle: forBillingCycle,
        usage,
        provider,
        discount: appliedDiscount,
      })
    },
    [plans, provider, usage, appliedDiscount]
  )

  const calculateEstimatedTotalBill = useCallback(
    forBillingCycle => {
      const lineItems = buildLineItemsFromPricingsForBillingCycle(
        forBillingCycle
      )

      const flatTotal = calculateSubscriptionFlatTotal(lineItems) || 0
      const usageTotal = calculateSubscriptionUsageTotal(lineItems) || 0

      return flatTotal + usageTotal
    },
    [buildLineItemsFromPricingsForBillingCycle]
  )

  const currentPricingIds = useMemo(() => plans.map(plan => plan.pricing.id), [
    plans,
  ])

  return {
    appliedDiscount,
    availableFeatures,
    billingCycle,
    accountBillingVersionByType,
    currentPricingIds,
    currentInbox,
    currentKb,
    plans,
    externalAccountId,
    externalApprovePendingSubscription,
    externalSubscriptionId,
    hasPlans,
    isBillingEstimateAvailable,
    isBillingLinked,
    isCanceling,
    isLoaded,
    isLoading,
    isSaving,
    isSubscriptionActive,
    isTrial,
    // Kevin R, hmm we should probably just replace isTrial with the implementation of
    // isActiveTrial. Unfortunately isTrial is used in quite a few places so for now we'll
    // add the 2nd definiction and just start using that moving forward until we can get rid
    // of the old definition
    isActiveTrial,
    isFreemium,
    isUpdatingCard,
    pendingSubscription,
    pricingsById,
    pricings,
    primaryCard,
    provider,
    providerSettings,
    reloadBilling,
    startTrial,
    startTrialDurationDays,
    trialDaysLeft,
    updateSubscription,
    usage,
    calculateEstimatedTotalBill,
    hasPendingDowngrade,
  }
}

export const useFeature = feature => {
  const {
    canUseFeature,
    featureLimit,
    currentUsage,
    isFeatureEnabled,
  } = useSelector(state => selectFeatureUsage(state, feature))

  return {
    canUseFeature,
    featureLimit,
    currentUsage,
    isFeatureEnabled,
  }
}

export const useProductChannelFeature = (isChat = false) => {
  const {
    canUseFeature: channelCanUseFeature,
    featureLimit: channelFeatureLimit,
    currentUsage: channelCurrentUsage,
  } = useFeature(FEATURE_INBOX_MAX_CHANNELS)

  const {
    canUseFeature: mailboxCanUseFeature,
    featureLimit: mailboxFeatureLimit,
    currentUsage: mailboxCurrentUsage,
  } = useFeature(FEATURE_INBOX_MAX_MAILBOXES)

  if (isChat || channelFeatureLimit !== 'unlimited') {
    return {
      canUseFeature: channelCanUseFeature,
      featureLimit: channelFeatureLimit,
      currentUsage: channelCurrentUsage,
    }
  }

  return {
    canUseFeature: mailboxCanUseFeature,
    featureLimit: mailboxFeatureLimit,
    currentUsage: mailboxCurrentUsage,
  }
}

export const usePricingChangeInfo = (inputNewPricingIds, newBillingCycle) => {
  const isLoading = useSelector(selectIsBillingDataLoading)
  const usage = useSelector(selectUsage)
  const currentBillingCycle = useSelector(selectCurrentBillingCycle)
  const currentPlans = useSelector(selectPlans)
  const pricingsById = useSelector(selectPricingsById)
  const appliedDiscount = useSelector(selectAppliedDiscount)

  const currentPricings = useMemo(
    () => {
      return currentPlans.map(plan => plan.pricing)
    },
    [currentPlans]
  )
  const currentPricingIds = useMemo(
    () => {
      return currentPlans.map(plan => plan.pricing.id)
    },
    [currentPlans]
  )
  const inputNewPricings = useMemo(
    () => {
      return inputNewPricingIds.map(id => pricingsById[id])
    },
    [inputNewPricingIds, pricingsById]
  )

  const accountBillingVersionByType = useSelector(
    selectPreferredBillingVersionByProduct
  )

  const { nextBillDate, currency } = useSelector(
    selectBillingEstimateForPlanChange
  )

  const currentAvailableFeatures = useSelector(state =>
    selectAvailableFeaturesByPricingIds(state, currentPricingIds)
  )

  const currentPlansInfo = useMemo(
    () => {
      return buildPricingInfo(
        currentPricings,
        accountBillingVersionByType,
        currentBillingCycle,
        usage,
        appliedDiscount
      )
    },
    [
      currentPricings,
      accountBillingVersionByType,
      currentBillingCycle,
      usage,
      appliedDiscount,
    ]
  )

  const inputNewPlansInfo = useMemo(
    () => {
      return buildPricingInfo(
        inputNewPricings,
        accountBillingVersionByType,
        newBillingCycle,
        usage,
        appliedDiscount
      )
    },
    [
      inputNewPricings,
      accountBillingVersionByType,
      newBillingCycle,
      usage,
      appliedDiscount,
    ]
  )

  const newBillingCycleOrDefault = newBillingCycle || currentBillingCycle
  const isCycleChange = currentBillingCycle !== newBillingCycleOrDefault

  const changesByType = useMemo(
    () => {
      return ALL.reduce((infoByType, type) => {
        const currentItems = currentPlansInfo.filter(
          info => info.pricing.type === type && info.lineItem.quantity > 0
        )
        const currentPricingIdsByType = uniq(
          currentItems.map(info => info.pricing.id)
        )
        const newItems = inputNewPlansInfo.filter(info => {
          const isKb = type === KB
          const inboxPlan = inputNewPlansInfo.find(
            p => p.pricing.type === INBOX
          )
          const inboxHasBuiltinKbSupport = inboxPlan.pricing.features.some(
            f => f.key === FEATURE_KB_MAX_KBS && f.value > 0
          )
          if (isKb && inboxHasBuiltinKbSupport) return false
          return info.pricing.type === type && info.lineItem.quantity > 0
        })
        const newPricingIdsByType = uniq(newItems.map(info => info.pricing.id))

        const hasLengthChanged = currentItems.length !== newItems.length
        const hasPricingIdsChanged = !areArraysEqual(
          currentPricingIdsByType,
          newPricingIdsByType
        )

        const currentTotalCyclePrice = currentItems.reduce(
          (total, info) => total + info.lineItem.cyclePrice,
          0
        )
        const newTotalCyclePrice = newItems.reduce(
          (total, info) => total + info.lineItem.cyclePrice,
          0
        )
        const isFreeChange =
          currentTotalCyclePrice === 0 && newTotalCyclePrice === 0

        const isPriceChange = currentTotalCyclePrice !== newTotalCyclePrice

        const hasChanged =
          (isCycleChange && !hasPricingIdsChanged && !isFreeChange) ||
          // when upgrading or downgrading the cycle, dont bother showing plans that are free
          hasLengthChanged ||
          hasPricingIdsChanged ||
          isPriceChange

        const isAddingPlan = currentItems.length === 0 && newItems.length > 0

        const isUpgrade =
          isAddingPlan ||
          (currentItems.length === 1 &&
            newItems.length === 1 &&
            isBillingUpgrade(
              currentItems[0].pricing,
              newItems[0].pricing,
              currentBillingCycle,
              newBillingCycle
            ))

        // eslint-disable-next-line no-param-reassign
        infoByType[type] = {
          currentPlansInfo: currentItems,
          newPlansInfo: newItems,
          hasChanged,
          isEmpty: currentItems.length === 0 && newItems.length === 0,
          isUpgrade,
        }
        return infoByType
      }, {})
    },
    [
      currentPlansInfo,
      inputNewPlansInfo,
      isCycleChange,
      currentBillingCycle,
      newBillingCycle,
    ]
  )

  const fillChange = useCallback(
    (change, type, pricing) => {
      const { id: pricingId } = pricing
      // eslint-disable-next-line no-param-reassign
      change[`${type}PricingId`] = pricingId
      // eslint-disable-next-line no-param-reassign
      change[`${type}Quantity`] =
        type === 'current'
          ? currentPlans.find(p => p.pricing.id === pricing.id)?.quantity
          : quantityFor(pricing, inputNewPricings, usage)

      // eslint-disable-next-line no-param-reassign
      change[`${type}Cycle`] =
        type === 'current' ? currentBillingCycle : newBillingCycle

      // eslint-disable-next-line no-param-reassign
      change[`${type}Info`] =
        type === 'current'
          ? currentPlansInfo.find(i => i.pricing.id === pricing.id)
          : inputNewPlansInfo.find(i => i.pricing.id === pricing.id)
    },
    [
      currentBillingCycle,
      newBillingCycle,
      inputNewPricings,
      currentPlans,
      usage,
      currentPlansInfo,
      inputNewPlansInfo,
    ]
  )

  // The subscription, quantityFor, and cycle variables/functions should be defined in your context

  const changes = useMemo(
    () => {
      let allPricings = []
      let allNewPricings = []

      ALL.forEach(type => {
        const changesForType = changesByType[type]
        changesForType.currentPlansInfo.forEach(info => {
          allPricings.push(info.pricing)
        })
        changesForType.newPlansInfo.forEach(info => {
          allPricings.push(info.pricing)
          allNewPricings.push(info.pricing)
        })
      })

      allPricings = uniqBy(allPricings, ({ id }) => id)
      allNewPricings = uniqBy(allNewPricings, ({ id }) => id)

      const changesByTypeAndUsage = {}

      sortPricings(allPricings).forEach(pricing => {
        const { id: pricingId } = pricing
        const isCurrentPricing = currentPricings.some(p => p.id === pricingId)
        const isCurrentNewPricing = allNewPricings.some(p => p.id === pricingId)
        if (!changesByTypeAndUsage[pricing.type]) {
          changesByTypeAndUsage[pricing.type] = {}
        }
        if (!changesByTypeAndUsage[pricing.type][pricing.usageFrom]) {
          changesByTypeAndUsage[pricing.type][pricing.usageFrom] = []
        }
        let added = false
        const groupedChanges =
          changesByTypeAndUsage[pricing.type][pricing.usageFrom]

        groupedChanges.forEach(change => {
          if (added) return

          if (!change.currentPricingId && isCurrentPricing) {
            added = true
            fillChange(change, 'current', pricing)
          } else if (!change.newPricingId && isCurrentNewPricing) {
            added = true
            fillChange(change, 'new', pricing)
          }
        })

        if (!added) {
          const newGroupedChange = {
            currentPricingId: null,
            currentQuantity: null,
            currentCycle: null,
            currentInfo: null,
            newPricingId: null,
            newQuantity: null,
            newCycle: null,
            newInfo: null,
          }
          if (isCurrentPricing) {
            fillChange(newGroupedChange, 'current', pricing)
          }
          if (isCurrentNewPricing) {
            fillChange(newGroupedChange, 'new', pricing)
          }
          groupedChanges.push(newGroupedChange)
        }
      })

      const relatedChanges = []
      Object.keys(changesByTypeAndUsage).forEach(type => {
        const typedChange = changesByTypeAndUsage[type]
        Object.keys(typedChange).forEach(usageFrom => {
          const typeChanges = typedChange[usageFrom]
          typeChanges.forEach(change => relatedChanges.push(change))
        })
      })

      const filteredRelatedChanges = relatedChanges.filter(
        change =>
          !(
            [null, 0].includes(change.currentQuantity) &&
            [null, 0].includes(change.newQuantity)
          )
      )

      return filteredRelatedChanges
    },
    [currentPricings, fillChange, changesByType]
  )

  const isUpgradingToAnnual =
    newBillingCycleOrDefault === BILLING_CYCLE_ANNUAL &&
    currentBillingCycle === BILLING_CYCLE_MONTHLY

  const isUpgradingFromLegacy = useMemo(
    () => {
      return (
        currentPlansInfo.some(info => info.isLegacy) &&
        inputNewPlansInfo.some(info => !info.isLegacy)
      )
    },
    [currentPlansInfo, inputNewPlansInfo]
  )

  const isUpgrade = Object.keys(changesByType).some(type => {
    return changesByType[type].isUpgrade && type !== ADDON
  })

  const changeType =
    isUpgrade ||
    isUpgradingToAnnual ||
    (!isCycleChange && isUpgradingFromLegacy)
      ? 'upgrade'
      : 'downgrade'

  const totalTypeWithChanges = useMemo(
    () => {
      return Object.keys(changesByType).reduce((total, type) => {
        return total + changesByType[type].hasChanged ? 1 : 0
      })
    },
    [changesByType]
  )

  const newPricingIds = useMemo(
    () => {
      return ALL.reduce((allNewPid, type) => {
        changesByType[type].newPlansInfo.forEach(info => {
          allNewPid.push(info.pricing.id)
        })
        return allNewPid
      }, [])
    },
    [changesByType]
  )

  const newPricings = useMemo(
    () => {
      return newPricingIds.map(id => pricingsById[id])
    },
    [newPricingIds, pricingsById]
  )

  const newPlansInfo = useMemo(
    () => {
      return buildPricingInfo(
        newPricings,
        accountBillingVersionByType,
        newBillingCycle,
        usage,
        appliedDiscount
      )
    },
    [
      newPricings,
      accountBillingVersionByType,
      newBillingCycle,
      usage,
      appliedDiscount,
    ]
  )

  const newAvailableFeatures = useSelector(state =>
    selectAvailableFeaturesByPricingIds(state, newPricingIds)
  )

  const isLimitsExceededOnNew = useMemo(
    () => isLimitsExceeded(newAvailableFeatures, usage),
    [newAvailableFeatures, usage]
  )

  const removedFeatures = useMemo(
    () => {
      const removed = []

      BOOLEAN_FEATURES.forEach(feature => {
        if (
          checkBooleanRemoved(
            currentAvailableFeatures[feature],
            newAvailableFeatures[feature]
          )
        ) {
          removed.push(feature)
        }
      })
      NUMBER_FEATURES.forEach(feature => {
        if (
          checkNumberRemoved(
            currentAvailableFeatures[feature],
            newAvailableFeatures[feature]
          )
        ) {
          removed.push(feature)
        }
      })
      return removed
    },
    [currentAvailableFeatures, newAvailableFeatures]
  )

  const exceededFeatures = useMemo(
    () => {
      const exceeded = []

      BOOLEAN_FEATURES.forEach(feature => {
        if (checkBooleanLimit(usage[feature], newAvailableFeatures[feature])) {
          exceeded.push(feature)
        }
      })
      NUMBER_FEATURES.forEach(feature => {
        if (checkNumberLimit(usage[feature], newAvailableFeatures[feature])) {
          exceeded.push(feature)
        }
      })
      return exceeded
    },
    [usage, newAvailableFeatures]
  )

  const isDowngradeCycle = isCycleChange && !isUpgradingToAnnual

  const planLimitsExceeded =
    isDowngradeCycle ||
    (totalTypeWithChanges === 0 ? false : isLimitsExceededOnNew)

  return {
    isLoading,
    currentPricingIds,
    newPricingIds,
    changeType,
    changesByType,
    changes,
    totalTypeWithChanges,
    currentBillingCycle,
    newBillingCycle,
    currentPlansInfo,
    newPlansInfo,
    isLimitsExceeded: isLimitsExceededOnNew,
    isUpgradingToAnnual,
    isUpgradingFromLegacy,
    isCycleChange,
    nextBillDate,
    currency,
    discount: appliedDiscount,
    removedFeatures,
    exceededFeatures,
    planLimitsExceeded,
  }
}

export const useSetupIntent = ({ onSubmitted }) => {
  const dispatch = useDispatch()

  const reloadBilling = useCallback(
    () => {
      return dispatch(doFetchBillingData())
    },
    [dispatch]
  )

  const onSetupSuccess = useCallback(
    async setupIntentId => {
      // We're re-using this sertup payment feedback drawer for the stripe success
      // redirect. In this case there is non setup intent id and we simply need to
      // call the onSubmitted which will start the subscription
      if (setupIntentId !== 'none') {
        await dispatch(doSetupIntentSuccess(setupIntentId))
        await dispatch(doFetchSetupIntents())
        await reloadBilling()
      }
      if (isFunction(onSubmitted)) onSubmitted(setupIntentId)
    },
    [dispatch, reloadBilling, onSubmitted]
  )

  return {
    onSetupSuccess,
  }
}

export const useSetupSubscription = ({
  convertTrials,
  pricingIds,
  billingCycle: inputBillingCycle,
}) => {
  const dispatch = useDispatch()
  const { openDrawer: openBillingExternalApprove } = useDrawer({
    type: DRAWER_TYPE_BILLING_EXTERNAL_APPROVE,
  })

  const { updateSubscription, plans, billingCycle } = useBilling()

  const saveSubscription = useCallback(
    async () => {
      const updateResponse = await updateSubscription(
        {
          pricingIds: pricingIds
            ? pricingIds.split('|')
            : plans.map(plan => plan.pricing.id),
          cycle: inputBillingCycle || billingCycle,
        },
        { convertTrials: !!convertTrials }
      )
      const {
        updateSubscription: { pendingSubscription: { approvalUrl } = {} } = {},
      } = updateResponse

      if (approvalUrl) {
        openBillingExternalApprove()
      } else {
        await dispatch(doFetchSettingsBootstrapData())
      }

      return updateResponse
    },
    [
      convertTrials,
      pricingIds,
      billingCycle,
      inputBillingCycle,
      plans,
      updateSubscription,
      openBillingExternalApprove,
      dispatch,
    ]
  )

  return {
    saveSubscription,
  }
}
