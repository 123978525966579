import { doAppGraphqlRequest } from 'ducks/requests/operations'

import { doFetchAllCustomFieldCategories } from '../../customFieldCategories/operations/fetching'
import { selectAllCustomFieldsIsLoading } from '../selectors'
import { customFieldsNormalizationSchema } from '../schema'
import {
  FETCH_ALL_CUSTOM_FIELDS_STARTED,
  FETCH_ALL_CUSTOM_FIELDS_SUCCESS,
  FETCH_CUSTOM_FIELDS,
  FETCH_ALL_TRASHED_CUSTOM_FIELDS_STARTED,
  FETCH_ALL_TRASHED_CUSTOM_FIELDS_SUCCESS,
  FETCH_TRASHED_CUSTOM_FIELDS,
} from '../types'

const urlQuery = `
  url {
    domain
    hash
    path
    protocol
    query
    raw
  }
`

export const customFieldValueNodeQuery = `
  nodes {
    id
    customField {
      key
      isArray
    }
    value {
      ... on Text {
        content
      }
      ... on Address {
        city
        country
        postalCode
        state
        street
        street2
      }
      ... on IpAddress {
        address
        city
        subdivisions
        country
      }
      ... on Link {
        text
        link
      }
      ... on Money {
        amount
        currencyCode
      }
      ... on Session {
        totalTimeOnPage
        totalPageViews
        firstPageView {
          referrer {
            source
            medium
          }
          ${urlQuery}
          occurredAt
        }
        lastPageView {
          ${urlQuery}
          occurredAt
        }
        penultimatePageView {
          ${urlQuery}
          occurredAt
        }
      }
      ... on UserAgent {
        browser {
          family
          version
        }
        device {
          family
        }
        platform {
          family
          version
        }
      }
      ... on File {
        url
      }
    }
  }
`

export const customFieldFields = `
  id
  category { createdAt, deletable, id, type, key, name }
  deletable
  description
  icon
  isArray
  key
  name
  handleType
  options(first: 100) { 
    nodes {
      label
      value
    }
  }
  type
`

const fetchCustomFieldQuery = `
  query CustomFields($after: String) {
    customFields(first: 20, after: $after, filter: { deleted: false }) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ${customFieldFields}
      }
    }
  }
`
export function doFetchAllCustomFields() {
  return async (dispatch, getState) => {
    const state = getState()
    const isLoading = selectAllCustomFieldsIsLoading(state)
    if (isLoading) return null
    let hasNextPage
    let after

    dispatch({ type: FETCH_ALL_CUSTOM_FIELDS_STARTED })
    do {
      const variables = {
        after,
      }
      // eslint-disable-next-line no-await-in-loop
      const result = await dispatch(
        doAppGraphqlRequest(
          FETCH_CUSTOM_FIELDS,
          fetchCustomFieldQuery,
          variables,
          {
            normalizationSchema: customFieldsNormalizationSchema,
          }
        )
      )
      const pageInfo = result.customFields.pageInfo
      hasNextPage = pageInfo.hasNextPage
      after = pageInfo.endCursor
    } while (hasNextPage)
    dispatch({ type: FETCH_ALL_CUSTOM_FIELDS_SUCCESS })
    return null
  }
}

const fetchCustomTrashedFieldQuery = `
  query TrashedCustomFields($after: String) {
    customFields(first: 20, after: $after, filter: { deleted: true }) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ${customFieldFields}
      }
    }
  }
`
export function doFetchAllTrashedCustomFields() {
  return async (dispatch, getState) => {
    const state = getState()
    const isLoading = selectAllCustomFieldsIsLoading(state)
    if (isLoading) return null
    let hasNextPage
    let after

    dispatch({ type: FETCH_ALL_TRASHED_CUSTOM_FIELDS_STARTED })
    do {
      const variables = {
        after,
      }
      // eslint-disable-next-line no-await-in-loop
      const result = await dispatch(
        doAppGraphqlRequest(
          FETCH_TRASHED_CUSTOM_FIELDS,
          fetchCustomTrashedFieldQuery,
          variables,
          {
            normalizationSchema: customFieldsNormalizationSchema,
            transformResponse: data => {
              return {
                ...data,
                customFields: {
                  ...data.customFields,
                  nodes: [
                    ...data.customFields.nodes.map(node => ({
                      ...node,
                      deletedAt: true,
                    })),
                  ],
                },
              }
            },
          }
        )
      )
      const pageInfo = result.customFields.pageInfo
      hasNextPage = pageInfo.hasNextPage
      after = pageInfo.endCursor
    } while (hasNextPage)
    dispatch({ type: FETCH_ALL_TRASHED_CUSTOM_FIELDS_SUCCESS })
    return null
  }
}

export function doBootstrapCustomFieldsAndCategories() {
  return dispatch => {
    dispatch(doFetchAllCustomFields())
    dispatch(doFetchAllCustomFieldCategories())
  }
}
