import { createSelector } from 'reselect'
import { selectIsComposingNewTicket } from 'selectors/tickets/current/selectIsComposingNewTicket'
import { createBasicSelector } from 'util/redux'

function selectBase(state) {
  return state.editor || {}
}

export const selectIsEditorFocused = createSelector(
  selectBase,
  editor => editor.isFocused
)

export const selectIsNoteEditor = state => selectBase(state).mode === 'note'
export const selectIsReplyEditor = state => selectBase(state).mode === 'reply'
export const selectIsEditorVisible = state => !!selectBase(state).isVisible
export const selectIsEditorPinned = createBasicSelector(
  selectBase,
  selectIsComposingNewTicket,
  (base, isNewTicket) => {
    if (isNewTicket) return false
    return !!base.isPinned
  }
)
