/* eslint-disable react/jsx-filename-extension */
import { createSelector } from 'reselect'
import config from 'config'
import storage from 'util/storage'
import {
  DRAWER_TYPE_SHOPIFY_STORE_ADD,
  DRAWER_TYPE_SHOPIFY_CONFIGURE,
  DRAWER_TYPE_INTEGRATION_INSTALL_ZAPIER,
  DRAWER_TYPE_INTEGRATION_MANAGE_MAILCHIMP,
  DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE,
  DRAWER_TYPE_INTEGRATION_SLACK_MAP_USERNAMES,
  DRAWER_TYPE_INTEGRATION_RECHARGE_INSTALL,
  DRAWER_TYPE_INTEGRATION_RECHARGE_CONFIGURE,
} from 'ducks/drawers/types'
import { SETTINGS_INTEGRATIONS_PROVIDER_PAGE } from 'subapps/settings/types'
import GitHubModal from 'subapps/settings/components/Integrations/GitHub/Modal'
import gitHubIcon from 'assets/integrations/github/icon-96.png'
import TrelloModal from 'subapps/settings/components/Integrations/Trello/Modal'
import trelloIcon from 'assets/integrations/trello/icon-96.png'
import JiraCloudModal from 'subapps/settings/components/Integrations/JiraCloud/Modal'
import jiraCloudIcon from 'assets/integrations/jira-cloud/icon-256.png'
import JiraServerModal from 'subapps/settings/components/Integrations/JiraServer/Modal'
import jiraServerIcon from 'assets/integrations/jira-server/icon-256.png'
import SalesforceModal from 'subapps/settings/components/Integrations/Salesforce/Modal'
import salesforceIcon from 'assets/integrations/salesforce/icon-96.png'
import HubspotModal from 'subapps/settings/components/Integrations/Hubspot/Modal'
import hubspotIcon from 'assets/integrations/hubspot/icon-24.png'
import {
  INSTALLATION_ACTION_TYPE_ACTIVATE,
  INSTALLATION_ACTION_TYPE_DRAWER_ACTION,
  INSTALLATION_ACTION_TYPE_OAUTH_LINK,
  INSTALLATION_ACTION_TYPE_EXTERNAL_LINK,
  INSTALLATION_ACTION_TYPE_LINK,
  INSTALLATION_ACTION_TYPE_AUTH_BUTTON,
  INSTALLATION_ACTION_TYPE_EMAIL_MARKETING_OAUTH_LINK,
} from 'ducks/integrations/utils/installationActionType'
import {
  ACCOUNT,
  ACCOUNT_PREFERENCES,
  BILLING_USAGE,
  INTEGRATION_PROVIDER,
} from 'ducks/integrations/utils/credentialsType'
import { FEATURE_INTEGRATION_ZAPIER } from 'ducks/billing/featureTypes'

// eslint-disable-next-line no-unused-vars
const selectIntegrationData = createSelector(state => {
  return [
    {
      id: 'TRELLO',
      name: 'Trello',
      description:
        'Quickly create new Trello cards directly from a conversation.',
      logo: 'Trello',
      visibility: true,
      installStateConfig: {
        type: INTEGRATION_PROVIDER,
      },
      installActionType: INSTALLATION_ACTION_TYPE_LINK,
      installActionTypeConfig: {
        modal: {
          component: TrelloModal,
          props: {
            provider: 'TRELLO',
            providerId: 'trello',
            logo: trelloIcon,
          },
        },
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'trello',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      configureActionConfig: {
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'trello',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      features: [
        {
          id: '1',
          content: `Create a new card from the ${app.t('Mailbox')}`,
        },
        {
          id: '2',
          content: 'Attach conversations to new or existing cards',
        },
        {
          id: '3',
          content: 'View Trello card actions in your conversation history',
        },
        {
          id: '4',
          content:
            'Have conversations re-opened when a Trello card has been updated',
        },
        {
          id: '5',
          content: 'View all linked cards and their details in the sidebar',
        },
      ],
    },
    {
      id: 'DELIGHTED',
      name: 'Delighted',
      description:
        'Delighted uses the Net Promoter System to gather real feedback from your customers in minutes.',
      logo: 'Delighted',
      visibility: false,
      isThirdParty: true,
      installActionType: INSTALLATION_ACTION_TYPE_EXTERNAL_LINK,
      installActionTypeConfig: {
        installButtonText: 'Go To Delighted',
        path: 'https://delighted.com/',
        target: '_blank',
      },
    },
    {
      id: 'FACEBOOK',
      name: 'Facebook',
      description:
        'Turn Facebook posts on your timeline into conversations and respond to customers from your Groove dashboard.',
      logo: 'Facebook',
      visibility: false,
      installActionType: INSTALLATION_ACTION_TYPE_OAUTH_LINK,
      installStateConfig: {
        type: ACCOUNT,
        // accountKey: 'has_facebook_installed',
      },
    },
    {
      id: 'MAILCHIMP',
      name: 'Mailchimp',
      description:
        'See subscriber profiles alongside your conversations, and subscribe or unsubscribe any customer without leaving Groove.',
      logo: 'Mailchimp',
      visibility: true,
      isAdminOnly: true,
      installStateConfig: {
        type: ACCOUNT_PREFERENCES,
        accountPreferenceKey: 'mail_chimp_installed',
      },
      installActionType: INSTALLATION_ACTION_TYPE_EMAIL_MARKETING_OAUTH_LINK,
      installActionTypeConfig: {
        backendType: 'MailChimp',
      },
      configureActionConfig: {
        drawerType: DRAWER_TYPE_INTEGRATION_MANAGE_MAILCHIMP,
      },
      features: [
        {
          id: '1',
          content: `See customer mailing list subscriptions next to every conversation`,
        },
        {
          id: '2',
          content: 'View a customer’s profile for any mailing list',
        },
        {
          id: '3',
          content:
            'Subscribe or unsubscribe a customer from a Mailchimp list without leaving Groove',
        },
      ],
    },
    {
      id: 'GITHUB',
      name: 'GitHub',
      description:
        'Quickly create new GitHub issues directly from a conversation.',
      logo: 'Github',
      visibility: true,
      installStateConfig: {
        type: INTEGRATION_PROVIDER,
      },
      installActionType: INSTALLATION_ACTION_TYPE_LINK,
      installActionTypeConfig: {
        modal: {
          component: GitHubModal,
          props: {
            provider: 'GITHUB',
            providerId: 'github',
            logo: gitHubIcon,
          },
        },
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'github',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      configureActionConfig: {
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'github',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      features: [
        {
          id: '1',
          content: `Create a new issue from the ${app.t('Mailbox')}`,
        },
        {
          id: '2',
          content: 'Attach conversations to new or existing issues',
        },
        {
          id: '3',
          content: 'View GitHub issue actions in your conversation history',
        },
        {
          id: '4',
          content:
            'Have conversations re-opened when a GitHub issue has been updated',
        },
        {
          id: '5',
          content: 'View all linked issues and their details in the sidebar',
        },
      ],
    },
    {
      id: 'SHOPIFY_V2',
      name: 'Shopify',
      description: `Supercharge your store's support by integrating Shopify right in your Groove ${app.t(
        'mailbox'
      )}.`,
      logo: 'Shopify',
      visibility: true,
      isAdminOnly: true,
      features: [
        {
          id: '1',
          content: "See all your customer's orders & shipping details",
        },
        {
          id: '2',
          content: 'Create and duplicate orders',
        },
        {
          id: '3',
          content: 'Refund existing orders',
        },
        {
          id: '4',
          content: "View the customer's timeline",
        },
        {
          id: '5',
          content: 'Multiple store support',
        },
      ],
      featuresFooter: {
        type: 'info',
        content: (
          <>
            This integration is <b>free to use.</b> The prices shown on the
            Shopify App Store listing don't apply to your account.
          </>
        ),
      },
      installStateConfig: {
        type: INTEGRATION_PROVIDER,
        allowsMultipleInstalls: true,
      },
      // uses the default app store link but we enable
      installActionType:
        storage.get('showShopifyV2LegacyInstallMode') === true
          ? INSTALLATION_ACTION_TYPE_DRAWER_ACTION
          : INSTALLATION_ACTION_TYPE_EXTERNAL_LINK,
      installActionTypeConfig: {
        drawerType:
          storage.get('showShopifyV2LegacyInstallMode') === true
            ? DRAWER_TYPE_SHOPIFY_STORE_ADD
            : undefined,
        path: !storage.get('showShopifyV2LegacyInstallMode')
          ? config.shopify.appStoreGrooveAppUrl
          : undefined,
        target: !storage.get('showShopifyV2LegacyInstallMode')
          ? '_self'
          : undefined,
      },
      configureActionConfig: {
        drawerType: DRAWER_TYPE_SHOPIFY_CONFIGURE,
      },
      uninstallActionType: INTEGRATION_PROVIDER,
      uninstallActionConfig: {
        deleteDrawerProps: {
          single: {
            deleteBtnText: 'Remove Store',
            title: 'Remove Shopify Store',
            header: 'You are about to remove the store',
          },
          all: {
            title: 'Uninstall Shopify',
          },
        },
      },
    },
    {
      id: 'RECHARGE',
      name: 'Recharge',
      description:
        'Pull in customer information, and view and manage subscriptions and orders right in your sidebar.',
      logo: 'Recharge',
      visibility: true,
      isAdminOnly: true,
      features: [
        {
          id: '1',
          content: 'See all your customer subscriptions and order details',
        },
        {
          id: '2',
          content: 'Cancel and reactivate subscriptions',
        },
        {
          id: '3',
          content: 'Skip orders',
        },
        {
          id: '4',
          content: 'Multiple store support',
        },
      ],
      installActionType: INSTALLATION_ACTION_TYPE_DRAWER_ACTION,
      installActionTypeConfig: {
        drawerType: DRAWER_TYPE_INTEGRATION_RECHARGE_INSTALL,
      },
      installStateConfig: {
        type: INTEGRATION_PROVIDER,
      },
      configureActionConfig: {
        drawerType: DRAWER_TYPE_INTEGRATION_RECHARGE_CONFIGURE,
      },
      uninstallActionType: INTEGRATION_PROVIDER,
    },
    {
      id: 'SLACK',
      name: 'Slack',
      description:
        'Your team already stopped using email to talk to each other. Why not use Slack for customer support too? Receive notifications after every conversation, note, and more.',
      logo: 'Slack',
      visibility: true,
      installStateConfig: {
        type: ACCOUNT_PREFERENCES,
        accountPreferenceKey: 'team_chat_app',
      },
      installActionType: INSTALLATION_ACTION_TYPE_OAUTH_LINK,
      installActionTypeConfig: {
        installButtonText: 'Connect Slack',
        oauth: {
          oauthService: 'SLACK',
          title: 'Connect Slack',
        },
        onSuccessConfig: {
          drawerType: DRAWER_TYPE_INTEGRATION_SLACK_MAP_USERNAMES,
        },
      },
      configureActionConfig: {
        drawerType: DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE,
      },
      uninstallActionType: ACCOUNT_PREFERENCES,
      uninstallActionConfig: {
        accountPreferenceKey: 'team_chat_app',
        // HACK (jscheel): The nodejs GQL server didn't support null values until
        // 2016, and we are still behind that. Therefore we send an empty string
        // and handle it on our node gql side.
        accountPreferenceValue: '',
        deleteDrawerProps: {
          all: {
            title: 'Uninstall Slack',
          },
        },
      },
      features: [
        {
          id: '1',
          content: `Easily connect your Groove ${app.t(
            'mailboxes'
          )} to your Slack channels and customize the routing between each.`,
        },
        {
          id: '2',
          content: 'Choose which actions fire notifications in Slack.',
        },
        {
          id: '3',
          content: `Grab ${app.t(
            'agent_possessive_with_article'
          )} attention in Slack when mentioning them in a ${app.t(
            'ticket'
          )} from your Groove ${app.t('mailbox')}.`,
        },
      ],
    },
    {
      id: 'STRIPE',
      name: 'Stripe',
      description: 'View Stripe data on the conversation.',
      logo: 'Stripe',
      visibility: true,
      isAdminOnly: true,
      installStateConfig: {
        type: INTEGRATION_PROVIDER,
      },
      installActionType: INSTALLATION_ACTION_TYPE_AUTH_BUTTON,
      installActionTypeConfig: {
        authButtonOptions: {
          id: 'STRIPE',
          name: 'Stripe',
          provider: 'stripe',
          authorize: {
            message: 'Taking you to Stripe',
            path: '/auth/stripe_connect',
            params: () => ({ return_to: window.location.href }),
          },
        },
      },
      configureActionConfig: {},
    },
    {
      id: 'ASK_NICELY',
      name: 'AskNicely',
      description:
        'Send Net Promoter Score surveys when a conversation is closed.',
      logo: 'AskNicely',
      visibility: false,
      installActionType: INSTALLATION_ACTION_TYPE_EXTERNAL_LINK,
      installActionTypeConfig: {
        installButtonText: 'Go To AskNicely',
        path:
          'http://www.asknice.ly/?utm_source=groove&utm_medium=integrations&utm_campaign=groovesetup',
        target: '_blank',
      },
    },
    {
      id: 'CAMPAIGN_MONITOR',
      name: 'Campaign Monitor',
      description:
        'Subscribe or unsubscribe any customer without leaving Groove.',
      logo: 'CampaignMonitor',
      visibility: false,
      installStateConfig: {
        type: ACCOUNT_PREFERENCES,
        accountPreferenceKey: 'campaign_monitor_installed',
      },
      installActionType: INSTALLATION_ACTION_TYPE_OAUTH_LINK,
    },
    {
      id: 'CONSTANT_CONTACT',
      name: 'Constant Contact',
      description:
        'Subscribe or unsubscribe any customer without leaving Groove.',
      logo: 'ConstantContact',
      visibility: false,
      installStateConfig: {
        type: ACCOUNT_PREFERENCES,
        accountPreferenceKey: 'constant_contact_installed',
      },
      installActionType: INSTALLATION_ACTION_TYPE_OAUTH_LINK,
    },
    {
      id: 'EVOICE',
      name: 'eVoice',
      description: 'Create Groove contacts from eVoice emails.',
      logo: 'EVoice',
      visibility: false,
      isThirdParty: true,
      installActionType: INSTALLATION_ACTION_TYPE_ACTIVATE,
      installStateConfig: {
        type: ACCOUNT_PREFERENCES,
        accountPreferenceKey: 'evoice_installed',
      },
      installActionTypeConfig: {},
    },
    {
      id: 'WEBHOOKS',
      name: 'Webhooks',
      description:
        'Comfortable with code? Add Groove functionality to any app with our easy-to-use webhooks.',
      logo: 'Webhooks',
      visibility: false,
      features: [
        {
          id: '1',
          content: `Trigger alerts in your own app whenever a Groove ${app.t(
            'ticket'
          )} is created or acted on`,
        },
        {
          id: '2',
          content:
            'Have new customers created in Groove get added directly to your CRM or other database',
        },
        {
          id: '3',
          content: `Create to-do's from your ${app.t(
            'tickets'
          )} inside your task management software`,
        },
        {
          id: '4',
          content: '…and much more.',
        },
      ],
    },
    {
      id: 'JUST_CALL',
      name: 'JustCall',
      description: `See your call logs and call recordings directly in Groove dashboard and ${app.t(
        'mailboxes'
      )}.`,
      logo: 'JustCall',
      visibility: false,
      isThirdParty: true,
      installActionType: INSTALLATION_ACTION_TYPE_EXTERNAL_LINK,
      installActionTypeConfig: {
        installButtonText: 'Go To JustCall',
        path: 'https://justcall.io/',
        target: '_blank',
      },
    },
    {
      id: 'ZAPIER',
      name: 'Zapier',
      description:
        'Zapier enables anyone (marketing, support, legal, HR, ops, product, etc.) to connect together the web apps they use to run their business, without writing code.',
      logo: 'Zapier',
      visibility: true,
      isThirdParty: false,
      isAdminOnly: true,
      installActionType: INSTALLATION_ACTION_TYPE_DRAWER_ACTION,
      installActionTypeConfig: {
        drawerType: DRAWER_TYPE_INTEGRATION_INSTALL_ZAPIER,
        skipOverview: true,
      },
      configureActionConfig: {
        drawerType: DRAWER_TYPE_INTEGRATION_INSTALL_ZAPIER,
      },
      installStateConfig: {
        type: BILLING_USAGE,
        billingUsageKey: FEATURE_INTEGRATION_ZAPIER,
        allowsMultipleInstalls: true,
      },
      features: [
        {
          id: '1',
          content:
            'SMS alerts or push notifications for new Groove conversations',
        },
        {
          id: '2',
          content:
            'Create JIRA issues or Trello cards from Groove conversations',
        },
        {
          id: '3',
          content: 'Create Salesforce cases from Groove conversations',
        },
        {
          id: '4',
          content: 'Create Groove conversations via Gravity Forms',
        },
        {
          id: '5',
          content: 'Create Basecamp to-dos from Groove conversations',
        },
        {
          id: '6',
          content: 'Create Github issues from Groove conversations',
        },
        {
          id: '7',
          content: 'and many, many more…',
        },
      ],
    },
    {
      id: 'VEXTRAS',
      name: 'Vextras',
      description:
        'View order details alongside your Groove support conversations for Bigcommerce, 3dcart& Volusion powered shopping carts.',
      logo: 'Vextras',
      visibility: false,
      installActionType: INSTALLATION_ACTION_TYPE_EXTERNAL_LINK,
      installActionTypeConfig: {
        installButtonText: 'Go To Vextras',
        path: 'https://www.vextras.com/setup-groove',
        target: '_blank',
      },
      features: [
        {
          id: '1',
          content: 'View recent order details in the Groove sidebar',
        },
        {
          id: '2',
          content: 'Quickly access past orders and customer details',
        },
        {
          id: '3',
          content: 'Keep your team in sync with any customer conversations',
        },
      ],
    },
    {
      id: 'JIRA_CLOUD',
      name: 'Jira Cloud',
      description:
        'Quickly create new Jira issues directly from a conversation.',
      logo: 'Jira',
      visibility: true,
      premium: true,
      installStateConfig: {
        type: INTEGRATION_PROVIDER,
      },
      installActionType: INSTALLATION_ACTION_TYPE_LINK,
      installActionTypeConfig: {
        modal: {
          component: JiraCloudModal,
          props: {
            provider: 'JIRA_CLOUD',
            providerId: 'jira_cloud',
            logo: jiraCloudIcon,
          },
        },
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'jira_cloud',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      configureActionConfig: {
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'jira_cloud',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      features: [
        {
          id: '1',
          content: `Create a new issue from the ${app.t('Mailbox')}`,
        },
        {
          id: '2',
          content: 'Attach conversations to new or existing issues',
        },
        {
          id: '3',
          content: 'View Jira issue actions in your conversation history',
        },
        {
          id: '4',
          content:
            'Have conversations re-opened when a Jira issue has been updated',
        },
        {
          id: '5',
          content: 'View all linked issues and their details in the sidebar',
        },
      ],
    },
    {
      id: 'JIRA_SERVER',
      name: 'Jira Server',
      description:
        'Quickly create new Jira issues directly from a conversation.',
      logo: 'JiraServer',
      visibility: true,
      premium: true,
      installStateConfig: {
        type: INTEGRATION_PROVIDER,
      },
      installActionType: INSTALLATION_ACTION_TYPE_LINK,
      installActionTypeConfig: {
        modal: {
          component: JiraServerModal,
          props: {
            provider: 'JIRA_SERVER',
            providerId: 'jira_server',
            logo: jiraServerIcon,
          },
        },
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'jira_server',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      configureActionConfig: {
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'jira_server',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      features: [
        {
          id: '1',
          content: `Create a new issue from the ${app.t('Mailbox')}`,
        },
        {
          id: '2',
          content: 'Attach conversations to new or existing issues',
        },
        {
          id: '3',
          content:
            'View Jira Server issue actions in your conversation history',
        },
        {
          id: '4',
          content:
            'Have conversations re-opened when a Jira Server issue has been updated',
        },
        {
          id: '5',
          content: 'View all linked issues and their details in the sidebar',
        },
      ],
    },
    {
      id: 'SALESFORCE',
      name: 'Salesforce',
      description: `Pull in Salesforce lead, contact, and account information right into your ${app.t(
        'Mailbox'
      )} sidebar, and create a new lead for new email addresses.`,
      logo: 'Salesforce',
      visibility: true,
      premium: true,
      installStateConfig: {
        type: INTEGRATION_PROVIDER,
      },
      installActionType: INSTALLATION_ACTION_TYPE_LINK,
      installActionTypeConfig: {
        modal: {
          component: SalesforceModal,
          props: {
            provider: 'SALESFORCE',
            providerId: 'salesforce',
            logo: salesforceIcon,
          },
        },
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'salesforce',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      configureActionConfig: {
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'salesforce',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
    },
    {
      id: 'HUBSPOT',
      name: 'HubSpot',
      description: `Pull in HubSpot contact and company information right into your ${app.t(
        'Mailbox'
      )} sidebar, and create a new contact for new email addresses.`,
      logo: 'Hubspot',
      visibility: true,
      premium: true,
      installStateConfig: {
        type: INTEGRATION_PROVIDER,
      },
      installActionType: INSTALLATION_ACTION_TYPE_LINK,
      installActionTypeConfig: {
        modal: {
          component: HubspotModal,
          props: {
            provider: 'HUBSPOT',
            providerId: 'hubspot',
            logo: hubspotIcon,
          },
        },
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'hubspot',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
      configureActionConfig: {
        linkTo: {
          type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
          payload: {
            provider: 'hubspot',
          },
        },
        linkOptions: {
          preserveQuery: true,
        },
      },
    },
  ]
})

export default selectIntegrationData
