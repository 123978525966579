import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = theme => css`
  width: ${rem(320)};
  background-color: ${theme.color.monochrome.white};
  box-shadow: 0 ${rem(2)} ${rem(4)} 0
    rgba(${theme.color.monochrome.black_rgba}, 0.1);
  border-radius: ${rem(6)};
  border: 1px solid ${theme.color.monochrome.light};
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const templatesWrapper = css`
  overflow: auto;
  flex-grow: 1;
`

const menuHeader = theme => css`
  font-weight: ${theme.fontWeight.medium};
  padding: ${rem(6)} ${rem(16)};
  cursor: pointer;

  &.activeItem {
    background-color: ${theme.color.info['50']};
  }

  & + [aria-expanded='false'] {
    display: none;
  }

  & + [aria-expanded='true'] {
    display: block;
  }
`

const templateItem = theme => css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${rem(6)} ${rem(12)} ${rem(6)} ${rem(24)};
  cursor: pointer;

  .star-icon {
    visibility: hidden;
  }

  &.activeItem {
    background-color: ${theme.color.info['50']};
  }

  &:hover {
    .star-icon {
      visibility: visible;
    }
  }
`

const searchContainer = theme => css`
  position: sticky;
  top: 0;
  background-color: ${theme.color.monochrome.white};
`

const search = theme => css`
  font-size: inherit;
  margin: ${rem(12)};

  && input {
    color: ${theme.color.info['900']};
    font-size: inherit;
    &::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
`

export const styles = {
  menuHeader,
  templateItem,
  container,
  templatesWrapper,
  search,
  searchContainer,
}
