import {
  FETCH_ALL_EXCLUDED_DOMAINS_STARTED,
  FETCH_ALL_EXCLUDED_DOMAINS_FAILED,
  FETCH_ALL_EXCLUDED_DOMAINS_SUCCESS,
  FETCH_CUSTOM_FIELDS_FAILED,
} from '../types'

export default function isLoading(state = false, { type }) {
  if (type === FETCH_ALL_EXCLUDED_DOMAINS_STARTED) return true
  if (
    [
      FETCH_ALL_EXCLUDED_DOMAINS_SUCCESS,
      FETCH_ALL_EXCLUDED_DOMAINS_FAILED,
      FETCH_CUSTOM_FIELDS_FAILED,
    ].includes(type)
  ) {
    return false
  }
  return state
}
