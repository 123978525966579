import { createSelector } from 'reselect'
import { selectBillingEstimate } from './selectBillingEstimate'

export const selectBillingEstimateForPlanChange = createSelector(
  selectBillingEstimate,
  ({ nextBillDate }) => ({
    nextBillDate,
    currency: 'USD',
  })
)
