import { UPDATE_ACCOUNT_SUCCESS } from 'constants/action_types'
import { createActionTypeReducer } from 'util/reducers'

import {
  FETCH_SLACK_APP_SUCCESS,
  FETCH_SLACK_CHANNELS_SUCCESS,
  FETCH_SLACK_PROFILES_SUCCESS,
  UPDATE_SLACK_APP_SUCCESS,
} from './types'

const defaultState = {
  app: null,
  channelsBySlackId: {},
  profilesBySlackId: {},
}

export default createActionTypeReducer(
  {
    [FETCH_SLACK_APP_SUCCESS]: (draftState, action) => {
      const slackApp = action.payload
      draftState.app = slackApp
    },
    [UPDATE_SLACK_APP_SUCCESS]: (draftState, action) => {
      const slackApp = action.payload
      draftState.app = slackApp
    },
    [FETCH_SLACK_CHANNELS_SUCCESS]: (draftState, action) => {
      const channels = action.payload
      draftState.channelsBySlackId = channels.reduce((memo, channel) => {
        // eslint-disable-next-line no-param-reassign
        memo[channel.slack_id] = channel
        return memo
      }, {})
    },
    [FETCH_SLACK_PROFILES_SUCCESS]: (draftState, action) => {
      const profiles = action.payload
      draftState.profilesBySlackId = profiles.reduce((memo, profile) => {
        // eslint-disable-next-line no-param-reassign
        memo[profile.slack_id] = profile
        return memo
      }, {})
    },
    [UPDATE_ACCOUNT_SUCCESS]: (draftState, action) => {
      const { updateAccount: account } = action.payload
      if (!account.preferences.team_chat_app && draftState.app) {
        Object.keys(defaultState).forEach(key => {
          draftState[key] = defaultState[key]
        })
      }
    },
  },
  defaultState
)
