import { oauthTokenSelector } from 'selectors/app'
import { selectIsInChat } from 'selectors/location'
import { selectCurrentContactName } from 'ducks/crm/contacts/selectors'
import {
  selectCurrentTicketTitle,
  selectCurrentConversationUrl,
} from 'selectors/tickets'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import gitHubGraphQl, { CLIENT_ID } from '../gitHubGraphQl'

export default function doPostTicketLinkOnGitHubIssue(issueId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const ticketUrl = selectCurrentConversationUrl(state)
    const ticketTitle = selectCurrentTicketTitle(state)
    const ticketId = selectCurrentTicketId(state)
    const isChat = selectIsInChat(state)
    const contactName = selectCurrentContactName(state)
    const title = isChat ? `Chat with ${contactName}` : ticketTitle
    const body = `[#${ticketId}: ${title}](${ticketUrl})<!-- GROOVE_HQ_COMMENT -->`
    const query = `mutation ($body: String!, $clientId:String, $issueId: ID!) {
      addComment(input: { 
        body: $body, 
        clientMutationId: $clientId, 
        subjectId: $issueId 
      } ) {
        clientMutationId
      }
    }`

    const variables = {
      issueId,
      clientId: CLIENT_ID,
      body,
    }
    return gitHubGraphQl(token, query, variables)
  }
}
