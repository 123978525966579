import {
  doApiReadRequest,
  doApiWriteRequest,
  doAppGraphqlRequest,
} from 'ducks/requests/operations'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectTimeframe } from 'ducks/reports/controls'
import { selectCurrentEntityById } from 'ducks/entities/selectors'
import { convertScatterIdToRaw } from 'util/scatterSwap'
import { constructApiV1SortBy } from 'ducks/searches/utils/query'
import {
  CANCEL_DATA_EXPORT,
  CREATE_DATA_EXPORT,
  DOWNLOAD_DATA_EXPORT,
  FETCH_DATA_EXPORTS,
} from './types'
import { exportsNormalizationSchema } from './schema'
import { getAllQuery } from './queries'

export const doFetchExports = ({ queryId }) => {
  const sortBy = constructApiV1SortBy(queryId)
  let field = 'COMPLETED_AT'
  let direction = 'ASC'

  if (sortBy) {
    const parts = sortBy.split('_')
    direction = parts.pop()
    field = parts.join('_')
  }

  const cursor = '1'
  const query = getAllQuery

  return doAppGraphqlRequest(
    FETCH_DATA_EXPORTS,
    query,
    {
      orderBy: {
        field,
        direction,
      },
    },
    {
      normalizationSchema: exportsNormalizationSchema,
      searches: {
        queryId,
        cursor,
      },
    }
  )
}

export const doFetchExportsByIds = ({ ids }) => {
  const query = getAllQuery

  return doAppGraphqlRequest(
    FETCH_DATA_EXPORTS,
    query,
    {
      filter: {
        ids,
      },
    },
    {
      normalizationSchema: exportsNormalizationSchema,
    }
  )
}

export const doCreateExportFromCurrentTimeframe = (options = {}) => (
  dispatch,
  getState
) => {
  const method = 'POST'
  const targetStores = ['pending', 'current']
  const entityType = 'dataExport'
  const state = getState()
  const currentUser = selectCurrentUser(state)
  const timeframe = selectTimeframe(state)
  const id = 'new'
  const optimistEntity = {
    id,
    created_at: Date.now(),
    state: 'requested',
    creator_id: currentUser.id,
  }

  return dispatch(
    doApiWriteRequest(
      CREATE_DATA_EXPORT,
      'api/settings/exporting/exports',
      {
        date_range_start: timeframe.start.toString(),
        date_range_end: timeframe.end.toString(),
      },
      {
        method,
        optimist: {
          entities: {
            [entityType]: {
              [id]: optimistEntity,
            },
          },
        },
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['dataExport'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'New export requested',
            },
            failed: {
              content: 'Export request failed',
              onClickAction: () => {
                dispatch(doCreateExportFromCurrentTimeframe(options))
              },
            },
          },
          entities: {
            additionalActions: [
              {
                entityType,
                entityId: id,
                stores: targetStores,
                operation: 'remove',
                phases: ['SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doCancelOrDeleteExport = (id, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const rawId = convertScatterIdToRaw(id)
  const currentEntity = selectCurrentEntityById(state, 'dataExport', id)
  const newEntityState =
    currentEntity.state === 'completed' ? 'deleted' : 'cancelled'
  const method = 'DELETE'
  const targetStores = ['pending', 'current']
  const entityType = 'dataExport'

  return dispatch(
    doApiWriteRequest(
      CANCEL_DATA_EXPORT,
      `api/settings/exporting/exports/${rawId}`,
      {},
      {
        method,
        optimist: {},
        // app: true,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['dataExport'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content:
                newEntityState === 'cancelled'
                  ? 'Export cancelled'
                  : 'Export deleted',
            },
            failed: {
              content:
                newEntityState === 'cancelled'
                  ? 'Failed to cancel export'
                  : 'Failed to delete export',
              onClickAction: () => {
                dispatch(doCancelOrDeleteExport(id, options))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                entityType,
                entityId: id,
                stores: targetStores,
                operation: 'remove',
                phases: ['STARTED'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doCancelOrDeleteExports = (ids, options = {}) => dispatch => {
  return Promise.all(
    ids.map(id => dispatch(doCancelOrDeleteExport(id, options)))
  )
}

export const doDownloadExport = id => dispatch => {
  const rawId = convertScatterIdToRaw(id)

  return dispatch(
    doApiReadRequest(
      DOWNLOAD_DATA_EXPORT,
      `api/settings/exporting/exports/${rawId}/download`,
      {
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: 'Generating export download…',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Failed to download export',
              onClickAction: () => {
                dispatch(doDownloadExport(id))
              },
            },
          },
        },
      }
    )
  ).then(data => {
    window.open(data.url, '_blank')
  })
}
