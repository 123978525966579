import MatrixClientPeg from 'matrix-react-sdk/lib/MatrixClientPeg'
import dis from 'matrix-react-sdk/lib/dispatcher'
import Timer from 'matrix-react-sdk/lib/utils/Timer'
import { debounce } from 'util/functions'
import isEqual from 'fast-deep-equal'
import { MatrixEvent } from 'matrix-js-sdk'

// Time in ms after that a user is considered as unavailable/away
export const UNAVAILABLE_TIME_MS = 5 * 60 * 1000 // 5 mins
export const OFFLINE_TIME_MS = 15 * 60 * 1000 // 15 mins
export const KEEPALIVE_TIME_MS = 20 * 1000 // 20 seconds
export const PRESENCE_STATES = [
  'online',
  'offline',
  'unavailable',
  'org.matrix.msc3026.busy',
  'unknown',
]
const ACCOUNT_PRESENCE_EVENT_TYPE = 'g.presence'

export default class Presence {
  constructor() {
    this.autoPresenceTimer = null
    this.keepAliveTimer = null
    this.onAction = this.onAction.bind(this)
    this.dispatcherRef = null
    this.onAccountData = this.onAccountData.bind(this)
    this.unavailableTimeMs = UNAVAILABLE_TIME_MS
    this.offlineTimeMs = OFFLINE_TIME_MS
    this.autoPresence = true
    this.state = 'unknown'
  }

  initClient(client, onAccountPresence) {
    if (!client) return false
    this.onAccountPresence = onAccountPresence
    this.client = client
    this.client.on('accountData', this.onAccountData)
    const initialEvent =
      this.getPresenceEvent() || this.getDefaultPresenceEvent()
    if (initialEvent) {
      this.onAccountData(initialEvent)
      this.saveSettings()
    }
    return true
  }

  onAccountData(event) {
    if (event.getType() === ACCOUNT_PRESENCE_EVENT_TYPE) {
      const {
        unavailableTimeMs,
        offlineTimeMs,
        state,
        autoPresence,
      } = event.getContent()

      if (this.unavailableTimeMs !== unavailableTimeMs) {
        this.setAutoAway(unavailableTimeMs)
      }

      if (this.offlineTimeMs !== offlineTimeMs) {
        this.setAutoOffline(offlineTimeMs)
      }

      if (this.state !== state) {
        this.setState(state)
      }

      if (this.autoPresence !== autoPresence) {
        this.setAutoPresence(autoPresence)
      }
      if (this.onAccountPresence) {
        this.onAccountPresence(event)
      }
    }
  }

  getDefaultPresenceEvent() {
    return new MatrixEvent({
      type: ACCOUNT_PRESENCE_EVENT_TYPE,
      content: this.constructSettingsContent(),
    })
  }

  getPresenceEvent() {
    if (!this.client) return null
    return this.client.getAccountData(ACCOUNT_PRESENCE_EVENT_TYPE)
  }

  getSettings() {
    const event = this.getPresenceEvent()
    if (!event || !event.getContent()) return null
    return event.getContent()
  }

  getAutoPresenceTimeoutMs(state) {
    if (state === 'online') return this.unavailableTimeMs
    if (['org.matrix.msc3026.busy', 'unavailable'].includes(state))
      return this.offlineTimeMs
    return 24 * 60 * 60 * 1000 // 1 Day
  }

  constructSettingsContent() {
    return {
      unavailableTimeMs: this.unavailableTimeMs,
      offlineTimeMs: this.offlineTimeMs,
      autoPresence: this.autoPresence,
      state: this.state,
    }
  }

  saveSettings = debounce(() => {
    if (!this.client) return false
    const oldSettings = this.getSettings()
    const newSettings = this.constructSettingsContent()
    if (isEqual(oldSettings, newSettings)) return true
    return this.client.setAccountData(ACCOUNT_PRESENCE_EVENT_TYPE, newSettings)
  }, 100)

  async start() {
    this.startKeepAlive()
    if (!this.autoPresence) return
    this.autoPresenceTimer = new Timer(
      this.getAutoPresenceTimeoutMs(this.getState())
    )
    // the user_activity_start action starts the timer
    this.dispatcherRef = dis.register(this.onAction)
    while (this.autoPresenceTimer) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await this.autoPresenceTimer.finished()
        if (this.getState() === 'online') {
          this.setState('unavailable')
          this.autoPresenceTimer = new Timer(
            this.getAutoPresenceTimeoutMs('unavailable')
          )
          this.autoPresenceTimer.start()
        } else {
          this.setState('offline')
        }
      } catch (e) {
        /* aborted, stop got called */
      }
    }
  }

  async startKeepAlive() {
    this.keepAliveTimer = new Timer(KEEPALIVE_TIME_MS)
    this.keepAliveTimer.start()
    while (this.keepAliveTimer) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await this.keepAliveTimer.finished()
        if (
          ['unavailable', 'org.matrix.msc3026.busy'].includes(this.getState())
        ) {
          this.setState('unavailable', true)
        }
      } catch (e) {
        /* aborted, stop got called */
      }
    }
  }

  /**
   * Stop tracking user activity
   */
  stop() {
    if (this.startTimeout) {
      clearTimeout(this.startTimeout)
    }
    if (this.dispatcherRef) {
      dis.unregister(this.dispatcherRef)
      this.dispatcherRef = null
    }
    if (this.autoPresenceTimer) {
      this.autoPresenceTimer.abort()
      this.autoPresenceTimer = null
    }
  }

  updateTimers() {
    if (this.autoPresenceTimer) {
      this.autoPresenceTimer.restart()
      this.autoPresenceTimer.changeTimeout(
        this.getAutoPresenceTimeoutMs(this.state)
      )
    }
  }

  /**
   * Get the current presence state.
   * @returns {string} the presence state (see PRESENCE enum)
   */
  getState() {
    return this.state
  }

  onAction(payload) {
    if (
      this.state !== 'unknown' &&
      this.client &&
      payload.action === 'user_activity' &&
      this.autoPresence
    ) {
      if (this.state !== 'online') {
        this.setState('online')
      } else if (this.autoPresenceTimer) {
        this.autoPresenceTimer.restart()
        this.autoPresenceTimer.changeTimeout(
          this.getAutoPresenceTimeoutMs('online')
        )
      }
    }
  }

  async setAutoAway(unavailableTimeMs) {
    this.unavailableTimeMs = unavailableTimeMs
    this.updateTimers()
    this.saveSettings()
  }

  async setAutoOffline(offlineTimeMs) {
    this.offlineTimeMs = offlineTimeMs
    this.updateTimers()
    this.saveSettings()
  }

  async setAutoPresence(autoPresence) {
    if (autoPresence === null || this.autoPresence === autoPresence) return
    this.autoPresence = autoPresence
    if (!this.autoPresenceTimer && autoPresence === true) {
      this.start()
    } else if (this.autoPresenceTimer && autoPresence === false) {
      this.stop()
    }
    this.saveSettings()
  }

  /**
   * Set the presence state.
   * If the state has changed, the homeserver will be notified.
   * @param {string} newState the new presence state (see PRESENCE enum)
   */
  async setState(newState, force = false) {
    this.keepAliveTimer.restart()
    if (newState === this.state && !force) {
      return
    }
    MatrixClientPeg.updateSyncPresence(newState)
    if (PRESENCE_STATES.indexOf(newState) === -1) {
      throw new Error(`Bad presence state: ${newState}`)
    }

    // const oldState = this.state
    this.state = newState

    try {
      await MatrixClientPeg.get().setPresence(
        this.state === 'unavailable' ? 'org.matrix.msc3026.busy' : this.state
      )
      // eslint-disable-next-line no-console
      console.info('Presence: %s', newState)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to set presence: %s', err)
    }
    this.saveSettings()
  }
}
