export const UPDATE_CUSTOMER_REQUEST =
  'integrations/recharge/UPDATE_CUSTOMER_REQUEST'
export const UPDATE_CUSTOMER_REQUEST_STARTED =
  'integrations/recharge/UPDATE_CUSTOMER_REQUEST_STARTED'
export const UPDATE_CUSTOMER_REQUEST_SUCCESS =
  'integrations/recharge/UPDATE_CUSTOMER_REQUEST_SUCCESS'
export const UPDATE_CUSTOMER_REQUEST_FAILED =
  'integrations/recharge/UPDATE_CUSTOMER_REQUEST_FAILED'

export const FETCH_SUBSCRIPTIONS_REQUEST =
  'integrations/recharge/FETCH_SUBSCRIPTIONS_REQUEST'
export const FETCH_SUBSCRIPTIONS_REQUEST_STARTED =
  'integrations/recharge/FETCH_SUBSCRIPTIONS_REQUEST_STARTED'
export const FETCH_SUBSCRIPTIONS_REQUEST_SUCCESS =
  'integrations/recharge/FETCH_SUBSCRIPTIONS_REQUEST_SUCCESS'
export const FETCH_SUBSCRIPTIONS_REQUEST_FAILED =
  'integrations/recharge/FETCH_SUBSCRIPTIONS_REQUEST_FAILED'

export const CANCEL_SUBSCRIPTION_REQUEST =
  'integrations/recharge/CANCEL_SUBSCRIPTION_REQUEST'
export const CANCEL_SUBSCRIPTION_REQUEST_STARTED =
  'integrations/recharge/CANCEL_SUBSCRIPTION_REQUEST_STARTED'
export const CANCEL_SUBSCRIPTION_REQUEST_SUCCESS =
  'integrations/recharge/CANCEL_SUBSCRIPTION_REQUEST_SUCCESS'
export const CANCEL_SUBSCRIPTION_REQUEST_FAILED =
  'integrations/recharge/CANCEL_SUBSCRIPTION_REQUEST_FAILED'

export const REACTIVATE_SUBSCRIPTION_REQUEST =
  'integrations/recharge/REACTIVATE_SUBSCRIPTION_REQUEST'
export const REACTIVATE_SUBSCRIPTION_REQUEST_STARTED =
  'integrations/recharge/REACTIVATE_SUBSCRIPTION_REQUEST_STARTED'
export const REACTIVATE_SUBSCRIPTION_REQUEST_SUCCESS =
  'integrations/recharge/REACTIVATE_SUBSCRIPTION_REQUEST_SUCCESS'
export const REACTIVATE_SUBSCRIPTION_REQUEST_FAILURE =
  'integrations/recharge/REACTIVATE_SUBSCRIPTION_REQUEST_FAILED'

export const FETCH_ORDERS_REQUEST = 'integrations/recharge/FETCH_ORDERS_REQUEST'
export const FETCH_ORDERS_REQUEST_STARTED =
  'integrations/recharge/FETCH_ORDERS_REQUEST_STARTED'
export const FETCH_ORDERS_REQUEST_SUCCESS =
  'integrations/recharge/FETCH_ORDERS_REQUEST_SUCCESS'
export const FETCH_ORDERS_REQUEST_FAILED =
  'integrations/recharge/FETCH_ORDERS_REQUEST_FAILED'

export const SKIP_NEXT_ORDER_REQUEST =
  'integrations/recharge/SKIP_NEXT_ORDER_REQUEST'
export const SKIP_NEXT_ORDER_REQUEST_STARTED =
  'integrations/recharge/SKIP_NEXT_ORDER_REQUEST_STARTED'
export const SKIP_NEXT_ORDER_REQUEST_SUCCESS =
  'integrations/recharge/SKIP_NEXT_ORDER_REQUEST_SUCCESS'
export const SKIP_NEXT_ORDER_REQUEST_FAILED =
  'integrations/recharge/SKIP_NEXT_ORDER_REQUEST_FAILED'

export const FETCH_CHARGES_REQUEST =
  'integrations/recharge/FETCH_CHARGES_REQUEST'
export const FETCH_CHARGES_REQUEST_STARTED =
  'integrations/recharge/FETCH_CHARGES_REQUEST_STARTED'
export const FETCH_CHARGES_REQUEST_SUCCESS =
  'integrations/recharge/FETCH_CHARGES_REQUEST_SUCCESS'
export const FETCH_CHARGES_REQUEST_FAILED =
  'integrations/recharge/FETCH_CHARGES_REQUEST_FAILED'

export const FETCH_CHARGE_REQUEST = 'integrations/recharge/FETCH_CHARGE_REQUEST'
export const FETCH_CHARGE_REQUEST_STARTED =
  'integrations/recharge/FETCH_CHARGE_REQUEST_STARTED'
export const FETCH_CHARGE_REQUEST_SUCCESS =
  'integrations/recharge/FETCH_CHARGE_REQUEST_SUCCESS'
export const FETCH_CHARGE_REQUEST_FAILED =
  'integrations/recharge/FETCH_CHARGE_REQUEST_FAILED'

export const CHECK_EXISTING_INTEGRATION =
  'integrations/recharge/CHECK_EXISTING_INTEGRATION'
