import { contactCustomFieldValuesSubQuery } from './contactCustomFieldValuesSubQuery'

export const fetchConversationContactCustomFieldsFirstPageQuery = `
  query ConversationContactByConversationNumberFirstPage($after: String, $number: Int!) {
    conversation(number: $number) {
      id
      number
      contact {
        id
        avatarUrl
        companies {
          nodes {
            id
          }
        }
        ${contactCustomFieldValuesSubQuery}
        email
        firstName
        lastName
        name
        lastSeenAt
        createdAt
      }
    }
  }
`
