import createCachedSelector from 're-reselect'
import { diff } from 'util/date'
import {
  calculateProrataAmount,
  getPastTimestamp,
  getFutureTimestamp,
  quantityFor,
} from '../util'
import { selectNextBillDate } from './selectNextBillDate'
import { selectPricingsById } from './selectPricingsById'
import { selectUsage } from './usage/selectUsage'
import { BILLING_CYCLE_MONTHLY } from '../constants'

export const selectUpgradeBreakdownForPricingIds = createCachedSelector(
  (_state, currentPricingIds) => currentPricingIds,
  (_state, _currentPricingId, newPricingIds) => newPricingIds,
  (_state, _currentPricingId, _newPricingId, currentBillingCycle) =>
    currentBillingCycle,
  (_state, _currentPricingId, _newPricingId, _currentCycle, newBillingCycle) =>
    newBillingCycle,
  (
    _state,
    _currentPricingId,
    _newPricingId,
    _currentCycle,
    _newBillingCycle,
    upgradeAt
  ) => upgradeAt,
  selectUsage,
  selectPricingsById,
  selectNextBillDate,
  (
    currentPricingIds,
    newPricingIds,
    currentBillingCycle,
    newBillingCycle,
    upgradeAt,
    usage,
    pricingsById,
    currentCycleEndAtSeconds
  ) => {
    const currentCycleEndAt = currentCycleEndAtSeconds * 1000

    const currentPricings = currentPricingIds.map(id => pricingsById[id])
    const newPricings = newPricingIds.map(id => pricingsById[id])

    const currentCycleStart = getPastTimestamp(
      currentCycleEndAt,
      currentBillingCycle
    )
    const nextCycleEnd = getFutureTimestamp(currentCycleStart, newBillingCycle)

    const prorataRefund = currentPricings.reduce((total, pricing) => {
      const cycleMonthlyPrice =
        currentBillingCycle === BILLING_CYCLE_MONTHLY
          ? pricing.monthly / 100
          : pricing.annual / 100 / 12

      const prorataAmount = calculateProrataAmount(
        cycleMonthlyPrice,
        upgradeAt,
        currentCycleEndAt
      )

      const usageAmount = quantityFor(pricing, currentPricings, usage)

      const pricingProrataRefund = prorataAmount * usageAmount

      return total + pricingProrataRefund
    }, 0)

    const refundDays = diff('days', upgradeAt, currentCycleEndAt)

    const prorataCharge = newPricings.reduce((total, pricing) => {
      const cycleMonthlyPrice =
        newBillingCycle === BILLING_CYCLE_MONTHLY
          ? pricing.monthly / 100
          : pricing.annual / 100 / 12
      const proRataAmount = calculateProrataAmount(
        cycleMonthlyPrice,
        upgradeAt,
        nextCycleEnd
      )
      const usageAmount = quantityFor(pricing, newPricings, usage)
      const pricingProrataCharge = proRataAmount * usageAmount
      return total + pricingProrataCharge
    }, 0)

    const chargeDays = diff('days', upgradeAt, nextCycleEnd)

    return {
      currentPricingIds,
      newPricingIds,
      refundDays,
      prorataRefund: Math.round(prorataRefund) * 100,
      prorataCharge: Math.round(prorataCharge) * 100,
      chargeDays,
    }
  }
)(
  (
    _state,
    currentPricingIds,
    newPricingIds,
    currentBillingCycle,
    newBillingCycle,
    upgradeAt
  ) =>
    [
      currentPricingIds.join('|'),
      newPricingIds.join('|'),
      currentBillingCycle,
      newBillingCycle,
      upgradeAt,
    ].join('-')
)
