import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { selectCustomFieldForKey } from 'ducks/crm/customFields/selectors/base'
import { selectIsInChat } from 'selectors/location'
import { createContactMutationSchema } from '../../schema'
import { CREATE_CONTACT } from '../../types'
import { doFetchCurrentContact } from '../fetching/doFetchCurrentContact'
import { doChangeContactForConversation } from './doChangeContactForConversation'
import { createContactMutation } from './createContactMutation'

export function doCreateAndChangeContactForConversation(
  contactIdOrTemp,
  conversationId,
  conversationNumber,
  name,
  email,
  contactMatrixId
) {
  return (dispatch, getState) => {
    const state = getState()
    const isInChat = selectIsInChat(state)
    const { id: emailId } = selectCustomFieldForKey(state, {
      key: 'contact_email',
    })
    const { id: firstNameId } = selectCustomFieldForKey(state, {
      key: 'contact_first_name',
    })
    const { id: lastNameId } = selectCustomFieldForKey(state, {
      key: 'contact_last_name',
    })
    const { id: contactMatrixFieldId } = selectCustomFieldForKey(state, {
      key: 'contact_matrix_id',
    })
    const [, firstName, lastName] = name.match(/^(.+) ([^ ]+)$/) || [null, name]
    return dispatch(
      doAppGraphqlRequest(
        CREATE_CONTACT,
        createContactMutation,
        {
          customFields: [
            email && { customFieldIdOrKey: emailId, value: email },
            firstName && { customFieldIdOrKey: firstNameId, value: firstName },
            lastName && { customFieldIdOrKey: lastNameId, value: lastName },
            contactMatrixId && {
              customFieldIdOrKey: contactMatrixFieldId,
              value: contactMatrixId,
            },
          ].filter(x => !!x),
        },
        {
          normalizationSchema: createContactMutationSchema,
          meta: {
            contactIdOrTemp,
          },
        }
      )
    )
      .then(result => {
        const { contactCreate: { contact } = {} } = result || {}
        const { id } = contact || {}
        // in chat we fetch conversations by matrix id custom field, not by conversation id
        // so no need to link. API throws error otherwise
        if (!isInChat && id) {
          return dispatch(
            doChangeContactForConversation(
              conversationId,
              conversationNumber,
              id
            )
          )
        }
        return result
      })
      .then(result => {
        const changeContactId =
          result?.conversationChangeContact?.conversation?.contact?.id
        const createContactId = result?.contactCreate?.contact?.id

        const contactId = isInChat ? createContactId : changeContactId
        const hasCreatedContactId = !!contactId
        // reload contact page, only if the change and/or create contact executes successfully
        if (hasCreatedContactId) {
          return dispatch(doFetchCurrentContact())
        }

        return contactId
      })
  }
}
