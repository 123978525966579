import { createSelector } from 'reselect'
import { createBasicSelector } from 'util/redux'
import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import { selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider } from 'ducks/integrations/selectors'

import { selectCurrentTicketLinkedIssues as sharedSelector } from '../jiraShared/selectors'

export function selectCurrentTicketLinkedIssues(state) {
  return sharedSelector(state, {
    provider: 'jira_cloud',
    source: 'jiraCloud',
  })
}

export const selectCurrentTicketJiraCloudIssuesById = createSelector(
  selectCurrentTicketLinkedIssues,
  issues => {
    if (!issues) return emptyObj
    return issues.reduce((result, issue) => {
      // eslint-disable-next-line no-param-reassign
      result[issue.id] = issue
      return result
    }, {})
  }
)

export function selectJiraCloudHiddenFields(state) {
  const {
    hidden,
  } = selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider(state, {
    provider: 'jira_cloud',
  })
  return hidden
}

export function selectJiraCloudIssuesById(state) {
  return state.integrations.thirdPartyData.jiraCloud.issues.byId
}

export function selectJiraCloudVisibleFields(state) {
  const {
    visible,
  } = selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider(state, {
    provider: 'jira_cloud',
  })
  return visible
}

function selectCreateIssueTypeFieldsBase(state) {
  return state.integrations.thirdPartyData.jiraCloud.createIssueTypeFields
}

export const selectJiraAreCreateIssueTypeFieldsLoading = createBasicSelector(
  selectCreateIssueTypeFieldsBase,
  base => base && base.loading
)

const selectCreateIssueTypeFieldsByProjectId = createBasicSelector(
  selectCreateIssueTypeFieldsBase,
  base => base.byProjectId || emptyObj
)

export const selectCreateIssueTypeFieldsFieldMeta = createCachedSelector(
  selectCreateIssueTypeFieldsByProjectId,
  (_, args) => args,
  (byProjectId, args) => {
    const { projectId, issueTypeId, fieldKey } = args

    if (!projectId || !issueTypeId || !fieldKey) return null

    const project = byProjectId[projectId] || emptyObj
    const issueType = project[issueTypeId] || emptyObj
    const fields = issueType.fields || emptyObj
    return fields[fieldKey]
  }
)(
  (_, { projectId, issueTypeId, fieldKey }) =>
    `${projectId || 'unknown'}-${issueTypeId || 0}-${fieldKey || 'empty'}`
)

export const selectRequiredCreateIssueTypeFieldNames = createCachedSelector(
  selectCreateIssueTypeFieldsByProjectId,
  (_state, projectId) => projectId,
  (_state, _projectId, issueTypeId) => issueTypeId,
  (byProjectId, projectId, issueTypeId) => {
    const fields = byProjectId?.[projectId]?.[issueTypeId]?.fields || emptyObj

    return Object.keys(fields).filter(key => fields?.[key]?.required === true)
  }
)(
  (_, projectId, issueTypeId) => `${projectId || 'unknown'}-${issueTypeId || 0}`
)
