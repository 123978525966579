import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'

import {
  FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_REQUEST,
  FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_SUCCESS,
  FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_ERROR,
} from '../types'

// get supported JIRA fields for project + issue type combination
// because sometimes some optional fields like priority, labels etc could be disabled on a JIRA project
// and JIRA throws us an error if we try to send to send that in the create issue POST
export default function doFetchJiraCloudCreateIssueMetaTypeFields(
  cloudId,
  projectId
) {
  return (dispatch, getState) => {
    const meta = { cloudId, projectId }
    const token = oauthTokenSelector(getState())
    dispatch({
      type: FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_REQUEST,
      meta,
    })
    integrationApi
      .get(
        token,
        `proxy/atlassian/ex/jira/${cloudId}/rest/api/3/issue/createmeta`,
        { projectIds: projectId, expand: 'projects.issuetypes.fields' }
      )
      .then(({ json: payload }) => {
        dispatch({
          type: FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_SUCCESS,
          meta,
          payload,
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_ERROR,
          error: true,
          meta,
          payload: error,
        })
      })
  }
}
