import {
  DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
  FETCH_DRAFTS,
  REQUEST_DRAFT_TYPES,
} from 'ducks/drafts2/constants'
import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import { isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import { camelize } from 'util/strings'
import { fetchDraftsActionRequestKey } from '../util'

const fetchDraftsQuery = `
  query DraftsQuery($filter: DraftFilter, $orderBy: DraftOrder, $size: Int) {
    drafts(filter: $filter, orderBy: $orderBy first: $size) {
      nodes {
        draftId
        version
        payload
        draftType
        conversationId
      }
    }
  }
`

export const doFetchDrafts = (ticketId, options = {}) => dispatch => {
  if (isNullOrUndefined(ticketId)) return Promise.resolve(null)

  const {
    pageSize,
    draftType,
    requestKey,
    orderBy: { field = 'UPDATED_AT', direction = 'DESC' } = {},
  } =
    options || {}

  const conversationId = buildId('Conversation', ticketId)

  const variables = {
    filter: {
      conversationId,
      draftType,
    },
    size: pageSize,
    orderBy: {
      field,
      direction,
    },
  }

  const meta = {}
  if (requestKey) {
    meta.requestKey = requestKey
  }

  return dispatch(
    doAppGraphqlRequest(FETCH_DRAFTS, fetchDraftsQuery, variables, {
      meta,
    })
  )
}

export const doFetchNewComposeDrafts = () => dispatch => {
  return Promise.all([
    dispatch(
      doFetchDrafts(DRAFT_NEW_CONVERSATION_CONVERSATION_ID, {
        pageSize: 1,
        draftType: REQUEST_DRAFT_TYPES.note,
        requestKey: camelize(
          fetchDraftsActionRequestKey(
            DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
            REQUEST_DRAFT_TYPES.note
          )
        ),
      })
    ),
    dispatch(
      doFetchDrafts(DRAFT_NEW_CONVERSATION_CONVERSATION_ID, {
        pageSize: 1,
        draftType: REQUEST_DRAFT_TYPES.reply,
        requestKey: camelize(
          fetchDraftsActionRequestKey(
            DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
            REQUEST_DRAFT_TYPES.reply
          )
        ),
      })
    ),
  ])
}
