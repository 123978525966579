import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { STATES as STORE_STATES } from 'ducks/bootstrap/constants'
import { selectBase } from 'selectors/bootstrap/selectBase'
import { selectApplication } from 'selectors/app/selectApplication'

// Kevin R (2023-12-11)
// Deprecated: We need to extend the selectCurrentAccount to have feature parity with
// the current GQL version and then eventually get rid of this method
export const selectAccount = state =>
  selectApplication(state).account || emptyObj

export const selectIsAccountBootstrapped = createSelector(
  selectBase,
  bootstrap => bootstrap.account === STORE_STATES.LOADED
)
