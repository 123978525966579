import { createSelector } from 'reselect'
import { selectBillingProvider } from './selectBillingProvider'

export const selectBillingProviderSettings = createSelector(
  selectBillingProvider,
  provider => ({
    hasCardSupport: provider === 'stripe',
    requireExternalApproval: provider === 'shopify',
    supportBillingEstimateCard: provider === 'stripe',
    supportBillingPaymentMethodCard: provider === 'stripe',
    supportBillingInformation: provider === 'stripe',
    supportBillingInvoices: provider === 'stripe',
    supportBillingActivity: provider === 'stripe',
    supportAllBillingCycles: provider === 'stripe',
  })
)
