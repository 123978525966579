import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import { FETCH_ORDER_BY_ID_REQUEST } from '../types'
import { orderPartial } from './partials'

const query = `
  query ShopifyFetchOrderById($orderId: ID!) {
    order(id: $orderId) {
      ${orderPartial}
    }
  }
`

const doFetchOrderById = (integrationId, orderId) => dispatch => {
  const variables = { orderId }
  return dispatch(
    doGraphqlRequest(FETCH_ORDER_BY_ID_REQUEST, query, variables, {
      graphqlFunc: graphql(integrationId),
    })
  )
}

export default doFetchOrderById
