import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { selectFuturePhase } from './selectFuturePhase'
import { planIdToComponents } from '../util'

export const selectFutureBillingCycle = createSelector(
  selectFuturePhase,
  futurePhase => {
    if (!futurePhase) return emptyArr

    return futurePhase.items.map(item => {
      return planIdToComponents(item.plan).cycle
    })[0]
  }
)
