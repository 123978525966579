import { createSelector } from 'reselect'
import { selectDiscounts } from './selectDiscounts'
import { sortDiscounts } from '../util'

export const selectAppliedDiscount = createSelector(
  selectDiscounts,
  discounts => {
    if (!discounts || discounts.length === 0) return null

    return sortDiscounts(discounts)[0]
  }
)
