import { doGraphqlRequest } from 'ducks/requests/operations'
import doCreateShopifyEvent from './doCreateShopifyEvent'
import doFetchOrderById from './doFetchOrderById'
import { ORDER_CANCEL_REQUEST } from '../types'
import graphql from '../api'

const mutation = `
  mutation orderCancel($orderId: ID!, $notifyCustomer: Boolean!, $reason: OrderCancelReason!, $refund: Boolean!, $restock: Boolean!) {
    orderCancel(orderId: $orderId, notifyCustomer: $notifyCustomer, reason: $reason, refund: $refund, restock: $restock) {
      orderCancelUserErrors {
        code
        message
      }
    }
  }
`

const doCancelOrder = (
  integrationId,
  orderId,
  conversationId,
  payload
) => async dispatch => {
  const { reason, refund, restock, notifyCustomer } = payload

  const variables = {
    orderId,
    notifyCustomer,
    reason,
    refund,
    restock,
  }

  const result = await dispatch(
    doGraphqlRequest(ORDER_CANCEL_REQUEST, mutation, variables, {
      graphqlFunc: graphql(integrationId),
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: 'Order cancelled',
          },
          failed: {
            enabled: true,
            content: 'Order cancellation failed',
          },
        },
      },
    })
  )

  if (orderId && conversationId) {
    dispatch(
      doCreateShopifyEvent(
        integrationId,
        orderId,
        conversationId,
        'ORDER_CANCELED'
      )
    )
  }

  dispatch(doFetchOrderById(integrationId, orderId))

  return result
}

export default doCancelOrder
