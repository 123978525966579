import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const integrationIdAndCustomerIdCacheKey = (
  state,
  integrationId,
  { customerId }
) => `${integrationId || 'unknown'}-${customerId || 'unknown'}`

const selectIntegrationData = state =>
  state?.integrations?.thirdPartyData?.recharge || emptyObj

export const selectCustomers = createBasicSelector(
  selectIntegrationData,
  integrationData => integrationData.customers
)

const selectCustomersByIntegrationId = createBasicSelector(
  selectCustomers,
  customers => customers.byIntegrationId || emptyObj
)

export const selectCustomersForIntegrationId = createCachedSelector(
  selectCustomersByIntegrationId,
  (state, integrationId) => integrationId,
  (customersByIntegrationId, integrationId) =>
    customersByIntegrationId[integrationId] || emptyObj
)((state, integrationId) => integrationId || 'unknown')

export const selectCustomersByCustomerId = createCachedSelector(
  selectCustomersForIntegrationId,
  customersForIntegrationId =>
    customersForIntegrationId.byCustomerId || emptyObj
)((state, integrationId) => integrationId || 'unknown')

export const selectCustomerForCustomerId = createCachedSelector(
  selectCustomersByCustomerId,
  (state, integrationId, { customerId }) => customerId,
  (byCustomerId, customerId) => byCustomerId[customerId] || emptyObj
)(integrationIdAndCustomerIdCacheKey)

export const selectCustomerIsReloading = createCachedSelector(
  selectCustomerForCustomerId,
  customer => customer?.isReloading
)(integrationIdAndCustomerIdCacheKey)

export const selectCustomerIsError = createCachedSelector(
  selectCustomerForCustomerId,
  customer => customer?.isError
)(integrationIdAndCustomerIdCacheKey)
