import {
  EMAIL_MARKETING_GET_INTEGRATION_STATE_SUCCESS,
  EMAIL_MARKETING_UNSUBSCRIBE_STARTED,
  EMAIL_MARKETING_SUBSCRIBE_STARTED,
} from '../types'

export default function contactState(
  state = { byId: {} },
  {
    type,
    payload: {
      emailMarketingSubscriptions: { nodes: subscriptions } = {},
    } = {},
    meta: {
      requestParameters: { contactId, integrationId, input = {} } = {},
      list,
    } = {},
  }
) {
  const key = `${input?.integrationId || integrationId}:${input?.contactId ||
    contactId}`
  if (type === EMAIL_MARKETING_GET_INTEGRATION_STATE_SUCCESS) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [key]: subscriptions,
      },
    }
  }
  if (type === EMAIL_MARKETING_UNSUBSCRIBE_STARTED) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [key]: (state.byId[key] || []).filter(({ id }) => id !== input.listId),
      },
    }
  }

  if (type === EMAIL_MARKETING_SUBSCRIBE_STARTED) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [key]: [...(subscriptions || []), list],
      },
    }
  }
  return state
}
