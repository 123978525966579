import {
  ACCOUNT,
  ACCOUNT_PREFERENCES,
  BILLING_USAGE,
  INTEGRATION_PROVIDER,
} from '../utils/credentialsType'
import { VISIBILITY_EXISTING_INSTALL } from '../utils/visibilityTypes'

export const isIntegrationInstalled = integrationEntity => {
  const {
    installStateConfig: { type } = {},
    installStateValue: { provider, accountPreference, billingUsage } = {},
  } =
    integrationEntity || {}
  switch (type) {
    case INTEGRATION_PROVIDER:
      return !!provider?.length
    case ACCOUNT_PREFERENCES:
      return !!accountPreference
    case BILLING_USAGE:
      return !!billingUsage
    case ACCOUNT:
      // eslint-disable-next-line no-console
      console.log('TODO: isIntegrationInstalled for account')
      return false
    default:
      return false
  }
}

export const isIntegrationVisible = integrationEntity => {
  // visibility can be a string or a boolean
  const { visibility } = integrationEntity || {}

  if (typeof visibility === 'boolean') return visibility

  if (visibility === VISIBILITY_EXISTING_INSTALL)
    return isIntegrationInstalled(integrationEntity)

  return false
}
