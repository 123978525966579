import { combineReducers } from 'core/redux'

import customers from './customers'
import subscriptions from './subscriptions'
import orders from './orders'
import charges from './charges'

export default combineReducers({
  customers,
  subscriptions,
  orders,
  charges,
})
