import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'

export default createSelector(
  (state, { source }) => {
    return Object.values(
      state.integrations.thirdPartyData[source].assignableUsers.byKey
    )
  },
  assignableUsers => {
    if (!assignableUsers) return emptyArr
    return assignableUsers.map(
      ({
        accountId,
        avatarUrls: { '48x48': avatarUrl },
        displayName,
        name,
      }) => {
        return {
          avatarUrl,
          text: displayName,
          value: accountId || name,
        }
      }
    )
  }
)
