import React, { useMemo } from 'react'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'

import { styles } from './styles'

// Show three tags at most
// and show the rest as text in a tooltip
const Tags = ({ tagAdded }) => {
  const moreTagNames = useMemo(() => tagAdded.slice(3).map(t => t.name), [
    tagAdded,
  ])

  return (
    <div>
      <h3 className="grui">Tags added:</h3>
      <div css={styles.tags}>
        {tagAdded.map(({ name, id: tagId, color }, index) => {
          if (index > 2) {
            if (tagAdded.length - 1 === index) {
              return (
                <Tooltip
                  title={moreTagNames.join(', ')}
                  maxWidth={305}
                  key={tagId}
                >
                  <div className="grui whitespace-nowrap">
                    &nbsp;+ {tagAdded.length - 3} more...
                  </div>
                </Tooltip>
              )
            }
            return null
          }
          return (
            <div
              key={tagId}
              style={{ background: color }}
              css={[styles.tag, moreTagNames.length && styles.smallTag]}
            >
              {name}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Tags
