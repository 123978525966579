import { capitalize } from 'util/strings'
import { strftime } from 'util/date'
import { planIdToComponents } from 'ducks/billing/util'

export function enrichEvent(evt, previousEvent = {}, nextEvent = {}) {
  const data = JSON.parse(evt.data)

  const updatedEvent = {
    ...evt,
    data,
  }
  updatedEvent.changes = getSubscriptionChanges(updatedEvent)
  updatedEvent.title = getEventTitleByType(updatedEvent)
  updatedEvent.isVisible = isEventVisible(
    updatedEvent,
    previousEvent,
    nextEvent
  )

  return updatedEvent
}

export function getEventTitleByType(evt) {
  const {
    type,
    system,
    data: { object, changes },
  } = evt
  if (type === 'charge.succeeded') return 'Credit card charged'
  if (type === 'charge.failed') return 'Charge attempt failed'
  if (type === 'charge.refunded') return 'Charge refunded'
  if (type === 'invoice.finalized') return 'Invoice created'
  if (type === 'customer.subscription.created') return 'Moved to a paid plan 🚀'
  if (type === 'customer.source.created') return 'Credit card added'
  if (type === 'customer.source.deleted') return 'Credit card removed'
  if (type === 'subscription_schedule.aborted') {
    return 'Scheduled subscription update canceled due to delinquency'
  }
  if (type === 'subscription_schedule.canceled') {
    return 'Scheduled subscription update canceled'
  }
  if (type === 'subscription_schedule.completed') {
    return 'Scheduled subscription update completed'
  }
  if (type === 'subscription_schedule.created') {
    return 'Scheduled subscription update created'
  }
  if (type === 'subscription_schedule.released') {
    return 'Scheduled subscription update released'
  }
  if (type === 'subscription_schedule.updated') {
    return 'Scheduled subscription updated'
  }

  if (type === 'customer.subscription.updated') {
    return getSubscriptionUpdatedEventName(evt)
  }
  if (
    (type === 'user.create' || type === 'user.update') &&
    system === 'groove'
  ) {
    return getEventNameFromUserUpdatedAudit(evt)
  }
  if (
    (type === 'plan.create' && object.state === 'trial') ||
    (type === 'plan.update' && changes.state && changes.state[1] === 'trial')
  ) {
    return 'Trial started 🍾'
  }
  if (type === 'plan.update' && changes.state && changes.state[1] !== 'trial') {
    return 'Trial Ended 😣'
  }

  return `${capitalize(type.replace(/\./g, ' '))} (${type})`
}

function getEventNameFromUserUpdatedAudit({ type, data: { changes } }) {
  // The changes record returned by a groove audit record contains the field
  // that was updated with an array which always contains 2 items
  // the first item is the value before the change, and the 2nd item is the value
  // after the change
  if (type === 'user.create') return `${app.t('Agent')} added`
  if (type === 'user.update') {
    if (changes.archived && !changes.archived[0] && changes.archived[1])
      return `${app.t('Agent')} removed`
    if (changes.archived && changes.archived[0] && !changes.archived[1])
      return `${app.t('Agent')} added`
  }
  return 'n/a'
}

function isEventVisible(
  { type, title, data: { changes }, changes: calculatedChanges },
  previousRawEvent,
  nextRawEvent
) {
  if (
    type === 'customer.subscription.updated' &&
    (title === `${app.t('Agent')} added` ||
      title === `${app.t('Agent')} removed` ||
      calculatedChanges.length === 0)
  ) {
    return false
  }
  if (type === 'plan.update' || type === 'plan.create') {
    if (!changes.state || !changes.state.includes('trial')) {
      return false
    }
  }

  // If the trial has expired due tot the customer signing up for a paid plan,
  // dont show the trial expiry.
  if (
    type === 'plan.update' &&
    changes.state &&
    changes.state[1] !== 'trial' &&
    (previousRawEvent.type === 'customer.subscription.created' ||
      nextRawEvent.type === 'customer.subscription.created')
  ) {
    return false
  }
  return true
}

function getSubscriptionUpdatedEventName({ changes }) {
  if (
    changes.some(
      c =>
        c.includes('was added') || c.includes('was removed') || c.includes('=>')
    )
  ) {
    return 'Subscription updated'
  }
  if (changes.some(c => c.includes(`${app.t('agents')} to`))) {
    return `${app.t('Agent')} added`
  }
  if (changes.some(c => c.includes(`${app.t('agents')} from`))) {
    return `${app.t('Agent')} removed`
  }
  return 'Subscription change'
}

function getPlanName(plan) {
  return plan.nickname || plan.name
}

function getCustomerSubscriptionUpdatedChanges(evt) {
  const {
    data: { object: current, previousAttributes: previous },
  } = evt
  const changes = []

  // Make sure you update the getSubscriptionUpdatedEventName if you add new
  // change detection to this method
  if (current.items && previous.items) {
    const currentItems = current.items.data
    const previousItems = previous.items.data

    // Check for items added
    currentItems.forEach(ci => {
      if (!previousItems.find(pi => ci.id === pi.id)) {
        changes.push(`${getPlanName(ci.plan)} was added`)
      }
    })

    // Check for items removed
    previousItems.forEach(pi => {
      if (pi.id && !currentItems.find(ci => pi.id === ci.id)) {
        changes.push(`${getPlanName(pi.plan)} was removed`)
      }
    })

    // Check for changes
    currentItems.forEach(ci => {
      const pi = previousItems.find(p => ci.id === p.id)
      if (pi) {
        if (pi.plan.id !== ci.plan.id) {
          changes.push(
            `Changed ${getPlanName(pi.plan)} => ${getPlanName(ci.plan)}`
          )
        }
        if (pi.quantity !== ci.quantity) {
          if (ci.quantity > pi.quantity) {
            changes.push(
              `Added ${ci.quantity - pi.quantity} ${app.t(
                'agents'
              )} to ${getPlanName(pi.plan)}`
            )
          } else {
            changes.push(
              `Removed ${pi.quantity - ci.quantity} ${app.t(
                'agents'
              )} from ${getPlanName(pi.plan)}`
            )
          }
        }
      }
    })
  }

  // Check for cancellation requests
  if (previous.cancelAtPeriodEnd !== undefined) {
    if (previous.cancelAtPeriodEnd && !current.cancelAtPeriodEnd) {
      changes.push(
        `Cancel pending cancellation request for ${strftime(
          '%d %b %Y @ %H:%M',
          previous.cancelAt * 1000
        )}`
      )
    } else if (!previous.cancelAtPeriodEnd && current.cancelAtPeriodEnd) {
      changes.push(
        `Schedule cancellation for ${strftime(
          '%d %b %Y @ %H:%M',
          current.cancelAt * 1000
        )}`
      )
    }
  }
  return changes
}

function getSubscriptionScheduleChanges(evt) {
  const { data: { object: current } = {} } = evt
  const changes = []

  const { phases = [] } = current || {}

  phases.forEach((phase, index) => {
    const { startDate, endDate, plans = [] } = phase || {}
    const startDateStr = strftime('%d %b %Y @ %H:%M', startDate * 1000)
    const endDateStr = strftime('%d %b %Y @ %H:%M', endDate * 1000)

    changes.push(`[Phase ${index + 1}]`)
    plans.forEach(plan => {
      const { product, planId, cycle } = planIdToComponents(plan.price)

      changes.push(
        `${capitalize(product)} ${capitalize(planId)} ${capitalize(
          cycle
        )}: ${startDateStr} -> ${endDateStr}`
      )
    })
  })

  return changes
}

function getSubscriptionChanges(evt) {
  const { type } = evt || {}

  if (type === 'customer.subscription.updated') {
    return getCustomerSubscriptionUpdatedChanges(evt)
  }

  if (type.startsWith('subscription_schedule.')) {
    return getSubscriptionScheduleChanges(evt)
  }

  return []
}
