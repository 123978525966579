import createCachedSelector from 're-reselect'

import selectGitHubNodesById from './selectGitHubNodesById'

export default createCachedSelector(
  selectGitHubNodesById,
  (state, { repositoryId }) => repositoryId,
  (nodes, repositoryId) => {
    const repository = nodes[repositoryId]
    return (
      repository &&
      repository.assignableUsers &&
      repository.assignableUsers.map(({ avatarUrl, id, login, name }) => ({
        avatarUrl,
        login,
        name,
        text: `${login} ${name}`,
        value: id,
      }))
    )
  }
)((state, { repositoryId }) => repositoryId || 'NULL')
