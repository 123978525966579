import {
  FETCH_LABELS_ERROR,
  FETCH_LABELS_REQUEST,
  FETCH_LABELS_SUCCESS,
} from '../types'

const initialState = { byQuery: {}, errored: false, loading: false }

export default function reduceLabels(
  state = initialState,
  { type, meta: { searchQuery } = {}, payload: labels = [] }
) {
  if (type === FETCH_LABELS_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  if (type === FETCH_LABELS_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }
  if (type === FETCH_LABELS_SUCCESS) {
    return {
      ...state,
      byQuery: {
        ...state.byQuery,
        [searchQuery]: labels,
      },
      errored: false,
      loading: false,
    }
  }
  return state
}
