import { doLinkResource } from 'actions/userIntegrations'
import { oauthTokenSelector } from 'selectors/app'

import {
  CREATE_ISSUE_REQUEST,
  CREATE_ISSUE_SUCCESS,
  CREATE_ISSUE_ERROR,
} from '../types'
import gitHubGraphQl, { CLIENT_ID } from '../gitHubGraphQl'
import doPostTicketLinkOnGitHubIssue from './doPostTicketLinkOnGitHubIssue'

export default function doCreateAndLinkGitHubIssueToTicket({
  repository,
  title,
  body,
  assignee,
  label,
  milestone,
}) {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_ISSUE_REQUEST })
    const state = getState()
    const token = oauthTokenSelector(state)
    const query = `mutation(
      $assigneeIds: [ID!], 
      $body: String,
      $clientId: String, 
      $labelIds: [ID!], 
      $milestoneId: ID,
      $repositoryId: ID!,
      $title: String!
    ) {
      createIssue(input: {
        assigneeIds: $assigneeIds,
        body: $body,
        clientMutationId: $clientId,
        labelIds: $labelIds,
        milestoneId: $milestoneId,
        repositoryId: $repositoryId,
        title: $title
      }) {
        issue {
          __typename
          id
          assignees(first: 100) {
            nodes {
              login
              url
            }
          }
          createdAt
          databaseId
          labels(first: 100) {
            nodes {
              name
              color
              description
              url
            }
          }
          locked
          activeLockReason
          milestone {
            description
            title
            url
          }
          number
          repository {
            name
            nameWithOwner,
            url
          }
          state
          title
          updatedAt  
          url
        }
        
      }
    }`
    const variables = {
      assigneeIds: assignee,
      body,
      clientId: CLIENT_ID,
      labelIds: label,
      milestoneId: milestone,
      repositoryId: repository,
      title,
    }
    return gitHubGraphQl(token, query, variables)
      .then(({ data: { createIssue: { issue } } }) => {
        dispatch({
          meta: {},
          payload: { nodes: [issue] },
          type: CREATE_ISSUE_SUCCESS,
        })
        dispatch(
          doLinkResource({
            id: issue.id,
            provider: 'github',
            title,
            url: issue.url,
          })
        )
        dispatch(doPostTicketLinkOnGitHubIssue(issue.id))
      })
      .catch(error => {
        dispatch({
          error: true,
          meta: {},
          payload: error,
          type: CREATE_ISSUE_ERROR,
        })
      })
  }
}
