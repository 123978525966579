import { selectIsInInbox } from 'selectors/location'
import { selectIsTwitterTicket } from 'selectors/tickets/type'
import { createBasicSelector } from 'util/redux'

export const selectIsTwitterDisabled = () => true

export const selectShowTwitterDeprecation = createBasicSelector(
  selectIsInInbox,
  selectIsTwitterTicket,
  selectIsTwitterDisabled,
  (isInInbox, isTwitterTicket, isTwitterDisabled) => {
    return isTwitterDisabled && isTwitterTicket && isInInbox
  }
)
