import { toDate } from 'util/date'
import { createActionTypeReducer } from 'util/reducers'

import {
  FETCH_CUSTOMER_EVENTS_REQUEST_STARTED,
  FETCH_CUSTOMER_EVENTS_REQUEST_FAILED,
  FETCH_CUSTOMER_EVENTS_REQUEST_SUCCESS,
} from '../types'

const initialState = {
  byCustomerId: {},
  metaByCustomerId: {},
  customerIdsByIntegrationId: {},
}

export default createActionTypeReducer(
  {
    [FETCH_CUSTOMER_EVENTS_REQUEST_STARTED]: (draftState, action) => {
      const {
        payload: { customerId },
        meta: { integrationId },
      } = action

      if (!draftState.metaByCustomerId[customerId])
        draftState.metaByCustomerId[customerId] = {}

      draftState.metaByCustomerId[customerId].isLoading = true

      if (!draftState.customerIdsByIntegrationId[integrationId]) {
        draftState.customerIdsByIntegrationId[integrationId] = []
      }

      if (
        !draftState.customerIdsByIntegrationId[integrationId].includes(
          customerId
        )
      ) {
        draftState.customerIdsByIntegrationId[integrationId].push(customerId)
      }

      return draftState
    },
    [FETCH_CUSTOMER_EVENTS_REQUEST_FAILED]: (draftState, action) => {
      const {
        payload: { customerId },
      } = action

      if (!draftState.metaByCustomerId[customerId])
        draftState.metaByCustomerId[customerId] = {}

      draftState.metaByCustomerId[customerId].isLoading = false

      return draftState
    },
    [FETCH_CUSTOMER_EVENTS_REQUEST_SUCCESS]: (draftState, action) => {
      const { payload } = action
      const { customer } = payload
      const { events: { edges, pageInfo } = [] } = customer

      const nodes = edges.map(edge => {
        const node = edge.node
        node.date = toDate(node.createdAt).toLocaleDateString('en-US')
        return node
      })
      draftState.byCustomerId[customer.id] = nodes
      if (!draftState.metaByCustomerId[customer.id])
        draftState.metaByCustomerId[customer.id] = {}
      draftState.metaByCustomerId[customer.id].pageInfo = pageInfo
      draftState.metaByCustomerId[customer.id].isLoading = false

      return draftState
    },
  },
  initialState
)
