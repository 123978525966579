import { createSelector } from 'reselect'

import { selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider } from 'ducks/integrations/selectors'

import { selectCurrentTicketLinkedIssues as sharedSelector } from '../jiraShared/selectors'

export function selectCurrentTicketLinkedIssues(state) {
  return sharedSelector(state, {
    provider: 'jira_server',
    source: 'jiraServer',
  })
}

export const selectCurrentTicketJiraServerIssuesById = createSelector(
  selectCurrentTicketLinkedIssues,
  issues => {
    return issues.reduce((result, issue) => {
      // eslint-disable-next-line no-param-reassign
      result[issue.id] = issue
      return result
    }, {})
  }
)

export function selectJiraServerHiddenFields(state) {
  const {
    hidden,
  } = selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider(state, {
    provider: 'jira_server',
  })
  return hidden
}

export function selectJiraServerIssuesById(state) {
  return state.integrations.thirdPartyData.jiraServer.issues.byId
}

export function selectJiraServerVisibleFields(state) {
  const {
    visible,
  } = selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider(state, {
    provider: 'jira_server',
  })
  return visible
}
