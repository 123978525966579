import { createSelector } from 'reselect'

import { selectCurrentCompany } from './current'
import { selectLatestRequestKey } from './base'
import { selectPage } from './page'
import { selectPageRequestKey } from './fetching'

export function selectPageHasErrored(state) {
  const page = selectPage(state)
  return page && page.errored
}

export function selectPageIsLoading(state) {
  const page = selectPage(state)
  return page && page.loading
}

export const selectPageIsOrWillBeLoading = createSelector(
  selectPage,
  selectLatestRequestKey,
  selectPageRequestKey,
  (page, latestRequestKey, requestKey) => {
    // If there is no page or the current request doesn't match the latest one
    // we can assume a component will load it soon.
    return !page || page.loading || latestRequestKey !== requestKey
  }
)

export const selectCurrentCompanyIsPartiallyLoaded = createSelector(
  selectCurrentCompany,
  contact => {
    const { isLoaded, isPartiallyLoaded } = contact || {}
    return !!isLoaded || !!isPartiallyLoaded
  }
)
