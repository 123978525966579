import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'
import {
  FETCH_SITES_ERROR,
  FETCH_SITES_REQUEST,
  FETCH_SITES_SUCCESS,
} from '../types'

export default function doFetchJiraCloudSite() {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    dispatch({ type: FETCH_SITES_REQUEST })
    return integrationApi
      .get(token, 'proxy/atlassian/oauth/token/accessible-resources')
      .then(({ json: sites } = {}) => {
        dispatch({ type: FETCH_SITES_SUCCESS, payload: { sites } })
      })
      .catch(error => {
        dispatch({ error: true, type: FETCH_SITES_ERROR, payload: error })
      })
  }
}
