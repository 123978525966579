export const orderPartial = `
id
customer {
  id
}
createdAt
refundable
restockable
name
unpaid
requiresShipping
note
tags
currencyCode
presentmentCurrencyCode
lineItems(first: 10) {
    edges {
      node {
        id
        name
        discountedTotalSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        originalUnitPriceSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        discountedUnitPriceSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        currentQuantity
        quantity
        product {
          id
          hasOnlyDefaultVariant
        }
        image {
          url
        }
        variant {
          id
          displayName
          price
          sku
      }
    }
  }
}

cancelledAt

displayFinancialStatus
displayFulfillmentStatus

fulfillments {
  id
  displayStatus
  trackingInfo {
  company
  number
  url
  }
}

currentSubtotalPriceSet {
  shopMoney {
    amount
    currencyCode
  }
}

currentTotalTaxSet {
  shopMoney {
    amount
    currencyCode
  }
}

currentCartDiscountAmountSet {
  shopMoney {
    amount
    currencyCode
  }
}

currentTotalPriceSet {
  shopMoney {
    amount
    currencyCode
  }
}

netPaymentSet {
  shopMoney {
    amount
    currencyCode
  }
}

totalShippingPriceSet {
  shopMoney {
    amount
    currencyCode
  }
}

totalReceivedSet {
  shopMoney {
    amount
    currencyCode
  }
}

refunds {
  id
  note
  totalRefundedSet {
    shopMoney {
      amount
      currencyCode
    }
  }
}

billingAddressMatchesShippingAddress
billingAddress {
  formatted(withName: true, withCompany: true)
}
shippingAddress {
  formatted(withName: true, withCompany: true)
}
`

export const draftOrderPartial = `
  id
  createdAt
  note2
  invoiceSentAt
  customer {
    id
    email
  }
  appliedDiscount {
    value
    valueType
    description
  }
  name
  tags
  taxExempt
  totalTax
  totalPrice
  lineItemsSubtotalPrice {
    shopMoney {
      amount
      currencyCode
    }
  }

  shippingLine {
    id
    custom
    shippingRateHandle
    title
    originalPriceSet {
      shopMoney {
        amount
        currencyCode
      }
    }
  }

  lineItems(first: 10) {
    edges {
      node {
        id
        custom
        quantity
        name
        originalUnitPrice
        requiresShipping
        taxable
        appliedDiscount {
          value
          valueType
          description
        }
        image {
          url
        }
        product {
          hasOnlyDefaultVariant
        }
        variant {
          id
          displayName
          price
          inventoryQuantity
          sku
        }
      }
    }
  }
`
