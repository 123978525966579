import { oauthTokenSelector } from 'selectors/app'
import config from 'config'
import { doLinkResource } from 'actions/userIntegrations'

import { selectCurrentContactOrCustomerEmail } from 'ducks/crm/contacts'
import { selectIntegrationCredentialsForProvider } from 'ducks/integrations/selectors'

import doFetchMatchingObjects from './doFetchMatchingObjects'
import * as api from '../api'
import {
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
} from '../types'

export default function doCreateContact(formFields) {
  return (dispatch, getState) => {
    const state = getState()
    const email = selectCurrentContactOrCustomerEmail(state)
    dispatch({
      type: CREATE_CONTACT_REQUEST,
      data: {
        email,
      },
    })
    const credentials = selectIntegrationCredentialsForProvider(state, {
      provider: 'salesforce',
    })

    const token = oauthTokenSelector(state)

    return api
      .createLead(token, JSON.stringify(formFields))
      .then(payload => {
        const { response } = payload
        dispatch({
          type: CREATE_CONTACT_SUCCESS,
          data: {
            email,
            ...response,
          },
        })
        return payload
      })
      .then(payload => {
        const { response } = payload
        const id = response.id
        const title = [formFields.FirstName, formFields.LastName].join(' ')
        const url = [credentials.storeDomain, id].join('/')
        dispatch(doLinkResource({ title, url, id, provider: 'salesforce' }))
        dispatch(doFetchMatchingObjects(email))
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        if (config.isDevelopment) console.error(error)
        dispatch({
          type: CREATE_CONTACT_FAILURE,
          data: {
            email,
            error,
          },
        })
        throw error
      })
  }
}
