export const INSERT_CANNED_REPLY_REQUEST =
  'cannedReplies/INSERT_CANNED_REPLY_REQUEST'
export const INSERT_CANNED_REPLY = 'cannedReplies/INSERT_CANNED_REPLY'

export const FETCH_CANNED_REPLY_CATEGORIES =
  'cannedReplies/FETCH_CANNED_REPLY_CATEGORIES'
export const FETCH_CANNED_REPLY_CATEGORIES_STARTED =
  'cannedReplies/FETCH_CANNED_REPLY_CATEGORIES_STARTED'
export const FETCH_CANNED_REPLY_CATEGORIES_SUCCESS =
  'cannedReplies/FETCH_CANNED_REPLY_CATEGORIES_SUCCESS'
export const FETCH_CANNED_REPLY_CATEGORIES_FAILED =
  'cannedReplies/FETCH_CANNED_REPLY_CATEGORIES_FAILED'
export const FETCH_CANNED_REPLY_CATEGORIES_INMEMORY =
  'cannedReplies/FETCH_CANNED_REPLY_CATEGORIES_INMEMORY'

export const FETCH_CANNED_REPLIES = 'cannedReplies/FETCH_CANNED_REPLIES'
export const FETCH_CANNED_REPLIES_STARTED =
  'cannedReplies/FETCH_CANNED_REPLIES_STARTED'
export const FETCH_CANNED_REPLIES_SUCCESS =
  'cannedReplies/FETCH_CANNED_REPLIES_SUCCESS'
export const FETCH_CANNED_REPLIES_FAILED =
  'cannedReplies/FETCH_CANNED_REPLIES_FAILED'

export const FETCH_CANNED_REPLY_VARIABLES =
  'cannedReplies/FETCH_CANNED_REPLY_VARIABLES'
export const FETCH_CANNED_REPLY_VARIABLES_STARTED =
  'cannedReplies/FETCH_CANNED_REPLY_VARIABLES_STARTED'
export const FETCH_CANNED_REPLY_VARIABLES_SUCCESS =
  'cannedReplies/FETCH_CANNED_REPLY_VARIABLES_SUCCESS'
export const FETCH_CANNED_REPLY_VARIABLES_FAILED =
  'cannedReplies/FETCH_CANNED_REPLY_VARIABLES_FAILED'

export const FETCH_CANNED_REPLY_TEMPLATE =
  'cannedReplies/FETCH_CANNED_REPLY_TEMPLATE'
export const FETCH_CANNED_REPLY_TEMPLATE_STARTED =
  'cannedReplies/FETCH_CANNED_REPLY_TEMPLATE_STARTED'
export const FETCH_CANNED_REPLY_TEMPLATE_SUCCESS =
  'cannedReplies/FETCH_CANNED_REPLY_TEMPLATE_SUCCESS'
export const FETCH_CANNED_REPLY_TEMPLATE_FAILED =
  'cannedReplies/FETCH_CANNED_REPLY_TEMPLATE_FAILED'

export const SAVE_CANNED_REPLY_CATEGORY =
  'cannedReplies/SAVE_CANNED_REPLY_CATEGORY'
export const SAVE_CANNED_REPLY_CATEGORY_STARTED =
  'cannedReplies/SAVE_CANNED_REPLY_CATEGORY_STARTED'
export const SAVE_CANNED_REPLY_CATEGORY_SUCCESS =
  'cannedReplies/SAVE_CANNED_REPLY_CATEGORY_SUCCESS'
export const SAVE_CANNED_REPLY_CATEGORY_FAILED =
  'cannedReplies/SAVE_CANNED_REPLY_CATEGORY_FAILED'
export const SAVE_CANNED_REPLY_CATEGORY_DRAFT =
  'cannedReplies/SAVE_CANNED_REPLY_CATEGORY_DRAFT'

export const UPDATE_CANNED_REPLY_TEMPLATE_OPTIMISTIC =
  'cannedReplies/UPDATE_CANNED_REPLY_TEMPLATE_OPTIMISTIC'
export const SAVE_CANNED_REPLY_TEMPLATE =
  'cannedReplies/SAVE_CANNED_REPLY_TEMPLATE'
export const SAVE_CANNED_REPLY_TEMPLATE_STARTED =
  'cannedReplies/SAVE_CANNED_REPLY_TEMPLATE_STARTED'
export const SAVE_CANNED_REPLY_TEMPLATE_SUCCESS =
  'cannedReplies/SAVE_CANNED_REPLY_TEMPLATE_SUCCESS'
export const SAVE_CANNED_REPLY_TEMPLATE_FAILED =
  'cannedReplies/SAVE_CANNED_REPLY_TEMPLATE_FAILED'

export const SAVE_CANNED_REPLY_TEMPLATE_DRAFT =
  'cannedReplies/SAVE_CANNED_REPLY_TEMPLATE_DRAFT'
export const CLEAR_CANNED_REPLY_TEMPLATE_DRAFT =
  'cannedReplies/CLEAR_CANNED_REPLY_TEMPLATE_DRAFT'

export const TOGGLE_CANNED_REPLY_COLLAPSED_STATE =
  'cannedReplies/TOGGLE_CANNED_REPLY_COLLAPSED_STATE'

export const SET_TEMPLATE_SEARCH_TERM = 'cannedReplies/SET_TEMPLATE_SEARCH_TERM'
export const CLEAR_TEMPLATES = 'cannedReplies/CLEAR_TEMPLATES'

export const DELETE_CANNED_REPLIES = 'cannedReplies/DELETE_CANNED_REPLIES'
export const DELETE_CANNED_REPLIES_STARTED =
  'cannedReplies/DELETE_CANNED_REPLIES_STARTED'
export const DELETE_CANNED_REPLIES_SUCCESS =
  'cannedReplies/DELETE_CANNED_REPLIES_SUCCESS'
export const DELETE_CANNED_REPLIES_FAILED =
  'cannedReplies/DELETE_CANNED_REPLIES_FAILED'

export const UPDATE_CANNED_REPLY_CATEGORY =
  'cannedReplies/UPDATE_CANNED_REPLY_CATEGORY'
export const UPDATE_CANNED_REPLY_CATEGORY_STARTED =
  'cannedReplies/UPDATE_CANNED_REPLY_CATEGORY_STARTED'
export const UPDATE_CANNED_REPLY_CATEGORY_SUCCESS =
  'cannedReplies/UPDATE_CANNED_REPLY_CATEGORY_SUCCESS'
export const UPDATE_CANNED_REPLY_CATEGORY_FAILED =
  'cannedReplies/UPDATE_CANNED_REPLY_CATEGORY_FAILED'

export const CREATE_CANNED_REPLY_CATEGORY_DRAFT =
  'cannedReplies/CREATE_CANNED_REPLY_CATEGORY_DRAFT'

export const UPLOAD_CANNED_REPLY_ATTACHMENT =
  'cannedReplies/UPLOAD_CANNED_REPLY_ATTACHMENT'
export const UPLOAD_CANNED_REPLY_ATTACHMENT_STARTED =
  'cannedReplies/UPLOAD_CANNED_REPLY_ATTACHMENT_STARTED'
export const UPLOAD_CANNED_REPLY_ATTACHMENT_SUCCESS =
  'cannedReplies/UPLOAD_CANNED_REPLY_ATTACHMENT_SUCCESS'
export const UPLOAD_CANNED_REPLY_ATTACHMENT_FAILED =
  'cannedReplies/UPLOAD_CANNED_REPLY_ATTACHMENT_FAILED'

export const TOGGLE_CANNED_REPLY_DROPDOWN =
  'cannedReplies/TOGGLE_CANNED_REPLY_DROPDOWN'
