import { createSelector } from 'reselect'
import { selectIsPendingCancellation } from './selectIsPendingCancellation'
import { selectIsBillingAccountActive } from './selectIsBillingAccountActive'

export const selectIsSubscriptionActive = createSelector(
  selectIsBillingAccountActive,
  selectIsPendingCancellation,
  (isBillingAccountActive, isPendingCancellation) => {
    if (isPendingCancellation) return false
    return isBillingAccountActive
  }
)
