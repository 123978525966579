import { SET_EDITING } from '../types'

export function doSetEditing(isEditing) {
  return {
    type: SET_EDITING,
    payload: {
      isEditing,
    },
  }
}

export function doStartEditing() {
  return doSetEditing(true)
}

export function doStopEditing() {
  return doSetEditing(false)
}
