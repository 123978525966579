import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'
import { selectAccountGlobalId } from './selectAccountId'
import { selectAccountsById } from './selectAccountsById'

// Kevin R (2023-12-11)
// !!! IMPORTANT !!!
// The following method pulls the account entity from the entities store
// The entities store variation of account doesnt have feature partity with
// the GQL version. Ideally we'll use this one moving forward and extend the
// api as required
export const selectCurrentAccount = createBasicSelector(
  selectAccountGlobalId,
  selectAccountsById,
  (accountId, accountsById) => accountsById[accountId] || emptyObj
)
