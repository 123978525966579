import { v4 as uuidV4 } from 'uuid'
import { accountPreferenceQuery, accountPreferencesQuery } from 'api/account'
import { doGraphqlRequest, doApiPutRequest } from 'ducks/requests/operations'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'
import { selectAccountUsageOnboarding } from 'selectors/app/base'
import { selectShowOnboardingWorkflow } from 'subapps/onboarding/selectors/selectShowOnboardingWorkflow'
import { BEGIN } from 'redux-optimist'
import { capture } from 'ducks/tracking/actions'
import { doSetFlag } from 'ducks/flags/operations'
import {
  FETCH_ACCOUNT_PREFERENCES,
  FETCH_ACCOUNT_PREFERENCE,
  UPDATE_ACCOUNT_PREFERENCES_USAGE_ONBOARDING_STARTED,
  UPDATE_ACCOUNT_PREFERENCES,
} from '../types'

// eslint-disable-next-line no-unused-vars
export const doFetchAccountPreferences = (options = {}) => dispatch => {
  return dispatch(
    doGraphqlRequest(FETCH_ACCOUNT_PREFERENCES, accountPreferencesQuery)
  )
}

export const doFetchAccountPreference = (
  preference,
  // eslint-disable-next-line no-unused-vars
  options = {}
) => dispatch => {
  return dispatch(
    doGraphqlRequest(
      FETCH_ACCOUNT_PREFERENCE,
      accountPreferenceQuery(preference)
    )
  )
}

export const doUpdateAccountPreference = (
  key,
  value,
  options = {}
) => dispatch => {
  return dispatch(
    doApiPutRequest(
      UPDATE_ACCOUNT_PREFERENCES,
      'v1/preferences',
      {
        account: {
          [`prefers_${key}`]: value,
        },
      },
      {
        moduleOptions: {
          toasts: {
            enabled: false,
          },
        },
        ...options,
      }
    )
  )
}

const checkUsageOnboardingUsingUsageKey = async ({
  dispatch,
  usageKey,
  id,
}) => {
  const result = await dispatch(doFetchAccountPreference('usage_onboarding'))
  if (result?.account?.preferences?.usage_onboarding?.[usageKey]) {
    clearInterval(id)
  }
}

// Try to fetch usage_onboarding data for onboarding workflow
export const doTryFetchAccountUsageOnboardingForOnboarding = (
  usageKey,
  { completed = true, completedEventName, shouldSetFlag } = {}
) => (dispatch, getState) => {
  const state = getState()
  const showOnboardingWorkflow = selectShowOnboardingWorkflow(state)
  const accountUsageOnboarding = selectAccountUsageOnboarding(state)
  // Only fetch data if
  // 1. The onboarding indicator is showing
  // 2. The usage doesn't exist
  if (
    showOnboardingWorkflow &&
    (!usageKey || !accountUsageOnboarding[usageKey])
  ) {
    const transactionId = uuidV4()
    const onboardingWorkflowData = selectFeatureBasedOnboardingWorkflowData(
      state
    )
    if (completed && usageKey && !accountUsageOnboarding[usageKey]) {
      const onboardingStepConfig =
        onboardingWorkflowData[
          Object.keys(onboardingWorkflowData).find(
            key => onboardingWorkflowData[key].usageKey === usageKey
          )
        ]
      dispatch({
        type: UPDATE_ACCOUNT_PREFERENCES_USAGE_ONBOARDING_STARTED,
        payload: {
          usageOnboarding: { [usageKey]: onboardingStepConfig?.isBool || 1 },
        },
        optimist: { type: BEGIN, id: transactionId },
        meta: {
          requestId: transactionId,
        },
      })
      if (shouldSetFlag && onboardingStepConfig?.flagName) {
        dispatch(doSetFlag(onboardingStepConfig?.flagName))
      }
      const timerCallbackArgs = { id: null, dispatch, usageKey }
      timerCallbackArgs.id = setInterval(
        checkUsageOnboardingUsingUsageKey,
        5000,
        timerCallbackArgs
      )
      // after 15 seconds stop
      setTimeout(() => {
        clearInterval(timerCallbackArgs.id)
      }, 15000)
      if (completedEventName) {
        capture(completedEventName)
      }
    } else {
      return dispatch(doFetchAccountPreference('usage_onboarding'))
    }
  }
  return null
}
