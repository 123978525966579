/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { emptyObj } from 'util/objects'

import {
  FETCH_CONTACTS_FAILED,
  FETCH_CONTACTS_STARTED,
  FETCH_CONTACTS_SUCCESS,
} from '../types'

export default createActionTypeReducer(
  {
    [FETCH_CONTACTS_STARTED]: (draftState, { meta: { requestKey } = {} }) => {
      draftState[requestKey] = {
        ...draftState[requestKey],
        loading: true,
      }
    },
    [FETCH_CONTACTS_SUCCESS]: (
      draftState,
      {
        entities: { contacts: contactEntities = {} } = {},
        meta: { requestKey } = {},
        payload: { contacts: contactsPayload },
      }
    ) => {
      const { pageInfo } = contactsPayload || {}
      const search = draftState[requestKey]
      search.ids = contactEntities && Object.keys(contactEntities)
      search.errored = false
      search.pageInfo = pageInfo
      search.loading = false
    },
    [FETCH_CONTACTS_FAILED]: (
      draftState,
      { meta: { requestKey } = {}, payload: { error } = {} }
    ) => {
      const search = draftState[requestKey]
      search.loading = false
      search.errored = true
      search.error = {
        message: error.message,
      }
    },
  },
  emptyObj
)
