import { oauthTokenSelector } from 'selectors/app'

import debug from 'util/debug'
import * as api from '../api'

const doFetchCustomer = (integrationId, id) => async (dispatch, getState) => {
  const state = getState()
  const token = oauthTokenSelector(state)

  try {
    const { response, code } = await api.getCustomer(token, integrationId, id)
    return { response, code }
  } catch (error) {
    debug(error)
    return error
  }
}

export default doFetchCustomer
