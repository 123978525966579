import { isDefined } from 'util/nullOrUndefinedChecks'
import { createSelector } from 'reselect'
import { selectBillingUsage } from '../selectBillingUsage'
import { selectLocalUsage } from './selectLocalUsage'

export const selectUsage = createSelector(
  selectLocalUsage,
  selectBillingUsage,
  (localUsage, billingUsage) => {
    const usage = {}
    Object.keys(billingUsage).forEach(key => {
      usage[key] = isDefined(localUsage[key])
        ? localUsage[key]
        : billingUsage[key]
    })
    return usage
  }
)
