import { uniq } from 'util/arrays'

import doFetchJiraCloudIssues from './doFetchJiraCloudIssues'

export default function doFetchJiraCloudResourceFromActions(actions) {
  const issueIds = actions.map(
    ({
      change: {
        action: {
          issue: { id },
        },
        external_id: externalId,
      } = {},
    }) => {
      const siteName = externalId.match(/^[^:]+/)[0]
      return `${siteName}:${id}`
    }
  )
  const uniqIssueIds = uniq(issueIds)

  return doFetchJiraCloudIssues(uniqIssueIds)
}
