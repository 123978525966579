/* eslint-disable no-param-reassign */
import { schema } from 'normalizr'
import { getRawId, isGid, buildId } from 'util/globalId'
import {
  snakecaseKeys,
  camelizeObjectKeys,
  snakecaseKeysNested,
} from 'util/objects'
import { hash, convertGidToScatterId } from 'util/scatterSwap'
import { camelize, capitalize } from 'util/strings'
import { isChatChannelType } from 'ducks/channels/channelTypes'
import { isDefined } from 'util/nullOrUndefinedChecks'

const convertToV1AndStoreRawIdField = (field, entity) => {
  const rawFieldName = `raw${capitalize(field)}`

  if (!entity[rawFieldName]) entity[rawFieldName] = entity[field]
  const scatterId = hash(entity[rawFieldName])
  if (entity[field] !== scatterId) entity[field] = scatterId
}

const convertToV2AndStoreRawIdField = (field, gidType, entity) => {
  const rawFieldName = `raw${capitalize(field)}`

  if (!entity[rawFieldName]) entity[rawFieldName] = entity[field]
  const gid = buildId(gidType, hash(entity[rawFieldName]))
  if (entity[field] !== gid) entity[field] = gid
}

const idToEntity = (idField, entityType, entity) => {
  entity[entityType] = { id: entity[idField] }
}

const processStrategyV0ToV1 = entity => {
  const updatedEntity = camelizeObjectKeys(entity)
  convertToV1AndStoreRawIdField('id', updatedEntity)
  return updatedEntity
}

const processStrategyV0ToV2 = gidType => entity => {
  const updatedEntity = camelizeObjectKeys(entity)
  convertToV2AndStoreRawIdField('id', gidType, updatedEntity)
  return updatedEntity
}

const idAttributeV0ToV1 = entity => {
  return hash(entity.id)
}

const idAttributeV0ToV2 = gidType => entity => {
  if (isGid(entity.id)) {
    return entity.id
  }
  return buildId(gidType, hash(entity.id))
}

// support gid being returned from V0 settings api
// if exists, convert to id (scatterswap) which is used for overview page
// otherwise return normal id
const idAttributeV2ToV1 = entity => {
  return entity.gid ? getRawId(entity.gid) : entity.id
}

export const defaultAgentNotificationPreferences = new schema.Entity(
  'defaultAgentNotificationPreferences',
  {},
  {
    idAttribute: 'key',
  }
)

export const agentNotificationPreferences = new schema.Entity(
  'agentNotificationPreferences',
  {},
  {
    idAttribute: 'key', // should be the id but key is easier for the notifications page + RHF
  }
)

export const agentPreferences = new schema.Entity(
  // HACK: agentPreferences conflicts with the agentsPreferences duck
  // which somehow cannot load the agent preeferences
  'userPreferences',
  {},
  {
    idAttribute: idAttributeV0ToV1,
    processStrategy: processStrategyV0ToV1,
  }
)

export const agent = new schema.Entity('agent', {
  preferences: agentPreferences,
})

export const team = new schema.Entity(
  'team',
  {},
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      if (entity.agent_ids !== undefined) {
        updatedEntity.agents = entity.agent_ids.map(agentId => ({
          id: agentId,
        }))
        delete updatedEntity.agent_ids
      }
      return updatedEntity
    },
  }
)

export const cannedReplyCategory = new schema.Entity('cannedReplyCategory')

export const attachment = new schema.Entity(
  'attachment',
  {
    creator: agent,
  },
  {
    idAttribute: idAttributeV2ToV1,
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      if (entity.gid !== undefined) {
        updatedEntity.id = getRawId(entity.gid)
        delete updatedEntity.gid
      }
      if (entity.creator_gid !== undefined) {
        updatedEntity.creator = getRawId(entity.creator_gid)
        delete updatedEntity.creator_gid
      }
      return updatedEntity
    },
  }
)

export const cannedReply = new schema.Entity(
  'cannedReply',
  {
    category: cannedReplyCategory,
    attachments: [attachment],
  },
  {
    idAttribute: idAttributeV2ToV1,
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      // START: V0 Settings API response
      if (entity.gid !== undefined) {
        updatedEntity.id = getRawId(entity.gid)
        delete updatedEntity.gid
      }

      if (entity.category_gid !== undefined) {
        updatedEntity.category_id = getRawId(entity.category_gid)
        delete updatedEntity.category_gid
      }

      if (entity.creator_gid !== undefined) {
        updatedEntity.creator_id = getRawId(entity.creator_gid)
        delete updatedEntity.creator_gid
      }

      if (entity.canned_reply_categories) {
        delete updatedEntity.canned_reply_categories
      }

      if (entity.title !== undefined) {
        // it's saved as name in the entity table
        updatedEntity.name = entity.title
        delete updatedEntity.title
      }
      // END: V0 Settings API response

      // using updatedEntity here because category_id might already been changed by v0 settings entity processing above
      if (updatedEntity.category_id !== undefined) {
        if (updatedEntity.category_id !== null) {
          updatedEntity.category = { id: updatedEntity.category_id }
        } else {
          updatedEntity.category = null
        }

        delete updatedEntity.category_id
      }

      return updatedEntity
    },
  }
)

export const starredCannedReply = new schema.Entity(
  'starredCannedReply',
  {},
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      const gid = entity.id

      updatedEntity.gid = entity.id
      updatedEntity.id = getRawId(gid)

      return updatedEntity
    },
  }
)

export const cannedReplyVariables = new schema.Entity(
  'cannedReplyVariables',
  {},
  { idAttribute: 'key' }
)

export const channel = new schema.Entity(
  'channel',
  {},
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      // Make sure widget properties for ChannelDataTable is the same as mailbox ones
      if (isChatChannelType(entity.channelType)) {
        updatedEntity.color = entity.channelColor
        updatedEntity.address = entity.name
        updatedEntity.state = entity?.publishedSettings?.enabled
          ? 'active'
          : entity.status
        delete updatedEntity.channelColor
        return snakecaseKeys(updatedEntity)
      }
      return updatedEntity
    },
  }
)

export const folder = new schema.Entity(
  'folder',
  {
    agents: {
      nodes: [agent],
    },
    teams: {
      nodes: [team],
    },
    channels: {
      nodes: [channel],
    },
  },
  {
    processStrategy: entity => {
      const gqlTypes = ['agents', 'conditions', 'teams', 'channels']
      gqlTypes.forEach(key => {
        if (entity[key] && entity[key].nodes) {
          // Perhaps we should merge the arrays instead of completely
          // replacing it?
          entity[key] = entity[key].nodes
        } else if (entity[key] && !Array.isArray(entity[key])) {
          entity[key] = []
        }
      })
      return entity
    },
  }
)

export const ruleActionMessageTemplate = new schema.Entity(
  'ruleActionMessageTemplate',
  {
    attachments: [attachment],
  }
)

export const ruleAction = new schema.Entity('ruleAction', {
  rule_reply_template: ruleActionMessageTemplate,
})

export const ruleCondition = new schema.Entity(
  'ruleCondition',
  {},
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }

      if (['assignee', 'mailbox'].includes(updatedEntity.param)) {
        updatedEntity.value = updatedEntity.value
          .split(',')
          .map(id => hash(id))
          .join(',')
      }

      return updatedEntity
    },
  }
)

export const ruleTrigger = new schema.Entity('ruleTrigger')

export const rule = new schema.Entity(
  'rule',
  {
    creator: agent,
    conditions: [ruleCondition],
    actions: [ruleAction],
    triggers: [ruleTrigger],
  },
  {
    idAttribute: idAttributeV2ToV1,
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      if (entity.gid !== undefined) {
        updatedEntity.id = getRawId(entity.gid)
        delete updatedEntity.gid
      }
      if (entity.creator_gid !== undefined) {
        updatedEntity.creator = getRawId(entity.creator_gid)
        delete updatedEntity.creator_gid
      }
      return updatedEntity
    },
  }
)

export const tag = new schema.Entity(
  'tag',
  {
    creator: agent,
  },
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      // Agents currently use scatterwap ids and we're returning gids.
      // we'll just convert that here for now
      if (entity.creator) {
        updatedEntity.creator.id = getRawId(entity.creator.id)
      }
      return updatedEntity
    },
  }
)

export const batchJob = new schema.Entity('batchJob')

export const integrationInstallStateProviderValue = new schema.Entity(
  'integrationInstallStateProviderValue'
)

export const integration = new schema.Entity('integration', {
  installStateValue: {
    provider: [integrationInstallStateProviderValue],
  },
})

export const widget = new schema.Entity(
  'widget',
  {},
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      updatedEntity.enabled = entity.publishedSettings?.enabled
      updatedEntity.status = updatedEntity.enabled ? 'Visible' : 'Not visible'
      return snakecaseKeys(updatedEntity)
    },
  }
)

export const webhook = new schema.Entity(
  'webhook',
  {},
  {
    idAttribute: idAttributeV0ToV1,
    processStrategy: processStrategyV0ToV1,
  }
)

export const accessToken = new schema.Entity(
  'accessToken',
  {},
  {
    idAttribute: idAttributeV0ToV1,
    processStrategy: entity => {
      const updatedEntity = processStrategyV0ToV1(entity)
      convertToV1AndStoreRawIdField('resourceOwnerId', updatedEntity)
      return updatedEntity
    },
  }
)

export const spammer = new schema.Entity('spammer', {
  creator: agent,
})

export const emailMarketingIntegrationMailboxSetting = new schema.Entity(
  'emailMarketingIntegrationMailboxSetting',
  {
    channel,
  },
  {
    idAttribute: idAttributeV0ToV2('EmailMarketingIntegrationMailboxSetting'),
    processStrategy: entity => {
      const updatedEntity = processStrategyV0ToV2(
        'EmailMarketingIntegrationMailboxSetting'
      )(entity)
      convertToV1AndStoreRawIdField('mailboxId', updatedEntity)
      idToEntity('mailboxId', 'channel', updatedEntity)
      return updatedEntity
    },
  }
)

export const emailMarketingIntegration = new schema.Entity(
  'emailMarketingIntegration',
  {
    mailboxSettings: [emailMarketingIntegrationMailboxSetting],
  },
  {
    idAttribute: entity => entity.type.toUpperCase(),
    processStrategy: camelizeObjectKeys,
  }
)

export const dataExport = new schema.Entity(
  'dataExport',
  {},
  {
    processStrategy: entity => ({
      ...entity,
      requestedById: convertGidToScatterId(entity.requestedBy?.id),
    }),
  }
)

export const invoice = new schema.Entity('invoice')

export const facebookPage = new schema.Entity('facebookPage')

export const customerRatingSetting = new schema.Entity('customerRatingSetting')

export const processStrategyRuleTemplate = entity => {
  const updatedEntity = { ...entity }

  const {
    template: {
      matchType,
      state,
      conditions = [],
      actions = [],
      triggers = [],
      scheduleType,
    },
  } = updatedEntity

  // match the structure of rules entity used for create/edit drawer

  if (isDefined(matchType)) {
    updatedEntity.template.matchType = matchType.toLowerCase()
  }

  if (isDefined(state)) {
    updatedEntity.template.active = state === 'ACTIVE'
    delete updatedEntity.template.state
  }

  if (isDefined(conditions)) {
    updatedEntity.template.conditions = conditions.map(c => {
      if (isDefined(c.operator)) {
        c.operator = c.operator.toLowerCase()
      }

      if (isDefined(c.param)) {
        c.param = c.param.toLowerCase()
      }

      return c
    })
  }

  if (isDefined(actions)) {
    updatedEntity.template.actions = actions.map(a => {
      if (isDefined(a.type)) {
        a.type = a.type.toLowerCase()
      }

      return a
    })
  }

  if (isDefined(triggers)) {
    updatedEntity.template.triggers = triggers.map(t => {
      if (isDefined(t.type)) {
        t.type = t.type.toLowerCase()
      }

      return t
    })
  }

  if (isDefined(scheduleType)) {
    updatedEntity.template.scheduleType = scheduleType.toLowerCase()
  }

  updatedEntity.template = snakecaseKeysNested(updatedEntity.template)

  return updatedEntity
}

export const featureTemplate = new schema.Entity(
  'featureTemplate',
  {},
  {
    processStrategy: entity => {
      switch (entity?.type) {
        case 'rules':
          return processStrategyRuleTemplate(entity)
        default:
          return entity
      }
    },
  }
)
export const featureTemplateCategory = new schema.Entity(
  'featureTemplateCategory',
  {},
  {
    idAttribute: entity => entity,
  }
)

export const feature = new schema.Entity(
  'feature',
  {},
  {
    idAttribute: 'key',
    processStrategy: entity => ({
      ...entity,
      name: camelize(entity.name),
    }),
  }
)

export const usage = new schema.Entity(
  'usage',
  {},
  {
    idAttribute: 'key',
  }
)

export const pricing = new schema.Entity(
  'pricing',
  {},
  {
    processStrategy: entity => {
      const updatedEntity = { ...entity }
      updatedEntity.usageFrom = updatedEntity.usageFrom.toLowerCase()
      updatedEntity.features = updatedEntity.features.map(f => ({
        ...f,
        name: camelize(f.name),
      }))
      return updatedEntity
    },
  }
)

export const plan = new schema.Entity('plan', {
  pricing,
})

export const account = new schema.Entity('account')

export const creditCard = new schema.Entity('creditCard')

export const coupon = new schema.Entity('coupon')

export const discount = new schema.Entity('discount', {
  coupon,
})

export const setupIntent = new schema.Entity('setupIntent')

// btw, the entity name needs to match the variable name
export default {
  accessToken,
  account,
  agent,
  agentNotificationPreferences,
  attachment,
  batchJob,
  cannedReply,
  cannedReplyCategory,
  channel,
  coupon,
  creditCard,
  customerRatingSetting,
  dataExport,
  defaultAgentNotificationPreferences,
  discount,
  emailMarketingIntegration,
  emailMarketingIntegrationMailboxSetting,
  facebookPage,
  feature,
  featureTemplate,
  featureTemplateCategory,
  folder,
  integration,
  integrationInstallStateProviderValue,
  invoice,
  plan,
  pricing,
  rule,
  setupIntent,
  spammer,
  starredCannedReply,
  tag,
  team,
  usage,
  webhook,
  widget,
}
