import { createSelector } from 'reselect'
import { selectIsBillingDataLoaded } from './selectIsBillingDataLoaded'
import { selectIsAccountClosed } from './selectIsAccountClosed'
import { selectIsAccountExpired } from './selectIsAccountExpired'
import { selectAvailableFeatures } from './selectAvailableFeatures'

export const selectIsBillingAccountActive = createSelector(
  selectIsAccountExpired,
  selectIsAccountClosed,
  selectAvailableFeatures,
  selectIsBillingDataLoaded,
  (isAccountExpired, isAccountClosed, availableFeatures, isBillingLoaded) => {
    if (!isBillingLoaded) return true
    if (isAccountExpired || isAccountClosed) return false
    if (!availableFeatures.users) return false
    return true
  }
)
