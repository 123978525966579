import { doUpdateRoom } from 'ducks/chat/actions/rooms'
import { selectRoomsById } from 'ducks/chat/selectors/rooms'
import { uniqBy } from 'util/arrays'
import { snakecaseKeys } from 'util/objects'

function getExternalId({ externalId, provider }) {
  return `${provider}:${externalId}`
}

export default function doLinkResourceToRoom(params) {
  const {
    id,
    provider,
    title,
    removed = false,
    roomId,
    url,
    ...otherParams
  } = params
  return (dispatch, getState) => {
    const state = getState()
    const room = selectRoomsById(state)[roomId]
    const existingLinkedExternalResources =
      room?.linkedExternalResources?.records || []
    const otherParamsSnakeCase = snakecaseKeys(otherParams || {})

    dispatch(
      doUpdateRoom(roomId, {
        linkedExternalResources: {
          records: uniqBy(
            [
              {
                external_id: id,
                linked_at: new Date(),
                provider,
                title,
                url,
                removed,
                ...otherParamsSnakeCase,
              },
              ...existingLinkedExternalResources,
            ],
            getExternalId
          ),
        },
      })
    )
  }
}
