import { useMemo, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  doDraftOrderComplete,
  doDraftOrderDelete,
  doDraftOrderCreate,
  doDraftOrderCreateFromOrder,
  doDraftOrderUpdate,
  doDraftOrderCalculate,
  doFetchOrders,
  doFetchShopInfo,
  doFetchEvents,
} from 'ducks/integrations/shopify/operations'
import { selectIntegrationCredentialsForId } from 'ducks/integrations/selectors'
import { emptyObj } from 'util/objects'
import { debounceAsync } from 'util/functions'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_SHOPIFY_CREATE_ORDER } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  selectDraftOrderCalculateRequest,
  selectDraftOrderCompleteRequest,
  selectDraftOrderForCustomerId,
  selectEventsForCustomerIdGroupedByDay,
  selectOrdersForCustomerId,
  selectOrderForId,
  selectOrdersMetaForCustomerId,
} from './selectors'
import { shopifyGidToId, shopifyStoreAdminUrl } from './utils'
import {
  EVENTS_LIMIT,
  RECENT_ORDERS_LIMIT,
  MORE_ORDERS_LIMIT,
} from './constants'

export const useShopifyDraft = ({ customerId, integrationId }) => {
  const dispatch = useDispatch()
  const { loading: isCalculating } = useSelector(
    selectDraftOrderCalculateRequest
  )
  const { loading: isSending } = useSelector(selectDraftOrderCompleteRequest)
  const integration = useSelector(state => {
    return selectIntegrationCredentialsForId(state, {
      id: integrationId,
    })
  })

  const draftOrder =
    useSelector(state =>
      selectDraftOrderForCustomerId(state, {
        customerId,
      })
    ) || emptyObj

  const draftOrderComplete = useCallback(
    (...args) => {
      return dispatch(doDraftOrderComplete(...args))
    },
    [dispatch]
  )

  const draftOrderDelete = useCallback(
    (...args) => {
      return dispatch(doDraftOrderDelete(...args))
    },
    [dispatch]
  )

  const draftOrderCreate = useCallback(
    (...args) => {
      return dispatch(doDraftOrderCreate(...args))
    },
    [dispatch]
  )

  const draftOrderCreateFromOrder = useCallback(
    (...args) => {
      return dispatch(doDraftOrderCreateFromOrder(...args))
    },
    [dispatch]
  )

  const discardDraftOrder = useCallback(
    () => {
      return draftOrderDelete(integration.id, draftOrder.id)
    },
    [integration.id, draftOrder.id, draftOrderDelete]
  )

  const draftOrderUpdate = useCallback(
    async (...args) => {
      return dispatch(doDraftOrderUpdate(...args))
    },
    [dispatch]
  )

  const delayedDraftOrderUpdate = useMemo(
    () => {
      return debounceAsync(draftOrderUpdate, 500)
    },

    [draftOrderUpdate]
  )

  const draftOrderCalculate = useCallback(
    async (...args) => {
      return dispatch(doDraftOrderCalculate(...args))
    },
    [dispatch]
  )

  const delayedDraftOrderCalculate = useMemo(
    () => {
      return debounceAsync(draftOrderCalculate, 50)
    },

    [draftOrderCalculate]
  )

  return {
    isCalculating,
    isSending,
    draftOrderComplete,
    integration,
    draftOrder,
    draftOrderDelete,
    discardDraftOrder,
    draftOrderCreate,
    draftOrderCreateFromOrder,
    draftOrderUpdate,
    delayedDraftOrderUpdate,
    delayedDraftOrderCalculate,
  }
}

export const useShopifyTimeline = ({ customerId, integrationId }) => {
  const dispatch = useDispatch()
  const timelineEventsByDay = useSelector(state =>
    selectEventsForCustomerIdGroupedByDay(state, customerId)
  )

  const integration = useSelector(state =>
    selectIntegrationCredentialsForId(state, {
      id: integrationId,
    })
  )

  const externalLinkViewMoreEvents = useMemo(
    () => {
      return `${shopifyStoreAdminUrl(
        integration.storeDomain
      )}/customers/${shopifyGidToId(customerId)}`
    },
    [integration.storeDomain, customerId]
  )

  const fetchEvents = useCallback(
    (options = {}) => {
      return dispatch(
        doFetchEvents(integrationId, customerId, {
          skipLoaded: true,
          limit: EVENTS_LIMIT,
          ...options,
        })
      )
    },
    [dispatch, customerId, integrationId]
  )

  useEffect(
    () => {
      fetchEvents()
    },
    [fetchEvents]
  )

  return {
    integrationId,
    customerId,
    integration,
    timelineEventsByDay,
    externalLinkViewMoreEvents,
    fetchEvents,
  }
}

export const useShopifyOrders = ({ customerId, integrationId }) => {
  const dispatch = useDispatch()
  const { isMoreFetched, pageInfo: { hasNextPage } = {} } = useSelector(state =>
    selectOrdersMetaForCustomerId(state, customerId)
  )

  const [isFetchingMoreOrders, setFetchingMoreOrders] = useState(false)
  const orders = useSelector(state =>
    selectOrdersForCustomerId(state, customerId)
  )
  const hasOrders = orders && orders.length > 0
  const hasMoreOrders = hasNextPage || isMoreFetched
  const isMoreOrdersFetched = isMoreFetched

  const integration = useSelector(state =>
    selectIntegrationCredentialsForId(state, {
      id: integrationId,
    })
  )

  const externalLinkViewCustomer = useMemo(
    () => {
      return `${shopifyStoreAdminUrl(
        integration.storeDomain
      )}/customers/${shopifyGidToId(customerId)}`
    },
    [integration.storeDomain, customerId]
  )

  const externalLinkViewMoreOrders = `${shopifyStoreAdminUrl(
    integration.storeDomain
  )}/orders`

  const fetchOrders = useCallback(
    (options = {}) => {
      return dispatch(
        doFetchOrders(integrationId, customerId, {
          limit: RECENT_ORDERS_LIMIT,
          skipLoaded: true,
          ...options,
        })
      )
    },
    [dispatch, integrationId, customerId]
  )

  useEffect(
    () => {
      fetchOrders()
    },
    [fetchOrders]
  )

  const fetchMoreOrders = useCallback(
    async e => {
      e.preventDefault()
      setFetchingMoreOrders(true)
      const response = await fetchOrders({
        limit: MORE_ORDERS_LIMIT,
        isMoreFetched: true,
        skipLoaded: false,
      })
      setFetchingMoreOrders(false)
      return response
    },
    [fetchOrders]
  )

  const { drawerId, openDrawer } = useDrawer({
    type: DRAWER_TYPE_SHOPIFY_CREATE_ORDER,
  })

  const openCreateOrderDrawer = useCallback(
    () => {
      openDrawer(customerId, {
        query: {
          ...buildDrawerQueryParam(
            drawerId,
            'drawerIntegrationId',
            integrationId
          ),
        },
      })
    },
    [openDrawer, drawerId, integrationId, customerId]
  )

  return {
    orders,
    externalLinkViewMoreOrders,
    externalLinkViewCustomer,
    fetchOrders,
    fetchMoreOrders,
    isFetchingMoreOrders,
    isMoreOrdersFetched,
    hasOrders,
    hasMoreOrders,
    openCreateOrderDrawer,
  }
}

export const useShopifyOrder = ({ integrationId, orderId }) => {
  const order = useSelector(state => selectOrderForId(state, orderId))
  const integration = useSelector(state => {
    return selectIntegrationCredentialsForId(state, {
      id: integrationId,
    })
  })

  return {
    orderId,
    order,
    integration,
  }
}

export const useShopInfo = ({ integrationId }) => {
  const dispatch = useDispatch()

  const fecthShop = useCallback(
    (options = {}) => {
      return dispatch(
        doFetchShopInfo(integrationId, { skipLoaded: true, ...options })
      )
    },
    [dispatch, integrationId]
  )

  useEffect(
    () => {
      fecthShop()
    },
    [fecthShop]
  )

  return {}
}
