import { emptyArr } from 'util/arrays'
import {
  SEARCHING_STARTED,
  CREATING_STARTED,
  SEARCH_USER_REQUEST,
  SEARCH_USER_FAILURE,
  SEARCH_USER_SUCCESS,
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
  CHANGE_USER_REQUEST,
  CHANGE_USER_FAILURE,
  CHANGE_USER_SUCCESS,
  RESET_STATES,
} from './types'

const defaultState = {
  search: {
    isLoading: false,
    isError: false,
    term: '',
    matchingUsers: emptyArr,
  },
  create: {
    isLoading: false,
    isError: false,
  },
  change: {
    newUserId: null,
  },
  isSearching: true,
  isCreating: false,
  isLoading: false,
}

export default function changeCustomer(
  state = defaultState,
  { type, payload }
) {
  if (type === RESET_STATES) {
    return defaultState
  }

  if (type === SEARCHING_STARTED) {
    return {
      ...state,
      isSearching: true,
      isCreating: false,
      search: defaultState.search,
    }
  }

  if (type === CREATING_STARTED) {
    return {
      ...state,
      isSearching: false,
      isCreating: true,
      create: defaultState.create,
    }
  }

  if (type === CHANGE_USER_REQUEST) {
    const { user: newUser } = payload
    return {
      ...state,
      isError: false,
      change: {
        newUserId: newUser.id,
      },
      isLoading: true,
    }
  }

  if (type === CHANGE_USER_FAILURE) {
    return {
      ...state,
      change: defaultState.change,
      isLoading: false,
      isError: true,
    }
  }

  if (type === CHANGE_USER_SUCCESS) {
    return {
      ...state,
      change: defaultState.change,
      isLoading: false,
    }
  }

  if (type === SEARCH_USER_REQUEST) {
    const { term } = payload
    return {
      ...state,
      search: {
        ...state.search,
        term,
        isLoading: true,
        isError: false,
      },
    }
  }
  if (type === SEARCH_USER_SUCCESS) {
    const { users: matchingUsers } = payload
    return {
      ...state,
      search: {
        ...state.search,
        matchingUsers,
        isLoading: false,
      },
    }
  }
  if (type === SEARCH_USER_FAILURE) {
    return {
      ...state,
      search: {
        ...state.search,
        matchingUsers: [],
        isLoading: false,
        isError: true,
      },
    }
  }
  if (type === ADD_NEW_USER_REQUEST) {
    return {
      ...state,
      create: { ...state.create, isLoading: true, isError: false },
    }
  }
  if (type === ADD_NEW_USER_SUCCESS) {
    return {
      ...state,
      create: { ...state.create, isLoading: false, isError: false },
    }
  }
  if (type === ADD_NEW_USER_FAILURE) {
    return {
      ...state,
      create: { ...state.create, isLoading: false, isError: true },
    }
  }

  return state
}
