import React, { PureComponent } from 'react'
import cn from 'classnames'

import TabbedModal, { Tab } from 'components/TabbedModal'

import AllRepliesState from './AllRepliesState'
import styles from './styles.css'

class CannedRepliesModal extends PureComponent {
  componentDidMount() {
    const { fetchCannedReplyCategories } = this.props
    fetchCannedReplyCategories()
  }

  render() {
    const { containerClass, isSearching } = this.props

    return (
      <TabbedModal className={cn(containerClass, styles.root)}>
        <Tab
          name={`insert ${app.t('canned_reply_with_article')}`}
          active={isSearching}
        >
          <AllRepliesState {...this.props} />
        </Tab>
      </TabbedModal>
    )
  }
}

export default CannedRepliesModal
