import { createSelector } from 'reselect'
import { selectFormElementValue } from 'selectors/userIntegrations'
import { selectIntegrationTrelloData } from './selectIntegrationTrelloData'

export const selectSelectedTrelloCardData = createSelector(
  selectFormElementValue,
  selectIntegrationTrelloData,
  (getValue, trelloData) => {
    return trelloData.cards[getValue('card')]
  }
)

export {
  default as selectCurrentTicketLinkedCardsById,
} from './selectCurrentTicketLinkedCardsById'
export { default as selectTrelloCards } from './selectTrelloCards'
export {
  default as selectTrelloCardsAsOptionsForList,
} from './selectTrelloCardsAsOptionsForList'
export { default as selectTrelloCardsById } from './selectTrelloCardsById'
export { default as selectTrelloHiddenFields } from './selectTrelloHiddenFields'
export {
  default as selectTrelloBoardIsLoading,
} from './selectTrelloBoardIsLoading'
export { default as selectTrelloBoards } from './selectTrelloBoards'
export { default as selectTrelloBoardsById } from './selectTrelloBoardsById'
export {
  default as selectTrelloBoardsAsOptions,
} from './selectTrelloBoardsAsOptions'
export {
  default as selectTrelloLabelsAsOptionsForBoard,
} from './selectTrelloLabelsAsOptionsForBoard'
export { default as selectTrelloListsById } from './selectTrelloListsById'
export { default as selectTrelloLists } from './selectTrelloLists'
export {
  default as selectTrelloListsAsOptionsForBoard,
} from './selectTrelloListsAsOptionsForBoard'
export {
  default as selectTrelloListIsLoading,
} from './selectTrelloListIsLoading'
export {
  default as selectTrelloMembersAsOptionsForBoard,
} from './selectTrelloMembersAsOptionsForBoard'
export { default as selectTrelloMembersById } from './selectTrelloMembersById'
export { default as selectTrelloLabelsById } from './selectTrelloLabelsById'
export {
  default as selectCurrentTicketLinkedCards,
} from './selectCurrentTicketLinkedCards'
export {
  default as selectTrelloVisibleFields,
} from './selectTrelloVisibleFields'
