import { createSelector } from 'reselect'

import { selectPage } from './page'

export const selectPageAfterCursor = createSelector(selectPage, page => {
  return page && page.pageInfo && page.pageInfo.endCursor
})

export const selectPageBeforeCursor = createSelector(selectPage, page => {
  return page && page.pageInfo && page.pageInfo.startCursor
})

export const selectPageCanGoBack = createSelector(selectPage, page => {
  return page && page.pageInfo && page.pageInfo.hasPreviousPage
})

export const selectPageCanGoForward = createSelector(selectPage, page => {
  return page && page.pageInfo && page.pageInfo.hasNextPage
})
