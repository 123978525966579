import { createSelector } from 'reselect'
import { selectCurrentUserPrefersOpenFolderOnTicketSave } from './selectCurrentUserPrefersOpenFolderOnTicketSave'
import { selectPrefersClassicView } from './selectPrefersClassicView'

export const selectPrefersOpenFolderOnTicketSave = createSelector(
  selectPrefersClassicView,
  selectCurrentUserPrefersOpenFolderOnTicketSave,
  (classicView, openFolder) => {
    return classicView && openFolder
  }
)
