import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isObject } from 'util/objects'
import { selectQueryParams } from 'selectors/location'
import { selectPreviousDrawer } from 'ducks/drawers/selectors'
import useComponentId from 'util/hooks/useComponentId'
import { doOpenDrawer } from '../actions'
import {
  extractDrawerPropsById,
  stripDrawerIdsFromProps,
  isResourceId,
} from '../util'
import { useDrawerCommons } from './useDrawerCommons'
import { drawerConfig } from './drawerConfig'

export function useDrawer(config) {
  const { id, type, closeIgnoresStack = false } = isObject(config)
    ? config
    : { type: config }
  const generatedDrawerId = useComponentId({ bucket: type })
  const drawerId = id || generatedDrawerId
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)
  const previousDrawer = useSelector(selectPreviousDrawer)
  const previousDrawerId = previousDrawer && previousDrawer.drawerId

  const { closeDrawer, updateDrawerParameter } = useDrawerCommons({
    closeIgnoresStack,
    drawerId,
    previousDrawerId,
  })

  const rawDrawerProps = extractDrawerPropsById(drawerId, queryParams)

  const {
    drawer,
    drawerResourceId,
    ...restSanitizedDrawerProps
  } = stripDrawerIdsFromProps(rawDrawerProps)

  const openDrawer = useCallback(
    (inputResourceId, options) => {
      const resourceId = isResourceId(inputResourceId) ? inputResourceId : null
      drawerConfig[drawerId] = {
        closeIgnoresStack,
        additionalProps: (options && options.additionalProps) || {},
      }

      dispatch(doOpenDrawer(drawerId, type, resourceId, options))
    },
    [dispatch, type, drawerId, closeIgnoresStack]
  )

  return {
    openDrawer,
    closeDrawer,
    updateDrawerParameter,
    drawerResourceId,
    drawerId,
    previousDrawer,
    ...restSanitizedDrawerProps,
  }
}
