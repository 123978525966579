import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import {
  selectCurrentCompany,
  selectCurrentCompanyId,
} from 'ducks/crm/companies/selectors/current'
import {
  selectCurrentContact,
  selectCurrentContactId,
} from 'ducks/crm/contacts/selectors/current'

export const selectSubjectIdForCategoryType = createSelector(
  selectCurrentCompanyId,
  selectCurrentContactId,
  (state, { type }) => type,
  (companyId, contactId, type) => {
    return type === 'CONTACT' ? contactId : companyId
  }
)

export const selectSubjectForCategoryType = createCachedSelector(
  selectCurrentCompany,
  selectCurrentContact,
  (state, { categoryType }) => categoryType,
  (company, contact, type) => {
    return type === 'CONTACT' ? contact : company
  }
)((state, { categoryType }) => categoryType)
