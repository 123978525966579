import createCachedSelector from 're-reselect'
import {
  selectPendingEntityById,
  selectPendingEntitiesByIds,
  selectPendingEntitiesById,
  selectEntityById,
} from 'ducks/entities/selectors'
import { selectRequestByType } from 'ducks/requests/selectors'
import { emptyArr } from 'util/arrays'
import { FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS } from 'ducks/integrations/types'

export const selectIsLoadingIntegrationsByProvider = state =>
  selectRequestByType(state, FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS)
    .loading

export const selectHasLoadedIntegrationsByProvider = state =>
  selectRequestByType(state, FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS).loaded

export const selectHasErrorIntegrationsByProvider = state =>
  selectRequestByType(state, FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS).error

export const selectPendingIntegrationById = (state, id) =>
  selectPendingEntityById(state, 'integration', id) || null

export const selectIntegrationById = (state, id, store) =>
  selectEntityById(state, 'integration', id, store)

export const selectPendingIntegrationInstallStateValueProviderIdsByProviderId = createCachedSelector(
  selectPendingIntegrationById,
  pendingIntegrationById => {
    return pendingIntegrationById?.installStateValue?.provider || emptyArr
  }
)((_state, id) => id || 'unknown')

const selectPendingIntegrationInstallStateProviderValuesById = state =>
  selectPendingEntitiesById(state, 'integrationInstallStateProviderValue')

export const selectPendingInstalledStoreDomainsByProviderId = createCachedSelector(
  selectPendingIntegrationInstallStateValueProviderIdsByProviderId,
  selectPendingIntegrationInstallStateProviderValuesById,
  (installedProviderIds, installStateProviderEntitiesById) => {
    const storeDomains = []

    installedProviderIds.forEach(id => {
      const entity = installStateProviderEntitiesById[id]
      if (entity && entity?.storeDomain) {
        storeDomains.push(entity.storeDomain)
      }
    })

    return storeDomains
  }
)((_state, id) => id || 'unknown')

export const selectPendingInstalledFullStoreDomainsByProviderId = createCachedSelector(
  selectPendingIntegrationInstallStateValueProviderIdsByProviderId,
  selectPendingIntegrationInstallStateProviderValuesById,
  (installedProviderIds, installStateProviderEntitiesById) => {
    const fullStoreDomains = []

    installedProviderIds.forEach(id => {
      const entity = installStateProviderEntitiesById[id]
      if (entity && entity?.fullStoreDomain) {
        fullStoreDomains.push(entity.fullStoreDomain)
      }
    })

    return fullStoreDomains
  }
)((_state, id) => id || 'unknown')

export const selectPendingIntegrationInstallStateProviderValueById = (
  state,
  id
) =>
  selectPendingEntityById(state, 'integrationInstallStateProviderValue', id) ||
  null

export const selectPendingIntegrationInstallStateProviderValuesByIds = (
  state,
  ids
) =>
  selectPendingEntitiesByIds(state, 'integrationInstallStateProviderValue', ids)

export const selectPendingIntegrationInstallStateProviderValuesByProviderId = createCachedSelector(
  selectPendingIntegrationInstallStateValueProviderIdsByProviderId,
  state => state,
  (installedIntegrationIds, state) =>
    selectPendingIntegrationInstallStateProviderValuesByIds(
      state,
      installedIntegrationIds
    )
)((_state, providerId) => providerId || 'unknown')
