import { createSelector } from 'reselect'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors/base'
import { selectIsBillingAccountActive } from './selectIsBillingAccountActive'

export const selectShowExpiredBilling = createSelector(
  selectIsBillingAccountActive,
  selectCurrentUserIsAdminOrOwner,
  (isBillingAccountActive, isManager) => {
    return !isBillingAccountActive && isManager
  }
)
