import { createSelector } from 'reselect'

import { isBoostrappedSelector } from 'selectors/app'
import selectIntegrationCredentialsAreLoaded from './selectIntegrationCredentialsAreLoaded'

export default createSelector(
  isBoostrappedSelector,
  selectIntegrationCredentialsAreLoaded,
  (bootstrapped, credentialsAreLoaded) => {
    return bootstrapped && credentialsAreLoaded
  }
)
