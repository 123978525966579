import { timeInHuman } from 'util/date'
import { getValueByPath } from 'util/objects'

import {
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILURE,
  FETCH_CONTACT_PROPERTIES_SUCCESS,
  FETCH_CONTACT_PROPERTIES_FAILURE,
  FETCH_CONTACT_LISTS_REQUEST,
  FETCH_CONTACT_LISTS_SUCCESS,
  FETCH_CONTACT_LISTS_FAILURE,
  FETCH_CONTACT_DEALS_REQUEST,
  FETCH_CONTACT_DEALS_SUCCESS,
  FETCH_CONTACT_DEALS_FAILURE,
  FETCH_CONTACT_COMPANY_REQUEST,
  FETCH_CONTACT_COMPANY_SUCCESS,
  FETCH_CONTACT_COMPANY_FAILURE,
  FETCH_DEAL_PROPERTIES_SUCCESS,
  FETCH_DEAL_PROPERTIES_FAILURE,
  FETCH_COMPANY_PROPERTIES_SUCCESS,
  FETCH_COMPANY_PROPERTIES_FAILURE,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  FETCH_OWNER_SUCCESS,
} from '../types'

const initialState = {
  fetched: [],
  byEmail: {},
}

const reducers = {}

const renderValue = (field, type) => {
  if (type === 'date') {
    const value = field.value * 1
    if (value === 0) return null
    return timeInHuman(value, { showToday: true })
  }
  return field.value
}

const extractProperties = (data, propertiesByName) => {
  const set = data.properties
  const properties = {}
  const keys = Object.keys(set)
  keys.forEach(key => {
    if (key.match(/^hs_/)) return
    const property = propertiesByName && propertiesByName[key]
    const label = property ? property.label : key
    const type = property ? property.fieldType : 'unknown'
    const value = renderValue(set[key], type)
    if (value) properties[key] = { value, type, key, label }
  })
  return properties
}

const extractContact = (state, action) => {
  const data = action.data
  const listIds = data['list-memberships'].map(list => list['static-list-id'])
  const associatedCompany = data['associated-company']
  const companyId = associatedCompany ? associatedCompany['company-id'] : null
  const propertiesByName = state.propertiesByName
  return {
    state: 'fetched',
    data,
    listIds,
    companyId,
    properties: extractProperties(action.data, propertiesByName),
  }
}

reducers[FETCH_CONTACT_REQUEST] = (state, action) => {
  const { email } = action.data
  const byEmail = { ...state.byEmail }
  byEmail[email] = {
    state: 'fetching',
  }
  return {
    ...state,
    byEmail,
  }
}

reducers[FETCH_CONTACT_SUCCESS] = (state, action) => {
  const contact = extractContact(state, action)
  const byEmail = { ...state.byEmail }
  const { email } = action.data
  const fetched = [...state.fetched]
  fetched.push(email)
  byEmail[email] = contact
  return {
    ...state,
    byEmail,
    fetched,
  }
}

reducers[FETCH_CONTACT_FAILURE] = (state, action) => {
  const { email, error } = action.data
  const byEmail = { ...state.byEmail }
  byEmail[email] = {
    state: 'failed',
    error,
  }
  return {
    ...state,
    byEmail,
  }
}

reducers[FETCH_CONTACT_PROPERTIES_SUCCESS] = (state, action) => {
  const { properties } = action.data
  const propertiesByName = {}
  properties.forEach(property => {
    propertiesByName[property.name] = property
  })
  return {
    ...state,
    propertiesByName,
  }
}

reducers[FETCH_CONTACT_PROPERTIES_FAILURE] = (state, action) => {
  const { error } = action.data
  return {
    ...state,
    propertiesError: error,
  }
}

reducers[FETCH_CONTACT_LISTS_REQUEST] = (state, action) => {
  const { email } = action.data
  const listsByEmail = { ...state.listsByEmail }
  listsByEmail[email] = {
    state: 'fetching',
  }
  return {
    ...state,
    listsByEmail,
  }
}

reducers[FETCH_CONTACT_LISTS_SUCCESS] = (state, action) => {
  const { email } = action.data
  const data = action.data
  const listsByEmail = { ...state.listsByEmail }

  listsByEmail[email] = {
    state: 'fetched',
    ...data,
  }

  return {
    ...state,
    listsByEmail,
  }
}

reducers[FETCH_CONTACT_LISTS_FAILURE] = (state, action) => {
  const { email, error } = action.data
  const listsByEmail = { ...state.listsByEmail }
  listsByEmail[email] = {
    state: 'failed',
    error,
  }
  return {
    ...state,
    listsByEmail,
  }
}

reducers[FETCH_CONTACT_DEALS_REQUEST] = (state, action) => {
  const { email } = action.data
  const dealsByEmail = { ...state.dealsByEmail }
  dealsByEmail[email] = {
    state: 'fetching',
  }
  return {
    ...state,
    dealsByEmail,
  }
}

reducers[FETCH_CONTACT_DEALS_SUCCESS] = (state, action) => {
  const { email } = action.data
  const data = action.data
  const dealsByEmail = { ...state.dealsByEmail }
  const dealPropertiesByName = state.dealPropertiesByName
  const dealStages = state.dealStages

  dealsByEmail[email] = {
    state: 'fetched',
    code: data.code,
    email: data.email,
    deals: data.deals.map(deal => {
      const properties = extractProperties(deal, dealPropertiesByName)
      const pipelineId = getValueByPath('pipeline.value', properties)
      const stageId = getValueByPath('dealstage.value', properties)
      const pipeline = dealStages.find(pl => pl.pipelineId === pipelineId)
      if (pipeline) {
        const stage = pipeline.stages.find(st => st.stageId === stageId)
        properties.dealstage.value = stage.label
      }
      return {
        data: deal,
        id: deal.dealId,
        properties,
      }
    }),
  }

  return {
    ...state,
    dealsByEmail,
  }
}

reducers[FETCH_CONTACT_DEALS_FAILURE] = (state, action) => {
  const { email, error } = action.data
  const dealsByEmail = { ...state.dealsByEmail }
  dealsByEmail[email] = {
    state: 'failed',
    error,
  }
  return {
    ...state,
    dealsByEmail,
  }
}

reducers[FETCH_CONTACT_COMPANY_REQUEST] = (state, action) => {
  const { email } = action.data
  const companiesByEmail = { ...state.companiesByEmail }
  companiesByEmail[email] = {
    state: 'fetching',
  }
  return {
    ...state,
    companiesByEmail,
  }
}

reducers[FETCH_CONTACT_COMPANY_SUCCESS] = (state, action) => {
  const { email } = action.data
  const data = action.data
  const companiesByEmail = { ...state.companiesByEmail }
  const companyPropertiesByName = state.companyPropertiesByName

  companiesByEmail[email] = {
    state: 'fetched',
    properties: extractProperties(data.company, companyPropertiesByName),
    data,
  }

  return {
    ...state,
    companiesByEmail,
  }
}

reducers[FETCH_CONTACT_COMPANY_FAILURE] = (state, action) => {
  const { email, error } = action.data
  const companiesByEmail = { ...state.companiesByEmail }
  companiesByEmail[email] = {
    state: 'failed',
    error,
  }
  return {
    ...state,
    companiesByEmail,
  }
}

reducers[FETCH_DEAL_PROPERTIES_SUCCESS] = (state, action) => {
  const { properties, stages } = action.data
  const propertiesByName = {}
  properties.forEach(property => {
    propertiesByName[property.name] = property
  })
  return {
    ...state,
    dealPropertiesByName: propertiesByName,
    dealStages: stages,
  }
}

reducers[FETCH_DEAL_PROPERTIES_FAILURE] = (state, action) => {
  const { error } = action.data
  return {
    ...state,
    dealPropertiesError: error,
  }
}

reducers[FETCH_COMPANY_PROPERTIES_SUCCESS] = (state, action) => {
  const { properties } = action.data
  const propertiesByName = {}
  properties.forEach(property => {
    propertiesByName[property.name] = property
  })
  return {
    ...state,
    companyPropertiesByName: propertiesByName,
  }
}

reducers[FETCH_COMPANY_PROPERTIES_FAILURE] = (state, action) => {
  const { error } = action.data
  return {
    ...state,
    companyPropertiesError: error,
  }
}

reducers[CREATE_CONTACT_SUCCESS] = (state, action) => {
  const contact = extractContact(state, action)
  const byEmail = { ...state.byEmail }
  const { email } = action.data
  const fetched = [...state.fetched]
  fetched.push(email)
  byEmail[email] = { ...contact, createdContact: true }
  return {
    ...state,
    byEmail,
    fetched,
  }
}

reducers[CREATE_CONTACT_FAILURE] = (state, action) => {
  const { error } = action.data
  return {
    ...state,
    createContactError: error.response,
  }
}

reducers[FETCH_OWNER_SUCCESS] = (state, action) => {
  const { ownerId, owner } = action.data
  const ownersById = { ...state.ownersById }
  owner.label = [owner.firstName, owner.lastName].join(' ')
  ownersById[ownerId] = owner
  return {
    ...state,
    ownersById,
  }
}

export default function reducer(state = initialState, action) {
  const found = reducers[action.type]
  if (found) return found(state, action)
  return state
}
