import { toObject } from 'util/arrays'

import {
  FETCH_BOARD_DETAILS_SUCCESS,
  FETCH_CARD_SUCCESS,
  FETCH_LIST_CARDS_FAILED,
  FETCH_LIST_CARDS_REQUEST,
  FETCH_LIST_CARDS_SUCCESS,
  FETCH_LIST_SUCCESS,
} from '../types'

const initialState = undefined

export default function reduceLists(
  state = initialState || {},
  { meta: { listId } = {}, payload: { lists = [] } = {}, type }
) {
  if (type === FETCH_LIST_CARDS_REQUEST) {
    return {
      ...state,
      [listId]: { ...state[listId], loading: true },
    }
  }
  if (type === FETCH_LIST_CARDS_SUCCESS || type === FETCH_LIST_CARDS_FAILED) {
    return {
      ...state,
      [listId]: { ...state[listId], loading: false },
    }
  }
  if (
    type === FETCH_BOARD_DETAILS_SUCCESS ||
    type === FETCH_CARD_SUCCESS ||
    type === FETCH_LIST_SUCCESS
  ) {
    return {
      ...state,
      ...toObject(lists),
    }
  }
  return state
}
