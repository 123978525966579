import uuid from 'util/uuid'
import { snoozeLabel, SNOOZED_INDEFINITELY_TIMESTAMP } from 'util/snooze'
import { getMatrixClient, getLanguageHandler } from './client'
import { isAgentUser } from './users'

const eventTypeTextHandlers = {
  'g.state': textForStateEvent,
  'g.assign': textForAssignEvent,
}

const actorNames = {
  schedule_check_unattended_room: 'unattended-message automation',
  auto_assign_agent: 'round-robin',
}

function getSenderUsername(event, agentsByChatId) {
  const senderUserId = event.getSender()
  const { actor } = event.getContent()
  return actorNames[actor] || agentsByChatId[senderUserId]?.username || ''
}

function textForStateEvent(event, { agentsByChatId = {} }) {
  const username = getSenderUsername(event, agentsByChatId)
  const { id, snoozed_until: snoozedUntil } = event.getContent()
  const { id: prevId } = event.getPrevContent()
  let translationKey = '%(senderName)s marked as <span>%(state)s</span>'
  const { _t } = getLanguageHandler()
  const translationOptions = {
    state: _t(`${id}`),
    senderName: username,
  }
  if (id === 4000) {
    if (snoozedUntil !== SNOOZED_INDEFINITELY_TIMESTAMP) {
      translationKey += ' until'
    }
    translationKey += ' %(snoozedUntil)s'
    translationOptions.snoozedUntil = snoozeLabel(snoozedUntil)
  } else if (id === 2000 && prevId === 4000) {
    return _t('%(senderName)s <span>Unsnoozed</span> this conversation', {
      senderName: username,
    })
  } else if (id === 2000 && prevId === 6000) {
    return _t('%(senderName)s <span>Unspammed</span> this conversation', {
      senderName: username,
    })
  }
  return _t(translationKey, translationOptions)
}

function textForAssignEvent(event, { agentsByChatId = {}, teamsById = {} }) {
  const senderUserId = event.getSender()
  const username = getSenderUsername(event, agentsByChatId)
  const { agentId } = agentsByChatId[senderUserId] || {}
  const { _t } = getLanguageHandler()
  const {
    agent_id: eventAgentId,
    team_id: eventTeamId,
    type: eventType,
  } = event.getContent()
  const isSelfAssign = agentId === eventAgentId

  if (eventType === 'none') {
    return _t('%(senderUsername)s unassigned', {
      senderUsername: username,
    })
  } else if (eventType === 'agent' && isSelfAssign) {
    return _t('%(senderUsername)s took ownership', {
      senderUsername: username,
    })
  } else if (eventType === 'agent' && !isSelfAssign) {
    const { username: assigneeUsername = '' } =
      agentsByChatId[eventAgentId] || {}
    return _t('%(senderUsername)s assigned to %(assigneeUsername)s', {
      senderUsername: username,
      assigneeUsername,
    })
  } else if (eventType === 'team') {
    const { name: teamName = '' } = teamsById[eventTeamId] || {}
    return _t('%(senderUsername)s assigned to %(assigneeUsername)s', {
      senderUsername: username,
      assigneeUsername: teamName.toLowerCase(),
    })
  }
  return ''
}

export function textForEvent(mxEvent, data) {
  const type = mxEvent.getType()
  const handler = eventTypeTextHandlers[type]
  if (handler) {
    return handler(mxEvent, data)
  }
  return `Unknown event type ${type}`
}

function removeNote(body) {
  if (!body) return body
  // Removes the ** and any spaces between it and the content
  return (
    body
      // Used when replacing plain text mesages
      .replace(/^\*\*(\s*)?/g, '')
      // Used when replacing body of reply to message
      .replace(/(>\s<.*?)\*\*(\s*)?/g, '$1')
      // Used when replacing formatted_body of reply to message
      .replace(/(<\/mx-reply>)\*\*(\s*)/g, '$1')
  )
}

export async function convertToShadow({ roomId, content, accountChatRoomId }) {
  const client = getMatrixClient()
  // Events cant be updated, and we have to events that essentially have
  // a 1 to 1 relationship, but they will be created in seperate rooms
  // in order to create a link, we generate uuid which is passed into the
  // content of both events. This will allow us to link the 2 events.
  // This is only ever usefull if we need to find the event from the
  // shadow events side which should never be the case. We'll always
  // load the events from the origin room side.
  const relationShipId = uuid()
  const { event_id: eventId } = await client.sendMessage(accountChatRoomId, {
    msgtype: content.msgtype,
    body: '',
    'm.new_content': {
      ...content,
      body: removeNote(content.body),
      formatted_body: removeNote(content.formatted_body),
      source_room_id: roomId,
    },
    'm.relates_to': {
      rel_type: 'm.replace',
      room_id: roomId,
      relationship_id: relationShipId,
    },
  })

  return {
    msgtype: 'g.shadow',
    body: '',
    event_id: eventId,
    relationship_id: relationShipId,
    account_room_id: accountChatRoomId,
  }
}

export function isAgentNoteEvent(mxEvent) {
  return !!(
    mxEvent &&
    mxEvent.getType() === 'm.room.message' &&
    mxEvent.getOriginalContent()?.msgtype === 'g.shadow' &&
    isAgentUser(mxEvent.getSender())
  )
}
