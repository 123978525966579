import { toObject } from 'util/arrays'

import {
  FETCH_BOARD_DETAILS_FAILED,
  FETCH_BOARD_DETAILS_REQUEST,
  FETCH_BOARD_DETAILS_SUCCESS,
  FETCH_BOARDS_SUCCESS,
  FETCH_CARD_SUCCESS,
} from '../types'

const initialState = undefined

export default function reduceBoards(
  state = initialState || {},
  { meta: { boardId } = {}, payload: { board, boards = [] } = {}, type }
) {
  if (type === FETCH_BOARD_DETAILS_REQUEST) {
    return {
      ...state,
      [boardId]: { ...state[boardId], loading: true },
    }
  }
  if (
    type === FETCH_BOARD_DETAILS_SUCCESS ||
    type === FETCH_BOARD_DETAILS_FAILED
  ) {
    return {
      ...state,
      [boardId]: { ...state[boardId], ...board, loading: false },
    }
  }

  if (type === FETCH_BOARDS_SUCCESS || type === FETCH_CARD_SUCCESS)
    return { ...(state || {}), ...toObject(boards) }
  return state
}
