import { createSelector } from 'reselect'

import selectGitHubInstallsByOwnerId from './selectGitHubInstallsByOwnerId'
import selectGitHubNodes from './selectGitHubNodes'
import selectGitHubNodesById from './selectGitHubNodesById'

export default createSelector(
  selectGitHubNodes,
  selectGitHubNodesById,
  selectGitHubInstallsByOwnerId,
  (nodes, nodesById, installs) => {
    const viewer = nodes.find(({ __typename, isViewer }) => {
      return __typename === 'User' && isViewer
    })
    const organizations = nodes.filter(({ __typename, viewerIsAMember }) => {
      return __typename === 'Organization' && viewerIsAMember
    })
    return [viewer, ...organizations].filter(owner => !!owner).map(owner => {
      const install = installs[owner.id]
      return {
        ...owner,
        install: install && {
          ...install,
          repositories: install.repositories.map(
            repositoryId => nodesById[repositoryId]
          ),
        },
      }
    })
  }
)
