import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectDraftOrderHasLineItemsForId } from 'ducks/integrations/shopify/selectors'
import { wait } from 'util/promises'
import graphql from '../api'
import * as types from '../types'
import { draftOrderPartial } from './partials'
import doDraftOrderClearLineItemsLocally from './doDraftOrderClearLineItemsLocally'
import doFetchDraftOrderById from './doFetchDraftOrderById'

const draftOrderUpdateMutation = `
  mutation ShopifyDraftOrderUpdate(
    $id: ID!,
    $input: DraftOrderInput!
  ) {
    draftOrderUpdate(id: $id, input: $input) {
      userErrors {
        message
        field
      }

      draftOrder {
        ${draftOrderPartial}
      }
    }
  }
`

const doReloadDraftById = (integrationId, draftOrderId) => async dispatch => {
  // There seems to be some async shit appening on shopify side with shipping
  // if we dont wait here before reloading shippingLine still returns null after
  // being set
  await wait(1000)
  const response = await dispatch(
    doFetchDraftOrderById(integrationId, draftOrderId)
  )

  return {
    draftOrderUpdate: {
      ...response,
    },
  }
}

const doDraftOrderUpdate = (
  integrationId,
  draftOrderId,
  input = {},
  options = {}
) => async (dispatch, getState) => {
  const { withReload = false } = options
  const state = getState()
  const currentDraftHasLineItems = selectDraftOrderHasLineItemsForId(
    state,
    draftOrderId
  )

  const isUpdatingLineItemsToNone =
    !!input.lineItems && input.lineItems?.length === 0

  // (we've removed the last product line item) || (we're updating non-product line item field(s) BUT no existing product line item)
  if (
    (currentDraftHasLineItems && isUpdatingLineItemsToNone) ||
    (!currentDraftHasLineItems && !input?.lineItems)
  ) {
    // you cannot update a draft with 0 product line items https://shopify.dev/api/admin-graphql/2021-10/mutations/draftOrderUpdate#field-draftorderinput-lineitems
    // would delete the draft entirely but we wanna keep discount, shipping, tags, notes for better work continuity

    if (isUpdatingLineItemsToNone) {
      // we're clearing out all line items, clear out the totals too
      return Promise.resolve(
        dispatch(doDraftOrderClearLineItemsLocally(draftOrderId))
      )
    }

    return Promise.resolve()
  }

  const variables = {
    id: draftOrderId,
    input: { ...input },
  }

  return dispatch(
    doGraphqlRequest(
      types.DRAFT_ORDER_UPDATE_REQUEST,
      draftOrderUpdateMutation,
      variables,
      {
        graphqlFunc: graphql(integrationId),
        onBeforeSuccessAction: async updateData => {
          if (withReload) {
            const loadData = await dispatch(
              doReloadDraftById(integrationId, draftOrderId)
            )
            Object.assign(updateData, loadData)
          }
        },
        ...options,
      }
    )
  )
}

export default doDraftOrderUpdate
