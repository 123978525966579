import {
  agentNotificationPreferences,
  defaultAgentNotificationPreferences,
} from 'ducks/entities/schema'

export const defaultNotificationPreferencesNormalizationSchema = {
  me: {
    defaultNotificationPreferences: [defaultAgentNotificationPreferences],
  },
}

export const agentNotificationPreferencesNormalizationSchema = {
  me: {
    notificationPreferences: {
      nodes: [agentNotificationPreferences],
    },
  },
}

export const agentNotificationPreferencesUpsertResponseSchema = {
  agentNotificationPreferenceBulkUpsert: {
    notificationPreferences: [agentNotificationPreferences],
  },
}
