import { uniqByProp } from 'util/arrays'
import { batchActions } from 'util/redux'

import doFetchTrelloBoardDetails from './doFetchTrelloBoardDetails'
import doFetchTrelloCard from './doFetchTrelloCard'
import doFetchTrelloList from './doFetchTrelloList'

const UPDATE_CARD_ACTIONS = [
  'updateCard',
  'addLabelToCard',
  'addMemberToCard',
  'moveCardFromBoard',
  'removeMemberFromCard',
  'removeLabelFromCard',
]

export default function doFetchTrelloResourceFromActions(actions) {
  const mappedActions = actions
    .map(
      ({
        change: {
          action: {
            data: {
              board: { id: boardId } = {},
              card: { id: cardId } = {},
              list: { id: listId } = {},
            } = {},
            type,
          } = {},
        } = {},
      }) => {
        let id = false
        if (type === 'updateBoard') id = boardId
        if (UPDATE_CARD_ACTIONS.includes(type)) id = cardId
        if (type === 'updateList') id = listId
        if (id) {
          return {
            type,
            id,
          }
        }
        return null
      }
    )
    .filter(x => !!x)
  const uniqActions = uniqByProp(mappedActions, 'id')

  return batchActions(
    uniqActions.map(({ id, type }) => {
      if (type === 'updateBoard') return doFetchTrelloBoardDetails(id)
      if (UPDATE_CARD_ACTIONS.includes(type)) return doFetchTrelloCard(id)
      if (type === 'updateList') return doFetchTrelloList(id)
      return null
    })
  )
}
