import { oauthTokenSelector } from 'selectors/app'
import config from 'config'
import { uniq } from 'util/arrays'
import { selectContactByEmail } from '../selectors'

import * as api from '../api'
import {
  FETCH_OWNER_REQUEST,
  FETCH_OWNER_SUCCESS,
  FETCH_OWNER_FAILURE,
} from '../types'

export default function doFetchOwners(email) {
  return (dispatch, getState) => {
    const state = getState()
    const ownerIds = []

    const customer = selectContactByEmail(state, email)
    if (!customer) return null
    const owner = customer.properties.hubspot_owner_id
    const ownerId = owner && owner.value
    if (ownerId) ownerIds.push(ownerId)
    uniq(ownerIds).forEach(id => dispatch(doFetchOwner(id)))
    return null
  }
}

function doFetchOwner(ownerId) {
  return (dispatch, getState) => {
    const state = getState()
    const meta = {
      requestKey: `integrations/hubspot/fetchOwner:${ownerId}`,
    }
    dispatch({
      type: FETCH_OWNER_REQUEST,
      data: {
        ownerId,
      },
      meta,
    })
    const token = oauthTokenSelector(state)
    return api
      .getOwner(token, ownerId)
      .then(payload => {
        const { response, code } = payload
        dispatch({
          type: FETCH_OWNER_SUCCESS,
          data: {
            ownerId,
            code,
            owner: response,
          },
          meta,
        })
        return payload
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        if (config.isDevelopment) console.error(error)
        return dispatch({
          type: FETCH_OWNER_FAILURE,
          data: {
            ownerId,
            error,
          },
          meta,
        })
      })
  }
}
