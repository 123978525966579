import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { asyncRpcQuery } from 'ducks/ai/queries'
import { isFeatureEnabled, record, capture } from 'ducks/tracking/actions'
import { DRAWER_TYPE_AI_DRAFT_LOCKOUT } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  selectAiDraftAccountWallet,
  selectAiDraftWalletsStatus,
} from 'ducks/wallets/selectors'
import { selectPrefersAiEnabled } from 'selectors/app/selectAccountPreferences'
import { walletsFeaturesMap } from 'ducks/wallets/contants'
import {
  doChargeCreditFromAiDraftWallet,
  doFetchWalletsForFeature,
} from 'ducks/wallets/operations'
import {
  FEATURE_AI_DRAFT_CREDITS,
  FEATURE_AI_MAX_DRAFT_GENERATIONS,
} from 'ducks/billing/featureTypes'
import { useFeature } from 'ducks/billing/hooks'
import { selectIsPaidAccount } from 'ducks/billing/selectors'
import {
  doAiRequest,
  doCancelAiResult,
  doHidePrompt,
  doClearState,
  getAiType,
  doFetchJobResult,
  doAiFailed,
  doAiEnd,
  doInitializeRequest,
  doFetchSourcesRequest,
} from './operations'
import { findJobId } from './utils'
import { selectAiComposerRequest } from './selectors/selectAiComposerRequest'
import {
  selectAiConversationRequest,
  selectAiConversationSourceIds,
} from './selectors/selectAiConversationRequest'
import { selectAiInstantReply } from './selectors/selectAiInstantReply'
import {
  COMPOSER_TYPE,
  CONVERSATION_TYPE,
  CREATE_REPLY_ACTION,
  INSTANT_REPLY_TYPE,
} from './types'
import { selectConversationSources } from './selectors/selectConversationSources'
import { selectShouldChargeConversation } from './selectors/conversationChargeStatus'

const TIMEOUT = 1000

// HACK (jscheel): this is bad, we need to refactor to just have a request id that drives everything in the state
export const AI_REQUEST_TYPE_SELECT_MAP = {
  [COMPOSER_TYPE]: selectAiComposerRequest,
  [INSTANT_REPLY_TYPE]: selectAiInstantReply,
  [CONVERSATION_TYPE]: selectAiConversationRequest,
}

export const useAi = aiRequestType => {
  const dispatch = useDispatch()
  const resultCheckTimeoutRef = useRef(null)
  const requestAborted = useRef(false)
  const requestState = useSelector(AI_REQUEST_TYPE_SELECT_MAP[aiRequestType])

  // FIXME (jscheel && kevinrademan && jdarnok): As discussed, this function
  // needs heavy refactoring so that its logic lives in operations. The hook
  // should ultimate just expose these operations. In the interest of time, we
  // are not full refactoring for now (save for the tweaks in the PR that also
  // added this note).
  const requestAndWait = useCallback(
    async (variables, options = {}) => {
      const mergedOptions = { ...options, requestType: aiRequestType }
      let retVal
      requestAborted.current = false

      try {
        const result = await dispatch(
          doAiRequest(asyncRpcQuery, variables, mergedOptions)
        )
        if (requestAborted.current) {
          return undefined
        }
        const resultJobId = findJobId(result)
        const pollForResult = async () => {
          const jobResult = await dispatch(
            doFetchJobResult(resultJobId, mergedOptions)
          )
          if (requestAborted.current) {
            return undefined
          }
          const { state, value } = jobResult?.ephemeralJobResult || {}
          if (state === 'COMPLETED') {
            return value
          }
          if (state === 'FAILED' || state === undefined) {
            throw new Error('Job failed')
          }
          await new Promise(resolve => {
            clearTimeout(resultCheckTimeoutRef.current)
            resultCheckTimeoutRef.current = setTimeout(resolve, TIMEOUT)
          })
          if (requestAborted.current) {
            return undefined
          }
          const ret = await pollForResult()
          if (requestAborted.current) {
            return undefined
          }
          return ret
        }
        retVal = await pollForResult()
      } catch (e) {
        dispatch(doAiFailed(mergedOptions.requestType))
        throw e // If you want to notify the caller about the failure
      }
      if (!requestAborted.current) {
        dispatch(
          doAiEnd(
            getAiType(mergedOptions.requestType),
            variables,
            mergedOptions,
            retVal
          )
        )
      }
      return retVal
    },
    [dispatch, aiRequestType, requestAborted]
  )

  const rateResponse = useCallback(() => {
    // TODO: Need to wire this up to some backend rating mechanisim
  }, [])

  useEffect(
    () => {
      const timeoutId = resultCheckTimeoutRef.current
      return () => {
        clearTimeout(timeoutId)
      }
    },
    [resultCheckTimeoutRef]
  )

  const abortResult = useCallback(
    () => {
      dispatch(doCancelAiResult(aiRequestType))
    },
    [dispatch, aiRequestType]
  )

  const hidePrompt = useCallback(
    () => {
      dispatch(doHidePrompt(aiRequestType))
    },
    [dispatch, aiRequestType]
  )

  const clearState = useCallback(
    () => {
      dispatch(doClearState(aiRequestType))
    },
    [dispatch, aiRequestType]
  )

  const cancelRequest = useCallback(
    () => {
      clearTimeout(resultCheckTimeoutRef.current)
      requestAborted.current = true
      resultCheckTimeoutRef.current = null
      dispatch(doClearState(aiRequestType))
    },
    [resultCheckTimeoutRef, requestAborted, dispatch, aiRequestType]
  )

  const retryRequest = useCallback(
    async () => {
      const lastRequest = requestState.lastRequest
      if (!lastRequest || !lastRequest.variables || !lastRequest.options) {
        throw new Error('Tried to retry without a proper lastRequest state')
      }

      return requestAndWait(lastRequest.variables, lastRequest.options)
    },
    [requestState, requestAndWait]
  )

  useEffect(
    () => {
      // If Change ticket before the request is finished, the state is cleared
      // but request is still processing, abort the request
      if (
        !requestState.loading &&
        !requestState.loaded &&
        !requestState.error &&
        resultCheckTimeoutRef.current
      ) {
        cancelRequest()
      }
    },
    [
      cancelRequest,
      requestState.loading,
      requestState.loaded,
      requestState.error,
    ]
  )

  return {
    requestAndWait,
    rateResponse,
    abortResult,
    hidePrompt,
    clearState,
    cancelRequest,
    retryRequest,
    requestState,
  }
}

export const useAiDraftGenerationAction = ({
  shouldLoadWallets = true,
} = {}) => {
  const dispatch = useDispatch()
  const {
    requestState: { loading },
  } = useAi(CONVERSATION_TYPE)

  const prefersAiEnabled = useSelector(selectPrefersAiEnabled)
  const isPaidAccount = useSelector(selectIsPaidAccount)

  const {
    isLoading: aiDraftWalletsIsLoading,
    isLoaded: aiDraftWalletsIsLoaded,
  } = useSelector(selectAiDraftWalletsStatus)

  const isAiDraftGenerationEnabledByPosthog = isFeatureEnabled(
    'ai-drafts-ready'
  )

  const { canUseFeature: canUseAiDraftGenerationByBilling } = useFeature(
    FEATURE_AI_MAX_DRAFT_GENERATIONS
  )

  const { featureLimit } = useFeature(FEATURE_AI_DRAFT_CREDITS)

  const wallet = useSelector(selectAiDraftAccountWallet)

  const { canUse } = wallet

  const isAiDraftGenerationFeatureEnabled =
    isAiDraftGenerationEnabledByPosthog &&
    canUseAiDraftGenerationByBilling &&
    prefersAiEnabled

  const canLoadWallets =
    shouldLoadWallets &&
    isAiDraftGenerationFeatureEnabled &&
    !aiDraftWalletsIsLoading &&
    !aiDraftWalletsIsLoaded

  const { openDrawer: openAIDraftOffer } = useDrawer({
    type: DRAWER_TYPE_AI_DRAFT_LOCKOUT,
  })

  const handleAiClick = useCallback(
    () => {
      if (canUse) {
        record()
        dispatch(doInitializeRequest(CONVERSATION_TYPE, CREATE_REPLY_ACTION))
      } else {
        openAIDraftOffer()
      }
    },
    [dispatch, canUse, openAIDraftOffer]
  )

  useEffect(
    () => {
      if (canLoadWallets) {
        dispatch(
          doFetchWalletsForFeature(walletsFeaturesMap.aiDraft, 'aiDraft')
        )
      }
    },
    [dispatch, canLoadWallets]
  )

  return {
    isAiDraftGenerationFeatureEnabled,
    canUseAiDraftGeneration: canUse,
    aiDraftGenerationIsLoading: loading,
    onAiDraftGenerationClick: handleAiClick,
    wallet,
    featureLimit,
    canUseFeatureByFeatureLimit: !!featureLimit,
    isPaidAccount,
    aiDraftWalletsIsLoaded,
    aiDraftWalletsIsLoading,
  }
}

export const useFetchAiConversationSources = (aiType, lastAiType) => {
  const dispatch = useDispatch()
  const isConversationType = [aiType, lastAiType].includes(CREATE_REPLY_ACTION)
  const { loaded, loading } = useSelector(selectConversationSources)
  const sourceIds = useSelector(selectAiConversationSourceIds)

  useEffect(
    () => {
      if (!isConversationType || loading || loaded) return

      if (sourceIds?.length) {
        dispatch(doFetchSourcesRequest(sourceIds))
      }
    },
    [loading, loaded, sourceIds, dispatch, isConversationType]
  )
}

export const useChargeCreditFromAiDraftWallet = (
  aiType,
  lastAiType,
  accountId
) => {
  const dispatch = useDispatch()
  const isConversationType = [aiType, lastAiType].includes(CREATE_REPLY_ACTION)
  const shouldCharge = useSelector(selectShouldChargeConversation)

  useEffect(
    () => {
      if (isConversationType && shouldCharge) {
        capture(`ai draft credit charge`, {
          account_id: accountId,
        })
        dispatch(doChargeCreditFromAiDraftWallet())
      }
    },
    [shouldCharge, dispatch, isConversationType, aiType, accountId]
  )
}
