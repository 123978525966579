import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_SUBSCRIPTIONS_REQUEST_STARTED,
  FETCH_SUBSCRIPTIONS_REQUEST_SUCCESS,
  FETCH_SUBSCRIPTIONS_REQUEST_FAILED,
  CANCEL_SUBSCRIPTION_REQUEST_STARTED,
  CANCEL_SUBSCRIPTION_REQUEST_SUCCESS,
  CANCEL_SUBSCRIPTION_REQUEST_FAILED,
  REACTIVATE_SUBSCRIPTION_REQUEST_STARTED,
  REACTIVATE_SUBSCRIPTION_REQUEST_SUCCESS,
  REACTIVATE_SUBSCRIPTION_REQUEST_FAILED,
} from 'ducks/integrations/recharge/types'

const initialState = {
  byCustomerId: {},
}

export const initialSingleState = {
  subscriptions: [],
  isLoading: false,
  isUpdating: false,
  isError: false,
  error: null,
}

const findSubscriptionByRequestParameters = (state, requestParameters) => {
  const { customerId, subscriptionId } = requestParameters

  return state.byCustomerId[customerId].subscriptions.find(
    s => s.id === parseInt(subscriptionId, 10)
  )
}

export default createActionTypeReducer(
  {
    [FETCH_SUBSCRIPTIONS_REQUEST_STARTED]: (draftState, action) => {
      const { customerId } = action.payload

      if (!draftState.byCustomerId[customerId]) {
        draftState.byCustomerId[customerId] = Object.assign(
          {},
          initialSingleState
        )
      }

      draftState.byCustomerId[customerId].isLoading = true

      return draftState
    },
    [FETCH_SUBSCRIPTIONS_REQUEST_SUCCESS]: (draftState, action) => {
      const { customerId } = action.meta.requestParameters
      const { subscriptions } = action.payload

      Object.assign(draftState.byCustomerId[customerId], {
        isLoading: false,
        isError: false,
        error: null,
        subscriptions,
      })

      return draftState
    },
    [FETCH_SUBSCRIPTIONS_REQUEST_FAILED]: (draftState, action) => {
      const { customerId } = action.meta.requestParameters
      const { error } = action.payload

      Object.assign(draftState.byCustomerId[customerId], {
        isLoading: false,
        isError: true,
        error,
      })

      return draftState
    },
    [CANCEL_SUBSCRIPTION_REQUEST_STARTED]: (draftState, action) => {
      const foundSubscription = findSubscriptionByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundSubscription, {
        isUpdating: true,
      })

      return draftState
    },
    [CANCEL_SUBSCRIPTION_REQUEST_SUCCESS]: (draftState, action) => {
      const { subscription } = action.payload

      const foundSubscription = findSubscriptionByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundSubscription, {
        ...subscription,
        isUpdating: false,
      })

      return draftState
    },
    [CANCEL_SUBSCRIPTION_REQUEST_FAILED]: (draftState, action) => {
      const foundSubscription = findSubscriptionByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundSubscription, {
        isUpdating: false,
      })

      return draftState
    },
    [REACTIVATE_SUBSCRIPTION_REQUEST_STARTED]: (draftState, action) => {
      const foundSubscription = findSubscriptionByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundSubscription, {
        isUpdating: true,
      })

      return draftState
    },
    [REACTIVATE_SUBSCRIPTION_REQUEST_SUCCESS]: (draftState, action) => {
      const { subscription } = action.payload

      const foundSubscription = findSubscriptionByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundSubscription, {
        ...subscription,
        isUpdating: false,
      })

      return draftState
    },
    [REACTIVATE_SUBSCRIPTION_REQUEST_FAILED]: (draftState, action) => {
      const foundSubscription = findSubscriptionByRequestParameters(
        draftState,
        action.meta.requestParameters
      )

      Object.assign(foundSubscription, {
        isUpdating: false,
      })

      return draftState
    },
  },
  initialState
)
