import { UPDATE_APP_DATA } from 'constants/action_types'
import {
  START_TRIAL_STARTED,
  START_TRIAL_SUCCESS,
  UPDATE_SUBSCRIPTION_STARTED,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_STARTED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  FETCH_BILLING_DATA_V1_REQUEST,
  FETCH_BILLING_DATA_V1_SUCCESS,
  FETCH_BILLING_DATA_V1_FAILED,
  APP_BILLING_EXPIRED,
  FETCH_BILLING_INVOICES_STARTED,
  FETCH_BILLING_INVOICES_SUCCESS,
  FETCH_BILLING_INVOICES_FAILED,
  FETCH_BILLING_INFORMATION_REQUEST,
  FETCH_BILLING_INFORMATION_SUCCESS,
  FETCH_BILLING_INFORMATION_FAILED,
  UPDATE_BILLING_INFORMATION_SUCCESS,
  UPDATE_BILLING_INFORMATION_FAILED,
  FETCH_BILLING_SUCCESS,
  CANCEL_DOWNGRADE_STARTED,
  CANCEL_DOWNGRADE_SUCCESS,
  CANCEL_DOWNGRADE_FAILED,
  GET_INVOICE_PREVIEW_STARTED,
  GET_INVOICE_PREVIEW_SUCCESS,
  GET_INVOICE_PREVIEW_FAILED,
} from './types'

const reducers = {}

reducers[UPDATE_APP_DATA] = (state, action) => {
  const data = action.data
  const { billing, creditCards, plans } = data
  // Expired billing updates might not send billing, so just
  // check if the object is present before adding it
  // to the state
  if (billing) {
    return {
      ...state,
      ...billing,
      ...{
        cards: creditCards,
        isLoading: false,
        isLoaded: true,
        plans,
      },
    }
  }
  return state
}

reducers[FETCH_BILLING_SUCCESS] = reducers[UPDATE_APP_DATA]

// Share reducer for app_data and expired billing updates
reducers[APP_BILLING_EXPIRED] = reducers[UPDATE_APP_DATA]

reducers[FETCH_BILLING_DATA_V1_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}
reducers[CANCEL_DOWNGRADE_STARTED] = reducers[FETCH_BILLING_DATA_V1_REQUEST]
reducers[GET_INVOICE_PREVIEW_STARTED] = reducers[FETCH_BILLING_DATA_V1_REQUEST]

reducers[FETCH_BILLING_DATA_V1_SUCCESS] = (state, action) => {
  const data = action.payload
  const { billing, creditCards } = data
  return {
    ...state,
    ...billing,
    ...{
      cards: creditCards,
      isLoading: false,
      isLoaded: true,
    },
  }
}

reducers[GET_INVOICE_PREVIEW_SUCCESS] = (state, action) => {
  const data = action.payload.invoicePreview
  return {
    ...state,
    account: {
      ...state.account,
      upcomingInvoice: data,
    },
    isLoading: false,
    isLoaded: true,
  }
}

reducers[FETCH_BILLING_DATA_V1_FAILED] = (state, action) => {
  return {
    ...state,
    isLoading: false,
    billingDataError: action.payload.error,
  }
}

reducers[CANCEL_DOWNGRADE_FAILED] = state => {
  return {
    ...state,
    isLoading: false,
  }
}

reducers[CANCEL_DOWNGRADE_SUCCESS] = reducers[CANCEL_DOWNGRADE_FAILED]
reducers[GET_INVOICE_PREVIEW_FAILED] = reducers[CANCEL_DOWNGRADE_FAILED]

reducers[START_TRIAL_STARTED] = (state, action) => {
  const billing = action.payload

  return {
    ...state,
    ...{
      ...billing,
      isStartingTrial: true,
    },
  }
}

reducers[START_TRIAL_SUCCESS] = (state, action) => {
  const billing = action.payload

  return {
    ...state,
    ...{
      ...billing,
      isStartingTrial: false,
    },
  }
}

reducers[UPDATE_SUBSCRIPTION_STARTED] = state => {
  return {
    ...state,
    ...{
      isSaving: true,
      updateSubscriptionError: null,
    },
  }
}

reducers[UPDATE_SUBSCRIPTION_SUCCESS] = (state, action) => {
  const data = action.payload
  const { updateSubscription } = data
  return {
    ...state,
    ...updateSubscription,
    ...{
      isSaving: false,
    },
  }
}

reducers[UPDATE_SUBSCRIPTION_FAILED] = (state, action) => {
  return {
    ...state,
    isSaving: false,
    updateSubscriptionError: action.payload.error,
  }
}

reducers[CANCEL_SUBSCRIPTION_STARTED] = state => {
  return {
    ...state,
    ...{
      isCanceling: true,
    },
  }
}

reducers[CANCEL_SUBSCRIPTION_SUCCESS] = (state, action) => {
  const data = action.payload
  const { cancelSubscription } = data
  return {
    ...state,
    ...cancelSubscription,
    ...{
      isCanceling: false,
    },
  }
}

reducers[FETCH_BILLING_INVOICES_STARTED] = state => {
  return {
    ...state,
    invoices: {
      isLoading: true,
      hasError: false,
    },
  }
}

reducers[FETCH_BILLING_INVOICES_SUCCESS] = (state, action) => {
  const data = action.payload

  return {
    ...state,
    invoices: {
      isLoading: false,
      isLoaded: true,
      data,
    },
  }
}

reducers[FETCH_BILLING_INVOICES_FAILED] = (state, action) => {
  const error = action.payload.error

  return {
    ...state,
    invoices: {
      isLoading: false,
      hasError: true,
      error,
    },
  }
}

reducers[FETCH_BILLING_INFORMATION_REQUEST] = state => {
  return {
    ...state,
    information: {
      isLoading: true,
      isLoaded: !state.information ? false : !!state.information.isLoaded,
      hasError: false,
    },
  }
}

reducers[FETCH_BILLING_INFORMATION_SUCCESS] = (state, action) => {
  const data = action.payload.data
  const { billingInformation } = data

  return {
    ...state,
    information: {
      isLoading: false,
      isLoaded: true,
      data: billingInformation,
    },
  }
}

reducers[UPDATE_BILLING_INFORMATION_SUCCESS] = (state, action) => {
  const { updateBillingInformation } = action.payload

  return {
    ...state,
    information: {
      data: updateBillingInformation,
    },
  }
}

reducers[FETCH_BILLING_INFORMATION_FAILED] = (state, action) => {
  const error = action.payload.error

  return {
    ...state,
    information: {
      isLoading: false,
      hasError: true,
      error,
    },
  }
}

reducers[UPDATE_BILLING_INFORMATION_FAILED] =
  reducers[FETCH_BILLING_INFORMATION_FAILED]

export default function reducer(state = {}, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
