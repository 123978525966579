import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'

export const selectPreferences = createSelector(
  selectCurrentUser,
  currentUser => {
    return currentUser.preferences || emptyObj
  }
)
