import { createSelector } from 'reselect'

import { selectContactDetailsColumnWidgetCards } from 'components/crm/ContactDetailsColumn/WidgetCards/selectors'
import { chunk, flatten } from 'util/arrays'

export const selectCustomFieldIdPages = createSelector(
  selectContactDetailsColumnWidgetCards,
  widgetCards => {
    const companyIds = flatten(
      widgetCards.map(({ categoryType, customFields }) => {
        if (categoryType === 'COMPANY') return customFields.map(({ id }) => id)
        return []
      })
    )
    const contactIds = flatten(
      widgetCards.map(({ categoryType, customFields }) => {
        if (categoryType === 'CONTACT') return customFields.map(({ id }) => id)
        return []
      })
    )
    return { company: chunk(companyIds, 20), contact: chunk(contactIds, 20) }
  }
)
