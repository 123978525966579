import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import { buildDraftDefaults } from 'ducks/drafts2/util'
import { selectPreferredMailboxId } from 'ducks/currentUser/selectors/preferences/selectPreferredMailboxId'

import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { selectRawTicket } from 'selectors/tickets/byId/selectRawTicket'
import { selectChangesetsForTicketId } from 'selectors/currentChangesets'
import { selectMailboxIdFromUrl } from 'selectors/mailboxes/selectMailboxIdFromUrl'
import { selectFirstMailboxId } from 'selectors/mailboxes/selectFirstMailboxId'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectPreviousMailboxId } from 'selectors/app'
import { selectIsAddingNote } from 'selectors/page'

import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { createBasicSelector } from 'util/redux'
import { stripHTML } from 'util/strings'

const storeKey = 'drafts2'

export const selectById = state => state[storeKey].byId || emptyObj

export const selectRawDraftsByTicketId = state => {
  return state[storeKey]?.byTicketId || emptyObj
}

export const selectByMessageId = state =>
  state[storeKey].byMessageId || emptyObj

export const selectDraftIdByTicketId = (state, ticketId, type) => {
  const byTicketId = selectRawDraftsByTicketId(state)
  const draftIdSet = byTicketId[ticketId]
  return draftIdSet ? draftIdSet[type] : null
}

export const selectDraftByTicketId = (state, ticketId, type) => {
  const draftId = selectDraftIdByTicketId(state, ticketId, type)
  if (draftId) return selectById(state)[draftId] || null
  return null
}

export const selectDraftBodyByTicketId = (state, ticketId, type) => {
  const found = selectDraftByTicketId(state, ticketId, type)
  return found ? found.body : null
}

export const selectDraftById = (state, draftId) => {
  return selectById(state)[draftId] || null
}

export const selectDraftBodyByDraftId = (state, draftId) => {
  const found = selectDraftById(state, draftId)
  return found ? found.body : null
}

export const selectDraftPropByDraftId = (state, draftId, prop) => {
  const found = selectDraftById(state, draftId)
  return found ? found[prop] : null
}

export const selectAttachmentsByDraftId = (state, draftId) => {
  const found = selectDraftById(state, draftId)
  return found ? found.attachmentsNormalized : emptyArr
}

export const selectDraftPropOrDefault = (state, draftId, ticketId, prop) => {
  const found = selectDraftById(state, draftId)
  if (found) return found[prop]

  const ticket = selectRawTicket(state, ticketId)
  const defaults = ticket.draftDefaults

  if (defaults) return defaults[prop]

  // (TA): There is a rare occurrence where ticket.draftDefaults not found for a ticket
  // (probably being overwritten or not being set in an obv action)
  // cannot find the redux action that's responsible for this so gonna try manually build the defaults
  const ticketChangesets = selectChangesetsForTicketId(state, ticketId)
  const rebuiltDraftDefaults = buildDraftDefaults(
    state,
    ticket,
    ticketChangesets
  )

  return rebuiltDraftDefaults ? rebuiltDraftDefaults[prop] : null
}

export const makeDraftPropOrDefaultSelector = (state, draftId, ticketId) => {
  return prop => selectDraftPropOrDefault(state, draftId, ticketId, prop)
}

export const selectDraftIsSynced = (state, draftId) => {
  const draft = selectDraftById(state, draftId)
  if (!draft) return null
  return draft.version === draft.serverVersion
}

const selectCurrentTicketDraftId = createSelector(
  selectCurrentTicketId,
  selectRawDraftsByTicketId,
  (ticketId, byTicketId) => byTicketId[ticketId]
)

export const selectCurrentTicketHasDraft = createSelector(
  selectCurrentTicketDraftId,
  draftId => !!draftId
)

export const selectMailboxIdForDraftById = createCachedSelector(
  selectMailboxIdFromUrl,
  (state, draftId) => selectDraftPropByDraftId(state, draftId, 'mailboxId'),
  selectCurrentMailboxId,
  selectPreviousMailboxId,
  selectPreferredMailboxId,
  selectFirstMailboxId,
  (
    mailboxIdFromUrl,
    draftMailboxId,
    currentMailboxId,
    previousMailboxId,
    preferredMailboxId,
    firstMailboxId
  ) =>
    draftMailboxId ||
    mailboxIdFromUrl ||
    currentMailboxId ||
    previousMailboxId ||
    preferredMailboxId ||
    firstMailboxId
)((_state, draftId) => draftId || 'unknown')

export const selectCurrentTicketReplyDraft = createBasicSelector(
  selectCurrentTicket,
  state => state,
  (ticket, state) => selectDraftByTicketId(state, ticket?.id, 'reply')
)

export const selectCurrentTicketNoteDraft = createBasicSelector(
  selectCurrentTicket,
  state => state,
  (ticket, state) => selectDraftByTicketId(state, ticket?.id, 'note')
)

export const selectCurrentTicketDraftByInferredType = createSelector(
  selectIsAddingNote,
  selectCurrentTicketNoteDraft,
  selectCurrentTicketReplyDraft,
  (isAddingNote, noteDraft, replyDraft) =>
    isAddingNote ? noteDraft : replyDraft
)

export const selectCurrentRecipientSyncSource = state =>
  state[storeKey].currentRecipientSync?.source

export const selectIsSyncingRecipients = createBasicSelector(
  selectCurrentRecipientSyncSource,
  source => !!source
)

export const selectCurrentTicketReplyDraftHasBody = createSelector(
  selectCurrentTicketReplyDraft,
  draft => !!(draft && draft.body && stripHTML(draft.body))
)

export const selectCurrentTicketReplyDraftTo = createSelector(
  selectCurrentTicketReplyDraft,
  draft => draft?.to
)

export const selectCurrentTicketReplyDraftAllRecipientIds = createSelector(
  selectCurrentTicketReplyDraft,
  draft => {
    if (!draft) return []
    const all = {}
    ;['to', 'cc', 'bcc'].forEach(type => {
      const recipients = draft[type] || []
      recipients.forEach(r => {
        if (r.id) {
          all[r.id] = r.id
        }
      })
    })

    return Object.values(all)
  }
)
