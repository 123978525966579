import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'

export default createSelector(
  (state, { source }) => {
    return Object.values(
      state.integrations.thirdPartyData[source].priorities.byId
    )
  },
  priorities => {
    if (!priorities) return emptyArr
    return priorities.map(({ iconUrl, id, name }) => {
      return {
        iconUrl,
        text: name,
        value: id,
      }
    })
  }
)
