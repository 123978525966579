import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import { SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST } from '../types'

const query = `
  query PaymentTermsTemplates {
    paymentTermsTemplates {
      id
      name
      paymentTermsType
      dueInDays
      description
    }
  }
`

const doFetchPaymentTermsTemplates = integrationId => dispatch => {
  return dispatch(
    doGraphqlRequest(
      SHOPIFY_PAYMENT_TERMS_TEMPLATES_CREATE_REQUEST,
      query,
      {},
      {
        graphqlFunc: graphql(integrationId),
        meta: {
          integrationId,
        },
      }
    )
  )
}

export default doFetchPaymentTermsTemplates
