import { createActionTypeReducer } from 'util/reducers'
import {
  AI_STREAMING_UPDATE,
  FETCH_AI_JOB_CONVERSATION_RESULT_END,
  FETCH_AI_JOB_CONVERSATION_RESULT_INITIALIZE,
} from 'ducks/ai/types'
import {
  CHARGE_CREDIT_FROM_WALLET_FAILED,
  CHARGE_CREDIT_FROM_WALLET_STARTED,
  CHARGE_CREDIT_FROM_WALLET_SUCCESS,
} from 'ducks/wallets/types'

const initialState = {
  loading: false,
  isCharged: false,
  needsCharge: false,
}

export default createActionTypeReducer(
  {
    [FETCH_AI_JOB_CONVERSATION_RESULT_INITIALIZE]: () => {
      return initialState
    },
    [AI_STREAMING_UPDATE]: (draftState, { payload }) => {
      const { content } = payload
      if (content && !draftState.needsCharge) {
        draftState.needsCharge = true
      }
      return draftState
    },
    [FETCH_AI_JOB_CONVERSATION_RESULT_END]: (draftState, { payload }) => {
      const response = payload.result?.result

      if (response?.success && !draftState.needsCharge) {
        draftState.needsCharge = true
      }
      return draftState
    },
    [CHARGE_CREDIT_FROM_WALLET_STARTED]: draftState => {
      draftState.loading = true
      return draftState
    },
    [CHARGE_CREDIT_FROM_WALLET_SUCCESS]: (draftState, { payload }) => {
      const { walletTransactionCreate } = payload
      draftState.loading = false
      if (walletTransactionCreate?.success) {
        draftState.isCharged = true
      } else {
        draftState.needsCharge = false
      }
      return draftState
    },
    [CHARGE_CREDIT_FROM_WALLET_FAILED]: () => {
      return initialState
    },
  },
  initialState
)
