import { contactCustomFieldValuesSubQuery } from './contactCustomFieldValuesSubQuery'

export const fetchContactCustomFieldsByCustomFieldFirstPageQuery = `
  query ContactByCustomFieldFirstPage($after: String, $filter: ContactFilter) {
    contacts(filter: $filter, first: 1) {
      nodes {
        id
        avatarUrl
        companies {
          nodes {
            id
          }
        }
        email
        firstName
        lastName
        name
        lastSeenAt
        ${contactCustomFieldValuesSubQuery}
      }
    }
  }
`
