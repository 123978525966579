import { oauthTokenSelector } from 'selectors/app'
import { FETCH_SUBSCRIPTIONS_REQUEST } from 'ducks/integrations/stripe/types'
import { doSdkRequest } from 'ducks/requests/operations'

import * as api from '../api'

const doFetchSubscriptions = ({ integrationId, customerId, lastItemId }) => {
  return doSdkRequest(
    FETCH_SUBSCRIPTIONS_REQUEST,
    async ({ getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)

      const { response } = await api.getSubscriptions(
        token,
        integrationId,
        customerId,
        lastItemId
      )
      const subscriptionsResponse = await Promise.all(
        response.data.map(sub =>
          api.getSubscription(token, integrationId, sub.id)
        )
      )

      return {
        data: subscriptionsResponse.map(s => s.response),
        has_more: response.has_more,
      }
    },
    { integrationId, customerId, lastItemId }
  )
}

export default doFetchSubscriptions
