import {
  account,
  feature,
  plan,
  pricing,
  usage,
  creditCard,
  discount,
} from 'ducks/entities/schema'

export const bootstrapSchema = {
  account,
  billing: {
    availableFeatures: [feature],
    usages: [usage],
    plans: [plan],
    pricings: [pricing],
    creditCards: [creditCard],
    discount,
  },
}
