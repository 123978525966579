import { doGraphqlRequest } from 'ducks/requests/operations'
import { ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST } from 'ducks/integrations/shopify/types'
import { REFUND_RESTOCK_TYPES } from 'ducks/integrations/shopify/constants'
import graphql from '../api'

const getRestockType = (restock, isFulfilled, isCustomLineItem) => {
  if (!restock || isCustomLineItem) {
    return REFUND_RESTOCK_TYPES.NO_RESTOCK
  } else if (isFulfilled) {
    return REFUND_RESTOCK_TYPES.RETURN
  }
  return REFUND_RESTOCK_TYPES.CANCEL
}

const query = `
query suggestedRefund($id: ID!, $refundLineItems: [RefundLineItemInput!], $shippingAmount: Money) {
    order(id: $id) {
      suggestedRefund(refundLineItems: $refundLineItems, shippingAmount: $shippingAmount) {
        amountSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        subtotalSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        totalTaxSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        totalCartDiscountAmountSet {
          shopMoney {
            amount
            currencyCode
          }
        }
        shipping {
          amountSet {
            shopMoney {
              amount
              currencyCode
            }
          }
          maximumRefundableSet {
            shopMoney {
              amount
              currencyCode
            }
          }
        }
        suggestedTransactions {
          amountSet {
            shopMoney {
              amount
              currencyCode
            }
          }
          gateway
          formattedGateway
          accountNumber
          kind
          maximumRefundableSet {
            shopMoney {
              amount
              currencyCode
            }
          }
          parentTransaction {
            id
          }
        }
        refundLineItems {
          quantity
          restockType
          location {
            id
          }
          subtotalSet {
            shopMoney {
              amount
              currencyCode
            }
          }
          lineItem {
            id
          }
        }
      }
    }
  }
`
// The api doc for this is quite hidden:
// https://shopify.dev/api/admin-graphql/2022-07/objects/Order#field-order-suggestedrefund
// https://shopify.dev/api/admin-graphql/2022-07/objects/SuggestedRefund
const doCalculateRefund = (integrationId, orderId, data) => dispatch => {
  const { lineItems, shippingAmount, isFulfilled, restock } = data

  const refundLineItems = lineItems.map(li => {
    const isCustomLineItem = !li.product
    return {
      lineItemId: li.id,
      quantity: li.suggestedRefund?.quantity || 0,
      restockType: getRestockType(restock, isFulfilled, isCustomLineItem),
    }
  })

  const variables = {
    id: orderId,
    refundLineItems,
    shippingAmount: Number.isNaN(parseFloat(shippingAmount))
      ? 0
      : shippingAmount,
  }

  return dispatch(
    doGraphqlRequest(
      ORDER_SUGGESTED_REFUND_CALCULATE_REQUEST,
      query,
      variables,
      {
        graphqlFunc: graphql(integrationId),
      }
    )
  )
}

export default doCalculateRefund
