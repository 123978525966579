import { DRAFT_ORDER_CLEAR_PRODUCT_LINE_ITEMS_LOCALLY } from '../types'

const doDraftOrderClearLineItemsLocally = draftOrderId => ({
  type: DRAFT_ORDER_CLEAR_PRODUCT_LINE_ITEMS_LOCALLY,
  payload: {
    draftOrderId,
  },
})

export default doDraftOrderClearLineItemsLocally
