import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'

import selectIntegrationSettingsForProvider from './selectIntegrationSettingsForProvider'

const SHOW_FIELD_REGEX = /^showSidebarField\.(.+)$/

export default createCachedSelector(
  selectIntegrationSettingsForProvider,
  settings => {
    const keys = Object.keys(settings)
    return (
      (keys.length > 0 &&
        keys.reduce((result, key) => {
          const matches = key.match(SHOW_FIELD_REGEX)
          // eslint-disable-next-line no-param-reassign
          if (matches) result[matches[1]] = settings[key]
          return result
        }, {})) ||
      emptyObj
    )
  }
)((state, { provider }) => provider)
