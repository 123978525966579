export const createContactMutation = `
mutation createContactMutation($customFields: [CustomFieldInput!]) {
  contactCreate(input: { contactType: contact, customFields: $customFields }) {
    contact {
      id
    }
  }
}

`
