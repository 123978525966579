/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { emptyObj } from 'util/objects'

import {
  FETCH_COMPANIES_FAILED,
  FETCH_COMPANIES_STARTED,
  FETCH_COMPANIES_SUCCESS,
} from '../types'

export default createActionTypeReducer(
  {
    [FETCH_COMPANIES_STARTED]: (draftState, { meta: { requestKey } = {} }) => {
      draftState[requestKey] = {
        ...draftState[requestKey],
        loading: true,
      }
    },
    [FETCH_COMPANIES_SUCCESS]: (
      draftState,
      {
        entities: { companies: companyEntities },
        meta: { requestKey } = {},
        payload: { companies: payloadCompanies },
      }
    ) => {
      const { pageInfo } = payloadCompanies || {}
      const search = draftState[requestKey]
      search.ids = companyEntities && Object.keys(companyEntities)
      search.errored = false
      search.pageInfo = pageInfo
      search.loading = false
    },
    [FETCH_COMPANIES_FAILED]: (
      draftState,
      { meta: { requestKey } = {}, payload: { error } = {} }
    ) => {
      const search = draftState[requestKey]
      search.loading = false
      search.errored = true
      search.error = {
        message: error.message,
      }
    },
  },
  emptyObj
)
