import { oauthTokenSelector } from 'selectors/app'

import gitHubGraphQl, { CLIENT_ID } from '../gitHubGraphQl'
import { GITHUB_ISSUE_CHANGED } from '../types'

function makeQuery(type) {
  return `
    mutation(
      $clientId: String, 
      $issueId: ID!, 
    ) {
      ${type}(input: {
        clientMutationId: $clientId,
        issueId: $issueId
      }) {
        issue {
          __typename
          id
          state
        }
        
      }
    }`
}
const closeQuery = makeQuery('closeIssue')
const openQuery = makeQuery('reopenIssue')

export default function doSetStateForGitHubIssue(issueId, openOrClosed) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const variables = {
      clientId: CLIENT_ID,
      issueId,
    }
    dispatch({
      type: GITHUB_ISSUE_CHANGED,
      meta: { id: issueId },
      payload: { node: { id: issueId, state: openOrClosed } },
    })
    return gitHubGraphQl(
      token,
      openOrClosed === 'OPEN' ? openQuery : closeQuery,
      variables
    )
  }
}
