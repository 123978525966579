import { doAppGraphqlRequest } from 'ducks/requests/operations'
import {
  CREATE_EXCLUDED_DOMAIN,
  DELETE_EXCLUDED_DOMAIN,
  UPDATE_EXCLUDED_DOMAIN,
} from '../types'
import {
  excludedDomainNormalizationSchema,
  excludedDomainDeleteNormalizationSchema,
} from '../schema'

const createQuery = `
  mutation ExcludedDomainCreate(
    $input: ExcludedDomainCreateInput!
  ) {
    excludedDomainCreate(input: $input) {
      excludedDomain {
        id
        domain
      }
    }
  }
`
export function doCreateExcludedDomain(input) {
  return async dispatch => {
    dispatch(
      doAppGraphqlRequest(
        CREATE_EXCLUDED_DOMAIN,
        createQuery,
        { input },
        {
          normalizationSchema: {
            excludedDomainCreate: {
              excludedDomain: excludedDomainNormalizationSchema,
            },
          },
        }
      )
    )
  }
}

const updateQuery = `
  mutation ExcludedDomainUpdate(
    $input: ExcludedDomainUpdateInput!
  ) {
    excludedDomainUpdate(input: $input) {
      excludedDomain {
        id
        domain
      }
    }
  }
`
export function doUpdateExcludedDomain({ id, domain }) {
  return async dispatch => {
    dispatch(
      doAppGraphqlRequest(
        UPDATE_EXCLUDED_DOMAIN,
        updateQuery,
        {
          input: {
            excludedDomainId: id,
            domain,
          },
        },
        {
          normalizationSchema: {
            excludedDomainUpdate: {
              excludedDomain: excludedDomainNormalizationSchema,
            },
          },
        }
      )
    )
  }
}

const deleteQuery = `
  mutation ExcludedDomainDelete(
    $input: ExcludedDomainDeleteInput!
  ) {
    excludedDomainDelete(input: $input) {
      deletedExcludedDomainId
    }
  }
`
export function doDeleteExcludedDomain(excludedDomainId) {
  return async dispatch => {
    dispatch(
      doAppGraphqlRequest(
        DELETE_EXCLUDED_DOMAIN,
        deleteQuery,
        { input: { excludedDomainId } },
        {
          meta: { removeEntities: true },
          normalizationSchema: {
            excludedDomainDelete: excludedDomainDeleteNormalizationSchema,
          },
        }
      )
    )
  }
}
