import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUpcomingInvoiceForSubscriptionId } from 'ducks/integrations/stripe/selectors/upcomingInvoices'

import doFetchUpcomingInvoice from 'ducks/integrations/stripe/operations/doFetchUpcomingInvoice'

export const useInvoice = (integrationId, customerId, subscriptionId, type) => {
  const dispatch = useDispatch()

  const invoiceData = useSelector(state =>
    selectUpcomingInvoiceForSubscriptionId(state, subscriptionId)
  )

  useEffect(
    () => {
      if (
        integrationId &&
        customerId &&
        subscriptionId &&
        !invoiceData.isInitialized
      ) {
        dispatch(
          doFetchUpcomingInvoice({
            integrationId,
            customerId,
            subscriptionId,
            type,
          })
        )
      }
    },
    [dispatch, integrationId, customerId, subscriptionId, type, invoiceData]
  )

  if (invoiceData) {
    return invoiceData
  }

  return {}
}
