import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'

import selectJiraProjects from './selectJiraProjects'

export default createSelector(selectJiraProjects, projects => {
  if (!projects) return emptyArr
  return projects.map(
    ({
      avatarUrls: { '48x48': avatarUrl } = {},
      id,
      name,
      site: { id: siteId } = {},
    }) => {
      return {
        avatarUrl,
        text: name,
        siteId,
        value: siteId ? `${siteId}:${id}` : id,
      }
    }
  )
})
