export const resetLoginAttemptsMutation = `
  mutation AgentResetLoginAttempts(
    $agentId: ID
  ) {
    agentResetLoginAttempts(input: {
      agentId: $agentId
    }) {
      agent {
        status2fa
      }
    }
  }
`

export const resetPasswordMutation = `
  mutation AgentResetPassword(
    $token: String!,
    $recaptchaToken: String!,
    $password: String!,
    $passwordConfirm: String!,
    $authCode: String
  ) {
    agentResetPassword(input: {
      token: $token,
      recaptchaToken: $recaptchaToken,
      password: $password,
      passwordConfirm: $passwordConfirm,
      authCode: $authCode
    }) {
      errors {
        message
        path
      }
    }
  }
`
export const agentSendAdminEmailNotification = `
  mutation AgentSendAdminEmailNotification($notificationType: AdminEmailNotification!) {
    agentSendAdminEmailNotification(input: { notificationType: $notificationType }) {
      errors {
        message
        path
      }
    }
  }
`
