import { uniq } from 'util/arrays'

import doFetchGitHubIssues from './doFetchGitHubIssues'

export default function doFetchGitHubResourceFromActions(actions) {
  const issueIds = actions
    .map(
      ({
        change: { action: { issue: { node_id: issueId } = {} } = {} } = {},
      }) => {
        return issueId
      }
    )
    .filter(x => !!x)
  const uniqIssueIds = uniq(issueIds)
  return doFetchGitHubIssues(uniqIssueIds)
}
