import { emptyObj } from 'util/objects'

import { selectBase } from './base'

export function selectPageOrderBy(state) {
  return selectBase(state).orderBy || emptyObj
}

export function selectPageOrderDirection(state) {
  return selectPageOrderBy(state).direction
}

export function selectPageOrderDirectionSemantic(state) {
  const direction = selectPageOrderDirection(state)
  return direction === 'ASC' ? 'ascending' : 'descending'
}

export function selectPageOrderDirectionToggled(state) {
  const direction = selectPageOrderDirection(state)
  return direction === 'ASC' ? 'DESC' : 'ASC'
}

export function selectPageOrderField(state) {
  return selectPageOrderBy(state).field
}
