/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { MATRIX_CLIENT_READY, MATRIX_UI_READY } from '../actionTypes/client'

const initialState = {
  client: false,
  ui: false,
}

export default createActionTypeReducer(
  {
    [MATRIX_CLIENT_READY]: draftState => {
      draftState.client = true
      return draftState
    },
    [MATRIX_UI_READY]: draftState => {
      draftState.ui = true
      return draftState
    },
  },
  initialState
)
