import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { isBlank } from 'util/strings'
import { selectContactsById } from 'ducks/crm/contacts/selectors/base'

export function selectRoomContactsBase(state) {
  const { chat: { contacts = emptyObj } = emptyObj } = state
  return contacts
}

export function selectByRoomId(state) {
  return selectRoomContactsBase(state).byRoomId || emptyObj
}

export const selectContactByRoomId = createSelector(
  selectByRoomId,
  selectContactsById,
  (byRoomId, contactsById) => {
    return Object.keys(byRoomId).reduce((obj, roomId) => {
      const contactByRoomId = byRoomId[roomId]
      const contact = contactsById[contactByRoomId.id]
      if (!contact) {
        // eslint-disable-next-line no-param-reassign
        obj[roomId] = contactByRoomId
      } else {
        const {
          contact_first_name: { value: { content: firstName = '' } = {} } = {},
          contact_last_name: { value: { content: lastName = '' } = {} } = {},
          contact_email: { value: { content: email = '' } = {} } = {},
          contact_avatar: { value: { url, dataUrl } = {} } = {},
          contact_avatar_url: { value: { content: avatarUrl } = {} } = {},
        } =
          contact?.customFieldValues || {}

        const name = `${firstName} ${lastName}`
        // eslint-disable-next-line no-param-reassign
        obj[roomId] = {
          id: contactByRoomId.id,
          name: !isBlank(name) ? name : 'Customer',
          email,
          avatarUrl: dataUrl || url || avatarUrl,
        }
      }
      return obj
    }, {})
  }
)
