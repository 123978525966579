import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import * as types from '../types'
import { draftOrderPartial } from './partials'

const draftOrderCreateMutation = `
  mutation ShopifyDraftOrderCreate($input: DraftOrderInput!)
  {
    draftOrderCreate(input: $input) {
      userErrors {
        message
        field
      }

      draftOrder {
        ${draftOrderPartial}
      }
    }
  }
`

const doDraftOrderCreate = (integrationId, customerId, data) => dispatch => {
  const variables = { input: { customerId, ...data } }

  const hasNoLineItemForCreate =
    !!data.lineItems && data.lineItems?.length === 0

  // you cannot create a draft with 0 product line items https://shopify.dev/api/admin-rest/2021-10/resources/draftorder#post-draft-orders
  if (hasNoLineItemForCreate) return Promise.resolve()

  return dispatch(
    doGraphqlRequest(
      types.DRAFT_ORDER_CREATE_REQUEST,
      draftOrderCreateMutation,
      variables,
      {
        graphqlFunc: graphql(integrationId),
      }
    )
  )
}

export default doDraftOrderCreate
