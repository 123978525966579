import { createSelector } from 'reselect'

import {
  selectAllCustomFieldCategoriesHasErrored,
  selectAllCustomFieldCategoriesIsLoading,
} from 'ducks/crm/customFieldCategories/selectors/loading'
import { selectCurrentId } from 'selectors/location'

export function selectBase(state) {
  return state.crm.customFields
}

export function selectCustomFieldsById(state) {
  return selectBase(state).byId
}
export const selectCustomFields = createSelector(selectCustomFieldsById, byId =>
  Object.values(byId)
)

export const selectCustomFieldKeys = createSelector(
  selectCustomFields,
  fields => fields.map(({ key }) => key)
)

export const selectCustomFieldKeysExcludingCurrentId = createSelector(
  selectCurrentId,
  selectCustomFields,
  (id, fields) => {
    return fields
      .filter(({ id: fieldId }) => id !== fieldId)
      .map(({ key }) => key)
  }
)

export const selectCustomFieldsByKey = createSelector(
  selectCustomFieldsById,
  customFields => {
    return Object.values(customFields).reduce((result, field) => {
      // eslint-disable-next-line no-param-reassign
      result[field.key] = field
      return result
    }, {})
  }
)

export function selectAllCustomFieldsIsLoading(state) {
  return selectBase(state).isLoading
}

export function selectAllCustomFieldsHasErrored(state) {
  return selectBase(state).hasErrored
}

export function selectAllCustomFieldsIsLoaded(state) {
  return selectBase(state).isLoaded
}

export function selectCustomFieldForId(state, { id }) {
  return selectCustomFieldsById(state)[id]
}

export const selectCustomFieldForKey = createSelector(
  selectCustomFields,
  (state, { key }) => key,
  (fields, key) => {
    return fields.find(({ key: findKey }) => findKey === key)
  }
)

export const selectCustomFieldWithHandleTypes = createSelector(
  selectCustomFields,
  customFields => customFields.filter(cf => !!cf.handleType)
)

export const selectIsCustomFieldsOrCategoriesLoading = createSelector(
  selectAllCustomFieldCategoriesIsLoading,
  selectAllCustomFieldsIsLoading,
  (categoriesIsLoading, fieldsIsLoading) => {
    return categoriesIsLoading || fieldsIsLoading
  }
)

export const selectCustomFieldsOrCategoriesHasErrored = createSelector(
  selectAllCustomFieldCategoriesHasErrored,
  selectAllCustomFieldsHasErrored,
  (categoriesIsErrored, fieldsIsErrored) => {
    return categoriesIsErrored || fieldsIsErrored
  }
)
