import { createSelector } from 'reselect'
import { selectIsBillingPage } from 'subapps/settings/selectors'
import { selectFlag } from 'ducks/flags/selectors'
import { selectIsPendingCancellation } from './selectIsPendingCancellation'

export const selectShowPendingCancellation = createSelector(
  selectIsPendingCancellation,
  selectIsBillingPage,
  selectFlag,
  (isPendingCancellation, isOnBillingPage, flags) => {
    return (
      isPendingCancellation &&
      isOnBillingPage &&
      flags &&
      flags('app-notice-pendingcancellation') !== true
    )
  }
)
