import { connect } from 'react-redux'

import { doQueueEditorToolbarMessage } from 'actions/app'
import {
  doInsertCannedReply,
  doUndoInsertCannedReply,
  doFetchCannedReplyCategories,
} from 'ducks/cannedReplies/operations'
import { doOpenReplyPage } from 'actions/pages'

import { selectIsSearchingCannedReplies } from 'ducks/cannedReplies/selectors'

import { runOnNextTick } from 'util/functions'
import CannedRepliesModal from './view'

const select = state => ({
  isSearching: selectIsSearchingCannedReplies(state),
})

const perform = (dispatch, ownProps) => ({
  fetchCannedReplyCategories: () => dispatch(doFetchCannedReplyCategories()),
  onSelectReply: reply => {
    if (ownProps.ticketId !== 'new') {
      dispatch(doOpenReplyPage(ownProps.ticketId))
    }
    runOnNextTick(() => {
      dispatch(doInsertCannedReply(reply))
      dispatch(doQueueEditorToolbarMessage('UNDO', doUndoInsertCannedReply))
    })
  },
})

export default connect(select, perform)(CannedRepliesModal)
