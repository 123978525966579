import Bugsnag from '@bugsnag/js'
import { v4 as uuidV4 } from 'uuid'
import appGraphql from 'api/app_graphql'

import {
  UPDATE_DRAFT,
  DELETE_DRAFT,
  FAILED_SEND,
  DRAFT_RECIPIENT_SYNC_STARTED,
  DRAFT_RECIPIENT_SYNC_FINISHED,
} from 'ducks/drafts2/constants'
import {
  selectDraftById,
  selectDraftIdByTicketId,
} from 'ducks/drafts2/selectors'
import { selectPreferences } from 'ducks/currentUser/selectors/preferences/selectPreferences'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectPrefersOpenFolderOnTicketReply } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketReply'
import { selectPrefersOpenFolderOnNoteAdd } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnNoteAdd'
import { selectCurrentUserPrefersAutoadvanceToNextTicket } from 'ducks/currentUser/selectors/preferences/selectCurrentUserPrefersAutoadvanceToNextTicket'
import { selectCurrentUserId } from 'ducks/currentUser/selectors/base'
import { doUpdateEditorVisibility } from 'ducks/editor/operations'
import {
  doFollowTicketById,
  doUnfollowTicketById,
  TRACK_TYPE_FOLLOW_TICKET_NOTE,
} from 'ducks/ticketing/operations/ticketFollow'
import { selectShouldSendFollowRequest } from 'ducks/ticketing/selectors/ticketFollow'

import {
  oauthTokenSelector,
  selectCurrentFolderId,
  selectCurrentTicketListRouteAction,
} from 'selectors/app'
import { selectGroupsById } from 'selectors/app/groups'
import { selectRawTicket } from 'selectors/tickets/byId/selectRawTicket'
import { selectNextTicketIdInList } from 'selectors/ticket_list'
import { selectCurrentTicketSearchQueryObject } from 'selectors/search'
import { selectAgentsByIdIncludingArchived } from 'selectors/agents/base'
import { selectFolderIdsMatchingDraftForCurrentUser } from 'selectors/folders'

import { doSendChangeset } from 'actions/tickets/changeset/doSendChangeset'
import { doClearUserSuggestion } from 'actions/tickets/doClearUserSuggestion'
import { doSearchUsers } from 'actions/tickets/doSearchUsers'
import { doAutoRedirect } from 'actions/autoRedirect'
import { doShowSnackbar } from 'actions/snackbar'
import { doOpenTicketPage } from 'actions/pages'
import { startTimer as startUndoTimer } from 'actions/undoSend'

import { messagePayload } from 'optimistic/message'
import { notePayload } from 'optimistic/note'

import { isPromise, runOnNextTick } from 'util/functions'
import windowUnload from 'util/windowUnload'
import { isTwitter, isFacebook } from 'util/ticket'
import { isEmpty } from 'util/objects'
import { isEmpty as isEmptyArray } from 'util/arrays'
import debug from 'util/debug'

import { doUpsertReplyDraft } from './doUpsertReplyDraft'
import { doSendDraftToServer } from './doSendDraftToServer'
import { saveRequests } from './saveRequests'

const MIN_RECIPIENT_QUERY_LENGTH = 1

const updateDebounces = {}
const postUpdateCallbacks = {}
const sendDebounces = {}
// this function makes sure that if we ever initialized a draft before having draftDefaults created, we can re-initialize those defaults safely without overriding any changes done by the customer
export function doEnsureDefaults(draftId, draftType, forwarding) {
  return (dispatch, getState) => {
    const state = getState()
    const draft = selectDraftById(state, draftId)
    const ticketId = draft.ticketId

    // only do this once
    if (draft.defaultsSet) {
      debug('defaults already set, aborting')
      return 1
    }

    const ticket = selectRawTicket(state, ticketId)
    if (!ticket || isEmpty(ticket)) {
      draft.defaultsFailReason = 'ticket not found'
      debug('ticket not found')
      return 2
    }
    const draftDefaults =
      draftType === 'note' ? ticket.noteDefaults : ticket.draftDefaults

    // this should never happen
    if (!draftDefaults) {
      draft.defaultsFailReason = 'no draft defaults'
      debug('no draft defaults')
      return 3
    }

    const fields = { ...draft }
    let hasChanges = false

    if (isEmptyArray(fields.to)) {
      fields.to = undefined
    }

    const candidates = [
      'title',
      'mailboxId',
      'assigneeId',
      'assigneeGroupId',
      'state',
      'to',
    ]
    candidates.forEach(field => {
      const defaultValue = draftDefaults[field]
      // ONLY override if not set
      if (fields[field] === undefined && defaultValue) {
        fields[field] = defaultValue
        hasChanges = true
      }

      if (field === 'to' && fields[field] !== undefined && forwarding) {
        // no default 'to' field for forwarded messages
        fields[field] = undefined
        hasChanges = true
      }
    })
    if (!hasChanges) {
      draft.defaultsFailReason = 'no changes'
      debug('no changes, aborting')
      return 4
    }

    // our job is done, we should never do it again
    fields.defaultsSet = true
    fields.defaultsSetOnEnsure = true
    dispatch({
      type: UPDATE_DRAFT,
      payload: { draftType, draftId, ticketId, fields },
    })
    // only send to server if already sent before
    if (draft.serverVersion > 0) {
      dispatch(doSendDraftToServer(draftId, ticketId))
    }
    return 0
  }
}

// set cc and bcc to default values
export function doSwitchToReplyAll(ticketId, draftType, draftId) {
  return (dispatch, getState) => {
    const state = getState()
    const { to = [], cc = [], bcc = [] } = selectDraftById(state, draftId) || {}
    const messageId = null
    const ticket = selectRawTicket(state, ticketId)

    if (!ticket || isEmpty(ticket)) {
      debug('ticket not found')
      return 2
    }

    const draftDefaults = ticket.draftDefaults || {}
    const fields = {
      replyType: 'reply-all',
      isForwarding: undefined,
      to: to && (to?.length || 0) > 0 ? [to[0]] : draftDefaults.to,
      cc: cc && (cc?.length || 0) > 0 ? cc : draftDefaults.cc,
      bcc: bcc && (bcc?.length || 0) > 0 ? bcc : draftDefaults.bcc,
    }
    handleDraftChange(dispatch, draftType, draftId, ticketId, messageId, fields)

    return 0
  }
}

// Remove all cc and bcc's
export function doSwitchToDirectReply(ticketId, draftType, draftId) {
  return (dispatch, getState) => {
    const state = getState()
    const { to = [] } = selectDraftById(state, draftId) || {}
    const messageId = null
    const ticket = selectRawTicket(state, ticketId)
    const draftDefaults = ticket.draftDefaults || {}

    if (!ticket || isEmpty(ticket)) {
      debug('ticket not found')
      return 2
    }

    const fields = {
      replyType: 'reply-direct',
      isForwarding: undefined,
      to: to && (to?.length || 0) > 0 ? [to[0]] : draftDefaults.to,
      cc: null,
      bcc: null,
    }
    handleDraftChange(dispatch, draftType, draftId, ticketId, messageId, fields)

    return 0
  }
}

export const doDeleteDraftLocally = (ticketId, draftId) => (
  dispatch,
  getState
) => {
  if (updateDebounces[draftId]) clearTimeout(updateDebounces[draftId])
  if (sendDebounces[draftId]) clearTimeout(draftId[draftId])
  // eslint-disable-next-line import/no-named-as-default-member
  windowUnload.uninstall()

  const state = getState()
  const draftFolderIds = selectFolderIdsMatchingDraftForCurrentUser(state)
  const currentUserId = selectCurrentUserId(state)

  return dispatch({
    type: DELETE_DRAFT,
    payload: { draftId, ticketId },
    meta: {
      currentUserId,
      draftFolderIds,
    },
  })
}

export function doDeleteDraft(ticketId, draftId) {
  return dispatch => {
    dispatch(doDeleteDraftLocally(ticketId, draftId))
    dispatch(doDeleteDraftOnServer(ticketId, draftId))
  }
}

function doDeleteDraftOnServer(ticketId, draftId) {
  return (dispatch, getState) => {
    const state = getState()
    const mutation = `
      mutation DraftDelete(
        $draftId: ID!,
      ) {
        draftDelete( input: {
          draftId: $draftId,
        }) {
          success
        }
      }
    `

    const variables = {
      draftId,
    }

    const token = oauthTokenSelector(state)
    return appGraphql(token, mutation, variables)
  }
}

const sendDraftTimeout = 500
export function debouncedSendDraftToServer(dispatch, draftId, ticketId) {
  if (sendDebounces[draftId]) clearTimeout(sendDebounces[draftId])
  sendDebounces[draftId] = setTimeout(() => {
    dispatch(doSendDraftToServer(draftId, ticketId))
    delete sendDebounces[draftId]
  }, sendDraftTimeout)
}

export const doChangeDraft = (
  draftId,
  draftType,
  ticketId,
  messageId,
  fields
) => (dispatch, getState) => {
  // eslint-disable-next-line import/no-named-as-default-member
  windowUnload.install(
    'Your draft is not yet saved, closing this window may result in data loss'
  )
  let ticketDraftId = draftId

  if (!ticketDraftId) {
    // make doubly sure that ticket draftId has not been updated since this call
    // because the debouncedHandleDraftChange#updateDebounces could have been stored with no draftId value (new draft)
    // and at the time debouncedHandleDraftChange executes doChangeDraft, draft could have been created (say, by inserting a canned reply)
    ticketDraftId = selectDraftIdByTicketId(getState(), ticketId, draftType)
  }

  dispatch(
    doUpsertReplyDraft(ticketDraftId, draftType, ticketId, messageId, fields)
  )
  debouncedSendDraftToServer(dispatch, ticketDraftId, ticketId)
}

export function handleDraftChange(
  dispatch,
  draftType,
  draftId,
  ticketId,
  messageId,
  fields
) {
  return dispatch(
    doChangeDraft(draftId, draftType, ticketId, messageId, fields)
  )
}

export function debouncedHandleDraftChange(
  dispatch,
  draftType,
  draftId,
  ticketId,
  messageId,
  fields,
  oldBody
) {
  // eslint-disable-next-line import/no-named-as-default-member
  windowUnload.install(
    'Your draft is not yet saved, closing this window may result in data loss'
  )
  const key = draftId

  if (updateDebounces[key]) {
    clearTimeout(updateDebounces[key].timeout)
    updateDebounces[key].fields = { ...updateDebounces[key].fields, ...fields }
  } else {
    updateDebounces[key] = { fields }
  }

  updateDebounces[key].timeout = setTimeout(() => {
    const accumulatedFields = updateDebounces[key].fields
    delete updateDebounces[key]

    const hasOnlyBodyField =
      'body' in accumulatedFields && Object.keys(accumulatedFields).length === 1
    const isChanged =
      ![null, undefined].includes(oldBody) && hasOnlyBodyField
        ? oldBody !== accumulatedFields.body
        : true

    if (isChanged) {
      handleDraftChange(
        dispatch,
        draftType,
        draftId,
        ticketId,
        messageId,
        accumulatedFields
      )
      if (postUpdateCallbacks[key]) {
        postUpdateCallbacks[key].forEach(callback => callback())
        delete postUpdateCallbacks[key]
      }
    }
  }, 500)
}

const handleFailedMessageSend = dispatch => err => {
  dispatch(
    doShowSnackbar(
      'Oops. Your message could not be sent. Please try again in a moment'
    )
  )
  throw err
}

function isUndoable(draft, ticket) {
  if (!draft) return false
  if (draft.isForwarding && draft.isNote) return false
  if (isFacebook(ticket) || isTwitter(ticket)) return false
  return true
}

export function doSendReplyDraft(draftId, follow) {
  return async (dispatch, getState) => {
    if (updateDebounces[draftId]) {
      postUpdateCallbacks[draftId] = [
        () => dispatch(doSendReplyDraft(draftId, follow)),
      ]
      return
    }
    const state = getState()
    const rawDraft = selectDraftById(state, draftId)
    if (!rawDraft) return

    let ticketId = rawDraft.ticketId
    const isNewTicket = ticketId === 'new'

    const ticket = selectRawTicket(state, ticketId)

    // make conversions for compatibility with doSendChangeset
    const draft = { ...rawDraft }
    const agentsById = selectAgentsByIdIncludingArchived(state)
    const groupsById = selectGroupsById(state)
    draft.assignee = { id: draft.assigneeId }
    if (
      draft.assigneeId === null ||
      (draft.assigneeId && !agentsById[draft.assigneeId])
    )
      draft.assignee = null
    draft.assigned_group_id = draft.assigneeGroupId
    if (
      draft.assigneeGroupId === null ||
      (draft.assigneeGroupId && !groupsById[draft.assigneeGroupId])
    )
      draft.assigned_group_id = null
    // ensure that notes use the ticket state when not set
    let isNoteWithoutStateChange = false
    if (draft.isNote) {
      if (draft.state === undefined) draft.state = ticket.state
      if (draft.state === ticket.state) isNoteWithoutStateChange = true

      // Allows customers to create notes without specifying a customer. Might hide
      // this behind a preference in the future because its implementation is very hacky
      if (isNewTicket) {
        if (!draft.to) draft.to = [{ name: '' }]
        if (draft.phone_number) draft.to[0].phone_number = draft.phone_number
      }
    }
    if (ticket.isTwitterTicket && !draft.isNote) {
      draft.body = `${draft.twitterPrefix} ${draft.body}`
    }

    // for any reply except send & snooze, (i.e. send & close/open), we need to
    // explicitly clear the snooze too.
    if (!draft.snoozeUntil) draft.removeSnooze = true

    // we don't want to show snoozed tickets in the 'mine' inbox
    // we also have to add snoozedById so that it goes into 'snoozed' inbox
    if (draft.snoozeUntil) {
      const userId = selectCurrentUserId(state)
      draft.state = 'closed'
      draft.snoozedById = userId
    }

    // when saving notes without any state changes on a snoozed ticket, make sure we don't unsnooze
    if (isNoteWithoutStateChange && ticket.snoozedUntil) {
      draft.removeSnooze = null
    }

    draft.removeDraftId = rawDraft.id

    const { undo_send_delay: delaySeconds } = selectPreferences(state)
    const changesetId = uuidV4()
    const prefersUndo = delaySeconds
    const undoable = prefersUndo && isUndoable(draft, ticket)
    const nextTicketId = selectNextTicketIdInList(state) || null
    const classicView = selectPrefersClassicView(state)
    const isNote = draft.isNote

    let optimisticData

    if (draft.isNote) {
      optimisticData = notePayload(
        state,
        ticketId,
        draft,
        changesetId,
        selectCurrentFolderId(state),
        selectCurrentTicketSearchQueryObject(state)
      )
    } else {
      optimisticData = messagePayload(
        state,
        ticketId,
        draft,
        changesetId,
        selectCurrentFolderId(state),
        selectCurrentTicketSearchQueryObject(state)
      )
    }

    let action

    if (isNewTicket) {
      action = doSendChangeset(null, draft, { optimisticData, changesetId })
    } else {
      action = doSendChangeset(ticketId, draft, {
        optimisticData,
        changesetId,
        // GR: Our SEND_REPLY reducers will handle (optimistic) deleting, and
        // restoration of drafts (on failure). We dont want/need doSendChangeset
        // to delete drafts for us. I recommend deprecating preserveDraft.
        preserveDraft: true, // disable, so we have full control here.
        // we want control over when to auto-navigate away - chiefly we'd like
        // the user to see the undo send button before it auto-navs away.
        // GR: I also recommend deprecating this.
        // NOTE (mbunsch) this line, when set to true, completely wrecks auto-advance
        // I need to bring it back, especially that now undo send is in a toast
        disableNavigateToFolder: !!classicView, // NOTE (jscheel): Normally sending false, but classicView needs to send true
        disableRefreshPage: true,
      })
    }

    if (!draft.isNote) dispatch(doShowSnackbar('Sending your reply...'))
    dispatch(doDeleteDraftLocally(ticketId, draftId))
    dispatch(doUpdateEditorVisibility(false))
    // We only want to send the draft after its saving request is finished
    // to prevent saving after sending
    if (isPromise(saveRequests[draftId])) {
      // eslint-disable-next-line import/no-named-as-default-member
      windowUnload.install(
        `Your ${app.t(
          'ticket'
        )} is being saved. If you close the window, you might lose your changes`
      )
      await saveRequests[draftId]
    }
    delete saveRequests[draftId]

    const onComplete = () => {
      if (isNewTicket) {
        dispatch(doOpenTicketPage(ticketId))
        return
      }

      if (classicView) {
        const shouldOpenFolderOnReply = selectPrefersOpenFolderOnTicketReply(
          state
        )
        const autoAdvance = selectCurrentUserPrefersAutoadvanceToNextTicket(
          state
        )
        const shouldOpenFolderOnNote = selectPrefersOpenFolderOnNoteAdd(state)
        const shouldOpenFolder = isNote
          ? shouldOpenFolderOnNote
          : shouldOpenFolderOnReply
        const routeAction = selectCurrentTicketListRouteAction(state)
        if (shouldOpenFolder && autoAdvance && routeAction) {
          // NOTE (jscheel): Execute on next cycle so everything else runs before
          // we navigate away.
          runOnNextTick(() => {
            dispatch(routeAction)
          })
        } else if (isNote) {
          // Open ticket page like in the modern view if we don't open folder
          dispatch(doOpenTicketPage(ticketId))
        }
      } else if (isNote) {
        dispatch(doOpenTicketPage(ticketId))
      } else if (nextTicketId && !undoable) {
        dispatch(doAutoRedirect(ticketId, nextTicketId))
      }
    }

    dispatch(action)
      .then(res => {
        if (isNewTicket && res?.ticket?.id) {
          ticketId = res.ticket.id
        }
        if (!undoable && !draft.isNote) {
          dispatch(
            doShowSnackbar('Reply sent', {
              link: `/tickets/${ticketId}`,
              linkText: 'Go back to conversation',
            })
          )
        } else if (!draft.isNote) {
          runOnNextTick(() => {
            dispatch(startUndoTimer(changesetId, ticketId, draft))
          })
        }

        if (
          follow !== undefined &&
          selectShouldSendFollowRequest(state, follow)
        ) {
          const followActionOptions = {
            trackType: TRACK_TYPE_FOLLOW_TICKET_NOTE,
          }
          const followAction = follow
            ? doFollowTicketById(ticketId, followActionOptions)
            : doUnfollowTicketById(ticketId, followActionOptions)
          dispatch(followAction)
        }

        if (isNewTicket) {
          onComplete()
        }

        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch({
          type: FAILED_SEND,
          payload: { draftId, draft },
        })
        Bugsnag.notify(err, event => {
          // eslint-disable-next-line no-param-reassign
          event.groupingHash = 'doSendReplyDraft failed'
          event.addMetadata('metaData', {
            ticketId,
            draft,
            optimisticData,
            changesetId,
          })
        })
        // At this point, the request failed, so reopen the reply form
        if (ticketId && ticketId !== 'new') {
          dispatch(doOpenTicketPage(ticketId))
        }
        dispatch(doUpdateEditorVisibility(true))
        throw err
      })
      .catch(err => {
        handleFailedMessageSend(dispatch)(err)
      })

    if (!isNewTicket) {
      onComplete()
    }
  }
}

export function doSearchRecipients(term) {
  return dispatch => {
    dispatch(doClearUserSuggestion())

    if (!term || term.length < MIN_RECIPIENT_QUERY_LENGTH) {
      return Promise.resolve()
    }

    return dispatch(doSearchUsers(term))
  }
}

export function doStartRecipientSyncSearch(source) {
  return {
    type: DRAFT_RECIPIENT_SYNC_STARTED,
    payload: {
      source,
    },
  }
}

export function doFinishRecipientSyncSearch() {
  return {
    type: DRAFT_RECIPIENT_SYNC_FINISHED,
    payload: {},
  }
}

export function doSyncDraftWithSearchRecipients(
  draftType,
  ticketId,
  searchRecipients = []
) {
  return (dispatch, getState) => {
    const state = getState()
    const draftId = selectDraftIdByTicketId(state, ticketId, draftType)
    const draft = selectDraftById(state, draftId)
    const updates = {}
    const searchRecipientsByEmail = searchRecipients.reduce(
      (result, recipient) => {
        if (recipient.email) {
          // eslint-disable-next-line no-param-reassign
          result[recipient.email] = recipient
        }
        return result
      },
      {}
    )
    ;['to', 'cc', 'bcc'].forEach(recipientType => {
      const draftRecipients = draft[recipientType] || []
      const syncedRecipients = []
      let changed = false
      draftRecipients.forEach(draftRecipient => {
        const searchRecipient = searchRecipientsByEmail[draftRecipient.email]
        if (searchRecipient && searchRecipient.email === draftRecipient.email) {
          syncedRecipients.push({
            ...searchRecipient,
            name: draftRecipient.name || searchRecipient.name,
          })
          changed = true
        } else {
          syncedRecipients.push(draftRecipient)
        }
      })
      if (changed) {
        updates[recipientType] = syncedRecipients
      }
    })
    if (Object.keys(updates).length > 0) {
      dispatch(doChangeDraft(draftId, draftType, ticketId, null, updates))
    }
  }
}
