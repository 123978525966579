import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'

import { selectBase } from './selectBase'

export const selectStreamingResults = createBasicSelector(
  selectBase,
  base => base.streamingResults || emptyObj
)

export const selectDraftReplyTextStreamingResult = createBasicSelector(
  selectStreamingResults,
  streamingResults => streamingResults.draftReplyText || ''
)

export const selectDraftReplyConversationIdStreamingResult = createBasicSelector(
  selectStreamingResults,
  streamingResults => streamingResults.draftReplyConversationId || null
)

export const selectDraftReplySourcesStreamingResult = createBasicSelector(
  selectStreamingResults,
  streamingResults => streamingResults.draftReplySources || emptyArr
)
