import debug from 'util/debug'
import { getFormattedPrice } from 'util/numbers'
import { ALLOWED_EXPIRED_BILLING } from 'constants/billing'
import {
  BILLING_CYCLE_ALL,
  BILLING_CYCLE_ANNUAL,
  BILLING_CYCLE_MONTHLY,
  PRICING_MODEL_FLAT,
  PRICING_MODEL_USAGE,
} from './constants'
import { ADDON, INBOX, KB } from './productTypes'

export function isLimitsExceeded(availableFeatures, usage) {
  const featureLookup = Object.keys(availableFeatures)
  return Object.keys(usage).some(key => {
    const usageValue = usage[key]
    // Eg custom_profile, rules
    if (
      featureLookup.includes(key) &&
      typeof usageValue === 'boolean' &&
      usageValue &&
      !availableFeatures[key]
    ) {
      return true
    }

    // Eg teams
    if (
      featureLookup.includes(key) &&
      typeof usageValue === 'number' &&
      usageValue > 0 &&
      !availableFeatures[key]
    ) {
      return true
    }

    if (
      featureLookup.includes(key) &&
      typeof usageValue === 'number' &&
      availableFeatures[key] !== 'unlimited' &&
      ((typeof availableFeatures[key] === 'boolean' &&
        !availableFeatures[key] &&
        usageValue > 0) ||
        (typeof availableFeatures[key] === 'number' &&
          usageValue > availableFeatures[key]))
    ) {
      return true
    }
    return false
  })
}

export function isAllowedAccessToFeature(plan, feature) {
  if (!plan || !plan.features || !feature) return true
  return !!plan.features[feature]
}

export function isPricingSmaller(currentPlan, newPlan) {
  if (currentPlan.version !== newPlan.version) return false
  return currentPlan.monthly >= newPlan.monthly
}

export const buildLineItemFromPricing = (
  pricing,
  billingCycle,
  quantity = 1,
  discount = null
) => {
  const { minimumQuantity, pricingModel } = pricing
  const { coupon: { percentOff = 0 } = {} } = discount || {}
  // Flips the discount around so that we can caclulate the cyclePrice
  // and itemPrice by just multiplying the numbers with this rate
  const discountRate = percentOff === 0 ? 1 : (100 - percentOff) / 100

  let cyclePrice =
    billingCycle === BILLING_CYCLE_ANNUAL ? pricing.annual : pricing.monthly

  if (pricingModel !== PRICING_MODEL_FLAT) {
    cyclePrice *= Math.max(minimumQuantity, quantity)
  }

  const itemPrice =
    billingCycle === BILLING_CYCLE_ANNUAL ? pricing.annual : pricing.monthly

  const monthlyItemPrice =
    billingCycle === BILLING_CYCLE_ANNUAL
      ? pricing.annual / 12
      : pricing.monthly

  const cycleDiscountPrice = cyclePrice * discountRate
  const itemDiscountPrice = itemPrice * discountRate
  const monthlyItemDiscountPrice = monthlyItemPrice * discountRate

  return {
    ...pricing,
    cyclePrice,
    cycleDiscountPrice,
    itemPrice,
    itemDiscountPrice,
    minimumQuantity,
    quantity,
    monthlyItemDiscountPrice,
    percentOff,
  }
}

export const sortPricings = pricings => {
  return pricings.sort((a, b) => {
    // Assign a numerical priority to each type.
    const order = { INBOX: 1, KB: 2, ADDON: 3 }

    // Get the priority for each type.
    const priorityA = order[a.type]
    const priorityB = order[b.type]

    // Compare the priorities to determine the order.
    if (priorityA < priorityB) {
      return -1 // 'a' comes before 'b'
    } else if (priorityA > priorityB) {
      return 1 // 'a' comes after 'b'
    }
    return 0 // 'a' and 'b' are equal in order
  })
}

// Note mirror algorithm in provider.rb quantity_for
export const quantityFor = (pricing, pricings, usage) => {
  const { usageFrom, pricingModel, features, minimumQuantity = 1 } = pricing

  if (pricingModel === PRICING_MODEL_FLAT) {
    return 1
  }

  const maxQuantity = features.find(f => f.key === usageFrom)?.value
  const productQuantity = usage[usageFrom]

  if (pricing.type !== ADDON) {
    if (productQuantity < minimumQuantity) {
      return minimumQuantity
    }
    if (maxQuantity !== 'unlimited' && productQuantity > maxQuantity) {
      return maxQuantity
    }
    return productQuantity
  }

  const basePricings = pricings.filter(
    p => p.id !== pricing.id && p.type !== ADDON
  )
  let availableBaseQuantity = 0

  basePricings.forEach(p => {
    if (availableBaseQuantity !== 'unlimited') {
      const pricingQuantity =
        p.features.find(f => f.key === usageFrom)?.value || 0
      if (pricingQuantity === 'unlimited') {
        availableBaseQuantity = pricingQuantity
      } else {
        availableBaseQuantity += pricingQuantity
      }
    }
  })

  const addonUsage =
    availableBaseQuantity === 'unlimited'
      ? 0
      : productQuantity - availableBaseQuantity
  const addonQuantity = Math.floor(addonUsage / maxQuantity)

  return addonQuantity
}

export const buildLineItemsFromPricings = ({
  pricings,
  billingCycle,
  usage,
  discount,
}) => {
  return sortPricings(pricings)
    .map(pricing => {
      const quantity = quantityFor(pricing, pricings, usage)
      return buildLineItemFromPricing(pricing, billingCycle, quantity, discount)
    })
    .filter(item => {
      return [INBOX, KB].includes(item.type) || item.cyclePrice > 0
    })
}

export const calculateSubscriptionFlatTotal = (planLineItems = []) => {
  return planLineItems
    .filter(r => r.pricingModel === PRICING_MODEL_FLAT)
    .reduce((total, row) => {
      // eslint-disable-next-line no-param-reassign
      total += row.cyclePrice
      return total
    }, 0)
}

export const calculateSubscriptionUsageTotal = (planLineItems = []) => {
  return planLineItems
    .filter(r => r.pricingModel === PRICING_MODEL_USAGE)
    .reduce((total, row) => {
      // eslint-disable-next-line no-param-reassign
      total += row.cyclePrice
      return total
    }, 0)
}

export const calculateSubscriptionMaxUsageAmount = (planLineItems = []) => {
  return calculateSubscriptionUsageTotal(planLineItems) * 100
}

const splitPlanIntoVersionAndPlan = plan => {
  if (!plan) return { version: 1, plan: '' }
  const matches = /v(\d+)-(.*)/g.exec(plan.value)
  if (!matches) return { version: 1, plan: plan.value }
  const [, version, planPart] = matches
  return {
    version: parseInt(version, 10),
    plan: planPart,
  }
}

export const isVersionUpgrade = (newPlan, currentPlan) => {
  const {
    version: newVersion,
    plan: newPlanPart,
  } = splitPlanIntoVersionAndPlan(newPlan)
  const {
    version: currentVersion,
    plan: currentPlanPart,
  } = splitPlanIntoVersionAndPlan(currentPlan)
  if (newVersion > currentVersion && newPlanPart === currentPlanPart)
    return true
  return false
}

export const getPlanSupportedCycles = plan => {
  if (!plan) return BILLING_CYCLE_ALL

  const pricing = plan.pricing
  if (
    // If we cant find the plan info, assume it supports monthly and annual
    !pricing ||
    // If the plan is completely free, then it supports both annual and monthly (kb-basic)
    (pricing.monthly === 0 && pricing.annual === 0) ||
    // If it has a month and annual price, then it supports both annual and monthly
    (pricing.monthly !== 0 && pricing.annual !== 0)
  )
    return BILLING_CYCLE_ALL
  if (pricing.monthly === 0) return BILLING_CYCLE_ANNUAL
  return BILLING_CYCLE_MONTHLY
}

export const isVersionSmaller = (version = '', targetVersion = '') => {
  const getVersionNumber = v => Number(v.split('v').filter(Boolean)[0])
  return getVersionNumber(version) < getVersionNumber(targetVersion)
}

export const isInboxPlanVersionSmallerThan18 = version =>
  version && isVersionSmaller(version, 'v18')

export const pricingIdToPlan = pricingId => {
  return pricingId.replace(/(kb-|inbox-|-annual)/g, '')
}

// Deprecated, you should use the plan/pricing id to lookup the pricing
// and pull the components from that
export const planIdToComponents = inputPlanId => {
  const parts = inputPlanId.split('-')
  const product = parts.shift()
  let cycle = BILLING_CYCLE_MONTHLY

  if (parts[parts.length - 1] === BILLING_CYCLE_ANNUAL) {
    cycle = parts.pop()
  }

  const planId = parts.join('-')
  const pricingId = `${product}-${planId}`

  return {
    product,
    planId,
    cycle,
    pricingId,
  }
}

export const isBillingUpgrade = (
  currentPlan,
  newPlan,
  currentBillingCycle,
  newBillingCycle
) => {
  if (
    currentBillingCycle === BILLING_CYCLE_ANNUAL &&
    newBillingCycle === BILLING_CYCLE_MONTHLY
  )
    return false
  if (
    currentBillingCycle === BILLING_CYCLE_MONTHLY &&
    newBillingCycle === BILLING_CYCLE_ANNUAL
  )
    return true
  if (newPlan.id === currentPlan.id) return false
  if (newPlan.monthly < currentPlan.monthly) return false

  return true
}

export const calculatePerSecondRate = (cycleMonthlyPrice, month) => {
  // Define the number of days in each month.
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  // Check for leap year for February.
  const now = new Date()
  const year = now.getFullYear()
  const leapYearCheck = new Date(year, 1, 29).getMonth() === 1 // February is month 1 in JavaScript date
  if (leapYearCheck) {
    daysInMonth[1] = 29
  }

  // Calculate the number of seconds in the month.
  const secondsInMonth = daysInMonth[month] * 24 * 60 * 60

  // Calculate the per second price.
  const perSecondPrice = cycleMonthlyPrice / secondsInMonth

  return perSecondPrice
}

export const calculateProrataAmount = (
  cycleMonthlyPrice,
  startTimestamp,
  endTimestamp
) => {
  let totalCharge = 0
  let currentTimestamp = startTimestamp

  // Loop through each month in the period.
  while (currentTimestamp < endTimestamp) {
    const currentDate = new Date(currentTimestamp)
    const currentMonth = currentDate.getMonth()
    const nextMonthTimestamp = new Date(
      currentDate.getFullYear(),
      currentMonth + 1,
      1
    ).getTime()

    let periodEndTimestamp
    // Check if the period ends in the current month or in a later month.
    if (nextMonthTimestamp > endTimestamp) {
      periodEndTimestamp = endTimestamp
    } else {
      periodEndTimestamp = nextMonthTimestamp
    }

    // Calculate the number of seconds in this part of the period.
    const secondsInPeriod = (periodEndTimestamp - currentTimestamp) / 1000

    // Calculate the charge for this part of the period.

    const perSecondRate = calculatePerSecondRate(
      cycleMonthlyPrice,
      currentMonth
    )
    totalCharge += perSecondRate * secondsInPeriod

    // Move to the next month.
    currentTimestamp = nextMonthTimestamp
  }

  return totalCharge
}

export const getPastTimestamp = (timestamp, period) => {
  const date = new Date(timestamp)

  switch (period) {
    case BILLING_CYCLE_MONTHLY:
      date.setMonth(date.getMonth() - 1)
      break
    case BILLING_CYCLE_ANNUAL:
      date.setFullYear(date.getFullYear() - 1)
      break
    default:
      // eslint-disable-next-line no-console
      console.error('Invalid period')
      return null
  }

  return date.getTime()
}

export const getFutureTimestamp = (timestamp, period) => {
  const date = new Date(timestamp)

  switch (period) {
    case BILLING_CYCLE_MONTHLY:
      date.setMonth(date.getMonth() + 1)
      break
    case BILLING_CYCLE_ANNUAL:
      date.setFullYear(date.getFullYear() + 1)
      break
    default:
      // eslint-disable-next-line no-console
      console.error('Invalid period')
      return null
  }

  return date.getTime()
}

export const getFeatureValueForPricingGrid = (
  featureId,
  features,
  planId,
  minimumQuantity
) => {
  if (featureId === 'minimumQuantity') {
    return minimumQuantity
  } else if (featureId === 'maxKbs' && planId === 'v18-free') {
    return 0
  } else if (featureId === 'maxKbs' && planId.startsWith('v18')) {
    return 'unlimited'
  }

  const feature = features.find(f => f.key === featureId)
  if (!feature) {
    debug('getFeatureValueForPricingGrid:missing', {
      features,
      featureId,
    })
  }

  return feature?.value
}

export const featureArrayToFeatureLookup = features => {
  return features.reduce((featureObj, item) => {
    // eslint-disable-next-line no-param-reassign
    featureObj[item.key] = item.value
    return featureObj
  }, {})
}

export const buildPricingInfo = (
  pricings,
  accountBillingVersionByType,
  billingCycle,
  usage,
  discount
) => {
  return pricings.map(pricing => {
    const preferredVersion = accountBillingVersionByType[pricing.type]
    const isLegacy =
      ![null, undefined].includes(preferredVersion) &&
      pricing.version !== preferredVersion
    const displayName = `${isLegacy ? 'Legacy' : ''} ${pricing.name}`.trim()

    const lineItem = buildLineItemFromPricing(
      pricing,
      billingCycle,
      quantityFor(pricing, pricings, usage),
      discount
    )

    return {
      displayName,
      pricing,
      isLegacy,
      lineItem,
      billingCycle,
    }
  })
}

const IGNORED_ADDON_KEYS = ['widgets']

export const getAddonInfoForFeature = ({
  key,
  name,
  addons,
  billingCycle,
  value,
  addonPricingIds,
}) => {
  if (IGNORED_ADDON_KEYS.includes(key)) return null
  if (['unlimited', true, false, undefined].includes(value)) return null
  if (!addonPricingIds.length) return null
  const addon = addons.find(ad =>
    ad.features.map(feature => feature.key).includes(key)
  )
  if (
    !addon ||
    addon.monthlyPrice === 0 ||
    !addonPricingIds.includes(addon.id)
  ) {
    return null
  }
  const { annual, monthly, features } = addon
  const lowercasedName = name.toLowerCase()
  const featureValue = features.find(feature => feature.key === key).value
  const count = featureValue === 1 ? 'each' : featureValue
  const price = billingCycle === BILLING_CYCLE_ANNUAL ? annual / 12 : monthly
  const formattedPrice = getFormattedPrice(price, 'USD')
  if (count === 'each') {
    return `Additional ${lowercasedName} are ${formattedPrice} ${count}/month`
  }
  return `Add an extra ${count} ${lowercasedName} for ${formattedPrice} per month`
}

export const isFeatureHiddenInPlanCards = (pricings, featureId) => {
  return pricings.every(pricing =>
    [0, false].includes(
      getFeatureValueForPricingGrid(featureId, pricing.features, pricing.id)
    )
  )
}

export const isPricingSupported = (pricing, newPricings) => {
  if ([INBOX, KB].includes(pricing.type)) return true

  // HACK: For the kb unlimited plan. For migration purposes we created a special kb unlimited
  // plan for customers. This plan however is not added as a supported addon on the v21 plans.
  // The system will only return addon-v2-kb-unlimited as a potential plan if its already on the
  // customers account. When that happens we'll prefer choosing the unlimited plan over the
  // normal addon plan.
  if (
    pricing.id === 'addon-v2-kb' &&
    newPricings.some(p => p.id === 'addon-v2-kb-unlimited')
  ) {
    return false
  } else if (pricing.id === 'addon-v2-kb-unlimited') {
    return true
  }

  // This basically means we're not looking at an addon pricing. Now we'll check
  // the other pricing to see if any of them add support for this addon

  return newPricings.some(p => p.addonPricingIds.includes(pricing.id))
}

export const sortDiscounts = discounts => {
  // Define a custom order for types
  const typeOrder = {
    SUBSCRIPTION: 1,
    ACCOUNT: 2,
  }

  // Custom comparator function for sorting
  function compare(a, b) {
    // Compare by type
    if (typeOrder[a.type] < typeOrder[b.type]) return -1
    if (typeOrder[a.type] > typeOrder[b.type]) return 1

    // If types are the same, compare by createdAt date
    const dateA = new Date(a.coupon.createdAt)
    const dateB = new Date(b.coupon.createdAt)
    return dateB - dateA // Descending order
  }

  // Sort the discounts using the custom comparator
  return discounts.sort(compare)
}

export const discountMessageFor = coupon => {
  if (!coupon) return ''

  let textDiscount
  let textDuration

  const { percentOff, amountOff, duration, durationInMonths } = coupon
  if (percentOff) {
    textDiscount = `${percentOff}%`
  } else if (amountOff) {
    textDiscount = `$${amountOff / 100}`
  } else {
    return ''
  }
  if (duration === 'FOREVER') {
    textDuration = 'forever'
  } else if (duration === 'ONCE') {
    textDuration = 'once'
  } else if (durationInMonths) {
    textDuration = `for ${durationInMonths} months`
  } else {
    return ''
  }

  return `${textDiscount} off ${textDuration}`
}

// We need to check if the page is allowed for expired billing
// If there is a tab, we need to check the tab as well
export const isPageAllowedForExpiredBilling = to => {
  return ALLOWED_EXPIRED_BILLING.some(allowedPage => {
    if (typeof allowedPage === 'string') {
      return to.type === allowedPage
    }
    return (
      to.type === allowedPage.type &&
      to.payload?.tab === allowedPage.payload.tab
    )
  })
}
