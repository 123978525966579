import { FETCH_INSTALLS_REQUEST, FETCH_INSTALLS_SUCCESS } from '../types'

const initialState = { byOwnerId: {}, loading: false }

export default function reduceInstalls(
  state = initialState,
  { type, payload: { installs } = {} }
) {
  if (type === FETCH_INSTALLS_REQUEST) return { ...state, loading: true }
  if (type === FETCH_INSTALLS_SUCCESS) {
    return {
      byOwnerId: installs.reduce((result, install) => {
        // eslint-disable-next-line no-param-reassign
        result[install.account.node_id] = install
        return result
      }, {}),
      loading: false,
    }
  }
  return state
}
