import { doSendChangeset } from 'actions/tickets/changeset/doSendChangeset'
import { deleteMessagePayload, updateMessagePayload } from 'optimistic/message'
import { selectCurrentFolderId } from 'selectors/app'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { v4 as uuidV4 } from 'uuid'

export const doDeleteNote = input => (dispatch, getState) => {
  const { ticketId, commentId } = input

  const state = getState()
  const changesetId = uuidV4()
  const optimisticData = deleteMessagePayload(
    state,
    ticketId,
    commentId,
    selectCurrentFolderId(state),
    selectLatestTicketSearchQueryObject(state),
    changesetId
  )

  return dispatch(
    doSendChangeset(
      ticketId,
      { deleteCommentId: commentId },
      { optimisticData, disableNavigateToFolder: true }
    )
  )
}

export const doUpdateNote = input => (dispatch, getState) => {
  const { ticketId, commentId, body, previousBody } = input

  const state = getState()
  const changesetId = uuidV4()
  const optimisticData = updateMessagePayload(
    state,
    ticketId,
    commentId,
    body,
    previousBody,
    selectCurrentFolderId(state),
    selectLatestTicketSearchQueryObject(state),
    changesetId
  )

  return dispatch(
    doSendChangeset(
      ticketId,
      { updateCommentId: commentId, body, isNote: true },
      { optimisticData, disableNavigateToFolder: true }
    )
  )
}
