import { oauthTokenSelector } from 'selectors/app/base'
import {
  doGraphqlRequest,
  doAppGraphqlRequest,
  doSdkRequest,
} from 'ducks/requests/operations'
import navigateToLegacy from 'util/navigateToLegacy'
import { selectAccountSubdomain } from 'selectors/app'
import OAuthWindow from 'util/oauth_window'
import { doOpenDrawer } from 'ducks/drawers/actions'
import { DRAWER_TYPE_BILLING_CONFIRM } from 'ducks/drawers/types'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import { invoice } from 'ducks/entities/schema'
import { wait } from 'util/promises'
import { doClearFlag } from 'ducks/flags/operations'
import {
  basicFields,
  EXTENDED_BILLING_FIELDS_V1,
  BILLING_DATA_QUERY_V2,
  fetchBillingInformation,
  BILLING_INFORMATION_FIELDS,
  INVOICES_DATA_QUERY,
  BILLING_DATA_QUERY_V1,
  BILLING_SETUP_INTENTS_QUERY,
  BILLING_CREATE_SETUP_INTENT_MUTATION,
  BILLING_CARD_SETUP_INTENT_SUCCEED_MUTATION,
  UPCOMING_INVOICE_FIELDS,
} from '../api'
import {
  START_TRIAL,
  UPDATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  FETCH_BILLING_DATA_V1,
  FETCH_BILLING_DATA_V2,
  FETCH_BILLING_SETUP_INTENTS,
  FETCH_BILLING_INVOICES,
  FETCH_BILLING_INFORMATION_REQUEST,
  FETCH_BILLING_INFORMATION_SUCCESS,
  FETCH_BILLING_INFORMATION_FAILED,
  UPDATE_BILLING_INFORMATION,
  GET_INVOICE_PDF_LINKS,
  GET_INVOICE_PREVIEW,
  SHOPIFY_BILLING_CONFIRMED,
  CANCEL_DOWNGRADE,
  UPDATE_SUBSCRIPTION_REQUEST,
  CANCEL_DOWNGRADE_REQUEST,
  CREATE_BILLING_SETUP_INTENT,
  SAVE_BILLING_SETUP_INTENT_CONFIRMED,
} from '../types'
import { selectBillingPendingSubscription } from '../selectors'
import { selectBillingProviderSettings } from '../selectors/selectBillingProviderSettings'
import { ACTIVE_PLAN_STATES } from '../constants'
import {
  billingDataSchema,
  createSetupIntentSchema,
  setupIntentsSchema,
  cardSetupIntentSucceedSchema,
} from '../schema'
import { selectFuturePhaseNoticeBarFlag } from '../selectors/selectFuturePhaseNoticeBarFlag'

export const doFetchBillingData = () => async dispatch => {
  await dispatch(
    doGraphqlRequest(
      FETCH_BILLING_DATA_V1,
      BILLING_DATA_QUERY_V1,
      {},
      {
        includeLegacyPayload: true,
      }
    )
  )
  const billingDataV2 = await dispatch(
    doGraphqlRequest(
      FETCH_BILLING_DATA_V2,
      BILLING_DATA_QUERY_V2,
      {},
      {
        normalizationSchema: billingDataSchema,
        includeLegacyPayload: true,
        app: true,
        moduleOptions: {
          entities: {
            additionalActions: [
              {
                entityType: 'discount',
                stores: ['current', 'pending'],
                phases: ['SUCCESS'],
                type: 'clear',
              },
              {
                entityType: 'coupon',
                stores: ['current', 'pending'],
                phases: ['SUCCESS'],
                type: 'clear',
              },
              {
                entityType: 'feature',
                stores: ['current', 'pending'],
                phases: ['SUCCESS'],
                type: 'clear',
              },
              {
                entityType: 'usage',
                stores: ['current', 'pending'],
                phases: ['SUCCESS'],
                type: 'clear',
              },
              {
                entityType: 'plan',
                stores: ['current', 'pending'],
                phases: ['SUCCESS'],
                type: 'clear',
              },
            ],
          },
        },
      }
    )
  )
  return billingDataV2
}

const LOAD_ALL_INVOICES_QUERYID = 'entityType:invoice pageSize:10000'

export const doFetchInvoices = ({ skipLoaded } = {}) => (
  dispatch,
  getState
) => {
  const queryId = LOAD_ALL_INVOICES_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  return dispatch(
    doGraphqlRequest(
      FETCH_BILLING_INVOICES,
      INVOICES_DATA_QUERY,
      {},
      {
        transformResponse: res => {
          // Filter out trial invoices
          return res.invoices.filter(inv => {
            if (inv.total > 0) return true
            const isTrial = inv.lines?.some(line =>
              line.description?.toLowerCase().includes('trial')
            )
            return !isTrial
          })
        },
        normalizationSchema: [invoice],
        searches: {
          queryId,
          cursor,
          extractPagination: data => {
            return {
              type: 'invoices',
              nodes: data,
              startCursor: 1,
              endCursor: 1,
              hasNextPage: false,
              hasPreviousPage: false,
              totalCount: data.length,
              totalPageCount: 1,
            }
          },
        },
      }
    )
  )
}

export const doFetchBillingInformation = () => {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    dispatch({ type: FETCH_BILLING_INFORMATION_REQUEST })

    return fetchBillingInformation(token)
      .then(data => {
        dispatch({ type: FETCH_BILLING_INFORMATION_SUCCESS, payload: { data } })
      })
      .catch(err => {
        dispatch({
          type: FETCH_BILLING_INFORMATION_FAILED,
          payload: { error: err },
        })
      })
  }
}

const cancelSubscriptionMutation = `
  mutation CancelSubscription($input: [CancellationReason!]!) {
    cancelSubscription(input: $input) {
      ${EXTENDED_BILLING_FIELDS_V1}
    }
  }
`

export const doCancelSubscription = cancellationReasons => {
  return dispatch => {
    dispatch(
      doGraphqlRequest(CANCEL_SUBSCRIPTION, cancelSubscriptionMutation, {
        input: cancellationReasons,
      })
    )
  }
}

const updateSubscriptionMutation = `
  mutation UpdateSubscription(
    $pricingIds: [String]
    $cycle: String!
    $convertTrials: Boolean!
    $legacy: Boolean!
    $prorate: Boolean
    $scheduleChanges: Boolean
  ) {
    updateSubscription(
      pricingIds: $pricingIds,
      cycle: $cycle,
      convertTrials: $convertTrials,
      legacy: $legacy,
      prorate: $prorate,
      scheduleChanges: $scheduleChanges
    ) {
      ${EXTENDED_BILLING_FIELDS_V1}
    }
  }
`

export const doUpdateSubscription = (billing, options = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const { requireExternalApproval } = selectBillingProviderSettings(state)

  const flagName = selectFuturePhaseNoticeBarFlag(state)
  if (flagName) {
    dispatch(doClearFlag(flagName))
  }

  const {
    successMessage,
    failed,
    successMessageDisabled,
    convertTrials = true,
    meta = {},
  } =
    options || {}

  return dispatch(
    doSdkRequest(
      UPDATE_SUBSCRIPTION,
      async () => {
        const updateSubscriptionResponse = await dispatch(
          doGraphqlRequest(
            UPDATE_SUBSCRIPTION_REQUEST,
            updateSubscriptionMutation,
            {
              convertTrials,
              legacy: false,
              ...billing,
            },
            {
              throwOnError: true,
            }
          )
        )
        await dispatch(doFetchBillingData())
        return updateSubscriptionResponse
      },
      {},
      {
        throwOnError: true,
        meta,
        moduleOptions: {
          toasts: {
            enabled: !requireExternalApproval,
            started: {
              enabled: false,
            },
            success: {
              enabled: !successMessageDisabled,
              content: successMessage || 'Subscription updated',
            },
            failed: {
              enabled: true,
              content: failed || 'Subscription update failed',
              onClickAction: () =>
                dispatch(doUpdateSubscription(billing, options)),
            },
          },
        },
      }
    )
  )
}

const startTrialMutation = `
  mutation StartTrial(
    $product: String!,
  ) {
    startTrial(
      product: $product
    ) {
      ${basicFields}
    }
  }
`

export function doStartTrial(product) {
  return dispatch => {
    const variables = { product }
    return dispatch(
      doGraphqlRequest(START_TRIAL, startTrialMutation, variables, {
        meta: { product },
        transformResponse: data => data.startTrial,
      })
    )
  }
}

export const doRedirectToLegacyBilling = () => (dispatch, getState) => {
  const state = getState()
  const subdomain = selectAccountSubdomain(state)
  const token = oauthTokenSelector(state)

  if (subdomain === undefined) return null

  return navigateToLegacy(token, subdomain, 'settings/billing')
}

const updateBillingInformationMutation = `
  mutation UpdateBillingInformation(
    $input: BillingInformationInput!
  ){
    updateBillingInformation(
      input: $input
    ) {
      ${BILLING_INFORMATION_FIELDS}
    }
  }
`

export const doUpdateBillingInformation = params => dispatch => {
  return dispatch(
    doGraphqlRequest(
      UPDATE_BILLING_INFORMATION,
      updateBillingInformationMutation,
      { input: params },
      {
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Information changes saved',
            },
            failed: {
              content: 'Information changes failed',
              onClickAction: () => dispatch(doUpdateBillingInformation(params)),
            },
          },
        },
      }
    )
  )
}

const getInvoicePdfLinksMutation = `
  mutation GetPdfLinksForInvoice(
    $invoiceId: String
  ) {
    getPdfLinksForInvoice(
      invoiceId: $invoiceId
    ) {
      invoicePdf
      receiptPdf
    }
  }
`

export const doGetInvoicePdfLinks = invoiceId => {
  return dispatch => {
    return dispatch(
      doGraphqlRequest(GET_INVOICE_PDF_LINKS, getInvoicePdfLinksMutation, {
        invoiceId,
      })
    )
  }
}

const getInvoicePreviewQuery = `
  query GetInvoicePreview(
    $pricingIds: [String]
    $cycle: String
    $coupon: String
  ) {
    invoicePreview(
      pricingIds: $pricingIds
      cycle: $cycle
      coupon: $coupon
    ) {
      ${UPCOMING_INVOICE_FIELDS}
    }
  }
`

export const doGetInvoicePreview = ({ pricingIds, cycle, coupon }) => {
  return dispatch => {
    return dispatch(
      doGraphqlRequest(GET_INVOICE_PREVIEW, getInvoicePreviewQuery, {
        pricingIds,
        cycle,
        coupon,
      })
    )
  }
}

export const doConfirmShopifyActiveSubscription = () => async dispatch => {
  let attempt = 0

  while (attempt <= 30) {
    attempt += 1

    const {
      billing: {
        pendingSubscription: { approvalUrl } = {},
        inbox: { state: inboxState } = {},
      } = {},
    } =
      // eslint-disable-next-line no-await-in-loop
      (await dispatch(doFetchBillingData())) || {}

    // When there is a paid or trial subscription we can continue
    if (ACTIVE_PLAN_STATES.includes(inboxState)) {
      dispatch({ type: SHOPIFY_BILLING_CONFIRMED, payload: {} })
      return true
    }

    // If there is still a pending subscrion, then it means we're still
    // waiting for the webhook to arrive
    if (approvalUrl) {
      // eslint-disable-next-line no-await-in-loop
      await wait(2000)
    } else {
      // If there is no pendingSubscription it means the customer most likely hit cancel
      // on the shopify side
      break
    }
  }
  // Not really my first choice, but this is inline with what the oauth window currently does
  // eslint-disable-next-line no-throw-literal
  throw 'SUBSCRIPTION_NOT_ACTIVE'
}

export const doExternalApprovePendingSubscription = (options = {}) => (
  dispatch,
  getState
) => {
  const {
    oAuthWindowOptions = { width: 600, height: 800 },
    waitForApproval = false,
    redirectFlow = true,
  } = options
  const state = getState()
  const { approvalUrl: url } = selectBillingPendingSubscription(state)

  // If there is no pending subscription, then just assume its already been approved
  if (!url) return Promise.resolve()

  if (redirectFlow) {
    window.location = url
    return Promise.resolve()
  }

  const oAuthFlow = new OAuthWindow({
    title: 'Add Shopify Store',
    url,
    ...oAuthWindowOptions,
  })

  return oAuthFlow
    .start()
    .then(() => {
      if (waitForApproval) {
        return dispatch(doConfirmShopifyActiveSubscription())
      }
      return dispatch(
        doOpenDrawer(
          DRAWER_TYPE_BILLING_CONFIRM,
          DRAWER_TYPE_BILLING_CONFIRM,
          '',
          options
        )
      )
    })
    .catch(err => {
      oAuthFlow.cancel()
      throw err
    })
}

export const doFetchInvoicesInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_INVOICES_QUERYID,
  entityType: 'invoice',
  doLoadAllFn: doFetchInvoices,
})

const cancelDowngradeMutation = `
mutation CancelDowngrade {
  cancelDowngrade(input: {}) {
    errors {
      message
      path
    }
  }
}
`

export const doCancelDowngrade = (options = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()

  const flagName = selectFuturePhaseNoticeBarFlag(state)
  if (flagName) {
    dispatch(doClearFlag(flagName))
  }

  await dispatch(
    doSdkRequest(
      CANCEL_DOWNGRADE,
      async () => {
        const cancelDowngradeResponse = await dispatch(
          doAppGraphqlRequest(
            CANCEL_DOWNGRADE_REQUEST,
            cancelDowngradeMutation,
            {},
            {
              ...options,
              throwOnError: true,
            }
          )
        )
        await dispatch(doFetchBillingData())
        return cancelDowngradeResponse
      },
      {},
      {
        throwOnError: true,
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Cancelled downgrade successfully',
            },
            failed: {
              enabled: true,
              content: 'Cancel downgrade failed',
              onClickAction: () => dispatch(doCancelDowngrade(options)),
            },
          },
        },
      }
    )
  )

  return true
}

export const doFetchSetupIntents = () => async dispatch => {
  return dispatch(
    doGraphqlRequest(
      FETCH_BILLING_SETUP_INTENTS,
      BILLING_SETUP_INTENTS_QUERY,
      {},
      {
        normalizationSchema: setupIntentsSchema,
        app: true,
        moduleOptions: {
          entities: {
            additionalActions: [
              {
                entityType: 'setupIntent',
                stores: ['current', 'pending'],
                phases: ['SUCCESS'],
                type: 'clear',
              },
            ],
          },
        },
      }
    )
  )
}

export const doCreateSetupIntent = () => async dispatch => {
  return dispatch(
    doGraphqlRequest(
      CREATE_BILLING_SETUP_INTENT,
      BILLING_CREATE_SETUP_INTENT_MUTATION,
      {},
      {
        normalizationSchema: createSetupIntentSchema,
        app: true,
      }
    )
  )
}

export const doSetupIntentSuccess = (id, options = {}) => async dispatch => {
  return dispatch(
    doGraphqlRequest(
      SAVE_BILLING_SETUP_INTENT_CONFIRMED,
      BILLING_CARD_SETUP_INTENT_SUCCEED_MUTATION,
      {
        id,
      },
      {
        normalizationSchema: cardSetupIntentSucceedSchema,
        app: true,
        ...options,
      }
    )
  )
}
