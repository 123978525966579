import { doGraphqlRequest } from 'ducks/requests/operations'
import { SET_FLAG, CLEAR_FLAG } from './types'

const setFlagMutation = `
  mutation SetAgentFlag($name: String!) {
    setAgentFlag(name: $name) {
      success
    }
  }
`

const clearFlagMutation = `
  mutation ClearAgentFlag($name: String!) {
    clearAgentFlag(name: $name) {
      success
    }
  }
`

export const doSetFlag = name => {
  return dispatch => {
    return dispatch(doGraphqlRequest(SET_FLAG, setFlagMutation, { name }))
  }
}

export const doClearFlag = name => {
  return dispatch => {
    return dispatch(doGraphqlRequest(CLEAR_FLAG, clearFlagMutation, { name }))
  }
}
