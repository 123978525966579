import createCachedSelector from 're-reselect'
import moment from 'moment'
import { createBasicSelector } from 'util/redux'

import { isAdmin, isOwner, isAgent, isViewer } from 'util/agents'
import { emptyObj } from 'util/objects'
import { selectCurrentEntitiesById } from 'ducks/entities/selectors'
import { selectRequestByType } from 'ducks/requests/selectors'
import { selectPrefers2FAEnforced } from 'selectors/app/selectAccountPreferences'
import { FETCH_PREFERENCES } from '../types'
import { selectCurrentUser } from './selectCurrentUser'

export { selectCurrentUser }

export const selectCurrentUserId = state => selectCurrentUser(state).id

export const selectCurrentUserPrivateFullRoomAlias = createBasicSelector(
  selectCurrentUser,
  agent => agent.chatPrivateRoomFullAlias
)

export const selectCurrentUserGlobalId = state => {
  const id = selectCurrentUserId(state)
  return id ? `ag_${id}` : id
}

export const selectCurrentUserIsAgent = createBasicSelector(
  selectCurrentUser,
  agent => isAgent(agent)
)

export const selectCurrentUserIsAdmin = createBasicSelector(
  selectCurrentUser,
  agent => isAdmin(agent)
)

export const selectCurrentUserIsOwner = createBasicSelector(
  selectCurrentUser,
  agent => isOwner(agent)
)

export const selectCurrentUserIsViewer = createBasicSelector(
  selectCurrentUser,
  agent => isViewer(agent)
)

export const selectCurrentUserIsAdminOrOwner = createBasicSelector(
  selectCurrentUserIsAdmin,
  selectCurrentUserIsOwner,
  (currentUserIsAdmin, currentUserIsOwner) =>
    currentUserIsAdmin || currentUserIsOwner
)

export const selectCurrentUserIsAdminOrOwnerOrViewer = createBasicSelector(
  selectCurrentUserIsAdmin,
  selectCurrentUserIsOwner,
  selectCurrentUserIsViewer,
  (currentUserIsAdmin, currentUserIsOwner, currentUserIsViewer) =>
    currentUserIsAdmin || currentUserIsOwner || currentUserIsViewer
)

export const selectCurrentUserIsAdminOrOwnerOrAgent = createBasicSelector(
  selectCurrentUserIsAdmin,
  selectCurrentUserIsOwner,
  selectCurrentUserIsAgent,
  (currentUserIsAdmin, currentUserIsOwner, currentUserIsAgent) =>
    currentUserIsAdmin || currentUserIsOwner || currentUserIsAgent
)

export const selectCurrentUserCanAccessAdmin = createBasicSelector(
  selectCurrentUserIsAdmin,
  state => state.app && state.app.account && state.app.account.subdomain,
  (admin, subdomain) => {
    return admin && (subdomain === 'groove' || subdomain === 'support-thing')
  }
)

export const selectCurrentUserHasFullAccess = createBasicSelector(
  selectCurrentUser,
  agent => !!agent.fullAccess
)

export const selectIsOauthAuthenticatable = createBasicSelector(
  selectCurrentUser,
  user => user.oauthAuthenticatable
)

export const selectCurrentAgentPreferences = state =>
  selectCurrentEntitiesById(state, 'userPreferences') || emptyObj

export const selectCurrentUserPreferences = createBasicSelector(
  selectCurrentUserId,
  selectCurrentAgentPreferences,
  (currentUserId, agentPreferencesById) =>
    (currentUserId ? agentPreferencesById[currentUserId] : null) || emptyObj
)

export const selectFetchPreferencesRequest = state => {
  return selectRequestByType(state, FETCH_PREFERENCES)
}

export const selectCurrentUserSignupTimestamp = createBasicSelector(
  selectCurrentUser,
  user => user?.confirmedAt
)

// The supported measurements are years, months, weeks, days, hours, minutes, and seconds
export const selectCurrentUserSignupOlderThan = createCachedSelector(
  selectCurrentUserSignupTimestamp,
  (_state, difference) => difference,
  (_state, _difference, measurement = 'days') => measurement,
  (timestamp, difference, measurement) =>
    timestamp
      ? moment().diff(moment(timestamp), measurement) >= difference
      : false
)((_state, difference, measurement) => `${measurement}${difference}`)

export const selectCurrentUserInvitationAcceptedAt = createBasicSelector(
  selectCurrentUser,
  user => user?.invitationAcceptedAt
)

export const selectCurrentUser2faEnabled = createBasicSelector(
  selectCurrentUser,
  user => user.status2fa !== 'disabled'
)

export const selectCurrentUserEmail = createBasicSelector(
  selectCurrentUser,
  user => user.email
)

export const selectCurrentUser2faEnforced = createBasicSelector(
  selectPrefers2FAEnforced,
  selectCurrentUser2faEnabled,
  selectCurrentUserId,
  (prefers2FAEnforced, currentUser2faEnabled, id) =>
    prefers2FAEnforced && !currentUser2faEnabled && !!id
)
