import {
  FETCH_CARD_FOR_BOARD_REQUEST,
  FETCH_CARD_FOR_BOARD_SUCCESS,
  FETCH_CARD_REQUEST,
  FETCH_CARD_SUCCESS,
  FETCH_LIST_CARDS_REQUEST,
  FETCH_LIST_CARDS_SUCCESS,
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
} from '../types'

const initialState = {}

export default function reduceCards(
  state = initialState,
  { payload: { cards = [] } = {}, type }
) {
  if (
    [
      FETCH_CARD_FOR_BOARD_REQUEST,
      FETCH_CARD_REQUEST,
      FETCH_LIST_CARDS_REQUEST,
    ].includes(type)
  ) {
    return {
      ...state,
      ...cards.reduce((reduction, { id }) => {
        // eslint-disable-next-line no-param-reassign
        reduction[id] = { ...state[id], loading: true }
        return reduction
      }, {}),
    }
  }
  if (
    [
      FETCH_CARD_FOR_BOARD_SUCCESS,
      FETCH_CARD_SUCCESS,
      FETCH_LIST_CARDS_SUCCESS,
      UPDATE_CARD_REQUEST,
      UPDATE_CARD_SUCCESS,
    ].includes(type)
  ) {
    return {
      ...state,
      ...cards.reduce((reduction, card) => {
        const { id } = card
        // eslint-disable-next-line no-param-reassign
        reduction[id] = { ...state[id], ...card, loaded: true, loading: false }
        return reduction
      }, {}),
    }
  }
  return state
}
