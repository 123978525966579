/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { emptyObj } from 'util/objects'

import { FETCH_CONTACT_PAGE_SUCCESS } from '../types'

const defaultState = emptyObj

export default createActionTypeReducer(
  {
    [FETCH_CONTACT_PAGE_SUCCESS]: (
      draftState,
      {
        entities: { contacts = {} } = {},
        meta: { identifyingKey, identifyingValue } = {},
      } = {}
    ) => {
      Object.values(contacts).forEach(({ id }) => {
        if (!draftState[identifyingKey]) draftState[identifyingKey] = {}
        draftState[identifyingKey][identifyingValue] = id
      })
    },
  },
  defaultState
)
