import { doLinkResource } from 'actions/userIntegrations'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentConversationUrl } from 'selectors/tickets'

import * as trello from '../api'
import { selectTrelloCardsById } from '../selectors'

export default function doUnlinkTrelloCard(cardId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const ticketUrl = selectCurrentConversationUrl(state)
    const cards = selectTrelloCardsById(state)
    const card = cards[cardId]
    if (!card) return Promise.reject('Cannot find Trello card')

    const { name: title, url, id } = card
    return Promise.all([
      dispatch(
        doLinkResource({
          id,
          provider: 'trello',
          removed: true,
          title,
          url,
        })
      ),
      trello.detachTicketFromCard(token, id, ticketUrl),
    ])
  }
}
