import { createSelector } from 'reselect'
import { planIdToComponents } from '../util'
import { selectFuturePhase } from './selectFuturePhase'
import { selectCurrentPlanByType } from './selectCurrentPlanByType'
import { INBOX, KB } from '../productTypes'
import { selectCurrentBillingCycle } from './selectCurrentBillingCycle'
import { selectPricings } from './selectPricings'

export const selectPendingFuturePhaseDetailed = createSelector(
  state => selectCurrentPlanByType(state, INBOX),
  state => selectCurrentPlanByType(state, KB),
  selectCurrentBillingCycle,
  selectFuturePhase,
  selectPricings,
  (inbox, kb, currentBillingCycle, futurePhase, pricings) => {
    const pendingDowngrades = { inbox: null, kb: null }
    if (!futurePhase) return pendingDowngrades
    const base = { inbox, kb }

    const phaseDowngrades = futurePhase.items.reduce((downgrades, phase) => {
      const pricing = pricings.find(
        ({ externalMonthlyPriceId, externalAnnualPriceId }) =>
          [externalMonthlyPriceId, externalAnnualPriceId].includes(phase.plan)
      )
      const { product: phaseProduct, cycle } = planIdToComponents(phase.plan)
      const isPlanChange =
        currentBillingCycle !== cycle ||
        base[phaseProduct]?.pricing?.id !== pricing.id
      // eslint-disable-next-line no-param-reassign
      downgrades[phaseProduct] = {
        pricingId: pricing.id,
        cycle,
        isPlanChange,
      }
      return downgrades
    }, {})

    return {
      ...pendingDowngrades,
      ...phaseDowngrades,
    }
  }
)
