import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { selectCurrentContact } from 'ducks/crm/contacts/selectors/current'
import { selectCurrentCompany } from 'ducks/crm/companies/selectors/current'
import { uniq } from 'util/arrays'

import { selectCustomFieldsByKey } from './base'
import { selectCustomFieldsForCategoryIdByKey } from './categories'

function makeSelectCustomFieldsForHeader(subjectSelector, fieldKeys) {
  return createSelector(
    selectCustomFieldsByKey,
    subjectSelector,
    (fields, subject) => {
      const { customFieldValues } = subject || {}
      return fieldKeys.reduce((result, key) => {
        const customFieldValue = customFieldValues[key]
        const customField = fields[key]
        // eslint-disable-next-line no-param-reassign
        result[key] = {
          ...customFieldValue,
          ...customField,
          subjectId: subject.id,
          valueId: customFieldValue && customFieldValue.id,
        }
        return result
      }, {})
    }
  )
}

export const selectCustomFieldsForContactHeader = makeSelectCustomFieldsForHeader(
  selectCurrentContact,
  [
    'contact_email',
    'contact_first_name',
    'contact_last_name',
    'contact_secondary_email1',
    'contact_secondary_email2',
    'contact_secondary_email3',
    'contact_secondary_email4',
    'contact_secondary_email5',
  ]
)
export const selectCustomFieldsForCompanyHeader = makeSelectCustomFieldsForHeader(
  selectCurrentCompany,
  [
    'company_name',
    'company_domain',
    'company_secondary_domain1',
    'company_secondary_domain2',
    'company_secondary_domain3',
    'company_secondary_domain4',
    'company_secondary_domain5',
  ]
)

const DEFAULT_ORDER = {
  contact_first_name: true,
  contact_last_name: true,
  contact_email_address: true,
  contact_notes: true,
  contact_secondary_emails: false,
  contact_avatar: false,
  contact_avatar_url: false,
  contact_date_of_birth: false,
  contact_bio: false,
  contact_phone_number: false,
  contact_address: false,
  contact_time_zone: false,
  contact_website: false,
  contact_facebook: false,
  contact_twitter: false,
  contact_linkedin: false,
  contact_institution: false,
  contact_degree: false,
  contact_graduation_date: false,
  contact_job_title: false,
  contact_company: false,
  contact_job_start_date: false,
  contact_job_end_date: false,
  derived_last_seen_at: false,
  local_time: false,

  company_logo: true,
  company_name: true,
  company_domain: true,
  company_secondary_domain1: true,
  company_phone_number: true,
  company_sector: false,
  company_industry: false,
  company_sic_code: false,
  company_description: false,
  company_year_founded: false,
  company_street_address: true,
  company_street_address_2: true,
  company_city: true,
  company_state: true,
  company_postal_code: true,
  company_country: true,
  company_time_zone: true,
  company_funding_raised: false,
  company_employees_count: false,
  company_ticker: false,
  company_market_cap: false,
  company_annual_revenue: false,
  company_facebook: true,
  company_twitter: true,
  company_linkedin: true,
}
const EXTRA_FIELDS = {
  contact_information: ['derived_last_seen_at', 'local_time'],
}
export const selectCustomFieldOrderAndVisibilityForCategoryId = createCachedSelector(
  selectCustomFieldsForCategoryIdByKey,
  (state, { categoryKey }) => categoryKey,
  (fields, categoryKey) => {
    const fieldKeysInOrder = uniq([
      ...Object.keys(DEFAULT_ORDER),
      ...Object.values(fields).map(({ key }) => key),
    ])
    return fieldKeysInOrder
      .filter(key => !!fields[key])
      .concat(EXTRA_FIELDS[categoryKey] || [])
      .map((key, index) => {
        const visible = DEFAULT_ORDER[key]
        return {
          key,
          index,
          visible: visible === undefined ? true : visible,
        }
      })
  }
)((state, { categoryId, categoryKey }) => `${categoryId}:${categoryKey}`)
