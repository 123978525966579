import { oauthTokenSelector } from 'selectors/app'

import * as trello from '../api'
import {
  FETCH_LIST_CARDS_REQUEST,
  FETCH_LIST_CARDS_SUCCESS,
  FETCH_LIST_CARDS_FAILURE,
} from '../types'

export default function doFetchTrelloCardsForList(listId) {
  return (dispatch, getState) => {
    dispatch({
      meta: { listId },
      type: FETCH_LIST_CARDS_REQUEST,
    })
    const state = getState()
    return trello
      .listCardsForList(oauthTokenSelector(state), listId)
      .then(cards =>
        dispatch({
          meta: { listId },
          payload: { cards },
          type: FETCH_LIST_CARDS_SUCCESS,
        })
      )
      .catch(error =>
        dispatch({
          error: true,
          meta: { listId },
          payload: error,
          type: FETCH_LIST_CARDS_FAILURE,
        })
      )
  }
}
