import { PREFERENCES_FIELDS } from './queries'

const bulkUpsertOperation = `
  agentNotificationPreferenceBulkUpsert(input: {notificationPreferences: $upsertPreferences}) {
    notificationPreferences {
      ${PREFERENCES_FIELDS}
    }
    errors {
      path
      message
    }
  }
`

const bulkDeleteOperation = `
  agentNotificationPreferenceBulkDelete(input: {ids: $deleteIds}) {
    ids
    errors {
      path
      message
    }
  }
`

export const bulkUpsertAndDeleteMutation = `
  mutation AgentNotificationPreferenceBulkUpsertAndDelete($deleteIds: [ID!], $upsertPreferences: [AgentNotificationPreferenceInput!]) {
    ${bulkDeleteOperation}
    ${bulkUpsertOperation}
  }
`

export const bulkUpsertMutation = `
  mutation AgentNotificationPreferenceBulkUpsert($upsertPreferences: [AgentNotificationPreferenceInput!]) {
    ${bulkUpsertOperation}
  }
`
