import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectPreferences } from './selectPreferences'

export const selectPreferredPersonalUsageOnboarding = createSelector(
  selectPreferences,
  preferences => {
    return preferences.personal_usage_onboarding || emptyObj
  }
)
