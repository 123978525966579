import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectIsSubscriptionActive } from './selectIsSubscriptionActive'
import { selectIsBillingAccountActive } from './selectIsBillingAccountActive'
import { selectUpcomingInvoiceItems } from './selectUpcomingInvoiceItems'
import { selectUpcomingInvoice } from './selectUpcomingInvoice'
import { selectBillingAccount } from './selectBillingAccount'
import { selectAppliedDiscount } from './selectAppliedDiscount'

export const selectBillingEstimate = createSelector(
  selectBillingAccount,
  selectUpcomingInvoice,
  selectUpcomingInvoiceItems,
  selectIsBillingAccountActive,
  selectIsSubscriptionActive,
  selectAppliedDiscount,
  (
    billingAccount,
    upcomingInvoice,
    upcomingInvoiceLines,
    isBillingAccountActive,
    isSubscriptionActive,
    appliedDiscount
  ) => {
    const subscriptionCharges = upcomingInvoiceLines
      .filter(item => !item.proration)
      .reduce((total, item) => item.amount + total, 0)

    const prorataCharges = upcomingInvoiceLines
      .filter(item => item.proration)
      .reduce((total, item) => item.amount + total, 0)

    return {
      isAccountActive: isBillingAccountActive,
      isSubscriptionActive,
      hasInvoice: !!upcomingInvoice.id,
      nextBillDate: upcomingInvoice.nextBill,
      subscriptionCharges,
      prorataCharges,
      creditBalance: billingAccount.creditBalance,
      total: upcomingInvoice.total || 0,
      subTotal: subscriptionCharges,
      proRataTotal: prorataCharges,
      balanceTotal: upcomingInvoice.startingBalance || 0,
      amountDue: upcomingInvoice.amountDue || 0,
      currency: upcomingInvoice.currency,
      collectionMethod: upcomingInvoice.billing,
      discountTotal: upcomingInvoice.totalDiscountAmount || 0,
      billingCycle: upcomingInvoice.billingCycle,
      discount: appliedDiscount || emptyObj,
      coupon: appliedDiscount?.coupon || emptyObj,
    }
  }
)
