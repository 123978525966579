import { oauthTokenSelector } from 'selectors/app'
import { FETCH_CUSTOMER_REQUEST } from 'ducks/integrations/stripe/types'
import { doSdkRequest } from 'ducks/requests/operations'

import * as api from '../api'

const doFetchCustomerByEmail = ({ integrationId, email }) => {
  return doSdkRequest(
    FETCH_CUSTOMER_REQUEST,
    async ({ getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)

      const { response } = await api.getCustomerByEmail(
        token,
        integrationId,
        email
      )
      return response
    },
    { integrationId, email }
  )
}

export default doFetchCustomerByEmail
