import customer from './customer'
import customProfile from './customProfile'
import openState from './openState'
import changeUser from './changeUser'

export default {
  customer,
  customProfile,
  openState,
  changeUser,
}

export * as customer from './customer'
export * as customProfile from './customProfile'
export * as openState from './openState'
export * as changeUser from './changeUser'
