import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import selectIntegrationCredentialsById from './selectIntegrationCredentialsById'

const selectIntegrationSettingsForId = createCachedSelector(
  selectIntegrationCredentialsById,
  (_, id) => id,
  (integrationCredentialsById, id) => {
    if (!integrationCredentialsById || !id) return emptyObj

    return integrationCredentialsById[id]?.settings?.settings || emptyObj
  }
)((_, id) => id || 'UNKNOWN')

export default selectIntegrationSettingsForId
