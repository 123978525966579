import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'

import selectJiraTypes from './selectJiraTypes'

export default createSelector(selectJiraTypes, types => {
  return (types || emptyArr).map(({ iconUrl, id, name }) => {
    return {
      iconUrl,
      text: name,
      value: id,
    }
  })
})
