import merge from 'deepmerge'
import storage from 'util/storage'
import sharedStorage from 'util/sharedStorage'
import Bugsnag from '@bugsnag/js'
import { APP_BILLING_EXPIRED } from 'ducks/billing/types'
import { SIGNUP_SUCCESS } from 'subapps/onboarding/types'
import {
  UPDATE_APP_DATA,
  LOGOUT,
  UPDATE_ACCOUNT_SUCCESS,
} from 'constants/action_types'
import { TIMEZONES } from 'constants/timzones'
import { createActionTypeReducer } from 'util/reducers'
import {
  CURRENT_USER_CHANGED,
  UPDATE_USER_PREFERENCES_REQUEST,
  UPDATE_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_SUCCESS,
  UPDATE_PREFERENCES_SUCCESS,
  FETCH_TWO_FACTOR_AUTHENTICATION_CODE_STARTED,
  FETCH_TWO_FACTOR_AUTHENTICATION_CODE_SUCCESS,
  FETCH_TWO_FACTOR_AUTHENTICATION_CODE_FAILED,
} from './types'

const currentUser = storage.get('currentUser') || {}

const defaultState = {
  ...currentUser,
  sortOrderBySortContext: {},
  twoFactorAuth: {},
}

function getAvatarUrl(agent) {
  return agent && agent.avatar_url && !agent.avatar_url.match('missing.png')
    ? agent.avatar_url
    : null
}

function createUser(apiUser) {
  const newUser = {
    preferences: {},
    ...apiUser,
    type: 'Agent',
    sortOrderBySortContext: {},
  }
  newUser.avatarUrl = getAvatarUrl(apiUser)
  delete newUser.flags // NOTE (jscheel): These are stored in a duck
  storage.set('currentUser', newUser)
  storage.set('mx_access_token', newUser.chatAccessToken, {
    ignoreNamespace: true,
    asJson: false,
  })
  // We need it in IndexedDB so that the ServiceWorker can access it too.
  sharedStorage.setCurrentUserId(newUser.id)
  Bugsnag.setUser(newUser.id, newUser.name)
  return newUser
}

const fetchCurrentUserReducer = (
  draftState,
  { data: { currentUser: user } }
) => {
  return Object.assign(draftState, createUser(user))
}

const updateUserPreferences = (draftState, preferences) => {
  return merge(draftState, { preferences })
}

export default createActionTypeReducer(
  {
    [UPDATE_APP_DATA]: fetchCurrentUserReducer,
    [FETCH_CURRENT_USER_SUCCESS]: fetchCurrentUserReducer,
    [APP_BILLING_EXPIRED]: (draftState, { data: { user } }) => {
      if (!user) return draftState
      return Object.assign(draftState, createUser(user))
    },
    [LOGOUT]: () => defaultState,
    // Created as a helper to test UI for different agent types. E.g.
    //
    //   { type: 'CURRENT_USER_CHANGED', data: { role: 'admin'} }
    //
    [CURRENT_USER_CHANGED]: (draftState, { data: agent }) => {
      return Object.assign(draftState, createUser(agent))
    },
    [SIGNUP_SUCCESS]: (draftState, { payload: { user } }) => {
      return Object.assign(draftState, createUser(user))
    },
    [UPDATE_USER_PREFERENCES_REQUEST]: (
      draftState,
      {
        payload: {
          preferences: { ...preferences },
        },
      }
    ) => updateUserPreferences(draftState, preferences),
    [UPDATE_PREFERENCES_SUCCESS]: (draftState, { payload: preferences }) =>
      updateUserPreferences(draftState, preferences),
    [UPDATE_CURRENT_USER_SUCCESS]: (draftState, { payload }) => {
      const { updateCurrentUser } = payload
      return Object.assign(draftState, createUser(updateCurrentUser))
    },
    [UPDATE_ACCOUNT_SUCCESS]: (draftState, { payload }) => {
      const { updateAccount: account } = payload
      Object.assign(draftState.preferences, account.preferences)
      // NOTE (jscheel): The currentUser.preferences.timezone object is an
      // amalgamation of user and account prefs. Because the account update
      // doesn't return this, we instead must manually recalculate it.
      const timezone = TIMEZONES.find(tz => tz.key === account.timezone)
      draftState.preferences.timezone.account = {
        id: timezone.name,
        offset: timezone.offset,
      }
      // NOTE (jscheel): The preferences endpoint automatically sets the agent's
      // timezone to the account timezone if it isn't set. We have to manually
      // do this here.
      if (!draftState.timezone) {
        draftState.preferences.timezone.agent = {
          id: timezone.name,
          offset: timezone.offset,
        }
      }
      draftState.preferences.prefers_v2_show_all_mailboxes_section =
        account.preferences.v2_show_all_mailboxes_section

      return draftState
    },
    [FETCH_TWO_FACTOR_AUTHENTICATION_CODE_STARTED]: draftState => {
      draftState.twoFactorAuth.isLoading = true
    },
    [FETCH_TWO_FACTOR_AUTHENTICATION_CODE_SUCCESS]: (
      draftState,
      { payload }
    ) => {
      const { uri, code } = payload.me.twoFactorAuthCode

      if (uri && code) {
        draftState.twoFactorAuth.isLoading = false
        draftState.twoFactorAuth.uri = uri
        draftState.twoFactorAuth.code = code
      } else {
        draftState.twoFactorAuth.isLoading = false
        draftState.twoFactorAuth.isError = false
      }
    },
    [FETCH_TWO_FACTOR_AUTHENTICATION_CODE_FAILED]: draftState => {
      draftState.twoFactorAuth.isError = true
    },
  },
  defaultState
)
