export const removeIntegrationMutation = `
  mutation IntegrationDelete(
    $integrationId: ID!,
  ){
    integrationDelete(input: {
      integrationId: $integrationId
    }) {
      deletedIntegrationId
    }
  }
`
