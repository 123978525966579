import { createBasicSelector } from 'util/redux'
import { selectBase } from './selectBase'
import { selectCurrentKb } from './selectCurrentKb'
import { selectCurrentInbox } from './selectCurrentInbox'

export const selectIsBillingEstimateAvailable = createBasicSelector(
  selectBase,
  selectCurrentInbox,
  selectCurrentKb,
  (base, currentInbox, currentKb) => {
    const { state } = base
    const { state: inboxState } = currentInbox
    const { state: kbState } = currentKb
    const includeAccountStates = ['open', 'expired', 'closed']
    const excludePlanStates = [null, 'trial']
    if (
      state &&
      includeAccountStates.includes(state) &&
      !(
        excludePlanStates.includes(kbState) ||
        excludePlanStates.includes(inboxState)
      )
    ) {
      return true
    }
    return false
  }
)
