import createCachedSelector from 're-reselect'

import selectIntegrationData from './selectIntegrationData'

const selectIntegrationDataForProviderId = createCachedSelector(
  selectIntegrationData,
  (_state, providerId) => providerId,
  (integrationData, providerId) => {
    return integrationData.find(i => i.id === providerId)
  }
)((_state, providerId) => providerId || 'unknown')

export default selectIntegrationDataForProviderId
