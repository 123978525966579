import { oauthTokenSelector } from 'selectors/app'

import * as trello from '../api'
import {
  FETCH_BOARD_DETAILS_REQUEST,
  FETCH_BOARD_DETAILS_SUCCESS,
  FETCH_BOARD_DETAILS_FAILURE,
} from '../types'

export default function doFetchTrelloBoardDetails(boardId) {
  return (dispatch, getState) => {
    dispatch({ meta: { boardId }, type: FETCH_BOARD_DETAILS_REQUEST })
    const state = getState()
    return trello
      .listBoardDetails(oauthTokenSelector(state), boardId)
      .then(({ lists, members, name, labels }) =>
        dispatch({
          meta: { boardId },
          payload: { board: { id: boardId, name }, labels, lists, members },
          type: FETCH_BOARD_DETAILS_SUCCESS,
        })
      )
      .catch(error =>
        dispatch({
          error: true,
          meta: { boardId },
          payload: error,
          type: FETCH_BOARD_DETAILS_FAILURE,
        })
      )
  }
}
