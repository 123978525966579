export const FETCH_CONTACT_PAGE = 'crm/contacts/FETCH_CONTACT_PAGE'
export const FETCH_CONTACT_PAGE_STARTED =
  'crm/contacts/FETCH_CONTACT_PAGE_STARTED'
export const FETCH_CONTACT_PAGE_SUCCESS =
  'crm/contacts/FETCH_CONTACT_PAGE_SUCCESS'
export const FETCH_CONTACT_PAGE_FAILED =
  'crm/contacts/FETCH_CONTACT_PAGE_FAILED'

export const FETCH_CONTACT_STARTED = 'crm/contacts/FETCH_CONTACT_STARTED'
export const FETCH_CONTACT_SUCCESS = 'crm/contacts/FETCH_CONTACT_SUCCESS'
export const FETCH_CONTACT_FAILED = 'crm/contacts/FETCH_CONTACT_FAILED'

export const FETCH_CONTACTS = 'crm/contacts/FETCH_CONTACTS'
export const FETCH_CONTACTS_STARTED = 'crm/contacts/FETCH_CONTACTS_STARTED'
export const FETCH_CONTACTS_SUCCESS = 'crm/contacts/FETCH_CONTACTS_SUCCESS'
export const FETCH_CONTACTS_FAILED = 'crm/contacts/FETCH_CONTACTS_FAILED'

export const FETCH_CONVERSATION_CONTACT =
  'crm/contacts/FETCH_CONVERSATION_CONTACT'
export const FETCH_CONVERSATION_CONTACT_STARTED =
  'crm/contacts/FETCH_CONVERSATION_CONTACT_STARTED'
export const FETCH_CONVERSATION_CONTACT_SUCCESS =
  'crm/contacts/FETCH_CONVERSATION_CONTACT_SUCCESS'
export const FETCH_CONVERSATION_CONTACT_FAILED =
  'crm/contacts/FETCH_CONVERSATION_CONTACT_FAILED'

export const FETCH_CONVERSATION_CONTACT_PAGE =
  'crm/contacts/FETCH_CONVERSATION_CONTACT_PAGE'
export const FETCH_CONVERSATION_CONTACT_PAGE_STARTED =
  'crm/contacts/FETCH_CONVERSATION_CONTACT_PAGE_STARTED'
export const FETCH_CONVERSATION_CONTACT_PAGE_SUCCESS =
  'crm/contacts/FETCH_CONVERSATION_CONTACT_PAGE_SUCCESS'
export const FETCH_CONVERSATION_CONTACT_PAGE_FAILED =
  'crm/contacts/FETCH_CONVERSATION_CONTACT_PAGE_FAILED'

export const PAGE_CONTACTS = 'crm/contacts/PAGE_CONTACTS'
export const SORT_CONTACTS = 'crm/contacts/SORT_CONTACTS'

export const UPDATE_CUSTOM_FIELD_VALUE =
  'crm/contacts/UPDATE_CUSTOM_FIELD_VALUE'
export const UPDATE_CUSTOM_FIELD_VALUE_STARTED =
  'crm/contacts/UPDATE_CUSTOM_FIELD_VALUE_STARTED'
export const UPDATE_CUSTOM_FIELD_VALUE_SUCCESS =
  'crm/contacts/UPDATE_CUSTOM_FIELD_VALUE_SUCCESS'
export const UPDATE_CUSTOM_FIELD_VALUE_FAILED =
  'crm/contacts/UPDATE_CUSTOM_FIELD_VALUE_FAILED'

export const BULK_UPDATE_CUSTOM_FIELD_VALUE =
  'crm/contacts/BULK_UPDATE_CUSTOM_FIELD_VALUE'
export const BULK_UPDATE_CUSTOM_FIELD_VALUE_STARTED =
  'crm/contacts/BULK_UPDATE_CUSTOM_FIELD_VALUE_STARTED'
export const BULK_UPDATE_CUSTOM_FIELD_VALUE_SUCCESS =
  'crm/contacts/BULK_UPDATE_CUSTOM_FIELD_VALUE_SUCCESS'
export const BULK_UPDATE_CUSTOM_FIELD_VALUE_FAILED =
  'crm/contacts/BULK_UPDATE_CUSTOM_FIELD_VALUE_FAILED'

export const MERGE_CONTACT = 'crm/contacts/MERGE_CONTACT'
export const MERGE_CONTACT_STARTED = 'crm/contacts/MERGE_CONTACT_STARTED'
export const MERGE_CONTACT_SUCCESS = 'crm/contacts/MERGE_CONTACT_SUCCESS'
export const MERGE_CONTACT_FAILED = 'crm/contacts/MERGE_CONTACT_FAILED'

export const SEARCH_CONTACTS = 'crm/contacts/SEARCH_CONTACTS'
export const SEARCH_CONTACTS_STARTED = 'crm/contacts/SEARCH_CONTACTS_STARTED'
export const SEARCH_CONTACTS_SUCCESS = 'crm/contacts/SEARCH_CONTACTS_SUCCESS'
export const SEARCH_CONTACTS_FAILED = 'crm/contacts/SEARCH_CONTACTS_FAILED'

export const CHANGE_CONVERSATION_CONTACT =
  'crm/contacts/CHANGE_CONVERSATION_CONTACT'
export const CHANGE_CONVERSATION_CONTACT_STARTED =
  'crm/contacts/CHANGE_CONVERSATION_CONTACT_STARTED'
export const CHANGE_CONVERSATION_CONTACT_SUCCESS =
  'crm/contacts/CHANGE_CONVERSATION_CONTACT_SUCCESS'
export const CHANGE_CONVERSATION_CONTACT_FAILED =
  'crm/contacts/CHANGE_CONVERSATION_CONTACT_FAILED'

export const CREATE_CONTACT = 'crm/contacts/CREATE_CONTACT'
export const CREATE_CONTACT_STARTED = 'crm/contacts/CREATE_CONTACT_STARTED'
export const CREATE_CONTACT_SUCCESS = 'crm/contacts/CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILED = 'crm/contacts/CREATE_CONTACT_FAILED'

export const RESYNC_CONTACT = 'crm/contacts/RESYNC_CONTACT'
export const RESYNC_CONTACT_STARTED = 'crm/contacts/RESYNC_CONTACT_STARTED'
export const RESYNC_CONTACT_SUCCESS = 'crm/contacts/RESYNC_CONTACT_SUCCESS'
export const RESYNC_CONTACT_FAILED = 'crm/contacts/RESYNC_CONTACT_FAILED'
