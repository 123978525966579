import { doApiWriteRequest } from 'ducks/requests/operations'
import { FEATURE_INTEGRATION_ZAPIER } from 'ducks/billing/featureTypes'
import { ZAPIER_REMOVE_ACCESS_TOKENS } from './types'

export const doRemoveZapierAccessTokens = (options = {}) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      ZAPIER_REMOVE_ACCESS_TOKENS,
      `v1/integrations/zapier`,
      {},
      {
        method: 'DELETE',
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['integration'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: 'Zapier access revoked',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Unable to revoke zapier access. Please try again',
              onClickAction: () => {
                dispatch(doRemoveZapierAccessTokens(options))
              },
            },
          },
          entities: {
            additionalActions: [
              {
                entityType: 'usage',
                entityId: FEATURE_INTEGRATION_ZAPIER,
                stores: ['current'],
                operation: 'update',
                phases: ['SUCCESS'],
                entity: {
                  key: FEATURE_INTEGRATION_ZAPIER,
                  value: false,
                },
              },
            ],
          },
        },
      }
    )
  )
}
