import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectPendingIntegrationById } from 'ducks/integrations/selectors/settings'
import { doFetchBillingData } from 'ducks/billing/operations'
import { capture } from 'ducks/tracking/actions'
import { removeIntegrationMutation } from './removeIntegrationMutation'
import { DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS } from '../types'
import { INTEGRATION_PROVIDER } from '../utils/credentialsType'
import { doFetchIntegrationsByProviderForSettingsDrawer } from './settings'

export const doDeleteIntegrationForProvider = (id, provider, options = {}) => (
  dispatch,
  getState
) => {
  const variables = { integrationId: id }

  const state = getState()

  const { installStateConfig: { type } = {} } =
    selectPendingIntegrationById(state, provider) || {}

  if (type === INTEGRATION_PROVIDER) {
    return dispatch(
      doGraphqlRequest(
        DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS,
        removeIntegrationMutation,
        variables,
        {
          app: true,
          optimist: {},
          searches: {
            additionalActions: [
              {
                type: 'INVALIDATE_ENTITIES',
                entityTypes: [
                  'integration',
                  'integrationInstallStateProviderValue',
                ],
                phases: ['SUCCESS'],
              },
            ],
          },
          moduleOptions: {
            toasts: {
              enabled: true,
              started: {
                enabled: true,
                content: 'Removed successfully',
              },
              success: {
                enabled: false,
              },
              failed: {
                content: 'Removal failed',
                onClickAction: () => {
                  dispatch(
                    doDeleteIntegrationForProvider(id, provider, options)
                  )
                },
              },
            },
            entities: {
              targetOperation: 'remove',
              additionalActions: [
                {
                  entityType: 'integrationInstallStateProviderValue',
                  entityId: id,
                  stores: ['pending', 'current'],
                  operation: 'remove',
                  phases: ['STARTED'],
                },
              ],
            },
          },
        }
      )
    ).then(() => {
      if (provider === 'SHOPIFY_V2') {
        capture('Shopify Store Uninstalled')
      } else if (provider === 'RECHARGE') {
        capture('Recharge Store Uninstalled')
      }
      dispatch(doFetchBillingData())
      dispatch(
        doFetchIntegrationsByProviderForSettingsDrawer(provider, {
          targetStore: 'pending',
        })
      )
    })
  }

  // eslint-disable-next-line no-console
  console.log(`TODO: doDeleteIntegrationForProvider: ${type}`)

  return Promise.reject({})
}
