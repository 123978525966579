import {
  BOOTSTRAP_APP_DATA_SUCCESS,
  BOOTSTRAP_APP_DATA_FAILED,
  BOOTSTRAP_APP_DATA_STARTED,
} from 'ducks/bootstrap/types'

import {
  FETCH_INTEGRATIONS_FAILED,
  FETCH_INTEGRATIONS_STARTED,
  FETCH_INTEGRATIONS_SUCCESS,
} from '../../types'

const defaultState = true // Initial state assumes something is either loading this or going to load it very shortly
export default function loadingReducer(state = defaultState, { type }) {
  if ([BOOTSTRAP_APP_DATA_STARTED, FETCH_INTEGRATIONS_STARTED].includes(type)) {
    return true
  } else if (
    [
      FETCH_INTEGRATIONS_FAILED,
      FETCH_INTEGRATIONS_SUCCESS,
      BOOTSTRAP_APP_DATA_FAILED,
      BOOTSTRAP_APP_DATA_SUCCESS,
    ].includes(type)
  ) {
    return false
  }
  return state
}
