export const updateIntegrationSettingsMutation = `
  mutation IntegrationSettingsUpdate(
    $integrationId: ID!,
    $settings: JSON!
  ) {
    integrationSettingsUpdate(input: {
      integrationId: $integrationId,
      settings: $settings
    }) {
      integration {
        __typename
        ... on Integration {
          id
          provider
          settings {
            settings
          }
        }

        ... on UserIntegration {
          agent {
            id
          }
        }

        ... on ChannelIntegration {
          channel {
            id
          }
        }

        ... on ShopifyV2Integration {
          storeName
          storeDomain
        }

        ... on ShopifyIntegration {
          storeName
          storeDomain
        }

        ... on SalesforceIntegration {
          salesforceDomain
        }

        ... on JiraServerIntegration {
          username
          url
          agent {
            id
          }
        }
      }
    }
  }
`
