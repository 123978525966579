import { connect } from 'react-redux'
import { selectCannedRepliesSearchTerm } from 'ducks/cannedReplies/selectors'
import { doFetchCannedReplyTemplatesBySearch } from 'ducks/cannedReplies/operations'

import SearchInput from './view'

const select = state => ({
  term: selectCannedRepliesSearchTerm(state),
})

const perform = {
  onSearch: doFetchCannedReplyTemplatesBySearch,
}

export default connect(select, perform)(SearchInput)
