import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import { isEmpty } from 'util/strings'
import {
  FETCH_CUSTOMER_RATING_SETTINGS,
  UPSERT_CUSTOMER_RATING_SETTINGS,
  DELETE_CUSTOMER_RATING_SETTING,
} from './types'
import {
  customerRatingSettingsNormalizationSchema,
  customerRatingSettingNormalizationSchema,
  customerRatingSettingDeleteNormalizationSchema,
} from './schema'
import { getAllQuery, upsertQuery, deleteQuery } from './queries'

const LOAD_ALL_CUSTOMER_RATING_SETTINGS_QUERYID =
  'entityType:customerRatingSetting pageSize:100'

export const doFetchCustomerRatingSettings = () => {
  const queryId = LOAD_ALL_CUSTOMER_RATING_SETTINGS_QUERYID
  const cursor = '1'
  const query = getAllQuery

  return doAppGraphqlRequest(
    FETCH_CUSTOMER_RATING_SETTINGS,
    query,
    {},
    {
      normalizationSchema: customerRatingSettingsNormalizationSchema,
      searches: {
        queryId,
        cursor,
      },
    }
  )
}

export const doFetchCustomerRatingSettingsInMemory = createDoFetchInMemoryByQueryId(
  {
    fromQueryId: LOAD_ALL_CUSTOMER_RATING_SETTINGS_QUERYID,
    entityType: 'customerRatingSetting',
    doLoadAllFn: doFetchCustomerRatingSettings,
  }
)

export function doUpsertCustomerRatingSetting(input) {
  const {
    locale,
    name,
    settingId,
    translations,
    channelVisibility,
    mailboxIds: uncheckedMailboxIds,
  } = input
  const query = upsertQuery
  const isCreate = isEmpty(settingId)
  const mailboxIds = channelVisibility === 'all' ? [] : uncheckedMailboxIds

  return doAppGraphqlRequest(
    UPSERT_CUSTOMER_RATING_SETTINGS,
    query,
    { locale, name, settingId, translations, mailboxIds },
    {
      normalizationSchema: customerRatingSettingNormalizationSchema,
      searches: {
        additionalActions: [
          {
            queryId: `${LOAD_ALL_CUSTOMER_RATING_SETTINGS_QUERYID} cursor:1`,
            type: 'ADD_CURSOR_ENTITY_IDS',
            entityTypes: ['customerRatingSetting'],
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: `Satisfaction rating ${isCreate ? 'created' : 'updated'}`,
          },
          failed: {
            content: `Satisfaction rating ${
              isCreate ? 'create' : 'update'
            } failed`,
          },
        },
      },
    }
  )
}

export function doDeleteCustomerRatingSetting(settingId) {
  const query = deleteQuery

  return doAppGraphqlRequest(
    DELETE_CUSTOMER_RATING_SETTING,
    query,
    { settingId },
    {
      meta: { removeEntities: true },
      normalizationSchema: {
        customerRatingSettingDelete: customerRatingSettingDeleteNormalizationSchema,
      },
      searches: {
        additionalActions: [
          {
            entityIds: [settingId],
            queryId: `${LOAD_ALL_CUSTOMER_RATING_SETTINGS_QUERYID} cursor:1`,
            type: 'REMOVE_CURSOR_ENTITY_IDS',
            entityTypes: ['customerRatingSetting'],
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        entities: {
          targetOperation: 'remove',
          additionalActions: [
            {
              entityType: 'customerRatingSetting',
              entityId: settingId,
              stores: ['pending', 'current'],
              operation: 'remove',
              phases: ['SUCCESS'],
            },
          ],
        },
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: 'Satisfaction rating deleted',
          },
          failed: {
            content: 'Satisfaction rating delete failed',
          },
        },
      },
    }
  )
}
