import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'

export const selectCustomersById = state => state.customers.byId || emptyObj

export const selectCustomersByEmail = createSelector(
  selectCustomersById,
  customersById => {
    const customersByEmail = {}
    Object.keys(customersById).forEach(customerId => {
      const customer = customersById[customerId]
      customersByEmail[customer.email] = customer
    })
    return customersByEmail
  }
)

export function selectCustomer(state, id) {
  return selectCustomersById(state)[id] || emptyObj
}

export function selectCustomerProp(state, id, prop) {
  const customer = selectCustomer(state, id)
  const value = customer && customer[prop]
  return value || null
}
