import {
  FETCH_ASSIGNABLE_USERS_ERROR,
  FETCH_ASSIGNABLE_USERS_REQUEST,
  FETCH_ASSIGNABLE_USERS_SUCCESS,
} from '../types'

const initialState = { byKey: {}, errored: false, loading: false }

export default function reduceAssignableUsers(
  state = initialState,
  { type, payload: assignableUsers = [] }
) {
  if (type === FETCH_ASSIGNABLE_USERS_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  if (type === FETCH_ASSIGNABLE_USERS_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }
  if (type === FETCH_ASSIGNABLE_USERS_SUCCESS) {
    return {
      ...state,
      byKey: {
        ...assignableUsers.reduce((result, user) => {
          // eslint-disable-next-line no-param-reassign
          result[user.key] = user
          return result
        }, {}),
      },
      errored: false,
      loading: false,
    }
  }
  return state
}
