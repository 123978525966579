import {
  FETCH_ISSUE_SEARCH_ERROR,
  FETCH_ISSUE_SEARCH_REQUEST,
  FETCH_ISSUE_SEARCH_SUCCESS,
} from '../types'

const initialState = { ids: null, errored: false, loading: false }

export default function makeSearchIssues(prefix) {
  return function reduceSearchedIssues(
    state = initialState,
    { type, payload: { issues } = {} }
  ) {
    switch (type) {
      case `${prefix}/${FETCH_ISSUE_SEARCH_ERROR}`:
        return {
          ...state,
          errored: true,
          loading: false,
        }

      case `${prefix}/${FETCH_ISSUE_SEARCH_REQUEST}`:
        return {
          ...state,
          loading: true,
        }
      case `${prefix}/${FETCH_ISSUE_SEARCH_SUCCESS}`:
        return {
          ...state,
          ids: issues.map(({ id }) => id),
          errored: false,
          loading: false,
        }
      default:
        return state
    }
  }
}
