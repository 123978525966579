import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsLoadingAccountPreference,
  selectHasLoadedAccountPreference,
  selectHasErrorAccountPreference,
} from 'ducks/accountPreferences/selectors'
import { doFetchIntegrationsByProviderForSettingsDrawer } from 'ducks/integrations/operations/settings'
import {
  selectIntegrationById,
  selectIsLoadingIntegrationsByProvider,
  selectHasLoadedIntegrationsByProvider,
  selectHasErrorIntegrationsByProvider,
} from 'ducks/integrations/selectors/settings'
import { isIntegrationInstalled } from 'ducks/integrations/utils/entity'
import {
  ACCOUNT_PREFERENCES,
  INTEGRATION_PROVIDER,
  ACCOUNT,
} from 'ducks/integrations/utils/credentialsType'

export const useFetchIntegrationProvider = (
  providerId,
  { store = 'pending', shouldPreventFetching } = {}
) => {
  const dispatch = useDispatch()
  const provider = useSelector(state =>
    selectIntegrationById(state, providerId, store)
  )

  useEffect(
    () => {
      if (!providerId || provider || shouldPreventFetching) return

      dispatch(
        doFetchIntegrationsByProviderForSettingsDrawer(providerId, {
          targetStore: store,
        })
      )
    },
    [dispatch, providerId, provider, store, shouldPreventFetching]
  )

  const {
    installStateConfig: {
      type: installStateConfigType,
      allowsMultipleInstalls,
    } = {},
  } =
    provider || {}

  const isAlreadyInstalled =
    !allowsMultipleInstalls && isIntegrationInstalled(provider)

  const isLoadingAccountPreference = useSelector(
    selectIsLoadingAccountPreference
  )
  const hasLoadedAccountPreference = useSelector(
    selectHasLoadedAccountPreference
  )
  const hasErrorAccountPreference = useSelector(selectHasErrorAccountPreference)

  const isLoadingIntegrationsByProvider = useSelector(
    selectIsLoadingIntegrationsByProvider
  )
  const hasLoadedIntegrationsByProvider = useSelector(
    selectHasLoadedIntegrationsByProvider
  )
  const hasErrorIntegrationsByProvider = useSelector(
    selectHasErrorIntegrationsByProvider
  )
  const isLoading = useMemo(
    () => {
      switch (installStateConfigType) {
        case ACCOUNT_PREFERENCES:
          return isLoadingAccountPreference
        case INTEGRATION_PROVIDER:
          return isLoadingIntegrationsByProvider
        case ACCOUNT:
          // eslint-disable-next-line no-console
          console.log('TODO|isLoading', installStateConfigType)
          return false
        default:
          return false
      }
    },
    [
      installStateConfigType,
      isLoadingAccountPreference,
      isLoadingIntegrationsByProvider,
    ]
  )

  const hasError = useMemo(
    () => {
      switch (installStateConfigType) {
        case ACCOUNT_PREFERENCES:
          return hasErrorAccountPreference
        case INTEGRATION_PROVIDER:
          return hasErrorIntegrationsByProvider
        case ACCOUNT:
          // eslint-disable-next-line no-console
          console.log('TODO|hasError', installStateConfigType)
          return false
        default:
          return false
      }
    },
    [
      installStateConfigType,
      hasErrorAccountPreference,
      hasErrorIntegrationsByProvider,
    ]
  )

  const hasLoaded = useMemo(
    () => {
      switch (installStateConfigType) {
        case ACCOUNT_PREFERENCES:
          return hasLoadedAccountPreference
        case INTEGRATION_PROVIDER:
          return hasLoadedIntegrationsByProvider
        case ACCOUNT:
          // eslint-disable-next-line no-console
          console.log('TODO|hasLoaded', installStateConfigType)
          return true
        default:
          return true
      }
    },
    [
      installStateConfigType,
      hasLoadedAccountPreference,
      hasLoadedIntegrationsByProvider,
    ]
  )

  const isMissing = hasLoaded && !provider

  return {
    provider,
    isLoading,
    hasLoaded,
    hasError,
    isMissing,
    isAlreadyInstalled,
  }
}
