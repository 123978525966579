import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'
import {
  FETCH_LABELS_ERROR,
  FETCH_LABELS_REQUEST,
  FETCH_LABELS_SUCCESS,
} from '../types'

export default function doFetchJiraCloudLabels(searchQuery) {
  return (dispatch, getState) => {
    const meta = { searchQuery }
    const token = oauthTokenSelector(getState())
    dispatch({
      type: FETCH_LABELS_REQUEST,
      meta,
    })
    integrationApi
      .get(
        token,
        `proxy/jira-server/rest/api/2/jql/autocompletedata/suggestions`,
        { fieldName: 'labels', fieldValue: searchQuery }
      )
      .then(({ json: { results } }) => {
        dispatch({
          type: FETCH_LABELS_SUCCESS,
          meta,
          payload: results.map(({ value }) => value),
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_LABELS_ERROR,
          error: true,
          meta,
          payload: error,
        })
      })
  }
}
