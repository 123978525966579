import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { changeContactMutationSchema } from '../../schema'
import { CHANGE_CONVERSATION_CONTACT } from '../../types'
import { changeContactMutation } from './changeContactMutation'

export function doChangeContactForConversation(
  conversationId,
  conversationNumber,
  contactId
) {
  return dispatch => {
    const variables = {
      conversationId,
      contactId,
    }
    return dispatch(
      doAppGraphqlRequest(
        CHANGE_CONVERSATION_CONTACT,
        changeContactMutation,
        variables,
        {
          optimist: {
            conversationNumber,
            contactId,
          },
          meta: {
            mergeEntities: true,
          },
          normalizationSchema: changeContactMutationSchema,
        }
      )
    )
  }
}
