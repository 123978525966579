import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectCurrentUser } from 'ducks/currentUser/selectors'
import { selectCurrentTicketReplyDraft } from 'ducks/drafts2/selectors'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { selectAccount } from 'selectors/app'
import { selectCurrentTicketChangesets } from 'selectors/currentChangesets'
import { selectCurrentTicketMailbox } from 'selectors/tickets'
import { emptyArr } from 'util/arrays'
import { selectBase } from './selectBase'
import { interpolateAiDraft } from '../utils'
import { selectDraftReplySourcesStreamingResult } from './streamingResults'

export const selectAiConversationRequest = createSelector(
  selectBase,
  selectCurrentUser,
  selectCurrentTicketReplyDraft,
  selectCurrentTicket,
  selectAccount,
  selectCurrentTicketChangesets,
  selectCurrentTicketMailbox,
  (base, currentUser, draft, ticket, account, changesets, mailbox) => {
    const requestState = base.conversationRequest || emptyObj
    if (!requestState.response) {
      return requestState
    }

    return {
      ...requestState,
      response: {
        ...requestState.response,
        answer: interpolateAiDraft({
          currentUser,
          draft,
          ticket,
          reply: requestState.response?.answer,
          account,
          changesets,
          mailbox,
        }),
      },
    }
  }
)

export const selectAiConversationSourceIds = createSelector(
  selectDraftReplySourcesStreamingResult,
  selectAiConversationRequest,
  (sourcesFromStreamingResult, aiConversationRequest) => {
    const sourcesFromResponse =
      aiConversationRequest.response?.sources || emptyArr
    return sourcesFromStreamingResult.length
      ? sourcesFromStreamingResult
      : sourcesFromResponse
  }
)
