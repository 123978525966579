import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { propFunc } from 'util/functions'
import StarIcon from '../StarIcon'
import { styles } from './styles'

// Need to add category id, because template id will be the same for recently items
const getTemplateIdWithCategory = (category, template) =>
  `${category.id}-${template.id}`

export default function RepliesInCategory({
  handleHeaderClick,
  item,
  onSelect,
  active,
  handleMouseEnterItem,
  activeItemId,
  handleActivateCategory,
  handleActivateTemplate,
  handleStarClick,
  starredCannedRepliesIds,
}) {
  const [open, setOpen] = useState(active)
  const handleClickCategory = useCallback(
    value => {
      setOpen(state => !state)
      handleHeaderClick(value)
    },
    [handleHeaderClick]
  )

  return (
    <>
      {item.type === 'category' && (
        <KeyboardNavigator.Item
          onSelect={propFunc(handleClickCategory, item)}
          onActivate={propFunc(handleActivateCategory, item.id)}
          css={styles.menuHeader}
          active={activeItemId === item.id}
          // Control active without by KeyboardNavigator.Item,
          // because we need to make it work togerther with useMenuAim
          className={cn('grui truncate', {
            activeItem: activeItemId === item.id,
          })}
          onMouseEnter={propFunc(handleMouseEnterItem, { id: item.id })}
        >
          {item.name}
        </KeyboardNavigator.Item>
      )}
      {!!item.templates?.length &&
        open &&
        item.templates.map(template => {
          const templateIdWithCategory = getTemplateIdWithCategory(
            item,
            template
          )
          return (
            <KeyboardNavigator.Item
              key={template.id}
              onSelect={propFunc(onSelect, template)}
              className={cn({
                activeItem: activeItemId === templateIdWithCategory,
              })}
              css={styles.templateItem}
              onActivate={propFunc(
                handleActivateTemplate,
                templateIdWithCategory,
                template
              )}
              onMouseEnter={propFunc(handleMouseEnterItem, {
                id: templateIdWithCategory,
                item: template,
              })}
              active={activeItemId === templateIdWithCategory}
            >
              <span>{template.name}</span>
              <StarIcon
                cannedReply={template}
                handleClick={handleStarClick}
                starredCannedRepliesIds={starredCannedRepliesIds}
                isStarred={starredCannedRepliesIds.indexOf(template.id) > -1}
              />
            </KeyboardNavigator.Item>
          )
        })}
    </>
  )
}
