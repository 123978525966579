import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { selectFuturePhase } from './selectFuturePhase'
import { selectPricings } from './selectPricings'

export const selectFuturePricingIds = createSelector(
  selectFuturePhase,
  selectPricings,
  (futurePhase, pricings) => {
    if (!futurePhase) return emptyArr
    if (pricings.length === 0) return emptyArr

    return futurePhase.items.map(item => {
      return pricings.find(
        ({ externalMonthlyPriceId, externalAnnualPriceId }) =>
          [externalMonthlyPriceId, externalAnnualPriceId].includes(item.plan)
      ).id
    })
  }
)
