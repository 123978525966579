import { doGraphqlRequest } from 'ducks/requests/operations'
import doCreateShopifyEvent from './doCreateShopifyEvent'
import graphql from '../api'
import * as types from '../types'
import { orderPartial } from './partials'

const draftOrderCompleteMutation = `
  mutation ShopifyDraftOrderComplete(
    $id: ID!,
    $paymentPending: Boolean
  ) {
    draftOrderComplete(id: $id, paymentPending: $paymentPending) {
      userErrors {
        message
        field
      }
      draftOrder {
        id
        order {
          ${orderPartial}
        }
      }
    }
  }
`

const doDraftOrderComplete = (
  integrationId,
  id,
  paymentPending,
  conversationId,
  isDuplicate
) => async dispatch => {
  const variables = { id, paymentPending }

  const result = await dispatch(
    doGraphqlRequest(
      types.DRAFT_ORDER_COMPLETE_REQUEST,
      draftOrderCompleteMutation,
      variables,
      {
        graphqlFunc: graphql(integrationId),
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Order created',
            },
            failed: {
              enabled: true,
              content: 'Order creation failed',
            },
          },
        },
      }
    )
  )

  const orderId = result?.draftOrderComplete?.draftOrder?.order?.id

  if (orderId) {
    dispatch(
      doCreateShopifyEvent(
        integrationId,
        orderId,
        conversationId,
        isDuplicate ? 'ORDER_DUPLICATED' : 'ORDER_CREATED'
      )
    )
  }

  return result
}

export default doDraftOrderComplete
