import { camelize } from 'util/strings'
import { selectContactById } from '../../selectors'
import { doFetchContactForCustomField } from './doFetchContactForCustomField'
import { fetchContactForCustomFieldRequestKey } from '../../utils'

export const doFetchContactForPrimaryEmail = (
  email,
  isForNewTicket = false
) => async (dispatch, getState) => {
  const key = 'contact_email'
  const requestKey = camelize(fetchContactForCustomFieldRequestKey(key, email))

  const contactId = await dispatch(
    doFetchContactForCustomField(key, email, null, false, isForNewTicket, {
      requestKey,
    })
  )

  return selectContactById(getState(), contactId)
}
