/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars
import {
  FETCH_FOLDERS_SUCCESS,
  FETCH_FOLDERS_V2_SUCCESS,
  DELETE_FOLDER_SUCCESS,
} from 'ducks/folders/actionTypes/folders'
import { UPDATE_APP_DATA } from 'constants/action_types'
import { createActionTypeReducer } from 'util/reducers'
import { buildId } from 'util/globalId'
import { hash } from 'util/scatterSwap'
import { DEFAULT_SORT_ORDER_FOR_FOLDER } from 'util/folders'
import { getFolderKind } from '../utils'

// Example of how the folder looks in state
/*
{
  fol_0: {
    id: 'fol_0',
    name: 'Unassigned',
    position: 1,
    conditions: [],
    displayCountWhenInactive: true,
    matchType: 'all',
    queryId: 'folder:fol_0',
    type: 'Ticket::Filter' // Can also be Chat::Filter
  }
}
*/

const byIdInitialState = {}

const defaultSortOrderKey = folder => {
  let sortOrder
  DEFAULT_SORT_ORDER_FOR_FOLDER.forEach((func, key) => {
    if (func(folder)) {
      sortOrder = key
      // eslint-disable-next-line no-useless-return
      return
    }
  })

  return sortOrder
}

// eslint-disable-next-line no-unused-vars
function byIdFetchFolderSuccessReducer(
  draftState,
  { data: { folders = [], currentUser = {} } }
) {
  folders.forEach(folder => {
    const { id } = folder
    const folderId = `fol_${id}`
    draftState[folderId] = {
      ...folder,
      id: folderId,
      queryId: `folder:${folderId}`,
      kind: getFolderKind(folder, { currentUserId: currentUser.id }),
      defaultSortOrderKey: defaultSortOrderKey(folder),
    }
  })
  return draftState
}

function byIdFetchFolderSuccessV2Reducer(draftState, action) {
  const {
    payload: { currentUser },
    entities: {
      current: { update: { folder: foldersById = {} } = {} } = {},
    } = {},
  } = action

  const folders = Object.values(foldersById)
  folders.forEach(folder => {
    const { id: folderId, type, state } = folder
    draftState[folderId] = {
      ...folder,
      id: folderId,
      type: ['TICKET', 'ALL'].includes(type)
        ? 'Ticket::Filter'
        : 'Chat::Filter',
      visible: state === 'ACTIVE',
      queryId: `folder:${folderId}`,
      kind: getFolderKind(folder, { currentUserId: currentUser.id }),
      defaultSortOrderKey: defaultSortOrderKey(folder),
    }
  })
  return draftState
}

export const byId = createActionTypeReducer(
  {
    [FETCH_FOLDERS_SUCCESS]: byIdFetchFolderSuccessReducer,
    [UPDATE_APP_DATA]: byIdFetchFolderSuccessReducer,
    [FETCH_FOLDERS_V2_SUCCESS]: byIdFetchFolderSuccessV2Reducer,
    [DELETE_FOLDER_SUCCESS]: (draftState, { payload: { id } }) => {
      delete draftState[buildId('Folder', hash(id))]
      return draftState
    },
  },
  byIdInitialState
)
