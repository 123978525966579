import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'

import {
  FETCH_PROJECTS_ERROR,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
} from '../types'

export default function doFetchJiraServerProjects() {
  return (dispatch, getState) => {
    const token = oauthTokenSelector(getState())
    dispatch({ type: FETCH_PROJECTS_REQUEST })
    return integrationApi
      .get(token, `proxy/jira-server/rest/api/2/project`, {
        expand: 'issueTypes',
      })
      .then(({ json: projects }) => {
        dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: { projects } })
      })
      .catch(error => {
        dispatch({ error: true, type: FETCH_PROJECTS_ERROR, payload: error })
      })
  }
}
