import { useEffect, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEditor } from 'shared/editor/utils'
import {
  selectSelectedMentionSelectorAgentId,
  selectSuppressMentionSelector,
  selectIsMentioningAgent,
} from 'selectors/mentions'
import { ENTER, TAB } from 'constants/keys'
import { isCmdEnter } from 'util/keys'
import { doMentionAgent } from 'actions/tickets'
import { doUpdateAgentMention } from 'actions/app'

export const useEditorInstance = () => {
  const editorWrapperRef = useRef()
  const [editorInstance, setEditorInstance] = useState()

  useEffect(() => {
    if (!editorWrapperRef.current) {
      return
    }

    const editors = getEditor().editorManager.get()
    const instance = editors.find(editor => {
      return !!editorWrapperRef.current.querySelector(`#${editor.id}`)
    })

    setEditorInstance(instance)
  }, [])

  return {
    editorWrapperRef,
    editorInstance,
  }
}

export const useEditorMentions = ({
  editorInstance,
  onSend,
  onStartTyping,
}) => {
  const dispatch = useDispatch()
  const selectedMentionSelectorAgentId = useSelector(state =>
    selectSelectedMentionSelectorAgentId(state)
  )
  const suppressMentionSelector = useSelector(state =>
    selectSuppressMentionSelector(state)
  )
  const isMentioning = useSelector(state => selectIsMentioningAgent(state))
  const [word, setWord] = useState(null)

  const onKeyDown = useCallback(
    e => {
      // do not re-do the whole mention UI, we're going to dispatch a keydown event
      // so that the Mentions dropdown will capture it and behave like it did before
      // it's needed because tinymce events happen within an iframe
      const event = document.createEvent('Event')
      const type = e.type

      // can bubble, and is cancellable
      event.initEvent(`editor-${type}`, true, true)
      event.forwarded = e
      document.dispatchEvent(event)

      if ((e.keyCode === TAB || e.keyCode === ENTER) && word) {
        if (!suppressMentionSelector) {
          dispatch(
            doMentionAgent(selectedMentionSelectorAgentId, editorInstance)
          )
          e.preventDefault()
          e.stopPropagation()
        }
      }

      if (isCmdEnter(e) && onSend) {
        e.preventDefault()
        e.stopPropagation()
        return onSend()
      }

      if (onStartTyping) {
        onStartTyping()
      }

      return true
    },
    [
      dispatch,
      editorInstance,
      word,
      selectedMentionSelectorAgentId,
      suppressMentionSelector,
      onSend,
      onStartTyping,
    ]
  )

  const closeMentionModalUnlessClosed = () => {
    if (!isMentioning) return
    dispatch(doUpdateAgentMention(null))
  }

  const onMention = mention => {
    const found = mention

    if (!found) {
      setWord(null)
      return closeMentionModalUnlessClosed()
    }

    const foundWord = found.word
    setWord(foundWord)

    if (foundWord.charAt(0) === '@') {
      return dispatch(doUpdateAgentMention(found))
    }

    return closeMentionModalUnlessClosed()
  }

  return {
    onKeyDown,
    onMention,
  }
}
