import {
  FETCH_COMPONENTS_ERROR,
  FETCH_COMPONENTS_REQUEST,
  FETCH_COMPONENTS_SUCCESS,
} from '../types'

const initialState = { byId: {}, errored: false, loading: false }

export default function reduceComponents(
  state = initialState,
  { type, payload: components = [] }
) {
  if (type === FETCH_COMPONENTS_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  if (type === FETCH_COMPONENTS_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }
  if (type === FETCH_COMPONENTS_SUCCESS) {
    return {
      ...state,
      byId: {
        ...components.reduce((result, component) => {
          // eslint-disable-next-line no-param-reassign
          result[component.id] = component
          return result
        }, {}),
      },
      errored: false,
      loading: false,
    }
  }
  return state
}
