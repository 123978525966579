import { oauthTokenSelector } from 'selectors/app'
import { selectCompanyProperties } from 'ducks/integrations/hubspot/selectors'
import config from 'config'

import * as api from '../api'
import {
  FETCH_COMPANY_PROPERTIES_REQUEST,
  FETCH_COMPANY_PROPERTIES_SUCCESS,
  FETCH_COMPANY_PROPERTIES_FAILURE,
} from '../types'

export default function doFetchCompanyProperties() {
  return (dispatch, getState) => {
    const state = getState()

    const properties = selectCompanyProperties(state)
    if (properties) return Promise.resolve(properties)
    dispatch({
      type: FETCH_COMPANY_PROPERTIES_REQUEST,
    })
    const token = oauthTokenSelector(state)
    return api
      .getCompanyProperties(token)
      .then(payload => {
        const array = Object.values(payload.response)
        dispatch({
          type: FETCH_COMPANY_PROPERTIES_SUCCESS,
          data: {
            properties: array,
          },
        })
        return payload
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        if (config.isDevelopment) console.error(error)
        return dispatch({
          type: FETCH_COMPANY_PROPERTIES_FAILURE,
          data: {
            error,
          },
        })
      })
  }
}
