import { doSubscribe } from 'actions/webPush/doSubscribe'
import { setMatrixAudioNotificationState } from 'util/audio'
import { isWebPushSupported } from 'util/browser'
import { isDefault } from 'util/webPush/permissions'
import { NOTIFICATIONS_STATE_UPDATE } from '../actionTypes/notifications'

export function doUpdateAudioNotificationsFromUI(
  enabled,
  skipSubscriptionCheck = false
) {
  return dispatch => {
    // NOTE (jscheel): If webPush is supported but the user has not made a
    // decision on allowing notifications, we ask them to subscribe to push
    // notifications so that we don't have to rely on realtime. Even if they
    // choose to block push notifications, we will still try to update through
    // realtime to get the notifications to users as best as possible.
    let subPromise = Promise.resolve()
    if (
      !skipSubscriptionCheck &&
      enabled &&
      isWebPushSupported() &&
      isDefault()
    ) {
      subPromise = dispatch(doSubscribe())
    }

    return subPromise.then(() => {
      dispatch({
        type: NOTIFICATIONS_STATE_UPDATE,
        payload: {
          enabled,
        },
      })
      return setMatrixAudioNotificationState(enabled)
    })
  }
}
