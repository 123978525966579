export const USAGE_INBOX_MAILBOXES = 'mailboxes'
export const USAGE_INBOX_CHANNELS = 'channels'
export const USAGE_CHAT_CHANNELS = 'chat_channels'
export const USAGE_LIVE_CHAT_CHANNELS = 'live_chat_channels'
export const USAGE_SOCIAL_CHAT_CHANNELS = 'social_chat_channels'
export const USAGE_INBOX_INTEGRATIONS = 'integrations'
export const USAGE_INBOX_USERS = 'users'
export const USAGE_INBOX_TEAMS = 'teams'
export const USAGE_INBOX_SATISFACTIONS_RATINGS = 'satisfactions_ratings'
export const USAGE_INBOX_RULES = 'rules'
export const USAGE_INBOX_CUSTOM_PROFILE = 'custom_profile'
export const USAGE_INBOX_CANNED_REPLIES = 'canned_replies'
export const USAGE_INBOX_PREMIUM_INTEGRATIONS = 'premium_integrations'
export const USAGE_INBOX_FOLDERS = 'folders'
export const USAGE_INBOX_RULES_TIER_1 = 'rules_tier_1'
export const USAGE_INBOX_RULES_TIER_2 = 'rules_tier_2'
export const USAGE_INBOX_CANNED_REPLIES_TIER_1 = 'canned_replies_tier_1'
export const USAGE_INBOX_WIDGETS = 'widgets'
export const USAGE_INBOX_API_ACCESS = 'api_access'
export const USAGE_INBOX_WEBHOOKS = 'webhooks'
export const USAGE_INBOX_CUSTOM_FIELDS = 'custom_fields'
export const USAGE_INBOX_BRANDING = 'conversation_branding'
export const USAGE_INBOX_VIEWERS = 'viewer_users'

export const USAGE_KB_KBS = 'kbs'
export const USAGE_KB_ARTICLES = 'articles'
export const USAGE_KB_BRANDING = 'kb_branding'
export const USAGE_KB_CUSTOM_SCRIPTS = 'custom_scripts'
export const USAGE_KB_PRIVATE = 'private_kbs'
export const USAGE_KB_SEO = 'seo'

export const USAGE_AI_MAX_DRAFT_GENERATIONS = 'ai_draft_generations'
export const USAGE_AI_MAX_CLASSIFICATIONS = 'ai_classifications'
