import React, { PureComponent } from 'react'
import { ENTER } from 'constants/keys'
import styles from './styles.css'

export default class SearchInput extends PureComponent {
  componentDidMount() {
    if (this.searchInput.current) {
      this.searchInput.current.focus()
    }
  }

  onInput = evt => {
    const { onSearch } = this.props
    onSearch(evt.target.value)
  }

  onKeyDown = evt => {
    const { onEnter } = this.props
    if (onEnter && evt.keyCode === ENTER) return onEnter()
    return null
  }

  render() {
    const { term } = this.props
    this.searchInput = React.createRef()
    return (
      <input
        ref={this.searchInput}
        className={styles.search}
        placeholder="Search..."
        value={term}
        onKeyDown={this.onKeyDown}
        onInput={this.onInput}
      />
    )
  }
}
