import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectQueryParams } from 'selectors/location'
import { selectPreviousDrawer } from 'ducks/drawers/selectors'
import Drawers from 'ducks/drawers/components'
import {
  extractDrawerPropsById,
  stripDrawerIdsFromProps,
  getCurrentDrawerId,
} from '../util'
import { useDrawerCommons } from './useDrawerCommons'
import { NullComponent } from './NullComponent'
import { drawerConfig } from './drawerConfig'

export function useRenderDrawers() {
  const queryParams = useSelector(selectQueryParams)
  const drawerId = getCurrentDrawerId(queryParams)
  const previousDrawer = useSelector(selectPreviousDrawer)
  const previousDrawerId = previousDrawer && previousDrawer.drawerId
  const rawDrawerProps = extractDrawerPropsById(drawerId, queryParams)

  const {
    drawer: drawerType,
    drawerResourceId,
    ...restSanitizedDrawerProps
  } = stripDrawerIdsFromProps(rawDrawerProps)

  const Drawer = drawerType ? Drawers[drawerType] : NullComponent

  const { closeIgnoresStack = false, additionalProps = {} } =
    drawerConfig[drawerId] || {}

  const { closeDrawer, updateDrawerParameter } = useDrawerCommons({
    closeIgnoresStack,
    drawerId,
    previousDrawerId,
  })

  const closeDrawerIgnoreStack = useCallback(
    options => {
      closeDrawer({ ...options, ignoreStack: true })
    },
    [closeDrawer]
  )

  const drawerProps = {
    drawer: drawerType,
    drawerResourceId,
    ...restSanitizedDrawerProps,
    ...additionalProps,
    open: true,
    onClose: closeDrawer,
    onExit: closeDrawerIgnoreStack,
    updateDrawerParameter,
    key: `${drawerType}-${drawerId}`,
    drawerId,
    previousDrawer,
  }

  return {
    // eslint-disable-next-line react/jsx-filename-extension
    Drawer,
    drawerProps,
  }
}
