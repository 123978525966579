import { createSelector } from 'reselect'
import { selectCurrentUserGlobalId } from 'ducks/currentUser/selectors'
import { selectBase as selectRequests } from 'ducks/requests/selectors'

const selectContactDetailsColumnPreferencesRequest = createSelector(
  selectCurrentUserGlobalId,
  selectRequests,
  (agentId, requests) => {
    return requests[`FetchAgentPreferences(${agentId},contact_details_column)`]
  }
)

export function selectContactDetailsColumnPreferencesIsLoading(state) {
  const request = selectContactDetailsColumnPreferencesRequest(state)
  return request && request.loading
}

export function selectContactDetailsColumnPreferencesIsLoaded(state) {
  const request = selectContactDetailsColumnPreferencesRequest(state)
  return request && request.loaded
}

export function selectAgentPreferences(state) {
  return state.agentPreferences.byId
}
