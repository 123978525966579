import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'
import {
  FETCH_COMPONENTS_ERROR,
  FETCH_COMPONENTS_REQUEST,
  FETCH_COMPONENTS_SUCCESS,
} from '../types'

export default function doFetchJiraServerComponents(projectId) {
  return (dispatch, getState) => {
    const meta = { projectId }
    const token = oauthTokenSelector(getState())
    dispatch({
      type: FETCH_COMPONENTS_REQUEST,
      meta,
    })
    integrationApi
      .get(
        token,
        `proxy/jira-server/rest/api/2/project/${projectId}/components`
      )
      .then(({ json: payload }) => {
        dispatch({
          type: FETCH_COMPONENTS_SUCCESS,
          meta,
          payload,
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_COMPONENTS_ERROR,
          error: true,
          meta,
          payload: error,
        })
      })
  }
}
