import { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsZapierInstalled,
  selectZapierRemoveAccessTokenRequest,
} from './selectors'
import { doRemoveZapierAccessTokens } from './operations'

let globalLoaded = false

function loadScript(onLoad) {
  const script = document.createElement('script')
  script.type = 'module'
  script.src =
    'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js'
  script.onload = onLoad
  document.head.appendChild(script)
}

function loadCss(onLoad) {
  const stylesheet = document.createElement('link')
  stylesheet.rel = 'stylesheet'
  stylesheet.href =
    'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css'
  stylesheet.onload = onLoad
  document.head.appendChild(stylesheet)
}

export function useZapier() {
  const [loaded, setLoaded] = useState(globalLoaded)
  const scriptLoaded = useRef(globalLoaded)
  const cssLoaded = useRef(globalLoaded)
  const dispatch = useDispatch()

  const isInstalled = useSelector(selectIsZapierInstalled)
  const removeAccessTokenRequest = useSelector(
    selectZapierRemoveAccessTokenRequest
  )

  const handleScriptLoad = useCallback(() => {
    scriptLoaded.current = true
    if (cssLoaded.current) {
      setLoaded(true)
    }
  }, [])

  const handleCssLoad = useCallback(() => {
    cssLoaded.current = true
    if (scriptLoaded.current) {
      setLoaded(true)
    }
  }, [])

  const deleteAccessTokens = useCallback(
    () => {
      dispatch(doRemoveZapierAccessTokens())
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (loaded) {
        globalLoaded = true
      }
    },
    [loaded]
  )

  useEffect(() => {
    if (!globalLoaded) {
      loadScript(handleScriptLoad)
      loadCss(handleCssLoad)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loaded,
    isInstalled,
    deleteAccessTokens,
    request: removeAccessTokenRequest,
  }
}
