import { doGraphqlRequest } from 'ducks/requests/operations'
import { FETCH_EVENTS } from './types'

export const EVENT_FIELDS = `
id
createdAt
data
type
system
performedBy
`

const EVENTS_DATA_QUERY = `
query EventsDataQuery {
  events {
    ${EVENT_FIELDS}
  }
}
`

export const doFetchEvents = () => dispatch => {
  return dispatch(doGraphqlRequest(FETCH_EVENTS, EVENTS_DATA_QUERY))
}
