import { contactCustomFieldValuesSubQueryClientPaging } from '.'

export const fetchConversationContactCustomFieldsFirstPageClientPagingQuery = `
  query ConversationContactByConversationNumberFirstPage($contactCustomFieldIds: [ID!], $number: Int!) {
    conversation(number: $number) {
      id
      number
      contact {
        id
        avatarUrl
        companies {
          nodes {
            id
          }
        }
        ${contactCustomFieldValuesSubQueryClientPaging}
        email
        firstName
        lastName
        name
        lastSeenAt
      }
    }
  }
`
