import createCachedSelector from 're-reselect'

import selectGitHubNodesById from './selectGitHubNodesById'

export default createCachedSelector(
  selectGitHubNodesById,
  (state, { repositoryId }) => repositoryId,
  (nodes, repositoryId) => {
    const repository = nodes[repositoryId]
    return (
      repository &&
      repository.labels &&
      repository.labels.map(({ color, id, name }) => ({
        color,
        value: id,
        text: name,
      }))
    )
  }
)((state, { repositoryId }) => repositoryId || 'NULL')
