import { doGraphqlRequest } from 'ducks/requests/operations'
import { UPDATE_INTEGRATION_SETTINGS } from '../types'
import splitAuthValues from '../utils/splitAuthValues'
import { doUpsertJiraServerCredentials } from './doUpsertJiraServerCredentials'
import { updateIntegrationProviderSettingsMutation } from './updateIntegrationProviderSettingsMutation'

export const doUpdateIntegrationProviderSettings = (
  provider,
  values
) => dispatch => {
  const [settingValues, authValues] = splitAuthValues(values)
  const variables = {
    provider: provider.toUpperCase(),
    settings: settingValues,
  }

  if (provider === 'jira_server')
    dispatch(doUpsertJiraServerCredentials(authValues))
  dispatch(
    doGraphqlRequest(
      UPDATE_INTEGRATION_SETTINGS,
      updateIntegrationProviderSettingsMutation,
      variables,
      { app: true }
    )
  )
}
