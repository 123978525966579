import config from 'config'
import { getMatrixClient } from './client'
import {
  END_USER_REGEX,
  AGENT_USER_REGEX,
  SUPER_USER_REGEX,
  BOT_USER_REGEX,
  BRIDGE_USER_REGEX,
} from './constants'

const { chatServerUrl: MATRIX_CHAT_SERVER_URL } = config

export function isEndUser(userId) {
  if (!userId) return false
  return userId.match(END_USER_REGEX) !== null
}

export function isAgentUser(userId) {
  if (!userId) return false
  return userId.match(AGENT_USER_REGEX) !== null
}

export function isSuperUser(userId) {
  if (!userId) return false
  return userId.match(SUPER_USER_REGEX) !== null
}

export function isBotUser(userId) {
  if (!userId) return false
  return userId.match(BOT_USER_REGEX) !== null
}

export function isBridgeUser(userId) {
  if (!userId) return false
  return userId.match(BRIDGE_USER_REGEX) !== null
}

export function isBotOrBridgeUser(userId) {
  if (!userId) return false
  return isBotUser(userId) || isBridgeUser(userId)
}

export function isUser(userId) {
  if (!userId) return false
  return (
    userId.match(END_USER_REGEX) !== null ||
    userId.match(AGENT_USER_REGEX) !== null ||
    userId.match(SUPER_USER_REGEX) !== null
  )
}

export function getUserType(userId) {
  if (userId.includes('_e:')) return 'enduser'
  if (userId.includes('_a:')) return 'agentuser'
  if (userId.includes('_s:')) return 'superuser'
  return 'adminuser'
}

export function getChatAdminUserId() {
  const MATRIX_CHAT_SERVER_HOST = MATRIX_CHAT_SERVER_URL.replace(
    /https?:\/\//g,
    ''
  )
  return `@chat-admin:${MATRIX_CHAT_SERVER_HOST}`
}

export function getAvatarUrl(mcxUrl, options) {
  const { avatarSize = 96 } = options || {}
  if (!mcxUrl) return null
  const urlRegex = /^mxc:\/\/(?<host>.*?)\/(?<mediaId>.*)$/
  const match = mcxUrl.match(urlRegex)
  if (!match) return null
  const { host, mediaId } = match.groups
  return `https://${host}/_matrix/media/r0/thumbnail/${host}/${mediaId}?width=${avatarSize}&height=${avatarSize}`
}

export function getCurrentMatrixUserId() {
  const client = getMatrixClient()
  if (!client) return null
  return client.getUserId()
}

export function getAgentIdFromMatrixUserId(userId) {
  if (!isAgentUser(userId)) return null
  return userId.substr(1, userId.indexOf('_') - 1)
}
