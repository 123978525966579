import { doLinkResource } from 'actions/userIntegrations'

import { selectIntegrationCredentialsForProvider } from 'ducks/integrations/selectors'
import { selectJiraIssuesById } from 'ducks/integrations/jiraShared'

export default function doUnlinkJiraServerIssue(issueId) {
  return (dispatch, getState) => {
    const state = getState()
    const issues = selectJiraIssuesById(state, { source: 'jiraServer' })
    const { url: siteUrl } = selectIntegrationCredentialsForProvider(state, {
      provider: 'jira_server',
    })
    const issue = issues[issueId]
    if (!issue) return Promise.reject('Cannot find Jira issue')
    const {
      fields: { summary },
      key,
    } = issue
    const url = `${siteUrl}/browse/${key}`

    return dispatch(
      doLinkResource({
        id: issueId,
        provider: 'jira_server',
        removed: true,
        title: `[${key}] ${summary}`,
        url,
      })
    )
  }
}
