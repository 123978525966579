import integrationApi from '../api'

const TRELLO_ENDPOINT_PREFIX = 'proxy/trello/1'

function parseProxyResponse({ code, json }) {
  if (code === 200 || code === 201) {
    return Promise.resolve(json)
  }
  if (code >= 400) Promise.reject(json)
  return Promise.reject()
}

function get(token, path, params) {
  return integrationApi
    .get(token, TRELLO_ENDPOINT_PREFIX + path, params)
    .then(parseProxyResponse)
}

function put(token, path, params) {
  return integrationApi
    .put(token, TRELLO_ENDPOINT_PREFIX + path, params)
    .then(parseProxyResponse)
}

function post(token, path, data) {
  return integrationApi
    .post(token, TRELLO_ENDPOINT_PREFIX + path, data)
    .then(parseProxyResponse)
}

function deleteRequest(token, path, data) {
  return integrationApi
    .delete(token, TRELLO_ENDPOINT_PREFIX + path, data)
    .then(parseProxyResponse)
}

window.trelloApi = {
  get,
  put,
  post,
  delete: deleteRequest,
}

const CARD_FIELDS =
  'closed,dateLastActivity,desc,due,idBoard,idLabels,idList,idMembers,name,url'
export function card(token, id) {
  return get(token, `/cards/${id}`, {
    fields: CARD_FIELDS,
  })
}

export function updateCard(token, id, data) {
  return put(token, `/cards/${id}`, data)
}
export function listCardsForBoard(token, board) {
  return get(token, `/boards/${board}/cards`, {
    fields: CARD_FIELDS,
  })
}

export function listCardsForList(token, listId) {
  return get(token, `/lists/${listId}/cards`, {
    fields: CARD_FIELDS,
  })
}

export function listBoards(token) {
  return get(token, '/members/me', {
    fields: 'boards',
    boards: 'open',
    board_fields: 'name',
  })
}

export function listBoardDetails(token, board) {
  return get(token, `/boards/${board}`, {
    fields: 'id,labels,lists,members,name,url',
    labels: 'all',
    lists: 'open',
    list_fields: 'id,idBoard,name,pos',
    label_fields: 'color,idBoard,name',
    members: 'all',
    member_fields: 'avatarUrl,fullName,memberType',
  })
}

export function list(token, listId) {
  return get(token, `/lists/${listId}`, {
    fields: 'name',
  })
}

export function attachTicketToCard(token, cardId, url, name) {
  return post(token, `/cards/${cardId}/attachments`, {
    url,
    name,
  })
}

export function detachTicketFromCard(token, cardId, url) {
  return get(token, `/cards/${cardId}/attachments`).then(attachments => {
    const { id: attachmentId } =
      attachments.find(({ url: attachmentUrl }) => url === attachmentUrl) || {}
    if (!attachmentId) return null
    return deleteRequest(token, `/cards/${cardId}/attachments/${attachmentId}`)
  })
}

export function createCard(token, data) {
  return post(token, '/cards', data)
}
