import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePrevious from 'util/hooks/usePrevious'
import { selectCurrentBatchJobsByType, selectIsPolling } from './selectors'
import { doFetchBatchJobs } from './actions'

export function useBatchJobs(type) {
  const dispatch = useDispatch()
  const [isInitialPoll, setIsInitialPoll] = useState(true)
  const [jobs, setJobs] = useState([])

  const stateJobs = useSelector(state =>
    selectCurrentBatchJobsByType(state, type)
  )
  const previousJobs = usePrevious(jobs)
  const isPolling = useSelector(selectIsPolling)
  const minDisplayTimeRef = useRef(null)

  useEffect(
    () => {
      if (isInitialPoll || (jobs.length > 0 && !isPolling)) {
        setIsInitialPoll(false)
        dispatch(
          doFetchBatchJobs({
            states: ['created', 'started'],
            debounce: true,
            shouldPoll: true,
          })
        )
      }
    },
    [dispatch, isInitialPoll, isPolling, jobs]
  )

  useEffect(
    () => {
      if (stateJobs.length === 0 && previousJobs?.length > 0) {
        minDisplayTimeRef.current = setTimeout(() => {
          setJobs(stateJobs)
        }, 5000)
      } else {
        setJobs(stateJobs)
      }

      return () => {
        if (minDisplayTimeRef.current) clearTimeout(minDisplayTimeRef.current)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateJobs]
  )

  return {
    jobs,
  }
}
