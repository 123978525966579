export const CREATING_STARTED = 'inappcards/changeUser/CREATING_STARTED'
export const SEARCHING_STARTED = 'inappcards/changeUser/SEARCHING_STARTED'
export const RESET_STATES = 'inappcards/changeUser/RESET_STATES'

export const SEARCH_USER_REQUEST = 'inappcards/changeUser/SEARCH_USER_REQUEST'
export const SEARCH_USER_SUCCESS = 'inappcards/changeUser/SEARCH_USER_SUCCESS'
export const SEARCH_USER_FAILURE = 'inappcards/changeUser/SEARCH_USER_FAILURE'

export const ADD_NEW_USER_REQUEST = 'inappcards/changeUser/ADD_NEW_USER_REQUEST'
export const ADD_NEW_USER_SUCCESS = 'inappcards/changeUser/ADD_NEW_USER_SUCCESS'
export const ADD_NEW_USER_FAILURE = 'inappcards/changeUser/ADD_NEW_USER_FAILURE'

export const CHANGE_USER_REQUEST = 'inappcards/changeUser/CHANGE_USER_REQUEST'
export const CHANGE_USER_SUCCESS = 'inappcards/changeUser/CHANGE_USER_SUCCESS'
export const CHANGE_USER_FAILURE = 'inappcards/changeUser/CHANGE_USER_FAILURE'
