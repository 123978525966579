import createCachedSelector from 're-reselect'

import selectTrelloListsById from './selectTrelloListsById'

const NO_LIST = 'NO_LIST'

export default createCachedSelector(
  selectTrelloListsById,
  (_, listId) => listId,
  (lists, listId) => {
    const list = lists && lists[listId]
    return list && list.loading
  }
)((_, listId) => listId || NO_LIST)
