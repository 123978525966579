import createCachedSelector from 're-reselect'

import { selectCustomerIsReloading } from './customers'
import { selectSubscriptionsIsLoading } from './subscriptions'

export const selectIsLoading = createCachedSelector(
  selectCustomerIsReloading,
  (state, integrationId, { customerId }) =>
    selectSubscriptionsIsLoading(state, customerId),
  (customerReloading, subscriptionsLoading) => {
    return customerReloading || subscriptionsLoading
  }
)(
  (state, integrationId, { customerId }) =>
    `${integrationId || 'unknown'}-${customerId || 'unknown'}`
)
