export const removeIntegrationsByProviderMutation = `
  mutation IntegrationDeleteByProvider(
    $provider: IntegrationProvider!,
  ) {
    integrationDeleteByProvider(input: {
      provider: $provider
    }) {
      deletedIntegrationIds
    }
  }
`
