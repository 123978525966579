import React, { useMemo, useCallback } from 'react'
import { motion } from 'framer-motion'
import DOMPurify from 'dompurify'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Negative from '@groovehq/internal-design-system/lib/assets/icons/Negative'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import styled from '@emotion/styled'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CANNED_REPLIES_UPDATE } from 'ducks/drawers/types'
import { styles } from './styles'
import { getActionTitle } from './util'
import Tags from './Tags'

const animations = {
  initial: { scale: 0.8, opacity: 0.9 },
  animate: { scale: 1, opacity: 1 },
}

const Container = styled.div`
  ${styles.preview};
`

const Preview = ({
  cannedReplyPreview,
  open,
  onVisibleChange,
  arrowPosition,
}) => {
  const {
    id,
    subject,
    body,
    actions = [],
    tagActions = {},
    meta: { templateSubjectUsed } = {},
  } =
    cannedReplyPreview || {}
  const { openDrawer: openUpdateDrawer } = useDrawer({
    type: DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  })

  const actionText = useMemo(
    () => actions.map(action => getActionTitle(action)).join(', '),
    [actions]
  )

  const handleEditDrawer = useCallback(
    () => {
      openUpdateDrawer(id)
      onVisibleChange(false)
    },
    [openUpdateDrawer, id, onVisibleChange]
  )

  const actionList = (
    <div>
      <h3 className="grui mb-2">Actions:</h3>
      <div css={styles.actions}>
        <Tooltip title={actionText}>
          <span>{actionText}</span>
        </Tooltip>
      </div>
    </div>
  )

  const removedTagList = useMemo(
    () => {
      return (
        <div>
          <h3 className="grui">Tags removed:</h3>
          {tagActions.allRemoved && (
            <ul className="grui mt-0 mb-10 pl-12">
              <li>All tags removed</li>
            </ul>
          )}
          {!tagActions.allRemoved && (
            <div css={styles.tags}>
              {tagActions.removed?.map(({ name, id: tagId, color }) => (
                <div key={tagId} style={{ background: color }} css={styles.tag}>
                  {name}
                </div>
              ))}
            </div>
          )}
        </div>
      )
    },
    [tagActions.allRemoved, tagActions.removed]
  )

  const sanitizedBody = useMemo(
    () => {
      const customPurify = DOMPurify()
      customPurify.addHook('afterSanitizeAttributes', node => {
        if (node.hasAttribute('style')) {
          const styleAttribute = node.getAttribute('style')
          const newStyle = styleAttribute
            .split(';')
            // Remove color style, because we want to show inherited color for normal text
            // Remove background-color, in case it's set to white, which will make the text invisible
            .filter(
              style =>
                !style.trim().startsWith('color:') &&
                !style.trim().startsWith('background-color:')
            )
            .join(';')
          node.setAttribute('style', newStyle)
        }
      })
      return customPurify.sanitize(body)
    },
    [body]
  )

  if (!open || !id) {
    return null
  }

  return (
    <motion.div
      initial={animations.initial}
      animate={animations.animate}
      className="preview"
      key={id}
    >
      <Container top={arrowPosition}>
        <div css={styles.perviewDetail}>
          <div>
            <div css={styles.heading}>
              <h3 className="grui">
                Subject:
                {!templateSubjectUsed && (
                  <Tooltip title="Conversation subject will be used">
                    <span>
                      <i>&nbsp;(Not applied)</i>
                      <Negative />
                    </span>
                  </Tooltip>
                )}
              </h3>
              <div css={styles.btnWrapper}>
                <Button
                  type="tertiary"
                  size="small"
                  css={text.styles.fontMedium}
                  onClick={handleEditDrawer}
                >
                  Edit template
                </Button>
              </div>
            </div>
            <p className="grui mt-0 ml-5 mb-7">
              {subject?.trim() ? (
                subject
              ) : (
                <span css={[text.styles.italic, styles.emptySubject]}>
                  No subject added…
                </span>
              )}
            </p>
          </div>
          <h3 className="grui">Body:</h3>
          <div
            css={styles.bodyContent}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: sanitizedBody }}
          />
          {!!tagActions.added?.length && <Tags tagAdded={tagActions.added} />}
          {(tagActions.allRemoved || !!tagActions.removed?.length) &&
            removedTagList}
          {!!actions.length && actionList}
        </div>
      </Container>
    </motion.div>
  )
}

export default Preview
