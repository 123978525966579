export const updateIntegrationProviderSettingsMutation = `
  mutation IntegrationProviderSettingsUpdate(
    $provider: IntegrationProvider!,
    $settings: JSON!
  ) {
    integrationProviderSettingsUpdate(input: {
      provider: $provider,
      settings: $settings
    }) {
      integrations {
        nodes {
          id
          settings {
            settings
          }
        }
      }
    }
  }
`
