import { createSelector } from 'reselect'

import selectCurrentTicketLinkedIntegrationsResources from 'ducks/integrations/selectors/selectCurrentTicketLinkedIntegrationsResources'
import { uniqBy } from 'util/arrays'

import selectTrelloCardsById from './selectTrelloCardsById'
import selectTrelloBoardsById from './selectTrelloBoardsById'
import selectTrelloMembersById from './selectTrelloMembersById'
import selectTrelloListsById from './selectTrelloListsById'
import selectTrelloLabelsById from './selectTrelloLabelsById'

export default createSelector(
  selectCurrentTicketLinkedIntegrationsResources,
  selectTrelloCardsById,
  selectTrelloBoardsById,
  selectTrelloMembersById,
  selectTrelloListsById,
  selectTrelloLabelsById,
  (linkedResources, cards, boards, members, lists, labels) => {
    if (!linkedResources) return linkedResources
    const uniq = uniqBy(
      [...linkedResources].reverse(),
      ({ externalId }) => externalId
    )
      .reverse()
      .filter(x => !!x)
      .filter(
        ({ provider, removed }) => provider === 'trello' && removed !== true
      )

    if (!uniq) return []

    return uniq
      .map(linkedResource => {
        const { externalId } = linkedResource
        if (!externalId) return null
        const card = cards[externalId]
        const { idBoard, idLabels = [], idList, idMembers = [] } = card || {}
        const createdAt = new Date(
          1000 * parseInt(externalId.substring(0, 8), 16)
        )
        const { due, dateLastActivity } = card || {}
        return {
          ...linkedResource,
          ...card,
          board: boards && boards[idBoard],
          createdAt,
          due: due && new Date(due),
          labels: idLabels.map(idLabel => labels[idLabel]),
          list: lists && lists[idList],
          members: idMembers.map(idMember => members[idMember]),
          updatedAt: new Date(dateLastActivity),
        }
      })
      .filter(x => !!x)
  }
)
