import { createBasicSelector } from 'util/redux'
import { selectAccountPreferenceReportingPermission } from 'selectors/app'
import { selectIsFreemium } from 'ducks/billing/selectors/selectIsFreemium'
import { selectCurrentUser } from './selectCurrentUser'

export const selectReportingPermitted = createBasicSelector(
  selectCurrentUser,
  selectAccountPreferenceReportingPermission,
  selectIsFreemium,
  (currentUser, reportingPermission, isFreemium) => {
    if (isFreemium) return false
    if (currentUser.role === 'owner') {
      return true
    }

    if (reportingPermission === 'admin' && currentUser.role === 'admin') {
      return true
    }

    if (reportingPermission === 'all') {
      return true
    }

    return false
  }
)
