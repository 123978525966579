import { emit } from 'util/realtime'
import { v4 as uuidV4 } from 'uuid'
import debug, { leaveBreadcrumb } from 'util/debug'

export function fetchCollisionStatuses(uuid) {
  return new Promise((resolve, reject) => {
    const requestId = uuid || uuidV4()
    emit('fetchCollisionStatuses', { requestId }, (err, statuses) => {
      if (err) {
        leaveBreadcrumb('fetchCollisionStatuses Error', {
          requestId,
          errorMessage: err.message,
        })
        debug(err)
        reject(err)
      } else {
        resolve(statuses)
      }
    })
  })
}
