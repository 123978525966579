import { produce } from 'immer'
import { combineReducers } from 'core/redux'
import merge from 'deepmerge'
import { emptyObj } from 'util/objects'
import { withPush, without } from 'util/arrays'

// Given an entity type this reducer factory will retrieve any matching
// entities on any action and place them in its state branch.
function overwriteMerge(destinationArray, sourceArray) {
  return sourceArray
}

function forEachEntity(draftState, entities, callback) {
  Object.keys(entities).forEach(entityType => {
    const entitiesOfType = entities[entityType]
    Object.keys(entitiesOfType).forEach(entityId => {
      const entity = entitiesOfType[entityId]
      if (!draftState[entityType]) {
        // eslint-disable-next-line no-param-reassign
        draftState[entityType] = { byId: {}, ids: [] }
      }
      callback(entityId, entity, entityType)
    })
  })
}

function updateEntities(draftState, entities) {
  forEachEntity(draftState, entities, (entityId, entity, entityType) => {
    // eslint-disable-next-line no-param-reassign
    draftState[entityType].byId[entityId] = merge(
      draftState[entityType]?.byId[entityId] || {},
      entity,
      {
        arrayMerge: overwriteMerge,
        clone: false,
      }
    )
    withPush(draftState[entityType].ids, entityId)
  })
}

function replaceEntities(draftState, entities) {
  forEachEntity(draftState, entities, (entityId, entity, entityType) => {
    // eslint-disable-next-line no-param-reassign
    draftState[entityType].byId[entityId] = entity
    withPush(draftState[entityType].ids, entityId)
  })
}

function removeEntities(draftState, entities) {
  forEachEntity(draftState, entities, (entityId, _entity, entityType) => {
    // eslint-disable-next-line no-param-reassign
    delete draftState[entityType].byId[entityId]
    without(draftState[entityType].ids, entityId, { clearAll: true })
  })
}

function clearEntities(draftState, clear) {
  const isClearAll = clear.includes('all')
  Object.keys(draftState).forEach(entityType => {
    if (isClearAll || clear.includes(entityType)) {
      const entitiesOfType = draftState[entityType].byId
      Object.keys(entitiesOfType).forEach(entityId => {
        // eslint-disable-next-line no-param-reassign
        delete draftState[entityType].byId[entityId]
        without(draftState[entityType].ids, entityId, { clearAll: true })
      })
    }
  })
}

function syncEntities(
  draftState,
  entities,
  otherStore,
  otherStoreUpdate,
  otherStoreReplace,
  combineMode
) {
  forEachEntity(draftState, entities, (entityId, _entity, entityType) => {
    const otherStateEntity = otherStore[entityType]?.byId[entityId]
    const otherStoreUpdateEntity = otherStoreUpdate[entityType]?.find(
      e => e.id === entityId
    )
    const otherStoreReplaceEntity = otherStoreReplace[entityType]?.find(
      e => e.id === entityId
    )

    let entity = null
    if (otherStoreReplaceEntity) {
      entity = otherStoreReplaceEntity
    } else if (otherStoreUpdateEntity) {
      entity = merge(otherStateEntity || {}, otherStoreUpdateEntity, {
        arrayMerge: overwriteMerge,
        clone: false,
      })
    } else if (otherStateEntity) {
      entity = otherStateEntity
    }

    if (entity) {
      let newStateEntity = entity

      // Aka merge changes from the other store into the entity in the current state
      // Eg: Merge changes from pending customer 1 into current customer 1
      if (combineMode === 'merge') {
        newStateEntity = merge(
          draftState[entityType]?.byId[entityId] || {},
          entity,
          {
            arrayMerge: overwriteMerge,
            clone: false,
          }
        )
      }
      // eslint-disable-next-line no-param-reassign
      draftState[entityType].byId[entityId] = newStateEntity
      withPush(draftState[entityType].ids, entityId)
    }
  })
}

function createEntityReducer(type) {
  return produce((draftState, action) => {
    if (!action.entities) return draftState

    const {
      update = {},
      replace = {},
      remove = {},
      mergeSync = {},
      replaceSync = {},
      clear = [],
    } =
      action.entities[type] || {}

    const otherType = type === 'current' ? 'pending' : 'current'

    const { update: otherUpdate = {}, replace: otherReplace = {} } =
      action.entities[otherType] || {}
    const entitiesState = action.entities.state || {}
    const otherState = entitiesState[otherType] || {}

    clearEntities(draftState, clear)
    updateEntities(draftState, update)
    replaceEntities(draftState, replace)
    removeEntities(draftState, remove)
    syncEntities(
      draftState,
      mergeSync,
      otherState,
      otherUpdate,
      otherReplace,
      'merge'
    )
    syncEntities(
      draftState,
      replaceSync,
      otherState,
      otherUpdate,
      otherReplace,
      'replace'
    )
    return draftState
  }, emptyObj)
}

export default combineReducers({
  current: createEntityReducer('current'),
  pending: createEntityReducer('pending'),
})
