import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import selectIntegrationCredentialsByProvider from './selectIntegrationCredentialsByProvider'

// NOTE: avoid using this selector if you have access to the integration credential id (e.g. integrations.credentials.byId['id'])
// use selectIntegrationSettingsForId instead
// reason is, we can have multiple credentials for the same provider e.g. SHOPIFY_V2 having 2 stores = 2 credentials with their own individual settings
// only use case left for this selector is ticket actions as it's linked to provider and not a credential id (see Ticket::Integration)
const selectIntegrationSettingsByProvider = createSelector(
  selectIntegrationCredentialsByProvider,
  byProvider => {
    return Object.keys(byProvider).reduce((accumulator, key) => {
      const provider = byProvider[key]
      if (provider.length) {
        // eslint-disable-next-line no-param-reassign
        accumulator[key] = byProvider[key][0]?.settings?.settings || emptyObj
      } else {
        // eslint-disable-next-line no-param-reassign
        accumulator[key] = emptyObj
      }

      return accumulator
    }, {})
  }
)

export default selectIntegrationSettingsByProvider
