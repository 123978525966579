import { emptyObj } from 'util/objects'

import { FETCH_CUSTOM_PROFILE_SUCCESS } from './types'

const defaultState = emptyObj

export default function reduceCustomProfiles(
  state = defaultState,
  {
    type,
    meta: { requestParameters: { contactId } = {} } = {},
    payload: { customProfile } = {},
  }
) {
  if (type === FETCH_CUSTOM_PROFILE_SUCCESS) {
    return {
      ...state,
      [contactId]: customProfile,
    }
  }
  return state
}
