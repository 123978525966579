import { doAppGraphqlRequest } from 'ducks/requests/operations'

import { customFieldValueNodeQuery } from '../../../customFields/operations/fetching'
import {
  selectPageIsLoading,
  selectPageRequestKey,
  selectPageRequestVariables,
  selectRequestsByKey,
} from '../../selectors'
import { contactsNormalizationSchema } from '../../schema'
import { FETCH_CONTACTS } from '../../types'

export const contactCustomFieldValuesSubQueryClientPaging = `
customFieldValues(filter: { customFieldIds: $contactCustomFieldIds }) {
  ${customFieldValueNodeQuery}
}
`

const fetchContactsQuery = `
  query Contacts(
    $first: Int,
    $after: String,
    $filter: ContactFilter,
    $orderBy: ContactOrder
  ) {
    contacts(first: $first, after: $after, filter: $filter, orderBy: $orderBy) {
      totalCount
      totalPageCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        companies {
          nodes {
            id
            createdAt
            name
            logoUrl
          }
        }
        contactType
        conversationCount
        createdAt
        email
        secondaryEmails
        lastSeenAt
        name
      }
    }
  }
`

export function doFetchContacts() {
  return (dispatch, getState) => {
    const state = getState()
    const isLoading = selectPageIsLoading(state)
    const requestKey = selectPageRequestKey(state)
    const variables = selectPageRequestVariables(state)

    if (isLoading) return null

    return dispatch(
      doAppGraphqlRequest(FETCH_CONTACTS, fetchContactsQuery, variables, {
        normalizationSchema: contactsNormalizationSchema,
        meta: {
          requestKey,
        },
      })
    )
  }
}

export function doBasicSearchContacts(search) {
  return (dispatch, getState) => {
    const state = getState()
    const requestKey = search
    const isLoading = selectRequestsByKey(state)[requestKey]?.isLoading
    const variables = {
      first: 20,
      filter: { search },
    }

    if (isLoading) return Promise.resolve(null)

    return dispatch(
      doAppGraphqlRequest(FETCH_CONTACTS, fetchContactsQuery, variables, {
        normalizationSchema: contactsNormalizationSchema,
        meta: {
          requestKey,
          mergeEntities: true,
        },
      })
    )
  }
}

export function makeEntityAvailable(result, contact) {
  // eslint-disable-next-line no-param-reassign
  result[contact.id] = {
    ...contact,
    isPartiallyLoaded: true,
    customFieldValues: contact.customFieldValues || {},
  }
  return result
}
