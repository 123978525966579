import createCachedSelector from 're-reselect'
import selectJiraProjectsById from './selectJiraProjectsById'

export default createCachedSelector(
  selectJiraProjectsById,
  (state, { projectId }) => projectId,
  (projects, projectId) => {
    const project = projects[projectId]
    return project && project.key
  }
)((state, { projectId }) => projectId)
