import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { DELETE_CUSTOM_FIELD_CATEGORY } from '../types'

const deleteQuery = `
  mutation CustomFieldCategoryDelete(
    $input: CustomFieldCategoryDeleteInput!
  ) {
    customFieldCategoryDelete(input: $input) {
      deletedCategoryId
    }
  }
`
export function doDeleteCustomFieldCategory(categoryId, newCategoryId) {
  return async dispatch => {
    const input = {
      categoryId,
    }
    if (newCategoryId) input.newCategoryId = newCategoryId
    dispatch(
      doAppGraphqlRequest(
        DELETE_CUSTOM_FIELD_CATEGORY,
        deleteQuery,
        {
          input,
        },
        {
          meta: input,
        }
      )
    )
  }
}
