import { doAppGraphqlRequest } from 'ducks/requests/operations'

import { selectAllCustomFieldCategoriesIsLoading } from '../selectors'
import { customFieldCategoriesNormalizationSchema } from '../schema'
import {
  FETCH_ALL_CUSTOM_FIELD_CATEGORIES_STARTED,
  FETCH_ALL_CUSTOM_FIELD_CATEGORIES_SUCCESS,
  FETCH_CUSTOM_FIELD_CATEGORIES,
} from '../types'

export const customFieldCategoryFields = `
  createdAt
  deletable
  id
  type
  key
  name
`
const fetchCustomFieldCategoriesQuery = `
  query CustomFieldCategories($after: String) {
    customFieldCategories(first: 20, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        ${customFieldCategoryFields}
      }
    }
  }
`
export function doFetchAllCustomFieldCategories() {
  return async (dispatch, getState) => {
    const state = getState()
    const isLoading = selectAllCustomFieldCategoriesIsLoading(state)
    if (isLoading) return null
    let hasNextPage
    let after

    dispatch({ type: FETCH_ALL_CUSTOM_FIELD_CATEGORIES_STARTED })
    do {
      const variables = {
        after,
      }
      // eslint-disable-next-line no-await-in-loop
      const result = await dispatch(
        doAppGraphqlRequest(
          FETCH_CUSTOM_FIELD_CATEGORIES,
          fetchCustomFieldCategoriesQuery,
          variables,
          {
            normalizationSchema: customFieldCategoriesNormalizationSchema,
          }
        )
      )
      const pageInfo = result.customFieldCategories.pageInfo
      hasNextPage = pageInfo.hasNextPage
      after = pageInfo.endCursor
    } while (hasNextPage)
    dispatch({ type: FETCH_ALL_CUSTOM_FIELD_CATEGORIES_SUCCESS })
    return null
  }
}
