import debouncePromise from 'debounce-promise'
import { leaveBreadcrumb } from 'util/debug'
import { selectIsEditorFocused } from 'ducks/editor'
import { selectIsAddingNote } from 'selectors/page'
import { selectIsImpersonating } from 'selectors/app/selectIsImpersonating'
import { startTyping, setCurrentAgentCollisionStatus } from './utils'
import {
  UPDATE_AGENT_TICKET_COLLISION_STATUS,
  FETCH_AGENT_COLLISION_STATUSES_SUCCESS,
} from './actionTypes'
import { fetchCollisionStatuses } from './api'

export function doRealtimeSetCurrentAgentCollisionStatus() {
  return (_, getState) => {
    const state = getState()
    const isImpersonating = selectIsImpersonating(state)

    if (isImpersonating) return

    setCurrentAgentCollisionStatus(getState)
  }
}

// eslint-disable-next-line no-underscore-dangle
const _doFetchCollisionStatuses = debouncePromise(
  async (dispatch, uuid, onComplete) => {
    try {
      const statuses = await fetchCollisionStatuses(uuid)
      if (onComplete) onComplete(statuses)
      dispatch({
        type: FETCH_AGENT_COLLISION_STATUSES_SUCCESS,
        payload: statuses,
      })
    } catch (err) {
      leaveBreadcrumb('post-subscribe fetchCollisionStatuses error', {
        error: err.message,
      })
      throw err
    }
  },
  500
)

export function doFetchCollisionStatuses(uuid, onComplete) {
  return dispatch => {
    return _doFetchCollisionStatuses(dispatch, uuid, onComplete)
  }
}

export function doUpdateAgentTicketCollisionStatus(message) {
  return dispatch => {
    const {
      data,
      meta: { action },
      createdAt,
    } = message
    const { conversationId, agentId, states = [] } = data
    dispatch({
      type: UPDATE_AGENT_TICKET_COLLISION_STATUS,
      payload: {
        action,
        agentId,
        conversationId,
        createdAt,
        states,
      },
    })
  }
}

export function doAgentStartTicketTypingNotification(
  ticketId,
  ignoreFocus = false
) {
  return (_, getState) => {
    const state = getState()
    const isEditorFocused = selectIsEditorFocused(state)
    const isAddingNote = selectIsAddingNote(state)
    const isImpersonating = selectIsImpersonating(state)

    // skip sending event because editor doesnt have focus
    if (!isEditorFocused && !ignoreFocus) return
    if (isImpersonating) return

    startTyping(ticketId, isAddingNote)
  }
}
