import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_AI_JOB_COMPOSER_RESULT_STARTED,
  FETCH_AI_JOB_COMPOSER_RESULT_END,
  FETCH_AI_JOB_COMPOSER_RESULT_CLEAR,
  FETCH_AI_JOB_COMPOSER_RESULT_INITIALIZE,
  FETCH_AI_JOB_COMPOSER_RESULT_FAILED,
} from 'ducks/ai/types'
import { SEARCH_PAGE, TICKET_PAGE } from 'constants/pages'

const initialState = {
  response: null,
  loading: false,
  loaded: false,
  error: false,
  rating: null,
  aiType: null,
  lastAiType: null,
  lastRequest: null,
}

export default createActionTypeReducer(
  {
    [FETCH_AI_JOB_COMPOSER_RESULT_STARTED]: draftState => {
      const aiType = draftState.aiType

      Object.assign(draftState, initialState)

      draftState.loading = true
      draftState.aiType = aiType

      return draftState
    },
    [FETCH_AI_JOB_COMPOSER_RESULT_END]: (draftState, { payload }) => {
      const lastAiType = draftState.aiType

      Object.assign(draftState, initialState)

      const response = payload.result.result

      draftState.response = response
      draftState.loading = false
      draftState.loaded = true
      draftState.lastAiType = lastAiType
      draftState.lastRequest = payload.lastRequest

      return draftState
    },
    [FETCH_AI_JOB_COMPOSER_RESULT_CLEAR]: draftState => {
      Object.assign(draftState, initialState)

      return draftState
    },
    [FETCH_AI_JOB_COMPOSER_RESULT_FAILED]: draftState => {
      const aiType = draftState.aiType

      Object.assign(draftState, initialState)

      draftState.error = true
      draftState.loading = false
      draftState.loaded = true
      draftState.aiType = aiType

      return draftState
    },
    [FETCH_AI_JOB_COMPOSER_RESULT_INITIALIZE]: (draftState, { payload }) => {
      Object.assign(draftState, initialState)

      draftState.loading = true
      draftState.aiType = payload.aiType

      return draftState
    },
    [SEARCH_PAGE]: draftState => {
      Object.assign(draftState, initialState)

      return draftState
    },
    [TICKET_PAGE]: draftState => {
      Object.assign(draftState, initialState)

      return draftState
    },
  },
  initialState
)
