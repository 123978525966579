import {
  FETCH_ALL_CUSTOM_FIELD_CATEGORIES_STARTED,
  FETCH_ALL_CUSTOM_FIELD_CATEGORIES_FAILED,
  FETCH_CUSTOM_FIELD_CATEGORIES_FAILED,
  FETCH_ALL_CUSTOM_FIELD_CATEGORIES_SUCCESS,
} from '../types'

export default function isLoading(state = false, { type }) {
  if (type === FETCH_ALL_CUSTOM_FIELD_CATEGORIES_STARTED) return true
  if (
    [
      FETCH_ALL_CUSTOM_FIELD_CATEGORIES_SUCCESS,
      FETCH_ALL_CUSTOM_FIELD_CATEGORIES_FAILED,
      FETCH_CUSTOM_FIELD_CATEGORIES_FAILED,
    ].includes(type)
  ) {
    return false
  }
  return state
}
