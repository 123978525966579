import createCachedSelector from 're-reselect'

import selectTrelloLists from './selectTrelloLists'

const NO_BOARD = 'NO_BOARD'

export default createCachedSelector(
  selectTrelloLists,
  (_, boardId) => boardId,
  (lists, boardId) => {
    if (!boardId) return []
    return lists
      .filter(list => list.idBoard === boardId)
      .sort(({ pos: posA }, { pos: posB }) => posA - posB)
      .map(({ id, name }) => ({ text: name, value: id }))
  }
)((_, boardId) => boardId || NO_BOARD)
