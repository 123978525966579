import {
  feature,
  plan,
  pricing,
  usage,
  creditCard,
  discount,
  setupIntent,
} from 'ducks/entities/schema'

export const billingDataSchema = {
  billing: {
    availableFeatures: [feature],
    usages: [usage],
    plans: [plan],
    pricings: [pricing],
    creditCards: [creditCard],
    discount,
  },
}

export const setupIntentsSchema = {
  billing: {
    cardSetupIntents: [setupIntent],
  },
}

export const createSetupIntentSchema = {
  cardSetupIntentCreate: {
    setupIntent,
  },
}

export const cardSetupIntentSucceedSchema = {
  cardSetupIntentSucceed: {
    setupIntent,
  },
}
