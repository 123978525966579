import { useEffect, useMemo, useState } from 'react'
import emailParser from 'email-addresses'
import { queryParams } from 'util/params'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDraftIdByTicketId,
  selectMailboxIdForDraftById,
} from 'ducks/drafts2/selectors'
import { doSearchRecipients } from 'ducks/drafts2/operations'
import { doUpsertReplyDraft } from 'ducks/drafts2/operations/doUpsertReplyDraft'
import { selectCurrentContact } from 'ducks/crm/contacts'
import { selectRequestByType } from 'ducks/requests/selectors'
import { selectReassignTicketByType } from 'selectors/tickets'
import { selectCurrentUserId } from 'ducks/currentUser/selectors'
import doClearConversationContact from 'ducks/crm/contacts/operations/updating/doClearConversationContact'
import {
  fetchDraftsActionRequestKey,
  buildNewConversationDraftDefaults,
} from './util'
import {
  DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
  REQUEST_DRAFT_TYPES,
} from './constants'

export function useQueryParamToRecipientLoader(draftId) {
  const [fetching, setFetching] = useState(true)
  const [toRecipient, setToRecipient] = useState(null)
  const dispatch = useDispatch()

  const currentContact = useSelector(selectCurrentContact)

  const toEmail = useMemo(() => {
    const params = queryParams()
    if (!params.to) return null

    const parsed = emailParser.parseAddressList({
      input: params.to,
      rejectTLD: true,
    })

    if (parsed && parsed[0]) {
      const item = parsed[0]
      return item.address
    }

    return null
  }, [])

  useEffect(
    () => {
      const updateRecipients = async () => {
        if (!toEmail) {
          setFetching(false)
          return
        }

        const contactEmail = currentContact?.email

        let foundRecipient

        if (contactEmail && contactEmail === toEmail) {
          foundRecipient = currentContact
        } else {
          const recipients = await dispatch(doSearchRecipients(toEmail))
          foundRecipient = (recipients?.contacts || []).find(recipient => {
            return recipient.email === toEmail
          })
        }

        if (foundRecipient) {
          setToRecipient(foundRecipient)
        }

        setFetching(false)
      }
      updateRecipients()
    },
    [dispatch, setFetching, draftId, currentContact, toEmail]
  )

  return {
    loading: fetching,
    toRecipient,
    toEmail,
  }
}

export const useNewComposeDraft = type => {
  const [draftsLoaded, setDraftsLoaded] = useState(false)
  const dispatch = useDispatch()
  const ticketId = 'new'
  const draftId = useSelector(state =>
    selectDraftIdByTicketId(state, ticketId, type)
  )
  const currentUserId = useSelector(selectCurrentUserId)

  const currentMailboxId = useSelector(state =>
    selectMailboxIdForDraftById(state, draftId)
  )

  const {
    loading: loadingToRecipient,
    toRecipient,
    toEmail,
  } = useQueryParamToRecipientLoader(draftId)

  const requestKey = fetchDraftsActionRequestKey(
    DRAFT_NEW_CONVERSATION_CONVERSATION_ID,
    REQUEST_DRAFT_TYPES[type]
  )

  const serverDraftLoaded = useSelector(state =>
    selectRequestByType(state, requestKey)
  ).loaded

  const reassignTicketByType = useSelector(selectReassignTicketByType)

  const loaded = !loadingToRecipient && serverDraftLoaded && draftsLoaded

  useEffect(
    () => {
      if (loadingToRecipient || !serverDraftLoaded) return

      if (draftId && !toEmail) {
        // use existing draft
        setDraftsLoaded(true)
        return
      }

      let fields = {
        mailboxId: currentMailboxId,
        assigneeId: reassignTicketByType[type] ? currentUserId : null,
      }

      if (toRecipient) {
        // replace to with user loaded from api if exists
        fields.to = [toRecipient]
      }

      if (draftId && toEmail) {
        // to email in query params + there is an existing draft.
        // replace existing draft with defaults + to recipient
        // you'll notice toEmail is not added to draft here; it's set in buildNewConversationDraftDefaults
        const newDraftFields = {
          ...buildNewConversationDraftDefaults(type),
          ...fields,
        }

        fields = newDraftFields

        if (!toRecipient) {
          // this email does not exist in our system.
          // change the sidebar contact from the old draft contact to null
          dispatch(doClearConversationContact(ticketId))
        }
      }

      dispatch(doUpsertReplyDraft(draftId, type, ticketId, null, fields))
      setDraftsLoaded(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentMailboxId,
      currentUserId,
      dispatch,
      draftId,
      loadingToRecipient,
      serverDraftLoaded,
      draftsLoaded,
      type,
      toRecipient?.id,
      toEmail,
    ]
  )

  return {
    ticketId,
    draftId,
    loaded,
  }
}
