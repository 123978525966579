import produce from 'immer'
import { toDate } from 'util/date'
import {
  FETCH_ORDERS_REQUEST_STARTED,
  FETCH_ORDERS_REQUEST_FAILED,
  FETCH_ORDERS_REQUEST_SUCCESS,
  FETCH_ORDER_BY_ID_REQUEST_SUCCESS,
  ORDER_REFUND_REQUEST_SUCCESS,
  DRAFT_ORDER_COMPLETE_REQUEST_SUCCESS,
} from '../types'

const initialState = {
  isLoading: false,
  byCustomerId: {},
  metaByCustomerId: {},
}

const reducers = {}

reducers[FETCH_ORDERS_REQUEST_STARTED] = state => {
  return Object.assign({}, state, {
    isLoading: true,
  })
}

reducers[FETCH_ORDERS_REQUEST_FAILED] = state => {
  return Object.assign({}, state, {
    isLoading: false,
  })
}

const prepareOrder = node => {
  const order = { ...node }
  order.date = toDate(order.createdAt).toLocaleDateString('en-US')
  const isFulfilled = order.displayFulfillmentStatus === 'FULFILLED'
  const isPaid = order.displayFinancialStatus === 'PAID'
  const isFulfilledAndPaid = isFulfilled && isPaid
  const isCancelled = !!order.cancelledAt

  order.isCancelled = isCancelled
  order.isCancellable = !isFulfilledAndPaid && !isCancelled
  order.refundCurrencyCode =
    order.netPaymentSet?.shopMoney?.currencyCode || 'USD'
  return order
}

const processOrder = (state, order) => {
  if (!order) return state

  const newByCustomerId = Object.assign({}, state.byCustomerId)
  const { customer } = order
  const orders = newByCustomerId[customer.id] || []
  const newOrders = [].concat(orders)
  const existingOrderIndex = newOrders.findIndex(o => o.id === order.id)
  if (existingOrderIndex === -1) {
    newOrders.unshift(prepareOrder(order))
  } else {
    newOrders[existingOrderIndex] = prepareOrder(order)
  }

  newByCustomerId[customer.id] = newOrders

  return Object.assign({}, state, {
    byCustomerId: newByCustomerId,
  })
}

reducers[ORDER_REFUND_REQUEST_SUCCESS] = (state, action) => {
  const { refundCreate } = action.payload
  const { order } = refundCreate

  return processOrder(state, order)
}

reducers[FETCH_ORDERS_REQUEST_SUCCESS] = produce((draftState, action) => {
  const {
    payload: { customer },
    meta: { isMoreFetched = false } = {},
  } = action
  const { orders: { edges, pageInfo } = [] } = customer

  if (!draftState.byCustomerId[customer.id]) {
    draftState.byCustomerId[customer.id] = []
    draftState.metaByCustomerId[customer.id] = {}
  }
  draftState.metaByCustomerId[customer.id].isMoreFetched = isMoreFetched
  draftState.metaByCustomerId[customer.id].pageInfo = pageInfo

  // TODO (jscheel): We should refactor the orders so that they are all added
  // to a byId store first, then byCustomerId can be an array of ids. Because
  // we don't do this right now, we instead need to see if we already have any
  // orders loaded, so that we don't push them onto the order array again each
  // time this reducer is run. Orders shouldn't be too massive, so probably not
  // a huge performance penalty.
  const loaded = new Set(draftState.byCustomerId[customer.id].map(o => o.id))

  edges.forEach(edge => {
    const { node } = edge
    const order = prepareOrder(node)
    if (loaded.has(node.id)) {
      const idx = draftState.byCustomerId[customer.id].findIndex(
        existingOrder => existingOrder.id === node.id
      )
      if (idx >= 0) {
        draftState.byCustomerId[customer.id][idx] = order
      }
    } else {
      draftState.byCustomerId[customer.id].push(order)
      loaded.add(node.id)
    }
  })

  draftState.isLoading = false
  draftState.isLoaded = true
})

reducers[FETCH_ORDER_BY_ID_REQUEST_SUCCESS] = (state, action) => {
  const {
    payload: { order },
  } = action

  return processOrder(state, order)
}

reducers[DRAFT_ORDER_COMPLETE_REQUEST_SUCCESS] = (state, action) => {
  const order = action?.payload?.draftOrderComplete?.draftOrder?.order

  return processOrder(state, order)
}

export default function reducer(state = initialState, action) {
  const found = reducers[action.type]
  if (found) return found(state, action)
  return state
}
