import createCachedSelector from 're-reselect'
import { toDate } from 'util/date'
import { selectAccount } from 'ducks/accounts/selectors/selectAccount'

export const selectIsNewAccountSinceDate = createCachedSelector(
  selectAccount,
  // Make sure don't pass dateObject (new Date()), will cause cache key is invalid warnings and return undefined
  (_state, dateString) => dateString,
  (account, dateString) => toDate(account.activated_at) >= toDate(dateString)
)((_state, dateString) => dateString || 'unknown')
