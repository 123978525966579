import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { createBasicSelector } from 'util/redux'
import { selectCurrentEntities } from 'ducks/entities/selectors'
import { selectRequestByType } from 'ducks/requests/selectors'
import { FETCH_BATCH_JOBS } from './actionTypes'

const selectBase = state => state.batchJobs || emptyObj

export const selectIsLoading = state =>
  selectRequestByType(state, FETCH_BATCH_JOBS).loading

export const selectIsPolling = createBasicSelector(
  selectBase,
  base => !!base.isPolling
)
export const selectShouldLoadBatchJobs = createBasicSelector(
  selectIsPolling,
  selectIsLoading,
  (isPolling, isLoading) => isPolling && !isLoading
)

export const selectCurrentBatchJobs = state =>
  selectCurrentEntities(state, 'batchJob') || emptyArr

export const selectCurrentBatchJobsByType = createCachedSelector(
  selectCurrentBatchJobs,
  (_state, jobType) => jobType,
  (batchJobs, jobType) => batchJobs.filter(j => j.batch_type === jobType)
)((_state, type) => type)
