import {
  FETCH_ALL_CUSTOM_FIELDS_STARTED,
  FETCH_CUSTOM_FIELDS_FAILED,
} from '../types'

export default function isLoading(state = false, { type }) {
  if (type === FETCH_CUSTOM_FIELDS_FAILED) return true
  if (type === FETCH_ALL_CUSTOM_FIELDS_STARTED) return false
  return state
}
