import createCachedSelector from 're-reselect'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'

import selectJiraProjectsById from './selectJiraProjectsById'

export default createCachedSelector(
  selectJiraProjectsById,
  (state, { projectId, siteId }) =>
    siteId ? `${siteId}:${projectId}` : projectId,
  (projects, projectId) => {
    const { issueTypes } = projects[projectId] || emptyObj
    return issueTypes || emptyArr
  }
)(
  (state, { projectId, siteId }) =>
    siteId ? `${siteId}:${projectId}` : projectId
)
