import { createSelector } from 'reselect'
import { selectPricings } from './selectPricings'
import { selectPendingDowngrades } from './selectPendingDowngrades'

export const selectPendingDowngradePlans = createSelector(
  selectPendingDowngrades,
  selectPricings,
  (pendingDowngrades, pricings) => {
    return {
      inbox: pendingDowngrades.inbox
        ? pricings.find(p => p.id === pendingDowngrades.inbox.pricingId)
        : null,
      kb: pendingDowngrades.kb
        ? pricings.find(p => p.id === pendingDowngrades.kb.pricingId)
        : null,
    }
  }
)
