export const FETCH_BOARDS_REQUEST = 'integrations/trello/FETCH_BOARDS_REQUEST'
export const FETCH_BOARDS_SUCCESS = 'integrations/trello/FETCH_BOARDS_SUCCESS'
export const FETCH_BOARDS_FAILURE = 'integrations/trello/FETCH_BOARDS_FAILURE'

export const FETCH_BOARD_DETAILS_FAILURE =
  'integrations/trello/FETCH_BOARD_DETAILS_FAILURE'
export const FETCH_BOARD_DETAILS_SUCCESS =
  'integrations/trello/FETCH_BOARD_DETAILS_SUCCESS'
export const FETCH_BOARD_DETAILS_REQUEST =
  'integrations/trello/FETCH_BOARD_DETAILS_REQUEST'

export const CREATE_CARD_REQUEST = 'integrations/trello/CREATE_CARD_REQUEST'
export const CREATE_CARD_SUCCESS = 'integrations/trello/CREATE_CARD_SUCCESS'
export const CREATE_CARD_FAILURE = 'integrations/trello/CREATE_CARD_FAILURE'

export const FETCH_CARD_REQUEST = 'integrations/trello/FETCH_CARD_REQUEST'
export const FETCH_CARD_SUCCESS = 'integrations/trello/FETCH_CARD_SUCCESS'
export const FETCH_CARD_FAILURE = 'integrations/trello/FETCH_CARD_FAILURE'

export const FETCH_CARD_FOR_BOARD_REQUEST =
  'integrations/trello/FETCH_CARD_FOR_BOARD_REQUEST'
export const FETCH_CARD_FOR_BOARD_SUCCESS =
  'integrations/trello/FETCH_CARD_FOR_BOARD_SUCCESS'
export const FETCH_CARD_FOR_BOARD_FAILURE =
  'integrations/trello/FETCH_CARD_FOR_BOARD_FAILURE'

export const FETCH_LIST_CARDS_REQUEST =
  'integrations/trello/FETCH_LIST_CARDS_REQUEST'
export const FETCH_LIST_CARDS_SUCCESS =
  'integrations/trello/FETCH_LIST_CARDS_SUCCESS'
export const FETCH_LIST_CARDS_FAILURE =
  'integrations/trello/FETCH_LIST_CARDS_FAILURE'

export const FETCH_LIST_REQUEST = 'integrations/trello/FETCH_LIST_REQUEST'
export const FETCH_LIST_SUCCESS = 'integrations/trello/FETCH_LIST_SUCCESS'
export const FETCH_LIST_FAILURE = 'integrations/trello/FETCH_LIST_FAILURE'

export const FETCH_LISTS_REQUEST = 'integrations/trello/FETCH_LISTS_REQUEST'
export const FETCH_LISTS_SUCCESS = 'integrations/trello/FETCH_LISTS_SUCCESS'
export const FETCH_LISTS_FAILURE = 'integrations/trello/FETCH_LISTS_FAILURE'

export const UPDATE_CARD_REQUEST = 'UPDATE_CARD_REQUEST'
export const UPDATE_CARD_SUCCESS = 'UPDATE_CARD_SUCCESS'
export const UPDATE_CARD_FAILURE = 'UPDATE_CARD_FAILURE'
