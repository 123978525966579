import { BASE_TYPE_REGEX } from '../types'

const initialState = {}

export default function reduceLoading(
  state = initialState,
  { type, meta: { loading } = {} }
) {
  if (!type) return state
  if (type.match(BASE_TYPE_REGEX) && loading) {
    return {
      ...state,
      ...loading,
    }
  }
  return state
}
