import createCachedSelector from 're-reselect'

import selectJiraProjectsById from './selectJiraProjectsById'

export default createCachedSelector(
  selectJiraProjectsById,
  (state, { projectId }) => projectId,
  (projectsById, projectId) => {
    const currentProject = projectsById[projectId]
    return currentProject && currentProject.site.id
  }
)((state, { projectId }) => projectId || 'NULL')
