import createCachedSelector from 're-reselect'

import selectIntegrationCredentialsById from './selectIntegrationCredentialsById'

export default createCachedSelector(
  selectIntegrationCredentialsById,
  (_state, { id } = {}) => id,
  (integrationCredentials, id) => {
    const forId = integrationCredentials[id]
    if (forId) return forId
    return undefined
  }
)((_state, { id } = {}) => id || 'unknown')
