import { createBasicSelector } from 'util/redux'
import { selectFuturePhase } from 'ducks/billing/selectors/selectFuturePhase'

export const selectFuturePhaseNoticeBarFlag = createBasicSelector(
  selectFuturePhase,
  futurePhase => {
    if (!futurePhase?.startDate) return null

    return `has_seen_billing_plan_usage_limited_${futurePhase.startDate}`
  }
)
