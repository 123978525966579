export const FETCH_CUSTOMER_REQUEST =
  'integrations/recharge/FETCH_CUSTOMER_REQUEST'
export const FETCH_CUSTOMER_REQUEST_STARTED =
  'integrations/recharge/FETCH_CUSTOMER_REQUEST_STARTED'
export const FETCH_CUSTOMER_REQUEST_SUCCESS =
  'integrations/recharge/FETCH_CUSTOMER_REQUEST_SUCCESS'
export const FETCH_CUSTOMER_REQUEST_FAILED =
  'integrations/recharge/FETCH_CUSTOMER_REQUEST_FAILED'

export const FETCH_SUBSCRIPTIONS_REQUEST =
  'integrations/stripe/FETCH_SUBSCRIPTIONS_REQUEST'
export const FETCH_SUBSCRIPTIONS_REQUEST_STARTED =
  'integrations/stripe/FETCH_SUBSCRIPTIONS_REQUEST_STARTED'
export const FETCH_SUBSCRIPTIONS_REQUEST_SUCCESS =
  'integrations/stripe/FETCH_SUBSCRIPTIONS_REQUEST_SUCCESS'
export const FETCH_SUBSCRIPTIONS_REQUEST_FAILED =
  'integrations/stripe/FETCH_SUBSCRIPTIONS_REQUEST_FAILED'

export const FETCH_CHARGES_REQUEST = 'integrations/stripe/FETCH_CHARGES_REQUEST'
export const FETCH_CHARGES_REQUEST_STARTED =
  'integrations/stripe/FETCH_CHARGES_REQUEST_STARTED'
export const FETCH_CHARGES_REQUEST_SUCCESS =
  'integrations/stripe/FETCH_CHARGES_REQUEST_SUCCESS'
export const FETCH_CHARGES_REQUEST_FAILED =
  'integrations/stripe/FETCH_CHARGES_REQUEST_FAILED'

export const FETCH_UPCOMING_INVOICE =
  'integrations/stripe/FETCH_UPCOMING_INVOICE'
export const FETCH_UPCOMING_INVOICE_STARTED =
  'integrations/stripe/FETCH_UPCOMING_INVOICE_STARTED'
export const FETCH_UPCOMING_INVOICE_SUCCESS =
  'integrations/stripe/FETCH_UPCOMING_INVOICE_SUCCESS'
export const FETCH_UPCOMING_INVOICE_FAILED =
  'integrations/stripe/FETCH_UPCOMING_INVOICE_FAILED'
