import { createSelector } from 'reselect'
import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { selectQueryParams } from 'selectors/location'
import { stripDrawerIdsFromProps } from './util'

export function selectBase(state) {
  return state.drawers || emptyObj
}

export const selectDrawerStack = createBasicSelector(
  selectBase,
  drawers => drawers.drawerStack || emptyArr
)

export const selectPreviousDrawer = createSelector(
  selectDrawerStack,
  stack => (stack[0] ? stripDrawerIdsFromProps(stack[0]) : null)
)

export const selectIsDrawerOpen = createSelector(
  selectQueryParams,
  queryParams => Object.keys(queryParams).some(k => k.startsWith('drawer-'))
)

export const selectCurrentOpenDrawerType = createSelector(
  selectQueryParams,
  queryParams => {
    const drawerTypeKey = Object.keys(queryParams).find(qp =>
      qp.startsWith('drawer-')
    )

    return queryParams[drawerTypeKey]
  }
)

export const selectDrawerQueryParams = createSelector(
  selectQueryParams,
  queryParams => {
    return Object.entries(queryParams).reduce((acc, [key, value]) => {
      if (key.startsWith('drawer-')) {
        // eslint-disable-next-line no-param-reassign
        acc[key] = value
      }
      return acc
    }, {})
  }
)

export const selectCurrentOpenDrawerResourceId = createSelector(
  selectQueryParams,
  queryParams => {
    const drawerTypeKey = Object.keys(queryParams).find(qp =>
      qp.startsWith('drawerResourceId-')
    )

    return queryParams[drawerTypeKey]
  }
)

export const selectIsChannelOnboardingDrawerOpen = createSelector(
  selectIsDrawerOpen,
  selectCurrentOpenDrawerType,
  (isDrawerOpen, currentOpenDrawerType) =>
    isDrawerOpen && currentOpenDrawerType?.startsWith('channels')
)
