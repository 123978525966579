import { schema } from 'normalizr'

export const excludedDomainNormalizationSchema = new schema.Entity(
  'excludedDomains'
)

export const excludedDomainsNormalizationSchema = {
  excludedDomains: {
    nodes: [excludedDomainNormalizationSchema],
  },
}

export const excludedDomainDeleteNormalizationSchema = new schema.Entity(
  'excludedDomains',
  {},
  {
    processStrategy: value => {
      return {
        id: value.deletedExcludedDomainId,
      }
    },
  }
)
