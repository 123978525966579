import { createBasicSelector } from 'util/redux'
import {
  selectBridgedWidgets,
  selectWidgets,
  selectWidgetsBootstrapped,
  selectWidgetsWithChat,
  selectWidgetsWithLiveChat,
} from 'ducks/widgets/selectors'
import { selectFolders } from 'selectors/folders/selectFolders'
import { selectRulesTotal } from 'ducks/rules/selectors/selectRulesTotal'
import { selectCustomerRatingSettingsCountIfLoaded } from 'ducks/customerRatingSettings/selectors/selectCustomerRatingSettingsCountIfLoaded'
import {
  USAGE_INBOX_CANNED_REPLIES,
  USAGE_INBOX_CHANNELS,
  USAGE_INBOX_FOLDERS,
  USAGE_INBOX_MAILBOXES,
  USAGE_INBOX_RULES,
  USAGE_INBOX_SATISFACTIONS_RATINGS,
  USAGE_INBOX_WIDGETS,
  USAGE_KB_ARTICLES,
  USAGE_KB_KBS,
  USAGE_INBOX_TEAMS,
  USAGE_CHAT_CHANNELS,
  USAGE_LIVE_CHAT_CHANNELS,
  USAGE_SOCIAL_CHAT_CHANNELS,
} from 'ducks/billing/usageTypes'
import { selectCannedReplyTemplatesTotalCount } from 'ducks/cannedReplies/selectors/selectCannedReplyTemplatesTotalCount'
import { selectKnowledgeBaseTotalOrNull } from 'subapps/kb/selectors/knowledge_bases/selectKnowledgeBaseTotalOrNull'
import { selectNonDeletedArticlesCount } from 'subapps/kb/selectors/articles/selectNonDeletedArticlesCount'
import { selectGroups } from 'selectors/app/groups/selectGroups'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { selectAllMailboxes } from 'selectors/mailboxes/selectAllMailboxes'

export const selectLocalUsage = createBasicSelector(
  selectWidgets,
  selectWidgetsBootstrapped,
  selectFolders,
  selectRulesTotal,
  selectCustomerRatingSettingsCountIfLoaded,
  selectKnowledgeBaseTotalOrNull,
  selectNonDeletedArticlesCount,
  selectCannedReplyTemplatesTotalCount,
  selectAllMailboxes,
  selectWidgetsWithChat,
  selectGroups,
  selectBridgedWidgets,
  selectWidgetsWithLiveChat,
  (
    widgets,
    isWidgetLoaded,
    folders,
    rulesCountIfLoaded,
    csatSettingsCountIfLoaded,
    kbCountIfLoaded,
    kbArticlesCount,
    cannedRepliesCountIfLoaded,
    mailboxes,
    widgetsWithChat,
    teams,
    socialChatWidgets,
    liveChatWidgets
  ) => {
    const mailboxCount = mailboxes.filter(m =>
      ['confirmed', 'active', 'disconnected'].includes(m.state)
    ).length
    const widgetsWithChatCount = (widgetsWithChat || []).length
    const widgetsWithSocialChatCount = (socialChatWidgets || []).length
    const widgetsWithLiveChatCount = (liveChatWidgets || []).length
    const foldersNonDefault = folders.filter(f => !f.default).length
    const csatSettingsEnabled = isDefined(csatSettingsCountIfLoaded)
      ? csatSettingsCountIfLoaded > 0
      : csatSettingsCountIfLoaded

    return {
      [USAGE_KB_KBS]: kbCountIfLoaded,
      // We can't use billing.usage.kb.articles state, because we need to include draft ones two
      [USAGE_KB_ARTICLES]: isDefined(kbCountIfLoaded) ? kbArticlesCount : null,
      [USAGE_INBOX_CANNED_REPLIES]: cannedRepliesCountIfLoaded,
      [USAGE_INBOX_CHANNELS]: mailboxCount + widgetsWithChatCount,
      [USAGE_INBOX_MAILBOXES]: mailboxCount,
      [USAGE_CHAT_CHANNELS]: widgetsWithChatCount,
      [USAGE_LIVE_CHAT_CHANNELS]: widgetsWithLiveChatCount,
      [USAGE_SOCIAL_CHAT_CHANNELS]: widgetsWithSocialChatCount,
      [USAGE_INBOX_WIDGETS]: isWidgetLoaded ? widgets.length : null,
      [USAGE_INBOX_RULES]: rulesCountIfLoaded,
      [USAGE_INBOX_FOLDERS]: foldersNonDefault,
      [USAGE_INBOX_SATISFACTIONS_RATINGS]: csatSettingsEnabled,
      [USAGE_INBOX_TEAMS]: teams.length,
    }
  }
)
