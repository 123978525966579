import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectIntegrationFormCurrentValues } from 'selectors/userIntegrations'
import { selectIntegrationTrelloData } from './selectIntegrationTrelloData'

export const selectTrelloFormCurrentOptions = createSelector(
  selectIntegrationFormCurrentValues,
  selectIntegrationTrelloData,
  (values, trello) => {
    if (!trello) return emptyObj
    return {
      board: trello.boards,
      list: trello.lists,
      member: trello.members,
      label: trello.labels,
      card: trello.cards,
    }
  }
)
