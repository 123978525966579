import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomerForCustomerEmail } from 'ducks/integrations/stripe/selectors/customers'
import { selectCurrentContactOrCustomerEmail } from 'ducks/crm/contacts'

import doFetchCustomerByEmail from 'ducks/integrations/stripe/operations/doFetchCustomerByEmail'

export const useCustomer = (integrationId, customerId) => {
  const dispatch = useDispatch()
  const email = useSelector(selectCurrentContactOrCustomerEmail)

  const customerData = useSelector(state =>
    selectCustomerForCustomerEmail(state, integrationId, { email })
  )

  useEffect(
    () => {
      if (integrationId && email && !customerData.isInitialized) {
        dispatch(doFetchCustomerByEmail({ integrationId, email }))
      }
    },
    [dispatch, integrationId, email, customerData]
  )

  const data = useMemo(
    () => {
      if (
        Array.isArray(customerData?.records) &&
        customerData.records.length > 0
      ) {
        if (customerId)
          return customerData?.records.find(item => item.id === customerId)

        return customerData
      }
      return {}
    },
    [customerData, customerId]
  )

  return data
}
