import { selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider } from 'ducks/integrations/selectors'

export default function selectGitHubVisibleFields(state) {
  const {
    visible,
  } = selectIntegrationSettingsSidebarVisibleAndHiddenFieldsForProvider(state, {
    provider: 'trello',
  })
  return visible
}
