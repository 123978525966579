export default function splitAuthValues(values) {
  return Object.keys(values).reduce(
    (result, key) => {
      const matches = key.match(/^auth\.(.+)$/)
      if (matches) {
        // eslint-disable-next-line no-param-reassign
        result[1][matches[1]] = values[key]
      } else {
        // eslint-disable-next-line no-param-reassign
        result[0][key] = values[key]
      }
      return result
    },
    [{}, {}]
  )
}
