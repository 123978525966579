import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import { FETCH_DRAFT_ORDER_BY_ID_REQUEST } from '../types'
import { draftOrderPartial } from './partials'

const fetchDraftOrderByIdQuery = `
  query ShopifyFetchDraftOrderById(
    $id: ID!
  ) {
    draftOrder(id: $id) {
      ${draftOrderPartial}
    }
  }
`

const doFetchDraftOrderById = (integrationId, draftOrderId) => dispatch => {
  const variables = { id: draftOrderId }
  return dispatch(
    doGraphqlRequest(
      FETCH_DRAFT_ORDER_BY_ID_REQUEST,
      fetchDraftOrderByIdQuery,
      variables,
      {
        graphqlFunc: graphql(integrationId),
      }
    )
  )
}

export default doFetchDraftOrderById
