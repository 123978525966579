import { combineReducers } from 'core/redux'

import boards from './boards'
import cards from './cards'
import labels from './labels'
import lists from './lists'
import members from './members'

export default combineReducers({
  boards,
  cards,
  labels,
  lists,
  members,
})
