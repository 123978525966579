export const REMOVE_INTEGRATION = 'integrations/REMOVE_INTEGRATION'
export const REMOVE_INTEGRATION_STARTED =
  'integrations/REMOVE_INTEGRATION_STARTED'
export const REMOVE_INTEGRATION_SUCCESS =
  'integrations/REMOVE_INTEGRATION_SUCCESS'
export const REMOVE_INTEGRATION_FAILED =
  'integrations/REMOVE_INTEGRATION_FAILED'

export const REMOVE_INTEGRATION_BY_PROVIDER =
  'integrations/REMOVE_INTEGRATION_BY_PROVIDER'
export const REMOVE_INTEGRATION_BY_PROVIDER_STARTED =
  'integrations/REMOVE_INTEGRATION_BY_PROVIDER_STARTED'
export const REMOVE_INTEGRATION_BY_PROVIDER_SUCCESS =
  'integrations/REMOVE_INTEGRATION_BY_PROVIDER_SUCCESS'
export const REMOVE_INTEGRATION_BY_PROVIDER_FAILED =
  'integrations/REMOVE_INTEGRATION_BY_PROVIDER_FAILED'

export const UPSERT_JIRA_SERVER_CREDENTIALS =
  'integrations/UPSERT_JIRA_SERVER_CREDENTIALS'
export const UPSERT_JIRA_SERVER_CREDENTIALS_STARTED =
  'integrations/UPSERT_JIRA_SERVER_CREDENTIALS_STARTED'
export const UPSERT_JIRA_SERVER_CREDENTIALS_SUCCESS =
  'integrations/UPSERT_JIRA_SERVER_CREDENTIALS_SUCCESS'
export const UPSERT_JIRA_SERVER_CREDENTIALS_FAILED =
  'integrations/UPSERT_JIRA_SERVER_CREDENTIALS_FAILED'

export const ADD_SHOPIFY_STORE = 'integrations/ADD_SHOPIFY_STORE'
export const ADD_SHOPIFY_STORE_STARTED =
  'integrations/ADD_SHOPIFY_STORE_STARTED'
export const ADD_SHOPIFY_STORE_SUCCESS =
  'integrations/ADD_SHOPIFY_STORE_SUCCESS'
export const ADD_SHOPIFY_STORE_FAILED = 'integrations/ADD_SHOPIFY_STORE_FAILED'

export const EDIT_SHOPIFY_STORE = 'integrations/EDIT_SHOPIFY_STORE'
export const EDIT_SHOPIFY_STORE_STARTED =
  'integrations/EDIT_SHOPIFY_STORE_STARTED'
export const EDIT_SHOPIFY_STORE_SUCCESS =
  'integrations/EDIT_SHOPIFY_STORE_SUCCESS'
export const EDIT_SHOPIFY_STORE_FAILED =
  'integrations/EDIT_SHOPIFY_STORE_FAILED'

export const UPDATE_INTEGRATION = 'integrations/UPDATE_INTEGRATION'

export const FETCH_INTEGRATIONS = 'integrations/FETCH_INTEGRATIONS'
export const FETCH_INTEGRATIONS_STARTED =
  'integrations/FETCH_INTEGRATIONS_STARTED'
export const FETCH_INTEGRATIONS_SUCCESS =
  'integrations/FETCH_INTEGRATIONS_SUCCESS'
export const FETCH_INTEGRATIONS_FAILED =
  'integrations/FETCH_INTEGRATIONS_FAILED'

export const FETCH_INTEGRATIONS_DATA_TABLE =
  'integrations/settings/FETCH_INTEGRATIONS_DATA_TABLE'
export const FETCH_INTEGRATIONS_DATA_TABLE_STARTED =
  'integrations/settings/FETCH_INTEGRATIONS_DATA_TABLE_STARTED'
export const FETCH_INTEGRATIONS_DATA_TABLE_SUCCESS =
  'integrations/settings/FETCH_INTEGRATIONS_DATA_TABLE_SUCCESS'
export const FETCH_INTEGRATIONS_DATA_TABLE_FAILED =
  'integrations/settings/FETCH_INTEGRATIONS_DATA_TABLE_FAILED'

export const FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS =
  'integrations/settings/integrationProvider/FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS'
export const FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS_STARTED =
  'integrations/settings/integrationProvider/FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS_STARTED'
export const FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS_SUCCESS =
  'integrations/settings/integrationProvider/FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS_SUCCESS'
export const FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS_FAILED =
  'integrations/settings/integrationProvider/FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS_FAILED'

export const LOAD_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS =
  'integrations/settings/loadEntity/FETCH_INTEGRATIONS_BY_PROVIDER_FOR_SETTINGS'

export const CLEAR_INTEGRATION_DRAFT =
  'integrations/settings/CLEAR_INTEGRATION_DRAFT'

export const UPDATE_INTEGRATION_SETTINGS_DRAFT =
  'integrations/settings/UPDATE_INTEGRATION_SETTINGS_DRAFT'

export const DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS =
  'integrations/settings/integrationProvider/DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS'
export const DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS_STARTED =
  'integrations/settings/integrationProvider/DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS_STARTED'
export const DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS_SUCCESS =
  'integrations/settings/integrationProvider/DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS_SUCCESS'
export const DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS_FAILED =
  'integrations/settings/integrationProvider/DELETE_INTEGRATION_FOR_PROVIDER_FOR_SETTINGS_FAILED'

export const UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS =
  'integrations/settings/integrationProvider/UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS'
export const UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS_STARTED =
  'integrations/settings/integrationProvider/UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS_STARTED'
export const UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS_SUCCESS =
  'integrations/settings/integrationProvider/UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS_SUCCESS'
export const UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS_FAILED =
  'integrations/settings/integrationProvider/UNINSTALL_INTEGRATION_BY_PROVIDER_FOR_SETTINGS_FAILED'

export const UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS =
  'integrations/settings/integrationProvider/UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS'
export const UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS_STARTED =
  'integrations/settings/integrationProvider/UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS_STARTED'
export const UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS_SUCCESS =
  'integrations/settings/integrationProvider/UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS_SUCCESS'
export const UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS_FAILED =
  'integrations/settings/integrationProvider/UPDATE_INTEGRATION_BY_PROVIDER_SETTINGS_FAILED'

export const UPDATE_INTEGRATION_SETTINGS =
  'integrations/UPDATE_INTEGRATION_SETTINGS'
export const UPDATE_INTEGRATION_SETTINGS_ERROR =
  'integrations/UPDATE_INTEGRATION_SETTINGS_ERROR'
export const UPDATE_INTEGRATION_SETTINGS_RESPONSE =
  'integrations/UPDATE_INTEGRATION_SETTINGS_RESPONSE'
export const UPDATE_INTEGRATION_SETTINGS_REQUEST =
  'integrations/UPDATE_INTEGRATION_SETTINGS_REQUEST'

export const UPDATE_INTEGRATION_CREDENTIALS_ERROR =
  'integrations/UPDATE_INTEGRATION_CREDENTIALS_ERROR'
export const UPDATE_INTEGRATION_CREDENTIALS_RESPONSE =
  'integrations/UPDATE_INTEGRATION_CREDENTIALS_RESPONSE'
export const UPDATE_INTEGRATION_CREDENTIALS_REQUEST =
  'integrations/UPDATE_INTEGRATION_CREDENTIALS_REQUEST'
