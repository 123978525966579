import { oauthTokenSelector } from 'selectors/app'

import gitHubGraphQl from '../gitHubGraphQl'
import {
  FETCH_DETAILS_FOR_REPOSITORY_ERROR,
  FETCH_DETAILS_FOR_REPOSITORY_REQUEST,
  FETCH_DETAILS_FOR_REPOSITORY_SUCCESS,
} from '../types'

function doFetchGitHubDetailsForRepositoryPage(
  token,
  repositoryId,
  assignableUsersCursor = null,
  labelsCursor = null,
  milestonesCursor = null,
  nodesSoFar = []
) {
  const query = `query FetchGitHubDetailsForRepository(
    $assignableUsersCursor: String, 
    $labelsCursor: String, 
    $milestonesCursor: String, 
    $repositoryId: ID!
  ) {
    node(id: $repositoryId) {
      __typename
      ... on Repository {
        assignableUsers(after: $assignableUsersCursor, first: 100) {
          nodes {
            __typename
            avatarUrl
            id
            login
            name
          }
          pageInfo {
            endCursor
            hasNextPage  
          }
        }
        labels(after: $labelsCursor, first: 100) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            __typename
            color
            description
            id
            name
          }
        }
        milestones(after: $milestonesCursor, first: 100) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            __typename
            id
            title
          }
        }
      }
    }
  }`
  const variables = {
    assignableUsersCursor,
    labelsCursor,
    milestonesCursor,
    repositoryId,
  }
  return gitHubGraphQl(token, query, variables).then(
    ({
      data: {
        node: {
          assignableUsers: {
            pageInfo: {
              endCursor: assignableUsersEndCursor,
              hasNextPage: assignableUsersHasNextPage,
            },
            nodes: assignableUsers,
          },
          labels: {
            pageInfo: {
              endCursor: labelsEndCursor,
              hasNextPage: labelsHasNextPage,
            },
            nodes: labels,
          },
          milestones: {
            pageInfo: {
              endCursor: milestonesEndCursor,
              hasNextPage: milestonesHasNextPage,
            },
            nodes: milestones,
          },
        },
      },
    }) => {
      const newNodesSoFar = [
        ...nodesSoFar,
        ...labels,
        ...assignableUsers,
        ...milestones,
      ]
      if (
        assignableUsersHasNextPage ||
        labelsHasNextPage ||
        milestonesHasNextPage
      ) {
        return doFetchGitHubDetailsForRepositoryPage(
          token,
          repositoryId,
          assignableUsersEndCursor,
          labelsEndCursor,
          milestonesEndCursor,
          newNodesSoFar
        )
      }
      return newNodesSoFar
    }
  )
}

export default function doFetchGitHubDetailsForRepository(repositoryId) {
  return (dispatch, getState) => {
    const token = oauthTokenSelector(getState())

    dispatch({
      type: FETCH_DETAILS_FOR_REPOSITORY_REQUEST,
      meta: { id: repositoryId },
    })
    doFetchGitHubDetailsForRepositoryPage(token, repositoryId)
      .then(nodes => {
        dispatch({
          meta: { id: repositoryId },
          payload: {
            nodes,
          },
          type: FETCH_DETAILS_FOR_REPOSITORY_SUCCESS,
        })
      })
      .catch(error => {
        dispatch({
          error: true,
          meta: { id: repositoryId },
          payload: error,
          type: FETCH_DETAILS_FOR_REPOSITORY_ERROR,
        })
      })
  }
}
