import { schema } from 'normalizr'
import companies, {
  companyNormalizationSchema,
} from 'ducks/crm/companies/schema'

const contact = new schema.Entity('contacts', {
  ...companies,
})
export const contactsNormalizationSchema = {
  contacts: {
    nodes: [contact],
  },
}

export const conversationContactNormalizationSchema = {
  conversation: {
    contact,
  },
}

export const customFieldValueCompanyNormalizationScheme = {
  customFieldValuesUpdate: {
    subject: companyNormalizationSchema,
  },
}

export const customFieldValueContactNormalizationScheme = {
  customFieldValuesUpdate: {
    subject: contact,
  },
}

export const changeContactMutationSchema = {
  conversationChangeContact: {
    conversation: {
      contact,
    },
  },
}

export const createContactMutationSchema = {
  contactCreate: {
    contact,
  },
}

export const resyncContactMutationSchema = {
  contactResync: {
    contact,
  },
}
