import {
  doApiReadRequest,
  doApiWriteRequest,
  doAppGraphqlRequest,
} from 'ducks/requests/operations'
import { reverseHashInt, hash } from 'util/scatterSwap'
import selectIntegrationDataForProviderId from 'ducks/integrations/selectors/settings/selectIntegrationDataForProviderId'
import { doFetchOAuthAuthenticationUrl } from 'ducks/integrations/operations/oauth'
import OAuthWindow from 'util/oauth_window'
import { doPostIntegrationInstallForProvider } from 'ducks/integrations/operations/settings'
import { selectCurrentChannelsById } from 'ducks/channels/selectors'

import {
  EMAIL_MARKETING_FETCH_INTEGRATIONS,
  EMAIL_MARKETING_GET_INTEGRATION_STATE,
  EMAIL_MARKETING_SUBSCRIBE,
  EMAIL_MARKETING_UNSUBSCRIBE,
  EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS,
  EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS,
  EMAIL_MARKETING_UNINSTALL_INTEGRATION,
  EMAIL_MARKETING_INSTALL_INTEGRATION,
  EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION,
} from './types'
import {
  emailMarketingIntegrationsNormalizationSchema,
  emailMarketingIntegrationMailboxSettingNormalizationSchema,
} from './schema'

const fetchEmailMarketingIntegrationsQuery = `
  query FetchEmailMarketingIntegrationsQuery {
    emailMarketingIntegrations {
      nodes {
        id
        lists {
          id
          name
        }
        type
      }
    }
  }
`
export function doFetchEmailMarketingIntegrations() {
  return doAppGraphqlRequest(
    EMAIL_MARKETING_FETCH_INTEGRATIONS,
    fetchEmailMarketingIntegrationsQuery,
    {},
    { normalizationSchema: emailMarketingIntegrationsNormalizationSchema }
  )
}

const fetchEmailMarketingIntegrationStateQuery = `
  query FetchEmailMarketingIntegrationState($contactId: ID!, $integrationId: ID!) {
    emailMarketingSubscriptions(contactId: $contactId, integrationId: $integrationId) {
      nodes {
        externalUrl
        id
        listId
        listName
        subscribedAt
      }
    }
  }
`

export function doFetchEmailMarketingIntegrationState(
  integrationId,
  contactId
) {
  if (!contactId) return null
  return doAppGraphqlRequest(
    EMAIL_MARKETING_GET_INTEGRATION_STATE,
    fetchEmailMarketingIntegrationStateQuery,
    { contactId, integrationId },
    {
      requestKey: `FetchEmailMarketingIntegrationState:${integrationId}:${contactId}`,
    }
  )
}

const subscribeToEmailListMutation = `
  mutation SubscribeToEmailListMutation($input: EmailMarketingSubscriptionCreateInput!) {
    emailMarketingSubscriptionCreate(input: $input) {
      errors {
        message
      }
    }
  }
`

export function doSubscribeToEmailList(integrationId, contactId, list) {
  return doAppGraphqlRequest(
    EMAIL_MARKETING_SUBSCRIBE,
    subscribeToEmailListMutation,
    { input: { contactId, integrationId, listId: list.id } },
    {
      meta: {
        list: {
          id: list.id,
          listId: list.id,
          listName: list.name,
          subscribedAt: new Date(),
        },
      },
    }
  )
}

const unsubscribeToEmailListMutation = `
  mutation UnsubscribeToEmailListMutation($input: EmailMarketingSubscriptionDeleteInput!) {
    emailMarketingSubscriptionDelete(input: $input) {
      errors {
        message
      }
    }
  }
`

export function doUnsubscribeFromEmailList(integrationId, contactId, listId) {
  return doAppGraphqlRequest(
    EMAIL_MARKETING_UNSUBSCRIBE,
    unsubscribeToEmailListMutation,
    { input: { contactId, integrationId, listId } }
  )
}

export const doFetchEmailMarketingIntegrationById = (
  integrationId,
  options = {}
) => {
  return doApiReadRequest(
    EMAIL_MARKETING_FETCH_INTEGRATION_MAILBOX_SETTINGS,
    `email_marketing/integrations/${integrationId}`,
    {},
    {
      normalizationSchema: emailMarketingIntegrationMailboxSettingNormalizationSchema,
      transformResponse: (data, store) => {
        const { getState } = store
        const state = getState()

        const channelsById = selectCurrentChannelsById(state)

        return {
          ...data,
          // There is deleted mailboxes being returned under mailbox_settings which causes issues on the frontend
          // Just sanitizing the resposne before we return it
          mailbox_settings: data.mailbox_settings.filter(
            ms => !!channelsById[hash(ms.mailbox_id)]
          ),
        }
      },
      ...options,
    }
  )
}

export const doUpdateEmailMarketingMailboxSettings = (
  integrationId,
  mailboxIds,
  options = {}
) => (dispatch, getState) => {
  const state = getState()
  const { name } = selectIntegrationDataForProviderId(state, integrationId)
  const unhashedMailboxIds = mailboxIds.map(id => reverseHashInt(id))

  return dispatch(
    doApiWriteRequest(
      EMAIL_MARKETING_UPDATE_INTEGRATION_MAILBOX_SETTINGS,
      `email_marketing/integrations/${integrationId}/mailbox_settings`,
      {
        mailbox_ids: unhashedMailboxIds,
      },
      {
        method: 'put',
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: `${name} saved`,
            },
            failed: {
              content: `Unable to save ${name}`,
              onClickAction: () => {
                dispatch(
                  doUpdateEmailMarketingMailboxSettings(
                    integrationId,
                    mailboxIds,
                    options
                  )
                )
              },
            },
          },
        },
        ...options,
      }
    )
  )
}

export const doInstallEmailMarketingIntegration = (
  integrationId,
  options = {}
) => async (dispatch, getState) => {
  const {
    oAuthWindowOptions = { width: 600, height: 900 },
    fetchOauthOptions = {},
  } = options
  const state = getState()
  const {
    name,
    installActionTypeConfig: { backendType } = {},
  } = selectIntegrationDataForProviderId(state, integrationId)

  const url = await dispatch(
    doFetchOAuthAuthenticationUrl(integrationId, fetchOauthOptions)
  )

  const oAuthFlow = new OAuthWindow({
    title: `Add ${name}`,
    url,
    ...oAuthWindowOptions,
  })

  const {
    access_token: accessToken,
    refresh_token: refreshToken,
  } = await oAuthFlow.start().catch(err => {
    oAuthFlow.cancel()
    throw err
  })

  await dispatch(
    doApiWriteRequest(
      EMAIL_MARKETING_INSTALL_INTEGRATION,
      `email_marketing/integrations`,
      {
        type: backendType,
        token: accessToken,
        refresh_token: refreshToken,
      },
      {
        method: 'post',
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: false,
              content: `Installed ${name}`,
            },
            failed: {
              content: `Unable to install ${name}`,
              onClickAction: () => {
                dispatch(
                  doInstallEmailMarketingIntegration(integrationId, options)
                )
              },
            },
          },
        },
        ...options,
      }
    )
  )

  return dispatch(doPostIntegrationInstallForProvider(integrationId))
}

export const doUninstallEmailMarketingIntegration = (
  integrationId,
  options = {}
) => async (dispatch, getState) => {
  const state = getState()
  const { name } = selectIntegrationDataForProviderId(state, integrationId)

  await dispatch(
    doApiWriteRequest(
      EMAIL_MARKETING_UNINSTALL_INTEGRATION,
      `email_marketing/integrations/${integrationId}`,
      {},
      {
        method: 'delete',
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: `Uninstalled ${name}`,
            },
            failed: {
              content: `Unable to uninstall ${name}`,
              onClickAction: () => {
                dispatch(
                  doUninstallEmailMarketingIntegration(integrationId, options)
                )
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                // Because we're passing through a global id here, we need to manually delete
                // the entity from the store
                entityType: 'emailMarketingIntegration',
                entityId: integrationId,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['STARTED'],
              },
            ],
          },
        },
        ...options,
      }
    )
  )
  return dispatch(doPostIntegrationInstallForProvider(integrationId))
}

export const doRebuildEmailMarketingIntegration = (
  integrationId,
  options = {}
) => async (dispatch, getState) => {
  const state = getState()
  const { name } = selectIntegrationDataForProviderId(state, integrationId)

  return dispatch(
    doApiWriteRequest(
      EMAIL_MARKETING_REBUILD_LISTS_WEBHOOKS_INTEGRATION,
      `email_marketing/integrations/${integrationId}/rebuild`,
      {},
      {
        method: 'post',
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: false,
              content: `Rebuilt ${name} successfully`,
            },
            failed: {
              content: `Unable to rebuild ${name}`,
              onClickAction: () => {
                dispatch(
                  doRebuildEmailMarketingIntegration(integrationId, options)
                )
              },
            },
          },
        },
        ...options,
      }
    )
  )
}
