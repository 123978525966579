import { oauthTokenSelector } from 'selectors/app'
import { CANCEL_SUBSCRIPTION_REQUEST } from 'ducks/integrations/recharge/types'
import { doSdkRequest } from 'ducks/requests/operations'
import { doLinkResource } from 'actions/userIntegrations'
import { getRechargeMerchantUrl } from 'ducks/integrations/recharge/utils'

import selectIntegrationForId from 'ducks/integrations/selectors/selectIntegrationForId'
import * as api from '../api'

const doCancelSubscription = ({
  customerId,
  subscriptionId,
  integrationId,
  reason,
  reasonComment,
  sendEmail,
}) => {
  return doSdkRequest(
    CANCEL_SUBSCRIPTION_REQUEST,
    async ({ getState, dispatch }) => {
      let state = getState()
      const token = oauthTokenSelector(state)

      const data = {
        cancellation_reason: reason,
        cancellation_reason_comments: reasonComment,
        send_email: sendEmail,
      }
      const { response } = await api.cancelSubscription(
        token,
        integrationId,
        subscriptionId,
        JSON.stringify(data)
      )

      state = getState()

      const integration = selectIntegrationForId(state, integrationId)

      const subscriptionUrl = getRechargeMerchantUrl(
        integration.fullStoreDomain,
        `merchant/subscriptions/${subscriptionId}/details`
      )

      await dispatch(
        doLinkResource({
          id: `${customerId}-${subscriptionId}-subscription-cancel`,
          provider: 'recharge',
          title: `${subscriptionId}-subscription-cancel`,
          url: subscriptionUrl,
          removed: true,
        })
      )

      return response
    },
    {
      customerId,
      subscriptionId,
      integrationId,
      reason,
      reasonComment,
      sendEmail,
    },
    {
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: 'Subscription cancelled successfully!',
          },
          failed: {
            content: 'Something went wrong.',
          },
        },
      },
    }
  )
}

export default doCancelSubscription
