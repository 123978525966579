import { doLinkResource } from 'actions/userIntegrations'

import { selectGitHubNodesById } from '../selectors'
import doPostTicketLinkOnGitHubIssue from './doPostTicketLinkOnGitHubIssue'

export default function doLinkTrelloCardToTicket(issueId) {
  return (dispatch, getState) => {
    const state = getState()
    const issues = selectGitHubNodesById(state)
    const issue = issues[issueId]
    if (!issue) return Promise.reject('Cannot find GitHub issue')

    const { title, url, id } = issue

    return Promise.all([
      dispatch(doLinkResource({ title, url, id, provider: 'github' })),
      dispatch(doPostTicketLinkOnGitHubIssue(issueId)),
    ])
  }
}
