import { toObject } from 'util/arrays'

import { FETCH_BOARD_DETAILS_SUCCESS, FETCH_CARD_SUCCESS } from '../types'

const initialState = {}

export default function reduceLabels(
  state = initialState,
  { payload: { labels = [] } = {}, type }
) {
  if (type === FETCH_BOARD_DETAILS_SUCCESS || type === FETCH_CARD_SUCCESS)
    return { ...state, ...toObject(labels) }
  return state
}
