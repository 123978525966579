import { createSelector } from 'reselect'

import { groupBy } from 'util/arrays'

import selectIntegrationCredentials from './selectIntegrationCredentials'

export default createSelector(
  selectIntegrationCredentials,
  integrationCredentials => ({
    ...groupBy(integrationCredentials, ({ provider }) => provider),
    // Hack, the code assumes the integration id provider ids will be lower case, but something has changed
    // and we need to force it to  lower case :/
    ...groupBy(integrationCredentials, ({ provider }) =>
      provider.toLowerCase()
    ),
  })
)
