import { getRawId } from 'util/globalId'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'

function buildFolderKindsTypes({ currentUserId }) {
  return [
    {
      type: 'unassigned',
      conditions: [
        {
          param: 'assigned_agent',
          operators: ['eq'],
          value: [null],
        },
        {
          param: 'assigned_group',
          operators: ['eq'],
          value: [null],
        },
        {
          param: 'status',
          operators: ['lteq', 'eq'],
          value: ['opened'],
        },
      ],
    },
    currentUserId && {
      type: 'mine',
      conditions: [
        {
          param: 'assigned_agent',
          operators: ['eq'],
          // 4517239960 is scatterswap for -1
          value: [currentUserId, '-1', '4517239960'],
        },
        {
          param: 'status',
          operators: ['lteq', 'eq'],
          value: ['opened'],
        },
      ],
    },
    {
      type: 'closed',
      conditions: [
        {
          param: 'status',
          operators: ['eq'],
          value: 'closed',
        },
        {
          param: 'snooze_until',
          operators: ['lt'],
          value: ['indefinitely'],
        },
      ],
    },
    {
      type: 'closed',
      conditions: [
        {
          param: 'status',
          operators: ['eq'],
          value: ['closed'],
        },
      ],
    },
    currentUserId && {
      type: 'drafts',
      conditions: [
        {
          param: 'drafts',
          operators: ['eq'],
          value: [currentUserId, '-1', '4517239960'],
        },
      ],
    },
    {
      type: 'assigned',
      conditions: [
        {
          param: 'assigned_agent',
          operators: ['not_eq'],
          value: [null],
        },
        {
          param: 'status',
          operators: ['lteq', 'eq'],
          value: ['opened'],
        },
      ],
    },
    {
      type: 'spam',
      conditions: [
        {
          param: 'status',
          operators: ['eq'],
          value: ['spam'],
        },
      ],
    },
    {
      type: 'trash',
      conditions: [
        {
          param: 'deleted',
          operators: ['eq'],
          value: ['true'],
        },
      ],
    },
    currentUserId && {
      type: 'snoozed',
      conditions: [
        {
          param: 'snooze_state',
          operators: ['eq'],
          value: [currentUserId, '-1', '4517239960'],
        },
      ],
    },
    {
      type: 'open',
      conditions: [
        {
          param: 'status',
          operators: ['eq', 'lteq'],
          value: ['opened'],
        },
      ],
    },
  ].filter(f => !!f)
}

export function getFolderKind(folder, data) {
  return (
    buildFolderKindsTypes(data).find(folderType => {
      if (folder.conditions.length !== folderType.conditions.length) return null
      let isMatch = true
      folderType.conditions.some(folderTypeCondition => {
        isMatch = folder.conditions.some(folderCondition => {
          return (
            folderTypeCondition.param === folderCondition.param.toLowerCase() &&
            folderTypeCondition.value.includes(folderCondition.value) &&
            folderTypeCondition.operators.includes(
              folderCondition.operator.toLowerCase()
            )
          )
        })
        return !isMatch
      })
      return isMatch ? folderType : null
    })?.type || null
  )
}

export function filterByActiveAndMailboxId(mailboxId) {
  return folder => {
    return (
      folder.state === 'ACTIVE' &&
      Object.values(folder.channels).some(
        ({ id: channelId }) => getRawId(channelId) === getRawId(mailboxId)
      )
    )
  }
}

export function mapWidgetChannelTypeToPageType(channelType) {
  if (channelType === 'social') {
    return SOCIAL_PAGE
  }
  return CHAT_PAGE
}

export function mapPageTypeToWidgetChannelType(pageType) {
  if (pageType === SOCIAL_PAGE) {
    return 'social'
  }
  return 'widget'
}
