import { selectCurrentOpenDrawerType } from 'ducks/drawers/selectors'
import { createBasicSelector } from 'util/redux'
import {
  DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD,
  DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD_FEEDBACK,
} from 'ducks/drawers/types'

export const selectIsBillingSetupPaymentDrawerOpen = createBasicSelector(
  selectCurrentOpenDrawerType,
  currentOpenDrawerType =>
    [
      DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD,
      DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD_FEEDBACK,
    ].includes(currentOpenDrawerType)
)
