import { FETCH_CRM_COUNTS_SUCCESS } from '../../types'

const defaultState = null

export default function reduceTotalCount(
  state = defaultState,
  { type, payload: { contacts } = {} }
) {
  if (type === FETCH_CRM_COUNTS_SUCCESS) return contacts
  return state
}
