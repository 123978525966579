import {
  FETCH_PRIORITIES_ERROR,
  FETCH_PRIORITIES_REQUEST,
  FETCH_PRIORITIES_SUCCESS,
} from '../types'

const initialState = { byId: {}, errored: false, loading: false }

export default function reducePriorities(
  state = initialState,
  { type, payload: priorities = [] }
) {
  if (type === FETCH_PRIORITIES_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  if (type === FETCH_PRIORITIES_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }
  if (type === FETCH_PRIORITIES_SUCCESS) {
    return {
      ...state,
      byId: {
        ...priorities.reduce((result, priority) => {
          // eslint-disable-next-line no-param-reassign
          result[priority.id] = priority
          return result
        }, {}),
      },
      errored: false,
      loading: false,
    }
  }
  return state
}
