import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectChargesForCustomerId } from 'ducks/integrations/stripe/selectors/charges'

import doFetchCharges from 'ducks/integrations/stripe/operations/doFetchCharges'

export const useCharges = (integrationId, customerId) => {
  const dispatch = useDispatch()

  const chargesData = useSelector(state =>
    selectChargesForCustomerId(state, customerId)
  )

  useEffect(
    () => {
      if (customerId && !chargesData.isInitialized) {
        dispatch(doFetchCharges({ integrationId, customerId }))
      }
    },
    [dispatch, integrationId, customerId, chargesData]
  )

  if (chargesData) {
    return chargesData
  }

  return {}
}

export const useStatusText = charge => {
  const statusText = useMemo(
    () => {
      switch (charge.status) {
        case 'succeeded':
          return 'Succeeded'
        case 'pending':
          return 'Incomplete'
        case 'failed':
          return 'Failed'
        default:
          return ''
      }
    },
    [charge]
  )

  return statusText
}

export const useStatus = charge => {
  const status = useMemo(
    () => {
      switch (charge.status) {
        case 'succeeded':
          return 'success'
        case 'pending':
          return 'neutral'
        case 'failed':
          return 'negative'
        default:
          return ''
      }
    },
    [charge]
  )

  return status
}
