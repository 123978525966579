import { createActionTypeReducer } from 'util/reducers'
import {
  DRAFT_ORDER_CREATE_REQUEST_STARTED,
  DRAFT_ORDER_CREATE_REQUEST_FAILED,
  DRAFT_ORDER_CREATE_REQUEST_SUCCESS,
  DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_STARTED,
  DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_FAILED,
  DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_SUCCESS,
  DRAFT_ORDER_UPDATE_REQUEST_STARTED,
  DRAFT_ORDER_UPDATE_REQUEST_FAILED,
  DRAFT_ORDER_UPDATE_REQUEST_SUCCESS,
  FETCH_DRAFT_ORDERS_REQUEST_STARTED,
  FETCH_DRAFT_ORDERS_REQUEST_FAILED,
  FETCH_DRAFT_ORDERS_REQUEST_SUCCESS,
  DRAFT_ORDER_COMPLETE_REQUEST_STARTED,
  DRAFT_ORDER_COMPLETE_REQUEST_FAILED,
  DRAFT_ORDER_COMPLETE_REQUEST_SUCCESS,
  DRAFT_ORDER_DELETE_REQUEST_SUCCESS,
  DRAFT_ORDER_CLEAR_PRODUCT_LINE_ITEMS_LOCALLY,
  FETCH_DRAFT_ORDER_BY_ID_REQUEST_SUCCESS,
} from '../types'

const defaultState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  byId: {},
  byCustomerId: {},
}

const buildLineItem = item => {
  if (item.custom) {
    const quantity = item.quantity || 1
    const price = item.originalUnitPrice * 100
    const newItem = {
      ...item,
      displayName: item.name,
      title: item.name,
      product: {
        name: item.name,
      },
      customPrice: item.originalUnitPrice,
      price: {
        price,
        currency: 'USD',
      },
      total: price * quantity,
      requiresShipping: item.requiresShipping,
      appliedDiscount: item.appliedDiscount,
    }
    return newItem
  }
  const { variant, product } = item
  const quantity = item.quantity || variant.quantity || 1
  const price = variant.price * 100
  const name = product.hasOnlyDefaultVariant ? item.name : variant.displayName
  const newItem = {
    ...variant,
    variantId: item.id,
    product: {
      name,
      stock: variant.inventoryQuantity,
      sku: variant.sku,
    },
    price: {
      price,
      currency: 'USD',
    },
    quantity,
    total: price * quantity,
    requiresShipping: item.requiresShipping,
    appliedDiscount: item.appliedDiscount,
    image: item.image,
  }
  return newItem
}

const processDraftOrder = (draftState, draftOrder) => {
  const newItem = { ...draftOrder }
  newItem.lineItems = newItem.lineItems.edges.map(lineItem =>
    buildLineItem(lineItem.node)
  )
  draftState.byId[draftOrder.id] = newItem
}

const processDraftOrderCreate = (draftState, draftOrder, customerId) => {
  draftState.isCreating = false
  processDraftOrder(draftState, draftOrder)
  draftState.byCustomerId[customerId] = draftOrder.id
}

const linkDraftOrderToCustomerId = (draftState, draftOrder) => {
  draftState.byCustomerId[draftOrder.customer.id] = draftOrder.id
}

export default createActionTypeReducer(
  {
    [DRAFT_ORDER_CREATE_REQUEST_STARTED]: draftState => {
      draftState.isCreating = true
      return draftState
    },
    [DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_STARTED]: draftState => {
      draftState.isCreating = true
      return draftState
    },
    [DRAFT_ORDER_CREATE_REQUEST_FAILED]: draftState => {
      draftState.isLoading = false
      draftState.isCreating = false
      return draftState
    },
    [DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_FAILED]: draftState => {
      draftState.isLoading = false
      draftState.isCreating = false
      return draftState
    },
    [DRAFT_ORDER_CREATE_REQUEST_SUCCESS]: (draftState, action) => {
      const { payload, meta } = action
      const {
        draftOrderCreate: { draftOrder },
      } = payload
      const {
        requestParameters: {
          input: { customerId },
        },
      } = meta

      processDraftOrderCreate(draftState, draftOrder, customerId)

      return draftState
    },
    [DRAFT_ORDER_CREATE_FROM_ORDER_REQUEST_SUCCESS]: (draftState, action) => {
      const {
        payload: {
          draftOrderCreateFromOrder: { draftOrder },
        },
        meta: { customerId },
      } = action

      processDraftOrderCreate(draftState, draftOrder, customerId)

      return draftState
    },
    [FETCH_DRAFT_ORDERS_REQUEST_STARTED]: draftState => {
      draftState.isLoading = true
      return draftState
    },
    [FETCH_DRAFT_ORDERS_REQUEST_FAILED]: draftState => {
      draftState.isLoading = false
      return draftState
    },
    [FETCH_DRAFT_ORDERS_REQUEST_SUCCESS]: (draftState, action) => {
      const { payload } = action
      const { draftOrders } = payload
      draftState.isLoading = false

      if (!draftOrders?.edges?.length) return draftState

      draftOrders.edges.forEach(item => {
        processDraftOrder(draftState, item.node)
      })

      const node = draftOrders.edges[0].node
      draftState.byCustomerId[node.customer.id] = node.id
      linkDraftOrderToCustomerId(draftState, node)

      return draftState
    },
    [FETCH_DRAFT_ORDER_BY_ID_REQUEST_SUCCESS]: (draftState, action) => {
      const { payload } = action
      const { draftOrder } = payload

      if (!draftOrder) return draftState

      processDraftOrder(draftState, draftOrder)
      linkDraftOrderToCustomerId(draftState, draftOrder)

      return draftState
    },
    [DRAFT_ORDER_UPDATE_REQUEST_STARTED]: draftState => {
      draftState.isUpdating = true
      return draftState
    },
    [DRAFT_ORDER_UPDATE_REQUEST_FAILED]: draftState => {
      draftState.isUpdating = false
      return draftState
    },
    [DRAFT_ORDER_UPDATE_REQUEST_SUCCESS]: (draftState, action) => {
      const { payload } = action
      const {
        draftOrderUpdate: { draftOrder },
      } = payload

      draftState.isUpdating = false
      const newOrder = { ...draftOrder }
      newOrder.lineItems = newOrder.lineItems.edges.map(lineItem =>
        buildLineItem(lineItem.node)
      )
      draftState.byId[draftOrder.id] = newOrder
      return draftState
    },
    [DRAFT_ORDER_CLEAR_PRODUCT_LINE_ITEMS_LOCALLY]: (draftState, action) => {
      const { payload: { draftOrderId } = {} } = action

      draftState.isUpdating = false

      if (!draftOrderId) return draftState

      const draftOrder = draftState.byId[draftOrderId] || {}

      draftOrder.lineItems = []
      // keeping discount, shipping, tags, notes for order form usage continuity
      draftOrder.lineItemsSubtotalPrice = null
      draftOrder.totalPrice = null
      draftOrder.totalTax = null

      draftState.byId[draftOrderId] = draftOrder

      return draftState
    },
    [DRAFT_ORDER_COMPLETE_REQUEST_STARTED]: draftState => {
      draftState.isUpdating = true
      return draftState
    },
    [DRAFT_ORDER_COMPLETE_REQUEST_FAILED]: draftState => {
      draftState.isUpdating = false
      return draftState
    },
    [DRAFT_ORDER_COMPLETE_REQUEST_SUCCESS]: (draftState, action) => {
      const { payload } = action
      const {
        draftOrderComplete: { draftOrder },
      } = payload

      delete draftState.byId[draftOrder.id]
      draftState.isUpdating = false
      return draftState
    },
    [DRAFT_ORDER_DELETE_REQUEST_SUCCESS]: (draftState, action) => {
      const { payload } = action
      const {
        draftOrderDelete: { deletedId },
      } = payload

      delete draftState.byId[deletedId]
      draftState.isUpdating = false

      return draftState
    },
  },
  defaultState
)
