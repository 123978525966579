export const PRESENCE_UPDATE = 'CHAT/PRESENCE/UPDATE'
export const PRESENCE_UPDATE_SETTINGS = 'CHAT/PRESENCE/UPDATE_SETTINGS'

export const USER_PRESENCE_CHANGED = 'CHAT/PRESENCE/USER_CHANGED'
export const BULK_USER_PRESENCE_CHANGED = 'CHAT/PRESENCE/BULK_USER_CHANGED'
export const MX_CLIENT_LOADING = 'CHAT/MX_CLIENT_LOADING'

export const PRESENCE_UPDATE_CACHE = 'CHAT/PRESENCE/UPDATE/CACHE'
export const PRESENCE_UPDATE_CACHE_STARTED =
  'CHAT/PRESENCE/UPDATE/CACHE_STARTED'
export const PRESENCE_UPDATE_CACHE_SUCCESS =
  'CHAT/PRESENCE/UPDATE/CACHE_SUCCESS'
export const PRESENCE_UPDATE_CACHE_FAILED = 'CHAT/PRESENCE/UPDATE/CACHE_FAILED'
