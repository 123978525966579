import { oauthTokenSelector } from 'selectors/app'

import * as trello from '../api'
import {
  FETCH_BOARDS_REQUEST,
  FETCH_BOARDS_SUCCESS,
  FETCH_BOARDS_FAILURE,
} from '../types'

export default function doFetchTrelloBoards() {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_BOARDS_REQUEST,
    })
    return trello
      .listBoards(oauthTokenSelector(getState()))
      .then(({ boards }) =>
        dispatch({
          type: FETCH_BOARDS_SUCCESS,
          payload: { boards },
        })
      )
      .catch(error =>
        dispatch({
          error: true,
          payload: error,
          type: FETCH_BOARDS_FAILURE,
        })
      )
  }
}
