import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { FETCH_ATTACHMENTS_REQUEST } from './types'
import { attachmentsQuery } from './queries'

export function doFetchAttachments(ticketId, type) {
  return doAppGraphqlRequest(FETCH_ATTACHMENTS_REQUEST, attachmentsQuery, {
    conversationId: ticketId,
    conversationType: type.toUpperCase(),
  })
}
