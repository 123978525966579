export const CREATE_ISSUE_ERROR = 'integrations/jira_cloud/CREATE_ISSUE_ERROR'
export const CREATE_ISSUE_REQUEST =
  'integrations/jira_cloud/CREATE_ISSUE_REQUEST'
export const CREATE_ISSUE_SUCCESS =
  'integrations/jira_cloud/CREATE_ISSUE_SUCCESS'

export const FETCH_SITES_ERROR = 'integrations/jira_cloud/FETCH_SITES_ERROR'
export const FETCH_SITES_REQUEST = 'integrations/jira_cloud/FETCH_SITES_REQUEST'
export const FETCH_SITES_SUCCESS = 'integrations/jira_cloud/FETCH_SITES_SUCCESS'

export const FETCH_ISSUE_SEARCH_ERROR =
  'integrations/jira_cloud/FETCH_ISSUE_SEARCH_ERROR'
export const FETCH_ISSUE_SEARCH_REQUEST =
  'integrations/jira_cloud/FETCH_ISSUE_SEARCH_REQUEST'
export const FETCH_ISSUE_SEARCH_SUCCESS =
  'integrations/jira_cloud/FETCH_ISSUE_SEARCH_SUCCESS'

export const FETCH_ISSUES_ERROR = 'integrations/jira_cloud/FETCH_ISSUES_ERROR'
export const FETCH_ISSUES_REQUEST =
  'integrations/jira_cloud/FETCH_ISSUES_REQUEST'
export const FETCH_ISSUES_SUCCESS =
  'integrations/jira_cloud/FETCH_ISSUES_SUCCESS'

export const FETCH_PROJECTS_ERROR =
  'integrations/jira_cloud/FETCH_PROJECTS_ERROR'
export const FETCH_PROJECTS_REQUEST =
  'integrations/jira_cloud/FETCH_PROJECTS_REQUEST'
export const FETCH_PROJECTS_SUCCESS =
  'integrations/jira_cloud/FETCH_PROJECTS_SUCCESS'

export const FETCH_ASSIGNABLE_USERS_ERROR =
  'integrations/jira_cloud/FETCH_ASSIGNABLE_USERS_ERROR'
export const FETCH_ASSIGNABLE_USERS_REQUEST =
  'integrations/jira_cloud/FETCH_ASSIGNABLE_USERS_REQUEST'
export const FETCH_ASSIGNABLE_USERS_SUCCESS =
  'integrations/jira_cloud/FETCH_ASSIGNABLE_USERS_SUCCESS'

export const FETCH_COMPONENTS_ERROR =
  'integrations/jira_cloud/FETCH_COMPONENTS_ERROR'
export const FETCH_COMPONENTS_REQUEST =
  'integrations/jira_cloud/FETCH_COMPONENTS_REQUEST'
export const FETCH_COMPONENTS_SUCCESS =
  'integrations/jira_cloud/FETCH_COMPONENTS_SUCCESS'

export const FETCH_PRIORITIES_ERROR =
  'integrations/jira_cloud/FETCH_PRIORITIES_ERROR'
export const FETCH_PRIORITIES_REQUEST =
  'integrations/jira_cloud/FETCH_PRIORITIES_REQUEST'
export const FETCH_PRIORITIES_SUCCESS =
  'integrations/jira_cloud/FETCH_PRIORITIES_SUCCESS'

export const FETCH_TYPES_ERROR = 'integrations/jira_cloud/FETCH_TYPES_ERROR'
export const FETCH_TYPES_REQUEST = 'integrations/jira_cloud/FETCH_TYPES_REQUEST'
export const FETCH_TYPES_SUCCESS = 'integrations/jira_cloud/FETCH_TYPES_SUCCESS'

export const FETCH_LABELS_ERROR = 'integrations/jira_cloud/FETCH_LABELS_ERROR'
export const FETCH_LABELS_REQUEST =
  'integrations/jira_cloud/FETCH_LABELS_REQUEST'
export const FETCH_LABELS_SUCCESS =
  'integrations/jira_cloud/FETCH_LABELS_SUCCESS'

export const FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_ERROR =
  'integrations/jira_cloud/FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_ERROR'
export const FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_REQUEST =
  'integrations/jira_cloud/FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_REQUEST'
export const FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_SUCCESS =
  'integrations/jira_cloud/FETCH_CREATE_ISSUE_META_ISSUE_TYPE_FIELDS_SUCCESS'
