import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectSubscriptionsForCustomerId } from 'ducks/integrations/recharge/selectors/subscriptions'

import doFetchSubscriptions from 'ducks/integrations/recharge/operations/doFetchSubscriptions'

export const useRechargeSubscriptions = (integrationId, customerId) => {
  const dispatch = useDispatch()

  const subscriptionsData = useSelector(state =>
    selectSubscriptionsForCustomerId(state, customerId)
  )

  useEffect(
    () => {
      if (subscriptionsData.subscriptions === undefined && customerId) {
        dispatch(doFetchSubscriptions({ integrationId, customerId }))
      }
    },
    [dispatch, integrationId, customerId, subscriptionsData]
  )

  if (subscriptionsData) {
    return subscriptionsData
  }

  return {}
}
