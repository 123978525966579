import {
  FETCH_SITES_ERROR,
  FETCH_SITES_REQUEST,
  FETCH_SITES_SUCCESS,
} from '../types'

const initialState = { byId: {}, errored: false, loading: false }
const JIRA_SITE_NAME_FROM_URL_REGEX = /^https?:\/\/([^.]+)/

export default function reduceInstalls(
  state = initialState,
  { type, payload: { sites } = {} }
) {
  if (type === FETCH_SITES_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  if (type === FETCH_SITES_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }
  if (type === FETCH_SITES_SUCCESS) {
    return {
      ...state,
      byId: {
        ...state.byId,
        ...sites.reduce((result, site) => {
          // eslint-disable-next-line no-param-reassign
          result[site.id] = {
            ...site,
            name: site.url.match(JIRA_SITE_NAME_FROM_URL_REGEX)[1],
          }
          return result
        }, {}),
      },
      errored: false,
      loading: false,
    }
  }
  return state
}
