import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import { INVOICE_PREVIEW_REQUEST } from '../types'

const mutation = `
  mutation draftOrderInvoicePreview($id: ID!, $email: EmailInput) {
    draftOrderInvoicePreview(id: $id, email: $email) {
      previewHtml
      previewSubject
    }
  }
`

const doPreviewInvoice = (
  integrationId,
  draftOrderId,
  emailData
) => async dispatch => {
  const { from, to, bcc, customMessage, subject } = emailData

  const variables = {
    id: draftOrderId,
    email: {
      from,
      to,
      bcc,
      customMessage,
      subject,
    },
  }

  const { draftOrderInvoicePreview } = await dispatch(
    doGraphqlRequest(INVOICE_PREVIEW_REQUEST, mutation, variables, {
      graphqlFunc: graphql(integrationId),
    })
  )

  return draftOrderInvoicePreview
}

export default doPreviewInvoice
