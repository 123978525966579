import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'

import { selectBase } from './selectBase'

const selectConversationChargeStatus = createBasicSelector(
  selectBase,
  base => base.conversationChargeStatus || emptyObj
)

export const selectShouldChargeConversation = createBasicSelector(
  selectConversationChargeStatus,
  ({ needsCharge, isCharged, loading }) => needsCharge && !isCharged && !loading
)
