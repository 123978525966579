import { createSelector } from 'reselect'
import { selectPreferences } from './selectPreferences'

// HACK (jscheel && msaspence): Account-level timezone preference is pulled in
// through the current user, even though it is account level.

export const selectAccountTimezone = createSelector(
  selectPreferences,
  preferences => {
    const { timezone: { account: { id } = {} } = {} } = preferences || {}
    return id
  }
)
