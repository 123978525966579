import {
  EMAIL_MARKETING_FETCH_INTEGRATIONS_STARTED,
  EMAIL_MARKETING_FETCH_INTEGRATIONS_FAILED,
  FETCH_CUSTOM_FIELD_CATEGORIES_FAILED,
  EMAIL_MARKETING_FETCH_INTEGRATIONS_SUCCESS,
} from '../../types'

export default function isLoading(state = false, { type }) {
  if (type === EMAIL_MARKETING_FETCH_INTEGRATIONS_STARTED) return true
  if (
    [
      EMAIL_MARKETING_FETCH_INTEGRATIONS_SUCCESS,
      EMAIL_MARKETING_FETCH_INTEGRATIONS_FAILED,
      FETCH_CUSTOM_FIELD_CATEGORIES_FAILED,
    ].includes(type)
  ) {
    return false
  }
  return state
}
