import { selectIsInChat } from 'selectors/location'
import { selectIsComposingNewTicket } from 'selectors/tickets/current/selectIsComposingNewTicket'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectCurrentMatrixUserId } from 'ducks/chat/selectors/users'
import { selectDraftByTicketId } from 'ducks/drafts2/selectors'
import {
  selectIsCreatingNewEmail,
  selectIsLoggingNewConversation,
} from 'selectors/page'
import { selectContactForEmail } from 'ducks/crm/contacts/selectors/base'
import { doFetchContactForConversationId } from './doFetchContactForConversationId'
import { doFetchContactForCustomField } from './doFetchContactForCustomField'
import { doFetchContactForPrimaryEmail } from './doFetchContactForPrimaryEmail'
import { selectFetchContactForPrimaryEmailRequestState } from '../../selectors'

export function doFetchCurrentContact(options = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const conversationId = selectCurrentTicketId(state)
    const matrixUserId = selectCurrentMatrixUserId(state)
    const isInChat = selectIsInChat(state)
    const isNewTicket = selectIsComposingNewTicket(state)

    if (isNewTicket) {
      let draftType
      if (selectIsCreatingNewEmail(state)) {
        draftType = 'reply'
      } else if (selectIsLoggingNewConversation(state)) {
        draftType = 'note'
      } else {
        return null
      }
      const currentDraft = selectDraftByTicketId(
        state,
        conversationId,
        draftType
      )
      const enduser = currentDraft?.to?.[0]
      if (enduser?.email) {
        const email = enduser.email
        const contact = selectContactForEmail(state, email)

        if (contact) {
          return contact
        }

        const { loading } = selectFetchContactForPrimaryEmailRequestState(
          state,
          email
        )
        // prevent unnecessary api requests for same contact email
        if (loading) {
          return undefined
        }

        return dispatch(doFetchContactForPrimaryEmail(email, true))
      }
    } else if (matrixUserId) {
      return dispatch(
        doFetchContactForCustomField(
          'contact_matrix_id',
          matrixUserId,
          conversationId,
          isInChat,
          false,
          options
        )
      )
    } else if (conversationId && !isInChat) {
      return dispatch(doFetchContactForConversationId(conversationId))
    }
    // Will be added for loading sidebar for contact detail page
    // if (contactId) {
    //   dispatch(doFetchContactForId(contactId))
    // } else
    return null
  }
}
