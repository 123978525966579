import {
  cannedReplyCategory,
  cannedReply,
  cannedReplyVariables,
} from 'ducks/entities/schema'

export const cannedReplyGraphQlResponseSchema = {
  cannedReplies: { nodes: [cannedReply] },
}

export const cannedReplyApiV1ResponseSchema = {
  template: cannedReply,
}

export const cannedReplyApiV0SettingsResponseSchema = cannedReply

export const cannedRepliesApiV1ResponseSchema = {
  templates: [cannedReply],
}

export const cannedReplyCategoryApiV1ResponseSchema = {
  categories: [cannedReplyCategory],
}

export const cannedReplyCategoriesGraphQlResponseSchema = {
  cannedReplyCategories: { nodes: [cannedReplyCategory] },
}

export const cannedReplyCategoryGraphqlV1ResponseSchema = {
  commentTemplateCategories: [cannedReplyCategory],
}

export const cannedReplyGraphQlV1ResponseSchema = {
  commentTemplate: cannedReply,
}

export const cannedReplyVariablesApiV1ResponseSchema = {
  comment_template_variables: [cannedReplyVariables],
}
