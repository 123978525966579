import { createSelector } from 'reselect'
import { selectPreferredPersonalUsageOnboarding } from './selectPreferredPersonalUsageOnboarding'

export const selectInstalledMobileApp = createSelector(
  selectPreferredPersonalUsageOnboarding,
  personalUsageOnboarding => {
    return (
      // Falsey values are never included, so if the key doesn't exists, it is false
      personalUsageOnboarding.ios_installed ||
      personalUsageOnboarding.android_installed
    )
  }
)
