/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { pick } from 'util/objects'

export const defaultState = {
  drawerStack: [],
}

export default createActionTypeReducer(
  {
    '*': (draftState, action) => {
      const { drawerStack } = draftState
      const {
        meta: {
          query: newQuery = {},
          location: { prev: { query: currentQuery = {} } = {} } = {},
        } = {},
        drawers: { clearStack = false } = {},
      } = action

      if (clearStack) {
        drawerStack.splice(0, drawerStack.length)
        return draftState
      }

      const currentQueryKeys = Object.keys(currentQuery)
      const newQueryKeys = Object.keys(newQuery)

      if (currentQueryKeys.length === 0 && newQueryKeys.length === 0)
        return draftState

      const currentQueryDrawerTypeKey = currentQueryKeys.find(key =>
        key.toLowerCase().startsWith('drawer-')
      )
      const newQueryDrawerTypeKey = newQueryKeys.find(key =>
        key.toLowerCase().startsWith('drawer-')
      )

      const isDrawerOpen = !!currentQueryDrawerTypeKey
      const isOpeningDrawer = !!newQueryDrawerTypeKey

      const previousDrawer = drawerStack[0] || null

      let previousDrawerType = null
      if (previousDrawer) {
        const [, previousDrawerTypeValue] = Object.entries(previousDrawer).find(
          ([key]) => key.startsWith('drawer-')
        )

        previousDrawerType = previousDrawerTypeValue
      }
      const currentDrawerType = currentQuery[currentQueryDrawerTypeKey]
      const newDrawerType = newQuery[newQueryDrawerTypeKey]

      if (
        isDrawerOpen &&
        isOpeningDrawer &&
        currentDrawerType !== newDrawerType
      ) {
        // We've opening a new drawer while an existing drawer is open
        if (newDrawerType !== previousDrawerType) {
          const drawerParamKeys = Object.keys(currentQuery).filter(k =>
            k.startsWith('drawer')
          )

          const currentDrawerQuery = pick(drawerParamKeys, currentQuery, true)

          drawerStack.unshift(currentDrawerQuery)
        } else if (previousDrawerType) {
          // We're closing a drawer and opening the previous one
          drawerStack.shift()
        }
      }

      return draftState
    },
  },
  defaultState
)
