import {
  FETCH_CANNED_REPLIES_STARTED,
  SET_TEMPLATE_SEARCH_TERM,
  FETCH_CANNED_REPLY_CATEGORIES_STARTED,
} from '../types'

const reducers = {}
export const defaultState = {
  searchTerm: null,
  lastRequestedSearchTerm: null,
}

reducers[FETCH_CANNED_REPLIES_STARTED] = (state, action) => {
  const { meta: { requestParameters: { keyword = null } = {} } = {} } = action

  return {
    ...state,
    lastRequestedSearchTerm: keyword,
  }
}

reducers[FETCH_CANNED_REPLY_CATEGORIES_STARTED] =
  reducers[FETCH_CANNED_REPLIES_STARTED]

reducers[SET_TEMPLATE_SEARCH_TERM] = (state, action) => {
  const { keyword } = action.payload
  return {
    ...state,
    searchTerm: keyword,
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
