export const FETCH_COMPANIES = 'crm/companies/FETCH_COMPANIES'
export const FETCH_COMPANIES_FAILED = 'crm/companies/FETCH_COMPANIES_FAILED'
export const FETCH_COMPANIES_STARTED = 'crm/companies/FETCH_COMPANIES_STARTED'
export const FETCH_COMPANIES_SUCCESS = 'crm/companies/FETCH_COMPANIES_SUCCESS'

export const PAGE_COMPANIES = 'crm/companies/PAGE_COMPANIES'
export const SEARCH_COMPANIES = 'crm/companies/SEARCH_COMPANIES'
export const SORT_COMPANIES = 'crm/companies/SORT_COMPANIES'

export const ADD_CONTACTS_TO_COMPANY = 'crm/companies/ADD_CONTACTS_TO_COMPANY'
export const REMOVE_CONTACTS_FROM_COMPANY =
  'crm/companies/REMOVE_CONTACTS_FROM_COMPANY'
export const CHANGE_CONTACTS_COMPANY = 'crm/companies/CHANGE_CONTACTS_COMPANY'

export const FETCH_COMPANY_PAGE = 'crm/companies/FETCH_COMPANY_PAGE'
export const FETCH_COMPANY_SUCCESS = 'crm/companies/FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_STARTED = 'crm/companies/FETCH_COMPANY_STARTED'
export const FETCH_COMPANY_FAILED = 'crm/companies/FETCH_COMPANY_FAILED'

export const CREATE_COMPANY = 'crm/companies/CREATE_COMPANY'
