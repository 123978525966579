import { useCallback, useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import { logError } from 'util/debug'
import { getRoomById, getRoomIdForAlias } from 'ducks/chat/utils/rooms'
import { useSelector } from 'react-redux'
import { selectCurrentUserPrivateFullRoomAlias } from 'ducks/currentUser/selectors/base'
import { selectIsMatrixClientReady } from 'ducks/chat/selectors/client'

export function useMatrixRoomById(roomId) {
  const [loadedRoom, setLoadedRoom] = useState(null)

  const loadRoom = useCallback(
    async () => {
      if (loadedRoom) return
      const room = await getRoomById(roomId)
      setLoadedRoom(room)
    },
    [loadedRoom, roomId]
  )

  useEffect(
    () => {
      if (roomId) loadRoom()
    },
    [loadRoom, roomId]
  )

  return loadedRoom
}

export function useMatrixCurrentUserPrivateRoom() {
  const isMatrixClientReady = useSelector(selectIsMatrixClientReady)
  const fullRoomAlias = useSelector(selectCurrentUserPrivateFullRoomAlias)
  const [loadedRoom, setLoadedRoom] = useState(null)

  const loadRoom = useCallback(
    async () => {
      if (loadedRoom) return
      try {
        const roomId = await getRoomIdForAlias(fullRoomAlias)
        const room = await getRoomById(roomId)
        setLoadedRoom(room)
      } catch (ex) {
        logError(
          'useMatrixCurrentUserPrivateRoom: Error loading user private room',
          {
            msg: ex.message,
            fullRoomAlias,
          }
        )

        Bugsnag.notify(ex, event => {
          // eslint-disable-next-line no-param-reassign
          event.fullRoomAlias = fullRoomAlias
        })
      }
    },
    [loadedRoom, fullRoomAlias]
  )

  useEffect(
    () => {
      if (fullRoomAlias && isMatrixClientReady) loadRoom()
    },
    [loadRoom, fullRoomAlias, isMatrixClientReady]
  )

  return {
    room: loadedRoom,
    isMatrixClientReady,
  }
}
