import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const copy = theme => css`
  font-size: ${theme.fontSize.large};
  color: ${theme.color.info['900']};
  margin-bottom: ${rem(10)};
`

const btn = css`
  border-radius: ${rem(6)};
  padding-left: ${rem(24)};
  padding-right: ${rem(24)};
`

export const styles = {
  container,
  copy,
  btn,
}
