import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import * as types from '../types'
import { selectShopIsLoaded } from '../selectors'

const query = `
  query ShopInfo {
    shop {
      contactEmail
      currencyCode
    }
  }
`

const doFetchShopInfo = (integrationId, { skipLoaded = true } = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const isLoaded = selectShopIsLoaded(state, integrationId)

  if (skipLoaded && isLoaded) {
    return Promise.resolve({})
  }
  return dispatch(
    doGraphqlRequest(
      types.FETCH_SHOP_INFO_REQUEST,
      query,
      {},
      {
        graphqlFunc: graphql(integrationId),
        meta: {
          integrationId,
        },
      }
    )
  )
}

export default doFetchShopInfo
