import {
  doApiReadRequest,
  doApiWriteRequest,
  doAppGraphqlRequest,
} from 'ducks/requests/operations'

import {
  FETCH_CUSTOM_PROFILE,
  FETCH_CUSTOM_PROFILE_APP,
  TEST_CUSTOM_PROFILE_APP,
  UPDATE_CUSTOM_PROFILE_APP,
} from './types'

const fetchCustomProfileQuery = `
  query FetchCustomProfile($contactId: ID!, $channelId: ID, $hard: Boolean) {
    customProfile(contactId: $contactId, channelId: $channelId, hardRefresh: $hard)
  }
`
export function doFetchCustomProfile(
  contactId,
  channelId = null,
  hard = false
) {
  return doAppGraphqlRequest(FETCH_CUSTOM_PROFILE, fetchCustomProfileQuery, {
    contactId,
    hard,
    channelId,
  })
}

export function doFetchCustomProfileApp() {
  return doApiReadRequest(FETCH_CUSTOM_PROFILE_APP, 'api/custom_profile_app')
}

// HACK (jscheel): This expects a full custom profile, so just passing the fields
// straight in from the RHF in the component.
export function doTestCustomProfileApp(fields, options = {}) {
  return doApiWriteRequest(
    TEST_CUSTOM_PROFILE_APP,
    'api/custom_profile_app/webhook_test',
    fields,
    {
      method: 'POST',
      ...options,
    }
  )
}

export function doUpdateCustomProfileApp(fields, options = {}) {
  return dispatch => {
    return dispatch(
      doApiWriteRequest(
        UPDATE_CUSTOM_PROFILE_APP,
        'api/custom_profile_app',
        fields,
        {
          method: 'PUT',
          moduleOptions: {
            toasts: {
              enabled: true,
              started: {
                enabled: false,
              },
              success: {
                enabled: true,
                content: 'Custom profile changes saved',
              },
              failed: {
                enabled: true,
                content: 'Custom profile changes failed',
                onClickAction: () => {
                  dispatch(doUpdateCustomProfileApp(fields, options))
                },
              },
            },
          },
          ...options,
        }
      )
    )
  }
}
