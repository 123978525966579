import * as types from 'constants/action_types'
import { buildId } from 'util/globalId'
import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_ATTACHMENTS_REQUEST_STARTED,
  FETCH_ATTACHMENTS_REQUEST_SUCCESS,
  FETCH_ATTACHMENTS_REQUEST_FAILED,
} from './types'

export const defaultState = {
  byTicketId: {},
}

export const initialSingleState = {
  current: {
    attachments: [],
    isLoading: false,
    isLoaded: false,
  },
  all: {
    attachments: [],
    isLoading: false,
    isLoaded: false,
  },
}

export default createActionTypeReducer(
  {
    [FETCH_ATTACHMENTS_REQUEST_STARTED]: (draftState, { payload }) => {
      const { conversationId, conversationType: type } = payload

      if (!draftState.byTicketId[conversationId]) {
        draftState.byTicketId[conversationId] = {
          current: {
            attachments: [],
            isLoading: false,
            isLoaded: false,
          },
          all: {
            attachments: [],
            isLoading: false,
            isLoaded: false,
          },
        }
      }

      draftState.byTicketId[conversationId][type.toLowerCase()].isLoading = true

      return draftState
    },
    [FETCH_ATTACHMENTS_REQUEST_SUCCESS]: (draftState, { payload, meta }) => {
      const { conversationId, conversationType: type } = meta.requestParameters
      const typeName = type.toLowerCase()
      const attachments = payload.attachments.nodes

      draftState.byTicketId[conversationId][typeName].isLoading = false
      draftState.byTicketId[conversationId][typeName].isLoaded = true
      draftState.byTicketId[conversationId][typeName].attachments = [
        ...attachments,
      ]

      return draftState
    },
    [FETCH_ATTACHMENTS_REQUEST_FAILED]: (draftState, { meta }) => {
      const { conversationId, conversationType: type } = meta.requestParameters
      const typeName = type.toLowerCase()

      draftState.byTicketId[conversationId][typeName].isLoading = false
      draftState.byTicketId[conversationId][typeName].isLoaded = true

      return draftState
    },
    [types.CREATE_CHANGESET_SUCCESS]: (draftState, { data }) => {
      const { ticketId, actions } = data

      if (!draftState.byTicketId[ticketId]) {
        return draftState
      }

      const messageAction = actions.find(
        action => action.change_type === 'Message'
      )

      const commentDeletionAction = actions.find(
        action => action.change_type === 'Comment::Deletion'
      )

      if (!messageAction && !commentDeletionAction) {
        return draftState
      }

      if (messageAction) {
        const attachments = messageAction.change.attachments.map(
          attachment => ({
            ticketId,
            id: buildId('Attachment', attachment.id),
            url: attachment.url,
            downloadUrl: attachment.download_url,
            fileName: attachment.file_name,
            fileSize: attachment.file_size,
            fileType: attachment.file_type,
            changesetId: messageAction.changeset,
            commentId: messageAction.change.id,
            createdAt: messageAction.created_at,
            creator: {
              name: messageAction.actor.name,
            },
          })
        )

        if (!attachments.length) {
          return draftState
        }

        draftState.byTicketId[ticketId] = {
          ...draftState.byTicketId[ticketId],
          current: {
            ...draftState.byTicketId[ticketId].current,
            attachments: [
              ...draftState.byTicketId[ticketId].current.attachments,
              ...attachments,
            ],
          },
          all: {
            ...draftState.byTicketId[ticketId].all,
            attachments: [
              ...draftState.byTicketId[ticketId].all.attachments,
              ...attachments,
            ],
          },
        }
      }

      if (commentDeletionAction) {
        const attachmentInCurrent = draftState.byTicketId[
          ticketId
        ].current.attachments.find(
          a => a.commentId === commentDeletionAction.change.comment_id
        )

        if (attachmentInCurrent) {
          draftState.byTicketId[ticketId] = {
            ...draftState.byTicketId[ticketId],
            current: {
              ...draftState.byTicketId[ticketId].current,
              attachments: [
                ...draftState.byTicketId[ticketId].current.attachments.filter(
                  a => a.commentId !== commentDeletionAction.change.comment_id
                ),
              ],
            },
          }
        }

        const attachmentInAll = draftState.byTicketId[
          ticketId
        ].all.attachments.find(
          a => a.commentId === commentDeletionAction.change.comment_id
        )

        if (attachmentInAll) {
          draftState.byTicketId[ticketId] = {
            ...draftState.byTicketId[ticketId],
            all: {
              ...draftState.byTicketId[ticketId].all,
              attachments: [
                ...draftState.byTicketId[ticketId].all.attachments.filter(
                  a => a.commentId !== commentDeletionAction.change.comment_id
                ),
              ],
            },
          }
        }
      }

      return draftState
    },
    [types.FETCH_TICKET_SUCCESS]: (draftState, { data }) => {
      const { ticketId, ticket } = data

      if (!draftState.byTicketId[ticketId]) {
        return draftState
      }

      const existingAttachmentIds = draftState.byTicketId[
        ticketId
      ].current.attachments.map(attachment => attachment.id)

      const attachments = ticket.actions.records
        .filter(
          action =>
            action.change_type === 'Message' && action.change?.attachments
        )
        .reduce((actions, action) => {
          const newAttachments = action.change.attachments.map(a => ({
            ticketId,
            id: buildId('Attachment', a.id),
            url: a.url,
            downloadUrl: a.download_url,
            fileName: a.file_name,
            fileSize: a.file_size,
            fileType: a.file_type,
            changesetId: action.changeset,
            commentId: action.change.id,
            createdAt: action.created_at,
            creator: {
              name: action.actor.name,
            },
          }))

          return actions.concat(newAttachments)
        }, [])
        .filter(attachment => !existingAttachmentIds.includes(attachment.id))

      if (!attachments.length) {
        return draftState
      }

      if (draftState.byTicketId[ticketId].current.isLoaded) {
        draftState.byTicketId[ticketId] = {
          ...draftState.byTicketId[ticketId],
          current: {
            ...draftState.byTicketId[ticketId].current,
            attachments: [
              ...draftState.byTicketId[ticketId].current.attachments,
              ...attachments,
            ],
          },
        }
      }

      if (draftState.byTicketId[ticketId].all.isLoaded) {
        draftState.byTicketId[ticketId] = {
          ...draftState.byTicketId[ticketId],
          all: {
            ...draftState.byTicketId[ticketId].all,
            attachments: [
              ...draftState.byTicketId[ticketId].all.attachments,
              ...attachments,
            ],
          },
        }
      }

      return draftState
    },
  },
  defaultState
)
