import { combineReducers } from 'core/redux'

import instantReplyRequest from './instantReplyRequest'
import conversationRequest from './conversationRequest'
import composerRequest from './composerRequest'
import suggestions from './suggestions'
import streamingResults from './streamingResults'
import conversationSources from './conversationSources'
import conversationChargeStatus from './conversationChargeStatus'

export default combineReducers({
  instantReplyRequest,
  conversationRequest,
  composerRequest,
  suggestions,
  streamingResults,
  conversationSources,
  conversationChargeStatus,
})
