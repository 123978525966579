import { createEntityReducer } from 'util/reducers'

import { DELETE_CUSTOM_FIELD_CATEGORY_SUCCESS } from '../types'

export default createEntityReducer('customFieldCategories', {
  reduce: (
    draftState,
    {
      type,
      payload: { customFieldCategoryDelete: { deletedCategoryId } = {} } = {},
    }
  ) => {
    if (type === DELETE_CUSTOM_FIELD_CATEGORY_SUCCESS) {
      // eslint-disable-next-line no-param-reassign
      delete draftState[deletedCategoryId]
    }
  },
})
