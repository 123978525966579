import {
  CREATE_ISSUE_SUCCESS,
  FETCH_DETAILS_FOR_REPOSITORY_ERROR,
  FETCH_DETAILS_FOR_REPOSITORY_REQUEST,
  FETCH_DETAILS_FOR_REPOSITORY_SUCCESS,
  FETCH_ISSUES_ERROR,
  FETCH_ISSUES_REQUEST,
  FETCH_ISSUES_SUCCESS,
  FETCH_ISSUES_FOR_REPOSITORY_REQUEST,
  FETCH_ISSUES_FOR_REPOSITORY_SUCCESS,
  FETCH_REPOSITORIES_SUCCESS,
  FETCH_INSTALLS_SUCCESS,
  GITHUB_ISSUE_CHANGED,
} from '../types'

const initialState = {}

export default function reduceNodes(
  state = initialState,
  { meta: { id, ids } = {}, payload: { nodes, node } = {}, type }
) {
  if (type === GITHUB_ISSUE_CHANGED) {
    return {
      ...state,
      [id]: {
        ...state[id],
        ...node,
      },
    }
  }
  if ([FETCH_ISSUES_FOR_REPOSITORY_REQUEST].includes(type) && id) {
    return {
      ...state,
      [id]: {
        ...state[id],
        isLoadingIssues: true,
      },
    }
  }
  if ([FETCH_DETAILS_FOR_REPOSITORY_REQUEST].includes(type) && id) {
    return {
      ...state,
      [id]: {
        ...state[id],
        isLoadingDetails: true,
      },
    }
  }
  if ([FETCH_ISSUES_REQUEST].includes(type) && ids) {
    return {
      ...state,
      ...ids.reduce((result, issueId) => {
        // eslint-disable-next-line no-param-reassign
        result[issueId] = {
          ...state[issueId],
          loading: true,
        }
        return result
      }, {}),
    }
  }
  if (
    [
      FETCH_INSTALLS_SUCCESS,
      CREATE_ISSUE_SUCCESS,
      FETCH_ISSUES_FOR_REPOSITORY_SUCCESS,
      FETCH_ISSUES_SUCCESS,
      FETCH_REPOSITORIES_SUCCESS,
    ].includes(type)
  ) {
    return {
      ...state,
      ...nodes.reduce((result, nodesNode, index) => {
        if (!nodesNode) {
          const missingId = ids[index]
          const existingNode = state[missingId]
          // eslint-disable-next-line no-param-reassign
          result[missingId] = {
            ...existingNode,
            errored: true,
            loaded: true,
            loading: false,
          }
          return result
        }
        const existingNode = state[nodesNode.id]
        const newNode = {
          ...existingNode,
          ...nodesNode,
        }
        // eslint-disable-next-line no-underscore-dangle
        if (newNode.__typename === 'Issue') {
          // eslint-disable-next-line no-param-reassign
          if (newNode.assignees)
            // eslint-disable-next-line no-param-reassign
            newNode.assignees = newNode.assignees.nodes
          // eslint-disable-next-line no-param-reassign
          if (newNode.labels) newNode.labels = newNode.labels.nodes
          if (newNode.repository) {
            // eslint-disable-next-line no-param-reassign
            newNode.repository = {
              ...((existingNode && existingNode.repository) || {}),
              ...newNode.repository,
            }
          }
        }
        // eslint-disable-next-line no-param-reassign
        newNode.loaded = true
        // eslint-disable-next-line no-param-reassign
        newNode.foo = 'bar'
        // eslint-disable-next-line no-param-reassign
        newNode.loading = false
        // eslint-disable-next-line no-param-reassign
        result[newNode.id] = newNode
        return result
      }, {}),
      ...(type === FETCH_ISSUES_FOR_REPOSITORY_SUCCESS
        ? {
            [id]: {
              ...state[id],
              isLoadingIssues: false,
            },
          }
        : {}),
    }
  }
  if (type === FETCH_ISSUES_ERROR) {
    return {
      ...state,
      ...ids.reduce((result, nodeId) => {
        // eslint-disable-next-line no-param-reassign
        result[nodeId] = {
          ...state[nodeId],
          id: nodeId,
          loaded: true,
          loading: false,
          errored: true,
        }
        return result
      }, {}),
    }
  }
  if (type === FETCH_DETAILS_FOR_REPOSITORY_SUCCESS) {
    return {
      ...state,
      [id]: {
        ...state[id],
        isLoadingDetails: false,
        assignableUsers: nodes.filter(
          ({ __typename }) => __typename === 'User'
        ),
        labels: nodes.filter(({ __typename }) => __typename === 'Label'),
        milestones: nodes.filter(
          ({ __typename }) => __typename === 'Milestone'
        ),
      },
    }
  }
  if ([FETCH_ISSUES_FOR_REPOSITORY_SUCCESS].includes(type)) {
    return {
      ...state,
      [id]: {
        ...state[id],
        isLoadingIssues: false,
      },
      ...nodes.reduce((result, nodesNode) => {
        // eslint-disable-next-line no-param-reassign
        nodesNode.loading = false
        // eslint-disable-next-line no-param-reassign
        result[nodesNode.id] = nodesNode
        return result
      }, {}),
      ...(type === FETCH_ISSUES_FOR_REPOSITORY_SUCCESS
        ? {
            [id]: {
              ...state[id],
              isLoadingIssues: false,
            },
          }
        : {}),
    }
  }
  if (type === FETCH_DETAILS_FOR_REPOSITORY_SUCCESS) {
    return {
      ...state,
      [id]: {
        ...state[id],
        isLoadingDetails: false,
        assignableUsers: nodes.filter(
          ({ __typename }) => __typename === 'User'
        ),
        labels: nodes.filter(({ __typename }) => __typename === 'Label'),
        milestones: nodes.filter(
          ({ __typename }) => __typename === 'Milestone'
        ),
      },
    }
  }
  if (type === FETCH_DETAILS_FOR_REPOSITORY_ERROR) {
    return {
      ...state,
      [id]: {
        ...state[id],
        loaded: true,
        loading: false,
        errored: true,
      },
    }
  }
  return state
}
