import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'

import selectJiraSearchedIssueIds from './selectJiraSearchedIssueIds'
import selectJiraIssuesById from './selectJiraIssuesById'

export default createSelector(
  selectJiraSearchedIssueIds,
  selectJiraIssuesById,
  (issueIds, issuesById) => {
    if (!issueIds) return emptyArr
    return issueIds.map(issueId => {
      const {
        id,
        fields: {
          components,
          project: { key: projectKey, name: projectName },
          summary,
        },
        key,
      } = issuesById[issueId]
      return {
        text: `${key} ${summary}`,
        project: `${projectKey} ${projectName}`,
        components: components.map(({ name }) => name),
        value: id,
      }
    })
  }
)
