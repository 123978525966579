import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_UPCOMING_INVOICE_STARTED,
  FETCH_UPCOMING_INVOICE_SUCCESS,
} from 'ducks/integrations/stripe/types'

const initialState = {
  bySubscriptionId: {},
}

export const initialSingleState = {
  isInitialized: true,
}

export default createActionTypeReducer(
  {
    [FETCH_UPCOMING_INVOICE_STARTED]: (draftState, action) => {
      const { subscriptionId } = action.payload

      if (!draftState.bySubscriptionId[subscriptionId]) {
        draftState.bySubscriptionId[subscriptionId] = Object.assign(
          {},
          initialSingleState
        )
      }

      return draftState
    },
    [FETCH_UPCOMING_INVOICE_SUCCESS]: (draftState, action) => {
      const { subscriptionId } = action.meta.requestParameters

      Object.assign(draftState.bySubscriptionId[subscriptionId], action.payload)

      return draftState
    },
  },
  initialState
)
