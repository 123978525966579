import { selectRequestByType } from 'ducks/requests/selectors'
import { createSelector } from 'reselect'
import {
  FETCH_SLACK_APP,
  FETCH_SLACK_CHANNELS,
  FETCH_SLACK_PROFILES,
} from './types'

export const selectBase = state => state.integrations.thirdPartyData.slack

export const selectApp = state => selectBase(state).app

export const selectChannelsBySlackId = state =>
  selectBase(state).channelsBySlackId

export const selectProfilesBySlackId = state =>
  selectBase(state).profilesBySlackId

export const selectMatchedProfilesByAgentGid = createSelector(
  selectProfilesBySlackId,
  byId => {
    return Object.values(byId).reduce((memo, profile) => {
      if (profile.agent_gid) {
        // eslint-disable-next-line no-param-reassign
        memo[profile.agent_gid] = profile
      }
      return memo
    }, {})
  }
)

export const selectChannels = createSelector(selectChannelsBySlackId, byId => {
  return Object.values(byId)
})

export const selectProfiles = createSelector(selectProfilesBySlackId, byId => {
  return Object.values(byId)
})

export const selectAppRequestState = state =>
  selectRequestByType(state, FETCH_SLACK_APP)
export const selectChannelsRequestState = state =>
  selectRequestByType(state, FETCH_SLACK_CHANNELS)
export const selectProfilesRequestState = state =>
  selectRequestByType(state, FETCH_SLACK_PROFILES)
