import createCachedSelector from 're-reselect'

import selectGitHubNodesById from './selectGitHubNodesById'

export default createCachedSelector(
  selectGitHubNodesById,
  (state, { repositoryId }) => repositoryId,
  (nodes, repositoryId) => {
    const node = nodes[repositoryId]
    return node && node.isLoadingIssues
  }
)((state, { repositoryId }) => repositoryId)
