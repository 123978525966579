import { UPDATE_APP_DATA } from 'constants/action_types'
import { FETCH_CURRENT_USER_SUCCESS } from 'ducks/currentUser/types'
import { omit } from 'util/objects'
import { SET_FLAG_STARTED, CLEAR_FLAG_STARTED } from './types'

// NOTE (jscheel): Fetching is true by default so that we don't return an
// indefinite falsey value for a flag (resulting in a flash of a notice, etc)
// while the agent flags are still being loading from the api. The flags are
// loaded by bootstrap, but this is a safety function to ensure we don't do
// do anything embarrassing.
const defaultState = {
  fetching: true,
  byName: {},
}

const reducers = {}

// eslint-disable-next-line no-multi-assign
reducers[UPDATE_APP_DATA] = reducers[FETCH_CURRENT_USER_SUCCESS] = (
  state,
  { data: { currentUser } }
) => {
  return {
    ...state,
    fetching: false,
    // NOTE (jscheel): Micro-optimization, but it's faster to use a hash lookup
    // than an array search, and you should not use es6 Set in your redux store.
    byName: (currentUser.flags || []).reduce((obj, val) => {
      obj[val] = true // eslint-disable-line no-param-reassign
      return obj
    }, {}),
  }
}

// NOTE (jscheel): Flags are optimistic, and we shouldn't bother rolling them
// back on a failure. Therefore we simply reduce the flag during the "started" phase.
reducers[SET_FLAG_STARTED] = (state, { payload: { name } }) => {
  return {
    ...state,
    byName: {
      ...state.byName,
      [name]: true,
    },
  }
}

reducers[CLEAR_FLAG_STARTED] = (state, { payload: { name } }) => {
  return {
    ...state,
    byName: omit([name], state.byName),
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
