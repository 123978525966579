import { oauthTokenSelector } from 'selectors/app'
import { FETCH_SUBSCRIPTIONS_REQUEST } from 'ducks/integrations/recharge/types'
import { doSdkRequest } from 'ducks/requests/operations'

import * as api from '../api'

const doFetchSubscriptions = ({ integrationId, customerId }) => {
  return doSdkRequest(
    FETCH_SUBSCRIPTIONS_REQUEST,
    async ({ getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)

      const { response } = await api.getSubscriptions(
        token,
        integrationId,
        customerId
      )
      return { subscriptions: response.subscriptions }
    },
    { integrationId, customerId }
  )
}

export default doFetchSubscriptions
