import {
  USAGE_CHAT_CHANNELS,
  USAGE_LIVE_CHAT_CHANNELS,
  USAGE_SOCIAL_CHAT_CHANNELS,
  USAGE_INBOX_API_ACCESS,
  USAGE_INBOX_BRANDING,
  USAGE_INBOX_CANNED_REPLIES,
  USAGE_INBOX_CHANNELS,
  USAGE_INBOX_CUSTOM_FIELDS,
  USAGE_INBOX_CUSTOM_PROFILE,
  USAGE_INBOX_FOLDERS,
  USAGE_INBOX_INTEGRATIONS,
  USAGE_INBOX_MAILBOXES,
  USAGE_INBOX_PREMIUM_INTEGRATIONS,
  USAGE_INBOX_RULES,
  USAGE_INBOX_RULES_TIER_1,
  USAGE_INBOX_RULES_TIER_2,
  USAGE_INBOX_SATISFACTIONS_RATINGS,
  USAGE_INBOX_TEAMS,
  USAGE_INBOX_USERS,
  USAGE_INBOX_WEBHOOKS,
  USAGE_INBOX_WIDGETS,
  USAGE_KB_ARTICLES,
  USAGE_KB_BRANDING,
  USAGE_KB_CUSTOM_SCRIPTS,
  USAGE_KB_KBS,
  USAGE_KB_PRIVATE,
  USAGE_KB_SEO,
  USAGE_INBOX_VIEWERS,
} from './usageTypes'

export const FEATURE_INBOX_MAX_CHANNELS = 'channels'
export const FEATURE_INBOX_MAX_MAILBOXES = 'mailboxes'
export const FEATURE_INBOX_MAX_CHAT_CHANNELS = 'chat_channels'
export const FEATURE_INBOX_MAX_LIVE_CHAT_CHANNELS = 'live_chat_channels'
export const FEATURE_INBOX_MAX_SOCIAL_CHAT_CHANNELS = 'social_chat_channels'
export const FEATURE_INBOX_MAX_INTEGRATIONS = 'integrations'
export const FEATURE_INBOX_MAX_REPORTING_DAYS = 'reporting_days'
export const FEATURE_INBOX_REPORTS = 'reports'
export const FEATURE_INBOX_REPORTS_TIER_1 = 'reports_tier_1'
export const FEATURE_INBOX_REPORTS_TIER_2 = 'reports_tier_2'
export const FEATURE_INBOX_MAX_CONVERSATIONS = 'conversations'
export const FEATURE_INBOX_MAX_SEARCH_HISTORY_DAYS = 'search_history_days'
export const FEATURE_INBOX_MAX_USERS = 'users'
export const FEATURE_INBOX_MAX_VIEWER_USERS = 'viewer_users'
export const FEATURE_INBOX_TEAMS = 'teams'
export const FEATURE_INBOX_API_ACCESS = 'api_access'
export const FEATURE_INBOX_API_ACCESS_RATE_LIMIT = 'api_access_rate_limit'
export const FEATURE_INBOX_API_ACCESS_TIER_1 = 'api_access_tier_1'
export const FEATURE_INBOX_SATISFACTIONS_RATINGS = 'satisfactions_ratings'
export const FEATURE_INBOX_FOLDERS_CUSTOM = 'folders_custom'
export const FEATURE_INBOX_MAX_FOLDERS = 'folders'
export const FEATURE_INBOX_RULES = 'rules'
export const FEATURE_INBOX_MAX_RULES = 'rules'
export const FEATURE_INBOX_RULES_TIER_1 = 'rules_tier_1'
export const FEATURE_INBOX_RULES_TIER_2 = 'rules_tier_2'
export const FEATURE_INBOX_WORKFLOWS = 'workflows'
export const FEATURE_INBOX_EXPORTS = 'exports'
export const FEATURE_INBOX_WEBHOOKS = 'webhooks'
export const FEATURE_INBOX_CUSTOM_FIELDS = 'custom_fields'
export const FEATURE_INBOX_CRM_FIELDS = 'crm_fields'
export const FEATURE_INBOX_CUSTOM_PROFILE = 'custom_profile'
export const FEATURE_INBOX_MENTIONS = 'mentions'
export const FEATURE_INBOX_COMMENTS = 'comments'
export const FEATURE_INBOX_MAX_CANNED_REPLIES = 'canned_replies'
export const FEATURE_INBOX_CANNED_REPLIES_TIER_1 = 'canned_replies_tier_1'
export const FEATURE_INBOX_MAX_WIDGETS = 'widgets'
export const FEATURE_INBOX_LIVE_CHAT = 'live_chat'
export const FEATURE_INBOX_PREMIUM_INTEGRATIONS = 'premium_integrations'
export const FEATURE_INBOX_SSO_SAML = 'sso_saml'
export const FEATURE_INBOX_2FA = '2fa'
export const FEATURE_INBOX_DEDICATED_SUCCESS = 'dedicated_success'
export const FEATURE_INBOX_PRIORITY_SUPPORT = 'priority_support'
export const FEATURE_INBOX_BRANDING = 'conversation_branding'
export const FEATURE_ROUND_ROBIN = 'round_robin'
export const FEATURE_USER_CUSTOM_PERMISSIONS = 'user_custom_permissions'
export const FEATURE_ENTERPRISE_SECURITY = 'enterprise_security'

// KB
export const FEATURE_KB_MAX_KBS = 'kbs'
export const FEATURE_KB_MAX_ARTICLES = 'articles'
export const FEATURE_KB_BRANDING = 'kb_branding'
export const FEATURE_KB_MAX_REPORTING_DAYS = 'reporting_days'
export const FEATURE_KB_REPORTS = 'reports'
export const FEATURE_KB_CUSTOM_SCRIPTS = 'custom_scripts'
export const FEATURE_KB_PRIVATE = 'private_kbs'
export const FEATURE_KB_SEO = 'seo'

// AI
export const FEATURE_AI_MAX_DRAFT_GENERATIONS = 'ai_draft_generations'
export const FEATURE_AI_TIER_1 = 'ai_tier_1'
export const FEATURE_AI_OVERVIEW_TIER = 'ai_overview_tier'
export const FEATURE_AI_DRAFT_CREDITS = 'ai_draft_credits'

// Integrations
export const FEATURE_INTEGRATION_BATCHBOOK = 'integration_batchbook'
export const FEATURE_INTEGRATION_CAMPAIGN_MONITOR =
  'integration_campaign_monitor'
export const FEATURE_INTEGRATION_CAPSULE = 'integration_capsule'
export const FEATURE_INTEGRATION_CONSTANT_CONTACT =
  'integration_constant_contact'
export const FEATURE_INTEGRATION_E_VOICE = 'integration_e_voice'
export const FEATURE_INTEGRATION_HIP_CHAT = 'integration_hip_chat'
export const FEATURE_INTEGRATION_MAILCHIMP = 'integration_mailchimp'
export const FEATURE_INTEGRATION_NIMBLE = 'integration_nimble'
export const FEATURE_INTEGRATION_OLARK = 'integration_olark'
export const FEATURE_INTEGRATION_SLACK = 'integration_slack'
export const FEATURE_INTEGRATION_SNAP_ENGAGE = 'integration_snap_engage'
export const FEATURE_INTEGRATION_ZAPIER = 'integration_zapier'
export const FEATURE_INTEGRATION_ZOHO = 'integration_zoho'
export const FEATURE_INTEGRATION_ASANA = 'integration_asana'
export const FEATURE_INTEGRATION_GITHUB = 'integration_github'
export const FEATURE_INTEGRATION_HUBSPOT = 'integration_hubspot'
export const FEATURE_INTEGRATION_JIRA = 'integration_jira'
export const FEATURE_INTEGRATION_RECHARGE = 'integration_recharge'
export const FEATURE_INTEGRATION_SALESFORCE = 'integration_salesforce'
export const FEATURE_INTEGRATION_SHOPIFY = 'integration_shopify'
export const FEATURE_INTEGRATION_STRIPE = 'integration_stripe'
export const FEATURE_INTEGRATION_TRELLO = 'integration_trello'

// we have this because the feature names to not match 1:1 with billing usage keys
// if value is null, means it's not usage based
const FEATURE_TO_USAGE_BILLING = new Map([
  [FEATURE_INBOX_MAX_CHANNELS, USAGE_INBOX_CHANNELS],
  [FEATURE_INBOX_MAX_CHAT_CHANNELS, USAGE_CHAT_CHANNELS],
  [FEATURE_INBOX_MAX_LIVE_CHAT_CHANNELS, USAGE_LIVE_CHAT_CHANNELS],
  [FEATURE_INBOX_MAX_SOCIAL_CHAT_CHANNELS, USAGE_SOCIAL_CHAT_CHANNELS],
  [FEATURE_INBOX_MAX_CHAT_CHANNELS, USAGE_CHAT_CHANNELS],
  [FEATURE_INBOX_MAX_MAILBOXES, USAGE_INBOX_MAILBOXES],
  [FEATURE_INBOX_MAX_INTEGRATIONS, USAGE_INBOX_INTEGRATIONS],
  [FEATURE_INBOX_MAX_REPORTING_DAYS, null],
  [FEATURE_INBOX_REPORTS, null],
  [FEATURE_INBOX_MAX_CONVERSATIONS, null],
  [FEATURE_INBOX_MAX_SEARCH_HISTORY_DAYS, null],
  [FEATURE_INBOX_MAX_USERS, USAGE_INBOX_USERS],
  [FEATURE_INBOX_MAX_VIEWER_USERS, USAGE_INBOX_VIEWERS],
  [FEATURE_INBOX_TEAMS, USAGE_INBOX_TEAMS],
  [FEATURE_INBOX_API_ACCESS, USAGE_INBOX_API_ACCESS],
  [FEATURE_INBOX_SATISFACTIONS_RATINGS, USAGE_INBOX_SATISFACTIONS_RATINGS],
  [FEATURE_INBOX_FOLDERS_CUSTOM, null],
  [FEATURE_INBOX_MAX_FOLDERS, USAGE_INBOX_FOLDERS],
  [FEATURE_INBOX_RULES, null],
  [FEATURE_INBOX_MAX_RULES, USAGE_INBOX_RULES],
  [FEATURE_INBOX_RULES_TIER_1, USAGE_INBOX_RULES_TIER_1],
  [FEATURE_INBOX_RULES_TIER_2, USAGE_INBOX_RULES_TIER_2],
  [FEATURE_INBOX_WORKFLOWS, null],
  [FEATURE_INBOX_EXPORTS, null],
  [FEATURE_INBOX_WEBHOOKS, USAGE_INBOX_WEBHOOKS],
  [FEATURE_INBOX_CUSTOM_FIELDS, USAGE_INBOX_CUSTOM_FIELDS],
  [FEATURE_INBOX_CUSTOM_PROFILE, USAGE_INBOX_CUSTOM_PROFILE],
  [FEATURE_INBOX_MENTIONS, null],
  [FEATURE_INBOX_COMMENTS, null],
  [FEATURE_INBOX_MAX_CANNED_REPLIES, USAGE_INBOX_CANNED_REPLIES],
  [FEATURE_INBOX_MAX_WIDGETS, USAGE_INBOX_WIDGETS],
  [FEATURE_INBOX_LIVE_CHAT, null],
  [FEATURE_INBOX_PREMIUM_INTEGRATIONS, USAGE_INBOX_PREMIUM_INTEGRATIONS],
  [FEATURE_INBOX_SSO_SAML, null],
  [FEATURE_INBOX_2FA, null],
  [FEATURE_INBOX_DEDICATED_SUCCESS, null],
  [FEATURE_INBOX_PRIORITY_SUPPORT, null],
  [FEATURE_INBOX_BRANDING, USAGE_INBOX_BRANDING],
  [FEATURE_KB_MAX_KBS, USAGE_KB_KBS],
  [FEATURE_KB_MAX_ARTICLES, USAGE_KB_ARTICLES],
  [FEATURE_KB_BRANDING, USAGE_KB_BRANDING],
  [FEATURE_KB_MAX_REPORTING_DAYS, null],
  [FEATURE_KB_REPORTS, null],
  [FEATURE_KB_CUSTOM_SCRIPTS, USAGE_KB_CUSTOM_SCRIPTS],
  [FEATURE_KB_PRIVATE, USAGE_KB_PRIVATE],
  [FEATURE_KB_SEO, USAGE_KB_SEO],
  // AI features are currently not usage based, but they will be in the future
  [FEATURE_AI_MAX_DRAFT_GENERATIONS, null],
  // Integration feature boolean flags
  [FEATURE_INTEGRATION_E_VOICE, FEATURE_INTEGRATION_E_VOICE],
  [FEATURE_INTEGRATION_HIP_CHAT, FEATURE_INTEGRATION_HIP_CHAT],
  [FEATURE_INTEGRATION_MAILCHIMP, FEATURE_INTEGRATION_MAILCHIMP],
  [FEATURE_INTEGRATION_NIMBLE, FEATURE_INTEGRATION_NIMBLE],
  [FEATURE_INTEGRATION_OLARK, FEATURE_INTEGRATION_OLARK],
  [FEATURE_INTEGRATION_SLACK, FEATURE_INTEGRATION_SLACK],
  [FEATURE_INTEGRATION_SNAP_ENGAGE, FEATURE_INTEGRATION_SNAP_ENGAGE],
  [FEATURE_INTEGRATION_ZAPIER, FEATURE_INTEGRATION_ZAPIER],
  [FEATURE_INTEGRATION_ZOHO, FEATURE_INTEGRATION_ZOHO],
  [FEATURE_INTEGRATION_ASANA, FEATURE_INTEGRATION_ASANA],
  [FEATURE_INTEGRATION_GITHUB, FEATURE_INTEGRATION_GITHUB],
  [FEATURE_INTEGRATION_HUBSPOT, FEATURE_INTEGRATION_HUBSPOT],
  [FEATURE_INTEGRATION_JIRA, FEATURE_INTEGRATION_JIRA],
  [FEATURE_INTEGRATION_RECHARGE, FEATURE_INTEGRATION_RECHARGE],
  [FEATURE_INTEGRATION_SALESFORCE, FEATURE_INTEGRATION_SALESFORCE],
  [FEATURE_INTEGRATION_SHOPIFY, FEATURE_INTEGRATION_SHOPIFY],
  [FEATURE_INTEGRATION_STRIPE, FEATURE_INTEGRATION_STRIPE],
  [FEATURE_INTEGRATION_TRELLO, FEATURE_INTEGRATION_TRELLO],
  [FEATURE_INBOX_REPORTS_TIER_1, null],
  [FEATURE_INBOX_REPORTS_TIER_2, null],
  [FEATURE_AI_TIER_1, null],
  [FEATURE_INBOX_API_ACCESS_RATE_LIMIT, null],
  [FEATURE_INBOX_API_ACCESS_TIER_1, null],
  [FEATURE_USER_CUSTOM_PERMISSIONS, null],
  [FEATURE_ENTERPRISE_SECURITY, null],
  [FEATURE_INBOX_CRM_FIELDS, null],
])

export const billingUsageKeyFeature = feature => {
  return FEATURE_TO_USAGE_BILLING.get(feature)
}

export const NUMBER_FEATURES = [
  FEATURE_INBOX_MAX_INTEGRATIONS,
  FEATURE_INBOX_MAX_USERS,
  FEATURE_INBOX_MAX_CANNED_REPLIES,
  FEATURE_INBOX_MAX_RULES,
  FEATURE_INBOX_MAX_FOLDERS,
  FEATURE_INBOX_MAX_WIDGETS,
  FEATURE_KB_MAX_KBS,
  FEATURE_INBOX_MAX_VIEWER_USERS,
  FEATURE_INBOX_MAX_CHANNELS,
  FEATURE_INBOX_MAX_MAILBOXES,
  FEATURE_INBOX_MAX_CHAT_CHANNELS,
  FEATURE_INBOX_MAX_LIVE_CHAT_CHANNELS,
  FEATURE_INBOX_MAX_SOCIAL_CHAT_CHANNELS,
  FEATURE_INBOX_MAX_INTEGRATIONS,
  FEATURE_INBOX_MAX_REPORTING_DAYS,
  FEATURE_INBOX_MAX_CONVERSATIONS,
  FEATURE_INBOX_MAX_SEARCH_HISTORY_DAYS,
  FEATURE_INBOX_MAX_RULES,
  FEATURE_KB_MAX_ARTICLES,
  FEATURE_KB_MAX_REPORTING_DAYS,
  FEATURE_AI_MAX_DRAFT_GENERATIONS,
  FEATURE_INBOX_API_ACCESS_RATE_LIMIT,
]

export const BOOLEAN_FEATURES = [
  FEATURE_INBOX_PREMIUM_INTEGRATIONS,
  FEATURE_INBOX_TEAMS,
  FEATURE_INBOX_CANNED_REPLIES_TIER_1,
  FEATURE_INBOX_RULES_TIER_1,
  FEATURE_INBOX_RULES_TIER_2,
  FEATURE_INBOX_CUSTOM_FIELDS,
  FEATURE_INBOX_SATISFACTIONS_RATINGS,
  FEATURE_INBOX_API_ACCESS,
  FEATURE_INBOX_CUSTOM_PROFILE,
  FEATURE_INBOX_BRANDING,
  FEATURE_KB_CUSTOM_SCRIPTS,
  FEATURE_KB_SEO,
  FEATURE_KB_PRIVATE,
  FEATURE_KB_BRANDING,
  FEATURE_ROUND_ROBIN,
  FEATURE_INBOX_REPORTS,
  FEATURE_INBOX_FOLDERS_CUSTOM,
  FEATURE_INBOX_RULES,
  FEATURE_INBOX_WORKFLOWS,
  FEATURE_INBOX_EXPORTS,
  FEATURE_INBOX_WEBHOOKS,
  FEATURE_INBOX_MENTIONS,
  FEATURE_INBOX_COMMENTS,
  FEATURE_INBOX_LIVE_CHAT,
  FEATURE_INBOX_SSO_SAML,
  FEATURE_INBOX_2FA,
  FEATURE_INBOX_DEDICATED_SUCCESS,
  FEATURE_INBOX_PRIORITY_SUPPORT,
  FEATURE_KB_REPORTS,
  FEATURE_INTEGRATION_E_VOICE,
  FEATURE_INTEGRATION_HIP_CHAT,
  FEATURE_INTEGRATION_MAILCHIMP,
  FEATURE_INTEGRATION_NIMBLE,
  FEATURE_INTEGRATION_OLARK,
  FEATURE_INTEGRATION_SLACK,
  FEATURE_INTEGRATION_SNAP_ENGAGE,
  FEATURE_INTEGRATION_ZAPIER,
  FEATURE_INTEGRATION_ZOHO,
  FEATURE_INTEGRATION_ASANA,
  FEATURE_INTEGRATION_GITHUB,
  FEATURE_INTEGRATION_HUBSPOT,
  FEATURE_INTEGRATION_JIRA,
  FEATURE_INTEGRATION_RECHARGE,
  FEATURE_INTEGRATION_SALESFORCE,
  FEATURE_INTEGRATION_SHOPIFY,
  FEATURE_INTEGRATION_STRIPE,
  FEATURE_INTEGRATION_TRELLO,
  FEATURE_INBOX_REPORTS_TIER_1,
  FEATURE_INBOX_REPORTS_TIER_2,
  FEATURE_AI_TIER_1,
  FEATURE_INBOX_API_ACCESS_TIER_1,
  FEATURE_USER_CUSTOM_PERMISSIONS,
  FEATURE_ENTERPRISE_SECURITY,
  FEATURE_INBOX_CRM_FIELDS,
]
