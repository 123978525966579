import { createActionTypeReducer } from 'util/reducers'

import {
  FETCH_IMPORTS_SUCCESS,
  FETCH_IMPORTS_START,
  FETCH_TOOLS_SUCCESS,
  FETCH_TOOLS_START,
} from 'ducks/imports/types'

const defaultState = {
  imports: [],
  tools: [],
  loading: false,
}

export default createActionTypeReducer(
  {
    [FETCH_IMPORTS_START]: draftState => {
      draftState.loading = true
      return draftState
    },
    [FETCH_TOOLS_START]: draftState => {
      draftState.loading = true
      return draftState
    },
    [FETCH_IMPORTS_SUCCESS]: (draftState, { payload }) => {
      const response = payload
      draftState.imports = response.import2Imports.nodes
      draftState.loading = false
      return draftState
    },
    [FETCH_TOOLS_SUCCESS]: (draftState, { payload }) => {
      const response = payload
      draftState.tools = response.import2Tools.nodes
      draftState.loading = false
      return draftState
    },
  },
  defaultState
)
