import { FEATURE_INTEGRATION_ZAPIER } from 'ducks/billing/featureTypes'
import { selectUsageForFeature } from 'ducks/billing/selectors/usage/selectUsageForFeature'
import { createBasicSelector } from 'util/redux'
import { selectRequestByType } from 'ducks/requests/selectors'
import { ZAPIER_REMOVE_ACCESS_TOKENS } from './types'

export const selectIsZapierInstalled = createBasicSelector(state =>
  selectUsageForFeature(state, FEATURE_INTEGRATION_ZAPIER)
)

export const selectZapierRemoveAccessTokenRequest = createBasicSelector(state =>
  selectRequestByType(state, ZAPIER_REMOVE_ACCESS_TOKENS)
)
