import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'

import selectIntegrationSettingsByProvider from './selectIntegrationSettingsByProvider'

export default createCachedSelector(
  selectIntegrationSettingsByProvider,
  (_, { provider }) => provider,
  (byProvider, provider) => {
    const settings = byProvider[provider] || byProvider[provider.toUpperCase()]
    if (!settings) return emptyObj

    return settings
  }
)((state, { provider }) => provider)
