/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'

import { SET_GROUPS_BYID, OPEN_GROUP, CLOSE_GROUP } from '../actionTypes/groups'

const groupsByIdInitialState = {
  fg1: {
    id: 'fg1',
    name: 'Team',
    icon: 'usersAlt',
    position: 9,
    collectionIds: ['fc1'],
  },
  fg2: {
    id: 'fg2',
    name: 'Tags',
    icon: 'label',
    position: 10,
    collectionIds: ['fc1'],
  },
}

export const groupsById = createActionTypeReducer(
  {
    [SET_GROUPS_BYID]: (_, { payload: { groups = [] } }) => {
      return groups.reduce((groupById, group) => {
        groupById[group.id] = group
        return groupById
      }, {})
    },
  },
  groupsByIdInitialState
)

const openGroupIdsInitialState = []

export const openGroupIds = createActionTypeReducer(
  {
    [OPEN_GROUP]: (state, { payload: { groupId } }) => {
      if (!state.includes(groupId)) {
        state.push(groupId)
      }
      return state
    },
    [CLOSE_GROUP]: (state, { payload: { groupId } }) => {
      if (state.includes(groupId)) {
        state.splice(state.indexOf(groupId), 1)
      }
      return state
    },
  },
  openGroupIdsInitialState
)
