import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'

function selectBase(state) {
  return state.events || emptyObj
}

export const selectIsEventsLoading = createSelector(
  selectBase,
  base => !!base.isLoading
)

export const selectIsEventsLoaded = createSelector(
  selectBase,
  base => !!base.isLoaded
)

export const selectEvents = createSelector(
  selectBase,
  base => base.data || emptyArr
)

export const selectVisibleEvents = createSelector(selectEvents, events =>
  events.filter(evt => evt.isVisible)
)
