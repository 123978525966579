import { createSelector } from 'reselect'

import selectCurrentTicketLinkedIntegrationsResources from 'ducks/integrations/selectors/selectCurrentTicketLinkedIntegrationsResources'
import { uniqBy } from 'util/arrays'

export default createSelector(
  selectCurrentTicketLinkedIntegrationsResources,
  linkedResources => {
    if (!linkedResources) return linkedResources
    const r = uniqBy(
      [...linkedResources].reverse(),
      ({ externalId }) => externalId
    )
      .reverse()
      .filter(resource => {
        if (!resource) return false
        const { provider, removed } = resource
        return provider === 'github' && removed !== true
      })
    return r
  }
)
