import createCachedSelector from 're-reselect'

import { emptyObj } from 'util/objects'

export function selectBase(state) {
  return state?.integrations?.thirdPartyData?.customProfile || emptyObj
}
export const selectCustomProfileContentByContactId = createCachedSelector(
  selectBase,
  (_state, contactId) => contactId,
  (base, contactId) => base[contactId]
)((_state, contactId) => contactId || 'unknown')
