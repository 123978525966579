import { createSelector } from 'reselect'

export function selectBase(state) {
  return state.crm.excludedDomains
}

export function selectAllExcludedDomainsIsLoading(state) {
  return selectBase(state).isLoading
}

export function selectExcludedDomainsHasErrored(state) {
  return selectBase(state).hasErrored
}

export function selectExcludedDomainsById(state) {
  return selectBase(state).byId
}
export const selectExcludedDomains = createSelector(
  selectExcludedDomainsById,
  byId => Object.values(byId)
)
