import {
  FETCH_ISSUE_SEARCH_SUCCESS,
  FETCH_ISSUES_REQUEST,
  FETCH_ISSUES_SUCCESS,
  FETCH_ISSUES_ERROR,
  FETCH_ISSUE_SEARCH_ERROR,
} from '../types'

const initialState = { byId: {}, errored: false, loading: false }

export default function makeIssues(prefix) {
  return function reduceIssues(
    state = initialState,
    {
      type,
      meta: { ids, siteId, siteName } = {},
      payload: { issues, errorMessages = [] } = {},
    }
  ) {
    if (type === `${prefix}/${FETCH_ISSUES_REQUEST}`) {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...ids.reduce((result, issueId) => {
            // eslint-disable-next-line no-param-reassign
            result[issueId] = {
              ...state.byId[issueId],
              id: issueId,
              loading: true,
              errored: false,
            }
            return result
          }, {}),
        },
      }
    }
    if (
      [
        `${prefix}/${FETCH_ISSUES_SUCCESS}`,
        `${prefix}/${FETCH_ISSUE_SEARCH_SUCCESS}`,
      ].includes(type)
    ) {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...issues.reduce((result, issue) => {
            // eslint-disable-next-line no-param-reassign
            result[issue.id] = {
              ...issue,
              errored: false,
              loaded: true,
              site: {
                id: siteId,
                name: siteName,
                url: `https://${siteName}.atlassian.net`,
              },
            }
            return result
          }, {}),
        },
      }
    }

    if (
      [
        `${prefix}/${FETCH_ISSUES_ERROR}`,
        `${prefix}/${FETCH_ISSUE_SEARCH_ERROR}`,
      ].includes(type)
    ) {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...ids.reduce((result, issueId) => {
            // eslint-disable-next-line no-param-reassign
            result[issueId] = {
              ...state.byId[issueId],
              id: issueId,
              loading: false,
              errored: true,
              loaded: true,
              errors: errorMessages,
            }
            return result
          }, {}),
        },
      }
    }

    return state
  }
}
