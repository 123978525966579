import { sleep, asyncForEach } from 'util/functions'

export function getMatrixClient() {
  // Kevin (2019-12-20)
  // Matrix react SDK does not work server side at all. Adding the require here
  // ensures that its evaluated during runtime and not compile time.
  // We know for a fact that no code using this is running server side because
  // we're dyncamically loading in the chat components via nextjs
  // eslint-disable-next-line global-require
  return require('matrix-react-sdk/lib/MatrixClientPeg').get()
}

export function getSettingStore() {
  // eslint-disable-next-line global-require
  return require('matrix-react-sdk/lib/settings/SettingsStore')
}

export function getLanguageHandler() {
  // eslint-disable-next-line global-require
  return require('matrix-react-sdk/lib/languageHandler')
}

export async function fireEvent(
  matrixRoomId,
  { type, content, stateKey },
  delay = 100
) {
  const client = getMatrixClient()
  const startTime = new Date()

  // This method returns a response that looks like this
  // { event_id: "$EZZRF0oFhE668a2EJ-cuQmYlft9pbT6vqVymnLcpMAA" }
  const { event_id: eventId } = await client.sendStateEvent(
    matrixRoomId,
    type,
    content,
    stateKey
  )
  const endTime = new Date()

  const duration = endTime.getTime() - startTime.getTime()
  if (duration < delay) {
    const sleepDelay = delay - duration
    await sleep(sleepDelay)
  }
  return eventId
}

export async function fireEvents(matrixRoomId, events, delay = 100) {
  await asyncForEach(events, async event =>
    fireEvent(matrixRoomId, event, delay)
  )
}

export async function watchSetting(setting, callback) {
  getSettingStore().watchSetting(
    setting,
    null,
    (
      _originalSettingName,
      _changedInRoomId,
      _atLevel,
      newValAtLevel,
      newValue
    ) => {
      callback(newValAtLevel || newValue)
    }
  )
}
