/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { buildId } from 'util/globalId'
import {
  queryIdToQuery,
  constructFolderItemQueryId,
  queryStringToQueryId,
} from 'ducks/searches/utils/query'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'
import { BUILD_FROM_WIDGETS } from '../actionTypes/collections'
import { SET_ITEMS_BYID } from '../actionTypes/items'
import { mapWidgetChannelTypeToPageType } from '../utils'

const itemsByIdInitialState = {}
const folderKindsWithUnreadIndicator = ['unassigned', 'mine']

const updateOrderBy = (draftState, action) => {
  const {
    meta: {
      location: {
        current: { query: { orderBy } = {} } = {},
        prev: { query: { orderBy: previousOrderBy } = {} } = {},
      } = {},
    } = {},
  } =
    action || {}

  if (!orderBy || orderBy === previousOrderBy) return draftState

  Object.keys(draftState).forEach(id => {
    if (draftState[id]?.linkTo?.meta?.query?.orderBy) {
      draftState[id].linkTo.meta.query.orderBy = orderBy

      draftState[id].queryId = queryStringToQueryId(
        draftState[id].linkTo.meta.query
      )
    }
  })

  return draftState
}

export const itemsById = createActionTypeReducer(
  {
    [SET_ITEMS_BYID]: (_, { payload: { items = [] } }) => {
      return items.reduce((itemById, item) => {
        itemById[item.id] = item
        return itemById
      }, {})
    },
    [BUILD_FROM_WIDGETS]: (
      draftState,
      {
        payload: {
          widgets = [],
          folders = [],
          prefersAllMailboxesSectionVisible = false,
          prefersUnifiedInbox = false,
          channelType: pageChannelType,
          orderBy,
        },
      }
    ) => {
      const validItems = []
      const hasAccessToAtleastOneWidget = widgets.some(w => w.hasAccess)

      const allowedWidgets = prefersUnifiedInbox
        ? []
        : [...widgets.filter(w => w.hasAccess)]
      const allowedWidgetsByPageType = allowedWidgets.filter(
        w => w.channelType === pageChannelType
      )

      if (
        hasAccessToAtleastOneWidget &&
        (prefersUnifiedInbox ||
          (prefersAllMailboxesSectionVisible &&
            allowedWidgetsByPageType.length > 1))
      ) {
        allowedWidgets.unshift({
          id: '',
          name: pageChannelType === 'widget' ? 'All widgets' : 'All social',
          hasAccess: true,
          channelType: pageChannelType,
        })
      }

      allowedWidgets.forEach(widget => {
        const { id: rawWidgetId, channelType } = widget
        const widgetId = buildId('Widget', rawWidgetId || pageChannelType)
        folders.forEach(folder => {
          const {
            id: folderId,
            position,
            displayCountWhenInactive,
            hideIfZeroConversations,
            name,
          } = folder
          const itemId = `${widgetId}_${folderId}`
          validItems.push(itemId)
          const itemQueryId = constructFolderItemQueryId({
            widget,
            folder,
            orderBy,
          })
          const finalChannelType = channelType || pageChannelType
          if (
            !draftState[itemId] &&
            // FIXME: Until we have chat folder permissions, hide facebook specific folders
            // for non-facebook widgets
            (!['New messages', 'Ending soon'].includes(name) ||
              isBridgeChannelType(finalChannelType))
          ) {
            draftState[itemId] = {
              id: itemId,
              name,
              groupId: null,
              collectionId: widgetId,
              position,
              displayCountWhenInactive,
              displayUnreadIndicator: folderKindsWithUnreadIndicator.includes(
                folder.kind
              ),
              hideIfZeroConversations,
              queryId: itemQueryId,
              linkTo: {
                type: mapWidgetChannelTypeToPageType(channelType),
                payload: {},
                meta: {
                  query: queryIdToQuery(itemQueryId),
                },
              },
            }
            draftState[itemId].position = position
          }
        })
      })
      Object.keys(draftState).forEach(itemId => {
        if (!validItems.includes(itemId)) {
          delete draftState[itemId]
        }
      })
      return draftState
    },
    [CHAT_PAGE]: updateOrderBy,
    [SOCIAL_PAGE]: updateOrderBy,
  },
  itemsByIdInitialState
)
