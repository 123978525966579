import { doGraphqlRequest } from 'ducks/requests/operations'
import doCreateShopifyEvent from './doCreateShopifyEvent'
import graphql from '../api'
import * as types from '../types'

const mutation = `
  mutation DraftOrderInvoiceSend($id: ID!, $email: EmailInput) {
    draftOrderInvoiceSend(id: $id, email: $email) {
      draftOrder {
        id
      }
    }
  }
`

const doSendInvoice = (
  integrationId,
  draftOrderId,
  conversationId,
  emailData
) => async dispatch => {
  const { from, to, bcc, customMessage, subject } = emailData

  const variables = {
    id: draftOrderId,
    email: {
      from,
      to,
      bcc,
      customMessage,
      subject,
    },
  }

  const result = await dispatch(
    doGraphqlRequest(types.INVOICE_SEND_REQUEST, mutation, variables, {
      graphqlFunc: graphql(integrationId),
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: 'Invoice sent',
          },
          failed: {
            enabled: true,
            content: 'Invoice sending failed',
          },
        },
      },
    })
  )

  if (draftOrderId && conversationId) {
    dispatch(
      doCreateShopifyEvent(
        integrationId,
        draftOrderId,
        conversationId,
        'DRAFT_ORDER_INVOICE_SENT'
      )
    )
  }

  return result
}

export default doSendInvoice
