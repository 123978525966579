export const isOrderFulfilled = order => {
  const { displayFulfillmentStatus } = order || {}
  return displayFulfillmentStatus === 'FULFILLED'
}

export const shopifyGidToId = gid => {
  const lastIndex = gid.lastIndexOf('/')
  return gid.substr(lastIndex + 1)
}

export const shopifyStoreAdminUrl = storeName =>
  `https://admin.shopify.com/store/${storeName}`
