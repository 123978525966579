import { doGraphqlRequest } from 'ducks/requests/operations'
import { REMOVE_INTEGRATION } from '../types'
import { removeIntegrationMutation } from './removeIntegrationMutation'

export const doRemoveIntegration = (id, options = {}) => dispatch => {
  const variables = { integrationId: id }
  dispatch(
    doGraphqlRequest(REMOVE_INTEGRATION, removeIntegrationMutation, variables, {
      app: true,
      meta: { integrationId: id },
      transformResponse: data => data.deleteIntegration,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: ['integration'],
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: true,
            content: 'Integration deleted',
          },
          success: {
            enabled: false,
          },
          failed: {
            content: 'Integration deletion failed',
            onClickAction: () => {
              dispatch(doRemoveIntegration(id, options))
            },
          },
        },
        entities: {
          targetOperation: 'remove',
          additionalActions: [
            {
              entityType: 'integration',
              entityId: id,
              stores: ['pending', 'current'],
              operation: 'remove',
              phases: ['STARTED'],
            },
          ],
        },
      },
      ...options,
    })
  )
}
