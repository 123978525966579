import { emptyObj } from 'util/objects'

import { emptyArr } from 'util/arrays'
import { CONVERSATION, ROOM, FETCH_RECENT_CONVERSATIONS_SUCCESS } from './types'

const defaultState = emptyObj
export default function(
  state = defaultState,
  {
    type,
    meta: { contactId } = {},
    payload: {
      rooms: { nodes: rooms = emptyArr } = {},
      conversations: { nodes: conversations = emptyArr } = {},
    } = {},
  }
) {
  if (FETCH_RECENT_CONVERSATIONS_SUCCESS === type) {
    return {
      ...state,
      [contactId]: [
        ...(rooms?.map(room => ({
          ...room,
          type: ROOM,
          assignee: { id: room.agentId },
          updatedAt: new Date(
            parseInt(room.updatedTimestamp, 10)
          ).toISOString(),
        })) || []),
        ...(conversations?.map(conversation => ({
          ...conversation,
          type: CONVERSATION,
        })) || []),
      ]
        .sort(({ updatedAt: a, updatedAt: b }) => {
          if (a > b) return 1
          if (a < b) return -1
          return 0
        })
        .slice(0, 10),
    }
  }
  return state
}
