import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const dropdownContainer = theme => css`
  &&& {
    display: flex;
    flex-direction: column;
    color: ${theme.color.info['900']};
    font-size: ${theme.fontSize.large};
    overflow: unset;
    height: ${rem(480)};
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: unset;
  }
`

const overlay = css`
  margin: 0 ${rem(-10)};
  & > * {
    margin: 0 ${rem(10)};
  }
`

const search = theme => css`
  font-size: inherit;
  && input {
    color: ${theme.color.info['900']};
    font-size: inherit;
    &::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
`

const iconStyle = theme => css`
  font-size: ${theme.fontSize.x3large};
  margin-top: 1px;
`

const buttonStyle = theme => css`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
`

export const styles = {
  dropdownContainer,
  overlay,
  search,
  iconStyle,
  buttonStyle,
}
