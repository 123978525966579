import { FETCH_COMPANIES_SUCCESS } from '../types'

export default function reduceLatestLoadedRequestKey(
  state = null,
  { type, meta: { requestParameters: { filter } = {} } = {} }
) {
  if (type === FETCH_COMPANIES_SUCCESS) {
    const { search } = filter || {}
    return search || null
  }
  return state
}
