import createCachedSelector from 're-reselect'

import selectGitHubRepositories from './selectGitHubRepositories'

export default createCachedSelector(
  selectGitHubRepositories,
  (state, { ownerId }) => ownerId,
  (repositories, ownerId) => {
    return repositories
      .filter(({ owner: { id } }) => id === ownerId)
      .map(({ id, name }) => ({ value: id, text: name }))
  }
)((state, { ownerId }) => ownerId)
