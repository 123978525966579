import {
  customFieldValueNodeQuery,
  doCreateCustomFieldValuesSubjectFromForm,
} from 'ducks/crm/customFields/operations'
import { CREATE_COMPANY } from '../types'
import { createCompanySchema } from '../schema'

const createCompanyQuery = `
mutation CreateCompanyQuery(
  $input: CompanyCreateInput!,
  $customFieldIds: [ID!]
) {
  companyCreate(input: $input) {
    company {
      id
      customFieldValues(filter: { customFieldIds: $customFieldIds }) {
        ${customFieldValueNodeQuery}
      }
    }
    errors {
      message
      path
      type
    }
  }
}
`

export function doCreateCompany(formData) {
  return async dispatch => {
    const result = await dispatch(
      doCreateCustomFieldValuesSubjectFromForm(
        CREATE_COMPANY,
        createCompanyQuery,
        formData,
        createCompanySchema
      )
    )
    return result?.companyCreate?.company?.id
  }
}
