import { oauthTokenSelector } from 'selectors/app'

import * as trello from '../api'
import {
  UPDATE_CARD_REQUEST,
  UPDATE_CARD_SUCCESS,
  UPDATE_CARD_FAILURE,
} from '../types'

export default function doUpdateTrelloCard(id, params) {
  return (dispatch, getState) => {
    const state = getState()

    dispatch({
      type: UPDATE_CARD_REQUEST,
      payload: { cards: [{ id, ...params }] },
    })
    const token = oauthTokenSelector(state)
    return trello
      .updateCard(token, id, params)
      .then(card =>
        dispatch({
          type: UPDATE_CARD_SUCCESS,
          payload: {
            cards: [card],
          },
        })
      )
      .catch(error =>
        dispatch({
          id,
          error: true,
          payload: error,
          type: UPDATE_CARD_FAILURE,
        })
      )
  }
}
