import { createSelector } from 'reselect'
import { isBoostrappedSelector } from 'selectors/app'
import { HIDE_INSTALL_APP_MENU } from 'ducks/flags/flagNames'
import { selectFlag } from 'ducks/flags/selectors'
import { selectInstalledMobileApp } from './selectInstalledMobileApp'

export const selectShowMobileAppsMenu = createSelector(
  isBoostrappedSelector,
  selectInstalledMobileApp,
  state => selectFlag(state)(HIDE_INSTALL_APP_MENU),
  (isBootstrapped, installedMobileApp, hideInstallAppMenu) => {
    // Falsy value for installedMobileApp may mean the data isn't fetched, so need to make sure is bootstrapped
    return isBootstrapped
      ? hideInstallAppMenu === false && !installedMobileApp
      : false
  }
)
