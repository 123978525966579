import { groupBy } from 'util/arrays'

import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import doFetchGitHubResourceFromActions from '../github/operations/doFetchGitHubResourceFromActions'
import doFetchJiraCloudResourcesFromActions from '../jiraCloud/operations/doFetchJiraCloudResourcesFromActions'
import doFetchJiraServerResourcesFromActions from '../jiraServer/operations/doFetchJiraServerResourcesFromActions'
import doFetchTrelloResourceFromActions from '../trello/operations/doFetchTrelloResourceFromActions'

function isIntegrationExternalChange({ change_type: changeType }) {
  return changeType === 'IntegrationExternal'
}

function selectActionsById(state) {
  return state.tickets.changesetsById || {}
}

const PROVIDER_ACTIONS = {
  github: doFetchGitHubResourceFromActions,
  jira_cloud: doFetchJiraCloudResourcesFromActions,
  jira_server: doFetchJiraServerResourcesFromActions,
  trello: doFetchTrelloResourceFromActions,
}

export default function doFetchExternalResourcesForNewActions(
  ticketId,
  actions
) {
  return (dispatch, getState) => {
    const state = getState()
    const byId = selectTicketsById(state)
    const ticket = byId[ticketId]
    if (!ticket) return
    const actionsById = selectActionsById(state)
    const found = actionsById[ticket]
    if (!found) return
    const existingActions = found.actions
    const existingIntegrationActions =
      existingActions && existingActions.filter(isIntegrationExternalChange)
    const existingIntegrationActionIds =
      existingIntegrationActions &&
      existingIntegrationActions.map(({ changeset }) => changeset)
    const incomingIntegrationActions =
      actions && actions.filter(isIntegrationExternalChange)
    const newIntegrationActions =
      incomingIntegrationActions &&
      existingIntegrationActionIds &&
      incomingIntegrationActions.filter(
        ({ changeset }) => !existingIntegrationActionIds.includes(changeset)
      )

    if (newIntegrationActions) {
      const newIntegrationActionsByProvider = groupBy(
        newIntegrationActions,
        ({ change: { provider } = {} }) => provider
      )
      Object.keys(newIntegrationActionsByProvider).forEach(provider => {
        const action = PROVIDER_ACTIONS[provider]
        if (action) dispatch(action(newIntegrationActionsByProvider[provider]))
      })
    }
  }
}
