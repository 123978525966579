import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from 'redux-first-router'
import { SETTINGS_INTEGRATIONS_PAGE } from 'subapps/settings/types'
import { selectCurrentPage } from 'selectors/location'
import { FEATURE_INBOX_MAX_INTEGRATIONS } from 'ducks/billing/featureTypes'
import { useFeature } from 'ducks/billing/hooks'

export const usePlanLimitationForDrawer = onClose => {
  const dispatch = useDispatch()
  const { canUseFeature } = useFeature(FEATURE_INBOX_MAX_INTEGRATIONS)

  const currentPage = useSelector(selectCurrentPage)
  const drawerClosed = useRef()
  useEffect(
    () => {
      if (drawerClosed.current || canUseFeature) return
      // Only redirect or close once
      drawerClosed.current = true
      if (currentPage !== SETTINGS_INTEGRATIONS_PAGE) {
        dispatch(
          redirect({
            type: SETTINGS_INTEGRATIONS_PAGE,
          })
        )
      } else if (onClose) {
        onClose()
      }
    },
    [onClose, canUseFeature, dispatch, currentPage]
  )
  return canUseFeature
}
