/* eslint-disable no-param-reassign */
import { createEntityReducer } from 'util/reducers'

import { DELETE_CUSTOM_FIELD_CATEGORY_SUCCESS } from 'ducks/crm/customFieldCategories/types'

import {
  RESTORE_CUSTOM_FIELD_SUCCESS,
  TRASH_CUSTOM_FIELD_SUCCESS,
  DROPDOWN,
} from '../types'

export default createEntityReducer('customFields', {
  transformEntity: customField => {
    const { options, ...newCustomField } = customField
    if (customField.type === DROPDOWN && options?.nodes) {
      newCustomField.options = options.nodes.map(({ label, value }) => {
        return { text: label, value }
      }, {})
    }
    return newCustomField
  },
  reduce: (
    draftState,
    {
      type,
      meta: {
        categoryId,
        newCategoryId,
        requestParameters: { mode } = {},
      } = {},
      payload: {
        customFieldDelete: { deletedCustomFieldId } = {},
        customFieldRestore: {
          customField: { id: restoredCustomFieldId } = {},
        } = {},
      } = {},
    }
  ) => {
    if (type === TRASH_CUSTOM_FIELD_SUCCESS) {
      if (mode === 'HARD') {
        delete draftState[deletedCustomFieldId]
      } else {
        draftState[deletedCustomFieldId].deletedAt = true
      }
    } else if (type === DELETE_CUSTOM_FIELD_CATEGORY_SUCCESS) {
      Object.values(draftState).forEach(field => {
        if (field.category === categoryId) {
          field.category = newCategoryId
        }
      })
    } else if (type === RESTORE_CUSTOM_FIELD_SUCCESS) {
      draftState[restoredCustomFieldId].deletedAt = false
    }
  },
})
