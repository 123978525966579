import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_ORDERS_REQUEST_STARTED,
  FETCH_ORDERS_REQUEST_SUCCESS,
  FETCH_ORDERS_REQUEST_FAILED,
} from 'ducks/integrations/recharge/types'

const initialState = {
  bySubscriptionId: {},
}

export const initialSingleState = {
  orders: [],
  isLoading: false,
  isError: false,
  error: null,
}

export default createActionTypeReducer(
  {
    [FETCH_ORDERS_REQUEST_STARTED]: (draftState, action) => {
      const { subscriptionId } = action.payload

      if (!draftState.bySubscriptionId[subscriptionId]) {
        draftState.bySubscriptionId[subscriptionId] = Object.assign(
          {},
          initialSingleState
        )
      }

      draftState.bySubscriptionId[subscriptionId].isLoading = true

      return draftState
    },
    [FETCH_ORDERS_REQUEST_SUCCESS]: (draftState, action) => {
      const { subscriptionId } = action.meta.requestParameters
      const { orders } = action.payload

      Object.assign(draftState.bySubscriptionId[subscriptionId], {
        isLoading: false,
        isError: false,
        error: null,
        orders,
      })

      return draftState
    },
    [FETCH_ORDERS_REQUEST_FAILED]: (draftState, action) => {
      const { subscriptionId } = action.meta.requestParameters
      const { error } = action.payload

      Object.assign(draftState.bySubscriptionId[subscriptionId], {
        isLoading: false,
        isError: true,
        error,
      })

      return draftState
    },
  },
  initialState
)
