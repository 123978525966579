import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { CHECK_EXISTING_INTEGRATION } from '../types'

const query = `
  query CheckExistingIntegrationByShopDomainQuery($provider: String!, $shopDomain: String!) {
    existsForAnyAccount(provider: $provider, shopDomain: $shopDomain)
  }
`

const doCheckExistingIntegration = (provider, shopDomain) => {
  const variables = { provider, shopDomain }
  return doAppGraphqlRequest(CHECK_EXISTING_INTEGRATION, query, variables)
}

export default doCheckExistingIntegration
