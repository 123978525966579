import { doUpsertReplyDraft } from './doUpsertReplyDraft'

export function doSwitchToForward(ticketId, draftType, draftId) {
  return doUpsertReplyDraft(draftId, draftType, ticketId, null, {
    isForwarding: true,
    to: null,
    cc: null,
    bcc: null,
  })
}
