import { doLinkResource } from 'actions/userIntegrations'

export default function doUnlinkGitHubIssue(issueId, title, url) {
  return doLinkResource({
    id: issueId,
    provider: 'github',
    removed: true,
    title,
    url,
  })
}
