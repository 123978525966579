import { without } from 'util/arrays'

export const findAddedReactionAction = (
  computedReactions,
  commentId,
  actorId,
  reaction
) => {
  const reactionsForComment = computedReactions[commentId]

  if (!reactionsForComment) {
    return null
  }

  const reactionActions = reactionsForComment[reaction]

  if (!reactionActions) {
    return null
  }

  return reactionActions.find(a => a.change.is_added && a.actor.id === actorId)
}

export const OWN_REACTION_PRONOUN = 'You'

export const isOwnReaction = (reactions, currentUserId) => {
  return !!reactions.find(action => action.actor.id === currentUserId)
}

export const getUserList = (reactions, currentUserId) => {
  const userNameList = reactions.map(action => {
    if (action.actor.id === currentUserId) {
      return OWN_REACTION_PRONOUN
    }

    return action.actor.name
  })

  if (userNameList.indexOf(OWN_REACTION_PRONOUN) > -1) {
    without(userNameList, OWN_REACTION_PRONOUN)
    userNameList.unshift(OWN_REACTION_PRONOUN)
  }

  return userNameList.join(', ')
}
