import { schema } from 'normalizr'

export const customFieldCategoryNormalizationSchema = new schema.Entity(
  'customFieldCategories'
)

export const customFieldCategoriesNormalizationSchema = {
  customFieldCategories: {
    nodes: [customFieldCategoryNormalizationSchema],
  },
}
