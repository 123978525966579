import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectEventsMetaForCustomerId } from '../selectors'
import graphql from '../api'
import * as types from '../types'

const fetchCustomerEventsQuery = `
  query ShopifyCustomerEventsQuery(
    $customerId: ID!,
    $limit: Int!
  ) {
    customer(id: $customerId) {
      id
      events(first: $limit, reverse: true) {
        edges {
          node {
            id
            createdAt
            attributeToApp
            attributeToUser
            appTitle
            message
            __typename
            ... on CommentEvent {
              rawMessage
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`

export default function doFetchEvents(
  integrationId,
  customerGid,
  { limit, skipLoaded = true } = {}
) {
  return (dispatch, getState) => {
    const state = getState()

    const metaForCustomerId = selectEventsMetaForCustomerId(state, customerGid)
    if (skipLoaded && metaForCustomerId) {
      return Promise.resolve()
    }

    const variables = { customerId: customerGid, limit }
    return dispatch(
      doGraphqlRequest(
        types.FETCH_CUSTOMER_EVENTS_REQUEST,
        fetchCustomerEventsQuery,
        variables,
        {
          graphqlFunc: graphql(integrationId),
          meta: {
            integrationId,
          },
        }
      )
    )
  }
}
