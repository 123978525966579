import { customerRatingSetting } from 'ducks/entities/schema'
import { schema } from 'normalizr'

export const customerRatingSettingsNormalizationSchema = {
  customerRatingSettings: {
    nodes: [customerRatingSetting],
  },
}

export const customerRatingSettingNormalizationSchema = {
  customerRatingSettingUpsert: {
    customerRatingSetting,
  },
}

export const customerRatingSettingDeleteNormalizationSchema = new schema.Entity(
  'customerRatingSetting',
  {},
  {
    processStrategy: value => {
      return {
        id: value.deletedCustomerRatingSettingId,
      }
    },
  }
)
