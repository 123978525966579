export const FETCH_RULE_FEATURE_TEMPLATE_CAEGORIES =
  'featureTemplates/FETCH_RULE_FEATURE_TEMPLATE_CAEGORIES'
export const FETCH_RULE_FEATURE_TEMPLATE_CAEGORIES_STARTED =
  'featureTemplates/FETCH_RULE_FEATURE_TEMPLATE_CAEGORIES_STARTED'
export const FETCH_RULE_FEATURE_TEMPLATE_CAEGORIES_SUCCESS =
  'featureTemplates/FETCH_RULE_FEATURE_TEMPLATE_CAEGORIES_SUCCESS'
export const FETCH_RULE_FEATURE_TEMPLATE_CAEGORIES_FAILED =
  'featureTemplates/FETCH_RULE_FEATURE_TEMPLATE_CAEGORIES_FAILED'

export const FETCH_RULE_FEATURE_TEMPLATES =
  'featureTemplates/FETCH_RULE_FEATURE_TEMPLATES'
export const FETCH_RULE_FEATURE_TEMPLATES_STARTED =
  'featureTemplates/FETCH_RULE_FEATURE_TEMPLATES_STARTED'
export const FETCH_RULE_FEATURE_TEMPLATES_SUCCESS =
  'featureTemplates/FETCH_RULE_FEATURE_TEMPLATES_SUCCESS'
export const FETCH_RULE_FEATURE_TEMPLATES_FAILED =
  'featureTemplates/FETCH_RULE_FEATURE_TEMPLATES_FAILED'
