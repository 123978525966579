import { createSelector } from 'reselect'

import selectIntegrationCredentialsByProvider from '../../selectors/selectIntegrationCredentialsByProvider'

export default createSelector(
  selectIntegrationCredentialsByProvider,
  credentials => {
    return credentials.githublegacy && !credentials.github
  }
)
