import { doGraphqlRequest } from 'ducks/requests/operations'
import graphql from '../api'
import { DRAFT_ORDER_CALCULATE_REQUEST } from '../types'

const mutation = `
  mutation ShopifyDraftOrderCalculate(
    $input: DraftOrderInput!
  ) {
    draftOrderCalculate(input: $input) {
      userErrors {
        message
        field
      }

      calculatedDraftOrder {
        availableShippingRates {
          handle
          title
          price {
            amount
            currencyCode
          }
        }
      }
    }
  }
`

const doDraftOrderCalculate = (
  integrationId,
  customerId,
  input = {}
) => dispatch => {
  const variables = {
    input: {
      customerId,
      ...input,
      useCustomerDefaultAddress: true,
      lineItems: input.lineItems,
    },
  }

  return dispatch(
    doGraphqlRequest(DRAFT_ORDER_CALCULATE_REQUEST, mutation, variables, {
      graphqlFunc: graphql(integrationId),
    })
  ).then(data => data?.draftOrderCalculate?.calculatedDraftOrder || {})
}

export default doDraftOrderCalculate
