import { combineReducers } from 'core/redux'

import draftOrders from './draftOrders'
import events from './events'
import orders from './orders'
import products from './products'
import shop from './shop'
import paymentTermsTemplates from './paymentTermsTemplates'
import customers from './customers'

export default combineReducers({
  draftOrders,
  events,
  orders,
  products,
  shop,
  paymentTermsTemplates,
  customers,
})
