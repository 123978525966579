import { folder } from 'ducks/entities/schema'

export const folderGraphQlResponseSchema = {
  folders: [folder],
}

export const folderGraphQlV2ResponseSchema = {
  folders: {
    nodes: [folder],
  },
}
