export function extractSpecificFields(settings, pattern) {
  if (!settings) return null
  const settingKeys = Object.keys(settings)
  const specific = settingKeys
    .filter(key => {
      return settings[key] && key.match(pattern)
    })
    .map(key => key.replace(pattern, ''))
  return specific
}
