import { createActionTypeReducer } from 'util/reducers'
import {
  UPDATE_CUSTOMER_REQUEST_STARTED,
  UPDATE_CUSTOMER_REQUEST_SUCCESS,
  UPDATE_CUSTOMER_REQUEST_FAILED,
} from 'ducks/integrations/recharge/types'

const initialState = {
  byIntegrationId: {},
}

export const initialSingleState = {
  isReloading: false,
  isError: false,
  error: null,
}

export default createActionTypeReducer(
  {
    [UPDATE_CUSTOMER_REQUEST_STARTED]: (draftState, action) => {
      const { integrationId, customerId } = action.payload

      if (!draftState.byIntegrationId[integrationId]) {
        draftState.byIntegrationId[integrationId] = Object.assign(
          {},
          { byCustomerId: {} }
        )
      }

      const byIntegrationId = draftState.byIntegrationId[integrationId]

      if (!byIntegrationId.byCustomerId[customerId]) {
        byIntegrationId.byCustomerId[customerId] = Object.assign(
          {},
          initialSingleState
        )
      }

      byIntegrationId.byCustomerId[customerId].isReloading = true

      return draftState
    },
    [UPDATE_CUSTOMER_REQUEST_SUCCESS]: (draftState, action) => {
      const { integrationId, customerId } = action.meta.requestParameters

      const byCustomerId =
        draftState.byIntegrationId[integrationId].byCustomerId[customerId]

      Object.assign(byCustomerId, {
        isReloading: false,
        isError: false,
        error: null,
      })

      return draftState
    },
    [UPDATE_CUSTOMER_REQUEST_FAILED]: (draftState, action) => {
      const { integrationId, customerId } = action.meta.requestParameters
      const { error } = action.payload

      const byCustomerId =
        draftState.byIntegrationId[integrationId].byCustomerId[customerId]

      Object.assign(byCustomerId, {
        isReloading: false,
        isError: true,
        error,
      })

      return draftState
    },
  },
  initialState
)
