import { createSelector } from 'reselect'

import { selectCustomFieldsByCategoryId } from 'ducks/crm/customFields/selectors'
import { selectCustomFieldCategories } from './base'

export const selectCustomFieldCategoriesWithFieldIds = createSelector(
  selectCustomFieldCategories,
  selectCustomFieldsByCategoryId,
  (categories, customFieldsByCategoryId) => {
    return categories.map(category => {
      return {
        ...category,
        customFields: customFieldsByCategoryId[category.id],
      }
    })
  }
)
