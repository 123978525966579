export const FETCH_OBJECT_REQUEST =
  'integrations/salesforce/FETCH_OBJECT_REQUEST'
export const FETCH_OBJECT_SUCCESS =
  'integrations/salesforce/FETCH_OBJECT_SUCCESS'
export const FETCH_OBJECT_FAILURE =
  'integrations/salesforce/FETCH_OBJECT_FAILURE'
export const FETCH_OBJECTS_FAILURE =
  'integrations/salesforce/FETCH_OBJECTS_FAILURE'
export const FETCH_OBJECTS_SUCCESS =
  'integrations/salesforce/FETCH_OBJECTS_SUCCESS'

export const FETCH_RELATED_OBJECTS_REQUEST =
  'integrations/salesforce/FETCH_RELATED_OBJECTS_REQUEST'
export const FETCH_RELATED_OBJECTS_SUCCESS =
  'integrations/salesforce/FETCH_RELATED_OBJECTS_SUCCESS'
export const FETCH_RELATED_OBJECTS_FAILURE =
  'integrations/salesforce/FETCH_RELATED_OBJECTS_FAILURE'

export const FETCH_PROPERTIES_REQUEST =
  'integrations/salesforce/FETCH_PROPERTIES_REQUEST'
export const FETCH_PROPERTIES_SUCCESS =
  'integrations/salesforce/FETCH_PROPERTIES_SUCCESS'
export const FETCH_PROPERTIES_FAILURE =
  'integrations/salesforce/FETCH_PROPERTIES_FAILURE'

export const FETCH_BATCH_PROPERTIES_REQUEST =
  'integrations/salesforce/FETCH_BATCH_PROPERTIES_REQUEST'
export const FETCH_BATCH_PROPERTIES_SUCCESS =
  'integrations/salesforce/FETCH_BATCH_PROPERTIES_SUCCESS'
export const FETCH_BATCH_PROPERTIES_FAILURE =
  'integrations/salesforce/FETCH_BATCH_PROPERTIES_FAILURE'

export const CREATE_CONTACT_REQUEST =
  'integrations/salesforce/CREATE_CONTACT_REQUEST'
export const CREATE_CONTACT_SUCCESS =
  'integrations/salesforce/CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILURE =
  'integrations/salesforce/CREATE_CONTACT_FAILURE'
