import { oauthTokenSelector } from 'selectors/app'

import * as trello from '../api'
import {
  FETCH_CARD_REQUEST,
  FETCH_CARD_SUCCESS,
  FETCH_CARD_FAILURE,
} from '../types'

export default function doFetchTrelloCard(id) {
  return (dispatch, getState) => {
    const state = getState()

    dispatch({ type: FETCH_CARD_REQUEST, payload: { cards: [{ id }] } })
    const token = oauthTokenSelector(state)
    return trello
      .card(token, id)
      .then(card => {
        return trello.listBoardDetails(token, card.idBoard).then(board => {
          const { id: boardId, name, url, ...data } = board
          return {
            ...data,
            boards: [{ id: boardId, name, url }],
            cards: [card],
          }
        })
      })
      .catch(error =>
        dispatch({
          id,
          error: true,
          payload: error,
          type: FETCH_CARD_FAILURE,
        })
      )
      .then(payload =>
        dispatch({
          type: FETCH_CARD_SUCCESS,
          payload,
        })
      )
  }
}
