import { oauthTokenSelector } from 'selectors/app'

import integrationApi from '../../api'
import {
  FETCH_PRIORITIES_ERROR,
  FETCH_PRIORITIES_REQUEST,
  FETCH_PRIORITIES_SUCCESS,
} from '../types'

export default function doFetchJiraCloudPriorities(cloudId) {
  return (dispatch, getState) => {
    const meta = { cloudId }
    const token = oauthTokenSelector(getState())
    dispatch({
      type: FETCH_PRIORITIES_REQUEST,
      meta,
    })
    integrationApi
      .get(token, `proxy/atlassian/ex/jira/${cloudId}/rest/api/3/priority`)
      .then(({ json: payload }) => {
        dispatch({
          type: FETCH_PRIORITIES_SUCCESS,
          meta,
          payload,
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_PRIORITIES_ERROR,
          error: true,
          meta,
          payload: error,
        })
      })
  }
}
