export const FETCH_CUSTOMER_RATING_SETTINGS =
  'customerRatingSettings/FETCH_CUSTOMER_RATING_SETTINGS'
export const FETCH_CUSTOMER_RATING_SETTINGS_STARTED =
  'customerRatingSettings/FETCH_CUSTOMER_RATING_SETTINGS_STARTED'
export const FETCH_CUSTOMER_RATING_SETTINGS_SUCCESS =
  'customerRatingSettings/FETCH_CUSTOMER_RATING_SETTINGS_SUCCESS'
export const FETCH_CUSTOMER_RATING_SETTINGS_FAILED =
  'customerRatingSettings/FETCH_CUSTOMER_RATING_SETTINGS_FAILED'

export const UPSERT_CUSTOMER_RATING_SETTINGS =
  'customerRatingSettings/UPSERT_CUSTOMER_RATING_SETTINGS'
export const UPSERT_CUSTOMER_RATING_SETTINGS_STARTED =
  'customerRatingSettings/UPSERT_CUSTOMER_RATING_SETTINGS_STARTED'
export const UPSERT_CUSTOMER_RATING_SETTINGS_SUCCESS =
  'customerRatingSettings/UPSERT_CUSTOMER_RATING_SUCCESS'
export const UPSERT_CUSTOMER_RATING_SETTINGS_FAILED =
  'customerRatingSettings/UPSERT_CUSTOMER_RATING_FAILED'

export const DELETE_CUSTOMER_RATING_SETTING =
  'customerRatingSettings/DELETE_CUSTOMER_RATING_SETTING'
export const DELETE_CUSTOMER_RATING_SETTING_STARTED =
  'customerRatingSettings/DELETE_CUSTOMER_RATING_SETTING_STARTED'
export const DELETE_CUSTOMER_RATING_SETTING_SUCCESS =
  'customerRatingSettings/DELETE_CUSTOMER_RATING_SETTING_SUCCESS'
export const DELETE_CUSTOMER_RATING_SETTING_FAILED =
  'customerRatingSettings/DELETE_CUSTOMER_RATING_SETTING_FAILED'
