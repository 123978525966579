import React from 'react'

import { propFunc } from 'util/functions'

import SearchInput from '../SearchInput'
import Category from './Category'

const CannedRepliesByCategory = ({
  categories,
  onSelectReply,
  fetchCannedReplies,
}) => (
  <div>
    <SearchInput />
    {categories.map(category => (
      <Category
        key={`category-${category.id}`}
        name={category.name}
        replies={category.templates}
        onSelectReply={onSelectReply}
        onOpen={propFunc(fetchCannedReplies, [category.id])}
      />
    ))}
  </div>
)

export default CannedRepliesByCategory
