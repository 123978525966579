import { oauthTokenSelector } from 'selectors/app'
import config from 'config'
import { selectContactByEmail } from '../selectors'

import * as api from '../api'
import {
  FETCH_CONTACT_LISTS_REQUEST,
  FETCH_CONTACT_LISTS_SUCCESS,
  FETCH_CONTACT_LISTS_FAILURE,
} from '../types'

export default function doFetchContactLists(email) {
  return (dispatch, getState) => {
    const state = getState()
    const meta = {
      requestKey: `integrations/hubspot/fetchContactLists:${email}`,
    }
    const customer = selectContactByEmail(state, email)
    if (!customer) return null
    if (!customer.listIds) return null

    dispatch({
      type: FETCH_CONTACT_LISTS_REQUEST,
      data: {
        email,
      },
      meta,
    })
    const token = oauthTokenSelector(state)
    return api
      .getContactLists(token, customer.listIds)
      .then(payload => {
        const { response, code } = payload
        dispatch({
          type: FETCH_CONTACT_LISTS_SUCCESS,
          data: {
            email,
            code,
            lists: response.lists,
          },
          meta,
        })
        return payload
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        if (config.isDevelopment) console.error(error)
        return dispatch({
          type: FETCH_CONTACT_LISTS_FAILURE,
          data: {
            email,
            error,
          },
          meta,
        })
      })
  }
}
