import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { selectCustomFields, selectCustomFieldsById } from './base'

export const selectCustomFieldsByCategoryId = createSelector(
  selectCustomFieldsById,
  fields => {
    return Object.values(fields).reduce((result, field) => {
      const { category } = field
      // eslint-disable-next-line no-param-reassign
      if (!result[category]) result[category] = []
      result[category].push(field)
      return result
    }, {})
  }
)

export const selectCustomFieldsForCategoryId = createCachedSelector(
  selectCustomFields,
  (state, { categoryId }) => categoryId,
  (fields, categoryId) => {
    return fields.filter(
      ({ deletedAt, category }) => !deletedAt && category === categoryId
    )
  }
)((state, { categoryId }) => categoryId)

export const selectTrashedCustomFieldsForCategoryId = createCachedSelector(
  selectCustomFields,
  (state, { categoryId }) => categoryId,
  (fields, categoryId) => {
    return fields.filter(
      ({ deletedAt, category }) => !!deletedAt && category === categoryId
    )
  }
)((state, { categoryId }) => categoryId)

export const selectCustomFieldsForCategoryIdByKey = createCachedSelector(
  selectCustomFieldsForCategoryId,
  fields => {
    return fields.reduce((result, field) => {
      // eslint-disable-next-line no-param-reassign
      result[field.key] = field
      return result
    }, {})
  }
)((state, { categoryId }) => categoryId)

export const selectCustomFieldIdsForCategoryId = createCachedSelector(
  selectCustomFields,
  fields => {
    return fields.map(({ id }) => id)
  }
)((state, { categoryId }) => categoryId)
