export const FETCH_THIRDPARTY_ACCESSTOKENS =
  'accessTokens/FETCH_THIRDPARTY_ACCESSTOKENS'
export const FETCH_THIRDPARTY_ACCESSTOKENS_REQUEST =
  'accessTokens/FETCH_THIRDPARTY_ACCESSTOKENS_STARTED'
export const FETCH_THIRDPARTY_ACCESSTOKENS_SUCCESS =
  'accessTokens/FETCH_THIRDPARTY_ACCESSTOKENS_SUCCESS'
export const FETCH_THIRDPARTY_ACCESSTOKENS_FAIL =
  'accessTokens/FETCH_THIRDPARTY_ACCESSTOKENS_FAILED'

export const FETCH_PRIVATE_ACCESS_TOKEN =
  'accessTokens/FETCH_PRIVATE_ACCESS_TOKEN'
export const FETCH_PRIVATE_ACCESS_TOKEN_REQUEST =
  'accessTokens/FETCH_PRIVATE_ACCESS_TOKEN_STARTED'
export const FETCH_PRIVATE_ACCESS_TOKEN_SUCCESS =
  'accessTokens/FETCH_PRIVATE_ACCESS_TOKEN_SUCCESS'
export const FETCH_PRIVATE_ACCESS_TOKEN_FAIL =
  'accessTokens/FETCH_PRIVATE_ACCESS_TOKEN_FAILED'

export const DELETE_THIRDPARTY_ACCESSTOKEN =
  'accessTokens/DELETE_THIRDPARTY_ACCESSTOKEN'
export const DELETE_THIRDPARTY_ACCESSTOKEN_STARTED =
  'accessTokens/DELETE_THIRDPARTY_ACCESSTOKEN_STARTED'
export const DELETE_THIRDPARTY_ACCESSTOKEN_SUCCESS =
  'accessTokens/DELETE_THIRDPARTY_ACCESSTOKEN_SUCCESS'
export const DELETE_THIRDPARTY_ACCESSTOKEN_FAILED =
  'accessTokens/DELETE_THIRDPARTY_ACCESSTOKEN_FAILED'
