import createCachedSelector from 're-reselect'
import { emptyArr, sortByKey } from 'util/arrays'
import { selectIsBillingDataLoaded } from './selectIsBillingDataLoaded'
import { selectPricings } from './selectPricings'
import { selectPlans } from './selectPlans'
import { selectCurrentBillingCycle } from './selectCurrentBillingCycle'
import { selectBillingUsage } from './selectBillingUsage'
import {
  ACTIVE_PLAN_STATES,
  BILLING_CYCLE_ANNUAL,
  BILLING_CYCLE_MONTHLY,
} from '../constants'

export const selectCurrentPlansByType = createCachedSelector(
  (_state, type) => type,
  selectPlans,
  selectIsBillingDataLoaded,
  selectPricings,
  selectCurrentBillingCycle,
  selectBillingUsage,
  (type, plans, isBillingDataLoaded, pricings, billingCycle, usage) => {
    if (!isBillingDataLoaded || plans.length === 0) return emptyArr

    const currentPlans = plans.filter(
      p =>
        p.pricing.type === type &&
        (ACTIVE_PLAN_STATES.includes(p.state) || p.pricing.active)
    )

    if (currentPlans) return currentPlans

    const eligiblePricings = pricings.filter(p => p.type === type && p.active)
    const defaultPricing = sortByKey(eligiblePricings, BILLING_CYCLE_MONTHLY)[0]

    return [
      {
        id: null,
        createdAt: new Date().toISOString(),
        expiresAt: new Date().toISOString(),
        externalId:
          billingCycle === BILLING_CYCLE_ANNUAL
            ? defaultPricing.externalAnnualPriceId
            : defaultPricing.externalMonthlyPriceId,
        quantity: usage[defaultPricing.usageFrom],
        state: 'EXPIRED',
        pricing: defaultPricing,
      },
    ]
  }
)((_state, type) => type || 'unknown')
