import { oauthTokenSelector } from 'selectors/app'
import config from 'config'
import { selectContactByEmail } from '../selectors'
import doFetchCompanyProperties from './doFetchCompanyProperties'

import * as api from '../api'
import {
  FETCH_CONTACT_COMPANY_REQUEST,
  FETCH_CONTACT_COMPANY_SUCCESS,
  FETCH_CONTACT_COMPANY_FAILURE,
} from '../types'

export default function doFetchContactCompany(email) {
  return (dispatch, getState) => {
    dispatch(doFetchCompanyProperties()).then(() => {
      const state = getState()

      const customer = selectContactByEmail(state, email)
      if (!customer) return null

      const companyId = customer.companyId
      if (!companyId) return null
      const meta = {
        requestKey: `integrations/hubspot/fetchContactCompany:${email}`,
      }
      dispatch({
        type: FETCH_CONTACT_COMPANY_REQUEST,
        data: {
          email,
        },
        meta,
      })
      const token = oauthTokenSelector(state)
      return api
        .getCompany(token, companyId)
        .then(payload => {
          const { response, code } = payload
          dispatch({
            type: FETCH_CONTACT_COMPANY_SUCCESS,
            data: {
              email,
              code,
              company: response,
            },
            meta,
          })
          return payload
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          if (config.isDevelopment) console.error(error)
          return dispatch({
            type: FETCH_CONTACT_COMPANY_FAILURE,
            data: {
              email,
              error,
            },
            meta,
          })
        })
    })
  }
}
