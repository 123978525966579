export const FETCH_CUSTOM_PROFILE =
  'integrations/customProfile/FETCH_CUSTOM_PROFILE'
export const FETCH_CUSTOM_PROFILE_SUCCESS =
  'integrations/customProfile/FETCH_CUSTOM_PROFILE_SUCCESS'

export const FETCH_CUSTOM_PROFILE_APP =
  'integrations/customProfile/FETCH_CUSTOM_PROFILE_APP'
export const FETCH_CUSTOM_PROFILE_APP_STARTED =
  'integrations/customProfile/FETCH_CUSTOM_PROFILE_APP_STARTED'
export const FETCH_CUSTOM_PROFILE_APP_SUCCESS =
  'integrations/customProfile/FETCH_CUSTOM_PROFILE_APP_SUCCESS'
export const FETCH_CUSTOM_PROFILE_APP_FAILED =
  'integrations/customProfile/FETCH_CUSTOM_PROFILE_APP_FAILED'

export const TEST_CUSTOM_PROFILE_APP =
  'integrations/customProfile/TEST_CUSTOM_PROFILE_APP'
export const TEST_CUSTOM_PROFILE_APP_STARTED =
  'integrations/customProfile/TEST_CUSTOM_PROFILE_APP_STARTED'
export const TEST_CUSTOM_PROFILE_APP_SUCCESS =
  'integrations/customProfile/TEST_CUSTOM_PROFILE_APP_SUCCESS'
export const TEST_CUSTOM_PROFILE_APP_FAILED =
  'integrations/customProfile/TEST_CUSTOM_PROFILE_APP_FAILED'

export const UPDATE_CUSTOM_PROFILE_APP =
  'integrations/customProfile/UPDATE_CUSTOM_PROFILE_APP'
export const UPDATE_CUSTOM_PROFILE_APP_STARTED =
  'integrations/customProfile/UPDATE_CUSTOM_PROFILE_APP_STARTED'
export const UPDATE_CUSTOM_PROFILE_APP_SUCCESS =
  'integrations/customProfile/UPDATE_CUSTOM_PROFILE_APP_SUCCESS'
export const UPDATE_CUSTOM_PROFILE_APP_FAILED =
  'integrations/customProfile/UPDATE_CUSTOM_PROFILE_APP_FAILED'
