import {
  FETCH_ALL_CUSTOM_FIELD_CATEGORIES_STARTED,
  FETCH_CUSTOM_FIELD_CATEGORIES_FAILED,
} from '../types'

export default function hasErrored(state = false, { type }) {
  if (type === FETCH_CUSTOM_FIELD_CATEGORIES_FAILED) return true
  if (type === FETCH_ALL_CUSTOM_FIELD_CATEGORIES_STARTED) return false
  return state
}
