import { TICKET_PAGE } from 'constants/pages'
import { getVariableValue } from 'util/cannedReply'
import { getRawId, getType } from 'util/globalId'

export function findJobId(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return undefined
  }

  if ('jobId' in obj) {
    return obj.jobId
  }

  let jobId
  Object.keys(obj).forEach(key => {
    if (!jobId) {
      jobId = findJobId(obj[key])
    }
  })

  return jobId
}

export const interpolateAiDraft = ({
  reply,
  currentUser,
  draft,
  ticket,
  account,
  changesets,
  mailbox,
}) => {
  if (!reply) return reply
  return reply.replace(/%\{([a-zA-Z0-9_]+)\}/gi, (match, variable) => {
    return (
      getVariableValue(
        currentUser,
        account,
        ticket,
        changesets,
        mailbox,
        draft,
        {},
        variable
      ) || match
    )
  })
}

export const getSourceTo = source => {
  if (source?.url) return source.url // article
  if (getType(source?.id) === 'Conversation') {
    return {
      type: TICKET_PAGE,
      payload: { id: getRawId(source.id) },
    }
  }
  return null
}

export const getSourceTitle = source => source.title || source.subject
