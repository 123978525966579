// HACK (jscheel): We seem to be inconsistent in our usage and capitalization
// of the shopify enums. Therefore we copy all enums to lowercase as well as
// uppercase, and append multiple fulfillment enumns into the same lookup table.
function includeLowerCaseKey(obj) {
  return Object.keys(obj).reduce((memo, key) => {
    const val = obj[key]
    // eslint-disable-next-line no-param-reassign
    memo[key] = val
    // eslint-disable-next-line no-param-reassign
    memo[key.toLowerCase()] = val
    return memo
  }, {})
}
export const ORDER_DISPLAY_FINANCIAL_STATUS_TEXT = includeLowerCaseKey({
  AUTHORIZED: 'Authorized',
  EXPIRED: 'Expired',
  PAID: 'Paid',
  PARTIALLY_PAID: 'Partially paid',
  PARTIALLY_REFUNDED: 'Partially refunded',
  PENDING: 'Payment Pending',
  REFUNDED: 'Refunded',
  VOIDED: 'Voided',
})

export const FULFILLMENT_DISPLAY_STATUS_TEXT = includeLowerCaseKey({
  CANCELED: 'Canceled',
  CONFIRMED: 'Confirmed',
  DELIVERED: 'Delivered',
  FAILURE: 'Failure',
  FULFILLED: 'Fulfilled',
  IN_TRANSIT: 'In transit',
  LABEL_PRINTED: 'Label printed',
  LABEL_PURCHASED: 'Label purchased',
  LABEL_VOIDED: 'Label voided',
  MARKED_AS_FULFILLED: 'Marked as fulfilled',
  NOT_DELIVERED: 'Not delivered',
  OUT_FOR_DELIVERY: 'Out for delivery',
  PICKED_UP: 'Picked up',
  READY_FOR_PICKUP: 'Ready for pickup',
  SUBMITTED: 'Submitted',

  // Other statuses
  IN_PROGRESS: 'In progress',
  ON_HOLD: 'On hold',
  OPEN: 'Open',
  PARTIALLY_FULFILLED: 'Partially fulfilled',
  PENDING_FULFILLMENT: 'Pending fulfillment',
  RESTOCKED: 'Restocked',
  SCHEDULED: 'Scheduled',
  UNFULFILLED: 'Unfulfilled',
})

export const CUSTOMER_MARKETING_OPT_IN_LEVEL = includeLowerCaseKey({
  CONFIRMED_OPT_IN: 'Confirmed opt-in',
  SINGLE_OPT_IN: 'Single opt-in',
  confirmed_opt_in: 'Confirmed opt-in',
  single_opt_in: 'Single opt-in',
})

export const PAYMENT_TERMS_TYPES = {
  RECEIPT: 'RECEIPT',
  NET: 'NET',
  FIXED: 'FIXED',
}

export const PAYMENT_TERMS_TYPES_DATE_PICKER_LABEL = {
  [PAYMENT_TERMS_TYPES.RECEIPT]: '',
  [PAYMENT_TERMS_TYPES.NET]: 'Issue date',
  [PAYMENT_TERMS_TYPES.FIXED]: 'Due date',
}

export const DISCOUNT_VALUE_TYPES = {
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
}

export const REFUND_RESTOCK_TYPES = {
  NO_RESTOCK: 'NO_RESTOCK',
  CANCEL: 'CANCEL',
  RETURN: 'RETURN',
}

// https://shopify.dev/api/admin-graphql/2024-01/enums/CustomerProductSubscriberStatus
export const PRODUCT_SUBSCRIBER_STATUS = {
  ACTIVE: 'Active',
  CANCELLED: 'Cancelled',
  EXPIRED: 'Expired',
  FAILED: 'Failed',
  NEVER_SUBSCRIBED: 'Never Subscribed',
  PAUSED: 'Paused',
}

export const MISSING_VALUE = 'No value'
export const EVENTS_LIMIT = 15
export const RECENT_ORDERS_LIMIT = 3
export const MORE_ORDERS_LIMIT = 10

export const defaultCustomFieldsOrder = keyPrefix => {
  return [
    {
      key: `${keyPrefix}_lifetime_duration`,
      index: 0,
      visible: true,
    },
    {
      key: `${keyPrefix}_total_spent`,
      index: 1,
      visible: true,
    },
    {
      key: `${keyPrefix}_orders_count`,
      index: 2,
      visible: true,
    },
    {
      key: `${keyPrefix}_tags`,
      index: 3,
      visible: true,
    },
    {
      key: `${keyPrefix}_note`,
      index: 4,
      visible: true,
    },
    {
      key: `${keyPrefix}_addresses`,
      index: 5,
      visible: false,
    },
    {
      key: `${keyPrefix}_currency`,
      index: 6,
      visible: false,
    },
    {
      key: `${keyPrefix}_locale`,
      index: 7,
      visible: false,
    },
    {
      key: `${keyPrefix}_marketing_opt_in_level`,
      index: 8,
      visible: false,
    },
    {
      key: `${keyPrefix}_product_subscriber_status`,
      index: 9,
      visible: false,
    },
    {
      key: `${keyPrefix}_state`,
      index: 10,
      visible: false,
    },
    {
      key: `${keyPrefix}_tax_exempt`,
      index: 11,
      visible: false,
    },
    {
      key: `${keyPrefix}_tax_exemptions`,
      index: 12,
      visible: false,
    },
  ]
}
