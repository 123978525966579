// Settings
import Sample from 'subapps/settings/components/drawers/Sample'
import CannedReplyCreateEdit from 'subapps/settings/components/drawers/CannedReplyCreateEdit'
import CannedReplyDelete from 'subapps/settings/components/drawers/CannedReplyDelete'
import CannedReplyCategoryDelete from 'subapps/settings/components/drawers/CannedReplyCategoryDelete'
import CannedReplyCategoryCreateEdit from 'subapps/settings/components/drawers/CannedReplyCategoryCreateEdit'
import CannedReplyChangeCategory from 'subapps/settings/components/drawers/CannedReplyChangeCategory'
import CannedReplyOverview from 'subapps/settings/components/drawers/CannedReplyOverview'
import FolderDelete from 'subapps/settings/components/drawers/FolderDelete'
import FolderCreateEdit from 'subapps/settings/components/drawers/FolderCreateEdit'
import FolderOverview from 'subapps/settings/components/drawers/FolderOverview'
import RulesOverview from 'subapps/settings/components/drawers/RulesOverview'
import RuleDelete from 'subapps/settings/components/drawers/RuleDelete'
import RuleCreateEdit from 'subapps/settings/components/drawers/RuleCreateEdit'
import TagCreateEdit from 'subapps/settings/components/drawers/TagCreateEdit'
import TagDelete from 'subapps/settings/components/drawers/TagDelete'
import TagMerge from 'subapps/settings/components/drawers/TagMerge'
import TagOverview from 'subapps/settings/components/drawers/TagOverview'
import UsersOverview from 'subapps/settings/components/drawers/UsersOverview'
import TeamsOverview from 'subapps/settings/components/drawers/TeamsOverview'
import WebhookCreateEdit from 'subapps/settings/components/drawers/WebhookCreateEdit'
import WebhookDelete from 'subapps/settings/components/drawers/WebhookDelete'
import ThirdPartyAccessTokenDelete from 'subapps/settings/components/drawers/ThirdPartyAccessTokenDelete'

import AgentArchive from 'subapps/settings/components/drawers/AgentArchive'
import AgentAdd from 'subapps/settings/components/drawers/AgentAdd'
import AgentAddMailboxAccess from 'subapps/settings/components/drawers/AgentAddMailboxAccess'
import AgentChangeOwner from 'subapps/settings/components/drawers/AgentChangeOwner'
import UserAdvanced from 'subapps/settings/components/drawers/UserAdvanced'
import TeamCreateEdit from 'subapps/settings/components/drawers/TeamCreateEdit'
import AgentAddAdmin from 'subapps/settings/components/drawers/AgentAddAdmin'
import AgentRemoveAdmin from 'subapps/settings/components/drawers/AgentRemoveAdmin'
import TeamDelete from 'subapps/settings/components/drawers/TeamDelete'
import IntegrationInstall from 'subapps/settings/components/drawers/integrations/Install'
import IntegrationUninstall from 'subapps/settings/components/drawers/integrations/Uninstall'
import SpammerCreateDrawer from 'subapps/settings/components/drawers/SpammerCreate'
import SpammerDeleteDrawer from 'subapps/settings/components/drawers/SpammerDelete'
import AgentSwitchToLite from 'subapps/settings/components/drawers/AgentSwitchToLite'
import UserRevokeInvitation from 'subapps/settings/components/drawers/UserRevokeInvitation'
import UserUnarchive from 'subapps/settings/components/drawers/UserUnarchive'

// Billing
import BillingConfirm from 'subapps/settings/components/drawers/BillingConfirm'
import BillingChangeVersions from 'subapps/settings/components/drawers/BillingChangeVersions'
import BillingLinkShopify from 'subapps/settings/components/drawers/BillingLinkShopify'
import BillingExternalApprove from 'subapps/settings/components/drawers/BillingExternalApprove'
import BillingSetupPaymentMethod from 'subapps/settings/components/drawers/BillingSetupPaymentMethod'
import BillingSetupPaymentMethodFeedback from 'subapps/settings/components/drawers/BillingSetupPaymentMethodFeedback'

// Channels
import AddChannel from 'subapps/settings/components/drawers/Channels/AddChannel'
import DeleteChannel from 'subapps/settings/components/drawers/Channels/DeleteChannel'
import SelectEmailChannel from 'subapps/settings/components/drawers/Channels/SelectEmailChannel'
import SelectGoogleEmailChannel from 'subapps/settings/components/drawers/Channels/SelectEmailChannel/SelectGoogleEmailChannel'
import SelectGoogleWorkspaceEmailChannel from 'subapps/settings/components/drawers/Channels/SelectEmailChannel/SelectGoogleWorkspaceEmailChannel'
import GoogleForwardingOptionAlert from 'subapps/settings/components/drawers/Channels/SelectEmailChannel/GoogleForwardingOptionAlert'
import SelectFacebookPage from 'subapps/settings/components/drawers/Channels/SelectFacebookPage'
import SetupEmailChannel from 'subapps/settings/components/drawers/Channels/SetupEmailChannel'
import ChannelForwardingDetails from 'subapps/settings/components/drawers/Channels/ChannelDetails/ChannelForwardingDetails'
import ChannelDetailsOAuthModal from 'subapps/settings/components/drawers/Channels/ChannelDetails/OAuthModal'
import ChannelDetailsOAuth from 'subapps/settings/components/drawers/Channels/ChannelDetails/OAuth'
import AuthenticateChannel from 'subapps/settings/components/drawers/Channels/AuthenticateChannel'
import AuthenticateSuccess from 'subapps/settings/components/drawers/Channels/AuthenticateSuccess'
import AuthenticateIMAPChannel from 'subapps/settings/components/drawers/Channels/AuthenticateChannel/AuthenticateIMAPChannel'
import EditMembersModal from 'subapps/settings/components/drawers/Channels/EditMembersModal'
import EditMembers from 'subapps/settings/components/drawers/Channels/EditMembers'
import MailImport from 'subapps/settings/components/drawers/Channels/MailImport'
import CreationFeedback from 'subapps/settings/components/drawers/Channels/CreationFeedback'
import EmailForward from 'subapps/settings/components/drawers/Channels/EmailForward'
import ForwardingInstructions from 'subapps/settings/components/drawers/Channels/ForwardingInstructions'
import ForwardingTest from 'subapps/settings/components/drawers/Channels/ForwardingTest'
import ForwardingFail from 'subapps/settings/components/drawers/Channels/ForwardingFail'
import ForwardingInstructionsSent from 'subapps/settings/components/drawers/Channels/ForwardingInstructionsSent'
import AddLiveChat from 'subapps/settings/components/drawers/Channels/AddLiveChat'
import SelectWidget from 'subapps/settings/components/drawers/Channels/SelectWidget'

// Shopify
import CancelOrderModal from 'components/integrations/ShopifyV2WidgetCard/CancelOrderModal'
import CreateOrderDrawer from 'components/integrations/ShopifyV2WidgetCard/CreateOrderDrawer'
import SendInvoice from 'components/integrations/ShopifyV2WidgetCard/SendInvoice/SendInvoice'
import ReviewInvoice from 'components/integrations/ShopifyV2WidgetCard/ReviewInvoice/ReviewInvoice'
import UnsavedChangesDrawer from 'components/integrations/ShopifyV2WidgetCard/UnsavedChangesDrawer'
import RefundOrderDrawer from 'components/integrations/ShopifyV2WidgetCard/RefundOrderDrawer'
import StoreAdd from 'subapps/settings/components/drawers/integrations/providers/shopify/StoreAdd'
import StoreUpdate from 'subapps/settings/components/drawers/integrations/providers/shopify/StoreUpdate'
import StoreConfigure from 'subapps/settings/components/drawers/integrations/providers/shopify/Configure'

// Zapier
import IntegrationInstallZapier from 'subapps/settings/components/drawers/integrations/providers/zapier'

// Mailchimp
import IntegrationManageMailchimp from 'subapps/settings/components/drawers/integrations/providers/mailchimp'

// Slack
import IntegrationSlackConfigure from 'subapps/settings/components/drawers/integrations/providers/slack/Configure'
import IntegrationSlackChannel from 'subapps/settings/components/drawers/integrations/providers/slack/Channel'
import IntegrationSlackChannelDelete from 'subapps/settings/components/drawers/integrations/providers/slack/ChannelDelete'
import IntegrationSlackMapUsernames from 'subapps/settings/components/drawers/integrations/providers/slack/MapUsernames'
import IntegrationSlackSetupSuccess from 'subapps/settings/components/drawers/integrations/providers/slack/SetupSuccess'

// Data Exports
import ExportCancelOrDelete from 'subapps/settings/components/drawers/ExportCancelOrDelete'

// Recharge
import IntegrationInstallRecharge from 'subapps/settings/components/drawers/integrations/providers/recharge/Install'
import IntegrationConfigureRecharge from 'subapps/settings/components/drawers/integrations/providers/recharge/Configure'
import IntegrationStoreUpdateRecharge from 'subapps/settings/components/drawers/integrations/providers/recharge/StoreUpdate'
import IntegrationCancelSubscriptionRecharge from 'subapps/settings/components/drawers/integrations/providers/recharge/CancelSubscription'
import IntegrationReactivateSubscriptionRecharge from 'subapps/settings/components/drawers/integrations/providers/recharge/ReactivateSubscription'
import IntegrationSkipNextOrderRecharge from 'subapps/settings/components/drawers/integrations/providers/recharge/SkipNextOrder'

// KB
import KBDelete from 'subapps/kb/components/Settings/Advanced/KBDelete'
import KBCreateMore from 'subapps/kb/components/KBCreateMore'
import KBArticleDelete from 'subapps/kb/components/drawers/ArticleDelete'
import KBCategoryCreate from 'subapps/kb/components/drawers/CategoryCreate'

// Widget
import WidgetDelete from 'subapps/settings/components/Widgets/WidgetDelete'
import DisabledWidgetDeletion from 'subapps/settings/components/Widgets/WidgetDelete/DisabledDeletion'
import ReorderContactFormFields from 'subapps/settings/components/Widgets/Settings/ContactForm/ReorderContactFormFields'
import EditCustomField from 'subapps/settings/components/Widgets/Settings/ContactForm/EditCustomField'
import CustomFieldDelete from 'subapps/settings/components/Widgets/Settings/ContactForm/CustomFieldDelete'
import LiveChatDelete from 'subapps/settings/pages/WidgetsChatPage/LiveChatDelete'
import LiveChatConfirmDelete from 'subapps/settings/pages/WidgetsChatPage/LiveChatDelete/LiveChatConfirmDelete'

// Rating Settings
import CustomerRatingSettingEdit from 'subapps/settings/components/drawers/CustomerRatingSettingEdit'
import CustomerRatingSettingDelete from 'subapps/settings/components/drawers/CustomerRatingSettingDelete'

// Billing
import AnnualOffer from 'components/AnnualOffer'
import BillingChangePlan from 'subapps/settings/components/drawers/BillingPlanChange/BillingChangePlanDetails'
import BillingBasicChangePlan from 'subapps/settings/components/drawers/BillingPlanChange/BillingBasicChangePlanDetails'
import BillingDownGradePlan from 'subapps/settings/components/drawers/BillingPlanChange/BillingDownGradePlan'
import BillingDownGradePlanConfirmation from 'subapps/settings/components/drawers/BillingPlanChange/BillingDownGradePlanConfirmation'
import BillingInformation from 'subapps/settings/components/drawers/BillingInformation'

// Tickets
import CommentDelete from 'subapps/ticketing/components/drawers/CommentDeleteDrawer'
import TicketMerge from 'subapps/ticketing/components/drawers/TicketMergeDrawer'

// Onboarding workflow
import OnboardingWorkflow from 'components/App/DesktopView/Layout/OnboardingWorkflow'
import DiscountLockout from 'components/App/DesktopView/Layout/DiscountLockout'

// AI reply lockout
import AIDraftLockout from 'components/App/DesktopView/Layout/AIDraftLockout'

// Send debug info
import SendDebugInfo from 'components/SendDebugInfo'

import {
  DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  DRAWER_TYPE_CANNED_REPLIES_DELETE,
  DRAWER_TYPE_CANNED_REPLY_CATEGORIES_DELETE,
  DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE,
  DRAWER_TYPE_CANNED_REPLIES_CHANGE_CATEGORY_UPDATE,
  DRAWER_TYPE_FOLDERS_DELETE,
  DRAWER_TYPE_FOLDERS_UPDATE,
  DRAWER_TYPE_FOLDERS_OVERVIEW,
  DRAWER_TYPE_RULES_OVERVIEW,
  DRAWER_TYPE_RULES_DELETE,
  DRAWER_TYPE_RULES_UPDATE,
  DRAWER_TYPE_TAGS_UPDATE,
  DRAWER_TYPE_TAGS_DELETE,
  DRAWER_TYPE_TAGS_MERGE,
  DRAWER_TYPE_TAGS_OVERVIEW,
  DRAWER_TYPE_USERS_OVERVIEW,
  DRAWER_TYPE_TEAMS_OVERVIEW,
  DRAWER_TYPE_AGENT_ARCHIVE,
  DRAWER_TYPE_AGENT_ADD,
  DRAWER_TYPE_AGENT_ADD_MAILBOX_ACCESS,
  DRAWER_TYPE_AGENT_OWNER,
  DRAWER_TYPE_USER_ADVANCED,
  DRAWER_TYPE_SAMPLE,
  DRAWER_TYPE_TEAM_UPDATE,
  DRAWER_TYPE_TEAM_DELETE,
  DRAWER_TYPE_CHANNELS_ADD,
  DRAWER_TYPE_CHANNELS_DELETE,
  DRAWER_TYPE_CHANNELS_SELECT_EMAIL,
  DRAWER_TYPE_CHANNELS_SELECT_FACEBOOKPAGE,
  DRAWER_TYPE_CHANNELS_FORWARDING_DETAILS,
  DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH_MODAL,
  DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH,
  DRAWER_TYPE_CHANNELS_AUTHENTICATE,
  DRAWER_TYPE_CHANNELS_AUTHENTICATE_SUCCESS,
  DRAWER_TYPE_CHANNELS_MEMBERS_EDIT_MODAL,
  DRAWER_TYPE_CHANNELS_MEMBERS_EDIT,
  DRAWER_TYPE_CHANNELS_MAIL_IMPORT,
  DRAWER_TYPE_CHANNELS_CREATION_FEEDBACK,
  DRAWER_TYPE_CHANNELS_ADD_LIVE_CHAT,
  DRAWER_TYPE_CHANNELS_SELECT_WIDGET,
  DRAWER_TYPE_SHOPIFY_CANCEL_ORDER,
  DRAWER_TYPE_SHOPIFY_CREATE_ORDER,
  DRAWER_TYPE_SHOPIFY_SEND_INVOICE,
  DRAWER_TYPE_SHOPIFY_REVIEW_INVOICE,
  DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
  DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS,
  DRAWER_TYPE_CHANNELS_FORWRADING_TEST,
  DRAWER_TYPE_CHANNELS_FORWRADING_FAILED,
  DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS_SENT,
  DRAWER_TYPE_SHOPIFY_REFUND_ORDER,
  DRAWER_TYPE_SHOPIFY_UNSAVED_CHANGES,
  DRAWER_TYPE_SHOPIFY_STORE_ADD,
  DRAWER_TYPE_SHOPIFY_STORE_UPDATE,
  DRAWER_TYPE_SHOPIFY_CONFIGURE,
  DRAWER_TYPE_INTEGRATION_INSTALL,
  DRAWER_TYPE_INTEGRATION_UNINSTALL,
  DRAWER_TYPE_CHANNELS_SETUP_EMAIL,
  DRAWER_TYPE_CANNED_REPLIES_OVERVIEW,
  DRAWER_TYPE_WEBHOOKS_UPDATE,
  DRAWER_TYPE_WEBHOOKS_DELETE,
  DRAWER_TYPE_THIRDPARTY_ACCESSTOKENS_DELETE,
  DRAWER_TYPE_INTEGRATION_INSTALL_ZAPIER,
  DRAWER_TYPE_SPAMMERS_CREATE,
  DRAWER_TYPE_SPAMMERS_DELETE,
  DRAWER_TYPE_INTEGRATION_MANAGE_MAILCHIMP,
  DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE,
  DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE,
  DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_DELETE,
  DRAWER_TYPE_INTEGRATION_SLACK_MAP_USERNAMES,
  DRAWER_TYPE_INTEGRATION_SLACK_SETUP_SUCCESS,
  DRAWER_TYPE_EXPORTS_CANCEL_OR_DELETE,
  DRAWER_TYPE_BILLING_CHANGE_VERSION,
  DRAWER_TYPE_BILLING_CONFIRM,
  DRAWER_TYPE_BILLING_LINK_SHOPIFY,
  DRAWER_TYPE_BILLING_EXTERNAL_APPROVE,
  DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD,
  DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD_FEEDBACK,
  DRAWER_TYPE_INTEGRATION_RECHARGE_INSTALL,
  DRAWER_TYPE_INTEGRATION_RECHARGE_CONFIGURE,
  DRAWER_TYPE_INTEGRATION_RECHARGE_STORE_UPDATE,
  DRAWER_TYPE_INTEGRATION_RECHARGE_CANCEL_SUBSCRIPTION,
  DRAWER_TYPE_INTEGRATION_RECHARGE_REACTIVATE_SUBSCRIPTION,
  DRAWER_TYPE_INTEGRATION_RECHARGE_SKIP_NEXT_ORDER,
  DRAWER_TYPE_KB_DELETE,
  DRAWER_TYPE_KB_CREATE_MORE,
  DRAWER_TYPE_KB_ARTICLE_DELETE,
  DRAWER_TYPE_KB_CATEGORY_CREATE,
  DRAWER_TYPE_WIDGET_DELETE,
  DRAWER_TYPE_WIDGET_DELETE_DISABLED,
  DRAWER_TYPE_WIDGET_REORDER_FORM_FIELDS,
  DRAWER_TYPE_WIDGET_EDIT_CUSTOM_FIELD,
  DRAWER_TYPE_WIDGET_DELETE_CUSTOM_FIELD,
  DRAWER_TYPE_LIVE_CHAT_DELETE,
  DRAWER_TYPE_LIVE_CHAT_CONFIRM_DELETE,
  DRAWER_TYPE_CUSTOMER_RATING_SETTING_UPSERT,
  DRAWER_TYPE_CUSTOMER_RATING_SETTING_DELETE,
  DRAWER_TYPE_ANNUAL_OFFER_MODAL,
  DRAWER_TYPE_BILLING_CHANGE_PLAN,
  DRAWER_TYPE_BILLING_BASIC_CHANGE_PLAN,
  DRAWER_TYPE_BILLING_DOWNGRADE_PLAN,
  DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION,
  DRAWER_TYPE_BILLING_INFORMATION,
  DRAWER_COMMENT_DELETE,
  DRAWER_TICKET_MERGE,
  DRAWER_TYPE_ONBOARDING_WORKFLOW,
  DRAWER_TYPE_DISCOUNT_LOCKOUT,
  DRAWER_TYPE_CHANNELS_AUTHENTICATE_IMAP,
  DRAWER_TYPE_ADMIN_ADD,
  DRAWER_TYPE_ADMIN_REMOVE,
  DRAWER_TYPE_LITE_USER_ADD,
  DRAWER_TYPE_REVOKE_INVITATION,
  DRAWER_TYPE_UNARCHIVE,
  DRAWER_TYPE_AI_DRAFT_LOCKOUT,
  DRAWER_TYPE_SEND_DEBUG_INFO,
  DRAWER_TYPE_CHANNELS_SELECT_GOOGLE_EMAIL,
  DRAWER_TYPE_CHANNELS_SELECT_GOOGLE_WORKSPACE_EMAIL,
  DRAWER_TYPE_CHANNELS_SELECT_GOOGLE_FORWARDING_OPTION,
} from './types'

export default {
  [DRAWER_TYPE_SAMPLE]: Sample,
  [DRAWER_TYPE_CANNED_REPLIES_UPDATE]: CannedReplyCreateEdit,
  [DRAWER_TYPE_CANNED_REPLIES_DELETE]: CannedReplyDelete,
  [DRAWER_TYPE_CANNED_REPLY_CATEGORIES_DELETE]: CannedReplyCategoryDelete,
  [DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE]: CannedReplyCategoryCreateEdit,
  [DRAWER_TYPE_CANNED_REPLIES_CHANGE_CATEGORY_UPDATE]: CannedReplyChangeCategory,
  [DRAWER_TYPE_CANNED_REPLIES_OVERVIEW]: CannedReplyOverview,
  [DRAWER_TYPE_FOLDERS_DELETE]: FolderDelete,
  [DRAWER_TYPE_FOLDERS_UPDATE]: FolderCreateEdit,
  [DRAWER_TYPE_RULES_OVERVIEW]: RulesOverview,
  [DRAWER_TYPE_RULES_DELETE]: RuleDelete,
  [DRAWER_TYPE_RULES_UPDATE]: RuleCreateEdit,
  [DRAWER_TYPE_TAGS_UPDATE]: TagCreateEdit,
  [DRAWER_TYPE_TAGS_DELETE]: TagDelete,
  [DRAWER_TYPE_TAGS_MERGE]: TagMerge,
  [DRAWER_TYPE_TAGS_OVERVIEW]: TagOverview,
  [DRAWER_TYPE_USERS_OVERVIEW]: UsersOverview,
  [DRAWER_TYPE_TEAMS_OVERVIEW]: TeamsOverview,
  [DRAWER_TYPE_AGENT_ARCHIVE]: AgentArchive,
  [DRAWER_TYPE_AGENT_ADD]: AgentAdd,
  [DRAWER_TYPE_AGENT_ADD_MAILBOX_ACCESS]: AgentAddMailboxAccess,
  [DRAWER_TYPE_AGENT_OWNER]: AgentChangeOwner,
  [DRAWER_TYPE_USER_ADVANCED]: UserAdvanced,
  [DRAWER_TYPE_TEAM_UPDATE]: TeamCreateEdit,
  [DRAWER_TYPE_ADMIN_ADD]: AgentAddAdmin,
  [DRAWER_TYPE_ADMIN_REMOVE]: AgentRemoveAdmin,
  [DRAWER_TYPE_LITE_USER_ADD]: AgentSwitchToLite,
  [DRAWER_TYPE_REVOKE_INVITATION]: UserRevokeInvitation,
  [DRAWER_TYPE_TEAM_DELETE]: TeamDelete,
  [DRAWER_TYPE_CHANNELS_ADD]: AddChannel,
  [DRAWER_TYPE_CHANNELS_DELETE]: DeleteChannel,
  [DRAWER_TYPE_CHANNELS_SELECT_EMAIL]: SelectEmailChannel,
  [DRAWER_TYPE_CHANNELS_SELECT_GOOGLE_EMAIL]: SelectGoogleEmailChannel,
  [DRAWER_TYPE_CHANNELS_SELECT_GOOGLE_WORKSPACE_EMAIL]: SelectGoogleWorkspaceEmailChannel,
  [DRAWER_TYPE_CHANNELS_SELECT_GOOGLE_FORWARDING_OPTION]: GoogleForwardingOptionAlert,
  [DRAWER_TYPE_CHANNELS_SELECT_FACEBOOKPAGE]: SelectFacebookPage,
  [DRAWER_TYPE_CHANNELS_FORWARDING_DETAILS]: ChannelForwardingDetails,
  [DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH_MODAL]: ChannelDetailsOAuthModal,
  [DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH]: ChannelDetailsOAuth,
  [DRAWER_TYPE_CHANNELS_AUTHENTICATE]: AuthenticateChannel,
  [DRAWER_TYPE_CHANNELS_AUTHENTICATE_SUCCESS]: AuthenticateSuccess,
  [DRAWER_TYPE_CHANNELS_AUTHENTICATE_IMAP]: AuthenticateIMAPChannel,
  [DRAWER_TYPE_CHANNELS_MEMBERS_EDIT_MODAL]: EditMembersModal,
  [DRAWER_TYPE_CHANNELS_MEMBERS_EDIT]: EditMembers,
  [DRAWER_TYPE_CHANNELS_MAIL_IMPORT]: MailImport,
  [DRAWER_TYPE_CHANNELS_CREATION_FEEDBACK]: CreationFeedback,
  [DRAWER_TYPE_CHANNELS_ADD_LIVE_CHAT]: AddLiveChat,
  [DRAWER_TYPE_CHANNELS_SELECT_WIDGET]: SelectWidget,
  [DRAWER_TYPE_SHOPIFY_CANCEL_ORDER]: CancelOrderModal,
  [DRAWER_TYPE_SHOPIFY_CREATE_ORDER]: CreateOrderDrawer,
  [DRAWER_TYPE_SHOPIFY_SEND_INVOICE]: SendInvoice,
  [DRAWER_TYPE_SHOPIFY_REVIEW_INVOICE]: ReviewInvoice,
  [DRAWER_TYPE_SHOPIFY_UNSAVED_CHANGES]: UnsavedChangesDrawer,
  [DRAWER_TYPE_SHOPIFY_REFUND_ORDER]: RefundOrderDrawer,
  [DRAWER_TYPE_SHOPIFY_STORE_ADD]: StoreAdd,
  [DRAWER_TYPE_SHOPIFY_STORE_UPDATE]: StoreUpdate,
  [DRAWER_TYPE_SHOPIFY_CONFIGURE]: StoreConfigure,
  [DRAWER_TYPE_CHANNELS_EMAIL_FORWARD]: EmailForward,
  [DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS]: ForwardingInstructions,
  [DRAWER_TYPE_CHANNELS_FORWRADING_TEST]: ForwardingTest,
  [DRAWER_TYPE_CHANNELS_FORWRADING_FAILED]: ForwardingFail,
  [DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS_SENT]: ForwardingInstructionsSent,
  [DRAWER_TYPE_INTEGRATION_INSTALL]: IntegrationInstall,
  [DRAWER_TYPE_INTEGRATION_UNINSTALL]: IntegrationUninstall,
  [DRAWER_TYPE_CHANNELS_SETUP_EMAIL]: SetupEmailChannel,
  [DRAWER_TYPE_FOLDERS_OVERVIEW]: FolderOverview,
  [DRAWER_TYPE_WEBHOOKS_UPDATE]: WebhookCreateEdit,
  [DRAWER_TYPE_WEBHOOKS_DELETE]: WebhookDelete,
  [DRAWER_TYPE_THIRDPARTY_ACCESSTOKENS_DELETE]: ThirdPartyAccessTokenDelete,
  [DRAWER_TYPE_INTEGRATION_INSTALL_ZAPIER]: IntegrationInstallZapier,
  [DRAWER_TYPE_SPAMMERS_CREATE]: SpammerCreateDrawer,
  [DRAWER_TYPE_SPAMMERS_DELETE]: SpammerDeleteDrawer,
  [DRAWER_TYPE_INTEGRATION_MANAGE_MAILCHIMP]: IntegrationManageMailchimp,
  [DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE]: IntegrationSlackConfigure,
  [DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE]: IntegrationSlackChannel,
  [DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_DELETE]: IntegrationSlackChannelDelete,
  [DRAWER_TYPE_INTEGRATION_SLACK_MAP_USERNAMES]: IntegrationSlackMapUsernames,
  [DRAWER_TYPE_INTEGRATION_SLACK_SETUP_SUCCESS]: IntegrationSlackSetupSuccess,
  [DRAWER_TYPE_EXPORTS_CANCEL_OR_DELETE]: ExportCancelOrDelete,
  [DRAWER_TYPE_BILLING_CHANGE_VERSION]: BillingChangeVersions,
  [DRAWER_TYPE_BILLING_CONFIRM]: BillingConfirm,
  [DRAWER_TYPE_BILLING_LINK_SHOPIFY]: BillingLinkShopify,
  [DRAWER_TYPE_BILLING_EXTERNAL_APPROVE]: BillingExternalApprove,
  [DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD]: BillingSetupPaymentMethod,
  [DRAWER_TYPE_BILLING_SETUP_PAYMENT_METHOD_FEEDBACK]: BillingSetupPaymentMethodFeedback,
  [DRAWER_TYPE_INTEGRATION_RECHARGE_INSTALL]: IntegrationInstallRecharge,
  [DRAWER_TYPE_INTEGRATION_RECHARGE_CONFIGURE]: IntegrationConfigureRecharge,
  [DRAWER_TYPE_INTEGRATION_RECHARGE_STORE_UPDATE]: IntegrationStoreUpdateRecharge,
  [DRAWER_TYPE_INTEGRATION_RECHARGE_CANCEL_SUBSCRIPTION]: IntegrationCancelSubscriptionRecharge,
  [DRAWER_TYPE_INTEGRATION_RECHARGE_REACTIVATE_SUBSCRIPTION]: IntegrationReactivateSubscriptionRecharge,
  [DRAWER_TYPE_INTEGRATION_RECHARGE_SKIP_NEXT_ORDER]: IntegrationSkipNextOrderRecharge,
  [DRAWER_TYPE_KB_DELETE]: KBDelete,
  [DRAWER_TYPE_KB_CREATE_MORE]: KBCreateMore,
  [DRAWER_TYPE_KB_ARTICLE_DELETE]: KBArticleDelete,
  [DRAWER_TYPE_KB_CATEGORY_CREATE]: KBCategoryCreate,
  [DRAWER_TYPE_WIDGET_DELETE]: WidgetDelete,
  [DRAWER_TYPE_WIDGET_DELETE_DISABLED]: DisabledWidgetDeletion,
  [DRAWER_TYPE_WIDGET_REORDER_FORM_FIELDS]: ReorderContactFormFields,
  [DRAWER_TYPE_WIDGET_EDIT_CUSTOM_FIELD]: EditCustomField,
  [DRAWER_TYPE_WIDGET_DELETE_CUSTOM_FIELD]: CustomFieldDelete,
  [DRAWER_TYPE_LIVE_CHAT_DELETE]: LiveChatDelete,
  [DRAWER_TYPE_LIVE_CHAT_CONFIRM_DELETE]: LiveChatConfirmDelete,
  [DRAWER_TYPE_CUSTOMER_RATING_SETTING_UPSERT]: CustomerRatingSettingEdit,
  [DRAWER_TYPE_CUSTOMER_RATING_SETTING_DELETE]: CustomerRatingSettingDelete,
  [DRAWER_TYPE_ANNUAL_OFFER_MODAL]: AnnualOffer,
  [DRAWER_TYPE_BILLING_CHANGE_PLAN]: BillingChangePlan,
  [DRAWER_TYPE_BILLING_BASIC_CHANGE_PLAN]: BillingBasicChangePlan,
  [DRAWER_TYPE_BILLING_DOWNGRADE_PLAN]: BillingDownGradePlan,
  [DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION]: BillingDownGradePlanConfirmation,
  [DRAWER_TYPE_BILLING_INFORMATION]: BillingInformation,
  [DRAWER_COMMENT_DELETE]: CommentDelete,
  [DRAWER_TICKET_MERGE]: TicketMerge,
  [DRAWER_TYPE_ONBOARDING_WORKFLOW]: OnboardingWorkflow,
  [DRAWER_TYPE_DISCOUNT_LOCKOUT]: DiscountLockout,
  [DRAWER_TYPE_UNARCHIVE]: UserUnarchive,
  [DRAWER_TYPE_AI_DRAFT_LOCKOUT]: AIDraftLockout,
  [DRAWER_TYPE_SEND_DEBUG_INFO]: SendDebugInfo,
}
