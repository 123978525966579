import { oauthTokenSelector } from 'selectors/app'
import { FETCH_CHARGE_REQUEST } from 'ducks/integrations/recharge/types'
import { doSdkRequest } from 'ducks/requests/operations'

import * as api from '../api'

const doFetchCharges = ({ integrationId, subscriptionId, chargeId }) => {
  return doSdkRequest(
    FETCH_CHARGE_REQUEST,
    async ({ getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)

      const { response } = await api.getCharge(token, integrationId, chargeId)
      return response
    },
    { integrationId, subscriptionId, chargeId }
  )
}

export default doFetchCharges
