import { oauthTokenSelector } from 'selectors/app'
import { FETCH_UPCOMING_INVOICE } from 'ducks/integrations/stripe/types'
import { doSdkRequest } from 'ducks/requests/operations'

import * as api from '../api'

const doFetchUpcomingInvoice = ({
  integrationId,
  customerId,
  subscriptionId,
  type,
}) => {
  return doSdkRequest(
    FETCH_UPCOMING_INVOICE,
    async ({ getState }) => {
      const state = getState()
      const token = oauthTokenSelector(state)

      const { response } = await api.getInvoice(
        token,
        integrationId,
        customerId,
        subscriptionId,
        type
      )
      return response
    },
    { integrationId, customerId, subscriptionId, type }
  )
}

export default doFetchUpcomingInvoice
