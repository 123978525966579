import { createBasicSelector } from 'util/redux'
import { selectBooleanPreferenceByName } from './selectBooleanPreferenceByName'

export const selectPrefersAllMailboxesSectionVisible = createBasicSelector(
  state =>
    selectBooleanPreferenceByName(
      state,
      'prefers_v2_show_all_mailboxes_section'
    )
)
