import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_CUSTOMER_REQUEST_STARTED,
  FETCH_CUSTOMER_REQUEST_SUCCESS,
  FETCH_CUSTOMER_REQUEST_FAILED,
} from 'ducks/integrations/stripe/types'

const initialState = {
  byIntegrationId: {},
}

export const initialSingleState = {
  isInitialized: false,
  isLoading: false,
}

export default createActionTypeReducer(
  {
    [FETCH_CUSTOMER_REQUEST_STARTED]: (draftState, action) => {
      const { integrationId, email } = action.payload

      if (!draftState.byIntegrationId[integrationId]) {
        draftState.byIntegrationId[integrationId] = Object.assign(
          {},
          { byCustomerEmail: {} }
        )
      }

      const byIntegrationId = draftState.byIntegrationId[integrationId]

      if (!byIntegrationId.byCustomerEmail[email]) {
        byIntegrationId.byCustomerEmail[email] = Object.assign(
          {},
          initialSingleState
        )
      }

      byIntegrationId.byCustomerEmail[email].isInitialized = true
      byIntegrationId.byCustomerEmail[email].isLoading = true

      return draftState
    },
    [FETCH_CUSTOMER_REQUEST_SUCCESS]: (draftState, action) => {
      const { integrationId, email } = action.meta.requestParameters
      const { data } = action.payload
      const byCustomerEmail =
        draftState.byIntegrationId[integrationId].byCustomerEmail[email]

      if (Array.isArray(data) && data.length > 0) {
        Object.assign(byCustomerEmail, {
          isLoading: false,
          records: data,
        })

        return draftState
      }

      Object.assign(byCustomerEmail, {
        isLoading: false,
      })

      return draftState
    },
    [FETCH_CUSTOMER_REQUEST_FAILED]: (draftState, action) => {
      const { integrationId, email } = action.meta.requestParameters

      const byCustomerEmail =
        draftState.byIntegrationId[integrationId].byCustomerEmail[email]

      Object.assign(byCustomerEmail, {
        isLoading: false,
      })

      return draftState
    },
  },
  initialState
)
