import { oauthTokenSelector } from 'selectors/app'

import * as trello from '../api'
import {
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
} from '../types'

export default function doFetchTrelloList(listId) {
  return (dispatch, getState) => {
    dispatch({ meta: { listId }, type: FETCH_LIST_REQUEST })
    const state = getState()
    return trello
      .list(oauthTokenSelector(state), listId)
      .then(list =>
        dispatch({
          meta: { listId },
          payload: { lists: [list] },
          type: FETCH_LIST_SUCCESS,
        })
      )
      .catch(error =>
        dispatch({
          error: true,
          meta: { listId },
          payload: error,
          type: FETCH_LIST_FAILURE,
        })
      )
  }
}
