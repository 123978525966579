import { combineReducers } from 'core/redux'

import categories from './categories'
import template from './template'
import templates from './templates'
import search from './search'
import variables from './variables'

export default combineReducers({
  categories,
  template,
  templates,
  search,
  variables,
})
