import { oauthTokenSelector } from 'selectors/app'
import { selectDealProperties } from 'ducks/integrations/hubspot/selectors'
import config from 'config'

import * as api from '../api'
import {
  FETCH_DEAL_PROPERTIES_REQUEST,
  FETCH_DEAL_PROPERTIES_SUCCESS,
  FETCH_DEAL_PROPERTIES_FAILURE,
} from '../types'

export default function doFetchDealProperties() {
  return (dispatch, getState) => {
    const state = getState()

    const dealProperties = selectDealProperties(state)
    if (dealProperties) return Promise.resolve()
    dispatch({
      type: FETCH_DEAL_PROPERTIES_REQUEST,
    })
    const token = oauthTokenSelector(state)
    const calls = []
    calls.push(api.getDealProperties(token))
    calls.push(api.getDealStages(token))
    return Promise.all(calls)
      .then(payload => {
        const [properties, stages] = payload
        dispatch({
          type: FETCH_DEAL_PROPERTIES_SUCCESS,
          data: {
            properties: properties.response,
            stages: stages.response.results,
          },
        })
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        if (config.isDevelopment) console.error(error)
        return dispatch({
          type: FETCH_DEAL_PROPERTIES_FAILURE,
          data: {
            error,
          },
        })
      })
  }
}
