import { doAppGraphqlRequest } from 'ducks/requests/operations'
import {
  CREATE_CUSTOM_FIELD_CATEGORY,
  UPDATE_CUSTOM_FIELD_CATEGORY,
} from '../types'
import { customFieldCategoryNormalizationSchema } from '../schema'

import { customFieldCategoryFields } from './fetching'

const createQuery = `
  mutation CustomFieldCategoryCreate(
    $input: CustomFieldCategoryCreateInput!
  ) {
    customFieldCategoryCreate(input: $input) {
      category {
        ${customFieldCategoryFields}
      }
    }
  }
`
export function doCreateCustomFieldCategory(input) {
  return async dispatch => {
    dispatch(
      doAppGraphqlRequest(
        CREATE_CUSTOM_FIELD_CATEGORY,
        createQuery,
        { input },
        {
          normalizationSchema: {
            customFieldCategoryCreate: {
              category: customFieldCategoryNormalizationSchema,
            },
          },
        }
      )
    )
  }
}

const updateQuery = `
  mutation CustomFieldCategoryUpdate(
    $input: CustomFieldCategoryUpdateInput!
  ) {
    customFieldCategoryUpdate(input: $input) {
      category {
        ${customFieldCategoryFields}
      }
    }
  }
`
export function doUpdateCustomFieldCategory(input) {
  return async dispatch => {
    dispatch(
      doAppGraphqlRequest(
        UPDATE_CUSTOM_FIELD_CATEGORY,
        updateQuery,
        { input },
        {
          normalizationSchema: {
            customFieldCategoryUpdate: {
              category: customFieldCategoryNormalizationSchema,
            },
          },
        }
      )
    )
  }
}
