import graphql, { mapErrorsToFormFields } from 'api/graphql'

export const basicTwitterFields = `
  twitter {
    username
  }
`

export const extendedTwitterFields = `
  twitter {
    username
    description
    followingCount
    followerCount
  }
`

export const generateFieldsPayload = extensions => {
  return `
    id
    name
    first_name
    last_name
    company_name
    location
    email
    avatar_url
    title
    website_url
    about
    phone_number
    linkedin {
      username

    }
    ${extensions && extensions.join('\n')}
  `
}

export const basicFields = generateFieldsPayload([basicTwitterFields])

export const basicFieldsWithTwitter = generateFieldsPayload([
  extendedTwitterFields,
])

export const generateCustomerPayload = fields => {
  return `
  customer {
    ${fields}
  }
`
}

export const customerPartial = generateCustomerPayload(basicFields)
export const customerPartialWithTwitter = generateCustomerPayload(
  basicFieldsWithTwitter
)

export const query = customerId => `
  query CustomerQuery {
    customer(id: "${customerId}") {
      ${basicFields}
    }
  }
`

export const byEmailAddressQuery = (fields = null) => `
  query CustomersQuery(
    $emailAddresses: String
  ) {
    customers(emailAddresses: $emailAddresses) {
      records {
        ${fields || basicFields}
      }
    }
  }
`

export const byIdQuery = (fields = null) => `
  query CustomersQuery($ids: String) {
    customers(ids: $ids) {
      records {
        ${fields || basicFields}
      }
    }
  }
`

export const CREATE_MUTATION = `
  mutation CreateCustomer($name: String!, $email: String!, $updateIfUserExists: Boolean) {
    createCustomer(name: $name, email: $email, updateIfUserExists: $updateIfUserExists) {
      id
      name
      email
    }
  }
`

const getData = res => res.json.data
const getResult = key => data => data[key]

export const createCustomer = (token, customer) =>
  graphql(token, CREATE_MUTATION, { ...customer, updateIfUserExists: true })
    .then(getData)
    .then(getResult('createCustomer'))
    .catch(mapErrorsToFormFields)
