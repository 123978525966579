import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { selectCurrentUser } from './base'

export const selectCurrentUserSortOrderBySortContext = createSelector(
  selectCurrentUser,
  currentUser => {
    return currentUser.sortOrderBySortContext || emptyObj
  }
)
